<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 60%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Stock Items Adjustment</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">
                            <div class="drug-register-table table-container"
                                style="padding-top: 5em; width: fit-content; height: 20em; box-shadow: none; margin: 0 auto"
                                v-if='!$store.state.user.role.permissions.includes("can_do_outgoing")'>
                                <h4
                                    style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                                    You do not have permission to access this feature. Please talk to your admin if you
                                    require access.</h4>
                            </div>
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div v-else class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <div class="date-entered-field">
                                            <!-- <p class="field-title">Transaction ID</p> -->
                                            <input v-model="transaction_id" placeholder="Transaction ID..."
                                            style="position: relative; width: 100%; margin-bottom: 5px; background-color: #E5F1FE;" />
                                        </div>
                                        <p class="note-header">Notes</p>
                                        <textarea
                                            style="border-top-left-radius: 0; border-top-right-radius: 0; width: 100%;"
                                            name="notes" id="note-modal" cols="44" rows="20"
                                            placeholder="Enter notes here..." v-model="form.submit.notes"></textarea>
                                    </div>

                                    </div>
                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel" style="display: flex; flex-direction: column;">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p><b>Medicare Number:</b> {{this.patient.regulatory_information && this.patient.regulatory_information.length ? patient.regulatory_information[0].data : 'N/A'}}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>
                                    <div v-if="!imprest_pom">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div class="pom-imprest-select">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']">
                                                    Patient Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                   >
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- PATIENT MEDICATION SEARCH -->
                                    <div class="right-form-section-drug-entry" v-if="patient">
                                        <div style="width: 100%; margin-right: 10px">

                                            <!-- <select style="position: relative; width:100%; padding: 10px; "
                                                id="pom-select" @change="changeStock()">
                                                <option value="" disabled selected>Select medication</option>
                                                <option :value="index" v-for="(item, index) in patientMeds"
                                                    :key="index">{{ item.bag_id ? '[PATIENT OWNED]':'' }} {{ item.alias ? item.alias.name : '' }}  {{ item.alias ?
                                                            item.alias.measurement_unit : ''
                                                    }}
                                                    ({{ Number(item.quantity).toFixed(1) }} left)</option>
                                            </select> -->
                                            <PatientStockSearch :patientMeds="patientMeds" @selectStock="changeStock"/>
                                        </div>
                                        
                                        <p v-if="$store.state.currentLocation.state != 'WA'  || ($store.state.currentLocation.state == 'WA' && (!form.submit.stock_items  || !form.submit.stock_items.length)) "
                                        class="submit-button blue-button" @click="() => { aliasSelect(stock) }">
                                            Add</p>
                                    </div>

                                    <!-- DRUG VARIANCES -->
                                    <div class="drug-variances-container" style="display: flex; height: fit-content"
                                        v-show='refinedDrugs.length'>
                                        <!-- <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p> -->
                                        <p v-for="(item, index) in refinedDrugs" :key="index"
                                            @click="item.qty_left > 0 ? aliasSelect(item) : ''"
                                            style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                            {{item.patient_owned ? '[PATIENT OWNED]':''}} {{ item.alias.name }} <span style="font-weight: bold">In Stock:
                                                {{ item.qty_left }}</span>
                                        </p>
                                    </div>
                                    <div v-if="Array.isArray(refinedDrugs) && refinedDrugs.length == 0">
                                        <p style="font-size: 1em;">No stock found for that item.</p>
                                    </div>

                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <div class="right-form-section-drug-entry" v-if="imprest_pom == 'imprest'" style="width: 100%">
                                        <StockSearch @drugselected="setdrug"/>
                                        <p class="submit-button blue-button" style="width: 10em"
                                        v-if="$store.state.currentLocation.state != 'WA'  || ($store.state.currentLocation.state == 'WA' && (!form.submit.stock_items ||  !form.submit.stock_items.length)) "
                                            @click="() => { checkIfExistingItem(selectedDrug.full_item) }">Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>


                                    <div class="right-form-section-drug-container" >
                                        <table style="border-collapse: collapse" v-if="form.submit.stock_items && form.submit.stock_items.length">
                                            <tr>
                                                <th style="padding-left: 10px">Drug</th>
                                                <th style="width: 8em">Expected Qty</th>
                                                <th style="width: 8em">Actual Qty</th>
                                                <th style="width: 6em; ">Diff</th>
                                                <th ></th>
                                            </tr>
                                            <tr v-for="(item, index) in form.submit.stock_items" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{item.patient_owned ? '[PATIENT OWNED]':''}} {{ item.stock }}</p>
                                                </td>
                                                <td>
                                                    <p style="font-size: 1em;">{{item.qty_left}}</p>
                                                </td>
                                                <td>
                                                    <input type="number" min="0" style="width: 8em; background-color: white" v-model="item.qty" />
                                                </td>
                                                <td>{{typeof item.qty === 'number' ? (item.qty - item.qty_left) : ''}}</td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style='padding: 0'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>
                                    <!-- <div style="margin-top: 20px;">
                                <h3 style="margin: 0; text-align: left; margin-bottom: 10px">Approval</h3>
                                <DualVerify @user="getUsername" witness='witness' @pass="getPassword" :password="form.submit.password"  :username="form.submit.username"
                            @user2="getUsername2" @pass2="getPassword2" :password2="form.submit.witness_name"  :username2="form.submit.witness_role" class="full-width-verify" style='justify-content: flex-start'/>
                            </div> -->
                                    <div v-if="errorMessage">
                                        <p style="color: red; font-weight: 500; font-size: 1em;">{{ errorMessage }}</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel</p>
                        <p v-if='$store.state.user.role.permissions.includes("can_do_outgoing")' class="regular-button complete-button" @click="submitAdjust()">Submit</p>
                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SignatureModal v-if="displaySignatureModal" :payload="payload" @isSuccessful="isSuccessful" @close="close"
            :saveContext="saveContext" :endpoint="'/adjustments'" :manual_loop="imprest_pom == 'imprest'  && $store.state.currentLocation.state == 'WA'"
            :dual="true" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import StockSearch from '@/components/searches/StockSearch.vue';
import PatientStockSearch from '@/components/searches/PatientStockSearch.vue';
import axios from 'axios';

export default {
    props: ['transferType'],
    components: {
        // DualVerify,
        SignatureModal,
        PatientSearchModal,
        StockSearch,
        PatientStockSearch
    },
    data() {
        return {
            loadingDrugs: false,
            payload: {},
            selectedDrug:{},
            drugList:[],
            dual: false,
            transaction_id: '',
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            prescriber: '',
            selectedPrescriber: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            reasonCat: '',
            reasonSubCat: '',
            errorMessage: '',
            // qty: null,
            refinedDrugs: '',
            form: {
                submit: {
                    notes: '',
                    stock_items: [],
                }
            },
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'Adjustment',
                patient: '',
                // location: '',
                drugs: [],
                doubleSig: false
            }
        }
    },
    methods: {
        setdrug(drugFormatted) {
            this.selectedDrug = drugFormatted
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.alias.form[0].toUpperCase() + item.alias.form.slice(1);
            this.selectedDrug.strength = item.alias.strength;
            this.selectedDrug.measurement_unit = item.alias.measurement_unit;
            this.selectedDrug.name = item.alias.name[0].toUpperCase() + item.alias.name.slice(1);
            this.selectedDrug.tags = item.alias.tags;
            this.loadingDrugs = false;
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                 axios.post('/get-stock-items', { drug: this.stock }, this.$store.state.header).then(res => {
                        console.log('this is the res data getstockitems', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.forEach(item=>{
                            item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                            if(item.qty_left == 0){
                                item.disabled = true;
                            } else{
                                item.disabled = false;
                            }
                        })
                        this.drugList = res.data;
                        this.errorMessage = '';
                        this.loadingDrugs = false;
                    }).catch(err => {
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                        console.log('this is the transfer stock items error', err.response.data);
                        this.errorMessage = err.response.data;
                    })
            }
        },
        checkIfExistingItem(item){
            let exit = false;
            if(this.form.submit.stock_items.length){
                this.form.submit.stock_items.forEach((drugItem, index) => {
                    console.log('LOOPING THROUGH LIST', drugItem, item, exit);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit ) {
                        console.log('HIT A MATCH', drugItem);
                        // drugItem.qty = this.qty;
                        exit = true;
                    } else if(!exit && ((index+1)==this.form.submit.stock_items.length)){
                        console.log('HITTING ELSE NO MATCH', drugItem);
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else{
                this.aliasSelect(item);
            }
        },
        changeStock(drugdata) {
            this.stock = drugdata.itemvalue
            this.qty = Number(drugdata.itemqty).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
            if (this.imprest_pom != 'imprest' && !this.patient) {
                this.displayPatientSearchModal = false;
                this.imprest_pom = '';
            }
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        close(value) {
            if (value) {
                this.dual = false;
                this.displaySignatureModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }

        },
        isSuccessful(value) {
            if (value) {
                this.closeModal();
            }
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    console.log('this is the patient register', res);
                    this.patientMeds = res.data;
                })
            }
        },
        addToPending() {
            this.form.submit.pending = !this.form.submit.pending;
        },
        addToReason(reason) {
            this.form.submit.reason = reason;
            this.reasonCat = '';
            console.log('DOES IT CONTAIN RETURN STOCK TO PHARM?', this.form.submit.reason.includes('Return Stock to Pharmacy'))
            if (this.form.submit.reason == 'Damaged or Expired Stock') {
                this.reasonCat = '';
                this.reasonSubCat = '';
            } else {
                this.reasonCat = 'Return Stock to Pharmacy';
            }
            if (reason == 'From Doctor' || reason == 'From Patient') {
                this.form.submit.reason = 'Return Stock to Pharmacy -' + reason;
                this.reasonSubCat = reason;
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);

            if ((this.imprest_pom == 'imprest')) {
                this.form.submit.stock_items.unshift({ tags: item.alias.tags, stock: item.alias.name, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left });
                this.qty = null;
                this.refinedDrugs = '';
                this.noStock = false;
            } else {
                this.form.submit.stock_items.unshift({ tags: item.alias.tags, stock: item.alias.name,patient_owned: item.bag_id ? true : false, form: item.alias.form, qty_left: item.quantity, drug: item.uuid, qty:'' });
            }
        },
        checkAndAddStock(drugName) {
            if (drugName) {
                axios.post('/get-stock-items', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.data;
                    this.errorMessage = '';
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                    this.errorMessage = err.response.data;
                    console.log('err', err.response.data);
                    console.log('this is the  stock item error', err);
                })
            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.stock_items);
            this.form.submit.stock_items.splice(index, 1);
        },
        submitAdjust() {
            let newDrugSub = [];
            // let autoDrugs = []; -autoDrugs not
            if (this.form.submit.stock_items.length && this.transaction_id) {
                this.form.submit.stock_items.forEach(item => {
                    let p = {
                        stock_item: item.drug,
                        qty: Number(item.qty),
                        alias: item.stock
                    }
                    // autoDrugs.push(p);
                    
                    newDrugSub.push(p);
                    
                });
                this.payload = {
                    notes: this.form.submit.notes + `--Transaction being referenced: ${this.transaction_id}`,
                    stock_items: newDrugSub,
                }
                // this.saveContext = {
                //     drugs: autoDrugs,
                //     location: JSON.parse(localStorage.getItem('currentLocation')).name,
                //     patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : 'Imprest/Emergency Stock',
                //     supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                //     doubleSig: this.dual,
                //     type: 'Outgoing'
                // }
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'A drug and transaction id must be added into this submission.'
            }
            console.log('this is the form to submit', this.form, this.displaySignatureModal);

        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";



 

  


 

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
