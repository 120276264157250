<template>
    <div>
        <div>
            <p style="text-align: left; font-size: 1em; ">Select medications that are to be stocked/tracked for each
                location.
                All users of a role that are assiged the 'notify low stock' alert channel, will receive low
                stock alerts. Locations in which stock can be tracked are Wards, Pharmacies, and Theaters. </p>
            <p style="text-align: left; font-size: 1em; "><b>NOTE</b>: Only locations with a drug register can have an stock
                list.</p>
            <div v-if="loadingFacilities">
                <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div v-else style="display:grid; margin-bottom: 1em; align-items: center; grid-template-columns: 70% 30%;">

                <Dropdown v-model="selectedLocation" :options="facilities" @change="getMedList()" optionLabel="name"
                    placeholder="Select Location" style="width: 40%; height: fit-content; ">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            {{ slotProps.value.name }}
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div style="display: grid; grid-template-columns: 70% 30%; grid-template-rows: 26px;">
                            <p style="margin: auto; text-align: left; width: 100%;">{{ slotProps.option.name }}</p>
                            <Tag rounded>{{ slotProps.option.type.charAt(0).toUpperCase() +
                                slotProps.option.type.slice(1) }} </Tag>
                        </div>
                    </template>
                </Dropdown>


                <div v-if="selectedLocation" style="display: flex; height: fit-content; justify-content: flex-end;">
                    <p v-if="selectedLocation && medItems.length > 0" class="button green-button"
                        style="width: fit-content;font-weight: bold;" @click="displayCopyModal = true">Copy To...</p>
                </div>




            </div>

            <div v-if="selectedLocation && medItems.length > 0"
                style="display: grid; grid-template-columns: 30%  70% ;  align-items: center; ">
                <div style="justify-self:flex-start ">
                    <h3 style="margin-bottom: 0px;">Stock List</h3>
                </div>
                <div></div>
            </div>
            <div v-if="(selectedLocation && medItems.length == 0 && !stocklistActive)">
                <div style="display: grid; justify-items: center;">
                    <p v-if="medItems.length == 0" class="blue-button" style="width: 20%;" @click="createMedItem()">Create
                        New Stock List</p>
                </div>
            </div>


            <div style="padding: 10px;" v-if="selectedLocation  || stocklistActive">
                <table v-if="medItems.length > 0 || stocklistActive" class="data-table" style=" width: 100%; border-collapse: collapse;">
                    <tr>
                        <th style="width: 35%"> Medication</th>
                        <th style="width: 21%;"></th>
                        <th style="width: 20%;"> Alert Status*</th>
                        <th style="width: 8%;"> Min Qty</th>
                        <th style="width: 8%;"> Max Qty</th>
                        <th style="width: 8%;text-align: center;">Delete</th>
                    </tr>

                    <tbody class="table-data nim-list-table-body" v-for="(item, index) in medItems" :key="item">
                        <tr :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']">
                            <td style="padding-bottom: 15px; padding-top:15px ;">
                                <div style="display: flex; align-items: center;">
                                    <!-- New Med UI -->
                                    <!-- Medication -->
                                    <Dropdown 
                                    id="prescription-med-search" 
                                    v-model="item.search_term" 
                                    ref="med"
                                    @keyup="algoliaDrugSearch(item.search_term, item); show();"
                                    @change="drugSelect(item.search_term, index)" :loading="item.loadingDrugs"
                                    :options="item.drugList" 
                                    optionLabel="name" 
                                    :editable="true"
                                    style="width: 100%; display: flex; margin-left: 18px;"
                                    placeholder="Search Medication" />
                                </div>
                            </td>
                            <!-- Action -->
                            <td></td>
                            <td>

                                <p> {{ item.minimumQuantity == null ? 'None' : "Low Stock" }} </p>

                            </td>
                            <!-- Minimum Quantity :minFractionDigits="1" for float -->
                            <td>
                                <InputNumber min=0 :minFractionDigits="2" class="border small-input" placeholder="N/A" v-model="item.minimumQuantity"
                                    style="border-radius: 6px; width: 99%" />
                            </td>
                            <!-- Maximum Quantity	 -->
                            <td>
                                <InputNumber min=0 :minFractionDigits="2"  class="border small-input" placeholder="N/A" v-model="item.maximumQuantity"
                                    style="border-radius: 6px; width: 99%" />
                            </td>


                            <!-- MED ACTIONS -->
                            <td style="text-align:center;"><button class="remove-button"
                                    @click="deleteMedItem(item, index)"><i class="pi pi-eraser"></i></button></td>

                        </tr>


                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <p style="margin-left: 18px; font-weight: bold;">Add New Medication</p>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td  style="text-align:center;">
                                
                                <button class="blue-button" @click="createMedItem()">+</button>
                            </td>

                        </tr>
                    </tfoot>


                </table>
                <div style="display: grid; grid-template-columns: 1% 95%;">
                    <h5 style="margin-left: 0px;margin-bottom: 0px;margin-top: 0px; font-weight: normal;">*</h5>
                    <h5 style="margin-left: 18px; font-weight: normal;margin-bottom: 18px;text-align: left;margin: auto;">Low stock alerts are only active when subscribed to the `Low Stock` alert channel. Low stock alerts are generated when a minimum quantity is entered in and the stock level falls below it. </h5>

                
                </div>


                <div v-if="medItems.length > 0" style="display: flex; justify-content: flex-end;; margin-bottom: 10px;">
                    <p style="color: red; font-weight: bold;" v-if="this.duplicateMeds">Duplicate Medication Entered</p>
                    <p v-if="!this.duplicateMeds" class="button green-button" style="width: fit-content; padding: 5px 18px; margin: 0; margin-top: 20px; font-weight: bold;" @click="saveMedList()">Save List</p>
                </div>
            </div>

        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
        <CopyToModal @close="close" v-if="displayCopyModal" :copyLocation="selectedLocation" :locationsList="facilities"
            :medItems="medItems" />

    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import CopyToModal from '@/components/modals/CopyToModal.vue';
import { isProxy, toRaw, ref } from 'vue';



const loading = ref(false);
const loadLazyTimeout = ref();



export default {
    components: {
        SuccessModal,
        CopyToModal
    },
    data() {
        return {
            medItems: [
            ],
            med_list_id: '',
            displaySuccessModal: false,
            duplicateMeds : false,
            displayCopyModal: false,
            successMessage: '',
            copytoMessage: "",
            errorMessage: '',
            selectedLocation: '',
            stocklistActive : false,
            facilities: [],
            loadingConfig: false,
            loadingFacilities: false,
            loadingDrugs: false,
            drugList: [],
            stock: '',
            selectedDrug: {},
            drugSearchFilter: 0,
            stockItems: [],
            loading: false,
            pagcount: 1,
            ongoingRequest: false
        }
    },
    watch: {
        medItems: {
        deep: true,
        handler : function(newMedArray) {

            let rawNewMedArray = toRaw(newMedArray)
            let uuidsAsSet = new Set();

            rawNewMedArray.forEach(function (currentmed) {
                uuidsAsSet.add(currentmed.drug.name)
            });
            console.log("Set")
            console.log(uuidsAsSet)

            if(newMedArray.length != 1) {
            if (uuidsAsSet.size != newMedArray.length) {
                this.duplicateMeds = true
            } else {
                this.duplicateMeds = false
            }
        } else {
            this.duplicateMeds = false
        }


        }
    }
    },
    methods: {
        show(){
            let interval = setInterval(() => {
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        onLazyLocationLoad(event) {

            loading.value = true;
            if (!this.ongoingRequest) {
                this.ongoingRequest = true;
                axios.post(`/get-locations`, { page: this.pagcount, location_type: ["ward", "pharmacy","theatre"] }, this.$store.state.header).then(res => {
                    const newLocations = res.data.locations.filter(location => location.has_drug_register == true)
                    if (newLocations.length != 0) {
                        const locationsOldAndNew = this.facilities + newLocations
                        this.facilities = locationsOldAndNew
                    }

                    this.loadingFacilities = false;
                    this.pagcount++;
                    this.ongoingRequest = false;
                    loading.value = false;
                }).catch(err => {
                    console.log('this is the error', err.response.data);
                    this.successMessage = 'Error: ' + JSON.stringify(err.response.data);
                    this.displaySuccessModal = true;
                })
            }
        },
        // Used for Med Drug Search
        algoliaDrugSearch(searchedDrugName, item) {
            var searchName = '';
            // Check if the search term is the full object or just a string
            if (typeof searchedDrugName === 'object') {
                searchName = searchedDrugName.full_name;
            } else {
                searchName = searchedDrugName;
            }

            let headers = {
                "X-Algolia-API-Key": process.env.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": process.env.VUE_APP_ALGOLIA_APP_ID
            }

            // Check if there is brand/generic searching
            // If searching for all meds
            axios.post(`https://${process.env.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`,
                { params: `query=${searchName}` },
                { headers })
                .then(res => {
                    console.log('this is the algolia res', res.data.hits);
                    item.drugList = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        // Add New Medication button. Adds meditem to meditem array
        createMedItem() {
            this.stocklistActive = true;
            this.medItems.push({
                search_term: '',
                drug: {},
                drugList: [],
                loadingDrugs: false,
                minimumQuantity: null,
                maximumQuantity: null
            })
        },
        // Save List button. Batch save. Batch locations too
        saveMedList() {
            this.displaySuccessModal = true;
            this.successMessage = 'Saving Stock List...';
            let payload = [];
            this.medItems.forEach(item => {
                if ((item.drug.drug_uuid || item.drug.alias_id)) {
                    payload.push({
                        maximum_quantity: item.maximumQuantity,
                        minimum_quantity: item.minimumQuantity,
                        drug: item.drug.drug_uuid ? item.drug.drug_uuid : item.drug.alias_id
                    })
                }
            })
            console.log("Selected")
            console.log(this.currentLocation)

            // Endpoint can handle updating multiple facilties
            axios.post(`/location/stock-list/save`, { locations: [this.selectedLocation.uuid], stock_items: payload }, this.$store.state.header).then(res => {
                console.log('saved stock list', res.data);
                this.successMessage = 'Saved Stock List';
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
                this.successMessage = err.response.data;
                this.$store.dispatch('getRefreshToken');
            })
        },
        // Removes an item from the list, then calls saveMedList to update on the backend
        deleteMedItem(item, index) {
            console.log(item)
            console.log(index)
            if (item.uuid) {
                console.log('removing the existing item by uuid, then splicing it out', item.uuid);
                this.medItems.splice(index, 1);
                this.medItems[index].display = false;
                document.getElementById(`expand-${index}`).style.display = 'none';
                const currentLocation = [this.currentLocation]
                this.saveMedList(currentLocation)
            } else {
                console.log('item not saved, but just deleting from FE list', item);
                this.medItems.splice(index, 1);
                // document.getElementById(`expand-${index}`).style.display = 'none';
            }

        },
        // Function when close is called from a modal
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
                this.displayCopyModal = false;
            }
        },
        // Med List reteaval when facility chosen
        getMedList() {
            this.medItems = []
            this.loadingConfig = true;
            this.displaySuccessModal = true;
            this.successMessage = 'Retrieving Stock List if available...';
            // REPLACE WITH /location/{uuid}/inventory-list
            axios.post(`/location/${this.selectedLocation.uuid}/stock-list`, {}, this.$store.state.header).then(res => {
                console.log('this is the res', res.data);
                this.displaySuccessModal = false;
                this.stockItems = res.data.list_items;
                console.log('these are the med items');
                console.log(toRaw(this.stockItems))

                this.stockItems.forEach((item) => {
                    this.medItems.push({
                        search_term: item.alias.name,
                        drug: item.alias,
                        drugList: [],
                        loadingDrugs: false,
                        minimumQuantity: item.minimum_quantity,
                        maximumQuantity: item.maximum_quantity
                    })
                });
                if (res.data.uuid) {
                    this.med_list_id = res.data.data.uuid;
                }
            }).catch(err => {
                this.successMessage = err.response.data;
                this.$store.dispatch('getRefreshToken');
            })

        },

        debounceSearchDrugs(searchItem, index) {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs(searchItem, index)
            }, 400); // delay
        },
        // Unused? 
        searchDrugs(searchItem, index) {
            if (searchItem) {
                this.medItems[index].loadingDrugs = true;
                axios.post('/get-drug-aliases', { drug: searchItem, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    res.data.drug_aliases.forEach(item => {
                        item.full_name = `(${item.generic_name}) ${item.name}`;
                    })

                    this.medItems[index].drugList = res.data.drug_aliases;

                    this.drugSelect(this.medItems[index].drugList[0], index);
                    this.medItems[index].loadingDrugs = false;
                    // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
                }).catch(err => {
                    console.log('this is the err', err);
                    this.medItems[index].loadingDrugs = false;
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
        },
        // Gets the drug details and populates the medlist item with the details of the selected drug.
        drugSelect(item, index) {
            this.medItems[index].loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.medItems[index].drug.full_item = item;
            this.medItems[index].drug.drug_uuid = item.alias_id;
            this.medItems[index].drug.full_name = item.full_name;
            this.medItems[index].drug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.medItems[index].drug.strength = `${item.strength}`;
            this.medItems[index].drug.measurement_unit = `${item.measurement_unit}`;
            this.medItems[index].drug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.medItems[index].drug.tags = item.tags;
            this.medItems[index].loadingDrugs = false;
            console.log('this is the selected drug item', this.medItems[index].drug);
            this.medItems[index].doseTypes = [
                this.medItems[index].drug.form,
                this.medItems[index].drug.measurement_unit.split('/')[0]
            ]

            if (this.medItems[index].drug.measurement_unit.split('/')[1] != 'each') {
                this.medItems[index].doseTypes.push(this.medItems[index].drug.measurement_unit.split('/')[1]);
            }
        },
        // Gets ward facilities that have a drug register 
        getFacilities() {
            this.loadingFacilities = true;
            console.log(this.$store.state.currentLocation)
            // cannot currently use location_type as array ['ward','pharmacy'] this functionality is required if we do not want to make multiple calls
            // location_type: ['ward','pharmacy','theater'] cannot request theater at this point
            axios.post(`/get-locations`, { location_type: ["ward", "pharmacy","theatre"] }, this.$store.state.header).then(res => {
                console.log('these are the locations', res.data.locations);
                const facilitiesWithRegisters = res.data.locations.filter(location => location.has_drug_register == true)
                this.facilities = facilitiesWithRegisters
                this.loadingFacilities = false;
            }).catch(err => {
                console.log('this is the error', err.response.data);
                this.successMessage = 'Error: ' + JSON.stringify(err.response.data);
                this.displaySuccessModal = true;
            })
        }
    },
    mounted() {
        this.getFacilities();
        let myHost = window.location.host;

        if(myHost.includes('hospital') && myHost.includes('staging')){
            this.$store.commit('setSubdomain', 'st-hp')
        } else if(myHost.includes('hospital')){
            this.$store.commit('setSubdomain', 'prod')
        } else{
            this.$store.commit('setSubdomain', 'st-hp')
        }

    },
    computed: {
        filteredPrescribers() {
            return this.prescribers.filter(prescriber => prescriber.name);
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";


.grey-input {
    background-color: #f3f4f4 !important;
    border-radius: 4px;

    input {
        background-color: #f3f4f4;
    }

}

.small-input {
    width: 4em;

    input {
        width: 100%;
    }
}

.medium-input {
    width: 6em;

    input {
        width: 100%;
    }
}

.long-input {
    width: 10em;

    input {
        width: 100%;
    }
}

.border {
    border: 1px solid #dadada;
}

.nim-list-table-body {
    td {
        padding: 2px;

    }
}

.save-button {
    background-color: #00AD50 !important;
    padding: 5px 20px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 auto;
    color: white;
    text-align: center;
}

.remove-button {
    background-color: #E04F39;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 auto;
    color: white;
    text-align: center;
}</style>
