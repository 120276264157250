<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%; min-width: 500px">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">A draft form was found: {{ saveCache.type }}</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr">
                                <div class="right-form-section-panel" style="display: grid;"
                                    :style="'grid-template-columns: 1fr'">
                                    <div style="text-align: left" v-if="!success">
                                        <p style="font-size: 1em"><b>Location</b>: {{ saveCache.location }}</p>
                                        <p style="font-size: 1em"><b>Patient/Imprest</b>: {{ saveCache.patient }}</p>
                                        <p style="font-size: 1em"><b>{{ saveCache.supplemental }}</b></p>
                                        <p v-if="saveCache.supplemental2" style="font-size: 1em">
                                            <b>{{ saveCache.supplemental2 }}</b></p>
                                        <div>
                                            <table style="border-collapse: collapse" class="data-table">
                                                <tr>
                                                    <th>Drug</th>
                                                    <th>Qty</th>
                                                </tr>
                                                <tr v-for="(item, index) in saveCache.drugs" :key="index">
                                                    <td>{{ item.drug }}</td>
                                                    <td>{{ item.qty }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <h4>Successfully submitted!</h4>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;" v-if='!success'>
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="background-color: green"
                            @click="displaySignatureModal = true">Submit Action</p>
                    </slot>
                    <slot name="footer" style="display: flex;" v-else>
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Close
                        </p>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :dual="true" :payload="payload"
            :header="header" :endpoint="endpoint" @close="close" />
    </div>

</template>

<script>
// import axios from 'axios';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import FirebaseService from "../../services/FirebaseService";

export default {
    props: [],
    components: {
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            success: false,
            endpoint: '',
            payload: '',
            header: '',
            saveCache: ''
        }
    },
    methods: {
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        isSuccessful(value) {
            if (value) {
                this.close(true);
                this.success = true;
                if (this.$route.name == 'Drug Register') {
                    console.log('loading the drug register stuff');
                    this.$store.dispatch('getDrugRegister', {
                        location: this.$store.state.uuid,
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        filters: {
                            transaction_id: '',
                            to_from: '',
                            entered_by: '',
                            date: {
                                start: '',
                                end: ''
                            },
                            drug: '',
                            imprest_only: this.$store.state.drugRegImprestToggle,
                            destruction_only: this.$store.state.drugRegDestructionToggle,
                        }
                    });
                }
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('close', true);
            this.deleteFirebaseRecord();
        },
        deleteFirebaseRecord() {
            let user_id = JSON.parse(localStorage.getItem('user_id'));
            FirebaseService.delete(user_id).then(() => {
                console.log('existing form has been deleted');
            })
        }
    },
    mounted() {
        console.log('this is the save Cache', this.saveCache, this.endpoint, this.payload, this.header);
        this.saveCache = JSON.parse(localStorage.getItem('savedform')).saveContext;
        this.payload = JSON.parse(localStorage.getItem('savedform')).payload;
        this.header = JSON.parse(localStorage.getItem('savedform')).header;
        this.header.headers['Authorization'] = `Bearer ${('; ' + document.cookie).split(`; auth=`).pop().split(';')[0]}`
        this.endpoint = this.payload.endpoint;
        delete this.payload.endpoint;
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
