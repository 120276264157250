<template>
    <router-link v-if="newTransfers.length > 0" style="text-decoration: none; margin:0; color: #2c3e50;"
        :to="'/stock/transfers'">
        <div class="notification-type-box">
            <div
                style="display: inline-block;display:flex ;justify-content: start;align-items: center; padding-left: 10px;">
                <h4 style="margin: 20px 0px 0px 15px;">Transfers</h4>
                <span style="margin-top: 20px;" class="alert-count">
                    <p style="display: inline-block;">{{ newTransfers.length }}</p>
                </span>
            </div>

            <div class="notification-content-container">
                <div>
                    <table  class="data-table-notifications" cellspacing="0">
                        <tr class="table-headers">
                            <!-- <th style="width: 30px;"></th> -->
                            <th style="width: 33%;padding-left: 10px;">
                                <h3 style="padding-right: 10px;">From</h3>
                            </th>
                            <th style="width: 33%;padding-left: 10px;">
                                <h3 style="padding-right: 10px;">To</h3>
                            </th>
                            <th style="width: 33%">
                                <h3 style="padding-right: 10px;">Date Created</h3>
                            </th>
                            <th style="width: 33%">
                                <h3 style="padding-right: 10px;">Status</h3>
                            </th>
                        </tr>
                        <tbody class="table-data-notifications-4col" >

                            <tr v-for="(item, index) in newTransfers" :key="index">
                         
                                <td style="padding-left: 10px;">{{ item.body.message.location.from.name }}</td>
                                <td style="padding-left: 10px;">{{ item.body.message.location.to.name }}</td>
                   
                                <td>{{ new Date(item.created_at).toLocaleDateString('en-AU')}}</td>

                                <td>{{ item.body.message.status[0].toUpperCase() + item.body.message.status.slice(1)  }}</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    </div>

</router-link></template>

<script>

export default {
    computed: {
        newTransfers() {
            const channelList = [
                'transfer_created',
                'transfer_pending',
                'transfer_cancelled',
                'transfer_complete'
            ];
            const reqsRaw = this.$store.getters.getNotificationItems.filter(item => channelList.includes(item.channel));
            return reqsRaw;

        },
    },
    mounted() {

    }
}

</script>

<style lang="scss" scoped>
.notification-item {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

// .data-table-notifications tr:last-child  {
//     border: 1px solid black;
//     border-bottom-right-radius: 8px;
//     border-bottom-left-radius: 8px;
//     background-color: aqua;
//   }


.notification-type-box {
    border-bottom: 0px solid #a4a4a4;
}

.notification-content-container {
    display: flex;
    padding: 10px 25px 10px 25px;
    gap: 10px;
    justify-content: space-between;
}

.alert-item-type {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
}
</style>