<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <!-- <div class="modal-container" style="width: 30%;" :class="[toAction.action == 'Approve' ? 'approve-container': 'reject-container']">

            <div class="modal-body">
              <slot name="body">
                <form   class="action-modal">
                    <div class="section-container">
                        <div class="notes-modal-section">
                            <p style="font-weight: 500; text-align: left; margin: 0; padding-left: 10px; color: black">Notes</p>
                            <textarea name="notes" id="note-modal" cols="95" :rows="[delivery ? 6 : 8]" placeholder="Enter notes here" v-model="form.submit.notes"></textarea>
                            
                        </div>
                    </div>
                </form>
              </slot>
            </div>
            <div class="modal-footer" style="grid-gap: 50px; grid-template-columns: 1fr;text-align: center;">
              <slot name="footer" style="display: flex;">
                  <p style="cursor: pointer; margin: 10px" @click="submitTransferAction()">Proceed to {{toAction.action}} {{delivery ? 'Delivery' : 'Transfer'}}</p>
                  <button @click="closeModal" 
                  style="background-color: white;
                        padding: 0px;
                        position: absolute;
                        margin-top: -30px;
                        margin-left: 34em;"><i class="pi pi-times"></i></button>
              </slot>
            </div>
          </div> -->
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">{{ toAction.action }} {{toAction.action.includes('Requisition') ? '': 'Entry'}}</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <p class="note-header">Notes</p>
                                        <textarea name="notes" id="note-modal"
                                            style="border-top-right-radius: 0px; border-top-left-radius: 0px;"
                                            cols="110" rows="16" placeholder="Enter notes here..."
                                            v-model="form.submit.notes"></textarea>
                                    </div>
                                </div>

                                <!-- RIGHT SIDE PANEL -->
                                <!-- <div class="right-form-section-panel" style="display: flex; flex-direction: column; justify-content: space-between;">
                            <div class="right-form-section-drug-container" >
                                <table style="border-collapse: collapse">     
                                    <tr v-for="(item, index) in payload.items" :key="index" class="drug-entry-transfer">
                                        <td style="width: 50%;"><p>{{item.alias.name}} {{Number(item.alias.strength).toFixed(1)}}{{item.alias.form_description}}</p></td>
                                        <td><p>{{item.quantity}} {{item.alias.form}}{{item.quantity > 1 ? 's':''}}</p></td>
                                    </tr>
                                </table>
                            </div>
                        </div> -->
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <div style="padding-right: 20px;">
                        <p v-if="contructRequiredData != ''" style="display: inline; font-weight: 500; font-size: 1em;">Missing Fields: </p>
                        <p v-if="contructRequiredData != ''" style="display: inline;color: red; font-weight: 500; font-size: 1em;">{{ contructRequiredData }}</p>
                        </div>
                        <div class="spacer"></div>
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p v-if="!contructRequiredData.length" class="regular-button complete-button" @click="toAction.action.includes('Requisition') ? submitRequisition(): submitTransferAction()">Submit</p>
                        <p v-else class="grey-button">Submit</p>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal
            :endpoint="endpoint ? endpoint : delivery ? '/single-delivery/action' : toAction.action == 'Approve' ? '/single-transfer/approve' : '/single-transfer/reverse'"
            v-if="displaySignatureModal" dual="true" @isSuccessful="isSuccessful" @close="closeSigModal"
            :payload="realSubmit ? realSubmit : form.submit" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
// import axios from 'axios';

export default {
    props: ["toAction", 'display', 'delivery', 'drugs', 'endpoint', 'drugReg'],
    components: {
        // DualVerify
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            form: {
                submit: {
                    notes: '',
                    uuid: ''
                }
            },
            realSubmit:'',
            reference_number: '',
            date_received: '',
        }
    },
    methods: {
        closeSigModal(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('close');
            }
        },
        isSuccessful(value) {
            if (value) {
                this.closeModal();
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.displaySignatureModal = false;

            this.$emit('close', true);
        },
        submitTransferAction() {
            if (this.toAction.action == 'Reverse') {
                this.form.submit.unit_uuids = this.toAction.unit_uuids;
            }
            console.log('is this a delivery', this.delivery);
            this.form.submit.uuid = this.toAction.uuid;
            if (this.delivery) {
                this.form.submit.action = 'reverse';
            }
            this.displaySignatureModal = true;

        },
        submitRequisition(){
            this.realSubmit = {
                requisition_id : this.toAction.uuid,
                notes : this.form.submit.notes
            }
            this.displaySignatureModal = true;
        }
    },
    beforeMount() {
        console.log('this is the display from action modal', this.display);
        if (this.toAction && this.toAction.action != 'Reverse') {
            console.log('this is the toaction', this.toAction.note);
            this.form.submit.notes = this.toAction.note
        }
    },
    computed: {
        getNotes() {
            return this.toAction.note

        },
        contructRequiredData() {
            let remaining = []
            let stringbuilder = ""

            // if note is empty
            if (!this.form.submit.notes) {
                remaining.push("Notes") 
            } 

            remaining.forEach((field, index) => {
                console.log(index)
                console.log(remaining.length)
                if(remaining.length == index+1) {
                    stringbuilder += field 
                } else {
                    stringbuilder += (field + ", ")
                }
            })
            return stringbuilder
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
</style>
