<template>
    <div class="single-user-verify-form verification-form" :style="drugSafeRemoval ? {marginLeft: '1em', marginRight: '1.4em'}:{}">
        <button v-if="!success" class="complete-button" style="font-weight: bold; margin: 0;" @click="openSignature = true">
            <i  v-if="drugSafeRemoval" class="pi pi-lock-open" style="padding: 0;"></i>
            <p v-else style="margin: 0" >SIGN</p>
        </button>
        <i v-if="success" class="pi pi-check-square" style="font-weight: bold; font-size: 1.3em !important;"
        :style="status == 'good'? {color: '#00AD50'}: status == 'warning' ? {color: '#EF9600'}:{color: '#E04F39'}"></i>
        <SignatureModal :notes="notes" :loop="drugSafeRemoval ? true : false" :warning="warning" :optional="optional" @close="close" v-if="openSignature" @isSuccessful="isSuccessful" :dual="dual" :endpoint='endpoint' :payload="payload" />
    </div>
</template>

<script>
import SignatureModal from '@/components/modals/SignatureModal.vue';

export default {
    props:['endpoint', 'dual', 'status', 'payload', 'warning', 'isCompleted', 'indices', 'notes', 'optional', 'drugSafeRemoval'],
    components:{
        SignatureModal
    },
    data(){
        return{
            openSignature: false,
            success:false,
        }
    },
    methods:{
        close(value){
            if(value){
                this.openSignature = false;
            }
        },
        isSuccessful(value){
            console.log('this is the IS SUCCESSFUL VALUE EMITTED', value);
            if(value){
                this.success = true;
                this.$emit('submitIsCompleted', { item: value.stock_register_id ? value : this.indices});
            }
        }
    },
    computed(){
        
    }
}
</script>