<template>
    <div class="drug-register-view view">
        <div class="panel" style="margin-left: 3em; width: 95%;">
            <h3 class="panel-header">Create New Chart</h3>
            <div class="charts-menu-container">
                <div v-for="(item, index) in charts" :key="index" class="charts-menu-item"
                    :style="{ backgroundColor: item.color }" @click="() => { selectedChart = item; removeEmptyItems() }">
                    <h4>{{ item.name }}</h4>
                    <p>{{ item.subtext }}</p>
                </div>
            </div>
            <div class="create-chart-container">

                <div class="chart-form-approval-container" style="justify-content: space-between">
                    <!-- BEGINNING OF CHART -->
                    <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin-bottom: 10px">
                        <div>
                            <div class="chart-form-container" :style="{ border: '3px solid ' + selectedChart.color }"
                                v-if="selectedChart">
                                <h4 :style="{ backgroundColor: selectedChart.color }">{{ selectedChart.name }}
                                    {{ selectedChart.subtext }}</h4>
                                <div class="chart-form">
                                    <div class="first-chart-row chart-row">
                                        <div class="first-chart-row-item chart-row-item"
                                            style="display: grid !important;">
                                            <div class="text-search-container search-container"
                                                style="display: flex; align-items: center">
                                                <input v-model="searchedDrugName" placeholder="Medication Name"
                                                    style="position: relative; width: fit-content; margin-bottom: 5px" />
                                                <div style="cursor: pointer;" @click="searchDrugs(searchedDrugName)"><i
                                                        class="pi pi-search" style="font-weight: bold"></i></div>
                                                <span v-if="form.chart_form.form">{{ form.chart_form.form }} &nbsp;&nbsp;
                                                    | &nbsp;&nbsp;{{ form.chart_form.strength }}</span>
                                            </div>
                                            <div class="drug-variances-container"
                                                style="position: absolute; margin-left:5px; padding-top: 10px; background-color: white; display: flex; flex-wrap: wrap; align-items: flex-start;
                                 height: fit-content; border: 1px solid lightgrey; box-shadow: 0 0 0 3px #f8f8f8;
                                 height: fit-content; max-height: 150px; overflow-y: scroll; margin-top: 180px; padding-left: 15px; width: 600px;"
                                                v-show='drugList.length'>
                                                <p v-for="(item, index) in drugList" :key="index"
                                                    @click="drugSelect(item)"
                                                    style="padding: 3px 10px; height: 30px; margin-right: 5px; margin-top: 2px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                                    {{ item.name }}
                                                </p>
                                            </div>
                                        </div>
                                        <!-- <div class="first-chart-row-item chart-row-item" style="width: 5em;">
                               <InputText v-tooltip="'Form'" placeholder="Form" v-model="form.chart_form.form" />
                               
                           </div> -->
                                        <!-- <div class="first-chart-row-item chart-row-item">
                               <InputText v-tooltip="'Dose'" placeholder="Dose" v-model="form.chart_form.dose" />
                              
                           </div> -->
                                    </div>
                                    <div class="chart-row" style="flex-wrap: wrap; width: 681px">
                                        <div class="chart-row-item" style="width: fit-content"
                                            v-for="(item, index) in selectedChart.inputs" :key="index">
                                            <InputText v-tooltip="'Reason'" placeholder="Reason" v-if="item == 'reason'"
                                                v-model="form.chart_form.reason" />
                                            <div class="toggle-switch" v-if="item == 'single_multi'"
                                                :style="{ border: '2px solid' + selectedChart.color }">
                                                <p class="toggle-switch-item" style="border-right: 2px solid"
                                                    :style="single_multi == 'single' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="single_multi = 'single'">Single</p>
                                                <p class="toggle-switch-item"
                                                    :style="single_multi == 'multi' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="single_multi = 'multi'">Multi</p>
                                            </div>
                                            <InputNumber v-tooltip="'Dose'" :minFractionDigits="2"
                                                :maxFractionDigits="2" mode="decimal" placeholder="Dose"
                                                v-if="item == 'dose'" v-model="form.chart_form.dose" />
                                            <select v-tooltip="'Route'" @change="selectRoute()" v-if="item == 'route'"
                                                id="route-type" class="chart-form-select"
                                                style="font-size: 15px; height: 30px; border-radius: 4px; padding: 5px 10px; background-color: #F3F4F4; ">
                                                <option value="" disabled selected>Route</option>
                                                <option v-for="(item, index) in administrationTypes" :key="index">
                                                    {{ item }}</option>
                                            </select>
                                            <InputText v-tooltip="'Hourly Frequency'" placeholder="Hourly Frequency"
                                                v-if="item == 'hourly_frequency'"
                                                v-model="form.chart_form.hourly_frequency" />
                                            <InputText v-tooltip="'Max Dose in 24hrs'" placeholder="Max Dose in 24hrs"
                                                v-if="item == 'max_dose'" v-model="form.chart_form.max_dose" />
                                            <div class="toggle-switch" style="margin: 0 px" v-if="item == 'packed'"
                                                :style="{ border: '2px solid' + selectedChart.color }">
                                                <p class="toggle-switch-item" style="border-right: 2px solid"
                                                    :style="packed == 'packed' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="packed = 'packed'">Packed</p>
                                                <p class="toggle-switch-item"
                                                    :style="packed == 'non-packed' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="packed = 'non-packed'">Non-Packed</p>
                                            </div>
                                            <div class="toggle-switch" style="margin: 0 5px"
                                                v-if="item == 'now_or_next_pack'"
                                                :style="{ border: '2px solid' + selectedChart.color }">
                                                <p class="toggle-switch-item" style="border-right: 2px solid"
                                                    :style="now_or_next_pack == 'now' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="now_or_next_pack = 'now'">Now</p>
                                                <p class="toggle-switch-item"
                                                    :style="now_or_next_pack == 'next-pack' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="now_or_next_pack = 'next-pack'">Next Pack</p>
                                            </div>

                                            <!-- <TreeSelect v-if="item == 'frequency'" v-model="form.chart_form.frequency" :options="frequencyTypes" placeholder="Frequency"></TreeSelect> -->
                                            <!-- FREQUENCY -->
                                            <!-- FREQUENCY -->
                                            <!-- FREQUENCY -->
                                            <select v-tooltip="'Frequency'" @change="selectFrequency()"
                                                v-if="item == 'frequency'" id="frequency-type" class="chart-form-select"
                                                style="font-size: 15px; height: 30px; border-radius: 4px; padding: 5px 10px; background-color: #F3F4F4; ">
                                                <option value="" disabled selected>Frequency</option>
                                                <option v-for="(item, index) in frequencyTypes" :key="index">{{ item }}
                                                </option>
                                            </select>
                                            <!-- FREQUENCY -->
                                            <!-- FREQUENCY -->
                                            <!-- FREQUENCY -->
                                            <InputText v-tooltip="'Indication'" style="min-width: 650px; width: 100%;  "
                                                placeholder="Indication" v-if="item == 'indication'"
                                                v-model="form.chart_form.indication" />

                                            <Calendar v-tooltip="'Prescription Date'" id="presdateformat"
                                                v-if="item == 'prescription_date'"
                                                v-model="prescription.prescription_date" dateFormat="dd/mm/yy"
                                                placeholder="Prescription Date" />
                                            <Calendar v-tooltip="'Start Date'" id="startdateformat"
                                                v-if="item == 'start_date'" v-model="prescription.start_date"
                                                dateFormat="dd/mm/yy" placeholder="Start Date" />
                                            <Calendar v-tooltip="'Stop Date'" id="stopdateformat"
                                                v-if="item == 'stop_date'" v-model="prescription.stop_date"
                                                dateFormat="dd/mm/yy" placeholder="Stop Date" />

                                            <Calendar v-tooltip="'Date and Time'" id="time24" placeholder="Date & Time"
                                                v-if="item == 'datetime'" v-model="form.chart_form.start_date"
                                                :showTime="true" :showSeconds="false" />
                                            <div class="toggle-switch" style="margin: 0 5px; min-width: 240px"
                                                v-if="item == 'substitution'"
                                                :style="{ border: '2px solid' + selectedChart.color }">
                                                <p class="toggle-switch-item" style="width: 100%"
                                                    :style="substitution ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                                    @click="substitution = !substitution">Brand Substitution Allowed</p>

                                            </div>
                                        </div>
                                        <div class="chart-row-item" style="width: fit-content"
                                            v-if="form.chart_form.frequency">
                                            <input type="time" v-tooltip="'Time 1'" placeholder="Time 1"
                                                v-if="form.chart_form.frequency" v-model="time1" />
                                        </div>
                                        <div class="chart-row-item" style="width: fit-content"
                                            v-if="form.chart_form.frequency && (form.chart_form.frequency == 'BD' || form.chart_form.frequency == 'TDS' || form.chart_form.frequency == 'QiD')">
                                            <input type="time" v-tooltip="'Time 2'" placeholder="Time 2"
                                                v-if="form.chart_form.frequency" v-model="time2" />
                                        </div>
                                        <div class="chart-row-item" style="width: fit-content"
                                            v-if="form.chart_form.frequency && form.chart_form.frequency && (form.chart_form.frequency == 'TDS' || form.chart_form.frequency == 'QiD')">
                                            <input type="time" v-tooltip="'Time 3'" placeholder="Time 3"
                                                v-if="form.chart_form.frequency" v-model="time3" />
                                        </div>
                                        <div class="chart-row-item" style="width: fit-content"
                                            v-if="form.chart_form.frequency && (form.chart_form.frequency == 'QiD')">
                                            <input type="time" v-tooltip="'Time 4'" placeholder="Time 4"
                                                v-if="form.chart_form.frequency" v-model="time4" />
                                        </div>
                                        <div class="chart-row-item" style="width: fit-content">
                                            <Textarea v-tooltip="'Comments/Instructions'"
                                                style="min-width: 650px; width: 100%; min-height: 100%; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                                                placeholder="Comments/Instructions"
                                                v-if="selectedChart.inputs.includes('comments')" :autoResize="true"
                                                v-model="form.chart_form.comments" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="approval-container">
                            <div class="valid-prescription-notes" style='margin-top: 0; width: 400px;'
                                v-if="selectedChart">
                                <div>
                                    <h4>Valid Prescription Notes</h4>
                                    <div>
                                        <p>Streamlined Authority Code</p>
                                        <InputText style="width: 100%; background-color: #F3F4F4"
                                            placeholder="Enter code here..." v-model="prescription.authority_code" />
                                    </div>
                                    <div class="prescriber-options-container">
                                        <div class="select-filters-container prescriber-options-item"
                                            style="border-radius: 10px">
                                            <p @click="() => { pbs = !pbs }"
                                                v-bind:class="[pbs == true ? 'active-select-filter' : '']"
                                                class="select-filter-item">PBS</p>
                                        </div>
                                        <div class="select-filters-container prescriber-options-item"
                                            style="border-radius: 10px">
                                            <p @click="() => { rpbs = !rpbs }"
                                                v-bind:class="[rpbs ? 'active-select-filter' : '']"
                                                class="select-filter-item">RPBS</p>
                                        </div>
                                        <div class="select-filters-container prescriber-options-item"
                                            style="border-radius: 10px">
                                            <p @click="() => { ctg = !ctg }"
                                                v-bind:class="[ctg ? 'active-select-filter' : '']"
                                                class="select-filter-item">CTG</p>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div v-if="selectedChart">
                                <h2 style="text-align: left; margin-left: 20px;">Approval</h2>
                                <!-- <SingleVerify />
                        <DualVerify /> -->
                                <div v-if="selectedChart.prescriber" class="valid-prescription-notes sign-off-item">
                                    <h4>Prescriber Sign Off</h4>
                                    <SingleVerify />
                                </div>
                                <div v-if="selectedChart.nurse" class="valid-prescription-notes sign-off-item">
                                    <h4>Nurse Sign Off</h4>
                                    <SingleVerify />
                                </div>
                                <div v-if="selectedChart.sign1" class="valid-prescription-notes sign-off-item">
                                    <h4>Signature 1</h4>
                                    <SingleVerify />
                                </div>
                                <div v-if="selectedChart.sign2" class="valid-prescription-notes sign-off-item">
                                    <h4>Signature 2</h4>
                                    <SingleVerify />
                                </div>
                            </div>
                            <div class="chart-approval" v-if="selectedChart">
                                <button class="complete-button" @click="createChart()"
                                    style="font-weight: bold; width:150px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);">Create
                                    Chart</button>
                            </div>
                        </div>
                    </div>
                    <!-- END OF CHART -->


                </div>

            </div>
        </div>
        <!-- PATIENT INFORMATION SUMMARY  -->
        <div>
            <div style="display: flex; align-items: flex-end">
                <router-link :to="'/patient/' + form.patient_id" style="text-decoration: none;">
                    <i class="pi-angle-left pi"
                        style="background-color: white; border-top-left-radius: 8px; border-top-right-radius: 8px;
                       display: inline; width: fit-content; margin: 0; padding: 5px; font-size: 1.5em !important; margin-right: 5px;"></i>
                </router-link>

                <h3 style="text-align: left; margin: 0;">Patient Information</h3>
            </div>
            <div class='valid-prescription-notes' style="margin-top: 0; border-top-left-radius: 0px;">
                <div class="general-patient">
                    <img :src="patient.general_info.image" alt="patient-image"
                        style="max-height: 165px; box-shadow: 0 0 5px rgba(0,0,0,0.05)">
                    <h3 class="general-patient-name">{{ patient.general_info.name }}</h3>
                </div>
                <!-- ADR -->
                <div class="adr-patient-header-container" style="background-color: #F7B9B0 !important; margin-top: 1em">
                    <div class="adr-patient-header"
                        style="background-color: #E04F39 !important; border-radius: 12px; align-items: center">
                        <h3 class="adr-patient-header-key">ADR</h3>
                        <h3 class="adr-patient-header-value" style="margin: 3px;">{{ patient.adr.length ? 'YES' : 'NO' }}
                        </h3>
                    </div>
                    <div
                        style="overflow-y: scroll; height: 50px; width: 15em; display: flex; flex-wrap: wrap; padding: 5px 10px;">
                        <span style="display: inline-block; margin-right: 10px;" v-for="(item, index) in patient.adr"
                            :key="index">
                            {{ item.drug }}{{ (index + 1) != patient.adr.length ? ',' : '' }}
                        </span>
                    </div>
                </div>
                <!-- ADR -->
                <!-- SPEC CONSIDERATIONS -->
                <div style="border: 2px solid #E04F39; border-radius: 8px; padding-top: 5px; margin-top: 10px;">
                    <div
                        style="padding: 0; margin: 0; text-align: left; padding-left:5px; 
                            width: 15em;flex-wrap: wrap; display: flex; max-height: 80px; min-height: 80px; overflow-y: scroll">
                        <span class="consideration-tag" v-for="(item, index) in patient.considerations.tags"
                            :key="index">{{ item }}</span>
                    </div>
                    <div>
                        <p style="font-weight: 500; text-align: left; padding-left: 5px;">Details:</p>
                        <div style="height: 60px; overflow-y: scroll; width: 15em; ">
                            <p style="text-align: left; padding-left: 5px; margin: 0;">
                                {{ patient.considerations.details }}</p>
                        </div>
                    </div>
                </div>
                <!-- SPEC CONSIDERATIONS -->
                <!-- COMPLEX MEDS -->
                <div></div>
                <!-- COMPLEX MEDS -->
            </div>

        </div>
    </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
// import DualVerify from '@/components/verification/DualVerify.vue';
import SingleVerify from '../../../components/verification/SingleVerify.vue';

export default {
    components: {
        SingleVerify,
        // DualVerify
    },
    data() {
        return {
            time1: '',
            time2: '',
            time3: '',
            time4: '',
            expandModal: false,
            tableData: null,
            prescription: {
                prescription_date: '',
                tags: [],
                authority_code: ''
            },
            single_multi: '',
            packed: '',
            now_or_next_pack: '',
            substitution: false,
            drugList: [],
            searchedDrugName: '',
            clearDrug: false,
            pbs: false,
            rpbs: false,
            ctg: false,
            formTypes: [
                'Capp',
                'Cream',
                'Eye drops',
                'Ear drops',
                'Ear ointment',
                'Eye ointment',
                'Injection',
                'MDI',
                'Mixture',
                'Ointment',
                'Pessary',
                'Powder',
                'Suppository',
                'Tablet',
                'PCA',

            ],
            administrationTypes: [
                'PO',
                'PR',
                'Topical',
                'Subcut',
                'Subling',
                'NG',
                'PEG',
                'IM',
                'IV',
                'Epidural',
                'Inhale/Inhalation',
                'Intraarticular (joint)',
                'Intrathecal (spine)',
                'Intranasal (nose)',
                'Irrigation',
                'NEB',
                'PV',
                'PICC'
            ],
            frequencyTypes: [
                //   {"key":"0", "label": "Mane", "data": "Mane"},
                //   {"key":"0", "label": "Nocte", "data": "Nocte"},
                //   {"key":"0", "label": "Daily", "data": "Daily"},
                //   {"key":"0", "label": "BD", "data": "BD"},
                //   {"key":"0", "label": "TDS", "data": "TDS"},
                //   {"key":"0", "label": "QiD", "data": "QiD"},
                //   {"key":"0", "label": "PRN", "data": "PRN"},
                'Mane',
                'Nocte',
                'Daily',
                'BD',
                'TDS',
                'QiD',
            ],
            charts: [
                {
                    name: 'Regular Medicine Order',
                    inputs: [
                        'single_multi',
                        'dose',
                        'route',
                        'packed',
                        'reason',
                        'substitution',
                        'indication',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'frequency',
                        'comments',
                    ],
                    subtext: '',
                    color: '#9F5CC0',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'PRN Medicine',
                    inputs: [
                        'reason',
                        'max-dose',
                        'dose',
                        'route',
                        'hourly_frequency',
                        'max_dose',
                        'substitution',
                        'packed',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'comments',
                    ],
                    subtext: '',
                    color: '#00B2A2',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Phone Order',
                    inputs: [
                        'reason',
                        'dose',
                        'route',
                        'frequency',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'comments',
                    ],
                    subtext: '',
                    color: '#2E97DE',
                    prescriber: true,
                    sign1: true,
                    sign2: true,
                },
                {
                    name: 'Short Term Medicine',
                    inputs: [
                        'now_or_next_pack',
                        'dose',
                        'route',
                        'packed',
                        'frequency',
                        'indication',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'reason',
                        'substitution',
                        'comments',

                    ],
                    subtext: '(antibiotics)',
                    color: '#D6001C',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Once Only Meds (Stat)',
                    inputs: [
                        'datetime'
                    ],
                    subtext: '',
                    color: '#8E0018',
                    prescriber: true,
                    sign1: true,
                    sign2: false,
                },
                {
                    name: 'Intermittent Medicine',
                    inputs: [
                        'frequency',
                        'start_date'
                    ],
                    subtext: '',
                    color: '#5B00AA',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Variable Dose Meds',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        'packed',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'comments',
                    ],
                    subtext: '(not insulin)',
                    color: '#00AD50',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Nurse Initiated Meds',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        'reason',
                        'start_date',
                        'comments'
                    ],
                    subtext: '(non-prescription)',
                    color: '#274188',
                    prescriber: false,
                    sign1: false,
                    sign2: false,
                    nurse: true,
                },
                {
                    name: 'Non Prescription Meds',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        'reason',
                        'start_date',
                        'comments',
                    ],
                    subtext: '(patient initiated)',
                    color: '#EF9600',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Nutritional Supplement',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        'reason',
                        'start_date',
                        'stop_date',
                        'substitution',
                        'packed',
                        'comments',
                    ],
                    subtext: '',
                    color: '#E56DB1',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
            ],
            selectedChart: '',
            registerData: [],
            patient: {
                general_info: {
                    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU',
                    name: 'Sally Smith',
                    preferred_name: 'Sally',
                    gender: 'Female',
                    dob: '10/01/1930',
                    age: 88,
                    room: '10A',
                    building: 'Lower Left Building',
                    chart_commenced: '10/02/2021',
                    expiry_date: '10/10/2022',
                    ihi: '2340234293423423',
                    rac_id: 'L93939393',
                    urn_mrn: 'kkdsfkskdfksdf'
                },
                adr: [
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    },
                    {
                        drug: 'Endone',
                        reaction: 'Rashes',
                        date: '10/02/2020',
                        type: 'Drug',
                    }

                ],
                considerations: {
                    tags: [
                        'Cognitive Impairment',
                        'Dizziness',
                        'Alzheimers',
                        'Cognitive Impairment',
                        'Dizziness',
                        'Alzheimers',
                        'Cognitive Impairment',
                        'Dizziness',
                        'Alzheimers',
                        'Cognitive Impairment',
                        'Dizziness',
                        'Alzheimers'
                    ],
                    details: 'Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth.'
                },
            },
            form: {
                patient_id: null,
                location: this.$store.state.uuid,
                chart_form: {
                    chart_type: '',
                    drug_uuid: '',
                    // form:'',
                    dose: null,
                    // strength:'',
                    route: '',
                    frequency: '',
                    indication: '',
                    comments: '',
                    start_date: '',
                    stop_date: '',
                    tags: [],
                    reason: '',
                    hourly_frequency: '',
                    max_dose: '',
                    times: []
                }
            },
        }
    },
    methods: {
        searchDrugs() {
            axios.post('/get-drug-aliases', { drug: this.searchedDrugName }, this.$store.state.header).then(res => {
                console.log('this is the res data', res.data);
                this.drugList = res.data.drug_aliases;

            })
        },
        drugSelect(item) {
            this.form.chart_form.drug_uuid = item.alias_id;
            this.form.chart_form.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.form.chart_form.strength = `${item.strength} ${item.form_description}`;
            this.drugList = [];
            this.searchedDrugName = item.name;
            this.clearDrug = true;
        },
        removeEmptyItems() {
            let allItems = document.getElementsByClassName('chart-row-item');
            setTimeout(() => {
                for (let item of allItems) {
                    if (!item.children.length) {
                        console.log('this is what is inside each item', item.children);
                        item.style.display = 'none';
                    } else {
                        item.style.display = 'flex';
                    }
                }
            }, 20);
        },
        selectForm() {
            this.form.chart_form.form = document.getElementById('form-type').value;
            console.log('this is the selected form', this.form.chart_form.form);
        },
        selectDose() {
            this.form.chart_form.dose = document.getElementById('dose-type').value;
            console.log('this is the selected dose', this.form.chart_form.dose);
        },
        selectRoute() {
            this.form.chart_form.route = document.getElementById('route-type').value;
            console.log('this is the selected route', this.form.chart_form.route);
        },
        selectFrequency() {
            this.form.chart_form.frequency = document.getElementById('frequency-type').value;
            console.log('this is the selected frequency', this.form.chart_form.frequency);
        },
        createChart() {
            console.log('chart is being created');
            if (this.single_multi) {
                this.form.chart_form.tags.push(this.single_multi);
            }
            if (this.packed) {
                this.form.chart_form.tags.push(this.packed);
            }
            if (this.now_or_next_pack) {
                this.form.chart_form.tags.push(this.now_or_next_pack);
            }
            if (!this.form.chart_form.stop_date) {
                this.form.chart_form.stop_date = this.form.chart_form.start_date;
            }
            if (this.pbs) {
                this.prescription.tags.push('pbs');
            }
            if (this.ctg) {
                this.prescription.tags.push('ctg');
            }
            if (this.rpbs) {
                this.prescription.tags.push('rpbs');
            }
            if (this.form.chart_form.frequency) {
                this.form.chart_form.times.push(parseInt(this.time1.slice(0, 2) + this.time1.slice(3, 5)))
            }
            if (this.form.chart_form.frequency == 'BD' || this.form.chart_form.frequency == 'TDS' || this.form.chart_form.frequency == 'QiD') {
                this.form.chart_form.times.push(parseInt(this.time2.slice(0, 2) + this.time2.slice(3, 5)))
            }
            if (this.form.chart_form.frequency == 'TDS' || this.form.chart_form.frequency == 'QiD') {
                this.form.chart_form.times.push(parseInt(this.time3.slice(0, 2) + this.time3.slice(3, 5)))
            }
            if (this.form.chart_form.frequency == 'QiD') {
                this.form.chart_form.times.push(parseInt(this.time4.slice(0, 2) + this.time4.slice(3, 5)))
            }
        }
    },
    mounted() {
        console.log('this is the route param', this.$route.params.id);
        this.form.patient_id = this.$route.params.id;
        //    axios.post('/retrieve-patient', this.form).then(res=>{
        //        this.patient = res.data;
        //        console.log('this is the res data for retrieve patient', res.data)
        //    })
    },
    computed: {
        getPatients() {
            return this.$store.getters.getPatients;
        },
        getPrimary() {
            return this.patient.prescribers.filter(item => item.primary_gp)
        },
        getPrescribers() {
            return this.patient.prescribers.filter(item => !item.primary_gp)
        }
    }
}
</script>

<style lang="scss">
@import "../../../assets/css/main.scss";

.sign-off-item {
    h4 {
        margin-bottom: 0;
        text-align: left;
    }

    button {
        width: 100%;
    }
}

.approval-container {
    display: flex;
    flex-direction: column;
    margin-left: 3em;

    h2 {
        margin-bottom: 0;
    }
}

.chart-approval {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;

}

.valid-prescription-notes {
    margin-top: 10px;
    background-color: white;
    padding: 20px;
    border-radius: 12px;

    h4 {
        margin-top: 0;
    }

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}

.prescriber-options-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px;
    justify-content: space-between;
}

.prescriber-options-item {
    border: 2px solid $strongblue;
    width: fit-content;
    margin: 0;

    p {
        width: fit-content !important;
        margin: 0;
        border-radius: 6px;
        padding: 5px 25px;
        font-weight: bold;
        background-color: white;
    }

}

.chart-form-select {
    background-color: white !important;
    border: none !important;
    width: 90%;
    min-width: 10em;
}

.toggle-switch {
    // padding: 5px 10px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    align-self: center;
    margin: 5px;
}

.toggle-switch-item {
    font-weight: bold;
    width: 115px;
    margin: 0;
    padding: 5px;
    cursor: pointer;
}

.chart-form-container {
    h4 {
        color: white;
        font-size: 1.3em;
        margin: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 10px 25px;
        height: 43px;
    }

    border-radius: 12px;
    min-width: 681px;
}

.first-chart-row {
    height: 107px;
}

.chart-row {
    display: flex;
    width: 100%;
}

.first-chart-row-item {
    border-top: none !important;
    display: flex;
    align-items: center !important;

    input::placeholder {
        color: black !important;
        font-weight: 700 !important;
    }

    input {
        width: 100%;
    }
}


.chart-row-item {
    border-right: 1px solid $bordergrey;
    border-top: 1px solid $bordergrey;
    display: flex;
    width: 100%;
    flex: 1;
    min-height: 60px;
    align-items: flex-end;

    // justify-content: center;
    input {
        min-width: 150px;
        background-color: white;
        margin: 0 10px;
    }

    input::placeholder {
        color: #101820;
        font-weight: 300;
    }
}

.chart-row-item:last-child {
    border-right: none !important;
}

.chart-form {
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.create-chart-container {
    margin-top: 4em;
}

.chart-form-approval-container {
    display: flex;
}

.charts-menu-container {
    display: flex;
    flex-wrap: wrap;
}

.charts-menu-item {
    color: white;
    width: fit-content;
    min-width: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 10px;
    height: 50px;
    padding: 5px 35px;
    border-radius: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

    h4,
    p {
        margin: 0;
    }

    h4 {
        font-size: 0.9em;
    }
}
</style>
