<template>
        <Dropdown v-model="stock" ref="med" optionLabel="full_name" @change="drugSelect" @keyup="debounceSearchDrugs(stock), show()"
            :loading="loadingDrugs" :options="drugList" :editable="true" optionDisabled="disabled"
            style="width: 100%; display: flex;" placeholder="Type medication name here">
            <!-- dropdown view -->
            <template #option="slotProps">
                <div
                style="display: grid;grid-template-rows: auto auto; width: 400px; margin: 0.5rem 1rem; gap: 10px;" class="ingredient-item">
                    <div style="display: grid; grid-template-columns: 75% 25%;grid-template-rows: max-content;grid-gap:4px;align-items: start;">
                        <div style="text-wrap: wrap;padding-bottom: 5px;">
                            <p style="margin: auto; text-align: left;font-weight: bold;"><Tag v-if="slotProps.option.bag_id" style="background-color: orange">{{ 'PATIENT OWNED' }}</Tag> {{ slotProps.option.alias.name }}
                            </p>
                        </div>
                        <div style="display: grid; align-items: end;">
                            <Tag rounded>Stock: {{ slotProps.option.qty_left }}</Tag>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 80% 20%;grid-template-rows: max-content;grid-gap:8px">
                        <div style="text-wrap: wrap;">
                            <p style="font-weight: 400; font-size:smaller; margin: 0px !important;"> Ingredients: <i>{{ strengthFormat(slotProps.option.alias.strength) }}</i></p>
                        </div>
                    </div>
                </div>
            </template>
        </Dropdown>
</template>

<script>
import axios from 'axios';
import { toRaw } from 'vue';
export default {
    props: ['optionalLocation', 'optionalPatient'],
    data() {
        return {
            stock: "",
            loadingDrugs: false,
            drugList: [],
            selectedDrug: ""
        }
    },
    methods: {
        show() {
            this.$refs.med.show();
        },
        hide() {
            this.$refs.med.hide();
            console.log("hidden")
        },
        drugSelect(item) {
            item = item.value
            console.log('this is the drug item selected', item);
            const drugFormatted = {
            full_item : item,
            drug_uuid : item.alias.alias_id,
            full_name : item.full_name,
            form : item.alias.form[0].toUpperCase() + item.alias.form.slice(1),
            strength : `${item.alias.strength}`,
            measurement_unit : `${item.alias.measurement_unit}`,
            name : item.alias.name[0].toUpperCase() + item.alias.name.slice(1),
            tags : item.alias.tags,
            patient_owned: item.bag_id ? true : false,
            quantity: item.qty_left,
            }

            this.$emit('drugselected', drugFormatted)

        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        searchDrugs() {
            // hide dropdown to prevent user from selecting a med that is about to to be able to referenced (object error)
            this.hide()
            let currentHeader = this.$store.getters.getHeader
            let currentHeaderLocation = currentHeader.headers.Location
            // check to see if optional location header has been used
            if(this.optionalLocation) { 
                console.log("Optional Location")
                this.$store.commit('setHeaderLocation', this.optionalLocation);
            }
            if (this.stock) {
                this.loadingDrugs = true;
                axios.post('/get-stock-items', { drug: this.stock, patient_id: this.optionalPatient?.uuid? this.optionalPatient?.uuid : null }, this.$store.state.header).then(res => {
                    console.log('this is the res data getstockitems', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.$store.commit('setHeaderLocation', currentHeaderLocation);
                    res.data.forEach(item => {
                        item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                        if (item.qty_left == 0) {
                            item.disabled = true;
                        } else {
                            item.disabled = false;
                        }
                    })
                    this.drugList = res.data;
                    this.errorMessage = '';
                    this.loadingDrugs = false;
                    // show dropdown
                    this.show()
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                    console.log('this is the transfer stock items error', err.response.data);
                    this.errorMessage = err.response.data;
                })

            }
        },
        strengthFormat(strengthArray) {
            strengthArray = toRaw(strengthArray)
            console.log(strengthArray)
            let strengthString = ""
            let currentStrengthentry = 1
            for (const strengthentry of strengthArray) {
                const strengthOfEntry = (strengthentry.strength).toString()
                const measurement_unitOfEntry = strengthentry.measurement_unit
                const measureandunit = strengthOfEntry.concat(measurement_unitOfEntry)
                const name = strengthentry.name
                const single = name.concat(" ", measureandunit)
                strengthString = strengthString.concat(single)
                if (currentStrengthentry != strengthArray.length) {
                    strengthString = strengthString + ", "
                }
                currentStrengthentry++
            }
            return strengthString

        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
li:has(> .ingredient-item){
    padding: 0 !important;
    border-bottom: 1px solid rgb(202, 202, 202) !important;
    margin: 0 2px !important;
}
</style>