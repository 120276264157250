<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 50%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Receive Patient Owned Medications </h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div  class="form-section-container">
                                <div class="left-form-section-panel">
                                    <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                        <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                        <textarea name="notes" id="note-modal" rows="16"
                                        placeholder=" Enter notes here..."
                                        style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                        v-model="this.form.submit.notes"></textarea>
                                    </div>
                                </div>
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">

                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p
                                                v-if='patient.regulatory_information && patient.regulatory_information.length'>
                                                <b>{{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].regulatory_type : ''
                                                }}:</b>
                                                {{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].data : ''
                                                }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information &&
                                                    patient.personal_information.dob ? patient.personal_information.dob :
                                                    'N/A'
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>

                                    <div v-if="!imprest_pom">
                                        <!-- <div class="right-form-section-drug-entry"> -->
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Search Patient</p>
                                            <!-- </div> -->
                                        </div>
                                    </div>

                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <div v-if="patient" class="right-form-section-drug-entry" style="gap: 10px;">
                                        <div style="display: flex; align-items: center; width: 100%;" >
                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="searchDrugs(stock), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                            style="width: 25em; display: flex" placeholder="Type medication name here"/> -->
                                            <div style="position: relative; width: 100%;">
                                                <input v-model="myDrugs" v-on:keyup="myDrugSearch(myDrugs)" placeholder="Type medication name here" style="position: relative; width: 100%; background-color:  rgb(229, 241, 254);" />
                                                <div class="drug-search-result-container" v-if="drugResults.length > 0" style="position: absolute; background-color: white; width: 30em; border: 2px solid #f6f6f6; z-index:1">
                                                    <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer" v-for="(item, index) in drugResults" :key="index" @click="drugSelect(item)">
                                                        {{ `(${item.generic_name}) ${item.name}` }}
                                                    </p>
                                                </div>
                                            
                                            </div>
                                        </div>
                                        <input v-model="qty" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 25%" />
                                        <p class="submit-button blue-button" style="padding: 8px 20px;"
                                            @click="() => { if (qty) { checkIfExistingItem(selectedDrug.full_item) } }">Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>
                                   

                                    <div class="right-form-section-drug-container">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock.slice(0, 1).toUpperCase() + item.stock.slice(1) }}</p>
                                                </td>

                                                <td>
                                                    <p>
                                                        <!-- IF ENTERED VALUE EXCEEDS STOCK QTY -->
                                                        <s v-if="item.qty != item.entered_qty"
                                                            style="">{{ item.entered_qty }}</s>
                                                        <span
                                                            :style="[item.entered_qty != item.qty ? { color: 'red' } : '']">&nbsp;
                                                            {{ item.qty }}</span> {{ item.qty != 'None in stock.' ?
                                                                    item.form : ''
                                                            }}{{ item.qty > 1 ? 's' : '' }} {{ transferType
        == 'Send' ? (item.qty_left ? '(Stock qty:' + item.qty_left + ')' :
            '(Stock qty: 0)') : ''
}}
                                                    </p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style='padding: 0'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>


                            </div>



                        </form>
                    </slot>
                </div>
                <p style="color: red;">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <!-- submitTransferAction() -->
                        <p class="regular-button complete-button" style="margin-right: 10px;"
                            @click="submitReceive()">Receive</p>
                        <!-- <p class="regular-button complete-button" @click="submitTransferAndCreateNew()">{{ transferType }}
                            Transfer + New</p> -->
                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <!-- <SignatureModal v-if="displaySignatureModal" @close="close" @isSuccessful="isSuccessful"
            :saveContext="saveContext" :payload='realSubmit' :endpoint="patient ? '/receive-pom/'+patient.uuid : ''" :dual="dual" /> -->
            <SignatureModal v-if="displaySignatureModal" @close="close" @isSuccessful="isSuccessful"
            :saveContext="saveContext" :payload='realSubmit' :endpoint="patient ? '/recieve-pom' : ''" :dual="dual" />
    </div>
</template>

<script>
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import axios from 'axios';

export default {
    props: ['transferType'],
    components: {
        SignatureModal,
        PatientSearchModal
    },
    data() {
        return {
            selectedDrug:{},
            notes: "",
            loadingDrugs:false,
            drugList:[],
            dual: true,
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            noStock: false,
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            drugResults: [],
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            qty: null,
            location: '',
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: '',
            myDrugs:'',
            form: {
                submit: {
                    type: '',
                    to_from: '',
                    drugs: [],
                    notes: '',
                    patient_id: '',
                    location_id: this.$store.state.uuid
                }
            },
            realSubmit: {
                type: '',
                to_from: '',
                drugs: [],
                notes: '',
                patient_id: '',
                location_id: this.$store.state.uuid
            },
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'Transfer',
                patient: '',
                location: '',
                drugs: [],
                doubleSig: false
            }
        }
    },
    methods: {
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        drugSelect(item) {
            this.loadingDrugs = true;
            this.drugResults = [];
            this.myDrugs = `(${item.generic_name}) ${item.name}`;
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = item.strength;
            this.selectedDrug.measurement_unit = item.measurement_unit;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
        },
        myDrugSearch(myDrugs){
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }

            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`, { params: `query=${myDrugs}&hitsPerPage=5`},
                { headers }).then(res => {
                    // console.log('this is the algolia res', res.data.hits);
                    this.drugResults = res.data.hits;
                }
            )
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                 axios.post('/get-drug-aliases', { drug: this.stock }, this.$store.state.header).then(res => {
                        console.log('this is the res data', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.drug_aliases.forEach(item=>{
                            item.full_name = `(${item.generic_name}) ${item.name}`
                        })
                        this.drugList = res.data.drug_aliases;
                        this.errorMessage = '';
                        this.loadingDrugs = false;
                    }).catch(err => {
                        this.errorMessage = err.response.data;
                        console.log('err', err.response.data);
                    })
            }
        },
        checkIfExistingItem(item){
            let exit = false;
            if(this.form.submit.drugs.length){
                this.form.submit.drugs.forEach(drugItem => {
                    console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } else if(!exit){
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else{
                this.aliasSelect(item);
            }
        },
        changeStock() {
            this.stock = this.patientMeds[document.getElementById('pom-select').value];
            this.qty = Number(this.stock.quantity).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            // { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        isSuccessful(value) {
            if (value) {
                console.log('IS SUCCESSFUL!');
                if (this.$route.name == 'Stock') {
                    console.log('loading the stock stuff');
                } 
                if (this.addNew) {
                    this.form.submit.drugs = [];
                    this.stock = '';
                    this.imprest_pom = '';
                    this.patient = '';
                    this.patient_uuid = '';
                    this.prescriber_uuid = '';
                    this.selectedPrescriber = '';
                    this.refinedDrugs = '';
                } else {
                    this.closeModal();
                }
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                // this.dual = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);
            
            this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: item.qty_left });
            
            this.qty = null;
            this.myDrugs = '';
            this.refinedDrugs = [];
            this.noStock = false;
        },
        checkAndAddStock(drugName) {
                axios.post('/get-drug-aliases', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.drug_aliases;
                    this.errorMessage = '';
                }).catch(err => {
                    this.errorMessage = err.response.data;
                    console.log('err', err.response.data);
                })
            


        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        submitReceive() {
            let newDrugSub = [];
            let autoDrugs = [];
            this.addNew = false;
            console.log('HERE ARE THE SUBMIT RECEIVE DRUGS', this.form.submit.drugs);
            this.form.submit.drugs.forEach(item => {
                let p = {
                    drug: item.drug,
                    qty: Number(item.qty)
                }
                let ad = {
                    drug: item.stock,
                    qty: Number(item.qty)
                }
                autoDrugs.push(ad);
                // if(item.tags && item.tags.length){
                //     this.dual = true;
                // }

                if (item.qty > 0) {
                    newDrugSub.push(p);
                }
            });
            this.realSubmit = {
                drugs: this.form.submit.drugs,
                notes: this.form.submit.notes,
                location_id: this.$store.state.uuid,
                patient_id: this.patient.uuid
            };
            this.saveContext = {
                drugs: autoDrugs,
                location: JSON.parse(localStorage.getItem('currentLocation')).name,
                patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : '',
                supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                doubleSig: true,
            }

            this.realSubmit.drugs = newDrugSub;
            console.log('this is the form to submit', this.form, this.saveContext);
            console.log(this.form.submit.drugs.length && this.form.submit.type)
            if (this.form.submit.drugs.length && this.patient) {
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drugs and a patient are required.'
            }

        },
        // selectPrescriber(prescriber) {
        //     console.log('this is the selected result', this.nonGP);
        //     this.prescriberResults = [];
        //     this.selectedPrescriber = {
        //         prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
        //         uuid: prescriber.uuid,
        //         first_name: prescriber.first_name,
        //         last_name: prescriber.last_name,
        //         phone: prescriber.phone,
        //         after_hours: prescriber.after_hours,
        //         address: prescriber.address,
        //         email: prescriber.email,
        //         fax: prescriber.fax,
        //         primary_gp: false
        //     }
        // },
        // changePrescriber() {
        //     this.selectedPrescriber = '';
        //     this.gp = '';
        // },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                this.realSubmit.patient_id = this.patient.uuid;
                // {uuid: this.patient.uuid}

                // axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                //     console.log('this is the patient register', res);
                //     this.patientMeds = res.data;
                // })
            }
        },
        // submitReceive() {
        //     this.realSubmit = {
        //         drugs: this.form.submit.drugs,
        //         notes: this.form.submit.notes,
        //         // prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
        //         patient_uuid: this.patient ? this.patient.uuid : '',
        //     };
        //     if (this.form.submit.drugs.length && this.form.submit.type && this.form.submit.to_from) {
        //         this.addNew = true;
        //         this.form.submit.type = this.transferType;
        //         console.log('this is the form to submit', this.form);
        //         this.form.submit.drugs.forEach(drug => {
        //             delete drug.qty_left;
        //             drug.qty = Number(drug.qty);
        //         })
        //         // this.$store.dispatch('submitDelivery',this.form);
        //         // this.refinedLocations = [];

        //         this.displaySignatureModal = true;
        //         this.errorMessage = '';
        //     } else {
        //         this.errorMessage = 'Drugs and location are required.'
        //     }
        // },
    },
    mounted() {
        // setting the subdomain - used in e.g. algolia searches
        let myHost = window.location.host;

        if(myHost.includes('hospital') && myHost.includes('staging')){
            this.$store.commit('setSubdomain', 'st-hp')
        } else if(myHost.includes('hospital')){
            this.$store.commit('setSubdomain', 'prod')
        } else{
            this.$store.commit('setSubdomain', 'st-hp')
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}




 

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.left-form-section-panel {
    padding: 0 20px;
    align-content:flex-start;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}

.right-form-section-panel {
    padding: 0 20px;

}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}
</style>
