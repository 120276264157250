<template>
    <div>
        <div style="display: grid; grid-gap:1em; margin-top: 1em;">
            <p style='font-size: 1em; text-align: left; margin: 0;'>Assign alert channels to roles. Multiple alert channels may be
            assigned to a role.</p>
            <p style='font-size: 1em; text-align: left; margin: 0; margin-bottom: 1em; font-weight: bold;'>Selecting an alert channel
            will enable the site notification of that alert.</p>
            <p style="margin: 0; margin-bottom: -10px; font-size: 1em; text-align: left; font-weight: 500">Select Role:</p>
            <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Role" :options="roles" optionLabel="name"
            optionValue="uuid" v-model="assignNotificationChannelPayload.role_id" @change="getRoleChannels(assignNotificationChannelPayload.role_id)" />
            <div v-if='assignNotificationChannelPayload.role_id'>
              <div v-if="alertChannels && alertChannels.length" 
            style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh;">
                <div v-for="(channel, i) in alertChannels" :key="i"
                      style="border-radius: 8px; box-shadow: 0 0 5px #d9d9d9; padding 10px; padding-bottom: 0px; height: fit-content">
                      <div class="p-field-checkbox" style="display: flex">
                          <Checkbox :id="i + '-channel'" :binary="true" v-model="channel.selected" />
                          <label style="padding: 10px;" :for="i + '-channel'">{{ channel.name }}</label>
                      </div>
                  </div>
              </div>
              <div v-else>
                <h4>No alerts channels available yet.</h4>
              </div>
                
            </div>
            <div style='margin-top: 20px;' v-if='assignNotificationChannelPayload.role_id'>
                <p class="green-button" style="width: 15em;" @click="updateRoleChannels()">Update Role Channels
                </p>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            successMessage:'',
            errorMessage:'',
            prePayloadArray:{},
            loadingRoles: false,
            updatedRole: false,
            roles:null,
            alertChannels: null,
            assignNotificationChannelPayload: {},
            filterAgedCareAlerts: ["alert_intermittent_order","alert_approaching_chart_expiry","alert_missed_administration","alert_missed_stocktake"]
        }
    },
    methods: {
    close(value){
            if(value){
                this.displaySuccessModal = false;
            }
        },
    getRoles() {
        this.loadingRoles = true;
        this.updatedRole = false;
        axios.post(`/get-roles`, {}, this.$store.state.header).then(res => {
            this.$store.dispatch('getRefreshToken');
            this.roles = res.data.roles;
            this.loadingRoles = false;
            // PERMISSION ENDPOINT TO BE ADDED, DELETE BELOW WHEN ADDED
            this.getPermissions();
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
            }
            this.loadingRoles = false;
            console.log('this is the roles error', err);
        });

    },
    getChannels() {
      this.alertChannels = [];
      axios.post('/get-alert-channels', {}, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        console.log('THESE ARE THE CHANNELS', res.data);
        console.log('this is the user type', JSON.parse(localStorage.getItem('user')).type);
        this.alertChannels = [];
        res.data.forEach(channel => {
          if(JSON.parse(localStorage.getItem('user')).type != 'prescriber' && JSON.parse(localStorage.getItem('user')).type != 'pharmacy' && JSON.parse(localStorage.getItem('user')).type != 'pharmacist'){
              this.alertChannels.push({
                name: channel,
                selected: false,
                sms: false,
                email: false,
              })
          } else{
            if(channel != "alert_intermittent_order" && channel != "alert_stock_discrepancy" ){
              this.alertChannels.push({
                name: channel,
                selected: false,
                sms: false,
                email: false,
              })
            }
          }
          
        })
         // filter out aged care alerts
         this.alertChannels = this.alertChannels.filter(alertChannel => !this.filterAgedCareAlerts.includes(alertChannel.name))
      })
    },
    getRoleChannels(role_id){
      this.getChannels()
      this.displaySuccessModal = true;
      this.successMessage = 'Getting role channels';
      setTimeout(()=>{
        axios.post(`/role/${role_id}/alert-channels`, {}, this.$store.state.header).then(res=>{
        console.log('these are the role channels', res.data.data);
        let roleChannels = res.data.data;
        if(roleChannels.length){
          roleChannels.forEach(item=>{
            this.alertChannels.forEach(channel=>{
              if(item.name == channel.name){
                channel.selected = true;
              }
            })
          })
        }
        this.displaySuccessModal = false;
      },500)
      
      })
    },
    updateRoleChannels() {
      this.displaySuccessModal = true;
      this.successMessage = 'Submitting...';
      this.assignNotificationChannelPayload.channels = [];
      this.alertChannels.forEach(channel => {
        if (channel.selected) {
          let submitChannel = { ...channel }
          delete submitChannel.selected;
          submitChannel.vertical = 'hospital';
          this.assignNotificationChannelPayload.channels.push(submitChannel);
        }
      })
      console.log('THESE ARE THE SELECTED CHANNELS', this.assignNotificationChannelPayload.channels);
      axios.post('/set-alert-channels', this.assignNotificationChannelPayload, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken'); 
        console.log('CHANNELS HAVE BEEN SUBMITED', res.data);
        this.successMessage = 'Successfully submitted...';
      }).catch(err => {
        // err.response.data.message
        this.successMessage = err.message;
      })
    },
        
    },
    mounted() {
        this.getRoles()
    },
    computed:{
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
