<template>
  <div class="login-view">
    <div class="login-form">
      <div class="login-logo" style="width: fit-content">
        <img src="@/assets/logos/full_logo.png" alt="" style="height:70px;">
      </div>
      <div class="login-form-details">
        <h2>Login to Strong Hospital</h2>
        
        <div class="login-form-fields slide-fade">
        <Transition name="fade" appear>
          <div class="login-form-fields">
            <Dropdown v-if="locationResults.length" v-on:keyup.enter="submitSelectedLocation()"
              v-model="locationSelectForm.location_id" :options="locationResults" optionLabel="name" optionValue="uuid"
              placeholder="Select location" style="margin-left: 5px; width: 20em; margin-bottom: 15px;" />
            <div v-if="locationSelectForm.location_id">
              <p @click="submitSelectedLocation()" class="blue-button"
                style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; ">
                {{loadingLogin ? 'Logging in...':'Select Location'}}</p>
            </div>
            <p v-if="loginError" style="color: red">Warning: {{ loginError }}</p>
          </div>
        </Transition>

      </div>
      </div>
    </div>
    <div class="login-context">

      <div class="login-logo" style="display: grid; justify-content: flex-end">
        <img src="@/assets/logos/short_logo.png" alt="" style="height:70px;">
      </div>
      <h1 style="margin-top: 3em; ">The AI-Driven Drug Management Platform</h1>

      <p style="font-size: 1.4em; font-weight: 500">Powering a secure digital gateway to quickly and safely access
        specialised treatment plans for patients.</p>
    </div>
  </div>
  
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import FirebaseService from "../services/FirebaseService";

export default {
  components: {
  },
  data() {
    return {
      displaySuccessModal: false,
      reloadPageOnClose: true,
      successMessage: '',
      first_spec_line:'',
      forgotPassword: false,
      show: true,
      loadingLogin: false,
      loginCount: 0,
      company: '',
      loginError: '',
      loginForm: {
        // location: this.$store.state.uuid,
        name: '',
        password: '',
        // hardcoding company id as temporary measure
        company_id: ''
      },
      locationSelectForm: {
        temp_access_token: '',
        location_id: ''
      },
      locationError: '',
      userError: '',
      selectLocation: false,
      locationSelected: '',
      locationResults: [],
      companyResults: [],
      temp_token: '',
      codeError: '',
      user: ''
    }
  },
  methods: {
    close(value){
      if(value){
        this.displaySuccessModal = false;
        this.forgotPassword = false;
      }
    },
    setExpiry() {
      let now = new Date();
      let time = now.getTime();
      let expiredTime = time + 60;
      let setTime = now.setTime(expiredTime);
      console.log('now is', setTime);
      document.cookie = "auth=" + "authtoken2342342" + "; max-age=" + 60 + "; path=/";
      console.log('document.cookie is', document.cookie);
    },
    getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        //eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    submitLogin() {
      this.loginError = '';
      this.loadingLogin = true;
      if (!this.loginForm.name || !this.loginForm.password) {
        this.userError = 'Email/password is required.'
      } else {
        this.userError = '';
      }
      if (this.loginForm.name && this.loginForm.password) {
        this.setExpiry();

        axios.post('/login', this.loginForm).then(res => {
          this.loadingLogin = false;
          localStorage.removeItem('loginCount');
          console.log('this is the login res', res.data);
          this.locationSelectForm.temp_access_token = res.data.temp_access_token;
          this.locationResults = res.data.locations;
          localStorage.setItem('locations', JSON.stringify(res.data.locations));
          this.$store.commit('setAvailableLocations', res.data.locations);
          this.$store.commit('setUserType');
        }).then(data => {
          // this.selectLocation = true;
          if (!this.sendPhoneCode && !this.sendMailCode) {
            this.selectLocation = true;
          }
          console.log('this is the data', data);
        }).catch(err => {
          // this.loginError = err;
          this.loadingLogin = false;
          if (err.message.split(" ").includes('403')) {
            // this.loginError = "Incorrect username or password."
            this.loginError = err.response && err.response.data ? err.response.data : "Incorrect username or password.";
            this.loginCount = localStorage.getItem('loginCount') ? Number(JSON.parse(localStorage.getItem('loginCount'))) + 1 : 1;
            localStorage.setItem('loginCount', this.loginCount);
          } else {
            this.loginError = err.response.data;
          }
          console.log('error is', err.response.data);
        })
      }

    },
    submitSelectedLocation() {
      this.selectLocationID();
      this.loginError = '';
      this.loadingLogin = true;
      setTimeout(() => {
        axios.post('/login/location-select', this.locationSelectForm).then(res => {
          console.log('this is the location res', res);
          this.loadingLogin = false;
          let cookie = "auth=" + res.data.access_token + "; max-age=" + 3600 + "; path=/; samesite";
          document.cookie = cookie;
          this.$store.commit('setUser', res.data);
          // this.$store.commit('setHeaderLocation', JSON.parse(localStorage.getItem('currentLocation').uuid))
          console.log('this is the state user', this.$store.state.user);
          localStorage.setItem('user', JSON.stringify(this.$store.state.user));
          let loginDate = new Date();
          localStorage.setItem('lastRefresh', JSON.stringify(loginDate.getTime()));
          console.log('this is the cookie', cookie);
          localStorage.removeItem('loginCount');
          this.locationSelectForm = {
            temp_access_token: '',
            location_id: ''
          }
            setTimeout(() => {
              this.getLoggedUser();
            }, 2000);
            if (this.$store.state.user.role.permissions.includes('can_view_drug_register')) {
                this.$router.push('/drug-register');
              } else {
                this.$router.push('/')
              }
          
        }).catch(err => {
          this.loginError = err.response && err.response.data ? err.response.data : err.response;
          console.log('this is the location error', err.response, err);
        })
      }, 500)

    },
    selectLocationID() {
      this.locationResults.forEach(item => {
        if (item.uuid == this.locationSelectForm.location_id) {
          localStorage.setItem('currentLocation', JSON.stringify(item));
          this.$store.commit('setLocation', JSON.parse(localStorage.getItem('currentLocation')));
        }
      })

    },
    getLoggedUser() {
      axios.post('/me', {}, this.$store.state.header).then(res => {
        localStorage.setItem('user_id', JSON.stringify(res.data.uuid));
        FirebaseService.getForm(res.data.uuid).then((res) => {
          // let formtype = type;
          console.log('trying to retrieve form', res.data())
          if (res.data() != undefined && res.data().saveContext) {
            // if(formtype == 'send' || formtype == 'receive'){
            //     formtype = 'transfer'
            // }

            localStorage.setItem('savedform', JSON.stringify(res.data()));
            console.log('form does exist', res.data());
            this.$store.commit('setCheckedFirebase', true);
            // console.log('this is the endpoint for the firebase form',res.data().endpoint, res.data().endpoint.includes(formtype));
          } else {
            console.log('form does not exist');
          }


        })
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the getloggeduser error', err.response.data);
      })
    },
    erasePreviousLoggedUser(){
      localStorage.removeItem('company');
      localStorage.removeItem('locations');
      localStorage.removeItem('currentLocation');
      localStorage.removeItem('user');
      localStorage.removeItem('user_id');
      localStorage.removeItem('lastRefresh');
      localStorage.removeItem('savedform');
      document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    },
    getLocations(){
      axios.post('/user-locations', {
        temp_access_token: this.$route.params.id
      }).then(res=>{
        this.locationResults = res.data;
      }).catch(err=>{
        console.log('didnt return stuff', err);
      })
    }
  },
  mounted() {
    this.erasePreviousLoggedUser();
    this.$store.commit('setDisplayNotifications', false);
    console.log('THIS IS THE PARAMS ID', this.$route.params.id);
    this.getLocations();
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login-context {
  background: linear-gradient(84.65deg, #10DCF4 31.37%, #0870DC 87.12%);
  color: white;
  padding-top: 8.125em;
  padding-left: 17em;
  padding-right: 8em;
  text-align: left;

  .login-logo {
    justify-content: flex-end;
  }

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  justify-self: flex-start;
}

.login-form {
  padding: 8.125em 4.375em;
  border-bottom-right-radius: 8.25em;
  background-color: white;
  margin-right: -10em;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.login-form-details {

  width: fit-content;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10em;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;

  h2 {
    text-align: left;
  }
}

.login-form-fields {
  display: grid;
  justify-content: center;
}

.form-error {
  margin: 0;
  margin-bottom: 1em;
  color: $error;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}

</style>
