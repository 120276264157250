<template>
    <!-- @change="drugSelect"  -->
    <!-- <div style="display: flex; align-items: center; width: 100%;" class="ingredients-dropdown">  -->
        <Dropdown v-model="stock" ref="med" filter optionLabel="alias.name"  @change="changeStock"
             :options="patientMedsWDisabled" optionDisabled="disabled"
            style="width: 100%; display: flex;" placeholder="Select medication">
            <!-- dropdown view -->
            <template #option="slotProps" style="border-bottom: 1px solid rgb(202, 202, 202);">
                <!-- border-bottom: 1px solid rgb(202, 202, 202); -->
                <div
                    style="display: grid;grid-template-rows: auto auto; width: 400px; margin: 0.5rem 1rem; gap: 10px;" class="ingredient-item">
                    <div style="display: grid; grid-template-columns: 80% 20%;grid-template-rows: max-content;grid-gap:8px">
                        <div style="text-wrap: wrap;">
                            <p style="margin: auto; text-align: left;font-weight: bold;"><Tag v-if="slotProps.option.bag_id" style="background-color: orange">{{ 'PATIENT OWNED' }}</Tag> {{slotProps.option.alias.name }}
                            </p>
                        </div>
                        <div style="display: grid; align-items: end;">
                            <Tag rounded>Stock: {{ slotProps.option.quantity }}</Tag>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 80% 20%;grid-template-rows: max-content;grid-gap:8px">
                        <div style="text-wrap: wrap;">
                        <p style="font-weight: 400; font-size:smaller; margin: 0px !important;"> Ingredients: <i>{{ strengthFormat(slotProps.option.alias.strength) }}</i></p>
                        </div>
                    </div>
                </div>
            </template>
        </Dropdown>
    <!-- </div> -->
</template>

<script>
import { toRaw } from 'vue'
export default {
    props: ['patientMeds'],
    data() {
        return {
            stock: ""
        }
    },
    methods: {
        strengthFormat(strengthArray) {
            strengthArray = toRaw(strengthArray)
            console.log(strengthArray)
            let strengthString = ""
            let currentStrengthentry = 1
            for (const strengthentry of strengthArray) {
                const strengthOfEntry = (strengthentry.strength).toString()
                const measurement_unitOfEntry = strengthentry.measurement_unit
                const measureandunit = strengthOfEntry.concat(measurement_unitOfEntry)
                const name = strengthentry.name.slice(0,1).toUpperCase()+strengthentry.name.slice(1);
                const single = name.concat(" ", measureandunit)
                strengthString = strengthString.concat(single)
                if (currentStrengthentry != strengthArray.length) {
                    strengthString = strengthString + ", "
                }
                currentStrengthentry++
            }
            return strengthString
        },
        changeStock(item) {
            const drugdata = {
                "itemvalue" : item.value,
                "itemqty" : item.value.quantity
            }
            this.$emit('selectStock', drugdata)
        },
    },
    computed: {
        patientMedsWDisabled() {  
            let patientMedsWithDisabled = this.patientMeds
            patientMedsWithDisabled.forEach((drugentry, index) => {
                const drugEntryQty = drugentry.quantity
                patientMedsWithDisabled[index].disabled = drugEntryQty == 0 
            });
           return patientMedsWithDisabled
        }

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
li:has(> .ingredient-item){
    padding: 0 !important;
    border-bottom: 1px solid rgb(202, 202, 202) !important;
    margin: 0 2px !important;
}
</style>