<template>
  <div class="drug-register-table table-container" style="padding-top: 10em;"
    v-if='!$store.state.user.role.permissions.includes("can_view_stock_report")'>
    <h3
      style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
      You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
  </div>
  <div v-else class="drug-register-table table-container" style="width: 95.5vw; border-top-right-radius: 0;">
    <div class="filters">
      <div style="padding-top: 18px;" class="text-search-container search-container">
        <Dropdown v-model="anesthetistselection" editable v-on:keyup="anesthetistSearch(), showAnethesists()"
          :options="anesthetistresults" :loading="anesthetistsearchloading" optionLabel="fullname"
          placeholder="Search for Anethesist..." ref="anethesist"> </Dropdown>
        <input v-model="payload.drug_name" placeholder="Medication..." style="position: relative;margin-right: 10px;" />
        <input v-model="payload.patient" placeholder="Patient..." style="position: relative;margin-right: 10px;" />
        <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" /> 
      </div>
      <div>
        <p style="text-align: left; font-weight: bold;margin: 0px;">Includes...</p>
        <div class="select-filters-container">
          <p @click="() => { payload.discard = !payload.discard }"
            v-bind:class="[payload.discard == true ? 'active-select-filter' : '']" class="select-filter-item">Discarded
          </p>
          <p @click="() => { payload.administered = !payload.administered }"
            v-bind:class="[payload.administered == true ? 'active-select-filter' : '']" class="select-filter-item">
            Administered</p>
          <p @click="() => { payload.checkedIn = !payload.checkedIn }"
            v-bind:class="[payload.checkedIn == true ? 'active-select-filter' : '']" class="select-filter-item">Checked In
          </p>
        </div>
      </div>

      <div style="display: flex; padding-top: 18px;">
        <button class="button submit-button" @click="getTheatreEntries(true)">Search</button>
        <button class="button clear-button" @click="clearPayload();getTheatreEntries(true)">Clear</button>
        <!-- <button class="button clear-button"
          v-if="$store.state.user.role.permissions.includes('can_export_stock_report') && $store.state.currentLocation.state != 'WA'"
          @click="exportCSV()" style=" background-color: #0870DC; color: white">Download CSV</button> -->
        <button class="button clear-button" v-if="$store.state.user.role.permissions.includes('can_export_stock_report')"
          @click="printElem()" style="background-color: #0870DC; color: white">Print</button>
      </div>



    </div>
    <div style='overflow-y: scroll'>
      <!-- <table class="data-table tx-table" cellspacing="0"> -->
      <!-- LOADING SPINNER -->
      <div v-if="loadingTheatreData">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
      </div>
      <div v-else>
        <table class="data-table audit-table" cellspacing="0">
          <tr class="table-headers">
            <th width="10%" style="padding-left: 10px">
              <h3>Date</h3>
            </th>
            <th width="25%">
              <h3>Medication</h3>
            </th>
            <th width="15%">
              <h3>Patient</h3>
            </th>
            <th width="15%">
              <h3>Anesthetist</h3>
            </th>
            <th>
              <h3>Status</h3>
            </th>
            <th>
              <h3>Checked Out Qty</h3>
            </th>
            <th>
              <h3>Returned Qty</h3>
            </th>
            <th>
              <h3>Discarded Qty</h3>
            </th>
            <th>
              <h3>Administered Qty</h3>
            </th>
          </tr>

          <tbody class="table-data" v-for="(item, index) in theatreReport.data" :key="index">
            <tr>
              <td style="padding-left: 10px">{{ new Date(item.created_at).toLocaleDateString('en-AU',{hour12: false, year:"numeric", month:"short", day:"numeric", hour:"2-digit", minute:"2-digit"}) }}</td>
              <td>{{ item.stock_item.alias.name }}</td>
              <td>{{ item.theatre.patient.personal_information.firstname }}
                {{ item.theatre.patient.personal_information.lastname }}</td>
              <td>{{ item.theatre.anesthetist.personal_information.firstname }}
                {{ item.theatre.anesthetist.personal_information.lastname }}</td>
              <td>{{ (item.theatre.status.charAt(0).toUpperCase() + item.theatre.status.slice(1)) }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.checkin }}</td>
              <td>{{ item.discard }}</td>
              <td>{{ item.administered }}</td>
            </tr>
          </tbody>
        </table>

                <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <div style="width: 100vw">
          <table class="data-table print-tx-table tx-table" cellspacing="0">
            <tr>
              <th style="width: 10em">Date</th>
              <th style="width: 4em">Medication</th>
              <th style="width: 10em">Patient</th>
              <th style="width: 10em">Anesthetist</th>
              <th style="width: 15em">Status</th>
              <th style="width: 10em">Checked Out Qty</th>
              <th style="width: 10em">Returned Qty</th>
              <th style="width: 10em">Administered Qty</th>
            </tr>
            <tr v-for="(item, index) in theatreReport.data" :key="index">
              <td style="padding-left: 10px">{{ new Date(item.created_at).toLocaleDateString() }}</td>
              <td>{{ item.stock_item.alias.name }}</td>
              <td>{{ item.theatre.patient.personal_information.firstname }}
                {{ item.theatre.patient.personal_information.lastname }}</td>
              <td>{{ item.theatre.anesthetist.personal_information.firstname }}
                {{ item.theatre.anesthetist.personal_information.lastname }}</td>
              <td>{{ (item.theatre.status.charAt(0).toUpperCase() + item.theatre.status.slice(1)) }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.checkin }}</td>
              <td>{{ item.discard }}</td>
              <td>{{ item.administered }}</td>
            </tr>
          </table>
        </div>


        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->
        <!-- PRINT AUDIT TABLE -->


        <div class="pagination-links">
          <p class="pagination-arrow" v-show="theatreReport.meta.current_page > 1"
            @click="payload.page -= 1; getTheatreEntries()"> &larr;
          </p>
          <p>Page {{ theatreReport.meta.current_page }} of {{ theatreReport.meta.last_page }}</p>
          <p class="pagination-arrow" v-show="theatreReport.meta.current_page < theatreReport.meta.last_page"
            @click="payload.page += 1; getTheatreEntries()"> &rarr; </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import axios from 'axios';

export default {
  data() {
    return {
      loadingTheatreData: true,
      drugNames: [],
      registerData: [],
      date: [],
      theatreReport: "",
      payload: {
        page: 1,
        discard: false,
        administered: false,
        checkedIn: false,
        patient: "",
        drug_name: "",
        theatre: "",
        anesthetist_id: "",
        date: ""
      },
      anesthetistselection:"",
      anesthetistsearch: "",
      anesthetistresults: [],
      anesthetistsearchloading: false,
    }
  },
  methods: {
    exportCSV() {
      const csv = "Uuid,Ip,User Agent,Controller,Action,Status Code,Message,Login,Recorded,Signature 1,Signature 2,Location,Drug Audit Event,Drug Audit,Person Audit Event,Person Audit,Location Audit Event,Location Audit,Role Audit Event,Role Audit,43fbcd1b-d1de-457c-8b3e-91fe38629690,127.0.0.1,Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36,auth,auth_refresh,200,Succesful refresh,sam ralston,Sep 14, 2023, 10:43,,,Decent Theatre,,,,,,,,"
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `audit_report_${new Date().toLocaleDateString().split('/').join('')}_${this.$store.state.currentLocation.name}.csv`;
      anchor.click();
    },
    clearPayload() {
      this.payload = {
        page: 1,
        discard: false,
        administered: false,
        checkedIn: false,
        patient: "",
        drug_name: "",
        theatre: "",
        anesthetist_id: "",
        date: []
      }
      this.date = []
      this.anesthetistselection = ""
    },
    getTheatreEntries(resetPage) {
      this.$store.dispatch('getRefreshToken');
      this.loadingTheatreData = true;
      if(resetPage){
        this.payload.page = 1;
      }
      
      // add anth if it's  there
      if(this.anesthetistselection.uuid) {
         this.payload.anesthetist_id = this.anesthetistselection.uuid
      } else {
        this.payload.anesthetist_id = ""
      }

      // format date if it's there
      if (this.date.length) {
        this.payload.date = this.formatDate()
      }
      
      // remove unrequired payload fields
      Object.keys(this.payload).forEach((key) => {
        console.log(key);
        if (this.payload[key] === false) {
          delete this.payload[key];
        }
        if (this.payload[key] === "") {
          delete this.payload[key];
        }
      });
       
      axios.post('/get-theatre-drugs', this.payload, this.$store.state.header).then((res) => {
        this.theatreReport = res.data
        this.loadingTheatreData = false
      })
    },
    formatDate() {
      let date = {
        start : "",
        end : ""
      }
        if (typeof this.date == "string") {
          date.start = this.date.split("-")[0]
          date.end = this.date.split("-")[1]
        } else {
          if(this.date[1] == null) {
            date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
            date.end = new Date(this.date[0]).toLocaleDateString('en-AU');

          } else {
            date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
            date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
        }
        }
      return date
    },
    printElem(elem) {
      console.log('this is the eleme', elem);
      window.print();
    },
    anesthetistSearch() {
      this.$store.dispatch('getRefreshToken');
      this.anesthetistsearchloading = true
      axios.post('/get-anesthetist', { name: this.anesthetistselection }, this.$store.state.header).then(res => {
        console.log('this is the res data', res.data);
        this.anesthetistsearchloading = false
        res.data.map((anesthestist) => anesthestist.fullname = anesthestist.firstname + (anesthestist.lastname ? " " + anesthestist.lastname : ""))
        this.anesthetistresults = res.data
      }).catch(err => {
        this.anesthetistsearchloading = false
        console.log('this is the err', err);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })
    },
    showAnethesists() {
      console.log('HITING SAVE FUNCTION');
      let interval = setInterval(() => {
        if (!this.anesthetistsearchloading && this.anesthetistresults) {
          clearInterval(interval);
          this.$refs.anethesist.show();
        }
      }, 100)
    }
  },
  mounted() {
    this.getTheatreEntries()
  }
}
</script>