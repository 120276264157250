<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Destroy Pending Items</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <p class="note-header">Notes</p>
                                        <textarea name="notes" id="note-modal" cols="40" rows="16"
                                            placeholder="Enter notes here..." v-model="form.submit.notes"></textarea>
                                    </div>
                                    <!-- METHOD -->
                                    <input v-model="form.submit.courier_name" placeholder="Courier Name"
                                        style="position: relative; width: 100%; margin-bottom: 5px; background-color: #E5F1FE;" />
                                    <input v-model="form.submit.courier_notes" placeholder="Courier Notes"
                                        style="position: relative; width: 100%; background-color: #E5F1FE;" />
                                </div>

                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel"
                                    style="display: flex; flex-direction: column; justify-content: space-between;">

                                    <div class="right-form-section-drug-container">
                                        <table style="border-collapse: collapse">

                                            <tr v-for="(item, index) in payload.items" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.alias.name }}
                                                        {{ Number(item.alias.strength).toFixed(1) }}{{
                                                                item.alias.form_description
                                                        }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>{{ item.quantity }} {{ item.alias.form }}{{ item.quantity > 1 ?
                                                            's' : ''
                                                    }}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" @click="submitDestroy()">Destroy</p>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal dual="true" :payload="form.submit" v-if="displaySignatureModal"
            :witness="$store.state.currentLocation.state == 'WA'" @isSuccessful="isSuccessful" @close="close"
            endpoint="/destroy-pending" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';

export default {
    props: ['transferType', 'payload', 'success'],
    components: {
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
            newLoc: '',
            stock: '',
            reasonCat: '',
            reasonSubCat: '',
            qty: null,
            location: '',
            addNewLocation: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: [],
            form: {
                submit: {
                    notes: '',
                    items: [],
                    courier_name: '',
                    courier_notes: ''
                }
            }
        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        isSuccessful(value) {
            if (value) {
                this.$store.dispatch('getDestructionItems', {
                    sort_direction: '',
                    col: '',
                    page: 1,
                    location: this.$store.state.uuid,
                    filters: {
                        status: 'pending',
                        drug: '',
                        patient: '',
                        date: {
                            start: '',
                            end: ''
                        },
                    }
                });
                this.$emit('success', true);
                this.closeModal();
            }
        },
        submitDestroy() {
            console.log('this is the form to submit', this.form);
            this.payload.items.forEach(item => {
                this.form.submit.items.push(item.uuid);
            })
            this.displaySignatureModal = true;
            // this.refinedLocations = [];

            // this.form = {
            //     submit:{
            //         reason: '',
            //         method:'',
            //         notes: '',
            //         witness_role: '',
            //         witness_name: '',
            //         pending: false,
            //         username: '',
            //         password: '',
            //     }
            // }
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: #E5F1FE; //$babyblue;
        padding: 10px;
    }

    .search-container input {
        background-color: #E5F1FE; //$babyblue;
    }
}


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: #E5F1FE; //$babyblue;
    }
}

.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.blue-button {
    background-color: #0870DC !important; //$strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: #00AD50; //$complete;
    color: white;
}

.selected-hollow-button {
    background-color: #0870DC; //$strongblue;
    color: white;
}
</style>
