<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="toggleNewTransferModal('Receive')" class="actions-menu-item">Transfer In</button>
      <button @click="toggleNewTransferModal('Send')" class="actions-menu-item">Transfer Out</button>
    </div>
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_transfers')">
      <h3 class="panel-header">Transfers</h3>
      <div class="drug-register-table table-container">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">
            <div class="text-search-container search-container">
             <input v-model="form.filters.completed_by" placeholder="Completed by..." style="position: relative" />
            </div>


            <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
            </div>

             <div class="text-search-container search-container">
             <input v-model="form.filters.location" placeholder="Location..." style="position: relative" />
            </div>

            <button class="button submit-button" @click="loadTransfers()">Search</button>
            <button class="button clear-button" @click="clearTransfersFilters()">Clear</button>
          </div>

          <div style="display: flex; align-items: center">
              <div class="select-filters-container">

              <p @click="()=>{types.send = !types.send; updateType()}" 
                  v-bind:class="[types.send == true ? 'active-select-filter' : '']" class="select-filter-item">Sent</p>

              <p @click="()=>{types.receive = !types.receive; updateType()}"  
                  v-bind:class="[types.receive == true ? 'active-select-filter' : '']" class="select-filter-item">Received</p>

              <!-- <p @click="()=>{types.reversal = !types.reversal; updateType()}" 
                  v-bind:class="[types.reversal == true ? 'active-select-filter' : '']" class="select-filter-item">Reversal</p> -->
              
            </div>
          </div>
          
          <!-- SELECT FILTER SECTION -->
          <MultiSelect v-model="selectedStatuses" :options="statusSelect" @change="updateStatusList" optionLabel="status" placeholder="Status" />

          
        </div>
        <div v-if="!$store.state.loadingTransfersData"  style="display: flex; align-items: start; overflow-y: scroll">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_transfers")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
        <table v-else  class="data-table" cellspacing="0">
          <tr class="table-headers" >
              <th style="width: 50px"></th>
            <th height=40px style="width: 55px">
              <h3 @click="sortBy('type', 'desc')">Type</h3>
              <div v-if="form.col == 'type'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort type-sort" v-if="form.col =='type' && form.sort_direction =='asc'" @click="sortBy('type', 'desc')"></i>
                <i class="pi pi-sort type-sort" v-else @click="sortBy('type', 'asc')"></i>
              </div>
              
            </th>
            <th>
              <!-- @click="sortBy('location', 'desc')" -->
              <h3 >Location</h3>
              <!-- <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="form.col =='location' && form.sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <!-- @click="sortBy('created_by', 'desc')" -->
              <h3 >Created by</h3>
              <!-- <div v-if="form.col == 'created_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  request-by-sort" v-if="form.col =='created_by' && form.sort_direction =='asc'" @click="sortBy('created_by', 'desc')"></i>
              <i class="pi pi-sort request-by-sort" v-else @click="sortBy('created_by', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('created_at', 'desc')" style="cursor: pointer">Created Date</h3>
              <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  created_at-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
              <i class="pi pi-sort created_at-sort" v-else @click="sortBy('created_at', 'asc')"></i>
              </div>
            </th>
            <th>
              <!-- @click="sortBy('completed_by', 'desc')" -->
              <h3 >Completed by</h3>
              <!-- <div v-if="form.col == 'completed_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_by-sort" v-if="form.col =='completed_by' && form.sort_direction =='asc'" @click="sortBy('completed_by', 'desc')"></i>
              <i class="pi pi-sort completed_by-sort" v-else @click="sortBy('completed_by', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('completed_at', 'desc')" style="cursor: pointer">Completed Date</h3>
              <div v-if="form.col == 'completed_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_at-sort" v-if="form.col =='completed_at' && form.sort_direction =='asc'" @click="sortBy('completed_at', 'desc')"></i>
              <i class="pi pi-sort completed_at-sort" v-else @click="sortBy('completed_at', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('status', 'desc')" style="padding-left: 20px;">Status</h3>
              <div v-if="form.col == 'status'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  status-sort" v-if="form.col =='status' && form.sort_direction =='asc'" @click="sortBy('status', 'desc')"></i>
              <i class="pi pi-sort status-sort" v-else @click="sortBy('status', 'asc')"></i>
              </div>
            </th>
            

          </tr>
          <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
            <tr @click="showContent(item, index)" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']">
                <td>
                    <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                    <i v-else class="pi pi-angle-right"></i>
                </td>
                <!-- v-if="item.type == 'receive'" -->
                <td height="40px" style="width: 70px;">
                  <!-- <i class="pi pi-download" v-if="item.to_location == $store.state.currentLocation.name"></i> -->
                  <p v-if="item.to_location == $store.state.currentLocation.name" class="blue-button" style="cursor: default; width: 45px; padding: 2px; text-align: center; font-weight: bold" >IN</p>
                  <!-- <i v-else class="pi pi-upload"></i> -->
                  <p v-else class="blue-button" style="cursor: default; width: 45px; padding: 2px; text-align: center; font-weight: bold; background-color: #9F5CC0 !important">OUT</p>
                </td>
                <td>{{item.to_location == $store.state.currentLocation.name ? item.from_location : item.to_location}}</td>
                <td>{{item.created_by}}</td>
                <td>{{item.request_date}}</td>
                <td :style="[!item.completed_by ? {paddingLeft: '3em'}:{}]">{{item.completed_by ? item.completed_by : '--'}}</td>
                <td :style="[!item.completed_date ? {paddingLeft: '3em'}:{}]">{{item.completed_date ? item.completed_date: '--'}}</td>
                <td v-if="item.status"><p :class="[item.status == 'completed' ? 'correct-status-color' : (item.status == 'rejected' || item.status == 'reversed' ? 'mismatch-status-color' : 'warning-status-color')]">{{item.type == 'reversal' && item.status == 'completed' ? 'Reversal' : item.status.charAt(0).toUpperCase()+item.status.slice(1)}}</p></td>
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container">
                 <div v-if="!item.expandedInfo" style="margin: 0 auto; margin-bottom: 1em;">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                 </div>
                   <div class="transfer-specs-container" v-if="item.expandedInfo">
                       <div class="status-field">
                           <p class="field-title">Status</p>
                           <p v-if="item.status" class="field-value" style='text-align: center' :class="[item.status == 'completed' ? 'correct-status-color' : (item.status == 'rejected' || item.status == 'reversed' ? 'mismatch-status-color' : 'warning-status-color')]"
                           >{{item.status.charAt(0).toUpperCase()+item.status.slice(1)}}</p>
                       </div>
                       <div class="type-field">
                           <p class="field-title">Type</p>
                           <p class="field-value" style='text-align: center' :class="[item.type == 'receive' ? 'received-status-color' : 'sent-status-color']">
                               {{item.type == 'reversal' ? 'Reversal' : item.to_location == $store.state.currentLocation.name ? 'Receive' : 'Send'}} Transfer</p>
                       </div>
                       <div class="date-received-field" v-if="item.to_location == $store.state.currentLocation.name">
                           <p class="field-title">Date Received</p>
                           <p class="field-value">{{item.completed_date ? item.completed_date : 'N/A'}}</p>
                       </div>
                       <div class="date-received-field" v-else>
                           <p class="field-title">Date Sent</p>
                           <p class="field-value">{{item.request_date ? item.request_date : 'N/A'}}</p>
                       </div>
                       <div class="ordered-from-field">
                           <p class="field-title">From Location</p>
                           <p class="field-value">{{item.from_location}}</p>
                       </div>
                       <div class="ordered-by-field">
                           <p class="field-title">To Location</p>
                           <p class="field-value">{{item.to_location}}</p>
                       </div>
                       <div class="fulfilled-by-field">
                           <p class="field-title">Created By</p>
                           <p class="field-value">{{item.created_by}}</p>
                       </div>
                       <div class="fulfilled-time-field">
                           <p class="field-title">Created Time</p>
                           <p class="field-value">{{item.request_date}}</p>
                       </div>
                       <div class="collection-time-field">
                           <p class="field-title">Completed Time</p>
                           <p class="field-value">{{item.completed_date ? item.completed_date : 'N/A'}}</p>
                       </div>
                       <div class="collected-by-field">
                           <p class="field-title">Completed By</p>
                           <p class="field-value">{{item.completed_by ? item.completed_by : 'N/A'}}</p>
                       </div>
                   </div>
                   <div class="transfer-notes-container" v-if="item.expandedInfo">
                       <p class="field-title">Notes</p>
                       <div class="field-notes" >
                           <p >{{item.expandedInfo.notes}}</p>
                       </div>
                   </div>
                   <div class="transfer-drugs-container" v-if="item.expandedInfo">
                       <p class="field-title">Medications</p>
                       <div class="drug-field-tables" style="width: 95%; border: 2px solid rgb(182, 182, 182);
                                                                border-radius: 4px;
                                                                height: 300px;">
                        <table style="padding: 0px 5px">
                            <tr>
                                <th>Name</th>
                                <th>Qty</th>
                                <th>Patient</th>
                                <th>Prescriber</th>
                                <!-- <th>Remaining</th> -->
                            </tr>
                        </table>
                        <div style="height: 250px; overflow-y: scroll; background-color: white;">
                            <table style="padding-top: 0;">
                                <tr v-for="(drug,key) in item.expandedInfo.drugs" :key="key" style="height: 25px">
                                    <td style="font-size: 0.8em;">{{drug.alias.name}}</td>
                                    <td style="font-size: 0.8em;">{{drug.quantity}} {{drug.alias.form}}{{drug.quantity == 1 ? '':'s'}}</td>
                                    <td style="font-size: 0.8em;">{{item.expandedInfo.resident}}</td>
                                    <td style="font-size: 0.8em;">{{item.expandedInfo.prescriber}}</td>
                                    <!-- <td>{{drug.remaining}}</td> -->
                                </tr>
                            </table>
                        </div>
                       </div>
                   </div>

                   <div class="reverse-container" v-if="item.expandedInfo && item.expandedInfo.reversal && (item.expandedInfo.reversal.reversed_notes || item.expandedInfo.reversal.reversed_by || item.expandedInfo.reversal.reversed_date || item.expandedInfo.reversal.reversed_witness_by)">
                    <div class="reversed-notes-field" :style="[item.expandedInfo.reversed_notes ? {minWidth: '15em'}:{}]" style="padding-right: 10px;">
                        <p class="field-title">Reverse Notes</p>
                        <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversal.reversed_notes ? item.expandedInfo.reversal.reversed_notes:'N/A'}}</p>
                    </div>
                    <div class="reversed-by-field">
                        <p class="field-title">Reversed By</p>
                        <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversal.reversed_by ? item.expandedInfo.reversal.reversed_by :'N/A'}}</p>
                    </div>
                    <div class="reversed-date-field">
                        <p class="field-title">Reversed Date</p>
                        <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversal.reversed_date ?  item.expandedInfo.reversal.reversed_date :'N/A'}}</p>
                    </div>
                    <div class="reversed-witness-by-field">
                        <p class="field-title">Reverse Witness By</p>
                        <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversal.reversed_witness_by ?  item.expandedInfo.reversal.reversed_witness_by :'N/A'}}</p>
                    </div>

                  </div>
               </div>
                <!-- TRANSFER ACTIONS -->
                
                <div class="transfer-actions-container" v-if="item.expandedInfo">
                    <button @click="transferAction('Reverse', item)" class="transfer-action-button error-button" v-show="!item.expandedInfo.reversal && ((item.status == 'completed' && item.to_location == $store.state.currentLocation.name && item.type != 'reversal') || (item.status == 'pending' && item.type == 'send' && item.to_location != $store.state.currentLocation.name))">Reverse Transfer</button>
                    <button @click="transferAction('Approve', item)" class="transfer-action-button complete-button" v-show="(item.status == 'pending' && item.to_location == $store.state.currentLocation.name) || ( item.status == 'pending reversal' && item.to_location == $store.state.currentLocation.name) ">Complete Transfer</button>
                    <!-- <button @click="transferAction('Approve', item)" class="transfer-action-button complete-button" v-show="item.status == 'pending reversal' && item.to_location == $store.state.currentLocation.name">Complete Transfer</button> -->
                    <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                </div>
            </tr>    
          </tbody>
          
        </table>
        </div>

        <div v-else>
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links">
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
          </div>
       </div>
       
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <ActionModal v-if="displayActionModal" @close="close" :toAction="toAction" />
    <NewTransferModal v-if="displayNewTransferModal" @close="close" :transferType="transferType" />
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import NewTransferModal from '@/components/modals/NewTransferModal.vue';
import ActionModal from '@/components/modals/ActionModal.vue';
import axios from 'axios';

export default {
  components: { 
    ActionModal,
    NewTransferModal
  },
  data(){
    return{
        displayNewTransferModal: false,
        displayActionModal: false,
        toAction:{
            uuid:'',
            action: ''
        },
        selectedStatuses: [
            {status:'Pending'},
            {status:'Pending Reversal'},
            {status:'Reversed'},
            {status:'Completed'}
		],
		statusSelect: [
            {status:'Pending'},
            {status:'Pending Reversal'},
            {status:'Reversed'},
            {status:'Completed'}
		],
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true,
          reversal: true,
      },
      toCache:[],
      date:[],
      clearForm:{
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters:{
          // 'pending','completed', 'reversed', 'pending reversal'
          status:[],
          completed_by:'',
          location:'',
          type:['send', 'receive', 'reversal'],
          date:{
            start:'',
            end:''
          },
        }
      },
      form:{
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          status:[],
          completed_by:'',
          location:'',
          type:['send', 'receive', 'reversal'],
          date:{
            start:'',
            end:''
          },
        }
      },
      
    }
  },
  methods:{
    close(){
      console.log("close ran")
            this.displayActionModal = false;
            this.displayNewTransferModal = false;
            this.$store.dispatch('getTransfers', this.form);
    },
    toggleNewTransferModal(type){
        this.transferType = type;
        this.displayNewTransferModal = true;
    },
    transferAction(selectedAction, item){
        console.log('this is the transfer being actioned', item);
        this.toAction = {
            action: selectedAction,
            uuid: item.uuid,
            'note': item.expandedInfo.notes
        }
        
        this.displayActionModal = true;
        console.log('this is the action id', this.toAction);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getTransfers', this.form);
    },
    showContent(transfer, index){
        console.log('this the selected log', transfer)
        // let refid=`expand-${index}`;

        if(transfer.display && (transfer.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            transfer.display = !transfer.display;
        } else{
            transfer.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
            axios.post('/single-transfer', {uuid: transfer.uuid}, this.$store.state.header).then(res=>{
                console.log('this is the state function to get a single transfer', res.data);
                transfer.expandedInfo = res.data;
            })
        }
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadTransfers(){
      console.log('this is the date', this.date);
      //this.updateType();
      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0];
          this.form.filters.date.end = this.date.split("-")[1];
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
        }   
        this.$store.dispatch('getTransfers', this.form);
      } else{
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        this.$store.dispatch('getTransfers', this.form);
      }
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getTransfers', this.form);
    },
    updateStatusList(){
        this.form.filters.status = [];
        console.log('updating status list')
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                console.log('this is the key', key);
                this.form.filters.status.push(value.toLowerCase());
             }
        })
        this.loadTransfers();
    },
    updateType(){
        this.form.filters.type = [];
        for (const [key, value] of Object.entries(this.types)) {
            if(value == true){
              this.form.filters.type.push(key)
            }
        }
    },
    clearTransfersFilters(){
      const clearForm = {
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          status:[],
          completed_by:'',
          location:'',
          type:['send', 'receive', 'reversal'],
          date:{
            start:'',
            end:''
          },
        }
      };
      this.form = clearForm
      this.date = [];
      this.$store.dispatch('getTransfers', this.form);
    },
  },
   mounted(){
    this.loadTransfers();
    this.toCache = [];
  },
  computed:{
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    }
  }
}
</script>
