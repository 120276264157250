import axios from 'axios';
import { createStore } from 'vuex';
import router from '@/router';

export const store = createStore({
  state: {
    lastRefresh:JSON.parse(localStorage.getItem('lastRefresh')),
    subdomain:'',
    view:'',
    quickSearch: '',
    uuid: JSON.parse(localStorage.getItem('currentLocation')) ? JSON.parse(localStorage.getItem('currentLocation')).uuid : '',
    company:JSON.parse(localStorage.getItem('company')),
    userType:JSON.parse(localStorage.getItem('company')) ? JSON.parse(localStorage.getItem('company')).type : '',
    user_id:'',
    user:JSON.parse(localStorage.getItem('user')),
    VUE_APP_ALGOLIA_API_KEY : 'b7ecebf2d8e997c8e5d567658035de2a',
    VUE_APP_ALGOLIA_APP_ID : 'H4AK5A0Y5K',
    VUE_APP_ALGOLIA_DNS : 'H4AK5A0Y5K',
    checkedFirebase: false,
    loadingDrugRegisterData: false,
    loadingTheatreData: false,
    loadingRequisitionData: false,
    loadingTransferData: false,
    loadingDeliveriesData: false,
    loadingDestructionsData: false,
    loadingStockTakeData: false,
    loadingAuditData: false,
    loadingIHIData: false,
    loadingEPrescribingData: false,
    loadingDrugListData: false,
    loadingReportData: false,
    loadingPatientsData: false,
    displayTimeRunnerModal: false,
    timeToLogOut:0,
    locationTypes: [
      "Pharmacy",
      "Hospital",
      "RACF",
      "Supplier",
      "Building",
      "Room",
      "Ward",
      "Wing",
      "Floor",
      "Level",
      "House",
      "Health District",
      "Patient",
      "Drug Safe",
      "Trolley",
      "Car",
      "Ambulance",
      "Drawer",
      "Cabinet",
      "Machine",
      "Theatre",
      "Generic",
    ],
    countries: [ 
    
      {name: 'Australia', code: 'AU', callingcode: '+61'}, 
      {name: 'New Zealand', code: 'NZ', callingcode: '+64'}, 
      {name: 'Norway', code: 'NO', callingcode: '+47'}, 
      {name: 'Sweden', code: 'SE',callingcode: '+46'}, 
      {name: 'United Kingdom', code: 'GB',callingcode: '+44'}, 
      {name: 'United States', code: 'US',callingcode: '+1'}, 
      
    ],
    chartTypes: [
      {
          name:'Regular Medicine Order',
          chart_name:'regular',
          inputs:[
              'dose',
              'route',
              'start_date',
              'schedule',
              'stop_date',
              'frequency',
              'reason',
              'substitution',
              'indication',
              'single_multi',
              'packed',
              'comments',
          ],
          subtext:'',
          color: '#9F5CC0',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'PRN Medicine',
          chart_name:'prn',
          inputs:[
            'indication',
            'frequency',
            'max_24_dose',
            'dose',
            'route',
            'max_dose',
            'substitution',
            'packed',
            'prescription_date',
            'start_date',
            'stop_date',
            'comments',
            'min_admin',
            'max_admin'
          ],
          subtext:'',
          color: '#00B2A2',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
        name: 'Insulin Order',
        chart_name: 'insulin',
        inputs: [
            'dose',
            'route',
            'start_date',
            'stop_date',
            'frequency',
            'schedule',
            // 'reason',
            'substitution',
            'indication',
            'single_multi',
            'packed',
            'comments',
        ],
        subtext: '',
        color: '#F3C400',
        prescriber: true,
        sign1: false,
        sign2: false,
    },
    //   {
    //       name:'Phone Order',
    //       inputs:[
    //           'reason',
    //           'dose',
    //           'route',
    //           'frequency',
    //           'prescription_date',
    //           'start_date',
    //           'stop_date',
    //           'comments',
    //       ],
    //       subtext:'',
    //       color: '#2E97DE',
    //       prescriber:true,
    //       sign1:true,
    //       sign2: true,
    //   },
      {
          name:'Short Term Medicine',
          chart_name:'short_term',
          inputs:[
              'now_or_next_pack',
              'dose',
              'route',
              'schedule',
              'packed',
              'frequency',
              'indication',
              'prescription_date',
              'start_date',
              'stop_date',
              'reason',
              'substitution',
              'comments',

          ],
          subtext:'(antibiotics)',
          color: '#D6001C',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'Once Only Meds (Stat)',
          chart_name:'stat',
          inputs:[
              'datetime',
              'dose',
              'route',
              'reason',
              'schedule'
          ],
          subtext:'',
          color: '#8E0018',
          prescriber:true,
          sign1:true,
          sign2: false,
      },
      {
          name:'Intermittent Medicine',
          chart_name:'intermittent',
          inputs:[
              'frequency',
              'start_date',
              'schedule'
          ],
          subtext:'',
          color: '#5B00AA',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'Variable Dose Meds',
          chart_name:'variable_dose',
          inputs:[
              'frequency',
              'schedule',
              'dose',
              'route',
              'packed',
              'prescription_date',
              'start_date',
              'stop_date',
              'comments',
          ],
          subtext:'(not insulin)',
          color: '#00AD50',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
    //   {
    //       name:'Nurse Initiated Meds',
    //       inputs:[
    //           'frequency',
    //           'dose',
    //           'route',
    //           'reason',
    //           'start_date',
    //           'comments'
    //       ],
    //       subtext:'(non-prescription)',
    //       color: '#274188',
    //       prescriber:false,
    //       sign1:false,
    //       sign2: false,
    //       nurse: true,
    //   },
      {
          name:'Non Prescription Meds',
          chart_name:'non_prescription',
          inputs:[
              'frequency',
              'schedule',
              'dose',
              'route',
              'reason',
              'start_date',
              'comments',
          ],
          subtext:'(resident initiated)',
          color: '#EF9600',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
      {
          name:'Nutritional Supplement',
          chart_name:'supplement',
          inputs:[
              'frequency',
              'schedule',
              'dose',
              'route',
              'reason',
              'start_date',
              'stop_date',
              'substitution',
              'packed',
              'comments',
          ],
          subtext:'',
          color: '#E56DB1',
          prescriber:true,
          sign1:false,
          sign2: false,
      },
    ],
    reportError:'',
    stockError:'',
    registerError:'',
    blind_count_settings: JSON.parse(localStorage.getItem('blind_count_settings')),
    availableLocations:JSON.parse(localStorage.getItem('locations')),
    currentLocation:JSON.parse(localStorage.getItem('currentLocation')),
    header: {
      headers: {
        'Authorization':`Bearer bubble`,
        'Content-Type': 'application/json',
        'Location': 'something'
      }
    },
    form:{
    },
    verify:{
      username: '',
      password: ''
    },
    currPage: 1,
    drugEntries: [],
    stockReports: [],
    auditReports: [],
    ihiReports: [],
    ePrescribingReports: [],
    reports: [],
    patients: [],
    drugNames: [],
    stockTakeCount: [],
    country: 'au',
    includeInactive: false,
    alerts:null,
    notifications:null,
    displayAlerts: false,
    lastAlertRefresh: null,
    lastNotificationRefresh: null,
    loadingAlerts: false,
    loadingNotifications: false,
    drugRegImprestToggle: false,
    drugRegDestructionToggle: false,
    numPendingDestructions:0,
    numNewRequisitions:0,
    pendingDestructions:[],
    requisitionEntries:[],
    requisitionNotificationItems:[],
    displayNotifications: false,
    numOfNotifications:0,
  },
  getters: {
    getSubdomain: state =>{
      return state.subdomain
    },
    getRoleUuid: state=>{
      return state.user.role.uuid
    },
    getAlerts : state => {
      return state.alerts
    },
    getHeader : state => {
      return state.header
    },
    getLastAlertRefresh: state=>{
      return state.lastAlertRefresh
    },
    getQuickSearch: state => {
      return state.quickSearch
    },
    getDrugEntries: state => {
      return state.drugEntries.data
    },
    getDrugListEntries: state => {
      return state.drugEntries.drugs
    },
    getTransactionsReport: state => {
      return state.reports.data
    },
    getStockReport: state => {
      return state.stockReports
    },
    getAuditReport: state => {
      return state.auditReports
    },
    getIHIReport: state => {
      return state.ihiReports
    },
    getEPrescribingReport: state => {
      return state.ePrescribingReports
    },
    getPatients: state => {
      return state.patients
    },
    getLocationTypes: state => {
      return state.locationTypes
    },
    getCountries: state => {
      return state.countries
    },
    getChartTypes: state => {
      return state.chartTypes
    },
    getRequisitionEntries:state=>{
      return state.requisitionEntries
    },
    getNotificationItems:state=>{
      return state.notifications ? state.notifications : []
    }
  },
  
  mutations: {
    setLoadingAlerts(state, loading){
      state.loadingAlerts = loading
    },
    setdrugRegDestructionToggle(state, toggle){
      state.drugRegDestructionToggle = toggle
    },
    setdrugRegImprestToggle(state, toggle){
      state.drugRegImprestToggle = toggle
    },
    setLastAlertRefresh(state, time){
      state.lastAlertRefresh = time;
    },
    setLastNotificationRefresh(state, time){
      state.lastNotificationRefresh = time;
    },
    setSubdomain(state, subdomain){
      state.subdomain = subdomain;
    },
    setNumAlerts(state,num){
      state.numOfAlerts = num;
    },
    setAlerts(state,alerts){
      state.alerts = alerts;
    },
    setNotifications(state,notifications){
      state.notifications = notifications;
    },
    setNotificationsCount(state,notificationcount){
      state.numOfNotifications = notificationcount;
    },
    setAlertAsRead(state,index){
      state.alerts[index].read = true;
    },
    setQuickSearch(state, quickSearch) {
      state.quickSearch = quickSearch;
    },
    setCheckedFirebase(state, check) {
      state.checkedFirebase = check;
    },
    changeView(state, view) {
      localStorage.removeItem('reg');
      state.view = view;
    },
    saveVerification(state,verify) {
      state.verify = verify;
    },
    setPatients(state, patients) {
      state.patients = patients;
    },
    setCurrPage(state, page) {
      state.currPage = page;
    },
    setDrugEntries(state, entries) {
      state.drugEntries = entries;
    },
    setReports(state, reports) {
      state.reports = reports;
    },
    setStockReports(state, reports) {
      state.stockReports = reports;
    },
    setAuditReports(state, reports) {
      state.auditReports = reports;
    },
    setIHIReports(state, reports) {
      state.ihiReports = reports;
    },
    setEPrescribingReports(state, reports) {
      state.ePrescribingReports = reports;
    },
    setDrugNames(state, names) {
      let drugs = []
      names.forEach(item => {
        drugs.push(item.name)
      })
      state.drugNames = drugs;
    },
    setAuthorization(state) {
      state.header.headers['Authorization'] = `Bearer ${('; '+document.cookie).split(`; auth=`).pop().split(';')[0]}`
    },
    setUserType(state) {
      state.userType = JSON.parse(localStorage.getItem('company')) ? JSON.parse(localStorage.getItem('company')).type : '';
    },
    updateCount(state, countCache) {
      state.stockTakeCount = countCache;
    },
    setUser(state, user) {
      state.user_id = user;
      state.user = user;
    },
    setResponsiblePersonError(state, message){
      state.reponsiblePersonErrorMessage = message.errorMessage;
      state.reponsiblePersonError= message.state;
    },
    setLoading(state, loadStatus) {
      console.log('this is the type of loading', loadStatus);
      switch (loadStatus.type) {
        case 'Drug Register':
          state.loadingDrugRegisterData = loadStatus.state;
          break;
        case 'Stock Take':
          state.loadingStockTakeData = loadStatus.state;
          break;
        case 'Transfers':
          state.loadingTransfersData = loadStatus.state;
          break;
        case 'Deliveries':
          state.loadingDeliveriesData = loadStatus.state;
          break;
        case 'Requisitions':
          state.loadingRequisitionData = loadStatus.state;
          break;
        case 'Destructions':
          state.loadingDestructionsData = loadStatus.state;
          break;
        case 'Report':
          state.loadingDestructionsData = loadStatus.state;
          break;
        case 'Audit':
          state.loadingAuditData = loadStatus.state;
          break;
        case 'IHI':
          state.loadingIHIData = loadStatus.state;
          break;
        case 'EPrescribing':
          state.loadingEPrescribingData = loadStatus.state;
          break;
        case 'Drug List':
          state.loadingDrugListData = loadStatus.state;
          break;
        case 'Patients':
          state.loadingPatientsData = loadStatus.state;
          break;
      }
    },
    setLocation(state, location) {
      state.uuid = location.location;
      state.currentLocation = location;
    },
    setAvailableLocations(state, locations) {
      state.availableLocations = locations;
    },
    setCompany(state, id) {
      state.company = id;
    },
    setCompanyBlindCount(state, status) {
      console.log('this is the blind stock state:',status);
      localStorage.setItem('blind_count_settings', status);
      state.blind_count_settings = status;
    },
    setHeaderLocation(state,location) {
      state.header.headers['Location'] = location;
    },
    setToken(state,token) {
      state.header.headers['Authorization'] = `Bearer ${token}`
    },
    setStockError(state, error) {
      state.stockError = error;
    },
    setLoadingNotifications(state, bool) {
      state.loadingNotifications = bool;
    },
    setRegisterError(state, error) {
      state.registerError = error;
    },
    setReportError(state,error) {
      state.reportError = error;
    },
    setDisplayTimeRunnerModal(state,value){
      state.displayTimeRunnerModal = value;
    },
    setDisplayAlerts(state,set){
      state.displayAlerts = set;
    },
    setTimeToLogOut(state,value){
      state.timeToLogOut = value
    },
    setInactive(state,value){
      state.includeInactive = value;
    },
    setNumNewRequisitions(state,num){
      state.numNewRequisitions = num;
    },
    setNumPendingDestructions(state,num){
      state.numPendingDestructions = num;
    },
    setPendingDestructions(state,items){
      state.pendingDestructions = items;
    },
    setDisplayNotifications(state,set){
      state.displayNotifications = set;
    },
    setRequisitionNotificationItems(state, entries){
      state.requisitionNotificationItems = entries;
    },
    setRequisitionEntries(state, entries){
      state.requisitionEntries = entries;
    },

  },
  actions: {
    getPatientSearchResults(state, quickSearch) {
      state.commit('setAuthorization');
      state.commit('setQuickSearch', quickSearch);
      state.commit('setLoading', { state: true, type: 'Patients' });
      state.commit('setPatients', []);
      axios.post('/search-patients', { 
          page: 1,
          name: quickSearch,
          location: this.state.currentLocation.uuid,
          include_inactive: this.state.includeInactive
        },
          this.state.header
        ).then(res => {
          state.commit('setPatients', res.data.patients);
          state.commit('setLoading', { state: false, type: 'Patients' });
          this.$store.dispatch('getRefreshToken');
        }).catch(err => {
          state.commit('setLoading', { state: false, type: 'Patients' });
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },
    getPendingDestructions(state){
      state.commit('setAuthorization');
      if(this.state.currentLocation && this.state.currentLocation.uuid){
        axios.post('/destruction-entries', {
          sort_direction: '',
          col: '',
          page: 1,
          location: this.state.uuid,
          filters: {
            status: 'pending',
            drug: '',
            patient: '',
            date: {
              start: '',
              end: ''
            },
          }
        }, this.state.header).then(res=>{
            console.log('this is the state function to get destruction entries', res.data.data.length);
            state.commit('setNumPendingDestructions', res.data.data.length);
            state.commit('setPendingDestructions', res.data.data);
          }).catch(err=>{
              if(err.response && err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                  console.log('yes, it does include expired')
                  router.push('/login');
              }
          })
      }

    },
    getAlerts(state){
      state.commit('setLoadingAlerts', true);
      axios.post('/get-alerts', {page: 1, read: false}, this.state.header).then(res=>{
          let alerts = res.data.data;
          
          let now = new Date()
          state.commit('setLastAlertRefresh', now.toLocaleTimeString('en-AU'));
          alerts.forEach((alert, index)=>{
            alert.read = false;
            alert.index= index
          })
          state.commit('setAlerts', alerts);
          if(res.data.data.length){
            state.commit('setDisplayAlerts', true);
          }
          state.commit('setLoadingAlerts', false);
      })
    },
    getNotifications(state){
      state.commit('setLoadingNotifications', true);
      axios.post('/get-channel-notifications', {page: 1}, this.state.header).then(res=>{
          let incomingNotifications = res.data.data;
          console.log("notifications:")
          console.log(incomingNotifications)
          
          let now = new Date()
          state.commit('setLastNotificationRefresh', now.toLocaleTimeString('en-AU'));
          incomingNotifications.forEach((notification, index)=>{
            notification.read = false;
            notification.index= index
          })

          state.commit('setNotifications', incomingNotifications);
          state.commit('setNotificationsCount', incomingNotifications.length)
          state.commit('setLoadingNotifications', false);
      })
    },
    readAlert(state, alert){
      
      axios.post('/read-alerts', {alert_ids: [alert.id]}, this.state.header).then(res=>{
        store.commit('setAlertAsRead', alert.index);
        console.log('this alert has been read', res);
      })
    },
    getRefreshToken(state) {
      state.commit('setAuthorization');
      let storedTime = JSON.parse(localStorage.getItem('lastRefresh'));
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      let timeDiff = (currentTime - storedTime)/ (1000 * 60) % 60;

      console.log('this is the time since last refresh', timeDiff);
      if (timeDiff > 5) {
        axios.post('/refresh', {}, this.state.header).then(res => {
          state.commit('setToken', res.data.access_token);
          let cookie = "auth="+res.data.access_token+"; max-age="+3600+"; path=/";
          document.cookie = cookie;
          
          console.log('set the localstorage last refresh to current time', currentTime);
          localStorage.removeItem('lastRefresh');
          localStorage.setItem('lastRefresh', currentTime);
        }).catch(err=>{
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired');
              localStorage.removeItem('lastRefresh');
              state.commit('setAlerts', null)
              router.push('/login');
          }
        })
      } else{
        console.log('do not need to get refresh token');
      }
    },
    timeRunner(state){
      let interval = setInterval(() => {
        // get local storage time
        let storedTime = JSON.parse(localStorage.getItem('lastRefresh'));
        // calculate 15mins ahead
        let expiryTime = storedTime + 900000;
        // get current time
        let currentTime = new Date();
        currentTime = currentTime.getTime();
        // calc time to expiry
        let timeToExpiry = expiryTime - currentTime;
        // run timer using setInterval

        let timer = timeToExpiry/1000;
          timer--;
          this.loadingDelay = true;
          state.commit('setTimeToLogOut', timer)
          // once timer gets to 0, direct to login page and stop running
          if (timer < 1) {
            console.log('TIME TO RETURN TO LOGIN');
            localStorage.removeItem('lastRefresh');
            state.commit('setDisplayTimeRunnerModal', false);
            clearInterval(interval);
            router.push('/login')
          } else{
            // run check to see if time diff is at or below 2mins
            // if condition met, display modal
            if(timer <= 120){
              console.log('DEPLOYING TIMER MODAL');
              state.commit('setDisplayTimeRunnerModal', true);
            } else{
              state.commit('setDisplayTimeRunnerModal', false);
            }
            // console.log('THIS IS THE TIME TO EXPIRY', timer);

          }
      }, 1000)
          // if user clicks "extend session" on modal, refreshtoken is retrieved, local storage time is reset (DONE IN MODAL)
    },
    submitForm(state,form) {
      state.commit('setAuthorization');
      console.log('this is what gets submitted', this.state.verify);
      if (form.type == 'stock-take-submit') {
        axios.post(`/${form.type}`, form.submit, this.state.header).then(res => {
          console.log('form has been submitted', res);
          state.commit('updateCount', []);
          state.dispatch('getRefreshToken');
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
      } else {
        axios.post(`/location/${this.state.uuid}/${form.type}`,  form.submit, this.state.header).then(res => {
          console.log('form has been submitted', res);
          state.dispatch('getRefreshToken');
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
      }
    },
    getDrugRegister(state, form){
      state.commit('setResponsiblePersonError',  {state: false, errorMessage: ''});
      state.commit('setAuthorization');
      state.commit('setDrugEntries', []);
      state.commit('setLoading', {state: true, type: 'Drug Register'});
      form.filters.imprest_only = this.state.drugRegImprestToggle;
      form.filters.destruction_only = this.state.drugRegDestructionToggle;
      axios.post('/drug-register-entries', form, this.state.header).then(res => {
        console.log('this is the state function to get drug register', res.data);
        state.commit('setDrugEntries', res.data);
        state.commit('setLoading', {state: false, type: 'Drug Register'});
        state.dispatch('getRefreshToken');
      }).catch(err => {
        console.log(err.response);
        if(err.response.status == 403){
          state.commit('setResponsiblePersonError',  {state: true, errorMessage: err.response.data});
        }
        if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
            console.log('yes, it does include expired');
            state.commit('setLoading', {state: false, type: 'Drug Register'});
            router.push('/login');
        } 
      })
    },
    getReportEntries(state, form){
      state.commit('setAuthorization');
      state.commit('setReportError', '');
      state.commit('setLoading', {state: true, type: 'Report'});
      axios.post('/transaction-report-entries', form, this.state.header).then(res=>{
        console.log('this is the state function to get reports', res.data);
        state.commit('setReports', res.data);
        state.commit('setLoading', {state: false, type: 'Report'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError',"Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getStockReportEntries(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Stock Take'});
      state.commit('setReportError', '');
      axios.post('/stock-report-entries', form, this.state.header).then(res=>{
        console.log('this is the state function to get stock take reports', res.data);
        state.commit('setStockReports', res.data);
        state.commit('setLoading', {state: false, type: 'Stock Take'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError',"Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getAuditReportEntries(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Audit'});
      state.commit('setReportError', '');
      axios.post('/get-logs', form, this.state.header).then(res=>{
        console.log('this is the state function to get reports', res.data);
        state.commit('setAuditReports', res.data);
        state.commit('setLoading', {state: false, type: 'Audit'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError',"Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getIHIReportEntries(state, form) {
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'IHI'});
      state.commit('setReportError', '');
      axios.post('/audit-ihi', form, this.state.header).then(res=>{
        console.log('this is the state function to get reports', res.data);
        state.commit('setIHIReports', res.data);
        state.commit('setLoading', {state: false, type: 'IHI'});
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError', "Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    getEPrescribingReportEntries(state, form) {
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'EPrescribing'});
      state.commit('setReportError', '');
      axios.post('/get-eprescribing-logs', form, this.state.header).then(res => {
        console.log('this is the state function to get reports', res.data);
        state.commit('setEPrescribingReports', res.data);
        state.commit('setLoading', {state: false, type: 'EPrescribing'});
        state.dispatch('getRefreshToken');
      }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          } else{
            state.commit('setReportError', "Oh dear, we're very sorry! There has been an error. We're fixing this now so please check back later.");
            console.log('this is the error', this.state.reportError);
          }
      })
    },
    searchPatients(state, form){
      state.commit('setAuthorization');
      axios.post('/search-patients', form, this.state.header).then(res=>{
        console.log('this is the state function to search patients', res.data);
        state.commit('setPatients', res.data.patients);
        state.dispatch('getRefreshToken');
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },
    getStockTake(state, form){
      state.commit('setAuthorization');
      state.commit('setStockError', '');
      state.commit('setLoading', {state: true, type: 'Stock Take'});
        axios.post('/stock-take-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get stock entries', res.data);
          state.commit('setDrugEntries', res.data);
          state.dispatch('getRefreshToken');
          return res;
        }).then(data=>{
          if(data){
            this.state.drugEntries.data.forEach(item=>{
              this.state.stockTakeCount.forEach(stockItem=>{
                if(item.uuid == stockItem.uuid){
                  item.count = stockItem.count;
                }
              })
            });  
          }
          state.commit('setLoading', {state: false, type: 'Stock Take'});
        }).catch(err=>{
            if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }else{
              console.log('THIS IS ANOTHER ERROR', err.response.data);
              state.commit('setStockError', err.response.data);
            }
        })
    },
    getTransfers(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Transfers'});
      axios.post('/transfer-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get transfer entries', res.data);
          state.commit('setDrugEntries', res.data);
          state.commit('setLoading', {state: false, type: 'Transfers'});
          state.dispatch('getRefreshToken');
          return res;
        }).catch(err=>{
          // console.log(err.response.data)
            if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    getDeliveries(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Deliveries'});
      axios.post('/delivery-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get delivery entries', res.data);
          state.commit('setDrugEntries', res.data);
          state.commit('setLoading', {state: false, type: 'Deliveries'});
          state.dispatch('getRefreshToken');
          return res;
        }).catch(err=>{
          console.log('this is the err', err.response.data)
            if(err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    getDestructionItems(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Destructions'});
      axios.post('/destruction-entries', form, this.state.header).then(res=>{
          console.log('this is the state function to get destruction entries', res.data);
          state.commit('setDrugEntries', res.data);
          state.commit('setLoading', {state: false, type: 'Destructions'});
          state.dispatch('getRefreshToken');
          return res;
        }).catch(err=>{
            if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
    },
    submitDelivery(state, form){
      state.commit('setAuthorization');
      if(state) console.log('yes, state exists');
      axios.post('/new-delivery', form.submit, this.state.header).then(res=>{
        console.log('this is the state function to get transfer entries', res.data);
        state.dispatch('getRefreshToken');
        return res;
      }).catch(err=>{
          if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    },
    emptyStockCache(state){
      state.commit('updateCount', []);
    },
    getRequisitionNotifications(state){
      console.log('this is statue', this.state);
      if(this.state.currentLocation && this.state.currentLocation.uuid){
        axios.post('/get-requisition-notifications', {}, this.state.header).then(res=>{
          console.log('this is the state function to get requisition notification entries', res.data.data);
          // state.commit('setNumNewRequisitions', res.data.length);
          state.commit('setRequisitionNotificationItems', res.data);
          console.log('THIS IS THE CURRENT LOCATION', this.state.currentLocation)
          if(this.state.currentLocation.type == 'pharmacy'){
              // console.log('PHARMACY REQS', res.data.filter(item=>item.status=='pending').length);
              console.log('PHARMACY REQS');
              state.commit('setNumNewRequisitions', res.data.filter(item=>item.status=='pending').length);
          } else{
              // console.log('WARD REQS', res.data.filter(item=>item.status=='in transit' || item.status=='pickup ready').length);
              console.log('WARD REQS');
              state.commit('setNumNewRequisitions', res.data.filter(item=>item.status=='in transit' || item.status=='pickup ready').length);
          } 
          state.dispatch('getRefreshToken');
          return res;
        }).catch(err=>{
            console.log(err)
            if(err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
      } else{
        console.log('NOT GETTING ANY UUID HERE')
      }
    },
    getRequisitions(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Requisitions'});
      console.log('this is statue', this.state);
      if(this.state.currentLocation && this.state.currentLocation.uuid){
        axios.post('/get-requisitions', form, this.state.header).then(res=>{
          console.log('this is the state function to get requisition entries', res.data.data);
          // state.commit('setNumNewRequisitions', res.data.length);
          state.commit('setRequisitionEntries', res.data);
          console.log('THIS IS TEH CURRENT LOCATION', this.state.currentLocation)
          state.commit('setLoading', {state: false, type: 'Requisitions'});
          state.dispatch('getRefreshToken');
          return res;
        }).catch(err=>{
            console.log(err)
            if(err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
                console.log('yes, it does include expired')
                router.push('/login');
            }
        })
      } else{
        console.log('NOT GETTING ANY UUID HERE')
      }
    },
    getDrugList(state, form){
      state.commit('setAuthorization');
      state.commit('setLoading', {state: true, type: 'Drug List'});
      
      axios.post(form.companyList ? '/company-drugs' :'/non-company-drugs', {filter:form.filter, page: form.page}, this.state.header).then(res=>{
        console.log('this is the state function to get transfer entries', res.data);
        state.commit('setDrugEntries', res.data);
        state.commit('setLoading', {state: false, type: 'Drug List'});
        state.dispatch('getRefreshToken');
        return res;
      }).catch(err=>{
        // console.log(err.response.data)
          if (err.response && err.response.data && !err.response.data.message && err.response.data.split(' ').includes('expired')){
              console.log('yes, it does include expired')
              router.push('/login');
          }
      })
    }
  }
})











