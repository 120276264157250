<template>
    <div>
        <div class="alert-type-box">
        <div style="display: inline-block;display:flex ;justify-content: start;align-items: center; padding-left: 10px;">
            <h4 style="margin-bottom: 0px;margin-top: 20px;"><i class="pi pi-exclamation-circle"></i>{{ arrayOfAlertKind.AlertType }}</h4>
            <span style="margin-top: 20px;" class="alert-count">
                <p style="display: inline-block;">{{ numberOfAlerts }}</p>
            </span>
        </div>
        <div class="alerts-content-container" v-for="singlealert in alertsUnread" :key="singlealert.AlertType">
            <div style="align-self: end;">
                <div style="display: flex; padding-left: 40px;gap: 5px;">
                    <div style="display: grid;grid-template-rows: auto auto;text-align: left;">
                        <p v-if="singlealert.body.message.split('||').length && singlealert.body.message.split('||')[0]"
                        style="margin: 0px;color: #4E4E4E;font-weight: bolder;">{{ singlealert.body.message.split('||')[0] }}</p>
                        <p class="alert-item-content">{{ singlealert.body.message.split('||')[1] }}</p>
                    </div>
                    <div>
                        <button class="small-red-close-button">
                            <i class="pi pi-times" style="padding: 0" @click="dismissAlert(singlealert.id, singlealert.index)"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
</template>

<script>
export default {
    props: ['arrayOfAlertKind'],
    methods: {
        dismissAlert(id, index){
            this.$store.dispatch('readAlert', {'id': id, 'index': index});
        }
    },
    computed: {
        numberOfAlerts() {
            return Object.keys(this.arrayOfAlertKind.alerts).length
        },
        alertsUnread() {
            const alerts = Object.values(this.arrayOfAlertKind.alerts);
            return alerts.filter(alert => !alert.read)
        }
    }




}
</script>

<style lang="scss" scoped>

.alert-count {
    color: white;
    background-color: #E04F39;
    border-radius: 8px;
    font-size: 13px;
    margin-left: 10px;
    font-weight: bold;
    padding: 1px 5px;

    p {
        margin: 0;
    }
}
.alerts-content-container {
    display: flex;
    padding: 10px;
    // border-bottom: 1px solid #a4a4a4;
    // background-color: rgba(224,79,57, 0.5);
    // background-color: #f3f4f4;
    gap: 10px;
    justify-content: space-between;
}


.alert-item-content {
    margin: 0px;
    text-align: left;
}

.small-red-close-button {
    background-color: #E04F39;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    padding: 0px;
    cursor: pointer;
    margin: 1px 0;

    .pi {
        font-size: 0.7rem !important;
        color: white;
        font-weight: bold;
    }
}
</style>