<template>
    <div class="modal-mask">
        <div @click.self="closeModal" class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <h3 class="form-header">{{ statusMessage }} </h3>
                    <button @click="loadingDelay ? cancelAction() : closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div v-if="loadingDelay">
                                    <h3>Contacting Exchange...</h3>
                                    <!-- <h2 style="border-radius: 50px; padding: 5px; background-color: powderblue; border: 1px solid powderblue; margin: 0 auto; width: 45px;">{{first_spec_line}}</h2> -->
                                </div>
                                <div v-else>
                                    <h4>{{ message }}</h4>
                                    <div v-if="loading">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin: 2em;"></i>
                                    </div>
                                    <div v-if='first_spec_line || second_spec_line'
                                        style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                        <p style="font-size: 1em; text-align: left; ">{{ first_spec_line }}</p>
                                        <p style="font-size: 1em; text-align: left; ">{{ second_spec_line }}</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;"
                    v-if="!loading">
                    <slot name="footer" style="display: flex;">
                        <button class="blue-button" v-if="action && actionType == 'sign'" @click="signAction()">
                            Sign
                        </button>
                    </slot>
                </div>
                <div class="modal-footer" style="display: flex; justify-content:center; padding: 5px 20px;"
                    v-if="loadingDelay">
                    <slot name="footer" style="display: flex;">
                        <button class="grey-button" @click="cancelAction()">
                            Cancel Process
                        </button>
                    </slot>
                </div>
            </div>

        </div>
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :payload="payload"
            @close="closeSignatureModal" :endpoint="signature_endpoint" />
    </div>
</template>

<script>
import SignatureModal from './SignatureModal.vue';

export default {
    props: ['message', 'close', 'first_spec_line', 'second_spec_line', 'signature_endpoint', 'reloadPageOnClose', 'loading',
        'statusMessage', 'payload', 'action', 'actionType', 'loadingDelay'],
    components: {
        SignatureModal
    },
    data() {
        return {
            displaySignatureModal: false,
        }
    },
    methods: {
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.closeModal();
            }
        },
        signAction() {
            this.displaySignatureModal = true
            console.log('this is the payload', this.payload);
        },
        closeSignatureModal(value) {
            if (value) {
                this.displaySignatureModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }

        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
