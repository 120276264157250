<template>
    <div class="administration-round-list-container" style="width: fit-content">
            <div class="administration-search-filters">
                <div class="text-search-container search-container">
                <InputText v-model="searchTerm" style="width: 20em; background-color: #F3F4F4; opacity: 1" placeholder="Patient name"/>
                </div>
                <i class="pi pi-filter-slash" style="cursor:pointer" @click="clearFilters()"></i>
            </div>
            <div class="administration-round-results">
                <div>
                    <div v-for="(item, index) in searchList" :key="index" class="administration-round-item" style="border-radius: 0px; justify-content: start; cursor: pointer;" :class="selectedItem == index ? 'selected-round-item':''" @click="choosePatient(item.uuid, index)">
                        <img :src="item.image" style="width: 120px; border-radius: 8px;"/>
                        <div style="display: flex; align-items: center;">
                            <h4>{{item.personal_information.first_name}} {{item.personal_information.last_name}}</h4>
                        </div>
                        <div  v-if="loading" >
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                        </div>
                    </div>
                </div>
            </div>
            <PatientSummaryModal v-if="expandPatientSummaryModal" @close="close" :patientId="patientId" />
            
    </div>
</template>

<script>
import PatientSummaryModal from '../modals/PatientSummaryModal.vue';
// import axios from 'axios';

export default{
    props:['patients', 'loading'],
    components:{
        PatientSummaryModal
    },
    data(){
        return{
            expandPatientSummaryModal:false,
            patientId: '',
            searchTerm:'',
            selectedItem:-1,
            filters:{
                name:''
            },
            searchResult:'',
        }
    },
    methods:{
        choosePatient(patient, index){
            this.selectedItem = index;
            this.patientId = patient;
            // this.expandPatientSummaryModal = true;
            this.$router.push(`/patient/${patient}`)
        },
        close(value){
            if(value){
                this.expandPatientSummaryModal = false;
            }
        },
        clearFilters(){
            this.searchResult = '';
            this.searchTerm = '';
            this.filters = {name:''};
        },
        selectPatientRoom(){
            
        },
    },
    mounted(){
        this.$emit('loadPharmacyPatients', true);
    },
    computed:{
        searchList(){
            return this.patients.filter(patient=>{return patient.personal_information.first_name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            patient.personal_information.last_name.toLowerCase().includes(this.searchTerm.toLowerCase())})
        }
    }
}
</script>

<style lang="scss">
    .administration-round-list-container{
        background-color: white;
        border-radius: 8px;
    }
    .administration-search-filters{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
    }

    .administration-round-item{
        
        padding: 10px 5px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #f3f4f4;
        h4{
            text-align: left;
        }
    }

    .selected-round-item{
        background-color: rgba(8, 112, 220, 0.2);
    }
</style>