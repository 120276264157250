<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Manufacturing</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">
                                    <div>
                                        <p style="text-align: left; font-weight: bold;">Reference Number</p>
                                        <InputText name="refnum" id="refnum" placeholder="Reference" style="background-color: #E5F1FE; width: 100%"
                                         v-model="form.submit.reference_number"></InputText>
                                    </div>
                                    <div>
                                        <p style="text-align: left; font-weight: bold; margin-top: 10px">Manufacturing Direction</p>
                                        <div style="display: flex; align-items: center; margin-bottom: 10px">
                                            <div class="select-filters-container" style="width: 100%">

                                                <p @click="() => { form.submit.manufacturing_direction = 'in'; refinedDrugs = ''; form.submit.drugs = [] }"
                                                    v-bind:class="[form.submit.manufacturing_direction == 'in' ? 'active-select-filter' : '']"
                                                    style="width: 100%" class="select-filter-item">In</p>

                                                <p @click="() => { form.submit.manufacturing_direction = 'out'; refinedDrugs = ''; form.submit.drugs = []}"
                                                    v-bind:class="[form.submit.manufacturing_direction == 'out' ? 'active-select-filter' : '']"
                                                    style="width: 100%" class="select-filter-item">Out</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                        <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                        <textarea name="notes" id="note-modal" rows="20"
                                        placeholder=" Enter notes here..."
                                        style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                        v-model="this.form.submit.notes"></textarea>
                                    </div>
                                </div>
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">

                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p
                                                v-if='patient.regulatory_information && patient.regulatory_information.length'>
                                                <b>{{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].regulatory_type : ''
                                                }}:</b>
                                                {{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].data : ''
                                                }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information &&
                                                    patient.personal_information.dob ? patient.personal_information.dob :
                                                    'N/A'
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div> -->


                                    <!-- <div v-if="!imprest_pom">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    style="width: 100%;cursor: pointer;  border: 2px solid blue; border-radius: 8px; padding: 10px 0">
                                                    Patient Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                    style="width: 100%;cursor: pointer;  border: 2px solid blue; border-radius: 8px; padding: 10px 0; margin-right: 10px;">
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div> -->

                                    <!-- AFTER THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- v-if="imprest_pom == 'imprest' || (patient && transferType != 'Send')" -->
                                    <!-- <div 
                                        class="right-form-section-drug-entry">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div> -->

                                    <!-- PATIENT MEDICATION SEARCH -->
                                    <!-- <div class="right-form-section-drug-entry"
                                        v-if="patient && transferType != 'Receive'">
                                        <div style="width: 100%; margin-right: 10px">

                                            <select style="position: relative; width:100%; padding: 10px; "
                                                id="pom-select" @change="changeStock()">
                                                <option value="" disabled selected>Select medication</option>
                                                <option :value="index" v-for="(item, index) in patientMeds"
                                                    :key="index">{{ item.alias ? item.alias.name : '' }}
                                                    ({{ Number(item.quantity).toFixed(1) }} left)</option>
                                            </select>
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Quantity"
                                            style="position: relative; width: 30%; margin-right: 10px;" />
                                        <p class="submit-button blue-button" @click="() => { if (qty) { aliasSelect(stock) } }">
                                            Add</p>
                                    </div> -->

                                    <!-- DRUG VARIANCES -->
                                    <!-- MANUFACTURING IN -->
                                    <!-- <div v-if="form.submit.manufacturing_direction === 'in'">
                                        <div class="drug-variances-container" style="display: flex; height: fit-content"
                                            v-show='refinedDrugs && refinedDrugs.length'>
                                            <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                            <p v-for="(item, index) in refinedDrugs" :key="index"
                                                @click="aliasSelect(item)"
                                                style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                                {{ item.name.slice(0, 1).toUpperCase() + item.name.slice(1) }}
                                            </p>
                                        </div>
                                    </div> -->

                                    <!-- MANUFACTURING OUT -->
                                    <!-- v-else -->
                                    <!-- <div v-else>
                                        <div class="drug-variances-container" style="display: flex; height: fit-content"
                                            v-if='refinedDrugs && refinedDrugs.length'>
                                            <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                            <p v-for="(item, index) in refinedDrugs" :key="index"
                                                @click="item.qty_left >= qty ? aliasSelect(item) : ''"
                                                style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px;"
                                                :style="item.qty_left >= qty ? {border:'2px solid green', cursor: 'pointer'}:{border:'2px solid grey', color: 'grey', backgroundColor: '#f3f4f4'}">
                                                {{ item.alias.name.slice(0, 1).toUpperCase() + item.alias.name.slice(1) }} (In stock: <b :style="item.qty_left >= qty ? {}:{color: '#E04F39'}">{{ item.qty_left }}</b>)
                                            </p>
                                        </div>
                                        <div v-if="Array.isArray(refinedDrugs) && refinedDrugs.length == 0">
                                            <p style="font-size: 1em;">No stock found for that item.</p>
                                        </div>
                                    </div> -->

                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <div class="right-form-section-drug-entry" v-if="!(this.form.submit.manufacturing_direction == 'in' && (form.submit.drugs).length > 0)">
                                                <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="debounceSearchDrugs(), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true" style="width: 100%;" placeholder="Select Medication"/>
                        

                                        <input v-model="qty" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 25%" />
                                        <p class="submit-button blue-button" @click="() => { if (qty) { checkIfExistingItem(selectedDrug.full_item) } }">Add</p>

                                    </div>
                                    <div v-if="addingMed" style="display: flex; align-items: center; justify-content: center;">
                                        <span>Adding medication...</span>
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>

                                    <div class="right-form-section-drug-container">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock.slice(0, 1).toUpperCase() + item.stock.slice(1) }}</p>
                                                </td>

                                                <td>
                                                    <p>
                                                        <!-- IF ENTERED VALUE EXCEEDS STOCK QTY -->
                                                        <s v-if="item.qty != item.entered_qty"
                                                            style="">{{ item.entered_qty }}</s>
                                                        <span
                                                            :style="[item.entered_qty != item.qty ? { color: 'red' } : '']">&nbsp;
                                                            {{ item.qty }}</span> {{ item.qty != 'None in stock.' ?
                                                                    item.form : ''
                                                            }}{{ item.qty > 1 ? 's' : '' }} {{ form.submit.manufacturing_direction == 'out' ? (item.qty_left ? '(Stock qty:' + item.qty_left + ')' :
                                                                        '(Stock qty: 0)') : ''
                                                            }}
                                                    </p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style='padding: 0'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>


                            </div>



                        </form>
                    </slot>
                </div>
                <p style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <!-- submitManufacturing() -->
                        <p class="regular-button complete-button" style="margin-right: 10px;"
                            @click="submitManufacturing()">{{ transferType }} Submit</p>
                    </slot>
                </div>
            </div>
        </div>
        <NewLocationModal v-if="addNewLocation" @closeLocationModal="closeLocationModal"
            @addNewLocationToRefined="addNewLocationToRefined" />
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SignatureModal v-if="displaySignatureModal" @close="close" @isSuccessful="isSuccessful"
            :saveContext="saveContext" :payload='realSubmit' :endpoint='dynamicManufacturingEndpoint' :dual="dual" />
    </div>
</template>

<script>
import NewLocationModal from '@/components/modals/NewLocationModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import axios from 'axios';

export default {
    props: ['transferType'],
    components: {
        NewLocationModal,
        SignatureModal,
        PatientSearchModal
    },
    computed: {
        dynamicManufacturingEndpoint() {
            return this.form.submit.manufacturing_direction == "in" ? "/manufacture-in" : "/manufacture-out"
        }
    },
    data() {
        return {
            notes: "",
            loadingDrugs:false,
            selectedDrug:{},
            drugList:[],
            addingMed: false,
            dual: true,
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            noStock: false,
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            qty: null,
            location: '',
            addNewLocation: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: '',
            form: {
                submit: {
                    reference_number:'',
                    type: '',
                    drugs: [],
                    manufacturing_direction:'in',
                    notes: '',
                }
            },
            realSubmit: {
                type: '',
                drugs: [],
                manufacturing_direction:'in',
                notes: '',
            },
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'Transfer',
                patient: '',
                location: '',
                drugs: [],
                manufacturing_direction:'in',
                doubleSig: false
            }
        }
    },
    methods: {
        changeStock() {
            this.stock = this.patientMeds[document.getElementById('pom-select').value];
            this.qty = Number(this.stock.quantity).toFixed(1);
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        drugSelect(item) {
            this.loadingDrugs = true;
            this.drugList = [];
            this.stock = item.full_name;
            //console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = this.form.submit.manufacturing_direction == 'out'? item.alias.alias_id : item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = this.form.submit.manufacturing_direction == 'in' ? item.form[0].toUpperCase() + item.form.slice(1) : item.alias.form[0].toUpperCase() + item.alias.form.slice(1);
            this.selectedDrug.strength = `${this.form.submit.manufacturing_direction == 'out'? item.alias.strength : item.strength}`;
            this.selectedDrug.measurement_unit = `${this.form.submit.manufacturing_direction == 'out'? item.alias.measurement_unit : item.measurement_unit}`;
            this.selectedDrug.name = this.form.submit.manufacturing_direction == 'out'?  item.alias.name[0].toUpperCase() + item.alias.name.slice(1) : item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = this.form.submit.manufacturing_direction == 'out'? item.alias.tags : item.tags;
            this.loadingDrugs = false;
            console.log('selected');
        },
        checkIfExistingItem(item){
            let exit = false;
            if(this.form.submit.drugs.length){
                this.form.submit.drugs.forEach((drugItem, index) => {
                    // console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit ) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } else if(((index+1)==this.form.submit.drugs.length) && !exit){
                        console.log('HIT A END OF LIST', drugItem);
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else{
                this.aliasSelect(item);
            }
        },
        // aliasSelect(item) {
        //     console.log('this is the alias select item', item);
        //     this.addingMed = true;
        //     if (this.form.submit.manufacturing_direction == 'in') {
        //         console.log('hitting else condition')
        //         if (this.transferType == 'Receive') {
        //             this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, form: item.form, drug: item.alias_id, entered_qty: this.qty, qty: this.qty });

        //             // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
        //             this.$store.dispatch('getRefreshToken');
        //             this.qty = null;
        //             this.stock = '';
        //             this.refinedDrugs = [];
        //             this.selectedDrug = {};
        //             this.addingMed = false;
        //             this.noStock = false
        //         } else {
        //             this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, form: item.alias.form, qty_left: item.quantity, drug: item.alias.alias_id, entered_qty: this.qty, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity) });
        //             this.selectedDrug = {};
        //             this.stock = '';
        //             this.addingMed = false;
        //         }
        //     } else {
        //             this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left });
        //             this.qty = null;
        //             this.refinedDrugs = '';
        //             this.stock = '';
        //             this.selectedDrug = {};
        //             this.noStock = false;
        //             this.addingMed = false;
        //     }
        // },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                 if (this.form.submit.manufacturing_direction == 'in') {
                    let headers = {
                        "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                        "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
                    }

                    axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`, { params: `query=${this.stock}&hitsPerPage=5`},
                        { headers }).then(res => {
                            // console.log('this is the algolia res', res.data.hits);
                            let algoliaResults = res.data.hits;

                            algoliaResults.forEach(item=>{
                                item.full_name = `(${item.generic_name}) ${item.name}`
                            })
                            this.drugList = algoliaResults;
                            this.errorMessage = '';
                            this.loadingDrugs = false;
                        }).catch(err => {
                            this.errorMessage = err.response.data;
                            console.log('err', err.response.data);
                        })
                    




                    // axios.post('/get-drug-aliases', { drug: this.stock }, this.$store.state.header).then(res => {
                    //     console.log('this is the res data', res.data);
                    //     this.$store.dispatch('getRefreshToken');
                    //     res.data.drug_aliases.forEach(item=>{
                    //         item.full_name = `(${item.generic_name}) ${item.name}`
                    //     })
                    //     this.drugList = res.data.drug_aliases;
                    //     this.errorMessage = '';
                    //     this.loadingDrugs = false;
                    // }).catch(err => {
                    //     this.errorMessage = err.response.data;
                    //     console.log('err', err.response.data);
                    // })
                } else {
                    axios.post('/get-stock-items', { drug: this.stock }, this.$store.state.header).then(res => {
                        console.log('this is the res data getstockitems', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.forEach(item=>{
                            item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`
                        })
                        this.drugList = res.data;
                        this.errorMessage = '';
                        this.loadingDrugs = false;
                    }).catch(err => {
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                        console.log('this is the transfer stock items error', err.response.data);
                        this.errorMessage = err.response.data;
                    })
                }
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            // { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        isSuccessful(value) {
            if (value) {
                console.log('IS SUCCESSFUL!');
                if (this.$route.name == 'Stock') {
                    console.log('loading the stock stuff');
                } 
                if (this.addNew) {
                    this.form.submit.drugs = [];
                    this.stock = '';
                    this.imprest_pom = '';
                    this.patient = '';
                    this.patient_uuid = '';
                    this.prescriber_uuid = '';
                    this.selectedPrescriber = '';
                    this.refinedDrugs = '';
                } else {
                    this.closeModal();
                }
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                // this.dual = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);
            if(this.form.submit.manufacturing_direction === 'in'){
                this.form.submit.drugs.unshift({ tags: item.tags, stock: item.full_name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: item.qty_left });
            } else{
                this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: `(${item.alias.generic_name}) ${item.alias.name}`, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left })
            }
            this.qty = null;
            this.stock = '';
            this.refinedDrugs = [];
            this.selectedDrug = {};
            this.addingMed = false;
            this.noStock = false
        },
        checkAndAddStock(drugName) {
                if(this.form.submit.manufacturing_direction === 'in'){
                    axios.post('/get-drug-aliases', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                        this.$store.dispatch('getRefreshToken');
                        this.refinedDrugs = res.data.drug_aliases;
                        this.errorMessage = '';
                    }).catch(err => {
                        this.errorMessage = err.response.data;
                        console.log('err', err.response.data);
                    })
                } else{
                    if (drugName) {
                        axios.post('/get-stock-items', { drug: drugName }, this.$store.state.header).then(res => {
                            console.log('this is the res data', res.data);
                            this.$store.dispatch('getRefreshToken');
                            this.refinedDrugs = res.data;
                            this.errorMessage = '';
                        }).catch(err => {
                            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                                console.log('yes, it does include expired')
                                this.$router.push('/login');
                            }
                            this.errorMessage = err.response.data;
                            console.log('err', err.response.data);
                            console.log('this is the  stock item error', err);
                        })
                    }
                }
                

        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        submitManufacturing() {
            let newDrugSub = [];
            let autoDrugs = [];
            this.addNew = false;
            console.log('HERE ARE THE SUBMIT RECEIVE DRUGS', this.form.submit.drugs);
            this.form.submit.drugs.forEach(item => {
                let p = {
                    stock_id: item.drug,
                    drug: item.drug,
                    qty: Number(item.qty)
                }
                let ad = {
                    stock_id: item.stock,
                    drug: item.stock,
                    qty: Number(item.qty)
                }
                autoDrugs.push(ad);
                // if(item.tags && item.tags.length){
                //     this.dual = true;
                // }

                if (item.qty > 0) {
                    newDrugSub.push(p);
                }
            });
            this.realSubmit = {
                drugs: newDrugSub,
                notes: this.form.submit.notes,
                reference_number: this.form.submit.reference_number
            };
            
            this.saveContext = {
                drugs: autoDrugs,
                location: JSON.parse(localStorage.getItem('currentLocation')).name,
                patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : '',
                supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                doubleSig: true,
            }

            this.realSubmit.drugs = newDrugSub;
            console.log('this is the form to submit', newDrugSub);
            console.log(this.form.submit.drugs.length && this.form.submit.type)
            if (this.form.submit.drugs.length && this.form.submit.reference_number) {
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drug/s and reference number are required.'
            }

        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                // {uuid: this.patient.uuid}

                // axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                //     console.log('this is the patient register', res);
                //     this.patientMeds = res.data;
                // })
            }
        },
    },
    mounted() {
        this.form.submit.type = this.transferType;
        let myHost = window.location.host;

        if(myHost.includes('hospital') && myHost.includes('staging')){
            this.$store.commit('setSubdomain', 'st-hp')
        } else if(myHost.includes('hospital')){
            this.$store.commit('setSubdomain', 'prod')
        } else{
            this.$store.commit('setSubdomain', 'st-hp')
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


 

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}
</style>
