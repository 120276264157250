<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div v-if="!submitLoading" class="modal-container" style="min-width: 33.25rem; width: 33.25rem">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                            <h3>Copy To</h3>
                        </div>
                        <button @click="closeModal()" class="red-close-button" style="width: 30px !important">
                            <i class="pi pi-times" style="padding: 0"></i>
                        </button>
                    </slot>

                </div>


                <div class="modal-body" style="padding-bottom: 20px; padding-left: 20px;padding-right: 20px;">
                    <h4 style="text-align: left; font-weight: normal; margin-top: 0%;">Select location(s) that you would
                        like to copy the selected stock list over to. Please Note: This action <b>will</b> overwrite any
                        existing
                        stock list in the chosen destination. </h4>
                    <div style="display: grid; grid-template-row: 33px 33px%;grid-gap: 10px;">
                        <!-- FILTERS -->
                        <!-- <div style="display: grid; grid-template-columns: 16% 29% 29% 29%">
                            <div class="flex align-items-center">
                                Filters:
                            </div>
                            <div class="flex align-items-center">
                                <Checkbox v-model="Wardfilter" inputId="fil1" name="wards" value="wards" :binary="true" />
                                <label for="fil1" class="ml-2"> Wards </label>
                            </div>
                            <div class="flex align-items-center">
                                <Checkbox v-model="Pharmfilter" inputId="fil2" name="pharmacies" value="pharmacies" :binary="true" />
                                <label for="fil2" class="ml-2"> Pharmacies </label>
                            </div>
                            <div class="flex align-items-center">
                                <Checkbox v-model="Theaterfilter" inputId="fil3" name="theatres" value="theatres" :binary="true" />
                                <label for="fil3" class="ml-2"> Theatres </label>
                            </div>
                        </div> -->
                        <div style="display: grid; grid-template-columns: 80% 20%;grid-gap: 10px;">
                            <MultiSelect v-model="selectedLocations" :options="locationsList" optionLabel="name"
                                :maxSelectedLabels="3" placeholder="Select Location" filter>
                                <template #option="slotProps">
                                    <div style="display: grid; grid-template-columns: 70% 30%;">
                                        <p style="margin: auto; text-align: left; width: 100%;">{{ slotProps.option.name }}
                                        </p>
                                        <!-- <Tag rounded>{{ slotProps.option.type.charAt(0).toUpperCase() +
                                    slotProps.option.type.slice(1) }} </Tag> -->
                                    </div>
                                </template>
                            </MultiSelect>

                            <button class="blue-button" @click="copyMedList()">Copy</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="modal-container" style="min-width: 500px; width: 500px; margin: 0 auto; display: grid;">
                <p style="font-size: 1em;font-weight: 500; text-align: center">Copying, please wait...</p>
                <i class="pi pi-spin pi-spinner"
                    style="font-size: 2rem !important;padding-top: 1em; padding-bottom: 1em; color: green; margin: 0 auto"></i>
            </div>
            <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { toRaw } from 'vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';


export default {
    props: ['medItems', 'copyLocation', 'locationsList'],
    components: {
        SuccessModal
    },
    data() {
        return {
            selectedLocations: [],
            Wardfilter: true,
            Pharmfilter: true,
            Theaterfilter: true,
            submitLoading: false,
            displaySuccessModal: false,
            successMessage: ""
        }
    },
    methods: {
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        copyMedList() {
            console.log("copy that")
            this.submitLoading = true;
            this.displaySuccessModal = true;
            this.successMessage = 'Copying Stock List...';
            let payload = [];

            const medItemRaw = toRaw(this.medItems)
            console.log(medItemRaw)

            medItemRaw.forEach(item => {
                if ((item.drug.drug_uuid || item.drug.alias_id)) {
                    payload.push({
                        minimumQuantity: item.minimumQuantity,
                        maximumQuantity: item.maximumQuantity,
                        drug: item.drug.drug_uuid ? item.drug.drug_uuid : item.drug.alias_id
                    })
                }
            })


            const locationUuids = []
            this.selectedLocations.forEach(item => {
                locationUuids.push(item.uuid)
            })

            // Endpoint can handle updating multiple facilties
            axios.post(`/location/stock-list/save`, { locations: locationUuids, stock_items: payload }, this.$store.state.header).then(res => {
                console.log('saved stock list', res.data);
                this.submitLoading = false;
                this.successMessage = 'Copy Completed';
                this.displaySuccessModal = true;
                this.$emit('close', true);
                this.$store.dispatch('getRefreshToken');
            }).catch(err => {
                console.log(err.response.data)
                this.successMessage = err.response.data;
                this.submitLoading = false;
                this.displaySuccessModal = true;
                this.$store.dispatch('getRefreshToken');
            })
        },
    },
    mounted() {

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 



.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>