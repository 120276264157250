<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 90%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">New Delivery</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 2fr 4fr; grid-gap: 0px">


                                <div class="left-form-section-panel">
                                    <div>
                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <!-- SELECT DROPDOWN SUPPLIER -->
                                        <div style="align-items: center; margin-bottom: 5px;">
                                            <div style="position: relative;">
                                                <Dropdown v-model="searchedSupplier" placeholder="Enter supplier name" ref="supplier" @change="locationSelect()" @keyup="debounceSearchSuppliers()" 
                                            :loading="loadingSupplier" :options="refinedLocations" optionLabel="name" :editable="true"
                                            style="width: 20em; display: flex"/>
                                            </div>
                                        </div>
                                        <!-- DATE RECEIVED -->
                                        <Calendar v-model="form.submit.delivery_date" placeholder=" Date"
                                            dateFormat="dd/mm/yy" class="text-search-container search-container"
                                            style="display: flex; margin-bottom: 5px;" />
                                        <!-- <input v-model="form.submit.delivery_date" placeholder="Date Received e.g. 30/10/2021" style="position: relative; width: 100%; margin-bottom: 5px; background-color:#E5F1FE" /> -->
                                        <!-- REFERENCE NUMBER -->
                                        <input v-model="form.submit.reference_number" placeholder="Reference Number"
                                            style="position: relative; width: 100%; margin-bottom: 5px; background-color:#E5F1FE" />
                                        <p class="note-header">Notes</p>
                                        <textarea name="notes" id="note-modal" rows="16"
                                            placeholder="Enter notes here..."
                                            style="width: 100%; border-top-right-radius: 0px; border-top-left-radius: 0px;"
                                            v-model="form.submit.notes"></textarea>
                                    </div>
                                    <div>
                                        <!-- <DualVerify @user="getUsername" @pass="getPassword" :password="form.submit.password"  :username="form.submit.username"
                            @user2="getUsername2" @pass2="getPassword2" :password2="form.submit.password2"  :username2="form.submit.username2" class="single-width-verify" style='justify-content: flex-start'/> -->
                                    </div>
                                </div>
                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p v-if="patient.regulatory_information.length"><b >Medicare Number:</b> {{ patient.regulatory_information[0].data }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>

                                    <!-- <div v-if="patient">
                                        <div style="display: flex; align-items: center;">
                                            <div v-if="!selectedPrescriber"
                                                class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search for
                                                    Prescriber</label>
                                                <input v-model="gp" v-on:keyup="gpSearch(gp)"
                                                    placeholder="Enter Prescriber No. or Name"
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="gp"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in prescriberResults" :key="index"
                                                        @click="selectPrescriber(item)">{{ item.regulatory_numbers.length
                                                                ? item.regulatory_numbers[0].data : ''
                                                        }} - {{ item.first_name }}
                                                        {{ item.last_name }}</p>
                                                </div>
                                            </div>
                                            <div v-if="selectedPrescriber"
                                                style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 15px;">
                                                <p style="font-size: 0.9em; text-align: left;"><b>Prescriber:</b>
                                                    {{ selectedPrescriber.first_name }} {{ selectedPrescriber.last_name }}
                                                </p>
                                                <p style="font-size: 0.9em"><b>Prescriber Number:
                                                    </b>{{ selectedPrescriber.prescriber_no }}</p>
                                            </div>
                                            <p style="text-align: left;  cursor:pointer; width: fit-content;
                                        border-bottom: 1px solid grey; margin-top: 0; margin-bottom: 0;"
                                                v-if="selectedPrescriber.uuid" @click="changePrescriber()">Change
                                                Prescriber</p>
                                        </div>
                                    </div> -->
                                    <!-- <div v-if="!imprest_pom">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    style="width: 100%;cursor: pointer;  border: 2px solid blue; border-radius: 8px; padding: 10px 0">
                                                    Patient Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                    style="width: 100%;cursor: pointer;  border: 2px solid blue; border-radius: 8px; padding: 10px 0; margin-right: 10px;">
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div> -->

                                    <!-- <div class="right-form-section-drug-entry"
                                        v-if="imprest_pom == 'imprest' || (patient)">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div>


                                    <div class="drug-variances-container" style="display: flex; height: fit-content"
                                        v-show='refinedDrugs.length'>
                                        <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                        <p v-for="(item, index) in refinedDrugs" :key="index" @click="aliasSelect(item)"
                                            style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                            {{ item.name }}
                                        </p>
                                    </div> -->


                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <div class="right-form-section-drug-entry" style="gap: 10px;">
                                        <!-- <div style="width: 50%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div> -->
                                        <div style="align-items: center; width: 100%;">
                                            <div style="position: relative;">
                                                <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="debounceSearchDrugs(), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true" style="width: 100%; display: flex" placeholder="Select Medication"/>
                                            </div>        
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 20%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { aliasSelect(selectedDrug.full_item) } }">Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>
                                    <div v-if="addingMed" style="display: flex; align-items: center; justify-content: center;">
                                        <span>Adding medication...</span>
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>
                                    <div class="left-form-section-drug-container"
                                        v-if="form.submit.drugs && form.submit.drugs.length > 0">
                                        <table style="border-collapse: collapse">
                                            <tr>
                                                <th>Drug Name, Strength, Form</th>
                                                <th v-if="$store.state.blind_count_settings">Enter Blind Count</th>
                                                <th v-else></th>
                                                <th></th>
                                                <th>Qty Received</th>
                                                <th v-if="!$store.state.blind_count_settings">Current Stock</th>
                                                <th></th>
                                            </tr>
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock }}</p>
                                                </td>
                                                <td v-if="item.stock_id && $store.state.blind_count_settings">
                                                    <input type="number" :disabled="item.valid" style="background-color: white; width: fit-content;" :style="item.last_count ? {marginTop: '20px'}:{}" v-model="item.count">
                                                    <p v-if="item.last_count">Last Count: {{ item.last_count }}</p>
                                                </td>
                                                <td v-else>
                                                    <p v-if="$store.state.blind_count_settings">No stock recorded.</p>
                                                </td>
                                                <td v-if="item.stock_id && $store.state.blind_count_settings">
                                                    <p class="blue-button" style="padding:7px 10px; text-align: center" @click="verifyCount(item)" v-if="!item.valid || !item.count">Verify Count</p>
                                                    <p v-if="item.valid" class="green-button" style="padding:7px 10px; text-align: center">Counted</p>
                                                </td>
                                                <td v-else>   
                                                </td>
                                                <td>
                                                    <p>{{ item.qty }}</p>
                                                </td>
                                                <td v-if="!$store.state.blind_count_settings">
                                                    <p>{{ item.qty_left }}</p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times"
                                                                style='padding: 0; padding-top: 3.5px;'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style=" margin-right: 10px;"
                            @click="submitTransfer(false)">Receive Delivery</p>
                        <p class="regular-button complete-button" @click="submitTransfer(true)">Receive Delivery
                            + New</p>
                    </slot>
                </div>
            </div>
        </div>
        <NewCompanyModal v-if="addNewSupplier" @closeLocationModal="closeLocationModal"
            @addNewCompanyToRefined="addNewCompanyToRefined" companyType='Supplier' />
        <SignatureModal @close="close" v-if="displaySignatureModal" @isSuccessful="isSuccessful"
            :endpoint="$store.state.blind_count_settings && realSubmit2.stocktake.length ? '/stock-take-submit' : '/new-delivery'" :payload="$store.state.blind_count_settings && realSubmit2.stocktake.length ? realSubmit2 : realSubmit"
            :endpoint2="$store.state.blind_count_settings && realSubmit2.stocktake.length ? '/new-delivery' : ''" :payload2="$store.state.blind_count_settings && realSubmit2.stocktake.length ? realSubmit : null"  dual="true" />
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SuccessModal v-if="displaySuccessModal" @close="close"
        :message="'There is a discrepancy between what has been entered and the actual stock count. \n Please repeat the count. If discrepancy is repeated, a balance check will be submitted .'" />

    </div>
</template>

<script>
import SuccessModal from '@/components/modals/SuccessModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import NewCompanyModal from '@/components/modals/NewCompanyModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import axios from 'axios';

export default {
    props: ['transferType'],
    components: {
        SuccessModal,
        SignatureModal,
        NewCompanyModal,
        PatientSearchModal
    },
    data() {
        return {
            displaySuccessModal: false,
            invalidCount: false,
            addingMed: false,
            imprest_pom: 'imprest',
            pom: '',
            patient: '',
            selectedDrug:{},
            drugList:[],
            gp: '',
            prescriberResults: [],
            addNew: false,
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: [],
            qty: null,
            loadingSupplier: false,
            searchedSupplier:[],
            refinedLocations: [],
            supplier: '',
            loadingDrugs:false,
            addNewSupplier: false,
            noLocationFound: false,
            refinedDrugs: [],
            realSubmit: {},
            realSubmit2: {},
            timeout:400,
            form: {
                submit: {
                    patient_uuid: '',
                    supplier: '',
                    delivery_date: '',
                    notes: '',
                    drugs: [],
                    reference_number: '',
                }
            }
        }
    },
    methods: {
        verifyCount(drug){
            console.log('verifying count', drug);
            if(drug.count != drug.qty_left){
                console.log('doesnt match', drug);
                if(!drug.last_count){
                    drug.valid = false;
                    drug.last_count = drug.count;
                    this.displaySuccessModal = true;
                } else{
                    
                    drug.valid = true;
                }
                
                
            } else{
                console.log('matches', drug);
                drug.valid = true;
            }
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        debounceSearchSuppliers() {
            this.hideSuppliers()
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchSupplier()
            }, 400); // delay
        },
        showSuppliers(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingSupplier && this.refinedLocations.length) {
                    clearInterval(interval);
                    this.$refs.supplier.show();
                }
            }, 100)
        },
        hideSuppliers(){
            this.$refs.supplier.hide();
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;

                let headers = {
                    "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                    "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
                }

                axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`, { params: `query=${this.stock}&hitsPerPage=5`},
                    { headers }).then(res => {
                        // console.log('this is the algolia res', res.data.hits);
                        res.data.hits.forEach(item=>{
                            item.full_name = `(${item.generic_name}) ${item.name}`;
                        })
                        this.drugList = res.data.hits;
                        console.log(this.drugResults);
                        this.drugSelect(this.drugList[0]);
                        this.loadingDrugs = false;
                    }).catch(err => {
                        console.log('error getting drugs from algolia', err);
                        console.log('quering kraken');
                        this.searchDrugsInKraken();
                        this.loadingDrugs = false;
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                    })
                
            }
        },
        searchDrugsInKraken(){
            if(this.stock){
                axios.post('/get-drug-aliases', { drug: this.stock, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
                        console.log('this is the res data', res.data);
                        res.data.drug_aliases.forEach(item=>{
                            item.full_name = `(${item.generic_name}) ${item.name}`;
                        })
                        
                        this.drugList = res.data.drug_aliases;

                        this.drugSelect(this.drugList[0]);
                        this.loadingDrugs = false;
                        // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
                    }).catch(err => {
                        console.log('this is the err', err);
                        this.loadingDrugs = false;
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                    })
            }
        },
        searchSupplier(){
            
            if (this.searchedSupplier) {
                this.loadingSupplier = true;
                axios.post('/get-companies', { name: this.searchedSupplier, type: 'Supplier' }, this.$store.state.header).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedLocations = res.data;
                    if(this.refinedLocations.length){
                        this.$refs.supplier.show();
                    }
                    this.loadingSupplier = false;
                    this.showSuppliers()
                    
                });
                if (this.refinedLocations.length == 1) {
                    this.form.submit.supplier = this.refinedLocations[0].uuid;
                    this.supplier = this.refinedLocations[0].name;
                }
            }
        },
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = `${item.strength}`;
            this.selectedDrug.measurement_unit = `${item.measurement_unit}`;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
            console.log('this is the selected drug item', this.selectedDrug);
            // this.drugList = [];
            // this.searchedDrugName = item.name;
            // this.clearDrug = true;
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                this.displaySuccessModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            // { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
            this.prescriberResults = [];
            console.log('this is the drug', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`, 
            // params: `query=${prescriber}&hitsPerPage=5`,
            { 
              requests:[
                
                { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}&facetFilters=company_uuid:${this.$store.state.company.uuid}` },
              ]  
            },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);
                    res.data.results.forEach(indexResult=>{
                        console.log('these are the results for this particular index', indexResult);
                        if(indexResult.hits.length){

                            // results.push(indexResult.hits);
                            indexResult.hits.forEach(hit=>{
                                this.prescriberResults.push(hit);
                            })
                            console.log('this is the local results varaible', this.prescriberResults);
                        }
                        
                    })
                    // this.prescriberResults = res.data.hits;
                    // state.commit('setDrugNames', res.data.hits);
                })

        },
        selectPrescriber(prescriber) {
            console.log('this is the selected result', this.nonGP);
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
            }
        },
        isSuccessful(value) {
            console.log("addNew"+this.addNew);
            console.log('IS SUCCESSFUL IS BEING CALLED', value);
            if (this.addNew == false) {
                console.log('re-loading deliveries register');
                if(this.$route.name == 'Deliveries'){
                    this.$store.dispatch('getDeliveries', {
                        sort_direction: 'desc',
                        col: 'completed_date',
                        page: 1,
                        filters: {
                            tags: [],
                            status: ['completed', 'reversed'],
                            supplier: '',
                            drug: '',
                            date: {
                                start: '',
                                end: ''
                            }
                        },
                    });
                } 
                this.closeModal();
            } else if (this.addNew) {
                this.form.submit.drugs = [];
                //this.imprest_pom = '';
                this.patient = '';
                this.selectedPrescriber = '';
                this.stock = '';
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewSupplier = false;
            }
        },
        addNewCompanyToRefined(value) {
            this.form.submit.supplier = value.uuid;
            this.refinedLocations = [value];
            console.log('RECEIVING FROM ADD LOCATION', value);
            this.supplier = value.name;
        },
        locationSelect() {
            this.supplier = this.searchedSupplier.name;
            this.form.submit.supplier = this.searchedSupplier.uuid;
            // this.$refs.supplier.hide();
        },
        stockTakeSubmit(){
            let payload = {
                notes: `Stock check for delivery.`,
                blind_count: true,
                stocktake:[
                    // {
                    //   uuid: '92342' ,
                    //   count: 55,
                    // }
                ]
            }

            this.form.submit.drugs.forEach(drug=>{
                if(drug.stock_id){
                    payload.stocktake.push({
                        uuid: drug.stock_id,
                        count: drug.count
                    })
                }
                
            })
            this.realSubmit2 = payload;
            
        },
        aliasSelect(item) {
            
            console.log('ALIAS SELECT ITEM',item);
            this.drugList = [];
            let drug =  item;
            this.stock = '';
            this.selectedDrug = {};
            // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:item.name, drug: item.alias_id, form: item.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: 0});
            // this.qty = null;
            // this.refinedDrugs = []; 
            // this.$store.dispatch('getRefreshToken');
            if (this.form.submit.drugs.length) {
                this.form.submit.drugs.forEach(drugItem => {
                    if ((drug.alias_id == drugItem.drug) ) {
                        if(this.patient && drugItem.patient &&  this.patient.uuid == drugItem.patient.uuid){
                            console.log('HITTING MATCH FOR PATIENT AND DRUG');
                            drugItem.qty = this.qty;
                            this.$store.dispatch('getRefreshToken');
                            this.qty = null;
                            this.refinedDrugs = [];
                            this.noStock = false;
                            this.selectedDrug = {};
                            this.stock = '';
                        }  else if(!drugItem.patient && !this.patient){
                            console.log('HITTING MATCH FOR IMPREST');
                            drugItem.qty = this.qty;
                            this.$store.dispatch('getRefreshToken');
                            this.qty = null;
                            this.refinedDrugs = [];
                            this.noStock = false;
                            this.selectedDrug = {};
                            this.stock = '';
                        } else{
                            console.log('NO MATCH FOR PATIENT OR DRUG');
                            this.addingAliasToList(drug);
                        } 
                    } else {
                        console.log('NO MATCH FOR PATIENT OR DRUG');
                        this.addingAliasToList(drug);
                    }
                })
            } else {
                console.log('no drug is in the list yet');
                this.addingAliasToList(drug);
            }
        },
        addingAliasToList(item) {
             this.addingMed = true;
            console.log('addingAliasToList function is running', item, this.$store.state.currentLocation.uuid);
            if ((this.imprest_pom == 'imprest')) {
                console.log('addingAliasToList -- IMPREST')
                axios.post('/get-stock', { alias_id: item.alias_id, location_id: this.$store.state.currentLocation.uuid }, this.$store.state.header).then(res => {
                    if (this.qty) {
                        console.log('this is the quantity if condition', res, this.qty, res.data.qty_left, this.qty > res.data.qty_left);
                        this.form.submit.drugs.unshift({ tags: item.tags, stock: res.data.alias.name, form: item.form, drug: item.alias_id, qty_left: res.data.qty_left, qty: this.qty });
                    }

                    // this.form.submit.drugs.unshift({tags: item.alias.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
                    this.$store.dispatch('getRefreshToken');
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.noStock = false;
                    this.selectedDrug = {};
                    this.addingMed = false;
                }).catch(err => {
                    console.log('err is', err, err.response.data);
                    // if (err.response.data == 'No stock has been recorded on this location for that drug alias.' || err.response.data == 'For some reason this location does not have a stock register'
                    // || (err.response.data.errors && err.response.data.errors.locationUUID && err.response.data.errors.locationUUID[0] == "There is no stock in the register.")) {
                    //     console.log('hitting the if condition');
                    //     if (this.qty) {
                    //         this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: 0 });
                    //     }
                    // }
                    this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: 0 });
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.selectedDrug = {};
                    this.addingMed = false;
                })
            } else {
                console.log('addingAliasToList -- ROM')

                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    // axios.post('/get-stock', {alias_id:item.alias_id, location_id: JSON.parse(localStorage.getItem('currentLocation')).uuid, patient_id: this.patient.uuid }, this.$store.state.header).then(res=>{
                    console.log('this is the quantity receive condition', res, item, this.qty, res.data.qty_left, this.qty > res.data.qty_left);
                    if (res.data.length) {
                        for (var i = 0; i < res.data.length; i++) {
                            if (this.qty) {
                                if (res.data[i].alias.alias_id == item.alias_id) {

                                    if (this.form.submit.drugs[this.form.submit.drugs.indexOf(item)] > -1) {
                                        this.form.submit.drugs[this.form.submit.drugs.indexOf(item)].qty = this.qty;
                                        break;
                                    } else {
                                        this.form.submit.drugs.unshift({ tags: res.data[i].alias.tags, stock: res.data[i].alias.name, form: res.data[i].alias.measurement_unit, drug: res.data[i].alias.alias_id, qty_left: res.data[i].quantity, qty: this.qty });
                                        break;
                                    }
                                } else {
                                    if (this.form.submit.drugs[this.form.submit.drugs.indexOf(item)] > -1) {
                                        this.form.submit.drugs[this.form.submit.drugs.indexOf(item)].qty = this.qty;
                                        break;
                                    } else {
                                        this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: 0 });
                                        break;
                                    }
                                }
                            }

                        }
                    } else {
                        console.log('no drugs in array, hitting else condition here');
                        if (this.qty) {
                            this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: 0 });
                        }

                    }
                    // this.form.submit.drugs.unshift({tags: item.tags,stock:res.data.drug_alias, 'drug': item.alias_id, form: res.data.form, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), qty_left: res.data.qty_left});
                    this.$store.dispatch('getRefreshToken');
                    this.noStock = false;
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.selectedDrug = {};
                    this.addingMed = false;

                }).catch(err => {
                    console.log('err is', err);
                    if (err.response?.data == 'No stock has been recorded on this location for that drug alias.') {
                        console.log('hitting the if condition');
                        if (this.qty) {
                            this.form.submit.drugs.unshift({ tags: item.tags, stock: item.name, drug: item.alias_id, form: item.form, qty: this.qty, qty_left: 0 });
                        }

                    }
                    this.qty = null;
                    this.refinedDrugs = [];
                    this.selectedDrug = {};
                    this.addingMed = false;
                })

                // }
                //  else{
                //     this.form.submit.drugs.unshift({tags: item.alias.tags,stock: item.alias.name, form:item.alias.form, qty_left:item.quantity, drug:item.alias.alias_id, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity)});
                // }
                // console.log('this is the quantity else condition', this.qty, Number(res.data.qty_left), this.qty > Number(res.data.qty_left))

            }
        },
        checkAndAddStock(drugName) {
            if (drugName) {
                axios.post('/get-drug-aliases', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the drug alias names', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.drug_aliases;
                })

            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        submitTransfer(addNewStatus) {
            this.addNew = addNewStatus;
            console.log('this is the form to submit', this.form);
            if(this.$store.state.blind_count_settings){
                this.stockTakeSubmit();
            }
            
            // this.form.submit.drugs.forEach(drug=>{
            //     delete drug.qty_left;
            //     delete drug.stock;
            //     delete drug.form;
            //     delete drug.tags;
            //     drug.qty = Number(drug.qty);
            // })
            
            let newDrugSub = [];
            this.form.submit.drugs.forEach(item => {
                let p = {
                    drug: item.drug,
                    qty: Number(item.qty)
                }
                if (item.tags && item.tags.length) {
                    this.dual = true;
                }

                if (item.qty > 0) {
                    newDrugSub.push(p);
                }
            });
            // this.$store.dispatch('submitDelivery',this.form);
            // this.refinedLocations = [];
            // this.form = {
            //     submit:{
            //         location:'',
            //         date_received:'',
            //         notes: '',
            //         drugs:[],
            //         reference_number:'',
            //     }
            // }


            if (newDrugSub.length && this.form.submit.reference_number && this.form.submit.delivery_date) {
                // if(this.patient){
                //     this.form.submit.patient_uuid = this.patient.uuid;
                //     this.form.submit.prescriber_uuid = this.selectedPrescriber.uuid
                // }
                this.realSubmit = {
                    drugs: newDrugSub,
                    notes: this.form.submit.notes,
                    patient_uuid: this.patient ? this.patient.uuid : '',
                    delivery_date: new Date(this.form.submit.delivery_date).toLocaleDateString('en-AU'),
                    reference_number: this.form.submit.reference_number,
                    supplier: this.form.submit.supplier
                };
                // this.form.submit.delivery_date = new Date(this.form.submit.delivery_date).toLocaleDateString()
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = `Drugs, Reference Number and Date Received are required.`
            }
        },
        
    },
    computed:{
        checkCountedMeds(){
            if(this.form.submit.drugs && this.form.submit.drugs.length){
                let discrepancyDrugs = 0;
                this.form.submit.drugs.forEach(drug=>{
                    if(drug.stock_id && (drug.count!= drug.qty_left)){
                        discrepancyDrugs +=1;
                    }
                });
                return discrepancyDrugs;
                // if(discrepancyDrugs){
                //     return discrepancyDrugs;
                // } else{
                //     return true;
                // }
            } else{
                return null
            }
        }
    },
    mounted() {

        // setting the subdomain - used in e.g. algolia searches
        let myHost = window.location.host;

        if(myHost.includes('hospital') && myHost.includes('staging')){
            this.$store.commit('setSubdomain', 'st-hp')
        } else if(myHost.includes('hospital')){
            this.$store.commit('setSubdomain', 'prod')
        } else{
            this.$store.commit('setSubdomain', 'st-hp')
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


 

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
