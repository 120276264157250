<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div v-if="!submitLoading" class="modal-container" style="min-width: 33.25rem; width: 33.25rem">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                            <h3 class="form-header">{{ endpoint == '/drug-safe-retrieval' || (drugReg && drugReg.length) ? 'Remove from Register' : 'Signature \
                            Verification'}}</h3>
                        </div>
                        <!-- $emit(isSuccessful, true) -->
                        <button @click="closeModal()" class="red-close-button"
                            style="width: 30px !important">
                            <i class="pi pi-times" style="padding: 0"></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body" style="padding-bottom: 20px;">
                    <slot name="body">
                        <!-- WARNING -->
                        <div v-if="warning"
                            style="color: #E04F39; width: fit-content; margin: 0 auto; margin: 1em auto; display: flex; align-items: center;">
                            <i class="pi pi-exclamation-circle" style="font-size: 2em !important"></i>
                            <h4 style="margin: 0; font-weight: normal">{{ warning }}</h4>
                        </div>
                        <!-- WARNING -->
                        <p style="margin-left: 20px;">Note: Closing this modal will not save any signatures or notes.
                        </p>
                        <!-- SIGN WARNING  -->
                        <p style="color: red; font-weight: 500; font-size: 1em;text-align: center; margin: 0"
                            v-if="sig2.name && sig1.name && (sig2.name == sig1.name)">{{ signError }}</p>
                        <p style="color: red; font-weight: 500; font-size: 1em;text-align: center; margin: 0" v-if="errMessage">{{ errMessage.message ? errMessage.message.slice(0, 40) : errMessage }}
                        </p>
                        <p style="color: red; font-weight: 500; font-size: 1em;text-align: center; margin: 0" v-if="errMessage2">{{ errMessage }}
                        </p>
                        <p style="color: orange; font-weight: 500; font-size: 1em; margin: 0" v-if="errMessage && saveContext">Draft has
                            been saved.</p>
                        <!-- <p v-if="errMessage">{{errMessage}}</p> -->
                        <!-- SIGN WARNING  -->

                        <form class="action-modal" style="padding-top: 0">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div v-if="!noAuthRequired" class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px; width: 100%; padding-left: 0px;">
                                <!-- v-if="notes" -->
                                <div v-if="endpoint == '/administer' || endpoint == '/administer-chart' || notes">
                                    <Textarea v-tooltip="'Notes'" style="width: 100%; min-height: 100%;"
                                        placeholder="Typing any Notes here will be saved and viewable via the Administration History" :autoResize="true" v-model="verification.notes" />
                                </div>
                                <div v-if="drugReg && drugReg.length">
                                    <p style="text-align: left;">You are removing the following drugs from the register:
                                    </p>
                                    <table style="border-collapse: collapse">
                                        <tr v-for="(item, index) in drugReg" :key="index" class="drug-entry-transfer">
                                            <td style="width: 50%;">
                                                <p>{{ item.stock }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.qty }} {{ item.form }}/s</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div
                                    style="margin-top: 10px;display: grid; justify-content: space-around; margin: 0 auto;" :style="dual || endpoint =='/drug-safe-retrieval' || endpoint =='/stock-take-submit' || endpoint == '/adjustments' ? {gridTemplateColumns: '1fr 1fr', gridGap: '10px', width: '100%'}:{}">
                                    <!-- && !singleSig -->
                                    <div style="display: grid; grid-gap: 10px;" v-if="!success1 && !loading1">
                                        <h4 style="margin: 0; padding-left: 15px; text-align: left">Signature {{ dual && !witness && !$store.state.user.role.permissions.includes('single_signature_primary') ? 1 :
                                                ''
                                        }}
                                        </h4>
                                        <input
                                            v-on:keyup.enter="verifySig(1)"
                                            ref="sig1username"
                                            type="text"
                                            v-model="sig1.name"
                                            placeholder="Username"
                                        >
                                        <input
                                            v-on:keyup.enter="verifySig(1)"
                                            ref="sig1password"
                                            type="password"
                                            v-model="sig1.password"
                                            placeholder="Password"
                                            autocomplete="one-time-code"
                                        >
                                        <div class="green-button"
                                            @click="verifySig(1)">
                                            Sign
                                        </div>
                                    </div>
                                    <div v-if="!success1 && loading1">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin: 0 auto; margin-top: 2em;"></i>
                                    </div>
                                    <div v-if="success1" style="display: flex; align-items: center; margin-top: 2em; ">
                                        <h4
                                            style="color: green; margin: 0; border: 2px solid green; padding: 5px 10px; border-radius: 5px">
                                            {{ prescriber ? "Prescriber" : "Signature 1" }} signed.</h4>
                                    </div>
                                    <div style="display: grid; grid-gap: 10px"
                                        v-if="!success2 && !loading2 &&  ( endpoint =='/drug-safe-retrieval' || endpoint =='/stock-take-submit' || endpoint == '/adjustments' || (dual && !witness && !$store.state.user.role.permissions.includes('single_signature_primary')))">
                                        <h4 style="margin: 0; padding-left: 15px; text-align: left">Signature 2 {{ optional == true ?
                                                '(optional)' : ''
                                        }}</h4>
                                        <input
                                            v-on:keyup.enter="verifySig(2)"
                                            ref="sig2username"
                                            type="text"
                                            v-model="sig2.name"
                                            placeholder="Username"
                                        >
                                        <input
                                            v-on:keyup.enter="verifySig(2)"
                                            ref="sig2password"
                                            type="password"
                                            v-model="sig2.password"
                                            placeholder="Password"
                                            autocomplete="one-time-code"
                                        >
                                        <div class="green-button"
                                            @click="verifySig(2)">
                                            Sign
                                        </div>
                                    </div>
                                    <div style="display: grid; grid-gap: 10px; margin-left: 3em; align-content: flex-start"
                                        v-if="witness">
                                        <h4 style="margin: 0; padding-left: 10px;">Witness</h4>
                                        <input ref="witnessname" type="text" v-model="witnessName" placeholder="Witness Name">
                                        <input ref="witnessrole" type="text" v-model="witnessRole" placeholder="Witness Role">
                                        <input ref="witnessref" type="text" v-model="witnessRefNumber" placeholder="Witness Ref Number">
                                    </div>
                                    <div v-if="!success2 && loading2">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin-top: 2em;"></i>
                                    </div>
                                    <div v-if="success2"
                                        style="margin-left: 3em; display: flex; align-items: center; margin-top: 2em;">
                                        <h4
                                            style="color: green;margin: 0; border: 2px solid green; padding: 5px 10px; border-radius: 5px">
                                            Signature 2 signed.</h4>
                                    </div>


                                </div>
                            </div>
                            <div v-else class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <p style="font-size: 1em; margin: 0;" v-if="!errMessage">Click complete to submit.</p>
                            </div>
                        </form>
                    </slot>
                </div>
                
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px; padding-top: 0px" v-if="witness || noAuthRequired || ((!dual || $store.state.user.role.permissions.includes('single_signature_primary')) && success1) || ((optional || success2) && success1)">
                    <slot v-if="!witness" name="footer" style="display: flex;">
                        <!-- || singleSig -->
                        <div v-if="noAuthRequired || ((!dual || $store.state.user.role.permissions.includes('single_signature_primary')) && success1) || ((optional || success2) && success1)"
                            style="margin: 0 auto; text-align: center;display: flex; align-items: center; margin-bottom: 2em; margin-top: 2em; cursor: pointer"
                            @click=" noAuthRequired ? noAuthSignature() : submitAction()">
                            <h3 style="margin: 0; border-bottom: 2px solid black; width: fit-content;">{{errMessage ? 'Retry' : 'Complete'}}</h3>
                        </div>
                    </slot>
                    <slot v-else name="footer" style="display: flex;">
                        <div v-if="success1 && witnessRole && witnessName"
                            style="margin: 0 auto; text-align: center;display: flex; align-items: center; margin-bottom: 2em; margin-top: 2em; cursor: pointer"
                            @click="submitAction">
                            <h3 style="margin: 0; border-bottom: 2px solid black; width: fit-content;">{{errMessage ? 'Retry' : 'Complete'}}</h3>
                        </div>
                    </slot>
                </div>
            </div>
            
            <div v-else  class="modal-container" style="min-width: 500px; width: 500px; margin: 0 auto; display: grid;">
                <p style="font-size: 1em;font-weight: 500; text-align: center">Submitting request...</p>
                <i class="pi pi-spin pi-spinner"
                    style="font-size: 2rem !important;padding-top: 1em; padding-bottom: 1em; color: green; margin: 0 auto"></i>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import FirebaseService from "../../services/FirebaseService";
export default {
    props: ['single', 'loop', 'close', 'endpoint', 'endpoint2', 'isSuccessful', 'payload', 'payload2', 'header',
        'dual', 'warning', 'prescriber', 'notes', 'witness', 'optional', 'drugReg', 'saveContext', 'noAuthRequired', 'bulkAdminister'],
    components: {
    },
    data() {
        return {
            submitLoading: false,
            signError: 'You cannot use the same sign-in for both signatures.',
            errMessage: '',
            errMessage2: '',
            witnessName: '',
            witnessRole: '',
            witnessRefNumber: '',
            toSubmit: this.payload,
            toSubmit2: this.payload2,
            verification: {
                notes: '',
            },
            singleSig: false,
            sigToken1: '',
            signature_1: '',
            sigToken2: '',
            signature_2: '',
            loading1: false,
            loading2: false,
            sig1: {
                name: '',
                password: '',
                company_id: this.$store.state.company.uuid,
            },
            success1: false,
            sig2: {
                name: '',
                password: '',
                company_id: this.$store.state.company.uuid,
            },
            success2: false,
            savedToBase: false,
            user_id: ''
        }
    },
    methods: {
        noAuthSignature(){
            axios.post('/account-signature', {}, this.$store.state.header).then(res=>{
                console.log('this is the account sig res', res.data);
                this.signature_1 = res.data.uuid;
                this.success1 = true;
                this.submitAction();
            }).catch(err=>{
                console.log('this is the error for account sig request', err.response.data);
            })
        },
        saveFormToFirebase() {
            this.user_id = JSON.parse(localStorage.getItem('user_id'));
            console.log('this is the user id', this.user_id);
            let fireload = {
                payload: { ...this.payload, endpoint: this.endpoint },
                saveContext: this.saveContext ? this.saveContext : '',
                header: { ...this.$store.state.header },
                userId: this.user_id,
            };
            FirebaseService.getForm(this.user_id).then((res)=>{
                console.log('USING GET FORM FIREBASE FUNCTION', res);
                // if user document does not exist yet, just create a new one.
                if(res.data() == undefined){
                    console.log('NO FORMS SAVED BY THIS USER YET', res.data());
                    FirebaseService.create(fireload, this.user_id).then(() => {
                        console.log("Created new item successfully!");
                        this.savedToBase = true;
                        localStorage.setItem('savedform',  JSON.stringify(fireload));
                    }).catch(e => {
                        console.log(e);
                    });
                } else{
                    // if user document does exist, delete previous one then create new one
                    console.log('A FORM HAS BEEN SAVED BY THIS USER ALREADY', res.data());
                    FirebaseService.delete(this.user_id).then(() => {
                        console.log('existing form has been deleted');
                        FirebaseService.create(fireload, this.user_id).then(() => {
                            console.log('form has been replaced successfully');
                            localStorage.setItem('savedform', JSON.stringify(fireload));
                        })
                    })
                }
            })
        },
        closeModal() {
            console.log('hitting this signiture closemodal function')
            this.$emit('close', true);
        },
        submitAction() {
            this.submitLoading = true;
            this.verification.signature_1 = this.signature_1;
            if ((this.dual && !this.$store.state.user.role.permissions.includes('single_signature_primary')) || this.endpoint == '/adjustments') {
                console.log('this is a dual sig');
                if (this.optional) {
                    if (this.signature_2) {
                        this.verification.signature_2 = this.signature_2;
                        console.log('this is an optional sig2 signature signed');
                    }
                    console.log('this is an optional sig');
                } else {
                    this.verification.signature_2 = this.signature_2;
                    console.log('this is a dual sig that is not optional');
                }
                console.log('running through dual conditional', this.verification)
            }
            // FOR REQUESTS LIKE LOOPING THROUGH MULTIPLE S8 MEDS TO REMOVE FROM REGISTER
            if (this.loop) {
                
                console.log('LOOPING THROUGH THE PAYLOAD DATA', this.loop, this.payload, typeof this.payload);
                let notes = this.verification.notes;
                delete this.verification.notes;
                this.payload.forEach((item, index) => {
                    
                    setTimeout(()=>{
                        console.log('this is a note', notes);
                        item.notes = notes;
                        item.verification = this.verification;
                        this.bulkItemsCompleted = 0;
                        axios.post(this.endpoint, item, this.$store.state.header).then(res => {
                            this.bulkItemsCompleted+=1;

                            console.log('looping through items', res);
                            this.$store.dispatch('getRefreshToken');
                            this.$emit('responseData', res.data);
                            setTimeout(() => {
                                if(this.endpoint == '/drug-safe-retrieval'){
                                    this.$emit('drugSafeRetrievalId', res.data);
                                } else{
                                    console.log('THIS IS THE SETTIMEOUT issuccessful', res.data, index);
                                    this.$emit('isSuccessful', this.endpoint == '/administer' ?  {data: item.calendar_item_id, index: index} : res.data);
                                }
                                if(index == (this.payload.length -1)){
                                    console.log('hitting the close modal from the signature modal', index, this.payload.length);
                                    this.closeModal();
                                }
                            }, 200);
                            this.errMessage = '';

                        }).catch(err => {
                            console.log('this is the error 290', err, err.response, err.response.data, err.response.data.errors);
                            this.errMessage = err.response.data.message +' '+ err.response.data.errors;
                            this.submitLoading = false;
                            this.sig1 = {
                                name: '',
                                password: '',
                                company_id: this.$store.state.company.uuid,
                            };
                            this.sig2 = {
                                name: '',
                                password: '',
                                company_id: this.$store.state.company.uuid,
                            };
                            this.loading1 = false;
                            this.success1 = false;
                            this.success2 = false;

                            this.singleSig = false;
                            if (this.saveContext) {
                                this.saveFormToFirebase();
                            }
                        });
                    },100)
                   
                })
            } else {
                this.toSubmit = {...this.payload};
                if (!this.verification.notes) {
                    delete this.verification.notes;
                } else {
                    this.toSubmit.notes = this.verification.notes;
                    delete this.verification.notes;
                }
                console.log('this is the toSubmit', this.toSubmit);

                this.toSubmit.verification = this.verification;
                if (this.toSubmit2) {
                    this.toSubmit2.verification = this.verification;
                }
                // if(this.witness){
                //     this.toSubmit.witness_role = this.witnessRole;
                //     this.toSubmit.witness_name = this.witnessName;
                // }
                // this.$store.dispatch('getRefreshToken');

                axios.post(`${this.drugReg && this.drugReg.length ? '/drug-safe-retrieval' : this.endpoint}`, this.toSubmit, this.header ? this.header : this.$store.state.header).then(res => {
                    if (this.endpoint2) {
                        axios.post(`${this.endpoint2}`, this.toSubmit2, this.$store.state.header).then(res1 => {
                            console.log('the second endpoint has been submitted', res1);
                            if (this.$route.name == 'Drug Register') {
                                console.log('loading the drug register stuff');
                                this.$store.dispatch('getDrugRegister', {
                        location: this.$store.state.uuid,
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        filters: {
                            transaction_id: '',
                            to_from: '',
                            entered_by: '',
                            date: {
                                start: '',
                                end: ''
                            },
                            drug: '',
                            imprest_only: this.$store.state.drugRegImprestToggle,
                            destruction_only: this.$store.state.drugRegDestructionToggle,
                        }
                    });
                            }
                        }).catch(err => {
                            console.log('this is the error updated', err, err.response, err.response.data, err.response.data.errors);
                            this.errMessage = err.response.data.errors
                            this.submitLoading = false;
                            this.sig1 = {
                                name: '',
                                password: '',
                                company_id: this.$store.state.company.uuid,
                            };
                            this.sig2 = {
                                name: '',
                                password: '',
                                company_id: this.$store.state.company.uuid,
                            };
                            this.loading1 = false;
                            this.success1 = false;
                            this.success2 = false;

                            this.singleSig = false;
                            if (this.saveContext) {
                                this.saveFormToFirebase();
                            }
                        });
                    }
                    console.log('action has been submitted', res);
                    this.$store.dispatch('getRefreshToken');
                    this.$emit('responseData', res.data);
                    setTimeout(() => {
                        
                        if(this.endpoint == '/drug-safe-retrieval'){
                            this.$emit('drugSafeRetrievalId', res.data);
                        } else{
                            if(this.bulkAdminister){
                                console.log('bulk administer in signature modal path', this.toSubmit);
                                this.$emit('isSuccessful', this.toSubmit)
                            } else{
                                this.$emit('isSuccessful',  this.endpoint == '/administer' ?  {data: this.toSubmit.calendar_item_id, index: null} : res.data);
                            }
                            
                        }
                        this.closeModal();
                    }, 200);
                    this.errMessage = '';

                }).catch(err => {
                    console.log('this is the error updated 389', err.response.data.message, err.response.data.errors);
                    // this.errMessage = err.response.data.errors;
                    // this.errMessage.concat('\n '+err.response.data.errors)
                    
                    if(err.response.data && err.response.data.message && err.response.data.message.includes('ePrescribing service could not be contacted')){
                        this.errMessage = 'Unfortunately the prescription has not been submitted as the request has timed out. Feel free to try again or issue a paper prescription.';
                        this.errMessage2 = err.response.data.errors ? err.response.data.errors : ''
                    } else{
                        this.errMessage = err.response.data;
                        this.errMessage2 = err.response.data.errors;
                    }
                    
                    this.submitLoading = false;
                    this.sig1 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.sig2 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;

                    this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });
            }
        },
        verifySig(signatureEntry) {
            if (signatureEntry == 1 && (this.sig2.name != this.sig1.name) && !this.singleSig) {
                this.loading1 = true;
                axios.post('/sign', this.sig1, this.$store.state.header).then(res => {
                    console.log('this is the signature token', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.sigToken1 = res.data.access_token;
                    return res;
                }).then(() => {
                    axios.post('/signature', { signature_token: this.sigToken1, type: '' }, this.$store.state.header).then(res => {
                        console.log('this is the signature id', res.data);
                        this.$store.dispatch('getRefreshToken');
                        this.signature_1 = res.data.uuid;
                        this.success1 = true;
                        this.loading1 = false;
                        if(this.dual){
                            if(this.success2 && this.success1){
                                this.submitAction()
                            }
                        } else{
                            if(this.success1){
                                this.submitAction()
                            }
                        }
                        
                        // if(res1.data.single_signature_primary && !this.drugReg && this.endpoint != "/stock-take-submit" && this.endpoint != "/new-delivery" && (!this.payload.pending)){
                        //     console.log('this is a single sig primary')
                        //     this.singleSig = true;
                        // }
                    });
                }).catch((err) => {
                    // this.errMessage = 'Incorrect username/password';
                    console.log('this is the sig error', err);
                    this.errMessage = err.response.data;
                    this.submitLoading = false;
                    this.sig1 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;
                    this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });

            }
            if (signatureEntry == 2 && (this.sig2.name != this.sig1.name) && !this.singleSig) {
                this.loading2 = true;
                this.singleSig = false;
                axios.post('/sign', this.sig2, this.$store.state.header).then(res => {
                    console.log('this is the signature token', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.sigToken2 = res.data.access_token;
                    // if(res.data.single_signature_primary){
                    //     console.log('this is a single sig primary')
                    //     this.singleSig = true;
                    // } 
                    return res;
                }).then(() => {
                    axios.post('/signature', { signature_token: this.sigToken2, type: 'Witness' }, this.$store.state.header).then(res => {
                        // console.log('this is the signature id for sig 2', res.data);
                        this.$store.dispatch('getRefreshToken');
                        this.signature_2 = res.data.uuid;
                        console.log('this is the signature id for sig 2', this.signature_2);
                        this.loading2 = false;
                        this.success2 = true;
                        if(this.dual){
                            if(this.success2 && this.success1){
                                this.submitAction()
                            }
                        } else{
                            if(this.success1){
                                this.submitAction()
                            }
                        }
                        // this.errMessage = '';
                        // if(res1.data.single_signature_primary){
                        //         console.log('this is a single sig primary')
                        //         this.singleSig = true;
                        //     }
                    });
                }).catch((err) => {
                    // this.errMessage = 'Incorrect username/password';
                    this.errMessage = err.response.data;
                    this.submitLoading = false;
                    this.sig2 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading2 = false;
                    this.success2 = false;
                    // this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });
            }
            if (this.witness) {
                let witnessLoad = {
                    name: this.witnessName,
                    reference_number: this.witnessRefNumber,
                    role: this.witnessRole,
                    type: 'Witness'
                }

                axios.post('/plain-signature', witnessLoad, this.$store.state.header).then(res => {
                    console.log('this is the signature id', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.signature_2 = res.data.uuid;
                }).catch((err) => {
                    // this.errMessage = 'Incorrect username/password';
                    this.errMessage = err.response.data.errors;
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;
                    this.singleSig = false;
                });
            }
        },
        focusInput() {
            // Focus the password if username prefilled
            if (this.sig1.name) {
                this.$refs.sig1password.focus();
            }
            // Else focus the username
            else {
                this.$refs.sig1username.focus();
            }
        }
    },
    mounted() {
        console.log('this is the noauthrequired', this.noAuthRequired)
        if(this.noAuthRequired){
            this.noAuthSignature();
        }
        console.log('this user has a username cached', localStorage.getItem('username'));
        if(localStorage.getItem('username')){
            console.log('does have username cached');
            this.sig1.name = localStorage.getItem('username');
        }
        if(!this.noAuthRequired){
            this.focusInput();
        }
        
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>