<template>
    <div>
        <div class="panel" style="margin-left: 1em">
            <h3 class="panel-header">Create New Patient</h3>
            <div class="panel-content-container">
                <div class="patient-form" v-if="$store.state.user.role.permissions.includes('can_do_resident')">
                    <div class="patient-form-navigation">
                        <div class="patient-form-image-container">
                            <img :src="previewImage" class="uploading-image" />
                            <div v-if="!previewImage"
                                style="height: 250px; display: flex; border-radius: 8px;
                            flex-direction: column; align-items: center; justify-content: space-around;  background-color: #F3F4F4;">
                                <i class="pi pi-user" style="font-size: 5em !important; color: #D8DCDC"></i>
                                <p>Patient photo to display here</p>
                            </div>
                            <label for="patient-image-upload" class="image-file-upload" style="width: 100%; margin: 0 auto;  padding: 5px 10px; border-radius: 4px; cursor: pointer; color: white; background-color: #0870DC"
                                v-html="previewImage ? 'Change Image' : 'Upload Patient Image'">
                            </label>
                            <input type="file" id="patient-image-upload" accept="image/jpeg" @change=uploadImage
                                style=" display: none; ">

                        </div>

                        <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU" alt=""> -->
                        <div class="patient-form-navigation-items" style="margin-top: 15px;">
                                    <div v-for="(item, index) in navigationItems" :key="index" class="patient-form-navigation-item" :class="selectedNavigation == item.link ? 'selected-patient-form-navigation-item':''">
                                        
                                        <!-- :class="[item.completed == true ? 'patient-form-navigation-label-completed' : '']" -->
                                        <div class="patient-form-navigation-label" :class="selectedNavigation == item.link ? 'selected-patient-form-navigation-label':''"
                                            @click="selectedNavigation = item.link">
                                            <p style="text-align: left">{{ item.name }}</p>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <div class="patient-form-content">
                        <div class="patient-form-content-section" v-if="selectedNavigation == 'personal_information'">
                            <div class="personal-info-form-content">
                                <h3>Personal Information</h3>
                                <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">First Name <span style="color: red">*</span></label>
                                        <input type="text" name="first_name" v-model="personalInformation.first_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Last Name <span style="color: red">*</span></label>
                                        <input type="text" name="first_name" v-model="personalInformation.last_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Preferred Name </label>
                                        <input type="text" name="first_name" v-model="personalInformation.preferred_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Sex <span style="color: red">*</span></label>
                                        <!-- <input type="text" name="first_name" v-model="personalInformation.sex"> -->
                                        <Dropdown v-model="personalInformation.sex" :options="sex" optionLabel="sex" optionValue="sex"
                                            :placeholder="personalInformation.sex ? personalInformation.sex : 'Select sex'" />
                                    </div>
                                    <div class="form-field date-field">
                                        <label for="first_name">Date of Birth<span style="color: red">*</span></label>
                                        <input type="date" name="first_name" v-model="personalInformation.dob">
                                        <!-- <InputMask style="background-color: #f3f4f4" mask="99/19/9999" v-model="personalInformation.dob" placeholder="01/01/1900" slotChar="dd/mm/yyyy" /> -->
                                    </div>
                                    

                                    <div class="form-field text-field">
                                        <label for="first_name">Medicare Number <span style="color: red">*</span></label>
                                        <InputMask style="background-color: #f3f4f4" mask="9999 99999 9 9" v-if="regulatory_numbers && regulatory_numbers.ihi_number"
                                            v-model="regulatory_numbers.medicare_number.number" placeholder="9999 99999 9 / 9(IRN)"/>
                                        <!-- <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.ihi_number"
                                            v-model="regulatory_numbers.medicare_number.number"> -->
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Medicare Expiry <span style="color: red">*</span></label>
                                        <Calendar id="monthpicker" v-model="regulatory_numbers.medicare_number.expiry"
                                            view="month" dateFormat="mm/yy" :yearNavigator="true" yearRange="2020:2050" style="background-color: #f3f4f4" />
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">DVA Number</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.dva_number"
                                            v-model="regulatory_numbers.dva_number.number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">DVA Status </label>
                                        <!-- <input type="text" name="first_name" v-model="personalInformation.sex"> -->
                                        <Dropdown v-model="regulatory_numbers.dva_number.regulatory_type" :options="dva_statuses" optionLabel="status" optionValue="value"
                                            placeholder="Select DVA Status" />
                                    </div>
                                    
                                </div>
                            </div>
                            <div>
                                <div class="field-checkbox" style="display: flex; margin-top: 2em;">
                                    <Checkbox :binary="true" v-model="aslr_site_consent" /> 
                                    <label style="font-size: 1em; padding-left: 10px; font-weight: bold" for="binary">Resident consents to healthcare provider view access of their Active Script List (if they are registered).</label>
                                </div>
                                <Button class="button" @click="createOrUpdatePatient()" style="display: flex; margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white; margin-top: 10px;" label="Create Patient" />
                            </div>
                            <div class="personal-info-form-content" v-if="$store.state.user.eprescribing_active" >
                                <h3 :style="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex ? {color: 'grey'}:{}">IHI Number</h3>
                                <p style="margin: 0; font-size: 0.9em; text-align: left">Fill in the patient's name, sex, DOB, Medicare/DVA Number to retrieve IHI Number.</p>
                                <p style="margin: 0; font-size: 0.9em; text-align: left; color: red; font-weight: bold">IHI Number is required to create prescriptions.</p>
                                <div class="form-fields">
                                    
                                    <div class="form-field text-field" style="width: fit-content !important" >
                                        <label for="first_name">IHI Number <span style="color: red">*</span></label>
                                        <div style="display: flex;">
                                            <Button class="blue-button" style="width: 20em" label="Retrieve IHI Number"
                                        v-if="!regulatory_numbers.ihi_number.number && !inputManualIHI"
                                        :disabled="!personalInformation.first_name || !personalInformation.last_name || !regulatory_numbers.medicare_number || !personalInformation.dob || !personalInformation.sex" @click="retrieveIHINumber()" />
                                        
                                            <InputMask v-if="regulatory_numbers.ihi_number.number && !inputManualIHI" style="background-color: #f3f4f4" mask="9999 9999 9999 9999"
                                            v-model="regulatory_numbers.ihi_number.number" placeholder="9999 9999 9999 9999"/>

                                            <Button :disabled="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex"  v-tooltip="'You need to provide the patient name, date of birth and sex to manually insert IHI number.'"
                                            v-if="!inputManualIHI && !ihiDetails" @click="inputManualIHI = true" class="blue-button"  style="width: 20em; margin-left: 1em;" label="Manually Enter IHI Number"
                                        />
                                            <InputMask v-if="inputManualIHI" style="background-color: #f3f4f4; width: 20em; margin-right: 1em" mask="9999 9999 9999 9999"
                                            v-model="regulatory_numbers.ihi_number.number" placeholder="9999 9999 9999 9999"/>
                                            <Button v-if="inputManualIHI && !ihiDetails.ihi_status" class="blue-button"  style="width: fit-content; margin-right: 1em;" label="Validate IHI Number"
                                            @disabled="!regulatory_numbers.ihi_number.number" @click="retrieveIHINumber(patientUUID ? false: true)"  />
                                            <!-- retrieveIHINumber  testManualIHINumber -->
                                            <Button v-if="inputManualIHI && !ihiDetails.ihi_status" class="grey-button"  style="width: fit-content; border: none;" label="Cancel"
                                            @click="inputManualIHI = false"  />
                                        </div>
                                        
                                    </div>
                                    <div class="form-field text-field" v-if="regulatory_numbers.ihi_number.number && ihiDetails">
                                        <label for="first_name">IHI Number Status</label>
                                        <inputText class="grey-input-color" disabled type="text" name="first_name" :value="ihiDetails.ihi_status" />
                                    </div>
                                    <div class="form-field text-field" v-if="regulatory_numbers.ihi_number.number && ihiDetails">
                                        <label for="first_name">IHI Record Status</label>
                                        <inputText class="grey-input-color" disabled type="text" name="first_name" :value="ihiDetails.ihi_record_status" />
                                    </div>
                                    
                                    <div class="form-field text-field" v-if="regulatory_numbers.ihi_number.number && ihiDetails">
                                        <Button  @click="displayIHIHistoryModal = true"
                                        class="outline-button p-button-outlined" label="View IHI Number History" />
                                    </div>
                                </div>
                            </div>
                            <div class="personal-info-form-content">
                                <h3 :style="!personalInformation.first_name || !personalInformation.last_name || !personalInformation.dob || !personalInformation.sex ? {color: 'grey'}:{}">Patient Location</h3>
                                <div class="form-fields" v-if="!facilities || !facilities.length || !nodes.length">
                                    <div class="form-field text-field" >
                                        <p style="font-size: 1em; margin-left: 1em;">Loading location data...</p>
                                    </div>
                                
                                </div>
                                <div v-else class="form-fields" style="align-items: flex-end">
                                    <div class="form-field text-field" v-if="facilities && facilities.length">
                                        <label for="first_name">Facility <span style="color: red">*</span></label>
                                        <Dropdown  v-model="selectedFacility" :options="facilities" optionLabel="label"
                                            @change="retrieveSelectedFacilityLocations()"
                                            :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" />
                                    </div>
                                    <div class="form-field text-field" v-if="nodes && nodes.length">
                                        <label for="first_name" >Location <span style="color: red">*</span></label>
                                        <div v-if="loadingLocations">
                                            <i class="pi pi-spin pi-spinner"
                                                style="font-size: 2rem !important;margin-top: 10px;"></i>
                                        </div>
                                        <TreeSelect v-else v-model="selectedNodeKey" :options="nodes" ariaLabelledBy="name"
                                             placeholder="Select Item" />
                                        <!-- <p class="blue-button" @click="confirmFacilityLocation()">Click</p> -->
                                    </div>
                                    
                                    <Button class="complete-button"  style="width: fit-content; margin-right: 1em;" label="Save Location"
                                            :disabled="!selectedNodeKey || !patientUUID" @click="confirmFacilityLocation()"  />
                                    <div class="form-field text-field" v-if="locationSaveStatus">
                                        <Button class="outline-button p-button-outlined" :loading="locationSaveStatus.includes('Saving')" :class="locationSaveStatus == 'Location saved!' ? 'p-button-success': locationSaveStatus ? 'p-button-warning':''" v-if="locationSaveStatus" :label="locationSaveStatus" />
                                    </div>
                                </div>

                                
                            </div>
                            
                        </div>

                        <div class="patient-form-content-section" v-if="selectedNavigation == 'contact_details'">
                            <div class="contact-info-form-content">
                                <h3>Contact Details</h3>
                                <div class="form-fields">

                                    <div class="form-field text-field">
                                        
                                        <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                                        <div class="form-field text-field">
                                            <label for="first_name">Address {{address && address.street_address ? ': '+address.street_address+', '+address.suburb+', '+address.postcode : ''}}</label>
                                            <vue-google-autocomplete id="contactmap" classname="form-control" placeholder=""
                                                v-on:placechanged="getPersonalAddressData" :country="$store.state.country">
                                            </vue-google-autocomplete>
                                        </div>
                                    </div>

                                    <div class="form-field text-field">
                                        <label for="first_name">Phone</label>
                                        <!-- <input type="text" name="first_name" v-model="untitled.phone"> -->
                                        <InputMask style="background-color: #f3f4f4" mask="(99) 9999 9999" 
                                            v-model="untitled.phone" placeholder="(03) 9999 9999"/>
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Email</label>
                                        <input type="text" name="first_name" v-model="untitled.email">
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex">
                                <button class="button blue-button" style="margin-top: 1em;"
                                    @click="setPatientAddress(), addContactMethods()">Add Contact Details</button>
                            </div>
                            <div class="contact-info-form-content">
                                <h3>Next of Kin Details</h3>
                                <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">Next of Kin - Name</label>
                                        <input type="text" name="first_name" v-model="kin.first_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Next of Kin - Relationship</label>
                                        <input type="text" name="first_name" v-model="kin.relationship">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Next of Kin - Phone</label>
                                        <input type="text" name="first_name" v-model="kin.phone">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Next of Kin - Email</label>
                                        <input type="text" name="first_name" v-model="kin.email">
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex">
                                <button class="button blue-button" style="margin-top: 1em;" @click="createKin()">
                                    Add Next of Kin</button>
                            </div>
                            <div class="contact-info-form-content">
                                <h3>Entitlement Numbers</h3>
                                <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">Pharmaceutical Entitlement Number</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.ihi_number"
                                            v-model="regulatory_numbers.pharmaceutical_entitlement_number.number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Safety Net Number</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.ihi_number"
                                            v-model="regulatory_numbers.safety_net_number.number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Safety Net Number 2</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.safety_net_number2"
                                            v-model="regulatory_numbers.safety_net_number2.number">
                                    </div>
                                    
                                    <div class="form-field text-field">
                                        <label for="first_name">Concession Number</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.ihi_number"
                                            v-model="regulatory_numbers.concession_number.number">
                                    </div>

                                    <div class="form-field text-field">
                                        <label for="first_name">RAC ID</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.ihi_number"
                                            v-model="regulatory_numbers.rac_id.number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">URN/MRN Number</label>
                                        <input type="text" name="first_name"
                                            v-if="regulatory_numbers && regulatory_numbers.urn_mrn"
                                            v-model="regulatory_numbers.urn_mrn.number">
                                    </div>
                                </div>
                                <div style="display: flex">
                                    <button class="button blue-button" style="margin-top: 1em;"
                                        @click="submitRegulatoryInfo()">Save Entitlement & Regulatory Numbers</button>
                                </div>
                            </div>
                        </div>

                        <div class="patient-form-content-section" v-if="selectedNavigation == 'prescriber_details'">
                            <div class="prescriber-info-form-content" style="display: grid">
                                <h3>Primary General Practitioner Details</h3>
                                <p style="font-size: 1em; text-align: left; margin: 0;">Select prescriber to link to
                                    resident.</p>
                                <Dropdown v-if="prescribers && prescribers.length" v-model="selectedPrescriber" :options="prescribers" optionLabel="name"
                                    placeholder="Select Prescriber" style="width: fit-content; margin-top: 10px;" />

                                <!-- <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">Prescriber Number</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.gp.prescriber_number" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.gp.prescriber_number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Name</label>
                                        <input v-if="untitled.gp.uuid" type="text" :value="untitled.gp.first_name +' '+ untitled.gp.last_name" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.gp.name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Phone</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.gp.phone" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.gp.phone">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">After Hours Phone</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.gp.after_hours" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.gp.after_hours">
                                        
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Address</label>
                                        <vue-google-autocomplete
                                            v-if="!untitled.gp.uuid"
                                            id="primarygpaddress"
                                            classname="form-control"
                                            placeholder=""
                                            v-on:placechanged="getAddressData('gp')"
                                            :country="$store.state.country"
                                            >
                                        </vue-google-autocomplete>
                                        <input v-else type="text" v-model="untitled.gp.address" disabled>
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Email</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.gp.email" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.gp.email">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Fax Number</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.gp.fax" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.gp.fax">
                                    </div>
                                </div> -->
                                <div style="display: flex;">
                                    <button class="button blue-button" style="margin-top: 1em;" @click="submitPrescriber()" 
                                    v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">Link Prescriber</button>
                                </div>
                            </div>
                            <div class="prescriber-info-form-content" style="display: grid">
                                <h3>Pharmacy Details</h3>
                                <p style="font-size: 1em; text-align: left; margin: 0;">Select pharmacy to link to patient.
                                </p>
                                <!-- optionLabel="label" optionValue="value"  -->
                                <Dropdown v-if="pharmacies && pharmacies.length" v-model="selectedPharmacy" :options="pharmacies" optionLabel="name"
                                    placeholder="Select Pharmacy" style="width: fit-content; margin-top: 10px;" />
                                <!-- <div style="display: flex; align-items: center;">
                                        <div class="text-search-container search-container text-field" 
                                        style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;">
                                            <label for="first_name" style="text-align: left; padding-left: 10px">Search by Pharmacy Name</label>
                                            <input v-model="pharmacySearchInput" v-on:keyup="pharmacySearch(pharmacySearchInput)" placeholder="Search Prescriber No." 
                                            style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                            <div class="drug-search-result-container"  v-if="pharmacySearchInput" 
                                            style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                                <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                                                v-for="(item, index) in pharmacyResults" :key="index" @click="selectPharmacy(item)">{{item.name}}</p>
                                            </div>
                                        </div>
                                        <p style="text-align: left; margin-left: 20px; cursor:pointer; width: fit-content; margin: 0; margin-top: 20px;
                                        border-bottom: 1px solid grey; "  
                                        v-if="untitled.pharmacy.uuid" @click="clearPharmacy">Clear Selected Pharmacy</p>
                                    </div>
                                <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">Pharmacy Name</label>
                                        <input v-if="untitled.pharmacy.uuid" type="text" v-model="untitled.pharmacy.name" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.pharmacy.name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Contact Name</label>
                                        <input v-if="untitled.pharmacy.uuid" type="text" v-model="untitled.pharmacy.contact_name" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.pharmacy.contact_name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Phone</label>
                                        <input v-if="untitled.pharmacy.uuid" type="text" v-model="untitled.pharmacy.phone" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.pharmacy.phone">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Email</label>
                                        <input v-if="untitled.pharmacy.uuid" type="text" v-model="untitled.pharmacy.name" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.pharmacy.name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Fax</label>
                                        <input v-if="untitled.pharmacy.uuid" type="text" v-model="untitled.pharmacy.fax" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.pharmacy.fax">
                                    </div>
                                    
                                </div> -->
                                <div style="display: flex;">
                                    <button class="button blue-button" style="margin-top: 1em;" @click="submitPharmacy()" 
                                    v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">Link Pharmacy</button>
                                </div>
                            </div>

                            <!-- <div class="prescriber-info-form-content" style="display: grid">
                                <h3>Prescriber Details (if not Primary GP)</h3>
                                <p style="font-size: 1em; text-align: left; margin: 0;">Select GP to link to resident.</p>
                                <Dropdown v-model="selectedPharmacy" :options="pharmacies" optionLabel="name" placeholder="Select Pharmacy" style="width: fit-content; margin-top: 10px;" />
                                <div style="display: flex; align-items: center;">
                                        <div class="text-search-container search-container text-field" 
                                        style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                            <label for="first_name" style="text-align: left; padding-left: 10px">Search by Prescriber Number</label>
                                            <input v-model="nonGP" v-on:keyup="gpSearch(nonGP)" placeholder="Search Prescriber No." 
                                            style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                            <div class="drug-search-result-container"  v-if="nonGP" 
                                            style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                                <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                                                v-for="(item, index) in prescriberResults" :key="index" @click="selectPrescriber(item)">{{item.prescriber_number}}</p>
                                            </div>
                                        </div>
                                        <p style="text-align: left; margin-left: 20px; cursor:pointer; width: fit-content; margin: 0; margin-top: 20px;
                                            border-bottom: 1px solid grey; " 
                                        v-if="untitled.prescriber.uuid" @click="clearPrescriber">Clear Selected Prescriber</p>
                                    </div>
                                <div class="form-fields">
                                    <div class="form-field text-field">
                                        <label for="first_name">Prescriber Number</label>
                                        <input v-if="untitled.prescriber.uuid" type="text" v-model="untitled.prescriber.prescriber_number" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.prescriber.prescriber_number">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Name</label>
                                        <input v-if="untitled.prescriber.uuid" type="text" :value="untitled.prescriber.first_name +' '+ untitled.prescriber.last_name" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.prescriber.name">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Phone</label>
                                        <input v-if="untitled.prescriber.uuid" type="text" v-model="untitled.prescriber.phone" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.prescriber.phone">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">After Hours Phone</label>
                                        <input v-if="untitled.prescriber.uuid" type="text" v-model="untitled.prescriber.after_hours" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.prescriber.after_hours">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Address</label>
                                        <vue-google-autocomplete
                                            v-if="!untitled.prescriber.uuid"
                                            id="prescriberaddress"
                                            classname="form-control"
                                            placeholder=""
                                            v-on:placechanged="getAddressData('prescriber')"
                                            :country="$store.state.country"
                                            >
                                        </vue-google-autocomplete>
                                        <input v-else type="text" v-model="untitled.prescriber.address" disabled>
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Email</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.prescriber.email" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.prescriber.email">
                                    </div>
                                    <div class="form-field text-field">
                                        <label for="first_name">Fax Number</label>
                                        <input v-if="untitled.gp.uuid" type="text" v-model="untitled.prescriber.fax" disabled>
                                        <input v-else type="text" name="first_name" v-model="untitled.prescriber.fax">
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>

                        <div class="patient-form-content-section" v-if="selectedNavigation == 'allergies'">
                            <div class="adr-info-form-content">
                                <h3>Allergies & Adverse Drug Reactions</h3>
                                <p style="text-align: left;">Enter any applicable allergies/drugs below:</p>
                                <button class="blue-button" style="display: flex; align-items: center" @click="submitADR()">
                                    Save List
                                </button>
                                <p v-if="adrErrorMessage" style="color: red; text-align: left; font-weight: bold;">
                                    {{ adrErrorMessage }}
                                </p>
                                <div style="max-height: 38em; overflow-y: scroll;">
                                    <table>
                                        <tr>
                                            <th>Drug Name or Substance</th>
                                            <th style="width: 30em">Reaction Description</th>
                                            <th>Type of Reaction</th>
                                            <th>Date Reaction Last Occured</th>
                                            <th></th>
                                        </tr>
                                        <tr v-for="(item, index) in untitled.adr" :key="index">
                                            <td><input type="text" v-model="item.custom" style="width: 95%;"></td>
                                            <td><input type="text" v-model="item.reaction_description" style="width: 95%;">
                                            </td>
                                            <td><input type="text" v-model="item.reaction_type" style="width: 95%;"></td>
                                            <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                            <td>
                                                <i @click="removeADR(index)"
                                                    style="cursor: pointer; font-size: 1.2em !important"
                                                    class='pi pi-trash'>
                                                </i>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="button outline-button" @click="addNewADR()">
                                    <p>Add Another ADR &nbsp; &nbsp; &nbsp; <span style="font-size: 1.2em;">+</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="patient-form-content-section" v-if="selectedNavigation == 'considerations'">
                            <h3>Special Considerations</h3>
                            <div class="considerations-info-form-content"
                                style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; width: 80%">
                                <div>
                                    <div class="special-considerations-form-container">
                                        <h4 style="text-align: left;font-weight: 500;">Medication Method</h4>
                                        <div class="special-considerations-form">
                                            <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                            <div class="spec-cons-items">
                                                <div class="spec-cons-item" v-for="(item, index) in medication_methods"
                                                    :key="index">
                                                    <input type="checkbox" name="inactive" @change="saveSelectedItem(item)"
                                                        :checked="untitled.special_considerations.includes(item)">
                                                    <label for="inactive">{{ item }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="special-considerations-form-container">
                                        <h4 style="text-align: left;font-weight: 500;">Medication Administration</h4>
                                        <div class="special-considerations-form">
                                            <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                            <div class="spec-cons-items">
                                                <div class="spec-cons-item"
                                                    v-for="(item, index) in medication_administration" :key="index">
                                                    <input type="checkbox" name="inactive" @change="saveSelectedItem(item)">
                                                    <label for="inactive">{{ item }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="special-considerations-form-container">
                                        <h4 style="text-align: left;font-weight: 500;">Patient Considerations</h4>
                                        <div class="special-considerations-form">
                                            <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                            <div class="spec-cons-items">
                                                <div class="spec-cons-item" v-for="(item, index) in patient_considerations"
                                                    :key="index">
                                                    <input type="checkbox" name="inactive" @change="saveSelectedItem(item)">
                                                    <label for="inactive">{{ item }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="display: flex;
                                            flex-direction: column;
                                            justify-content: space-between;">
                                    <div class="special-considerations-form-container">
                                        <h4 style="text-align: left;font-weight: 500;">Medication Delivery</h4>
                                        <div class="special-considerations-form">
                                            <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                            <div class="spec-cons-items">
                                                <div class="spec-cons-item" v-for="(item, index) in medication_delivery"
                                                    :key="index">
                                                    <input type="checkbox" name="inactive"
                                                        @change="saveSelectedItem(item)">
                                                    <label for="inactive">{{ item }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="special-considerations-form-container">
                                        <h4 style="text-align: left;font-weight: 500; margin-bottom: 0;">Specific
                                            Instructions/Additional Comments</h4>
                                        <p style="margin-top: 0; text-align: left">Note here if other medication
                                            methods/administration/delivery or patient considerations.</p>
                                        <div class="special-considerations-form">
                                            <textarea name="spec-cons-notes" id="spec-cons-notes"
                                                style="max-width: 95%; box-sizing: border-box" cols="60"
                                                rows="10"></textarea>
                                        </div>
                                    </div>
                                    <button class="blue-button" style="width: fit-content; align-self: flex-end" @click="submitSpecCons()">Save Special Considerations</button>
                                </div>


                                <div>

                                </div>

                            </div>
                        </div>

                        <div class="patient-form-content-section" v-if="selectedNavigation == 'vaccinations'">
                            <div class="vac-weight-info-form-content">
                                <h3>Vaccination History</h3>
                                <div class="vac-weight-table">
                                    <table>
                                        <tr>
                                            <th>Vaccine</th>
                                            <th>Date Last Given</th>
                                            <th></th>
                                        </tr>
                                        <tr v-for="(item, index) in untitled.vaccinations" :key="index">
                                            <td><input type="text" v-model="item.vaccine" style="width: 95%;"></td>
                                            <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                            <td><i @click="removeVaccine(index)"
                                                    style="cursor: pointer; font-size: 1.2em !important"
                                                    class='pi pi-trash'></i></td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="button outline-button" @click="addNewVaccine">
                                    <p>Add Another Vaccination Record &nbsp; &nbsp; &nbsp; <span
                                            style="font-size: 1.2em;">+</span></p>
                                </div>
                            </div>
                            <div class="vac-weight-info-form-content">
                                <h3>Weights</h3>
                                <div class="vac-weight-table">
                                    <table>
                                        <tr>
                                            <th>Weights</th>
                                            <th>Date</th>
                                            <th></th>
                                        </tr>
                                        <tr v-for="(item, index) in untitled.weights" :key="index">
                                            <td><input type="text" v-model="item.weight" style="width: 95%;"></td>
                                            <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                            <td><i @click="removeWeight(index)"
                                                    style="cursor: pointer; font-size: 1.2em !important"
                                                    class='pi pi-trash'></i></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="button outline-button" @click="addNewWeight">
                                    <p>Add Another Weight Record &nbsp; &nbsp; &nbsp; <span
                                            style="font-size: 1.2em;">+</span></p>
                                </div>
                            </div>
                        </div>

                        <!-- FORM BUTTONS -->
                        <div style="display:flex; align-items: center; justify-content: flex-end; align-self: flex-end">
                            <!-- <button class="clear-button"
                                v-if="selectedNavigation != 'personal_information' && selectedNavigation != 'vaccinations'"
                                @click="nextFormPage"
                                style="margin: 0; padding: 0 10px; width: 10em; margin-right: 1em; color: white; background-color: #BDC4C4">Skip</button> -->
                            <!-- <button class="outline-button" @click="submitForm"
                                v-if="(this.personalInformation.first_name && this.personalInformation.last_name && this.personalInformation.preferred_name && this.personalInformation.sex && this.personalInformation.dob) && ihiDetails"
                                style="margin: 0; padding: 0 10px; width: 10em; margin-right: 1em; background-color: white; color: #0870DC;">Save
                                and exit</button> -->
                            <!-- <Button class="button" @click="nextFormPage" v-if="selectedNavigation != 'considerations' && (!this.$store.state.user.enmrc_eprescribing_active ? selectedNavigation != 'contact_details' : true)" :disabled="(!patientUUID || !selectedNodeKey) && selectedNavigation == 'personal_information'"
                            style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white; margin-right: 10px; margin-top: -5.5em" label="Next" /> -->
                            <!-- <button class="button" @click="submitForm" v-if="selectedNavigation == 'vaccinations'" -->
                            <!-- <button class="button" v-if="selectedNodeKey" @click="submitForm" 
                                style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white;">Submit</button> -->
                        </div>
                    </div>
                </div>
                <div v-else style="margin-top: 10em;">
                    <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                        You do not have permission to access this feature. Please talk to your admin if you require access.
                    </h3>
                </div>
            </div>
        </div>
        <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage"
            :first_spec_line="first_spec_line"
            :second_spec_line="errActions ? errActions : second_spec_line" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';


export default {
    components: {
        SuccessModal,
    },
    data() {
        return {
            aslr_site_consent: false,
            locationSaveStatus: '',
            inputManualIHI: false,
            loadingLocations: false,
            displaySuccessModal: false,
            displayIHIHistoryModal: false,
            totalActions: 1,
            completedActions: 0,
            errActionsCount: 0,
            errActions: '',
            adrErrorMessage: '',
            first_spec_line: '',
            second_spec_line: 'Do not close this modal until this is completed.',
            successMessage: 'Creating Patient...',
            selectedNavigation: 'personal_information',
            gp: '',
            nonGP: '',
            patientUUID: '',
            pharmacySearchInput: '',
            validForm: false,
            selectedNodeKey: '',
            selectedFacility: '',
            facilities: '',
            nodes: [],
            prescriberResults: [],
            pharmacyResults: [],
            navigationItems: [
            {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Info & Regulatory Numbers',
                    link: 'contact_details',
                    completed: false,
                },
                {
                    name: 'Prescriber and Pharmacy Details',
                    link: 'prescriber_details',
                    completed: false,
                },
                {
                    name: 'Allergies & Adverse Drug Reactions',
                    link:'allergies',
                    completed:false,
                },
                {
                    name: 'Special Considerations',
                    link:'considerations',
                    completed:false,
                },
                // {
                //     name: 'Vaccinations and Weights',
                //     link:'vaccinations',
                //     completed:false,
                // },
                // {
                //     name: 'Diabetes Action Plan',
                //     link:'complex',
                //     completed:false,
                // },
                // {
                //     name: 'Additional Comments',
                //     link:'additional',
                //     completed:false,
                // },
                // {
                //     name: 'New Patient Sign Off',
                //     link:'sign_off',
                //     completed:false,
                // },


            ],
            adrForm: {
                custom: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            },
            personalInformation: {
                first_name: '',
                last_name: '',
                preferred_name: '',
                tag: [],
                sex: '',
                dob: '',
            },
            regulatory_numbers: {
                ihi_number: {
                    number: '',
                    regulatory_type: 'IHI Number'
                },
                rac_id: {
                    number: '',
                    regulatory_type: 'RAC ID'
                },
                urn_mrn: {
                    number: '',
                    regulatory_type: 'URN/MRN Number'
                },
                pharmaceutical_entitlement_number: {
                    number: '',
                    regulatory_type: 'Pharmaceutical Entitlement Number'
                },
                medicare_number: {
                    number: '',
                    regulatory_type: 'Medicare Number',
                    expiry: ''
                },
                safety_net_number: {
                    number: '',
                    regulatory_type: 'Safety Net Number'
                },
                safety_net_number2: {
                    number: '',
                    regulatory_type: 'Safety Net Number 2'
                },
                dva_number: {
                    number: '',
                    regulatory_type: 'DVA Number',
                    expiry:''
                },
                concession_number: {
                    number: '',
                    regulatory_type: 'Concession Number'
                },
            },
            sex:[
                {sex:'Male'},
                {sex: 'Female'},
                {sex:'Intersex'}
            ],
            dva_statuses:[
                {status:'Gold', value: 'DVA Gold'},
                {status:'White', value: 'DVA White'},
                {status:'Orange', value: 'DVA Orange'},
            ],
            address: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            facility_location: {
                room_uuid: '',
            },
            kin: {
                first_name:'',
                last_name:'',
                relationship: '',
                phone: '',
                email: ''
            },
            untitled: {
                commencement_date: '',
                expiry_date: '',
                phone: '',
                email: '',
                gp: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: true
                },
                pharmacy: {
                    name: '',
                    address: '',
                    contact_name: '',
                    phone: '',
                    email: '',
                    fax: '',
                    uuid: ''
                },
                prescriber: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: false
                },
                prescribers: [],
                adr: [
                    {
                        custom: '',
                        reaction_description: '',
                        reaction_type: '',
                        date: ''
                    }
                ],
                special_considerations: [],
                special_considerations_notes: '',
                vaccinations: [
                    {
                        vaccine: '',
                        date: ''
                    }
                ],
                weights: [
                    {
                        weight: '',
                        date: ''
                    }
                ]
            },
            // SPECIAL CONSIDERATION OPTIONS
            medication_methods: [
                'Whole',
                'Quartered',
                'Dissolved',
                'Halved',
                'Capsule Opened',
                'Crushed and Mixed with...',
            ],
            medication_administration: [
                'Water',
                'Thickened Fluids',
            ],
            patient_considerations: [
                'Swallowing Difficulty',
                'Cognitive Impairment',
                'Resistive to Medication',
                'Self Administers',
                'Crush Medications',
                'Dexterity Issues',
                'Nil by Mouth',
            ],
            medication_delivery: [
                'Teaspoon - Staff Guide',
                'Medication Cup',
                'Resident/Patient Hand',
                'PEG Administration',
            ],
            // LOCATION DETAILS
            parentLocation: '',
            locationName: '',
            childLocations: [],
            previewImage: null,
            pharmacies: '',
            selectedPharmacy: '',
            prescribers: '',
            selectedPrescriber: '',
            ihiDetails:''
        }
    },
    methods: {
        testManualIHINumber() {
            let payload = {
                type: 'ihi',
                number: this.regulatory_numbers.ihi_number.number.split(' ').join(''),
                patient_id: "51c4b68c-8df4-4a60-8383-5e0ef3a76d79"
            }

            axios.post('/validate-ihi', payload , this.$store.state.header).then(response=>{
                
                console.log('this is the response for validate IHI', response.data);
                this.regulatory_numbers.ihi_number.number = response.data.data.ihi_number;
                this.ihiDetails = response.data.data;
                this.inputManualIHI = false;
            }).catch(err=>{
                console.log('THIS IS THE CUSTOM ERROR', err, err.response, err.response.data, err.response.data.error);
                this.displaySuccessModal = false;
                this.errActions =  err.response.data.data ? err.response.data.data.service_message.reason : 'Please update information or try again later.';
                this.displaySuccessModal = true;
                this.successMessage = err.response.data.error ? err.response.data.error : err.response.data.exception ? 'Error.' : err.response.data;
                this.first_spec_line = '';
                this.second_spec_line = err.response.data.data.service_message.reason;
            })
        },
        retrieveIHINumber(isNewPatient){
            // this.regulatory_numbers.ihi_number.number = '9393393999221119';
            // this.personalInformation.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            this.displaySuccessModal = true;
            this.totalActions = 1;
            
            let personalPayload = {...this.personalInformation}
            personalPayload.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            if(this.aslr_site_consent){
                console.log('HITTING THE ASLR SITE CONSENT CONDITION', personalPayload);
                personalPayload.tag.push('aslr_site_consent');
            }
            // let payload = {
            //     "first_name": "Aoul",
            //     "last_name": "Copeland",
            //     "dob": "1961-07-16", 
            //     "sex": "M",
            //     "ihi_number": "8003608333339273"
            // }

            // axios.post('http://localhost:8030/ihi/get-ihi', payload , this.$store.state.header).then(response=>{
            //     console.log('this is the response for validate IHI', response.data);
            // })
            console.log('THIS IS A NEW PATIENT', isNewPatient);
            if (isNewPatient || !this.patientUUID) {
                return axios.post('/person/bundle', personalPayload, this.$store.state.header).then(res => {
                    
                    console.log('patient registered.');
                    this.patientUUID = res.data.uuid;
                    this.displaySuccessModal = false;
                    this.submitRegulatoryInfo();
                    axios.post(`/person/${this.patientUUID}/status`, {status: 'onboarding'}, this.$store.state.header).then(res=>{
                        console.log('updated status', res);
                    }).catch(err=>{
                        this.loading = false;
                        this.successMessage = `Error: ${err.response.data}`;
                    })
                    this.submitImage();
                }).then(() => {
                    let payload = {
                            type: this.inputManualIHI ? 'ihi' : this.regulatory_numbers.dva_number.number ? 'dva' : this.regulatory_numbers.medicare_number.number ? 'medicare': 'ihi',
                            number: this.inputManualIHI ? this.regulatory_numbers.ihi_number.number.split(' ').join('') : this.regulatory_numbers.medicare_number.number ? this.regulatory_numbers.medicare_number.number.split(' ').join('') : this.regulatory_numbers.dva_number.number ? this.regulatory_numbers.dva_number.number : '',
                            patient_id: this.patientUUID
                        }

                    axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                        console.log('this is the response for validate IHI', response.data);
                        this.regulatory_numbers.ihi_number.number = response.data.data.ihi_number;
                        this.ihiDetails = response.data.data;
                        
                    }).catch(err => {
                        console.log('/validate-ihi failed with the following error:', err, err.response);
                        // CHECK IF THE RESPONSE IS RESOLVED BUT FOR A DIFFERENT IHI
                        if (err.response && err.response.data && err.response.data.error && err.response.data.error.includes("duplicate")) {
                            console.log('Resolved and different IHI number returned', err.response.data.data);
                            payload = {
                                type: 'ihi',
                                number: err.response.data.data.ihi_number,
                                patient_id: this.patientUUID
                            }
                            axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                                console.log("This is the response for the second validate IHI", response.data);
                                this.validIHI(response, true);
                            }).catch(err=>{
                                this.invalidIHI(err, true);
                            })
                        }
                        this.invalidIHI(err, true);
                    })
                }).catch(err=>{
                    this.displaySuccessModal = true;
                    this.message = err.response;
                    this.first_spec_line = err.response.data;
                    this.second_spec_line = '';
                });
            } else {
                let payload = {
                    type: this.inputManualIHI ? 'ihi' : this.regulatory_numbers.dva_number.number ? 'dva' : this.regulatory_numbers.medicare_number.number ? 'medicare': 'ihi',
                    number: this.inputManualIHI ? this.regulatory_numbers.ihi_number.number.split(' ').join('') : this.regulatory_numbers.dva_number.number ? this.regulatory_numbers.dva_number.number.split(' ').join('') : this.regulatory_numbers.medicare_number.number ? this.regulatory_numbers.medicare_number.number.split(' ').join('') : '',
                    patient_id: this.patientUUID
                }

                return axios.post('/person/' + this.patientUUID + '/edit', personalPayload, this.$store.state.header).then(res => {
                    console.log('patient updated.', res);
                    this.loading = false;
                    this.second_spec_line = 'Successfully updated!';
                    this.submitImage();
                    this.submitRegulatoryInfo();
                    axios.post('/validate-ihi', payload , this.$store.state.header).then(response => {
                        console.log('this is the response for validate IHI', response.data);
                        this.validIHI(response);
                    }).catch(err => {
                        console.log('/validate-ihi failed with the following error:', err, err.response);
                        // CHECK IF THE RESPONSE IS RESOLVED BUT FOR A DIFFERENT IHI
                        if (err.response && err.response.data && err.response.data.error && err.response.data.error.includes("duplicate")) {
                            console.log('Resolved and different IHI number returned', err.response.data.data);
                            payload = {
                                type: 'ihi',
                                number: err.response.data.data.ihi_number,
                                patient_id: this.patientUUID
                            }
                            axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                                console.log("This is the response for the second validate IHI", response.data);
                                this.validIHI(response);
                            }).catch(err => {
                                this.invalidIHI(err);
                            })
                        }
                        this.invalidIHI(err);
                    })
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired');
                        this.$router.push('/login');
                    }
                })
            }
        },
        createOrUpdatePatient(){
            // this.regulatory_numbers.ihi_number.number = '9393393999221119';
            // this.personalInformation.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            this.displaySuccessModal = true;
            this.totalActions = 1;
            
            let personalPayload = {...this.personalInformation}
            personalPayload.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            if(this.aslr_site_consent){
                console.log('HITTING THE ASLR SITE CONSENT CONDITION', personalPayload);
                personalPayload.tag.push('aslr_site_consent');
            }
            if (!this.patientUUID) {
                return axios.post('/person/bundle', personalPayload, this.$store.state.header).then(res => {
                    
                    console.log('patient registered.');
                    this.patientUUID = res.data.uuid;
                    axios.post(`/person/${this.patientUUID}/status`, {status: 'onboarding'}, this.$store.state.header).then(res=>{
                        console.log('updated status', res);
                    }).catch(err=>{
                        this.loading = false;
                        this.successMessage = `Error: ${err.response.data}`;
                    })
                    this.displaySuccessModal = false;
                    this.submitRegulatoryInfo();
                    this.submitImage();
                }).catch(err=>{
                    this.displaySuccessModal = true;
                    this.message = err.response;
                    this.first_spec_line = err.response.data;
                    this.second_spec_line = '';
                });
            } else {

                return axios.post('/person/' + this.patientUUID + '/edit', personalPayload, this.$store.state.header).then(res => {
                    console.log('patient updated.', res);
                    this.loading = false;
                    this.second_spec_line = 'Successfully updated!';
                    this.submitImage();
                    this.submitRegulatoryInfo();
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired');
                        this.$router.push('/login');
                    }
                })
            }
        },
        validIHI(response) {
            this.regulatory_numbers.ihi_number.number = response.data.data.ihi_number;
            this.ihiDetails = response.data.data;
            this.submitRegulatoryInfo();
        },
        invalidIHI(err) {
            this.errActions =  err.response.data.data ? err.response.data.data.service_message.reason : 'Please try again later.';
            this.displaySuccessModal = true;
            this.successMessage = err.response.data.error ? err.response.data.error : err.response.data.exception ? 'Error.' : err.response.data;
            this.first_spec_line = '';
            this.second_spec_line = err.response.data.data.service_message.reason;
        },
        saveNonBasicNonIHIDetails(){
            this.displaySuccessModal = true;
            this.totalActions = 1;
            if (this.previewImage) {
                this.totalActions += 1;
            }
            if (Object.keys(this.selectedNodeKey)[0]) {
                this.totalActions += 1;
            }
            if (this.address.street_address) {
                this.totalActions += 1;
            }
            if (this.untitled.phone || this.untitled.email) {
                this.totalActions += 1;
            }
            if (this.kin.first_name) {
                this.totalActions += 1;
            }
            if (this.untitled.prescriber.prescriber_number) {
                this.totalActions += 1;
            }
            if (this.untitled.gp.prescriber_number) {
                this.totalActions += 1;
            }

            this.submitImage();
            this.confirmFacilityLocation();
            this.submitRegulatoryInfo();
            this.setPatientAddress();
            this.addContactMethods();
            this.createKin();
            // BELOW DOES NOT HAVE COMPLETED/TOTAL ACTIONS ACCOUNTED FOR
            this.submitPharmacy();
            this.submitPrescriber();
            this.submitADR();
            this.submitSpecCons();
            this.submitVx();
            this.submitWeights();
        },
        retrieveSelectedFacilityLocations() {
            this.loadingLocations = true;
            this.locationDirectory = '';
            console.log('this is the selected facility', this.selectedFacility);
            axios.post(`/facility/${this.selectedFacility.value}/locations`, {}, this.$store.state.header).then(res => {
                console.log('these are the facilities locations', res.data);
                this.nodes = res.data;
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
                this.loadingLocations = false;
            })
        },
        getFacilities() {
            this.$store.dispatch('getRefreshToken');
            axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
                this.facilities = res.data;
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        close() {
            this.displaySuccessModal = false;
            this.displayIHIHistoryModal = false;
            if(this.inputManualIHI && this.errActions){
                this.inputManualIHI = false;
                this.errActions = '';
                this.regulatory_numbers.ihi_number = {
                    number: '',
                    regulatory_type: 'IHI Number'
                }
            }
            // window.location.reload();
        },
        
        // UPLOAD IMAGE
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                console.log(this.previewImage);
            };
        },
        nextFormPage() {
            if (this.selectedNavigation == 'personal_information') {
                console.log('hitting the if, personal_information')
                this.selectedNavigation = this.navigationItems[3].link;
            } else {

                switch (this.selectedNavigation) {
                    // case 'prescriber_details':
                    //     this.selectedNavigation = this.navigationItems[4].link;
                    //     break;
                    case 'personal_information':
                        this.selectedNavigation = 'contact_details';
                        break;
                    case 'contact_details':
                        this.selectedNavigation = 'prescriber_details';
                        break;
                    case 'prescriber_details':
                        this.selectedNavigation = 'allergies';
                        break;
                    case 'allergies':
                        this.selectedNavigation = 'considerations';
                        break;
                    case 'considerations':
                        this.selectedNavigation = this.navigationItems[6].link;
                        break;
                    case 'vaccinations':
                        this.selectedNavigation = this.navigationItems[7].link;
                        break;
                    case 'complex':
                        break;

                }

            }
        },
        getPharmacies() {
            this.$store.dispatch('getRefreshToken');
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/pharmacies`, {}, this.$store.state.header).then(res => {
                console.log('these are the pharmacies', res.data);
                this.pharmacies = res.data;
            })
        },
        getPrescribers() {
            this.$store.dispatch('getRefreshToken');
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/prescribers`, {}, this.$store.state.header).then(res => {
                console.log('these are the prescribers', res.data);
                this.prescribers = res.data;
            })
        },
        // REGISTER PATIENT
        patientRegistration() {
            this.personalInformation.dob = this.personalInformation.dob.split("-")[2] + "/" + this.personalInformation.dob.split("-")[1] + "/" + this.personalInformation.dob.split("-")[0];
            this.displaySuccessModal = true;
            this.totalActions = 1;
            if (this.previewImage) {
                this.totalActions += 1;
            }
            if (Object.keys(this.selectedNodeKey)[0]) {
                this.totalActions += 1;
            }
            if (this.address.street_address) {
                this.totalActions += 1;
            }
            if (this.untitled.phone || this.untitled.email) {
                this.totalActions += 1;
            }
            if (this.kin.first_name) {
                this.totalActions += 1;
            }
            if (this.untitled.prescriber.prescriber_number) {
                this.totalActions += 1;
            }
            if (this.untitled.gp.prescriber_number) {
                this.totalActions += 1;
            }
            return axios.post('/person/bundle', this.personalInformation, this.$store.state.header).then(res => {
                
                // if(this.completedActions == this.totalActions){
                //     window.location.reload(); 
                // }
                console.log('patient registered.');
                this.patientUUID = res.data.uuid;
                axios.post(`/person/${this.patientUUID}/status`, {status: 'onboarding'}, this.$store.state.header).then(res=>{
                    console.log('updated status', res);
                }).catch(err=>{
                    this.loading = false;
                    this.successMessage = `Error: ${err.response.data}`;
                })
                this.submitImage();
                this.confirmFacilityLocation();
                this.submitRegulatoryInfo();
                this.setPatientAddress();
                this.addContactMethods();
                this.createKin();
                // BELOW DOES NOT HAVE COMPLETED/TOTAL ACTIONS ACCOUNTED FOR
                this.submitPharmacy();
                this.submitPrescriber();
                this.submitADR();
                this.submitSpecCons();
                this.submitVx();
                this.submitWeights();
            })
        },
        getLocations() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/locations`, {}, this.$store.state.header).then(res => {
                console.log('this is the facility location', res);
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
            })
        },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        // SUBMIT IMAGE
        submitImage() {
            if (this.previewImage) {
                axios.post(`/aws/patient/${this.patientUUID}/image`, {
                    image: this.previewImage,
                    item:['profile', 'thumbnail']
                }, this.$store.state.header).then(res => {
                    
                    // if(this.completedActions == this.totalActions){
                    //     window.location.reload(); 
                    // }
                    console.log('uploaded patient image', res);
                }).catch(err => {
                    
                    
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error uploading image'
                    console.log('this is the image upload error', err, err.response.data);
                })
            } else {
                console.log('No image has been uploaded');
            }

        },
        // submitImage() {
        //     if (this.previewImage) {
        //         axios.post(`/aws/patient/${this.patientUUID}/image`, {
        //             image: this.previewImage,
        //             item:['profile', 'thumbnail']
        //         }, this.$store.state.header).then(res => {
        //             console.log('uploaded patient image', res);
        //             this.displaySuccessModal = true;
        //             this.first_spec_line = 'Successfully uploaded image';
        //             this.$store.dispatch('getRefreshToken');
        //         }).catch(err=>{
        //             this.displaySuccessModal = true;
        //             this.first_spec_line = err.response && err.response.data ? err.response.data : err;
        //         })
        //     } else {
        //         console.log('No image has been uploaded');
        //     }

        // },
        // CONFIRM FACILITY LOCATION
        confirmFacilityLocation() {
            this.locationSaveStatus = 'Saving Location...';
            this.$store.dispatch('getRefreshToken');
            if (Object.keys(this.selectedNodeKey)[0]) {
                axios.post(`/person/${this.patientUUID}/location`, { status: 'resident', location: Object.keys(this.selectedNodeKey)[0] }, this.$store.state.header).then(res => {
                    
                    
                    console.log('facility location submitted.', res);
                    this.locationSaveStatus = 'Location saved!'
                }).catch(err => {
                    
                    
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error assigning resident to location.';
                    this.locationSaveStatus = 'Error when saving location. Try again later.'
                    console.log('this is the link resident error', err, err.response.data);
                })
            } else {
                console.log('no facility location confirmed.');
                this.locationSaveStatus = 'No location selected.';
            }
        },
        // REGULATORY INFO
        submitRegulatoryInfo() {
            let regulatory_numbers = [];
            this.displaySuccessModal = true;
            this.second_spec_line = '';
            this.first_spec_line = '';
            this.loading = true;
            for (let key in this.regulatory_numbers) {
                console.log('this is the key', key);
                if (this.regulatory_numbers[key].number && this.regulatory_numbers[key].regulatory_type != 'IHI Number') {
                    regulatory_numbers.push({
                        data: this.regulatory_numbers[key].number.split(' ').join(''),
                        regulatory_type: this.regulatory_numbers[key].regulatory_type,
                        expiry: this.regulatory_numbers[key].expiry ? new Date(this.regulatory_numbers[key].expiry).toLocaleDateString() : ''
                    })
                }

            }
            if (regulatory_numbers.length) {
                this.totalActions += 1;
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.patientUUID}/regulatory`, { 'regulatory_numbers': regulatory_numbers }, this.$store.state.header).then(res => {
                    this.loading = false;
                    this.first_spec_line = 'Successfully saved!';
                    this.successMessage = '';
                    this.second_spec_line = '';
                    // if(this.completedActions == this.totalActions){
                    //     window.location.reload(); 
                    // }
                    console.log('regulatory information submitted.', res)
                }).catch(err => {
                    
                    // if(this.completedActions == this.totalActions){
                    //     window.location.reload(); 
                    // }
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error submitting regulatory information.'
                    console.log('this is the reg info error', err, err.response.data);
                })
            } else {
                console.log('no regulatory numbers filled in or submitted.')
            }


        },
        // PATIENT ADDRESS
        setPatientAddress() {
            this.second_spec_line = '';
            this.first_spec_line = '';
            if (this.address.street_address) {
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.patientUUID}/address`, this.address, this.$store.state.header).then(res => {
                    
                    
                    console.log('patient address submitted.', res);
                    this.displaySuccessModal = true;
                    this.second_spec_line = 'Address successfully saved.'
                }).catch(err => {
                    
                    
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error adding address..';
                    console.log('this is the address error', err, err.response.data);
                })
            } else {
                console.log('no address has been added or submitted');
            }

        },
        // ADD PHONE AND EMAIL CONTACT METHODS
        addContactMethods() {
            this.loading = true;
            this.displaySuccessModal = true;
            this.second_spec_line = '';
            this.first_spec_line = '';
            let contact_methods = [];
            if (this.untitled.phone) {
                contact_methods.push({
                    name: 'Phone',
                    data: this.untitled.phone,
                    type: 'phone'
                });
            }
            if (this.untitled.email) {
                contact_methods.push({
                    name: 'Email',
                    data: this.untitled.email,
                    type: 'email'
                });
            }
            if (contact_methods.length) {
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.patientUUID}/contact-method`, { 'contact_methods': contact_methods }, this.$store.state.header).then(res => {
                    this.second_spec_line = '';
                    this.first_spec_line = 'Successfully saved contact details.';
                    this.loading = false;
                    this.successMessage = '';
                    
                    console.log('contact methods submitted.', res)
                }).catch(err => {
                    
                    
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error adding contact methods.'
                    console.log('this is the create contact method error', err, err.response.data);
                })
            } else {
                console.log('no contact methods were entered, therefore not submitted.')
            }
        },
        // CREATE KIN
        createKin() {
            if (this.kin.first_name) {
                this.loading = true;
                this.second_spec_line = '';
                this.first_spec_line = '';
                this.displaySuccessModal = true;
                let names = this.kin.first_name.split(' ');
                this.kin.first_name = names.shift();
                this.kin.last_name = names.join(' ');
                this.$store.dispatch('getRefreshToken');
                axios.post(`/patient/${this.patientUUID}/kin`, this.kin, this.$store.state.header).then(res => {
                    
                    this.loading = false;
                    this.first_spec_line = 'Successfully saved kin details.';
                    this.second_spec_line = '';
                    console.log('patient kin submitted.', res)
                }).catch(err => {
                    
                    
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error creating Kin.'
                    console.log('this is the create kin error', err, err.response.data);
                })
            } else {
                console.log('first name of kin not entered, so submission not made for kin')
            }

        },
        // ADD PRESCRIBERS (INCL GP) AND PHARMACY
        submitPrescriber() {
            this.second_spec_line = '';
            this.first_spec_line = '';
            if (this.selectedPrescriber) {
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.patientUUID}/link-prescriber`, { prescriber_id: this.selectedPrescriber.uuid, primary: true }, this.$store.state.header).then(res => {
                    console.log('patient prescriber submitted.', res);
                    this.first_spec_line = 'Prescriber linked to resident.';
                    this.successMessage = '';
                    this.displaySuccessModal = true;
                    this.second_spec_line = '';
                })
            }

        },
        submitPharmacy() {
            this.second_spec_line = '';
            this.first_spec_line = '';
            if (this.selectedPharmacy) {
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.patientUUID}/link-pharmacy`, { pharmacy_id: this.selectedPharmacy.uuid }, this.$store.state.header).then(res => {
                    console.log('patient pharmacy submitted.', res);
                    this.displaySuccessModal = true;
                    this.first_spec_line = 'Pharmacy linked to resident.';
                    this.second_spec_line = '';
                })
            }

        },
        // SUBMIT ADR ARRAY
        submitADR() {
            this.$store.dispatch('getRefreshToken');
            this.adrErrorMessage = '';
            this.second_spec_line = '';
            this.first_spec_line = '';
            this.displaySuccessModal = true;
            this.loading = true;
            let adrPayload = [];
            if (this.untitled.adr[0].custom != '') {
                this.untitled.adr.forEach((item)=>{
                    if(item.custom){
                        let citem = {...item};
                        if(citem.date){
                            citem.date = new Date(citem.date).toLocaleDateString('en-AU');
                        }
                        adrPayload.push(citem);
                    }
                })
                axios.post(`/patient/${this.patientUUID}/adr`, { adr: adrPayload }, this.$store.state.header)
                .then(res => {
                    console.log('ADR submitted.', res);
                    this.loading = false;
                    this.first_spec_line = 'Successfully saved ADR list.';
                    this.second_spec_line = '';
                    this.successMessage = '';
                })
                .catch(err => {
                    console.log('Error submitting adr', err);
                    this.adrErrorMessage = err.response.data.message;
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            } else {
                console.log('no adr to submit')
            }
        },
        // SUBMIT SPECIAL CONSIDERATIONS
        submitSpecCons() {
            this.loading = true;
            this.displaySuccessModal = true;
            this.second_spec_line = '';
            this.first_spec_line = '';
            let specCons = {
                special_considerations: this.untitled.special_considerations,
                comments: this.untitled.special_considerations_notes
            }
            if (specCons.special_considerations.length || specCons.comments) {
                this.$store.dispatch('getRefreshToken');
                axios.post(`/patient/${this.patientUUID}/special-considerations`, specCons, this.$store.state.header).then(res => {
                    console.log('spec cons submitted.', res);
                    this.second_spec_line = '';
                    this.first_spec_line = '';
                    this.first_spec_line = 'Successfully saved special considerations.';
                    this.loading = false;
                    this.successMessage = '';
                })
            } else {
                console.log('no special cons or notes made, not submitted.')
            }

        },
        // SUBMIT VAX
        submitVx() {
            if (this.untitled.vaccinations[0].vaccine != '') {
                axios.post(`/patient/${this.patientUUID}/vaccinations`, this.untitled.vaccinations, this.$store.state.header).then(res => {
                    console.log('vaccinations submitted.', res)
                })
            } else {
                console.log('no vaccinations recorded')
            }
        },
        // SUBMIT WEIGHTS
        submitWeights() {
            if (this.untitled.weights[0].weight != '') {
                axios.post(`/patient/${this.patientUUID}/weights`, this.untitled.weights, this.$store.state.header).then(res => {
                    console.log('weights submitted.', res)
                })
            } else {
                console.log('no weights recorded')
            }
        },
        // FORM SUBMIT PROCESS
        submitForm() {
            this.patientRegistration();
        },
        selectChildLocation() {
            this.facility_location.room_uuid = Object.keys(this.selectedNodeKey)[0];
        },
        addNewADR() {
            let newEntry = {
                custom: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            };
            this.untitled.adr.unshift(newEntry);
        },
        removeADR(index) {
            this.untitled.adr.splice(index, 1);
        },
        addNewVaccine() {
            let newVaccine = {
                vaccine: '',
                date: ''
            };
            this.untitled.vaccinations.unshift(newVaccine);
        },
        removeVaccine(index) {
            this.untitled.vaccinations.splice(index, 1);
        },
        addNewWeight() {
            let newWeight = {
                weight: '',
                date: ''
            };
            this.untitled.weights.unshift(newWeight);
        },
        removeWeight(index) {
            this.untitled.weights.splice(index, 1);
        },
        saveSelectedItem(item) {
            console.log('cacheSelectedItem is working', item);
            if (this.untitled.special_considerations.includes(item)) {
                console.log('this item exists in array', this.untitled.special_considerations);
                this.untitled.special_considerations.forEach((alreadySavedItem, index) => {
                    if (item == alreadySavedItem) {
                        this.untitled.special_considerations.splice(index, 1);
                    }
                })
            } else {
                console.log('this item does not exist in array', this.untitled.special_considerations);
                this.untitled.special_considerations.push(item);
            }
        },
        getPersonalAddressData(addressData, propertyData, id) {

            this.address.state = addressData.administrative_area_level_1;
            this.address.city = addressData.administrative_area_level_2;
            this.address.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.address.country = addressData.country;
            this.address.postcode = addressData.postal_code;
            this.address.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.address);
        },
        // ON PAGE LOAD
        getLocationTree() {
            axios.post(`/location/${this.$store.state.uuid}/simple-tree`, {}, this.$store.state.header).then(res => {
                this.parentLocation = res.data.parent_location_name;
                this.locationName = res.data.name;
                this.childLocations = res.data.child_locations;
            })
        }
    },
    mounted() {
        if(!this.$store.state.user.enmrc_eprescribing_active){
            this.navigationItems = [
                {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Details',
                    link: 'contact_details',
                    completed: false,
                },
                {
                    name: 'Entitlement Numbers',
                    link: 'contact_details',
                    completed: false,
                }
            ]
        } else{
            this.getPharmacies();
            this.getPrescribers();
        }
        this.getLocationTree();
        this.getLocations();
        
        this.getFacilities();
    },
    computed() {

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";


#monthpicker{
    border-radius: 12px;
    input.p-inputtext{
        background-color: #f3f4f4 !important;
        
    }
}

.selected-navigation-item {
    width: 5px;
    height: 45px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    background-color: $strongblue;
}


.form-fields {
    display: flex;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;

    label {
        padding-left: 10px;
    }

    input,
    label {
        display: block;
    }
}

.text-field {
    width: 20em;
}

.date-field {
    width: 20em;
}

.address-field {
    width: 12em;
}

.special-considerations-form {
    background-color: $grey;
    padding: 10px;
    border-radius: 8px;
}

.spec-cons-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.spec-cons-item {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.vac-weight-table {
    max-height: 300px;
    overflow-y: scroll;
}

// .form-control{
//     width: 20em;
// }

.prescriber-info-form-content {
    h3 {
        margin-top: 12px;
    }
}

#monthpicker {
    background-color: #F3F4F4 !important
}
</style>