<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="displayDeliveryModal = true" class="actions-menu-item">New Delivery</button>
    </div>
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')">
      <h3 class="panel-header">Deliveries</h3>
      <div class="drug-register-table table-container">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center; height: 50px;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">

            <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
            </div>

            <div class="text-search-container search-container">
             <input v-model="form.filters.drug" placeholder="Drug..." style="position: relative" />
            </div>

             <div class="text-search-container search-container">
             <input v-model="form.filters.supplier" placeholder="Supplier..." style="position: relative" />
            </div>

            <button class="button submit-button" @click="loadEntries()">Search</button>
            <button class="button clear-button" @click="clearEntriesFilters()">Clear</button>
          </div>

          <div style="display: flex; align-items: center">
              <div class="select-filters-container">

              <!-- <p @click="()=>{tags.antimicrobial = !tags.antimicrobial; autoAll()}" 
                v-bind:class="[tags.antimicrobial == true ? 'active-select-filter' : '']" class="select-filter-item">Antimicrobial</p> -->

              <p @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter' : 'outline-button']" :style="tags.s8 ? { paddingTop: '8px'}:{paddingTop: '6px'}" style="height: 33px;">S8 Only</p>

              <!-- <p @click="()=>{tags.s4 = !tags.s4; autoAll()}" 
                v-bind:class="[tags.s4 == true ? 'active-select-filter' : '']" class="select-filter-item">S4</p> -->
              
            </div>
          </div>
          
          <!-- SELECT FILTER SECTION -->
          <MultiSelect v-model="selectedStatuses" :options="statusSelect" @change="updateStatusList" optionLabel="status" placeholder="Status" />
          
        </div>
        <div v-if="!$store.state.loadingDeliveriesData" style="display: flex; align-items: start; overflow-y: scroll">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_deliveries")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
        <table v-else class="data-table" cellspacing="0">
          <tr class="table-headers" >
              <th style="width: 50px"></th>
            <th>
              <h3 @click="sortBy('location', 'desc')">Supplier</h3>
              <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="form.col =='location' && form.sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3>Patient</h3>
            </th>
            <th>
              <h3 @click="sortBy('delivery_date', 'desc')">Delivery Date</h3>
              <div v-if="form.col == 'delivery_date'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  delivery_date-sort" v-if="form.col =='delivery_date' && form.sort_direction =='asc'" @click="sortBy('delivery_date', 'desc')"></i>
              <i class="pi pi-sort delivery_date-sort" v-else @click="sortBy('delivery_date', 'asc')"></i>
              </div>
            </th>
            
            <th>
              <h3 @click="sortBy('completed_by', 'desc')">Completed by</h3>
              <div v-if="form.col == 'completed_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_by-sort" v-if="form.col =='completed_by' && form.sort_direction =='asc'" @click="sortBy('completed_by', 'desc')"></i>
              <i class="pi pi-sort completed_by-sort" v-else @click="sortBy('completed_by', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('completed_date', 'desc')">Completed Date</h3>
              <div v-if="form.col == 'completed_date'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  date-sort" v-if="form.col =='completed_date' && form.sort_direction =='asc'" @click="sortBy('completed_date', 'desc')"></i>
              <i class="pi pi-sort date-sort" v-else @click="sortBy('completed_date', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 style="padding-left: 20px;">Status</h3>
              <!-- <i class="pi pi-sort  status-sort" v-if="form.col =='status' && form.sort_direction =='asc'" @click="sortBy('status', 'desc')"></i>
              <i class="pi pi-sort status-sort" v-else @click="sortBy('status', 'asc')"></i> -->
            </th>
            

          </tr>
          <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
            <tr @click="showContent(item, index)" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']">
                <td>
                    <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                    <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>{{item.supplier}}</td>
                <td>{{item.resident}}</td>
                <td>{{item.delivery_date}}</td>
                <td>{{item.completed_by && item.completed_by.personal_information && item.completed_by.personal_information.first_name ? item.completed_by.personal_information.first_name + ' '+ item.completed_by.personal_information.last_name:''}}</td>
                <td>{{item.completed_date}}</td>
                <td><p :class="[item.status == 'completed' ? 'correct-status-color' : (item.status == 'reversed' || item.status == 'Reversed' ? 'mismatch-status-color' : 'warning-status-color')]">{{item.status == 'completed' ? 'Completed' : 'Reversed'}}</p></td>
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr">
                  <div v-if="!item.expandedInfo" style="margin: 0 auto">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                  </div>
                  <div v-else class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                   <div class="transfer-notes-container">
                     <div>
                       <h4 class="field-title">Reference Number</h4>
                       <div class="field-notes" style="width: 95%; height: fit-content;">
                           <p >{{item.reference_number}}</p>
                       </div>
                     </div>
                     <div>
                       <h4 class="field-title">Notes</h4>
                       <div class="field-notes" style="width: 95%; height: 245px;">
                           <p >{{item.expandedInfo.notes}}</p>
                       </div>
                     </div>
                       
                   </div>
                   <div class="transfer-drugs-container">
                       <h4 class="field-title">Drugs</h4>
                       <div class="drug-field-tables" style="width: 100%;
                                                                border-radius: 4px;
                                                                height: 300px;">
                        <table style="padding: 0px 5px; border-bottom: 1px solid #E7E9E8">
                            <tr>
                                <th>Name</th>
                                <!-- <th style="width: 80px;">Form</th> -->
                                <th style="width: 100px;">Count</th>
                                <!-- <th>Patient</th>
                                <th>Prescriber</th> -->
                            </tr>
                        </table>
                        <div style="height: 250px; overflow-y: scroll; background-color: white;">
                            <table style="padding-top: 0;">
                                <tr v-for="(drug,key) in item.expandedInfo.drugs" :key="key" style="height: 25px">
                                    <td>{{drug.alias.name}}</td>
                                    <!-- <td style="width: 80px;">{{drug.alias.form}}</td> -->
                                    <td style="width: 100px;">{{drug.quantity}}</td>
                                    <!-- <td>Patrick Souzasolopous</td>
                                    <td>Richard Kit</td> -->
                                </tr>
                            </table>
                        </div>
                       </div>
                   </div>
                  </div>
               </div>
                <!-- TRANSFER ACTIONS -->
                <div class="transfer-actions-container">
                    <button @click="transferAction('Approve', item, item.expandedInfo.drugs)" class="transfer-action-button complete-button" v-show="item.status == 'Pending'">Complete Delivery</button>
                    <!-- <button @click="transferAction('Reject', item.uuid, item.expandedInfo.drugs)" class="transfer-action-button error-button" v-show="item.status == 'Pending'">Reject Delivery</button> -->
                    <button @click="transferAction('Reverse', item)" class="transfer-action-button error-button" v-show="item.status == 'completed'">Reverse Delivery</button>
                    <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                </div>
            </tr>    
          </tbody>
          
        </table>
        </div>
        <div v-else>
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="$store.state.drugEntries && $store.state.drugEntries.current_page">
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
          </div>
       </div>
       <!-- <ActionModal :toAction="toAction" /> -->
       <SignatureModal v-if="displayActionModal" @close="close" @isSuccessful="isSuccessful" :payload='toAction' endpoint="/reverse-delivery" dual="true" :notes=true />
       <DeliveryModal v-if="displayDeliveryModal" @close="close" />
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import DeliveryModal from '@/components/modals/DeliveryModal.vue';
import axios from 'axios';

export default {
  components: { 
      SignatureModal,
      DeliveryModal
  },
  data(){
    return{
        loadingError:'',
        displayNewTransferModal: false,
        displayActionModal: false,
        displayDeliveryModal: false,
        toAction:{
            uuid:'',
            action: 'Approve',
            drugs:[]
        },
        selectedStatuses: [
            // {status:'Pending'},
            {status:'Reversed'},
            {status:'Completed'}
		],
		statusSelect: [
            // {status:'Pending'},
            {status:'Reversed'},
            {status:'Completed'}
		],
        tags:{
            s8:false,
            s4: false,
            antimicrobial: false,
        },
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true
      },
      toCache:[],
      date:[],
      clearForm:{
            sort_direction: 'desc',
            col: 'completed_date',
            page:1,
            filters:{
                tags:[],
                status:['completed', 'reversed'],
                supplier:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                }
            },
            
        },
      form:{
            sort_direction: 'desc',
            col: 'completed_date',
            location: this.$store.state.currentLocation.uuid,
            page:1,
            filters:{
                tags:[],
                status:['completed', 'reversed'],
                supplier:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                }
            },
            
        },
      testData:[
      ]
      
    }
  },
  methods:{
    close(value){
        console.log('delivery modal should be closing here, outside if');
        if(value){
            console.log('delivery modal should be closing here');
            this.displayActionModal = false;
            this.displayDeliveryModal = false;            
        }
    },
    toggleNewTransferModal(type){
        this.transferType = type;
        this.displayNewTransferModal = true;
    },
    transferAction(selectedAction, item, drugArray){
        console.log('this is the action id', item.uuid, selectedAction);
        console.log('TRANSFER ACTION FUNCTION', drugArray);
        this.toAction = {
            uuid: item.uuid,
            notes: item.expandedInfo.notes
        }
        this.displayActionModal = true;
    },
    isSuccessful(value){
      console.log('this has been', value);
      this.$store.dispatch('getDeliveries', this.form);
    },
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.tags)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        this.loadEntries();
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.loadEntries();
    },
    showContent(delivery, index){
        console.log('this the selected log', delivery)
        // let refid=`expand-${index}`;

        if(delivery.display && (delivery.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            delivery.display = !delivery.display;
        } else{
            delivery.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
            axios.post('/single-delivery', {uuid: delivery.uuid}, this.$store.state.header).then(res=>{
                console.log('this is the state function to get a single delivery', res.data);
                delivery.expandedInfo = {
                  notes:res.data.note,
                  drugs:res.data.units
                }
            }).catch(err=>{
              this.loadingError = err;
              console.log('this is the error, err');
            })
            

        }
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadEntries(){
      console.log('this is the date', this.date);
      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
        }
        this.$store.dispatch('getDeliveries', this.form);
      } else{
        this.form.filters.date.start ='';
        this.form.filters.date.end = '';
        this.$store.dispatch('getDeliveries', this.form);
      }
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getDeliveries', this.form);
    },
    updateStatusList(){
        this.form.filters.status = [];
        console.log('updating status list')
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                console.log('this is the key', key);
                this.form.filters.status.push(value.toLowerCase());
             }
        });
        this.loadEntries();
    },
    updateType(){
        this.form.filters.type = [];
        for (const [key, value] of Object.entries(this.types)) {
            if(value == true){
                this.form.filters.type.push(key)
            }
        }
    },
    clearEntriesFilters(){
      this.form = {
            sort_direction: 'desc',
            col: 'completed_date',
            page:1,
            filters:{
                tags:[],
                status:['reversed', 'completed'],
                supplier:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                }
            },
            
        },
      this.date = [];
      this.$store.dispatch('getDeliveries', this.form);
    },
  },
   mounted(){
    this.loadEntries();
    this.toCache = [];
  },
  computed:{
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    }
  }
}
</script>
