<template>
  <div v-if="user && !loadingMFA">
    <div v-if="displayMFAWarning" style="border: 2px solid orange; border-radius: 8px; padding: 5px; width: 80%;">
      <i class="pi pi-exclamation-circle" style="font-size: 1.5em !important; color: orange; margin-top: 10px;"></i>
      <p style="font-size: 1em; font-weight: bold">
        You are required to enable Multi-Factor Authentication to log in. <br> Please enable mobile phone and/or email
        authentication methods and choose a contact option to receive the verification code.
      </p>
    </div>
    <!-- PHONE MFA -->
    <!-- PHONE MFA -->
    <!-- PHONE MFA -->
    <!-- PHONE MFA -->
    <div>
      <p style="text-align: left; font-size: 1em;"><span style="color: red; font-weight:bold;">Warning</span>: Selecting
        <b>both</b> mobile and email authentication will require you to enter both codes when logging in.
      </p>
      <div style="display: flex; align-items: center;">
        <p style="margin-right: 10px; font-size: 1em;">Mobile Phone Multi-Factor Authentication</p>
        <InputSwitch v-model="userSettings.mfa_phone_active" @change="saveMFA('phone', 'toggle', false)"
          :disabled="(user.contact_methods.filter(contact => contact.type == 'phone')).length == 0" />
      </div>
      <p v-if="(user.contact_methods.filter(contact => contact.type == 'phone')).length == 0"
        style="color: red;text-align: left;">A mobile number is required to enable MFA via SMS.</p>
      <div v-if="userSettings.mfa_phone_active">
        <p v-if="userSettings.mfa_phone_number" style="text-align: left; font-size: 1em;">Current MFA Phone Number:
          <span style="font-weight: bold">{{ userSettings.mfa_phone_number.data }}</span>
        </p>
        <div v-if="userSettings.mfa_phone_number">
          <div v-for="(item, index) in user.contact_methods" :key="index">
            <div v-if="item.type == 'phone'" style="display: flex; align-items: center;">
              <!-- @click="item.is_mfa ? item.is_mfa = true : item.is_mfa = false" -->
              <RadioButton v-model="userSettings.mfa_phone_number.data" @change="updatePhoneMFA(item)"
                :value="item.data" />
              <!-- NUMBER -->
              <p style="padding-left: 10px;padding-right: 10px; font-size: 1em;">{{ item.data }}</p>
              <!-- VERIFY INTERFACE -->
              <div style="display: flex;" v-if="verifyPhone && userSettings.mfa_phone_number.data == item.data">
                <!-- Get Verify Code -->
                <p v-if="!verifiedPhone && !sentPhoneCode" class="blue-button" style="width: fit-content;"
                  @click="sendMFAPhoneCode()">Receive Verification Code</p>
                <!-- Verify Phone Code -->
                <div style="display: flex; align-items: center;" v-if="sentPhoneCode && !verifiedPhone">
                  <input type="text" v-model="phoneCode" placeholder="Enter phone code here." />
                  <p class="blue-button" style="margin-left: 10px; margin-right: 10px;" @click="checkMFACode('phone')">
                    Verify
                  </p>
                  <p class="black-button" @click="verifyPhone = false, verifiedPhone = false, sentPhoneCode = false">
                    Cancel</p>
                </div>
                <!-- Number Verfied And Save Option -->
                <p v-if="verifyPhone && verifiedPhone"
                  style="width: fit-content; margin-right: 10px; font-size:1em; color: green">MFA phone number verified
                  and updated!</p>
                <!-- <p v-if="verifyPhone && verifiedPhone" class="blue-button" style="width: fit-content; margin-right: 10px;"
                  @click="saveMFA('phone')">Save</p> -->


              </div>
            </div>
          </div>
        </div>


        <!-- ADD NEW PHONE TO EXISTING USER -->
        <!-- ADD NEW PHONE TO EXISTING USER -->
        <div v-if="!displayNewPhone" style="margin: 1em 0; width: fit-content" @click="displayNewPhone = true">
          <p class="button green-button"><span style="font-size: 1.2em;">+</span> Add Phone Number </p>
        </div>

        <!-- NEW PHONE -->
        <div v-if="displayNewPhone">
          <p style="font-weight: 500; text-align: left; font-size: 1em; margin: 0;">Mobile Phone:</p>
          <div style="display: grid;grid-template-columns: 10% 90%; align-items: center; width: fit-content;gap:5px;">
            <p>{{ selectedPhoneCountry.callingcode }} </p>
            <InputText type="number" style="background-color: #F3F4F4;" v-model="newPhone.data"
              placeholder="Enter Phone Number" />
          </div>
          <p style="margin: 0; font-size: 1em;text-align: left;">This number must be a mobile phone.</p>
        </div>
        <div style="display: flex;">
          <div v-if="displayNewPhone" style="margin: 2em 0; margin-right: 1em;"
            @click="displayNewPhone = false, addPhoneToUser()">
            <p class="green-button">Submit</p>
          </div>
          <div v-if="displayNewPhone" style="margin: 2em 0; margin-right: 1em; border-color: grey;"
            @click="displayNewPhone = false">
            <p class="button black-button">Cancel</p>
          </div>
        </div>



      </div>
    </div>


    <!-- EMAIL MFA -->
    <!-- EMAIL MFA -->
    <!-- EMAIL MFA -->
    <!-- EMAIL MFA -->
    <div>
      <div style="display: flex; align-items: center;">
        <p style="margin-right: 10px; font-size: 1em;">Email Multi-Factor Authentication</p>
        <InputSwitch v-model="userSettings.mfa_email_active" @change="saveMFA('email', 'toggle', false)" />
      </div>
      <p v-if="(user.contact_methods.filter(contact => contact.type == 'email')).length == 0"
        style="color: red;text-align: left;">An email address is required to enable MFA via email.</p>
      <div v-if="userSettings.mfa_email_active">
        <p v-if="userSettings.mfa_email" style="text-align: left;  font-size: 1em;">Current MFA Email: <span
            style="font-weight: bold">{{ userSettings.mfa_email.data }}</span></p>
        <div v-if="userSettings.mfa_email">
          <div v-for="(item, index) in user.contact_methods" :key="index">
            <div v-if="item.type == 'email'" style="display: flex; align-items: center;">
              <!-- @click="item.is_mfa ? item.is_mfa = true : item.is_mfa = false" -->
              <RadioButton v-model="userSettings.mfa_email.data" @change="updateEmailMFA(item)" :value="item.data" />
              <p style="padding-left: 10px;padding-right: 10px; font-size: 1em;">{{ item.data }}</p>
              <!-- VERIFY EMAIL -->
              <div style="display: flex;" v-if="verifyEmail && userSettings.mfa_email.data == item.data">
                <p v-if="verifyEmail && verifiedEmail"
                  style="width: fit-content; margin-right: 10px; font-size:1em; color: green">MFA email address verified
                  and
                  updated!</p>
                <p v-if="verifyEmail && !verifiedEmail && !sentMailCode" class="blue-button"
                  style="width: fit-content; margin-right: 10px;" @click="sendMFAEmailCode()">Receive
                  Verification Code</p>
                <div style="display: flex; align-items: center;" v-if="sentMailCode && !verifiedEmail">
                  <input type="text" v-model="mailCode" placeholder="Enter email code here." />
                  <p class="blue-button" style="margin-left: 10px; margin-right: 10px;" @click="checkMFACode('email')">
                    Verify
                  </p>
                  <p class="black-button"
                    @click="userSettings.new_mfa_email = '', verifyEmail = false, verifiedEmail = false">
                    Cancel</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ADD NEW EMAIL ADDRESS TO EXISTING USER -->
        <!-- ADD NEW EMAIL ADDRESS TO EXISTING USER -->
        <div v-if="!displayNewEmail" style="margin: 1em 0; width: fit-content;" @click="displayNewEmail = true">
          <p class="button green-button"><span style="font-size: 1.2em;">+</span> Add Email </p>
        </div>

        <!-- NEW EMAIL -->
        <div v-if="displayNewEmail" style="justify-content: start; display: grid">
          <p style="font-weight: 500; text-align: left; font-size: 1em; margin: 0;">Email Address:</p>
          <input type="text" style="margin-bottom: 0; width: 20em" v-model="newEmail.data" placeholder="Enter Email">
          <p style="margin: 0; font-size: 1em">
            This email address does not affect login details, but is required for MIMS activation.
          </p>
        </div>
        <div style="display: flex;">
          <div v-if="displayNewEmail" style="margin: 2em 0; margin-right: 1em;"
            @click="displayNewEmail = false, addEmailToUser()">
            <p class="button green-button">Submit</p>
          </div>
          <div v-if="displayNewEmail" style="margin: 2em 0; margin-right: 1em; border-color: grey;"
            @click="displayNewEmail = false">
            <p class="button black-button">Cancel</p>
          </div>
        </div>


      </div>
    </div>

    <p style="font-weight: bold; color: red; text-align: left; font-size: 1em;" v-if="mailCodeError || phoneCodeError">
      Error: {{ mailCodeError }} {{ phoneCodeError }}</p>
  </div>
  <div v-else style="margin-top: 1em; padding-bottom: 2em;">
    <p style="margin: 0; font-size: 1em;">Loading MFA Settings...</p>
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 1em;"></i>
  </div>
  <SuccessModal @close="close" v-if="displaySuccessModal" :message="successMessage" />
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
export default {
  props: ['user'],
  emits: ['getloggeduser'],
  components: {
    SuccessModal
  },
  data() {
    return {
      loadingMFA: true,
      displayMFAWarning: false,
      displaySuccessModal: false,
      successMessage: "",
      userSettings: {
        mfa_phone_active: false,
        mfa_email_active: false,
      },
      verifyPhone: false,
      verifiedPhone: false,
      sendPhoneCode: false,
      sentPhoneCode: false,
      phoneCode: '',
      phoneCodeError: '',
      newEmail: {
        name: 'email',
        data: '',
        type: 'email'
      },
      newPhone: {
        name: 'phone',
        data: '',
        type: 'phone'
      },
      displayNewPhone: false,
      displayNewEmail: false,
      mailCode: '',
      sentMailCode: false,
      verifyEmail: false,
      verifiedEmail: false,
      mailCodeError: '',
      selectedPhoneCountry: { "name": "Australia", "code": "AU", "callingcode": "+61" }
    }
  },
  methods: {
    close() {
      this.displaySuccessModal = false
    },
    getMFA() {
      axios.post('/get-login-settings', {}, this.$store.state.header).then(res => {
        this.userSettings = res.data;
        this.loadingMFA = false
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    updateMFAstatus() {
      console.log('this is the email and phone statuses', this.userSettings.mfa_email_active, this.userSettings.mfa_phone_active);
      axios.post('/update-login-settings',
        {
          mfa_phone_active: this.userSettings.mfa_phone_active,
          mfa_email_active: this.userSettings.mfa_email_active
        },
        this.$store.state.header).then(res => {
          console.log('updated statuses', res.data);
        }).catch(err => {
          this.mailCodeError = err.response.data;
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })

    },
    saveMFA(type, item, check) {
      console.log(type)
      console.log(item);
      axios.post('/update-login-settings',
        {
          mfa_phone_active: this.userSettings.mfa_phone_active,
          mfa_email_active: this.userSettings.mfa_email_active
        },
        this.$store.state.header).then(() => {
          if (type == 'phone' && this.userSettings.mfa_phone_active) {
            this.updatePhoneMFA(item)
          } else if (type == 'email' && this.userSettings.mfa_email_active) {
            this.updateEmailMFA(item)
          }
        }).then(() => {
          if (check) {
            this.checkMFA(type);
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
    },
    updatePhoneMFA(item) {
      this.verifyPhone = false
      // check if coming from toggle
      if (item == 'toggle') {
        // first MFA attempt
        if (this.userSettings.mfa_phone_number == null) {
          item = this.user.contact_methods.find(singlecontact => singlecontact.type == "phone")
        } else {
          // Prior MFA saved details
          item = this.userSettings.mfa_phone_number
        }
      }
      axios.post('/add-2fa-phone', { mfa_phone_id: item.uuid }, this.$store.state.header).then(res => {
        console.log('updated phone', res);
        this.userSettings.mfa_phone_number = res.data.mfa_phone_number
        this.verifyPhone = true
      }).catch(err => {
        console.log('err issss', err.response.data);

        if (err.response && err.response.data && err.response.data.message.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        this.displaySuccessModal = true;
        this.successMessage = "Failed to add MFA phone number. " + err.response.data;
        console.log('err is', err.response.data, err.response.data);
      })
    },
    updateEmailMFA(item) {
      this.verifyEmail = false
      // check if coming from toggle
      if (item == 'toggle') {
        // first MFA attempt
        if (this.userSettings.mfa_email == null) {
          item = this.user.contact_methods.find(singlecontact => singlecontact.type == "email")
        } else {
          // Prior MFA saved details
          item = this.userSettings.mfa_email
        }
      }
      axios.post('/add-2fa-email', { mfa_email_id: item.uuid }, this.$store.state.header).then(res => {
        console.log('updated email', res);
        this.verifyEmail = true
        this.userSettings.mfa_email = res.data.mfa_email
      }).catch(err => {
        console.log('err issss', err.response.data);

        if (err.response && err.response.data && err.response.data.message.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        this.displaySuccessModal = true;
        this.successMessage = "Failed to add MFA email address. " + err.response.data.errors;
        console.log('err is', err.response.data, err.response.data);
      })
    },
    addPhoneToUser() {
      // no landline
      const phonename = "phone"
      const fullphonenumber = this.selectedPhoneCountry.callingcode.concat(this.newPhone.data)
      this.newPhone.name = phonename
      this.newPhone.data = fullphonenumber
      this.newPhone.country_code = this.selectedPhoneCountry.code
      this.newPhone.type = 'phone'
      axios.post(`/person/${this.user.uuid}/contact-method`, { contact_methods: [this.newPhone] }, this.$store.state.header).then(res => {
        console.log('added phone to user', res.data)
        this.newPhone = {
          name: 'phone',
          data: '',
          type: 'phone'
        }

        this.$emit('getloggeduser', {})

      }).catch(err => {
        console.log('err issss', typeof err.response.data);

        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        this.displaySuccessModal = true;
        this.successMessage = "Failed to add phone number. " + err.response.data;
        console.log('err is', err.response.data, err.response.data);
      })
    },
    sendMFAEmailCode() {
      axios.post('/send-mfa-email', {}, this.$store.state.header).then(() => {
        this.sentMailCode = true;
      }).catch(err => {
        this.mailCodeError = err.response.data && err.response.data.error && err.response.data.error.settings && err.response.data.error.settings.length ? err.response.data.error.settings[0] : err.response.data.error;
        this.sendMailCode = false;
        this.displaySuccessModal = true
        this.successMessage = err.response.data
      })
    },
    sendMFAPhoneCode() {
      axios.post('/send-mfa-phone', {}, this.$store.state.header).then(() => {
        console.log("mfa check sent")
        this.sentPhoneCode = true;
      }).catch(err => {
        this.phoneCodeError = err.response.data && err.response.data.error && err.response.data.error.settings && err.response.data.error.settings.length ? err.response.data.error.settings[0] : err.response.data.error;
        this.sendPhoneCode = false;
        this.displaySuccessModal = true
        this.successMessage = err.response.data
      })
    },
    checkMFACode(type) {
      axios.post('/check-mfa', {
        'type': type,
        code: type == 'phone' ? this.phoneCode : this.mailCode,
        verification: true
      }, this.$store.state.header).then(() => {
        console.log('checked the MFA');
        if (type == 'phone') {
          this.verifiedPhone = true;
        } else {
          this.verifiedEmail = true;
        }
        this.mailCode = '';
        this.phoneCode = '';
        this.sentPhoneCode = false;
        this.sentMailCode = false;
        this.mailCodeError = '';
      }).catch(err => {
        this.mailCodeError = err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    addEmailToUser() {
      axios.post(`/person/${this.user.uuid}/contact-method`, { contact_methods: [this.newEmail] }, this.$store.state.header).then(res => {
        console.log('added email to user', res.data)
        this.newEmail = {
          name: 'email',
          data: '',
          type: 'email'
        }
        this.$emit('getloggeduser', {})

      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        this.displaySuccessModal = true;
        this.successMessage = "Failed to add phone number. " + err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
  },
  mounted() {
    console.log("mounted")
    this.getMFA();
  },
  computed: {
  }
}
</script>

<style lang="scss">@import "../../assets/css/main.scss";</style>
