<template>
    <div style="display: grid; width: 30em;">
            <div class="right-form-section-panel" >
                <!-- SELECT DROPDOWN SUPPLIER -->
                <!-- SELECT DROPDOWN SUPPLIER -->
                <!-- SELECT DROPDOWN SUPPLIER -->
                <div style="display: grid; align-items: left; justify-content: flex-start; margin-bottom: 5px; margin-top: 2em;">
                    <label for="contact_name"><h4 style="margin: 0; margin-bottom: 10px; text-align: left;">1. Check if supplier exists:</h4></label>
                    <Dropdown v-model="searchedSupplier" placeholder="Enter supplier name" ref="supplier" @change="locationSelect()" @keyup="searchSupplier()" 
                    :loading="loadingSupplier" :options="refinedLocations" optionLabel="name" :editable="true"
                    style="width: 20em; display: flex"/>
                </div>
                <h4 style="text-align: left; margin-top: 10px; margin-top: 1em;">2. If no matching supplier found, enter details below:</h4>
                <div class="form-field" style="margin-right: 0">
                    <label for="contact_name">Supplier Name <span style="color: red;">*</span></label>
                    <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="form.submit.name" placeholder="Enter Supplier Name">
                </div>
                <!-- <div class="right-form-section-drug-entry">
                    <input v-model="form.submit.name" placeholder="Enter name"
                        style="position: relative; width: 100%;" />
                </div> -->

<!--                 
                <div class="right-form-section-drug-entry">
                    <div class="form-field text-field"
                        style="width: 100%; margin-top: 0; margin-right: 0px;">
                        <vue-google-autocomplete id="contactmap" classname="form-control"
                            :placeholder="registerCompanyAddress.address ? registerCompanyAddress.address.street_address + ', ' + registerCompanyAddress.address.suburb + ', ' + registerCompanyAddress.address.country : 'Enter Address'"
                            v-on:placechanged="getPersonalAddressData"
                            :country="$store.state.country">
                        </vue-google-autocomplete>
                    </div>
                </div> -->

                <div class="form-field" style="margin-top: 10px; margin-right: 0"
                    v-if="$store.state.user.role.permissions.includes('can_do_admin_company')">
                    <label for="contact_name">Address<span style="color: red;">*</span></label>
                    <vue-google-autocomplete id="contactmap" classname="form-control"
                        :placeholder="registerCompanyAddress.address && registerCompanyAddress.address.street_address  ? registerCompanyAddress.address.street_address + ', ' + registerCompanyAddress.address.suburb + ', ' + registerCompanyAddress.address.country : 'Enter Address'"
                        v-on:placechanged="getPersonalAddressData" :country="$store.state.country">
                    </vue-google-autocomplete>
                </div>
            </div>
            <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px; margin-top: 10px;">
                <slot name="footer" style="display: flex;">
                    <p class="regular-button" style="color: white; margin-right: 10px;" @click="close(true)">Cancel
                    </p>
                    <p class="regular-button" :class="form.submit.name && registerCompanyAddress.street_address ? 'complete-button':'grey-button'" 
                    :style="form.submit.name && registerCompanyAddress.street_address ? {cursor:'pointer'}:{cursor:'inherit'}"
                    @click=" form.submit.name && registerCompanyAddress.street_address ? addCompany() : ''">
                        Add Supplier</p>
                </slot>
            </div>
    <SuccessModal @close="close" v-if="displaySuccessModal"
    :message="successMessage" :first_spec_line="first_spec_line" :loading="addingSupplier" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            displaySuccessModal: false,
            addingSupplier: false,
            loadingSupplier: false,
            searchedSupplier:'',
            refinedLocations: [],
            location: '',
            companyTypes: [
                'Supplier',
                'Pharmacy',
                'Hospital'
            ],
            form: {
                submit: {
                    type: '',
                    name: '',
                },
            },
            registerCompanyAddress: {
                address:{
                    street_address: '',
                    country: '',
                    postcode: '',
                    suburb: '',
                    city: ''
                }
            }
        }
    },
    methods: {
        close(value){
            if(value){
                this.displaySuccessModal = false;
                this.registerCompanyAddress.address = {
                    street_address: '',
                    country: '',
                    postcode: '',
                    suburb: '',
                    city: ''
                }
                this.form = {
                    submit: {
                        type: '',
                        name: '',
                    },
                }
            }
        },
        addCompany() {
            this.displaySuccessModal = true;
            this.addingSupplier = true;
            this.first_spec_line = 'Adding new supplier...'
            console.log('this is the form to submit', this.form);
            this.form.submit.type = 'supplier';
            axios.post('/create-company', this.form.submit, this.$store.state.header).then(res => {
                console.log('this was the transfer action response', res.data);
                this.$store.dispatch('getRefreshToken');
                return res.data.uuid;
            }).then(id => {
                this.setCompanyAddress(id);

            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        changeCompanyType() {
            this.form.submit.type = this.companyTypes[document.getElementById('company-select').value];
        },
        setCompanyAddress(id) {
            if (this.registerCompanyAddress.street_address) {
                axios.post(`/company/${id}/address`, this.registerCompanyAddress, this.$store.state.header).then(res => {
                    console.log('company address submitted.', res);
                    this.loadingCompanycurr += 1;
                    this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
                    if (this.loadingCompanytot == this.loadingCompanycurr) {
                        this.successMessage = 'Edit Completed.'
                    }
                    this.displaySuccessModal = true;
                    this.addingSupplier = false;
                    this.successMessage = 'Successfully added new supplier!';
                    this.first_spec_line = `${this.form.submit.name} added.`
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expire')) {
                        this.$router.push('/login');
                    }
                    this.displaySuccessModal = true;
                    this.addingSupplier = false;
                    this.successMessage = err;
                    console.log('this is the stocktake error', err.response.data);
                })
            } else {
                console.log('no address has been added or submitted');
            }
        },
        showSuppliers(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingSupplier && this.refinedLocations.length) {
                    clearInterval(interval);
                    this.$refs.supplier.show();
                }
            }, 100)
        },
        hideSuppliers(){
            this.$refs.supplier.hide();
        },
        searchSupplier(){
            this.hideSuppliers()
            if (this.searchedSupplier) {
                this.loadingSupplier = true;
                axios.post('/get-companies', { name: this.searchedSupplier, type: 'Supplier' }, this.$store.state.header).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedLocations = res.data;
                    if(this.refinedLocations.length){
                        this.$refs.supplier.show();
                    }
                    this.loadingSupplier = false;
                    this.showSuppliers()
                });
                if (this.refinedLocations.length == 1) {
                    this.form.submit.supplier = this.refinedLocations[0].uuid;
                    this.supplier = this.refinedLocations[0].name;
                }
            }
        },
        locationSelect() {
            this.supplier = this.searchedSupplier.name;
        },
        getPersonalAddressData(addressData, propertyData, id) {

            this.registerCompanyAddress.state = addressData.administrative_area_level_1;
            this.registerCompanyAddress.city = addressData.administrative_area_level_2;
            this.registerCompanyAddress.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.registerCompanyAddress.country = addressData.country;
            this.registerCompanyAddress.postcode = addressData.postal_code;
            this.registerCompanyAddress.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.registerCompanyAddress);
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>