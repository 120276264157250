<template>
  <div class="view drug-register-view" style="display: grid; grid-template-columns: 1fr 8fr;">
    <div class="actions-panel panel"
      style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="toggleNewTransferModal('Receive')" class="actions-menu-item">Transfer In</button>
      <button @click="toggleNewTransferModal('Send')" class="actions-menu-item">Transfer Out</button>
    </div>
    <div class="panel">
      <div class="stock-view">
        <div class="stock-table-container">
          <h3 class="panel-header" style="padding-left: 20px;">Transfers</h3>
          <div class="stock-table-wrapper" v-if="$store.state.user.role.permissions.includes('can_view_transfers')">
            <div v-if="loadingTransfer">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <table v-else class="stock-table">
              <tr>
                <th style="width: 60px; text-align: left;">Type</th>
                <th style="width: 5em; text-align: left">Date</th>
                <th style="text-align: left">Medication</th>
                <th style="width: 3em">Quantity</th>
              </tr>
              <tr v-for="(item, index) in transferData" :key="index">
                <td height="40px" style="width: 60px;">
                  <p v-if="item.in > 0" class="blue-button"
                    style="cursor: default; width: 45px; padding: 2px; text-align: center; font-weight: bold">IN</p>

                  <p v-else class="blue-button"
                    style="cursor: default; width: 45px; padding: 2px; text-align: center; font-weight: bold; background-color: #9F5CC0 !important">
                    OUT</p>
                </td>
                <td style="width: 10em;">{{ new Date(item.time).toDateString() }}</td>
                <!-- <td>{{ item.username }}</td> -->
                <td>{{ item.drug }}</td>
                <td>{{ item.in > 0 ? item.in : item.out }} {{ item.form }}{{ item.qty == 1 ? '' : 's' }}</td>
              </tr>
            </table>
            <router-link to="/stock/transfers" style="text-decoration: none">
              <p style="font-weight: bold; color: #0870DC; font-size: 15px;">Click here to see more</p>
            </router-link>
          </div>
          <div v-else class="stock-table-wrapper" style="padding: 5em;">
            <h4
              style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
              You do not have permission to access this feature. Please talk to your admin if you require access.</h4>
          </div>
        </div>
        <div class="stock-table-container">
          <h3 class="panel-header" style="padding-left: 20px;">Destructions</h3>
          <div class="stock-table-wrapper" v-if="$store.state.user.role.permissions.includes('can_view_destruction')">
            <div v-if="loadingDestroy">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <table v-else class="stock-table">
              <tr>
                <th style="width: 10em">Date</th>
                <th>Medication</th>
                <th style="width: 3em">Quantity</th>
              </tr>
              <tr v-for="(item, index) in destructionData" :key="index">
                <td style="width: 10em;">{{ new Date(item.time).toDateString() }}</td>
                <!-- <td>{{ item.username }}</td> -->
                <td>{{ item.drug }}</td>
                <td>{{ item.out }} {{ item.form }}{{ item.out == 1 ? '' : 's' }}</td>
              </tr>
            </table>
            <router-link to="/stock/destructions" style="text-decoration: none">
              <p style="font-weight: bold; color: #0870DC; font-size: 15px;">Click here to see more</p>
            </router-link>
          </div>
          <div v-else class="stock-table-wrapper" style="padding: 5em;">
            <h4
              style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
              You do not have permission to access this feature. Please talk to your admin if you require access.</h4>
          </div>
        </div>
        <div class="stock-table-container">
          <h3 class="panel-header" style="padding-left: 20px;">Deliveries</h3>
          <div class="stock-table-wrapper" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')">
            <div v-if="loadingDelivery">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <table v-else class="stock-table">
              <tr>
                <th style="width: 10em">Date</th>
                <th>Medication</th>
                <th style="width: 3em">Quantity</th>
              </tr>
              <tr v-for="(item, index) in deliveryData" :key="index">
                <td style="width: 10em;">{{ new Date(item.time).toDateString() }}</td>
                <!-- <td>{{ item.username }}</td> -->
                <td>{{ item.drug }}</td>
                <td>{{ item.in }} {{ item.form }}{{ item.in == 1 ? '' : 's' }}</td>
              </tr>
            </table>
            <router-link to="/stock/deliveries" style="text-decoration: none">
              <p style="font-weight: bold; color: #0870DC; font-size: 15px;">Click here to see more</p>
            </router-link>
          </div>
          <div v-else class="stock-table-wrapper" style="padding: 5em;">
            <h4
              style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
              You do not have permission to access this feature. Please talk to your admin if you require access.</h4>
          </div>
        </div>
        <div class="stock-table-container">
          <h3 class="panel-header" style="padding-left: 20px;">Outgoing</h3>
          <div class="stock-table-wrapper">
            <div v-if="loadingOutgoing">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
            </div>
            <table v-else class="stock-table">
              <tr>
                <th style="width: 10em">Date</th>
                <th>Medication</th>
                <th style="width: 3em">Quantity</th>
              </tr>
              <tr v-for="(item, index) in outgoingData" :key="index">
                <td style="width: 10em;">{{ new Date(item.time).toDateString() }}</td>
                <!-- <td>{{ item.username }}</td> -->
                <td>{{ item.drug }}</td>
                <td>{{ item.in > 0 ? item.in : item.out }} {{ item.form }}{{ item.qty == 1 ? '' : 's' }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <NewTransferModal v-if="displayNewTransferModal" @close="close" :transferType="transferType" />
  </div>
</template>


<script>
// @ is an alias to /src
import NewTransferModal from '@/components/modals/NewTransferModal.vue';
import axios from 'axios';

export default {
  components: {
    NewTransferModal
  },
  data() {
    return {
      displayNewTransferModal: false,
      transferType: '',
      loadingTransfer: false,
      loadingDestroy: false,
      loadingDelivery: false,
      loadingOutgoing: false,
      transferData: [
      ],
      destructionData: [
      ],
      deliveryData: [],
      outgoingData: []
    }
  },
  methods: {
    close(value) {
      if (value) {
        this.displayNewTransferModal = false;
      }
    },
    toggleNewTransferModal(type) {
      this.transferType = type;
      this.displayNewTransferModal = true;
    },
    receiveAllLogs() {
      let logTypes = ['destruction', 'receipt'];
      // discard, loss, damage
      let transferTypes = ['transfer in', 'transfer out'];
      let outTypes = ['discard', 'loss', 'damage'];
      this.loadingTransfer = true;
      this.loadingDestroy = true;
      this.loadingDelivery = true;
      this.loadingOutgoing = true;
      logTypes.forEach(item => {
        axios.post('/stock-logs', { log_table: [item] }, this.$store.state.header).then(res => {
          switch (item) {
            case 'destruction':
              this.destructionData = res.data.logs;
              this.loadingDestroy = false;
              break;
            case 'receipt':
              this.deliveryData = res.data.logs;
              this.loadingDelivery = false;
              break;
          }
        })
      })
      axios.post('/stock-logs', { log_table: transferTypes }, this.$store.state.header).then(res => {
        this.transferData = res.data.logs;
        this.loadingTransfer = false;
      });
      axios.post('/stock-logs', { log_table: outTypes }, this.$store.state.header).then(res => {
        this.outgoingData = res.data.logs;
        this.loadingOutgoing = false;
      });

    }

  },
  mounted() {
    this.receiveAllLogs();
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.stock-view {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(300px, 41.6vw)) !important;
  grid-gap: 20px;
}

.stock-table {
  background-color: white;
  border-radius: 8px;
  border-collapse: collapse;
  padding-left: 10px;

  td {
    text-align: left;
    border-bottom: 2px solid #F3F4F4; //$grey;
  }
  th{
    font-weight: 500;
  }
}

.stock-table-wrapper {
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  padding-top: 5px;
}
</style>
