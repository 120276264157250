<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    
                    <slot name="header">
                        <h3 class="form-header">Create new channel notification </h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <h4>Create a new notification channel. This will require a developer to enable its functionality post creation.</h4>
                                <div v-if="loading">
                                    <i class="pi pi-spin pi-spinner"
                                        style="font-size: 2rem !important;margin: 2em;"></i>
                                </div>
                                

                                    <div v-else style="display:grid; grid-template-columns: 70% 30%;width: 50%;justify-self: center;align-items: center;">
                                    <input id="channelName" ref="channelName" v-model="channelName" type="text" placeholder="Channel Name">
                                    <div class="flex align-items-center">
                                        <Checkbox v-model="isGlobal" :binary="true" name="global"/>
                                        <label for="ingredient1" class="ml-2"> is Global? </label>
                                    </div>  
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: grid; justify-content: end; grid-gap: 15px;grid-template-columns: auto auto;padding: 5px 20px;"
                    v-if="!loading">
                    <slot name="footer">
                        <div v-if="msg">
                                        <p style="font-size: 1em;">{{ msg }}</p>
                        </div>
                        <button class="blue-button"  @click="createChannelAction()">
                            Create channel
                        </button>
                    </slot>
                </div>
                
            </div>

        </div>
        
    </div>
</template>

<script>
import axios from 'axios';
import FirebaseService from "../../services/FirebaseService";

export default {
    
    data() {
        return {
            isGlobal: false,
            loading: false,
            channelName: "",
            msg: ""
        }
    },
    methods: {
        cancelAction() {
            this.$emit('stopAction', true);
            this.$emit('close', true);
        },
        isSuccessful(value) {
            if (value) {
                
                this.closeModal();
            }
        },
        
        createChannelAction(){
            this.loading = true;
            console.log('channel Name: ' + this.channelName);
            console.log('global: ' + this.isGlobal);
            const vertical = "hospital";

            const data = {
                "name": this.channelName,
                "vertical": vertical,
                "is_global": this.isGlobal
            }

            axios.post('/create-channel', data, this.$store.state.header).then(res=>{
                this.msg = 'New channel '+ this.channelName +' has been created'
                this.reloadPageOnClose == true
                
            }).catch(err=>{
                console.log('There is the error creating the channel', err.response.data);
                this.msg = 'There was an error creating the channel';
            })
            this.loading = false;
        },
        closeModal() {
            console.log('hitting this closemodal function');
            if (this.reloadPageOnClose == true) {
                this.$emit('close', 'reload');

            } else {
                this.$emit('close', true);

            }

        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
