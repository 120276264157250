<template>
    <div style="padding: 23px; padding-left: 0px;" class="print">
        <div v-if="$store.state.user.role.permissions.includes('can_view_prescription')">
            <div class="filters" style="align-items: center; display: flex; justify-content: space-between;">
                <button @click="displayAdministrationModal = true,requisitionDrug = null" class="blue-button"><p style="margin: 0">Administer</p></button>
                <SelectButton v-model="selected_view" :options="history_admin" @change="getRelevantData()" aria-labelledby="single" />
                
                <!-- <div class="date-search-container search-container" style="display: flex; align-items: center;">
                    <Calendar v-if="selected_view == 'Admin History'" v-model="adminHistoryFormDate" selectionMode="range" placeholder="Date Range..." dateFormat="dd/mm/yy" />
                </div> -->
                <!-- <button v-tooltip.bottom="'Reset Filters'" v-if="selected_view == 'Admin History'" class="black-button" @click="clearFilters()">
                    Clear
                </button> -->
                <!-- <button v-tooltip.bottom="'Download'" class="button clear-button"
                    @click="displayExportCSVModal = true, loadReport()"
                    style="background-color: transparent; color: white; margin-top: 0px;"><i
                        class="pi pi-download"></i></button> -->
                <!-- <button v-tooltip="'Print'" class="button clear-button" @click="printElem()"
                    style="margin-right: 1em; background-color: transparent; color: white" v-if="selected_view =='Admin History'">
                    <i class="pi pi-print" style="font-size: 1.1em !important; border-radius: 50%; padding: 8px; background-color: #0870DC; margin-top: -4px;"></i>
                </button> -->
            </div>
            <div v-if="selected_view == 'Stock Register'">
                <div style="padding-top: 10px">
                    <div v-if="loading">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    </div>
                    <table v-else class="medication-history-table">
                        <tr>
                            <th>Medication</th>
                            <th>Current Stock</th>
                            <th></th>
                        </tr>
                        <!-- @click="displayExpiredChartModal = true; displayChart = item" -->
                        <tr v-for="(item, index) in stock_history" :key="index" >
                            <td>{{ item.alias.name }}</td>
                            <td>{{ item.quantity }}</td>
                            <td><button class="green-button" @click="displayRequisitionModal=true, requisitionDrug = item.alias.name">Re-Supply</button></td>
                        </tr>
                    </table>
                </div>
                <div class="pagination-links">
                    <p class="pagination-arrow" v-show="pages.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
                    <p>Page {{ pages.current_page }} of {{ pages.page_count }}</p>
                    <p class="pagination-arrow" v-show="pages.current_page < pages.page_count"
                        @click="displayPage(pages.current_page + 1)"> &rarr; </p>
                </div>
            </div>
            <div v-if="selected_view =='Admin History'" class="print">
                <div style="padding-top: 10px">
                    <div v-if="loading">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    </div>
                    <div v-else>
                        <div v-if="!administrations.length">
                            <h3>No administrations found.</h3>
                        </div>
                        <table v-else class="medication-history-table">
                            <tr>
                                <th>Date & Time</th>
                                <th>Drug</th>
                                <th>Quantity</th>
                                <th>Location</th>
                                <th>Administrator</th>
                                <th>Notes</th>
                                <th></th>
                            </tr>
                            <tr v-for="(item, index) in administrations" :key="index">
                                <td>{{ item.administered_at}}</td>
                                <td>{{ item.drug.name }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.location.name }}</td>
                                <td>{{item.administrator && item.administrator.personal_information && item.administrator.personal_information.first_name ? item.administrator.personal_information.first_name : ''}} 
                                    {{item.administrator && item.administrator.personal_information && item.administrator.personal_information.last_name ? item.administrator.personal_information.last_name : ''}}</td>
                                <td><p class="blue-button" v-if="item.note" 
                                style="padding: 5px 0px;
                                    width: fit-content;
                                    display: flex;
                                    align-items: center;" @click="modalNotes = item.note, displayNotesModal = true" ><i class="pi pi-align-justify" ></i></p></td>
                                <td>
                                    <button class="green-button" v-if="(item.quantity < item.quantity_left) && item.location.name == currentLocation"
                                    @click="displayAdministrationModal = true, administeredDrug = item.drug,
                                     administeredDrug.qty_left = item.quantity_left, administeredDrug.quantity = item.quantity,
                                     administeredDrug.quantity = item.quantity,
                                     administeredDrug.stock_id = item.stock_id,
                                      administerPrescriber = item.prescriber">
                                    Re-administer</button>
                                    <button v-if="item.quantity > item.quantity_left" class="grey-button" style="height: fit-content">Insufficient Stock</button>
                                
                                </td>
                                
                            </tr>
                        </table>
                    </div>
                    
                </div>
                <div class="pagination-links" v-if="administrations.length && !this.loading">
                    <p class="pagination-arrow" v-show="pages.current_page > 1" @click="displayAdminPage(adminHistoryForm.page - 1)"> &larr; </p>
                    <p>Page {{ pages.current_page }} of {{ pages.page_count }}</p>
                    <p class="pagination-arrow" v-show="pages.current_page < pages.page_count"
                        @click="displayAdminPage(pages.current_page + 1)"> &rarr; </p>
                </div>
            </div>
            
            <ExpiredChartModal :chart="displayChart" v-if="displayExpiredChartModal" @close="close" :frequencyOptions="frequencyOptions" />
            <AdminHistoryNotesModal v-if="displayNotesModal" :notes="modalNotes" @close="close" />
            <RequisitionModal v-if="displayRequisitionModal" @close="close" :propPatient="patient" :propDrug="requisitionDrug" />
            <AdministrationModal v-if="displayAdministrationModal" @close="close" @refreshAdminHistory="refreshAdminHistory" :propPatient="patient"
             :propDrug="administeredDrug" :propPrescriber="administerPrescriber" />
        </div>
        <div v-else style="margin-top: 10em;">
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                You do not have permission to access this feature. Please talk to your admin if you require access.
            </h3>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ExpiredChartModal from '@/components/modals/ExpiredChartModal.vue';
import RequisitionModal from '@/components/modals/RequisitionModal.vue';
import AdministrationModal from '@/components/modals/AdministrationModal.vue';
import AdminHistoryNotesModal from '@/components/modals/AdminHistoryNotesModal.vue';

export default {
    props: ['patient', 'frequencyOptions'],
    components: {
        ExpiredChartModal,
        AdminHistoryNotesModal,
        AdministrationModal,
        RequisitionModal
    },
    data() {
        return {
            currentLocation: JSON.parse(localStorage.getItem('currentLocation')).name,
            displayNotesModal: false,
            displayRequisitionModal: false,
            displayAdministrationModal: false,
            modalNotes: '',
            selected_view:'Admin History',
            history_admin:[
                'Admin History',
                'Stock Register',
            ],
            displayExpiredChartModal: false,
            displayChart: '',
            loading: false,
            typeOptions: [],
            charts: [],
            drugs: [],
            frequencies: [],
            types: [],
            routes: [],
            date: [],
            adminHistoryFormDate:[],
            administrations:[],
            pages: {
                current_page: 1,
                page_count: 1,
            },
            form: {
                page: 1,
                filters: {
                    drug: '',
                    type: '',
                    route: '',
                    date: {
                        start: '',
                        end: ''
                    }
                }
            },
            adminHistoryForm:{
                filters:{
                    drug:'',
                    date:{
                        start:'',
                        end:''
                    },
                    page:1
                }
            },
            stock_history:[],
            requisitionDrug: '',
            administeredDrug: '',
            administerPrescriber: ''
        }
    },
    methods: {
        close(value) {
            if (value) {
                this.displayExpiredChartModal = false;
                this.displayNotesModal = false;
                this.displayRequisitionModal = false;
                this.displayAdministrationModal = false;
                this.administeredDrug = '';
                this.administerPrescriber = '';

            }
        },
        refreshAdminHistory(value){
            if(value){
                this.retrieveMedicationHistory();
            }
        },
        printElem() {
            
            // window.print();
            // let elementToPrint = document.getElementById('medication-history-table');
            // let cloned = elementToPrint.cloneNode(true);
            // document.body.appendChild(cloned);
            // cloned.classList.add("printable");
            // console.log('this is the eleme', cloned);
            // window.print();
            // document.body.removeChild(cloned);
            var mywindow = window.open('', 'PRINT', 'height=600,width=1000');

            mywindow.document.write('<html><head><title>' + 'ADMINISTRATION HISTORY' + '</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write('<table class="medication-history-table">');
            mywindow.document.write('<tr>');
            mywindow.document.write('<th style="width: 10em"> Date & Time </th>');
            mywindow.document.write('<th style="width: 20em"> Drug </th>');
            mywindow.document.write('<th> Quantity </th>');
            mywindow.document.write('<th> Administered</th>');
            mywindow.document.write('<th style="width: 5em"> DOC </th>');
            mywindow.document.write('<th > Administrator </th>');
            mywindow.document.write('</tr>');
            this.administrations.forEach(admin=>{
                mywindow.document.write('<tr>');
                mywindow.document.write('<td>'+admin.administered_at+'</td>');
                mywindow.document.write('<td>'+admin.drug.name+'</td>');
                mywindow.document.write('<td>'+admin.quantity+'</td>');
                if(!admin.doc && admin.administrator){
                    mywindow.document.write('<td>'+'Y'+'</td>');
                } else{
                    mywindow.document.write('<td>'+'N'+'</td>');
                }
                if(admin.doc){
                    mywindow.document.write('<td style="width: 5em; text-align: center">'+admin.doc+'</td>');
                } else{
                    mywindow.document.write('<td style="width: 5em; text-align: center">'+'--'+'</td>');
                }
                if(admin.administrator && admin.administrator.personal_information && admin.administrator.personal_information.first_name){
                    mywindow.document.write('<td>'+admin.administrator.personal_information.first_name+ ' '+admin.administrator.personal_information.last_name+'</td>')
                } else{
                    mywindow.document.write('<td>'+'N/A'+'</td>');
                }
                // mywindow.document.write('<td>'+admin.administrator && admin.administrator.personal_information && admin.administrator.personal_information.first_name ? admin.administrator.personal_information.first_name + ' '+admin.administrator.personal_information.last_name : 'N/A' +'</td>');
                mywindow.document.write('</tr>');
            })
            mywindow.document.write('</table>');
            // mywindow.document.write(elementToPrint);
            // <table v-else id="medication-history-table">
            //     <tr>
            //         <th>Date & Time</th>
            //         <th>Drug</th>
            //         <th>Quantity</th>
            //         <th>Administered</th>
            //         <th>DOC</th>
            //         <th>Administrator</th>
            //     </tr>
            //     <tr v-for="(item, index) in administrations" :key="index">
            //         <td>{{ item.administered_at}}</td>
            //         <td>{{ item.drug.name }}</td>
            //         <td>{{ item.quantity }}</td>
            //         <td>{{ !item.doc && item.administrator ? 'Y' : 'N'}}</td>
            //         <td>{{ item.doc ? item.doc : '-' }}</td>
            //         <td>{{item.administrator && item.administrator.personal_information && item.administrator.personal_information.first_name ? item.administrator.personal_information.first_name : ''}} 
            //             {{item.administrator && item.administrator.personal_information && item.administrator.personal_information.last_name ? item.administrator.personal_information.last_name : ''}}</td>
            //     </tr>
            // </table>
            mywindow.document.write('</body></html>');

            // mywindow.document.close(); // necessary for IE >= 10
            // mywindow.focus(); // necessary for IE >= 10*/

            // window.print();
            // mywindow.close();

            // return true;
        },
        getRelevantData(){
            if(this.selected_view == 'Admin History'){
                this.retrieveMedicationHistory();
            } else{
                this.retrieveStockHistory();
            }
        },
        clearFilters() {
            this.date= []
            this.form = {
                page: 1,
                filters: {
                    drug: '',
                    type: '',
                    route: '',
                    date: {
                        start: '',
                        end: ''
                    }
                }
            }
            this.adminHistoryFormDate= '';

            this.adminHistoryForm = {
                drug:'',
                date:{
                    start:'',
                    end:''
                },
                page:1
            }
            if(this.selected_view == 'Admin History'){
                this.retrieveMedicationHistory();
            } else{
                this.retrieveMedicationHistory();
            }
            
        },
        displayPage(page) {
            this.$store.dispatch('getRefreshToken');
            this.form.page = page;
            this.retrieveMedicationHistory();
        },
        displayAdminPage(page) {
            this.$store.dispatch('getRefreshToken');
            this.adminHistoryForm.page = page;
            this.retrieveMedicationHistory();
        },
        sortMedicationCharts() {
            this.$store.dispatch('getRefreshToken');
            if (this.date.length) {
                if (typeof this.date == "string") {
                    this.form.filters.date.start = this.date.split("-")[0]
                    this.form.filters.date.end = this.date.split("-")[1]
                } else {
                    
                    this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
                    this.form.filters.date.end = (this.date[1] == null) ? new Date(this.date[0]).toLocaleDateString('en-AU') : new Date(this.date[1]).toLocaleDateString('en-AU');
                }
            } else {
                this.form.filters.date.start = '';
                this.form.filters.date.end = '';
            }
            console.log('this is the filter', this.form.filters);
            this.retrieveMedicationHistory();
        },
        retrieveStockHistory(){
            this.$store.dispatch('getRefreshToken');
            this.administrations = [];
            this.loading = true;
            axios.post(`/person/${this.$route.params.id}/stock-register`,{}, this.$store.state.header).then(res=>{
                console.log('this is the stock register', res.data);
                this.stock_history = res.data;
                this.loading = false;
            }).catch(err=>{
                this.loading = false;
                console.log('this is the error message', err.response);
            })
        },
        retrieveMedicationHistory() {
            this.$store.dispatch('getRefreshToken');
            this.loading = true;
            axios.post(`/person/${this.$route.params.id}/hospital-administrations`, this.adminHistoryForm, this.$store.state.header).then(res=>{
                this.administrations = res.data.data;
                this.administrations.forEach(administration=>{
                    administration.quantity = parseFloat(administration.quantity);
                    administration.quantity_left = parseFloat(administration.quantity_left);
                })
                this.loading = false;
        //         this.administrations = [{
        //     "uuid": "3212d828-06cf-46ec-bb10-453092a0af0a",
        //     "quantity": "1.00",
        //     "administrator": {
        //         "uuid": "1046e5ae-c974-4192-8cce-6b79beab2c37",
        //         "personal_information": {
        //             "firstname": "Sam",
        //             "lastname": "Jones",
        //             "dob": null,
        //             "sex": "N/A",
        //             "preferred_name": "N/A"
        //         },
        //         "created_at": "Nov 18, 2022, 16:57"
        //     },
        //     "administered_at": "Dec 02, 2022, 23:33",
        //     "note": null,
        //     "additional_notes":[
        //         "note string",
        //     ],
        //     "drug":{
        //         alias: {
        //               "alias_id": "f31d231a-db52-3223-9efd-c6e4cba47bd9",
        //               "name": "buprenorphine 20 microgram/hour patch",
        //               "region": "Australia",
        //               "measurement_unit": "microgram/hour",
        //               "form": "patch",
        //               "strength": "20.0000",
        //               "quantity": "2.0000",
        //               "tags": [
        //                   "S8"
        //               ],
        //               "is_generic": true,
        //               "generic_name": "buprenorphine"
        //           },
        //         uuid: '929342-2342-3-232222',
        //         qty_available: 200,
        //       },
        //     "prescriber":{first_name: 'Jacob', last_name: 'Rogers', uuid:'9239423-2342342-3232' },

        // }]
            })

        },
        // GETFILTERVALUES FUNCTION DROPS ALL DUPLICATES IN FILTER ARRAYS
        getFilterValues() {
            if(this.drugs.length){
                this.drugs = this.drugs.filter((v, i, a) => a.indexOf(v) === i);
            }
            if(this.routes.length){
                this.routes = this.routes.filter((v, i, a) => a.indexOf(v) === i);
            }
            if(this.types.length){
                this.types = this.types.filter((v, i, a) => a.indexOf(v) === i);
            }
            if(this.frequencies.length){
                this.frequencies = this.frequencies.filter((v, i, a) => a.indexOf(v) === i);
            }
            
            if(this.types.length){
                let chartTypes = this.$store.getters.getChartTypes;
                this.types.forEach(type => {
                    chartTypes.forEach(chartType => {
                        if (type == chartType.chart_name) {
                            this.typeOptions.push(chartType);
                            console.log('this is the list of TYPES', this.typeOptions);
                        }
                    })
                })
            }
            
        },
    },
    mounted() {
        this.retrieveMedicationHistory();
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

 

 

  



.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.medication-history-table {
    margin: 0;
    padding: 5px;
    border-collapse: collapse;
    background-color: #F3F4F4;
    border-radius: 12px;

    th,
    td {
        padding: 10px 0px 10px 10px !important;
        font-size: 1em;
    }

    ;
    border-collapse: collapse;

    th {
        border-bottom: 2px solid #E7E9E8;
    }

    td {
        border-bottom: 1px solid #E7E9E8;
    }

}

@media print {
  body *:not(.printable) {
    // hide everything but printable elements and their children
    display: none;
  }
}
</style>
