<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%; min-width: 500px">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Add New {{ companyType == 'Supplier' ? 'Supplier' : 'Company' }}</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr">
                                <div class="right-form-section-panel" style="display: grid;"
                                    :style="companyType == 'Supplier' ? '' : 'grid-template-columns: 1fr 1fr'">
                                    <div class="right-form-section-drug-entry">
                                        <input v-model="form.submit.name" placeholder="Enter name"
                                            style="position: relative; margin-right: 10px; width: 100%;" />
                                    </div>
                                    <div class="right-form-section-drug-entry" v-if="companyType != 'Supplier'">
                                        <select style="position: relative; width:100%; padding: 10px; "
                                            id="company-select" @change="changeCompanyType()">
                                            <option value="" disabled selected>Select Company Type</option>
                                            <option :value="index" v-for="(item, index) in companyTypes" :key="index">
                                                {{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="right-form-section-drug-entry" style="margin-right: 10px;">
                                        <div class="form-field text-field"
                                            style="width: 100%; margin-top: 0; margin-right: 0px;">
                                            <vue-google-autocomplete id="contactmap" classname="form-control"
                                                :placeholder="registerCompanyAddress.address ? registerCompanyAddress.address.street_address + ', ' + registerCompanyAddress.address.suburb + ', ' + registerCompanyAddress.address.country : 'Enter Address'"
                                                v-on:placechanged="getPersonalAddressData"
                                                :country="$store.state.country">
                                            </vue-google-autocomplete>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="background-color: green" @click="addCompany()">
                            Add {{ companyType == 'Supplier' ? 'Supplier' : 'Company' }}</p>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['companyType'],
    components: {
    },
    data() {
        return {
            location: '',
            companyTypes: [
                'Supplier',
                'Pharmacy',
                'Hospital'
            ],
            form: {
                submit: {
                    type: '',
                    name: '',
                },
            },
            registerCompanyAddress: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            }
        }
    },
    methods: {
        isSuccessful(value) {
            if (value) {
                this.$emit('closeLocationModal', true);
            }
        },
        responseData(value) {
            if (value) {
                this.$emit('addNewLocationToRefined', {
                    name: value.name,
                    uuid: value.uuid
                });
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('closeLocationModal', true);
        },
        addCompany() {
            console.log('this is the form to submit', this.form);
            if (this.companyType == 'Supplier') {
                this.form.submit.type = 'supplier';
            }
            axios.post('/create-company', this.form.submit, this.$store.state.header).then(res => {
                console.log('this was the transfer action response', res.data);
                this.$store.dispatch('getRefreshToken');
                this.$emit('addNewCompanyToRefined', {
                    name: res.data.name,
                    uuid: res.data.uuid
                });
                return res.data.uuid
            }).then(id => {
                this.setCompanyAddress(id);
                this.$emit('closeLocationModal', true);
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        changeCompanyType() {
            this.form.submit.type = this.companyTypes[document.getElementById('company-select').value];
        },
        setCompanyAddress(id) {
            if (this.registerCompanyAddress.street_address) {
                axios.post(`/company/${id}/address`, this.registerCompanyAddress, this.$store.state.header).then(res => {
                    console.log('company address submitted.', res);
                    this.loadingCompanycurr += 1;
                    this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
                    if (this.loadingCompanytot == this.loadingCompanycurr) {
                        this.successMessage = 'Edit Completed.'
                    }
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expire')) {
                        this.$router.push('/login');
                    }
                    console.log('this is the stocktake error', err.response.data);
                })
            } else {
                console.log('no address has been added or submitted');
            }
        },
        getPersonalAddressData(addressData, propertyData, id) {

            this.registerCompanyAddress.state = addressData.administrative_area_level_1;
            this.registerCompanyAddress.city = addressData.administrative_area_level_2;
            this.registerCompanyAddress.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.registerCompanyAddress.country = addressData.country;
            this.registerCompanyAddress.postcode = addressData.postal_code;
            this.registerCompanyAddress.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.registerCompanyAddress);
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";
</style>
