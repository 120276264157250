<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button class="actions-menu-item" @click="displayThreatreAllocateModal = true">Theatre Checkout</button>
      <!-- <button class="actions-menu-item" @click="displayTheatreCheckinModal = true">Theatre Usage</button> -->
    </div>
    <div class="panel">
      <h3 class="panel-header">Theatre Movement | <span style="color: darkslategray">{{ $store.state.currentLocation.label
      }}</span></h3>
      <div class="drug-register-table table-container" style="padding-top: 10em;"
        v-if='!$store.state.user.role.permissions.includes("can_view_drug_register") || $store.state.reponsiblePersonError'>
        <h3
          style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
          {{ $store.state.reponsiblePersonError ? $store.state.reponsiblePersonErrorMessage : 'You do not have permission to access this feature.Please talk to your admin if you require access.' }}</h3>
      </div>
      <div v-else class="drug-register-table table-container"
        :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
        <div class="filters" style="display: flex;justify-self: start; gap:10px">

          <Dropdown v-model="anesthetistsearch" editable v-on:keyup="anesthetistSearch(), showAnethesists()" :options="anesthetistresults" :loading="anesthetistsearchloading" 
          optionLabel="fullname" placeholder="Search for Anaesthetist..."  ref="anethesist"> </Dropdown>
          <MultiSelect v-model="selectedStatuses" :options="status" @change="statusChanged" optionLabel="label" placeholder="Status" />
          <input v-model="patientsearch" placeholder="Patient..." style="position: relative" />
          <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />

          <button style="margin: 0px;" class="button clear-button" @click="resetfilters()">Clear</button>
          <button style="margin: 0px;" class="button submit-button" @click="search()">Search</button>
        </div>
        <!-- <div class="drug-variances-container" style="display: flex;" v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
          <p style="padding: 5px 10px; margin-right: 10px;">
            Refine your search: 
          </p>
          <p v-for="(item, index) in $store.state.drugEntries.drug_aliases" :key="index" @click="aliasSearch(item)" class="drug-variance-option">{{item}}</p>
          <p style="padding: 5px 10px; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer" @click="aliasSearch('Endone 10mg')" >Endone 10mg</p>
        </div> -->
        <!-- TABLE -->
        <div v-if="!loadingTheatreData" style="overflow-y: auto;">
          <table class="data-table" cellspacing="0">
            <tr class="table-headers">
              <th width="10%">
                <h3 style="padding-left: 10px;">Date</h3>
              </th>
              <th width="20%">
                <h3>Patient</h3>
              </th>
              <th width="20%">
                <h3>Anaesthetist</h3>
              </th>
              <th width="10%">
                <h3 ># of Medications</h3>
              </th>
              <th width="10%">
                <h3 >Status</h3>
              </th>
              <th width="10%">
                <h3></h3>
              </th>
            </tr>
            <tbody class="table-data" v-for="(item, index) in theatreSearchResult" :key="index">
              <tr>
                <td>
                  <div style="margin: 10px;">{{ new Date(item.created_at).toLocaleDateString('en-AU',{hour12: false, year:"numeric", month:"short", day:"numeric", hour:"2-digit", minute:"2-digit"}) }}</div>
                </td>
                <td>{{ item.patient.personal_information.firstname }} {{ item.patient.personal_information.lastname }}
                </td>
                <td>{{ item.anesthetist.personal_information.firstname }} {{
                  item.anesthetist.personal_information.lastname }}</td>
                <td>{{ (item.drugs).length }}</td>
                <td>{{ (item.status.charAt(0).toUpperCase() + item.status.slice(1)) }}</td>
                <td>
                  <div style="display: flex;cursor: pointer; margin: 10px 10px;justify-content: right;"
                    @click="rec(index)">
                    <p style="width: 100%;" class="green-button">{{ item.status == 'open' ? 'Reconcile' : 'View' }} </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
        <div class="pagination-links" v-if="!loadingTheatreData">
          <p class="pagination-arrow" v-show="currentPage > 1"
            @click="displayPage(currentPage - 1)"> &larr; </p>
          <p>Page {{ currentPage }} of {{ pageCount }}</p>
          <p class="pagination-arrow" v-show="currentPage < pageCount"
            @click="displayPage(currentPage + 1)"> &rarr; </p>
        </div>
      </div>
    </div>
  </div>

  <ThreatreAllocateModal v-if="displayThreatreAllocateModal" @close="close">
  </ThreatreAllocateModal>
  <TheatreCheckinModal v-if="displayTheatreCheckinModal" :prefill="theatreCheckinProp"
    @close="close"> </TheatreCheckinModal>
</template>


<script>
// @ is an alias to /src

import axios from 'axios';
import TheatreCheckinModal from '@/components/modals/TheatreCheckinModal.vue'
import ThreatreAllocateModal from '@/components/modals/TheatreAllocateModal.vue'

export default {
  components: {
    TheatreCheckinModal,
    ThreatreAllocateModal
  },
  data() {
    return {
      drugNames: [],
      currentPage: 1,
      pageCount: 0,
      date: [],
      filters: [],
      displayThreatreAllocateModal: false,
      displayTheatreCheckinModal: false,
      theatreCheckinProp: [],
      theatreSearchResult: [],
      status: [{ status: "open", label: "Open" }, { status: "closed", label: "Closed" }],
      selectedStatuses: "",
      statusSubmission: "",
      patientsearch: "",
      anesthetistsearch: "",
      anesthetistresults: "",
      anesthetistsearchloading : false,
      loadingTheatreData: false,
    }
  },
  methods: {
    close(value){
      if(value){
        this.displayTheatreCheckinModal = false;
        this.displayThreatreAllocateModal = false;
        this.search();
      }
    },
    anesthetistSearch() {
      this.anesthetistsearchloading = true
      axios.post('/get-anesthetist', { name: this.anesthetistsearch }, this.$store.state.header).then(res => {
        console.log('this is the res data', res.data);
        this.anesthetistsearchloading = false
        res.data.map((anesthestist) => anesthestist.fullname = anesthestist.firstname + (anesthestist.lastname ? " " + anesthestist.lastname : ""))
        this.anesthetistresults = res.data
      }).catch(err => {
        console.log('this is the err', err);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })
    },
    showAnethesists(){
      console.log('HITING SAVE FUNCTION');
      let interval = setInterval(() => {
          console.log('HITTING INTERVAL');
          if (!this.anesthetistsearchloading && this.anesthetistresults) {
              clearInterval(interval);
              this.$refs.anethesist.show();
          }
      }, 100)
    },
    rec(index) {
      this.theatreCheckinProp = this.theatreSearchResult[index]
      this.displayTheatreCheckinModal = true
    },
    statusChanged() {
      this.statusSubmission = this.selectedStatuses.length == 2 ? "" : this.selectedStatuses[0].status
      this.search()
    },
    resetfilters() {
      this.selectedStatuses = "";
      this.statusSubmission = "";
      this.patientsearch = "";
      this.anesthetistsearch = "";
      this.anesthetistresults = ""
      this.date = []
      this.search()
    },
    search() {
      this.loadingTheatreData = true;
      let payload = {}
      // open/closed status
      if (this.statusSubmission) {
        payload.status = this.statusSubmission
      }
      // specific patient 
      if (this.patientsearch) {
        payload.patient = this.patientsearch
      }
      // specific anesthetist 
      if (this.anesthetistsearch) {
        payload.anesthetist_id = this.anesthetistsearch.uuid
      }
      // specific date range
      if (this.date.length) {
        payload.date = this.formatDate()
      }
      // page
      payload.page = this.currentPage

      axios.post('/theatre-checkout-search', payload, this.$store.state.header).then(res => {
        this.loadingTheatreData = false;
        this.theatreSearchResult = res.data.data
        this.pageCount = res.data.page_count
      }).catch(err => {
        console.log('this is the err', err);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })

    },
    formatDate() {
      let date = {
        start : "",
        end : ""
      }
        if (typeof this.date == "string") {
          date.start = this.date.split("-")[0]
          date.end = this.date.split("-")[1]
        } else {
          if(this.date[1] == null) {
            date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
            date.end = new Date(this.date[0]).toLocaleDateString('en-AU');
          } else {
            date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
            date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
        }
        }
      return date
    },
    copyTransactionID(item) {
      item.copied = true;
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = item.transaction_id;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setTimeout(() => { item.copied = false }, 500)
    },
    clearFilteredDrugRegister() {
      this.form = {
        location: this.$store.state.uuid,
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          transaction_id: '',
          to_from: '',
          entered_by: '',
          date: {
            start: '',
            end: ''
          },
          drug: '',
          imprest_only: this.$store.state.drugRegImprestToggle,
          destruction_only: this.$store.state.drugRegDestructionToggle,
        }
      };
      this.date = [];
    },
    displayPage(page) {
      this.currentPage = page;
      this.search()
    },
  },
  mounted() {
    this.search()
    if (localStorage.getItem('reset_password') == 'true') {
      this.displayResetPasswordModal = true;
    }
  },
  beforeUnmount() {
    this.$store.commit('setDrugEntries', []);
  },
  created() {
    // window.addEventListener('beforeunload', function (event) {
    //     document.cookie = 'auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    //     alert('leaving', event) // logs to my logger
    //   }, false)
    window.addEventListener("beforeunload", function () {
      // var confirmationMessage = window.event;

      // (e || window.event).returnValue = confirmationMessage;
      // console.log('this is the performance', window.performance.getEntriesByType("navigation")[0].type);
      console.info("This page is reloaded", performance.type);
      if (performance.type == performance.TYPE_RELOAD) {
        alert("This page is reloaded", performance.type);
      } else {
        document.cookie = 'auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        console.log('this is being closed');
      }
      // return confirmationMessage;                            //Webkit, Safari, Chrome
    });
  },
  computed:{
  }
}
</script>

<style scoped>
.p-dropdown .p-dropdown-label {
    font-size: 0.8em;
}

</style>
