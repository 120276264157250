<template>
    <router-link v-if="newTheatreMovements.length > 0" style="text-decoration: none; margin:0; color: #2c3e50;" :to="'/theatre'">
            <div class="notification-type-box">
            <div style="display: inline-block;display:flex ;justify-content: start;align-items: center; padding-left: 10px;">
                <h4 style="margin: 20px 0px 0px 15px;">Theatre Checkouts</h4>
                <span style="margin-top: 20px;" class="alert-count">
                    <p style="display: inline-block;">{{ newTheatreMovements.length }}</p>
                </span>
            </div>
            <div class="notification-content-container">
                <div>
                    <table class="data-table-notifications" cellspacing="0">
                        <tr>
                            <th style="width: 33%;padding-left: 10px;">
                                <h3 style="padding-right: 10px;">Patient</h3>
                            </th>
                            <th style="width: 33%">
                                <h3 style="padding-right: 10px;">Date Created</h3>
                            </th>
                            <th style="width: 33%">
                                <h3 style="padding-right: 10px;">Status</h3>
                            </th>
                        </tr>
                        <tbody class="table-data-notifications-3col">

                            <tr v-for="(item, index) in newTheatreMovements" :key="index">
                         
                                <td style="padding-left: 10px;">{{ item.body.message.data[0].patient }}</td>
                   
                                <td>{{ new Date(item.created_at).toLocaleDateString('en-AU')}}</td>

                                <td>{{ item.body.message.status[0].toUpperCase() + item.body.message.status.slice(1)  }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </router-link>
</template>


<script>

export default {
    computed: {
        newTheatreMovements() {
            const channelList = [
                'theatre_created'
            ];
            const theatreRaw = this.$store.getters.getNotificationItems.filter(item => channelList.includes(item.channel));
            return theatreRaw;
           
        },
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.notification-item {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.notification-type-box {
    border-bottom: 0px solid #a4a4a4;
}

.notification-content-container {
    display: flex;
    padding: 10px 25px 10px 25px;
    gap: 10px;
    justify-content: space-between;
}

.alert-item-type {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
}
</style>