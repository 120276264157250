<template>
  <div class="dashboard-container" style="display: grid; overflow: scroll; padding: 10px; padding-top: 0px; ">
      <div class="stock-table-wrapper" style="max-height: none">
          <table class="stock-table chart-table">
              <tr style="border-bottom: 1px solid black">
                <th style="text-align: end">Name</th>
                <th style="text-align: right; padding-left: 25px;">Last Review</th>
                <th style="text-align: center; padding-left: 20px;">Next Review</th>
            </tr>
          </table>
          <table class="stock-table" style="border-collapse:separate; 
border-spacing:0 5px; border-radius: 10px;">
            <tr  style="background-color: #F3F4F4; padding: 0px; margin-bottom: 5px; border-radius: 8px" v-for="(item, index) in patients" :key="index">
                <router-link style="text-decoration: none; margin:0; color: black; cursor: pointer; display: flex; justify-content: space-around; padding-right:20px;" :to="'/patient/'+item.uuid">
                <td style="cursor: pointer; width: 6em; text-align: left;">{{item.personal_information.first_name}} {{item.personal_information.last_name}}</td>
                <td style="cursor: pointer">{{item.chart_review && item.chart_review.reviewed_on ? item.chart_review.reviewed_on.slice(0,12) : 'Never Reviewed'}}</td>
                <td style="cursor: pointer">{{item.chart_review && item.chart_review.next_review_date ? item.chart_review.next_review_date.slice(0,12) : 'Review Now'}}</td>
                </router-link>
            </tr>
        </table>
        <div  v-if="loading" >
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div>
      </div>


                    
                    
      
      <!-- <div style="display: flex; height: 10px;">
          <h4>Name</h4>
          <h4>Last Review</h4>
          <h4>Next Review</h4>
      </div>
      <div v-for="(item, index) in chart_reviews" :key="index" style="display: flex; height: 10px;">
          <p>{{item.name}}</p>
            <p>{{item.last_reviewed}}</p>
            <p>{{item.next_review}}</p>
      </div> -->
        
  </div>
</template>

<script>
export default {
    props:['patients', 'loading'],
    data(){
        return{
        }
    }
}
</script>

<style lang="scss">
    #chart-review-table{
        tr, th{
            height: 10px !important;
        }
    }

    .chart-table{
        tr:first-child td:first-child { border-top-left-radius: 10px; }
        tr:first-child td:last-child { border-top-right-radius: 10px; }
        tr:last-child td:first-child { border-bottom-left-radius: 10px; }
        tr:last-child td:last-child { border-bottom-right-radius: 10px; }
    }
</style>