<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Pending Invoices</h3>
                        <button @click="closeModal()" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr">

                                <!-- <div class="left-form-section-panel">
                            <div>
                                <p class="note-header">Notes</p>
                                <textarea style="border-top-left-radius: 0; border-top-right-radius: 0; width: 100%;" name="notes" id="note-modal" cols="23" rows="20" placeholder="Enter notes here..." v-model="form.submit.notes"></textarea>
                            </div>
                        </div> -->

                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel" style="display: flex; flex-direction: column;">
                                    
                                    <!-- PRESCRIPTION/MED SEARCH -->
                                    <div style="margin: 0 auto">
                                        <div style="display: flex; align-items: center; margin: 0 auto">
                                            <div class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-bottom: 10px; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search Invoices</label>
                                                    <InputText v-model="searchTerm" style="width: 30em; background-color: #F3F4F4; opacity: 1" placeholder="Enter Medication Name or Invoice Number"/>
                                               
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END PRESCRIPTION/MED SEARCH -->

                                    <!-- DELETE DISPENSING BUTTON -->
                                    <div style="display: flex; height: 35px">
                                        <transition name="fade" appear>
                                            <div style="display: flex; align-items: center; cursor: pointer" v-if="selectedDispensings.length" @click="deleteDispensings()">
                                                <i class="pi pi-trash" style="font-size: 1em;"></i>
                                                <span style="border-bottom: 1px solid black">Delete Selected Items</span>
                                            </div>
                                        </transition>
                                        
                                        
                                    </div>
                                    <!-- END DELETE DISPENSING BUTTON -->
                                    
                                    <div class="right-form-section-drug-entry" style="max-height: 500px; align-items: flex-start; overflow-x: scroll;">
                                        <table class="data-table audit-table" id="transaction-table" cellspacing="0">
                                            <tr>
                                                <th style="width: 50px;"><Checkbox v-model="selectAll" :binary="true" @change="selectAllDispensings()" style="margin-left: 10px;" /></th>
                                                <th>Supplier</th>
                                                <th>Invoice Number</th>
                                                <th>Date Received</th>
                                                <th style="width: 50px;"></th>
                                            </tr>
                                            <tbody  class="table-data" v-for="(item, index) in searchList" :key="index">
                                                 <tr style="background-color: #f3f4f4"
                                                    :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']"
                                                    >
                                                    <td><Checkbox v-model="item.selected" @click="selectDispensing(item)" :binary="true" style="margin-left: 10px;" /></td>
                                                    <td @click="() => { showContent(item, index);}" style="cursor: pointer">{{item.supplier.name}} </td>
                                                    <td @click="() => { showContent(item, index);}" style="cursor: pointer">{{item.invoice_number}}</td>
                                                    <td @click="() => { showContent(item, index);}" style="cursor: pointer">{{item.date_received}}</td>
                                                    <td @click="() => { showContent(item, index);}" style="cursor: pointer" >
                                                        <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                                                        <i v-else class="pi pi-angle-right"></i>
                                                    </td>
                                                </tr>
                                                <tr :ref="'expanddispensingitem-' + index" :id="'expanddispensingitem-' + index" class="expanded-content">
                                                    <div class="expanded-transfer-container"
                                                        style="display: grid; 
                                                        grid-template-columns: 2fr 1fr; 
                                                        background-color: white;
                                                        width: 77.4vw;
                                                        border: 1px solid black;
                                                        border-top: none;">
                                                        <table class="row-table" style="border: 1px solid black; margin-bottom: 10px;">
                                                            <tr style="border-top-left-radius: 5px">
                                                                <th style="padding-left: 10px; border-top-left-radius: 5px">Drug</th>
                                                                <th>Quantity</th>
                                                                <th>In Stock</th>
                                                            </tr>
                                                            <tr v-for="(drug, i) in item.drugs" :key="i">
                                                                <td style="padding-left: 10px;">{{drug.name}}</td>
                                                                <td>{{drug.quantity}} {{drug.form}}/s</td>
                                                                <td>{{drug.in_stock}}</td>
                                                            </tr>
                                                        </table>
                                                         <table class="row-table">
                                                            <tr>
                                                                <td>Supplier:</td>
                                                                <td>{{item.supplier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Contact Name:</td>
                                                                <td>{{item.supplier.contact_name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Address:</td>
                                                                <td>{{item.supplier.email}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phone Number:</td>
                                                                <td>{{item.supplier.phone_number}}</td>
                                                            </tr>
                                                        </table>

                                                    </div>
                                                </tr>
                                            </tbody>
                                           
                                        </table>
                                    </div>

                                    <div v-if="errorMessage && !form.submit.drugs.length">
                                        <p style="color: red; font-weight: 500; font-size: 1em;">{{ errorMessage }}</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="margin-right: 10px;" @click="submitDispensings()">
                            Add</p>
                    </slot>
                </div>
            </div>
        </div>
        <SignatureModal v-if="displaySignatureModal" :payload="payload"
            @isSuccessful="isSuccessful" @close="close" :endpoint="endpoint" 
            dual="true"  />
    </div>
</template>

<script>
import SignatureModal from '@/components/modals/SignatureModal.vue';
// import axios from 'axios';

export default {
    props: [],
    components: {
        SignatureModal,
    },
    data() {
        return {
            endpoint:'/accept-invoices',
            searchTerm:'',
            items:[
                {
                    uuid:'234234-23423-42342-34234',
                    invoice_number: '29939-239399',
                    date_received: '12/11/2022',
                    supplier:{
                        name: 'CSH',
                        contact_name:'Richard Williamson',
                        email: 'rich@csh.com',
                        address:'1 Smith Street, Kilmore, VIC 3029',
                        phone_number: '03 9999 2000'
                    },
                    drugs:[
                        {
                            name:'Oxycontin 5mg',
                            form: 'tablet',
                            quantity: 20,
                            in_stock: 200
                        },
                        {
                            name:'Amoxylin 5mg',
                            form: 'tablet',
                            quantity: 30,
                            in_stock: 200
                        },
                        {
                            name:'Panadol 5mg',
                            form: 'tablet',
                            quantity: 10,
                            in_stock: 200
                        },
                        {
                            name:'Ritalin 5mg',
                            form: 'tablet',
                            quantity: 50,
                            in_stock: 200
                        }
                    ]
                }
            ],
            selectAll:false,
            displaySignatureModal: false,
            errorMessage: '',
            selectedDispensings:[],
            payload:''
        }
    },
    methods: {
        deleteDispensings(){
            console.log('will delete selected dispensings', this.selectedDispensings);
            this.endpoint = '/delete-dispensings';
            this.payload = {
                dispensing_ids: this.selectedDispensings
            }

        },
        selectDispensing(item){
            if(item.selected && this.selectedDispensings.includes(item.uuid)){
                this.selectedDispensings.splice(this.selectedDispensings.indexOf(item.uuid),1);
            } else{
                this.selectedDispensings.push(item.uuid);
            }
            console.log('this is the selected list', this.selectedDispensings, this.selectedDispensings.includes(item.uuid));
        },
        selectAllDispensings(){
            console.log('this is the select all status', this.selectAll);
            this.selectedDispensings = [];
            this.items.forEach(item=>{
                item.selected = this.selectAll;
                if(this.selectAll){
                    this.selectedDispensings.push(item.uuid);
                }
            })
        },
        showContent(item, index) {
            if (item.display && (item.display === true)) {
                document.getElementById(`expanddispensingitem-${index}`).style.display = 'none';
                item.display = !item.display;
            } else {
                item.display = true;
                console.log('expanding range for this object');
                document.getElementById(`expanddispensingitem-${index}`).style.display = 'table-row';
                
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
            if (this.imprest_pom != 'imprest' && !this.patient) {
                this.displayPatientSearchModal = false;
                this.imprest_pom = '';
            }
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        isSuccessful(value) {
            if (value) {
                if (!this.addNew) {
                    this.$emit('close', true);
                }
                else {
                    if (this.drugReg.length) {
                        this.removedDrug = true;
                    }
                    this.form.submit.drugs = [];
                    this.discard_amount = '';
                    this.discard = false;
                }
                if (this.$route.name == 'Drug Register') {
                    console.log('loading the drug register stuff');
                    this.$store.dispatch('getDrugRegister', {
                        location: this.$store.state.uuid,
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        filters: {
                            transaction_id: '',
                            to_from: '',
                            entered_by: '',
                            date: {
                                start: '',
                                end: ''
                            },
                            drug: '',
                            imprest_only: this.$store.state.drugRegImprestToggle,
                            destruction_only: this.$store.state.drugRegDestructionToggle,
                        }
                    });
                }
            }
        },
        submitDispensings(){
            this.payload = {
                dispensing_ids: this.selectedDispensings
            }
            this.endpoint = '/accept-incoming-dispensings';
            console.log('submitting this for signatures', this.payload); 
            this.displaySignatureModal = true;
        }
    },
    mounted() {
    },
    computed:{
         searchList(){
            return this.items.filter(item=>{return item.invoice_number.toLowerCase().includes(this.searchTerm.toLowerCase())})
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


 

.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.row-table{
    border-collapse: collapse;
    th{
        height: 30px !important; 
    }
    td,tr{
        height: 30px !important;
    }
    tr:nth-child(odd){
            background-color: #f3f4f4;
        }
    td{
        border-bottom: none !important;
    }
}
</style>
