<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">New Requisition</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <!-- SELECT DROPDOWN LOCATION -->
                                <div class="left-form-section-panel">
                                        <div style="display: flex; align-items: center; margin-bottom: 5px;">
                                            <Dropdown v-model="searchedLocation" :disabled="searchLocationDisabled" :placeholder="$store.state.currentLocation.type == 'pharmacy' ? 'Distribute to...' : 'Select Pharmacy'" ref="location" 
                                            @change="locationSelect()" @keyup="debounceSearchLocation()" 
                                            :loading="loadingLocation" :options="refinedLocations" optionLabel="name" :editable="true"
                                            style="width: 14em; display: flex"/>
                                        </div>
                                        <div style="margin-top: 10px;">
                                            <p style="text-align: left; font-weight: bold;" class="note-header">Note
                                            </p>
                                            <textarea class="notes-modal" name="notes" id="note-modal"
                                                placeholder="Enter notes here..." v-model="form.submit.notes">
                                            </textarea>
                                        </div>
                                </div>
                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information && form.submit.location"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p v-if="patient.regulatory_information.length"><b >Medicare Number:</b> {{ patient.regulatory_information[0].data }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                            <p style="color:#0870DC; text-decoration: underline;" @click="changePatient()">Change Patient</p>
                                        </div>
                                    </div>
                                    <p v-if="!imprest_pom && !form.submit.location" style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left; font-weight: bold">
                                            First, select a location on the left panel.</p>
                                    <div v-if="!imprest_pom && form.submit.location && !patient">
                                        <p style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div style="width: 100%; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true; searchLocationDisabled = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']" class="pom-imprest-choice-button">
                                                    Patient Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; searchLocationDisabled = true}"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']" class="pom-imprest-choice-button">
                                                    Imprest/Emergency Meds/Ward Stock</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="right-form-section-drug-entry requisition-search-drug"

                                        v-if="imprest_pom == 'imprest' || (patient) && form.submit.location">
                                        <!-- <div style="width: 50%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div> -->
                                        <!-- <div style="width: 100%; margin-right: 10px">
                                                <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="searchDrugs(stock), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                            style=" display: flex" placeholder="Type medication name here"/>
                                           
                                        </div> -->
                                        <StockSearch :optionalLocation="searchedLocation.uuid" :optionalPatient="patient" @drugselected="setdrug"/>

                                        <input v-model="qty" min="0" type="number" :disabled="drugAddDisabled" placeholder="Enter qty"
                                        style="position: relative; width: 110px; margin-right: 10px;margin-left:10px" />
                                        <p class="blue-button"
                                            @click="() => { if (qty) { checkIfExistingItem(selectedDrug.full_item) } }">Add</p>
                                    </div>
                                    <p v-if="errorAddingAlias" style="color: red;">{{ errorAddingAliasMessage }}</p>

                                    <div v-if="addingMed" style="display: flex; align-items: center; justify-content: center;">
                                    <span>Adding medication...</span>
                                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem !important;"></i>
                                    </div>
                                    <div class="left-form-section-drug-container"
                                        v-if="form.submit.drugs && form.submit.drugs.length > 0">
                                        <table style="border-collapse: collapse">
                                            <tr>
                                                <th><h4 style="font-size: 0.9em; margin: 0; padding: 0;">Patient</h4></th>
                                                <th><h4 style="font-size: 0.9em; margin: 0; padding: 0;">Drug Name, Strength, Form</h4></th>
                                                <th><h4 style="font-size: 0.9em; margin: 0; padding: 0;">Pharm. Stock Qty</h4></th>
                                                <th><h4 style="font-size: 0.9em; margin: 0; padding: 0;">Stock Qty</h4></th>
                                                <th><h4 style="font-size: 0.9em; margin: 0; padding: 0;">Qty Requested</h4></th>
                                                <th></th>
                                            </tr>
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td>
                                                    <p v-if="item.patient && item.patient.personal_information && item.patient.personal_information.first_name">{{ item.patient.personal_information.first_name }}
                                                {{ item.patient.personal_information.last_name }}</p>
                                                <p v-else>IMPREST</p>
                                                </td>
                                                <td style="width: 35%;">
                                                    <p>{{ item.stock }}</p>
                                                </td>
                                                <td>
                                                    <p>{{ item.pharm_qty ? item.pharm_qty : 0 }}</p>
                                                </td>
                                                <td>
                                                    <p>{{ item.qty_left ? item.qty_left : 0 }} [{{item.patient ? 'PATIENT':'WARD'}}]</p>
                                                </td>
                                                <td>
                                                    <p>{{ item.qty }}</p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times"
                                                                style='padding: 0; padding-top: 3.5px;'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red;">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p v-if="contructRequiredData == ''" class="regular-button complete-button" style=" margin-right: 10px;" @click="submitTransfer()">Submit</p>
                        <p v-else class="grey-button">Submit</p>
                        <!-- <p class="regular-button complete-button" @click="submitTransferAndCreateNew()">Receive Delivery
                            + New</p> -->
                    </slot>
                </div>
            </div>
        </div>
        <!-- <NewCompanyModal v-if="addNewSupplier" @closeLocationModal="closeLocationModal"
            @addNewCompanyToRefined="addNewCompanyToRefined" companyType='Supplier' /> -->
        <SignatureModal @close="close" v-if="displaySignatureModal" @isSuccessful="isSuccessful"
            endpoint="/create-requisition" :payload="realSubmit" dual="true" />
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
    </div>
</template>

<script>
import SignatureModal from '@/components/modals/SignatureModal.vue';
// import NewCompanyModal from '@/components/modals/NewCompanyModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import StockSearch from '@/components/searches/StockSearch.vue';
import axios from 'axios';

export default {
    props: ['transferType', 'propPatient', 'propDrug'],
    components: {
        SignatureModal,
        // NewCompanyModal,
        PatientSearchModal,
        StockSearch
    },
    data() {
        return {
            searchLocationDisabled: false,
            drugAddDisabled: false,
            errorAddingAlias: false,
            errorAddingAliasMessage: '',
            loadingLocation:false,
            searchedLocation: '',
            addingMed: false,
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            prescriberResults: [],
            addNew: false,
            prescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            qty: null,
            supplier: '',
            addNewSupplier: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: [],
            selectedDrug:{},
            loadingDrugs:false,
            realSubmit: {},
            location:'',
            drugSearchFilter:1,
            drugList:[],
            requestRunning: false,
            form: {
                submit: {
                    patient_uuid: '',
                    prescriber_uuid: '',
                    location: '',
                    notes: '',
                    drugs: [],
                }
            }
        }
    },
    methods: {
        setdrug(drugFormatted) {
            this.selectedDrug = drugFormatted
            this.form.submit.location = this.searchedLocation.uuid
        },
        changePatient(){
            console.log("new patient search");
            this.displayPatientSearchModal = true;
        },
        showLocations(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                // console.log('HITTING INTERVAL');
                if (!this.loadingLocation && this.refinedLocations?.length) {
                    clearInterval(interval);
                    this.$refs.location.show();
                }
            }, 100)
        },
        hideLocations(){
            this.$refs.location.hide();
        },
        debounceSearchLocation() {
            this.hideLocations()
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchLocation()
            }, 400); // delay
        },
        searchLocation(){
            
            if (this.searchedLocation) {
                if (this.cancelTokenSource && this.requestRunning) {
                    this.cancelTokenSource.cancel("Request canceled");
                    this.requestRunning = false;
                }

                // Create new cancel token source
                this.cancelTokenSource = axios.CancelToken.source();
                this.requestRunning = true;
                this.loadingLocation = true;
                console.log('SEARCHED LOCATION RUNNING', this.searchedLocation, this.loadingLocation, this.$store.state.currentLocation.type);
                axios.post('/get-locations', { location: this.searchedLocation, location_type: [this.$store.state.currentLocation.type == 'pharmacy' ? 'ward' : 'pharmacy']}, {
                    cancelToken: this.cancelTokenSource.token,
                    headers: this.$store.state.header.headers
                }).then(res => {
                    
                    console.log('these are the returned locations', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedLocations = res.data.locations;
                    if(this.refinedLocations.length){
                        this.$refs.location.show();
                    }
                    this.loadingLocation = false;
                    if (this.refinedLocations.length == 1) {
                        this.form.submit.location = this.refinedLocations[0].uuid;
                        this.location = this.refinedLocations[0].name;
                    }
                    this.requestRunning = false;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                });
                
            }
        },
        locationSelect() {
            this.location = this.searchedLocation.name;
            this.form.submit.location = this.searchedLocation.uuid;
            if(this.stock){
                this.debounceSearchDrugs(this.stock);
                this.show()
            }
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        searchDrugs() {
            if(this.stock){
                this.loadingDrugs = true;
                console.log('this is the current location', this.$store.state.currentLocation.type);
                if(this.$store.state.currentLocation.type != 'pharmacy'){
                    let pharmHeader = {
                        headers:{
                            'Authorization': this.$store.state.header.headers['Authorization'],
                            'Content-Type': 'application/json',
                            'Location': this.form.submit.location
                        }
                    }

                    axios.post('/get-stock-items', { drug: this.stock }, pharmHeader).then(res => {
                        console.log('this is the res data', res.data);
                        res.data.forEach(item=>{
                            item.full_name = `(${item.alias.generic_name}) ${item.alias.name}  [In stock: ${item.qty_left}]`;
                            if(item.qty_left == 0){
                                item.disabled = true;
                            } else{
                                item.disabled = false;
                            }
                        })
                        this.drugList = res.data;

                        this.loadingDrugs = false;
                    }).catch(err => {
                        console.log('this is the err', err);
                        this.loadingDrugs = false;
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                    })
                } else{
                    axios.post('/get-stock-items', { drug: this.stock }, {
                        cancelToken: this.cancelTokenSource.token,
                        headers: this.$store.state.header.headers
                    }).then(res => {
                        console.log('this is the res data', res.data);
                        res.data.forEach(item=>{
                            item.full_name = `(${item.alias.generic_name}) ${item.alias.name}  [In stock: ${item.qty_left}]`;
                            if(item.qty_left == 0){
                                item.disabled = true;
                            } else{
                                item.disabled = false;
                            }
                        })
                        this.drugList = res.data;

                        this.loadingDrugs = false;
                    }).catch(err => {
                        console.log('this is the err', err);
                        this.loadingDrugs = false;
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                    })
                }
                
            }
            
        },
        drugSelect(item) {
            this.loadingDrugs = true;

            this.qty = '';
            if(item.qty_left < 1){
                this.drugAddDisabled = true;
                this.errorAddingAliasMessage = `There is no stock for this drug to add.`;
                this.errorAddingAlias = true;
            }else{
                this.drugAddDisabled = false;
                this.errorAddingAliasMessage = ``;
                this.errorAddingAlias = false;
            }

            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.alias.form[0].toUpperCase() + item.alias.form.slice(1);
            this.selectedDrug.strength = item.alias.strength;
            this.selectedDrug.measurement_unit = item.alias.measurement_unit;
            this.selectedDrug.name = item.alias.name[0].toUpperCase() + item.alias.name.slice(1);
            this.selectedDrug.tags = item.alias.tags;
            this.loadingDrugs = false;
        },
        close(value) {
            if (value) {
                this.displaySignatureModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        getPatient(value) {
            if (value) {
                this.imprest_pom = 'pom'
                this.patient = value;
                this.displayPatientSearchModal = false;
            }
        },
        isSuccessful(value) {
            console.log('IS SUCCESSFUL IS BEING CALLED', value);
            if (value && this.addNew == false) {
                console.log('IS SUCCESSFUL IS BEING CALLED -- IF', value);
                this.$store.dispatch('getRequisitions', {
                    sort_direction: '',
                    col: '',
                    page: 1,
                    filters:{
                    status:['pending', 'pickup ready', 'transit','complete', 'cancelled'],
                    completed_by:'',
                    location:'',
                    type:[],
                    date:{
                        start:'',
                        end:''
                    },
                    }
                });
                this.closeModal();
            } else if (value && this.addNew) {
                console.log('IS SUCCESSFUL IS BEING CALLED -- ELSE IF', value);
                this.form.submit.drugs = [];
                this.imprest_pom = '';
                this.patient = '';
                this.selectedPrescriber = '';
                this.stock = '';
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewSupplier = false;
            }
        },
        addNewCompanyToRefined(value) {
            this.form.submit.supplier = value.uuid;
            this.refinedLocations = [value];
            console.log('RECEIVING FROM ADD LOCATION', value);
            this.supplier = value.name;
        },
        aliasSelect(item) {
            // check if qty for the drug selected is higher than the stock
            this.errorAddingAlias = false;
            if (this.qty > item.qty_left){
                this.errorAddingAliasMessage = `The quantity (${this.qty}) can not exceed the stock level (${item.qty_left}).`;
                this.errorAddingAlias = true;
                return false;
            }

            this.addingMed = true;
            console.log(item);
            this.drugList = [];

            if(item.alias)item.alias_id = item.alias.alias_id;
            if (this.form.submit.drugs.length) {
                this.form.submit.drugs.forEach(drugItem => {
                    // drug is already in the list
                    if ((item.alias_id == drugItem.drug) ) {
                        console.log('THIS IS THE ITEM AND DRUG ITEM', item, drugItem)
                        if(this.patient && drugItem.patient &&  this.patient.uuid == drugItem.patient.uuid){
                             // there is drugs in the list that match, and patient owned
                            console.log('HITTING MATCH FOR PATIENT AND DRUG');
                            drugItem.qty = this.qty;
                            this.$store.dispatch('getRefreshToken');
                            this.qty = null;
                            this.refinedDrugs = [];
                            this.noStock = false;
                            this.selectedDrug = {};
                            this.stock = '';
                            this.addingMed = false;
                        }  else if(!drugItem.patient && !this.patient){
                            // there is drugs in the list that match, imprest
                            console.log('HITTING MATCH FOR IMPREST');
                            drugItem.qty = this.qty;
                            this.$store.dispatch('getRefreshToken');
                            this.qty = null;
                            this.refinedDrugs = [];
                            this.noStock = false;
                            this.selectedDrug = {};
                            this.stock = '';
                            this.addingMed = false;
                        } else{
                            // there is drugs in the list that match, but no imprest or patient owned?
                            this.addDrugToList(item);
                        } 
                    } else {
                        // there is drugs in the list but no match
                        this.addDrugToList(item);
                    }
                })
            } else {
                // there is no drugs in the list yet
                this.addDrugToList(item);
            }
        },
        checkIfExistingItem(item){
            let exit = false;
            if(this.form.submit.drugs.length){
                this.form.submit.drugs.forEach(drugItem => {
                    console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit ) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } else if(!exit){
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else{
                this.aliasSelect(item);
            }
        },
        addDrugToList(item){
            // getting the stock for the current location to display the locations current stock
            // if stock does not exist it will follow the exception
            console.log('stock item response  --', item);
            axios.post('/get-stock', { patient_id: (this.imprest_pom == 'imprest') ? null : this.patient.uuid, alias_id: item.alias.alias_id, location_id: this.$store.state.currentLocation.uuid }, this.$store.state.header).then(res => {
                if (this.qty) {
                    this.form.submit.drugs.unshift({
                        tags: item.alias.tags,
                        stock: item.alias.name,
                        form: item.alias.form,
                        drug: item.alias.alias_id,
                        stock_id: item.uuid,
                        qty_left: res.data.qty_left,
                        qty: this.qty,
                        pharm_qty: item.qty_left,
                        patient: (this.imprest_pom == 'imprest') ? null : this.patient
                    });
                    this.noStock = false;

                    this.qty = null;
                    this.addingMed = false;
                    // this.selectedDrug = {};
                    this.stock = '';
                }
               
            }).catch(err => {
                if (err.response.data == 'No stock has been recorded on this location for that drug alias.' ||
                    err.response.data == 'For some reason this location does not have a stock register' ||
                    (err.response.data.errors && err.response.data.errors.locationUUID && err.response.data.errors.locationUUID[0] == "There is no stock in the register.")) {
                    if (this.qty) {
                        this.form.submit.drugs.unshift({
                            tags: item.alias.tags,
                            stock: item.alias.name,
                            form: item.alias.form,
                            drug: item.alias.alias_id,
                            stock_id: item.uuid,
                            qty_left: 0,
                            qty: this.qty,
                            pharm_qty: item.qty_left,
                            patient: (this.imprest_pom == 'imprest') ? null : this.patient
                        });
                        this.noStock = false;
                        this.qty = null;
                        this.addingMed = false;
                        // this.selectedDrug = {};
                        this.stock = '';
                    }
                }
            });

        },
        checkAndAddStock(drugName) {
            if (drugName) {
                axios.post('/get-drug-aliases', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the drug alias names', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.drug_aliases;
                })

            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        submitTransfer() {

            let newDrugSub = [];
            this.addNew = false;
            this.form.submit.drugs.forEach(item => {
                let p = {
                    // drug: item.drug,
                    qty: Number(item.qty),
                    patient_uuid: item.patient ? item.patient.uuid : '',
                    stock_id: item.stock_id,
                }
                if (item.tags && item.tags.length) {
                    this.dual = true;
                }

                if (item.qty > 0) {
                    newDrugSub.push(p);
                }
            });
            
            this.refinedLocations = [];

            if (newDrugSub.length) {
                // if(this.patient){
                //     this.form.submit.patient_uuid = this.patient.uuid;
                //     this.form.submit.prescriber_uuid = this.selectedPrescriber.uuid
                // }
                this.realSubmit = {
                    requisition_items: newDrugSub,
                    notes: this.form.submit.notes,
                    to: this.$store.state.currentLocation.type == 'pharmacy' ? this.form.submit.location : this.$store.state.currentLocation.uuid,
                    from: this.$store.state.currentLocation.type == 'pharmacy' ?  this.$store.state.currentLocation.uuid : this.form.submit.location
                };
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drugs, Reference Number and Date Received are required.'
            }
        },

    },
    computed: {
        contructRequiredData() {
            let remaining = []
            let stringbuilder = ""

            if (!this.form.submit.drugs.length) {
                remaining.push("Medication(s)") 
            } 
            
            remaining.forEach((field, index) => {
                console.log(index)
                console.log(remaining.length)
                if(remaining.length == index+1) {
                    stringbuilder += field 
                } else {
                    stringbuilder += (field + ", ")
                }
            })
            return stringbuilder
        }
    },
    mounted() {
        // setting the subdomain - used in e.g. algolia searches
        
        let myHost = window.location.host;

        if(myHost.includes('hospital') && myHost.includes('staging')){
            this.$store.commit('setSubdomain', 'st-hp')
        } else if(myHost.includes('hospital')){
            this.$store.commit('setSubdomain', 'prod')
        } else{
            this.$store.commit('setSubdomain', 'st-hp')
        }

        if(this.propPatient){
            this.patient = this.propPatient;
            if(this.propDrug){
                this.stock = this.propDrug;
            }
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


 

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
