<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel"
      style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="displayActionModal = true" class="actions-menu-item">New Destruction</button>
    </div>
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_destruction')">
      <h3 class="panel-header">Destructions</h3>
      <div class="drug-register-table table-container">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center; height: 50px;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">
            <div class="text-search-container search-container">
              <input v-model="form.filters.drug" placeholder="Medication..." style="position: relative" />
            </div>

            <div class="text-search-container search-container">
              <input v-model="form.filters.patient" placeholder="Patient..." style="position: relative" />
            </div>

            <!-- <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
            </div> -->

            <button class="button submit-button" @click="loadDestructions()">Search</button>
            <button class="button clear-button" @click="clearTransfersFilters()">Clear</button>
          </div>

          <div style="display: flex; align-items: center">
            <div class="select-filters-container">

              <p @click="() => { form.filters.status = 'pending'; loadDestructions() }"
                v-bind:class="[form.filters.status == 'pending' ? 'active-select-filter' : '']"
                class="select-filter-item">Pending Destructions</p>

              <p @click="() => { form.filters.status = 'completed'; loadDestructions() }"
                v-bind:class="[form.filters.status == 'completed' ? 'active-select-filter' : '']"
                class="select-filter-item">Destruction Register</p>

            </div>
          </div>

          <!-- DESTROY BUTTON -->
          <div class="select-filters-container" v-if="numberToDestroy > 0 && form.filters.status == 'pending'">
            <p @click="() => { destroyItems() }" class="select-filter-item destroy-button">Destroy {{ numberToDestroy }}
              Items
            </p>
          </div>
          <!-- END DESTROY BUTTON -->

          <!-- REVERSE DESTROY BUTTON -->
          <div class="select-filters-container" v-if="numberToReverse > 0">
            <p @click="() => { reverseDestroyItems() }" class="select-filter-item warning-button">Reverse
              {{ numberToReverse }} Items</p>
          </div>
          <!-- END REVERSE DESTROY BUTTON -->

        </div>
        <div v-if="!$store.state.loadingDestructionsData" style="display: flex; align-items: start; overflow-y: scroll">
          <div class="drug-register-table table-container" style="padding-top: 10em;"
            v-if='!$store.state.user.role.permissions.includes("can_view_destruction")'>
            <h3
              style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
              You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
          <table v-else class="data-table" cellspacing="0">
            <tr class="table-headers">
              <th style="width: 25px"></th>
              <th style="width: 40px"></th>
              <th style="width: 150px;">
                <h3 @click="sortBy('date', 'desc')">Date</h3>
                <div v-if="form.col == 'date'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  date-sort" v-if="form.col == 'date' && form.sort_direction == 'asc'"
                    @click="sortBy('date', 'desc')"></i>
                  <i class="pi pi-sort date-sort" v-else @click="sortBy('date', 'asc')"></i>
                </div>
              </th>
              <th>
                <h3 @click="sortBy('drug', 'desc')">Drug</h3>
                <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  drug-sort" v-if="form.col == 'drug' && form.sort_direction == 'asc'"
                    @click="sortBy('drug', 'desc')"></i>
                  <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
                </div>
              </th>
              <th style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">Qty</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th>
              <!-- <th style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">In</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th> -->
              <!-- <th style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">Out</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th> -->
              <th v-if="form.filters.status == 'pending'" style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">Total</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th>
              <!-- <th>
                <h3>Notes</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th> -->
              <th style="width: 200px;">
                <h3 @click="sortBy('user_sort', 'desc')">User</h3>
                <div v-if="form.col == 'user_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  user-sort" v-if="form.col == 'user_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('user_sort', 'desc')"></i>
                  <i class="pi pi-sort user-sort" v-else @click="sortBy('user_sort', 'asc')"></i>
                </div>
              </th>

            </tr>

            <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
              <tr :class="[item.display && (item.display == true) ? 'select-row' : '']"
                @click="showContent(item, index)">
                <td style="cursor: pointer">
                  <i v-if="item.expand && (item.expand == true)" class="pi pi-angle-down"></i>
                  <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>
                  <div @click.stop="() => { selectItem(item, index) }" v-if="($store.state.currentLocation.state != 'WA' || ($store.state.currentLocation.state == 'WA' && (numberToDestroy == 0 || item.display)))" style="cursor: pointer">
                    <div v-if="item.display && (item.display == true)" class="check-circle circle-on"></div>
                    <div v-else class="circle-off"></div>
                  </div>
                </td>
                <td>{{ item.date }}</td>
                <td v-if="item.alias">{{ item.alias.name }}</td>
                <td>{{ item.quantity }}</td>
                <!-- In -->
                <!-- <td>{{ item.quantity }}</td> -->
                <!-- Out -->
                <!-- <td>{{ item.qty_destroyed ?? 0 }}</td> -->
                <!-- Total -->
                <td v-if="form.filters.status == 'pending'">{{ item.qty_pending ?? 0 }}</td>
                <!-- <td>{{ item.notes }}</td> -->
                <td v-if="item.user && item.user.personal_information">{{ item.user.personal_information.first_name }}
                  {{ item.user.personal_information.last_name }}</td>
              </tr>
              <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content">
                <div class="expanded-transfer-container">
                  <div v-if="!item.expandedInfo" style="margin: 0 auto">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                  </div>
                  <div v-else class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                    <div class="transfer-specs-container" v-if="item.expandedInfo">
                      <div class="status-field">
                        <h4 class="field-title" style="line-height: 30px;">Type</h4>
                        <p v-if="item.expandedInfo && item.expandedInfo.status" class="field-value"
                          style='text-align: center; height: 30px;'
                          :class="[item.expandedInfo.status == 'completed' ? 'correct-status-color' : (item.expandedInfo.status == 'rejected' || item.expandedInfo.status == 'reversed' ? 'mismatch-status-color' : 'mismatch-status-color')]">
                          Destruction</p>
                      </div>
                      <div class="date-received-field">
                        <p class="field-title">Method</p>
                        <p class="field-value">{{ item.expandedInfo.method ? item.expandedInfo.method : 'N/A' }}</p>
                      </div>
                      <div class="ordered-from-field" v-if="item.expandedInfo.location">
                        <p class="field-title">Location</p>
                        <p class="field-value">{{ item.expandedInfo.location.name }}</p>
                      </div>
                      <div class="ordered-by-field">
                        <p class="field-title">User</p>
                        <p class="field-value" v-if="item.user && item.user.personal_information">
                          {{ item.user.personal_information.first_name }} {{ item.user.personal_information.last_name }}
                        </p>
                      </div>
                      <div class="fulfilled-by-field">
                        <p class="field-title">Courier Notes</p>
                        <p class="field-value">{{ item.expandedInfo.courier_notes ? item.expandedInfo.courier_notes :
                            'N/A'
                        }}</p>
                      </div>
                      <div class="fulfilled-time-field">
                        <p class="field-title">Courier Name</p>
                        <p class="field-value">{{ item.expandedInfo.courier_name ? item.expandedInfo.courier_name :
                            'N/A'
                        }}</p>
                      </div>
                    </div>
                    <div class="transfer-notes-container" style="padding-bottom: 1em;">
                      <h4 class="field-title">Notes</h4>
                      <div class="field-notes" style="width: 95%;">
                        <p>{{ item.notes }}</p>
                      </div>
                    </div>
                    <div class="transfer-drugs-container" v-if="item.expandedInfo">
                      <h4 class="field-title">Drugs</h4>
                      <div class="drug-field-tables"
                        style="width: 95%;  height: 300px;">
                        <table class="dropdownDrugTable">
                          <thead>
                            <tr>
                              <th colspan="1">Name</th>
                              <th colspan="1">Qty</th>
                              <th colspan="1">Patient</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(drug, key) in item.expandedInfo.drugs" :key="key">
                              <td>{{ drug.alias.name }}</td>
                              <td>{{ drug.quantity }} {{ drug.alias.form }}/s</td>
                              <td>{{ item.expandedInfo.resident }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </tr>
            </tbody>

          </table>
        </div>
        <div v-else>
          <h3 v-if="$store.state.destructionError">{{$store.state.destructionError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
        <div class="pagination-links" v-if="$store.state.drugEntries && $store.state.drugEntries.current_page">
          <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1"
            @click="displayPage(form.page - 1)"> &larr; </p>
          <p>Page {{ $store.state.drugEntries.current_page }} of {{ $store.state.drugEntries.page_count }}</p>
          <p class="pagination-arrow"
            v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count"
            @click="displayPage($store.state.drugEntries.current_page + 1)"> &rarr; </p>
        </div>
      </div>

    </div>
    <div v-else class="drug-register-table table-container"
      style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <!-- <ActionModal v-if="displayActionModal" @close="close" :toAction="toAction" display="['destructions']" /> -->
    <DestructionModal v-if="displayActionModal" @close="close" @success="isSuccessful" />
    <PendingDestructionModal v-if="displayPendingDestructionModal" :payload="payload" @close="close"
      @success="isSuccessful" />
    <ReverseDestructionModal v-if="displayReverseDestructionModal" @success="isSuccessful"
      :pending='form.filters.status == "pending" ? "true" : ""' :payload="payload" @close="close" />
    <SignatureModal v-if="displaySignatureModal" @close="close" endpoint="/destroy-pending" dual="true"
      :payload="payload" />
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import DestructionModal from '@/components/modals/DestructionModal.vue';
import PendingDestructionModal from '@/components/modals/PendingDestructionModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import ReverseDestructionModal from '@/components/modals/ReverseDestructionModal.vue';
import axios from 'axios';

export default {
  components: {
    DestructionModal,
    SignatureModal,
    PendingDestructionModal,
    ReverseDestructionModal
  },
  data() {
    return {
      loadingDestructionEntry: false,
      displaySignatureModal: false,
      displayNewTransferModal: false,
      displayActionModal: false,
      displayPendingDestructionModal: false,
      displayReverseDestructionModal: false,
      toAction: {
        uuid: '',
        action: ''
      },
      selectedStatuses: [
        { status: 'Pending' },
        { status: 'Rejected' },
        { status: 'Completed' }
      ],
      statusSelect: [
        { status: 'Pending' },
        { status: 'Rejected' },
        { status: 'Completed' }
      ],
      payload: {
        items: []
      },
      drugNames: [],
      date: [],
      clearForm: {
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters: {
          status: 'pending',
          drug: '',
          date: {
            start: '',
            end: ''
          },
        }
      },
      form: {
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters: {
          status: 'pending',
          drug: '',
          patient: '',
          date: {
            start: '',
            end: ''
          },
        }
      },
      testData: [
        {
          date: '10/12/2021',
          drug: 'Abilify 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'm230f923f23f00asaaa'
        },
        {
          date: '12/10/2021',
          drug: 'Oxycodone 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'f39f2j3f9egw'
        },
        {
          date: '24/09/2021',
          drug: 'Methadone 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'sdlfsd9gsdf9w'
        },
        {
          date: '02/05/2021',
          drug: 'Panadol 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'sdfksndncam2r22'
        }
      ]

    }
  },
  methods: {
    close(value) {
      if (value) {
        this.displayActionModal = false;
        this.displayNewTransferModal = false;
        this.displaySignatureModal = false;
        this.displayPendingDestructionModal = false;
        this.displayReverseDestructionModal = false;
        this.payload.items = [];
        this.getDrugEntries.forEach(item => {
          item.display = false;
        })
      }
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir) {
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getDestructionItems', this.form)
    },
    selectItem(item, index) {
      console.log('this the selected log', item, index)
      // let refid=`expand-${index}`;
      if (this.getDrugEntries[index].display) {
        this.getDrugEntries[index].display = false;
      } else {
        this.getDrugEntries[index].display = true;
      }

    },
    destroyItems() {
      this.getDrugEntries.forEach(item => {

        if (item.display == true) {

          this.payload.items.push(item);
          console.log('destroy items function is running', this.payload.items)
        }
      })
      this.displayPendingDestructionModal = true;
    },
    reverseDestroyItems() {
      this.getDrugEntries.forEach(item => {
        if (item.display == true) {
          this.payload.items.push(item);
          console.log('reverse items function is running', this.payload.items)
        }
      })
      this.displayReverseDestructionModal = true;
    },
    isSuccessful(value) {
      if (value) {
        window.location.reload();
      }
    },
    selectDrugName(name) {
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadDestructions() {
      console.log('this is the date', this.date);
      if (this.date.length) {
        if (typeof this.date == "string") {
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else {
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
        }
        this.$store.dispatch('getDestructionItems', this.form);
      } else {
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        this.$store.dispatch('getDestructionItems', this.form);

      }
    },
    displayPage(page) {
      this.form.page = page;
      this.$store.dispatch('getDestructionItems', this.form);
    },
    clearTransfersFilters() {
      this.form = {
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters: {
          status: 'pending',
          drug: '',
          date: {
            start: '',
            end: ''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getDestructionItems', this.form);
    },
    showContent(destruction, index) {
      console.log('this the selected log', destruction)
      // let refid=`expand-${index}`;

      if (destruction.expand && (destruction.expand === true)) {
        document.getElementById(`expand-${index}`).style.display = 'none';
        destruction.expand = !destruction.expand;
      } else {
        destruction.expand = true;
        console.log('expanding range for this object');
        document.getElementById(`expand-${index}`).style.display = 'table-row';
        axios.post('/single-destruction', { uuid: destruction.uuid }, this.$store.state.header).then(res => {
          console.log('this is the state function to get a single destruction', res.data);
          destruction.expandedInfo = res.data.destruction;
          destruction.expandedInfo.drugs = res.data.destruction.units;
        })
        // destruction.expandedInfo = {
        //     note:'This is a note...',
        //     method:'Burning',
        //     courier_name: 'Fedex',
        //     courier_notes: 'Fedex notes',
        //     location:{
        //       name: "Somewhere",
        //       uuid:"230fk3-dfgdfkg2-4g34gwed",
        //       location_type:"Pharmacy",
        //       has_drug_register: true,
        //       parent: "Somewhere's parent"
        //     },
        //     drugs:[
        //         {
        //             uuid:"asidjeg-434g3ger-g23f2k3f32f",
        //             quantity:5,
        //             alias:{
        //               alias_id: "95bf12e8-d4a4-11eb-ab14-04d4c478fc6f",
        //               form: "mL",
        //               form_description: "mg/mL",
        //               measurement_unit: "each",
        //               name: "Podophyllin Compound APF (extemporaneous)",
        //               quantity: "25.0000",
        //               region: "Australia",
        //             }
        //         },
        //         {
        //             uuid:"asidjeg-434g3ger-g23f2k3f32f",
        //             quantity:5,
        //             alias:{
        //               alias_id: "95bf12e8-d4a4-11eb-ab14-04d4c478fc6f",
        //               form: "mL",
        //               form_description: "mg/mL",
        //               measurement_unit: "each",
        //               name: "Isomonit",
        //               quantity: "25.0000",
        //               region: "Australia",
        //             }
        //         },
        //     ],
        //     status: "pending",
        //     executor:{},
        //     signatures:[]
        // }

      }
    },
  },
  mounted() {
    this.loadDestructions();
    this.toCache = [];
  },
  computed: {
    getDrugEntries() {
      return this.$store.getters.getDrugEntries
    },
    numberToDestroy() {
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.display) {
            num += 1;
          }
        })

      }
      return num;

    },
    numberToReverse() {
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.display) {
            num += 1;
          }
        })
      }

      return num;
    }
  }
}
</script>


<style lang="scss">
@import "../../assets/css/main.scss";

.select-row {
  background-color: #E5F1FE; //$babyblue;
}

.pi-circle-on,
.pi-circle-off,
.pi-check-circle {
  color: #0870DC; //$strongblue;
  opacity: 50%;
  font-weight: bold;

}

.circle-on,
.circle-off,
.check-circle {

  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 20%;
  margin-left: 5px;
  border: 2px solid #0870DC; //$strongblue;
}

.circle-on {
  background-color: #0870DC; //$strongblue;
}

.status-field {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 20px;
    align-content: center; 
}

          
.dropdownDrugTable td {
  text-align: center;
  padding: 8px;
  background-color: white;
}

.dropdownDrugTable th {
  text-align: center;
  background-color: #f3f2f2;
}
          


.field-title {
    text-align: left;
    padding-left: 10px;
    border-top: 10px;
    border-bottom: 10px;
    font-weight: 500;
    margin: auto;
    
}

.field-value {
    text-align: left;
    
    padding-left: 10px;
    background-color: white;
    border-radius: 4px;
    padding: 6px 10px;

    p {
        background-color: white;
    }
}




.destroy-button {
  color: white;
  background-color: #E04F39; //$error;
  font-weight: bold;
}

.warning-button {
  color: white;
  background-color: #EF9600; //$warningborder;
  font-weight: bold;
}
</style>