<template>
            <div class="active-chart-table-container" style="display: flex; flex-wrap: wrap; margin-left: 0; background-color: white; margin-left: 1em; border-radius: 8px; width: 100%;">
                <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin-left: 0; width: 100%;">
                 <div v-if="patient && patient.personal_information && patient.regulatory_information.length" style="width: 100%">
                   <div class="admin-chart-form-container">
                   <div class="admin-chart-form" >
                        <!-- PATIENT SUMMARY HEADER -->
                        <div class="admin-chart-header">
                            <!-- PATIENT IMAGE -->
                            <div>
                                <img :src="patient.image" style="width: 350px" />
                            </div>
                            <!-- PATIENT SUMMARY INFO -->
                            <div style="width: 100%;">
                                <div style="display: flex; justify-content: space-between">
                                    <h2>{{patient.personal_information.first_name}} {{patient.personal_information.last_name}}</h2>
                                    <router-link style="text-decoration: none; margin:0" :to="'/patient/'+patient.uuid">
                                        <h4 class="strong-blue-text">View Patient Profile</h4>
                                    </router-link>
                                </div>
                                <div class="four-table-row" v-if="patient.personal_information">
                                        <div class="four-column-entry" v-if="patient.personal_information.dob">
                                            <p class="field-title">DOB</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.personal_information.dob}}</p>
                                            </div>
                                        </div>
                                        <div class="four-column-entry">
                                            <p class="field-title">Age</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.personal_information.age}}</p>
                                            </div>
                                        </div>
                                        <div class="four-column-entry">
                                            <p class="field-title">Sex</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.personal_information.sex}}</p>
                                            </div>
                                        </div>
                                        <!-- <div class="four-column-entry">
                                            <p class="field-title">Location</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.location}}.{{patient.room}}</p>
                                            </div>
                                        </div> -->
                                </div>
                                <div class="four-table-row">
                                         
                                        <div class="four-column-entry"  v-for="(item, index) in patient.regulatory_information" :key="index">
                                            <p class="field-title">{{item.regulatory_type}}</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{item.data}}</p>
                                            </div>
                                        </div>
                                        <!-- <div class="four-column-entry">
                                            <p class="field-title">Medicare No.</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.entitlement_numbers.medicare}}</p>
                                            </div>
                                        </div>
                                        <div class="four-column-entry">
                                            <p class="field-title">RAC ID</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.entitlement_numbers.rac_id}}</p>
                                            </div>
                                        </div>
                                        <div class="four-column-entry">
                                            <p class="field-title">MRN</p>
                                            <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                                <p >{{patient.entitlement_numbers.mrn}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                <!-- <div class="two-column-row">
                                    <div class="two-column-entry">
                                        <p class="field-title">Primary GP</p>
                                        <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                            <p >{{patient.primary_gp.name}}</p>
                                            <p >{{patient.primary_gp.address}}</p>
                                            <div style="display: flex; align-items: center; flex-wrap: wrap; justify-content: space-between">
                                                <p><i class="pi pi-phone"></i>{{patient.primary_gp.phone}}</p>
                                                <p><i class="pi pi-envelope"></i>{{patient.primary_gp.email}}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="two-column-entry">
                                        <p class="field-title">Pharmacy</p>
                                        <div class="field-notes grey-field" style="width: 100%; height: fit-content">
                                            <p >{{patient.pharmacy.name}}</p>
                                            <p >{{patient.pharmacy.address}}</p>
                                            <div style="display: flex; align-items: center; flex-wrap: wrap; justify-content: space-between">
                                                <p><i class="pi pi-phone"></i>{{patient.pharmacy.phone}}</p>
                                                <p><i class="pi pi-envelope"></i>{{patient.pharmacy.email}}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> -->
                                <div>
                                    <div style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 10px; overflow: scroll; max-height: 200px;">
                                        <p class="field-value"  v-for="(item, index) in patient.adr" :key="index" 
                                        style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 5px; margin-right: 5px;">
                                        {{item.drug.toUpperCase()}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                                                        
                        </div>

                        <!-- PATIENT ADMINISTRATION COMPONENTS -->
                        <div>
                            <!-- PACKED MEDICATIONS -->
                            <!-- <div class="medication-group">
                                <div class="strong-blue-background" 
                                style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                    <h2 style="margin: 0;">PACKED MEDICATIONS</h2>
                                    <div style="display: flex; align-items: center">
                                        <h4 style="margin: 0;margin-left: 3.3em; color: white;">All</h4>
                                        <i class="pi pi-check" style="margin-left: 2.3em"></i>
                                        <i class="pi pi-times" style="margin-left: 1.4em; margin-right: 1.4em;"></i>
                                    </div>
                                </div>
                                <div>
                                    <table style="border-collapse: collapse;" v-if="!patient.completed" >
                                        <tr class="packed-medication-row" v-for="(drug, i) in getPacked" :key="i">
                                        <td style="paddng: 0 10px">{{drug.alias.drug}}</td>
                                        <td style="width: 5em">x {{drug.dose}}</td>
                                        <td style="width: 10em;">{{drug.route}}</td>
                                        <td v-if="!drug.sign || (drug.status == 'approve')" @click="submitPrimeSignature(drug, 'approve')" style="width: 1em">
                                            <div ><i class="pi pi-check" :id="drug.chart_id+ '-approve'"></i></div>
                                        </td>
                                        <td v-if="!drug.sign || (drug.status == 'approve')" @click="submitPrimeSignature(drug, 'reject')"  style="width: 1em">
                                            <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                        </td>
                                        <td v-if="drug.sign && (drug.status != 'approve')" style="width: 1em" >
                                            <div style="width: 70px" class="doc-select-container">
                                                <select name="DOC" :id="drug.chart_id+'-doc-admin'" @change="selectDOC(drug)" :style="drug.status ? {backgroundColor: '#E04F39', color: 'white'}:{backgroundColor: '#E5E8EA'}"
                                                style="padding: 10px;width: 70px;border-radius: 20px;">
                                                    <option value="" disabled selected>DOC</option>
                                                    <option v-for="(reason, listIndex) in doseOmittedCode" :key="listIndex" :value="reason">{{reason}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td v-if="drug.sign && (drug.status != 'approve')"></td>
                                        <td style="width:60px" v-if="drug.sign && drug.status">
                                            <SingleVerify @submitIsCompleted="submitIsCompleted" :indices="i" :payload="drug.status != 'approve' ? {'chart_id': drug.chart_id, 'DOC': drug.status, 'administered': false } : {'chart_id': drug.chart_id, 'DOC': null, 'administered': true}" 
                                            endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                            :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                            'Patient Refused - Please notify prescriber': drug.status == 'W' ?
                                            'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                            :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" notes=true />
                                        </td>
                                        <td v-else style="width: 0em"></td>
                                        </tr>
                                    </table>
                                </div>
                                
                            </div> -->

                            <!-- S8/S4D MEDICATIONS -->
                            <!-- <div class="medication-group">
                                <div class="red-background" 
                                style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                    <h2 style="margin: 0;">S8/S4D MEDICATIONS</h2>
                                    <div style="display: flex; align-items: center" v-if="removeFromSafe.length">
                                        <h4 style="margin: 0;margin-left: 3.3em; color: white;">Remove from safe</h4>
                                            <SingleVerify @submitIsCompleted="submitIsCompleted" @click="mapRemoveFromRegisterPayload()" :payload="removeFromRegisterPayload" 
                                            endpoint="/drug-safe-retrieval" :drugSafeRemoval="true"
                                            :dual="true" :notes="true" />
                                    </div>
                                </div>
                                <div>
                                    <table style="border-collapse: collapse;" v-if="!patient.completed" >
                                        <tr class="scheduled-medication-row" v-for="(drug, i) in getS8" :key="i">
                                        <td style="paddng: 0 10px">{{drug.alias.drug}}</td>
                                        <td style="width: 5em">x {{drug.dose}}</td>
                                        <td style="width: 10em;">{{drug.route}}</td>
                                        <td v-if="drug.removed && (!drug.sign || (drug.status == 'approve'))" @click="submitPrimeSignature(drug, 'approve')" style="width: 1em">
                                            <div ><i class="pi pi-check" :id="drug.chart_id+ '-approve'"></i></div>
                                        </td>
                                        <td v-if="drug.removed && (!drug.sign || (drug.status == 'approve'))" @click="submitPrimeSignature(drug, 'reject')"  style="width: 1em">
                                            <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                        </td>
                                        <td v-if="!drug.removed" style="width: 4em">
                                        </td>
                                        <td v-if="!drug.removed" @click="()=>{updateRemovalList(drug)}"  style="width: 1em">
                                            <div ><i v-tooltip="'Select to remove from safe'" class="pi pi-sign-out" :style="drug.selectedForRemoval ? {backgroundColor: '#00AD50', color: 'white'}:{}"></i></div>
                                        </td>
                                        <td v-if="drug.sign && (drug.status != 'approve')"></td>
                                        <td v-if="drug.removed && drug.sign && (drug.status != 'approve')" style="width: 1em" >
                                            <div style="width: 70px" class="doc-select-container">
                                                <select name="DOC" :id="drug.chart_id+'-doc-admin'" @change="selectDOC(drug)" :style="drug.status ? {backgroundColor: '#E04F39', color: 'white'}:{backgroundColor: '#E5E8EA'}"
                                                style="padding: 10px;width: 70px;border-radius: 20px; margin-left: 55px;">
                                                    <option value="" disabled selected>DOC</option>
                                                    <option v-for="(reason, listIndex) in doseOmittedCode" :key="listIndex" :value="reason">{{reason}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        
                                        <td style="width:60px" v-if="drug.sign && drug.status">
                                            <SingleVerify @submitIsCompleted="submitIsCompleted" :indices="i" :payload="drug.status != 'approve' ? {'chart_id': drug.chart_id, 'DOC': drug.status, 'administered': false } : {'chart_id': drug.chart_id, 'DOC': null, 'administered': true}" 
                                            endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                            :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                            'Patient Refused - Please notify prescriber': drug.status == 'W' ?
                                            'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                            :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" notes=true />
                                        </td>
                                        <td v-else style="width: 0em"></td>
                                        </tr>
                                    </table>
                                    
                                </div>
                                
                            </div> -->

                            <!-- NON PACKED MEDICATIONS -->
                            <!-- <div class="medication-group">
                                <div class="grey-background" 
                                style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                    <h2 style="margin: 0;">NON-PACKED MEDICATIONS</h2>
                                </div>
                                <div>
                                    <table style="border-collapse: collapse;" v-if="!patient.completed" >
                                        <tr class="non-packed-medication-row" v-for="(drug, i) in getNonPacked" :key="i">
                                        <td style="paddng: 0 10px">{{drug.alias.drug}}</td>
                                        <td style="width: 5em">x {{drug.dose}}</td>
                                        <td style="width: 10em;">{{drug.route}}</td>
                                       <td v-if="!drug.sign || (drug.status == 'approve')" @click="submitPrimeSignature(drug, 'approve')" style="width: 1em">
                                            <div ><i class="pi pi-check" :id="drug.chart_id+ '-approve'"></i></div>
                                        </td>
                                        <td v-if="!drug.sign || (drug.status == 'approve')" @click="submitPrimeSignature(drug, 'reject')"  style="width: 1em">
                                            <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                        </td>
                                        <td v-if="drug.sign && (drug.status != 'approve')" style="width: 1em" >
                                            <div style="width: 70px" class="doc-select-container">
                                                <select name="DOC" :id="drug.chart_id+'-doc-admin'" @change="selectDOC(drug)" :style="drug.status ? {backgroundColor: '#E04F39', color: 'white'}:{backgroundColor: '#E5E8EA'}"
                                                style="padding: 10px;width: 70px;border-radius: 20px;">
                                                    <option value="" disabled selected>DOC</option>
                                                    <option v-for="(reason, listIndex) in doseOmittedCode" :key="listIndex" :value="reason">{{reason}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td v-if="drug.sign && (drug.status != 'approve')"></td>
                                        <td style="width:60px" v-if="drug.sign && drug.status">
                                            <SingleVerify @submitIsCompleted="submitIsCompleted" :indices="i" :payload="drug.status != 'approve' ? {'chart_id': drug.chart_id, 'DOC': drug.status, 'administered': false } : {'chart_id': drug.chart_id, 'DOC': null, 'administered': true}" 
                                            endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                            :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                            'Patient Refused - Please notify prescriber': drug.status == 'W' ?
                                            'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                            :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" notes=true />
                                        </td>
                                        <td v-else style="width: 0em"></td>
                                        </tr>
                                    </table>
                                </div>
                                
                            </div> -->

                        </div>

                      <!-- <div v-if="patient.completed" :style="patient.report == 'danger' ? {backgroundColor: '#E04F39'} : patient.report == 'warning'  ? {backgroundColor: '#EF9600'}: {backgroundColor: '#00AD50'}">
                        <div style="display: flex; align-items: center" v-for="(drug, i) in patient.error_messages" :key="i">
                            <h3 style="margin: 0; width: 50px; color: white;">{{drug.status != 'approve' ? drug.status : ''}}</h3>
                            <div v-if="drug.status">
                              <p class="completed-chart-text" v-if="drug.status == 'R'">Patient refused - Please notify prescriber</p>
                              <p class="completed-chart-text" v-if="drug.status == 'N'">Medication not available - Please notify prescriber</p>
                              <p class="completed-chart-text" v-if="drug.status == 'W'">Medication withheld for clinical reasons - Please notify prescriber</p>
                              <p class="completed-chart-text" v-if="drug.status == 'C'">Contraindicated.</p>
                              <p class="completed-chart-text" v-if="drug.status == 'A/T'">Adjusted Administration.</p>
                              <p class="completed-chart-text" v-if="drug.status == 'W/R'">Withheld pending results</p>
                              <p class="completed-chart-text" v-if="drug.status == 'S'">Sleeping</p>
                              <p class="completed-chart-text" v-if="drug.status == 'H'">Hospital</p>
                              <p class="completed-chart-text" v-if="drug.status == 'F'">Fasting</p>
                              <p class="completed-chart-text" v-if="drug.status == 'V'">Vomiting</p>
                              <p class="completed-chart-text" v-if="drug.status == 'O'">Omitted</p>
                              <p class="completed-chart-text" v-if="drug.status == 'N/A'">Not Available</p>
                              <p class="completed-chart-text" v-if="drug.status == 'S/A'">Self Administering</p>
                              <p class="completed-chart-text" v-if="drug.status == 'A'">Absent</p>
                            </div>
                            
                        </div>
                      </div> -->
                      
                   </div>
               </div>
                    
               </div>
               
              </div>
            </div>
</template>

<script>
// import SingleVerify from '@/components/verification/SingleVerify.vue';


export default{
    props:['adminCharts', 'patient'],
    components:{
        // SingleVerify,
    },
    data(){
        return{
            removeFromSafe:[],
            // removeFromRegisterPayload is the MAPPED payload of items within the removeFromSafe
            removeFromRegisterPayload:[],
            doseOmittedCode:[
              'A',
              'F',
              'H',
              'L',
              'S',
              'S/A',
              'V',
              'A/T',
              'C',
              'N',
              'N/R',
              'O',
              'R',
              'W',
              'W/R'
            ],
        }
    },
    methods:{

        mapRemoveFromRegisterPayload(){
            this.removeFromRegisterPayload=[];
            this.removeFromSafe.forEach(item=>{
                this.removeFromRegisterPayload.push({
                    prescriber_uuid:'fd458e00-6e98-4a49-abbc-6c6b3c5cd504',
                    stock_register_id:item.alias.uuid,
                    quantity:item.dose
                })
            })
        },
        selectDOC(drug){
            drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
            console.log('this is the drug doc and object', drug.status, drug);
        },
        submitPrimeSignature(drug, status){
            this.$emit('primeSignature', drug, status);
        },
        submitIsCompleted(value){
            console.log('RECEIVED THIS FROM SUBMIT IS COMPLETED', value);
            this.$emit('isCompleted', value);
            this.removeFromSafe = [];
        },
        updateRemovalList(drug){
           
            drug.selectedForRemoval = !drug.selectedForRemoval; 
            if(!drug.selectedForRemoval){
                this.removeFromSafe.splice(this.removeFromSafe.indexOf(drug), 1)
            } else{
                this.removeFromSafe.push(drug);
            }
             console.log('this is the remove from safe list', this.removeFromSafe);
            
        }
        
    },
    mounted(){
    },
    computed:{
        // getPacked(){
        //     return this.patient.current_medications.filter(item=>item.tags.includes('packed'))
        // },
        // getNonPacked(){
        //     return this.patient.current_medications.filter(item=>(!item.tags.includes('packed') && !item.tags.includes('S8')))
        // },
        // getS8(){
        //     let filtered = this.patient.current_medications.filter(item=>!item.tags.includes('packed')&&item.tags.includes('S8'));
        //     filtered.forEach(chart=>{
        //         chart.removed = false;
        //         chart.selectedForRemoval = false;
        //     });
        //     return filtered;
        // }
    }
}
</script>

<style lang="scss" scoped>

.admin-chart-header{
    display: flex;
    padding: 1em;
    padding-top: 0;
}
.grey-field{
    background-color: #f3f4f4 !important;
    border: none !important;
}

.four-table-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.four-column-entry{
    width: 22%;
    min-width: 120px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.two-column-row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.two-column-entry{
    width: 45%;
    min-width: 200px;
    margin-right: 10px;
}

.medication-group{
    margin-top: 10px;
    h2{
        color: white;
        width: fit-content;
    }
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.packed-medication-row{
     background-color: rgba(8, 112, 220, 0.2);  
     td{
         padding: 10px;
     }   
}

.scheduled-medication-row{
     background-color: rgba(224, 79, 57, 0.2);
     td{
         padding: 10px;
     }   
}

.non-packed-medication-row{
     background-color: rgba(184, 184, 184, 0.5);
     td{
         padding: 10px;
     }   
}




.pi-check, .pi-times, .pi-sign-out, .pi-lock-open{
    background-color: white;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    color: grey;
    cursor: pointer;
}


</style>