<template>
    <div>
        <div class="panel" style="margin-left: 3em">
            <div style="display: flex; margin-top: 1em;">
                <h3 class="panel-header" style="margin: 0; margin-left: 10px;">Edit Patient</h3>
                <router-link style="text-decoration: none; margin-left: 1em" :to="'/patient/' + $route.params.id">
                    <p style="font-weight: 500; color: black; margin: 5px; font-size: 1em;"> <i
                            class="pi-pencil pi"></i> Return to Patient</p>
                </router-link>
            </div>

            <div v-if="!loadingPatient" class="panel-content-container patient-form">
                <div class="patient-form-navigation">
                    <div class="patient-form-image-container">
                        <img :src="previewImage" class="uploading-image" />
                        <div v-if="!previewImage"
                            style="height: 200px; display: flex; border-radius: 8px;
                        flex-direction: column; align-items: center; justify-content: space-around;  background-color: #F3F4F4; margin-bottom: 15px;">
                            <i class="pi pi-user" style="font-size: 5em !important; color: #D8DCDC"></i>
                            <p>Patient photo to display here</p>
                        </div>
                        <label for="patient-image-upload" class="image-file-upload" style="width: 90%; margin: 0 auto;  padding: 5px 10px; border-radius: 4px; cursor: pointer; color: white;
                        background-color: #0870DC"
                            :innerHTML="previewImage ? 'Change Image' : 'Upload Patient Image'">
                            Upload Resident Image here.
                        </label>
                        <input type="file" id="patient-image-upload" accept="image/jpeg" @change=uploadImage
                            style=" display: none; ">

                    </div>
                    <div class="patient-form-navigation-items" style="margin-top: 15px;">
                        <div v-for="(item, index) in navigationItems" :key="index" class="patient-form-navigation-item">
                            <div v-if="selectedNavigation == item.link" class="selected-navigation-item"></div>
                            <div class="patient-form-navigation-label"
                                :class="[item.completed == true ? 'patient-form-navigation-label-completed' : '']"
                                @click="selectedNavigation = item.link">
                                <p style="text-align: left">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="patient-form-content">
                    <div class="patient-form-content-section" v-if="selectedNavigation == 'personal_information'">
                        <div class="personal-info-form-content">
                            <h3>Personal Information</h3>
                            <div class="form-fields">
                                <div class="form-field text-field">
                                    <label for="first_name">First Name <span style="color: red">*</span></label>
                                    <input type="text" name="first_name" v-model="personalInformation.first_name">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Last Name <span style="color: red">*</span></label>
                                    <input type="text" name="first_name" v-model="personalInformation.last_name">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Preferred Name <span style="color: red">*</span></label>
                                    <input type="text" name="first_name" v-model="personalInformation.preferred_name">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Sex <span style="color: red">*</span></label>
                                    <input type="text" name="first_name" v-model="personalInformation.sex">
                                </div>
                                <div class="form-field date-field">
                                    <label for="first_name">Date of Birth <span style="color: red">*</span> -
                                        {{ personalInformation.dob }}</label>
                                    <input type="date" name="first_name" v-model="personalInformation.dob">
                                </div>

                            </div>
                            <div style="display: flex;">
                                <button class="button blue-button" style="margin-top: 1em;"
                                    @click="editPersonalPatientInfo()">Update Personal Information</button>
                            </div>

                            <!-- END PERSONAL INFORMATION -->
                            <div class="personal-info-form-content">
                                <h3>Location</h3>
                                <p style="text-align: left; font-size: 1em;"
                                    v-if="patient.physical_location && patient.physical_location.location">Current
                                    Location: <b>{{ patient.physical_location.location.name }}</b></p>
                                <div class="form-fields">
                                    <div class="form-field wide-text-field" v-if="facilities && facilities.length">
                                        <label for="first_name">Facility</label>
                                        <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
                                            @change="retrieveSelectedFacilityLocations()"
                                            :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" />
                                    </div>
                                    <div class="form-field wide-text-field" v-if="nodes && nodes.length">
                                        <label for="first_name" >Location</label>
                                        <div v-if="loadingLocations">
                                            <i class="pi pi-spin pi-spinner"
                                                style="font-size: 2rem !important;margin-top: 10px;"></i>
                                        </div>
                                        <TreeSelect v-model="selectedNodeKey" :options="nodes"
                                            ariaLabelledBy="name" modelValue="key" placeholder="Select Item" />

                                    </div>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <button class="button blue-button" style="margin-top: 1em;"
                                    @click="confirmFacilityLocation()">Update Location</button>
                            </div>
                        </div>

                        <div class="contact-info-form-content">
                            <h3>Contact Details</h3>
                            <div class="form-fields">

                                <div class="form-field text-field">
                                    <label for="first_name">Address</label>
                                    <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                                    <div class="form-field text-field">
                                        <vue-google-autocomplete id="contactmap" classname="form-control"
                                            :placeholder="patient.address" v-on:placechanged="getPersonalAddressData"
                                            :country="$store.state.country">
                                        </vue-google-autocomplete>
                                    </div>
                                </div>

                                <div class="form-field text-field">
                                    <label for="first_name">Phone</label>
                                    <input type="text" name="first_name" v-model="untitled.phone">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Email</label>
                                    <input type="text" name="first_name" v-model="untitled.email">
                                </div>
                            </div>
                        </div>
                        <div style="display: flex">
                            <button class="button blue-button" style="margin-top: 1em;"
                                @click="editContactDetails()">Update Contact Details</button>
                        </div>
                        <div class="contact-info-form-content">
                            <h3>Next of Kin Details</h3>
                            <div class="form-fields">
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Name</label>
                                    <input type="text" name="first_name" v-model="kin.name">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Relationship</label>
                                    <input type="text" name="first_name" v-model="kin.relationship">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Phone</label>
                                    <input type="text" name="first_name" v-model="kin.phone">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Next of Kin - Email</label>
                                    <input type="text" name="first_name" v-model="kin.email">
                                </div>
                            </div>
                        </div>
                        <div style="display: flex">
                            <button class="button blue-button" style="margin-top: 1em;" @click="editNextOfKin()">Update
                                Next of Kin</button>
                        </div>
                        <div class="contact-info-form-content">
                            <h3>Entitlement & Regulatory Numbers</h3>
                            <div class="form-fields">
                                <div class="form-field text-field" v-for="(item, index) in regulatory_numbers"
                                    :key="index">
                                    <label for="first_name">{{ item.regulatory_type }}</label>
                                    <input type="text" name="first_name" :placeholder="item.data" v-model="item.data">
                                </div>
                            </div>
                        </div>
                        <div style="display: flex">
                            <button class="button blue-button" style="margin-top: 1em;"
                                @click="editEntitlementNumbers()">Update Entitlement & Regulatory Numbers</button>
                        </div>
                    </div>

                    <div class="patient-form-content-section" v-if="selectedNavigation == 'prescriber_details'">
                        <div class="prescriber-info-form-content" style="display: grid;" v-if="prescribers && prescribers.length">
                            <h3>Primary General Practitioner Details</h3>
                            <p v-if="selectedPrescriber" style="font-size: 1em; text-align: left;">Current Prescriber:
                                <b>{{ selectedPrescriber.name }}</b></p>
                            <p v-if="!selectedPrescriber" style="font-size: 1em; text-align: left; margin: 0;">Select
                                prescriber to link to patient:</p>
                            <p v-else style="font-size: 1em; text-align: left; margin: 0;">Change prescriber:</p>
                            <Dropdown v-model="selectedPrescriber" :options="prescribers" optionLabel="name"
                                placeholder="Select Prescriber" style="width: fit-content; margin-top: 10px;" />

                        </div>
                        <div style="display: flex;" v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">
                            <button class="button blue-button" style="margin-top: 1em;"
                                @click="submitPrescriber()">Update GP</button>
                        </div>
                        <!-- PHARMACY LINK -->
                        <!-- PHARMACY LINK -->
                        <!-- PHARMACY LINK -->
                        <div class="prescriber-info-form-content" style="display: grid; margin-top: 1em;" v-if="pharmacies && pharmacies.length">
                            <h3>Pharmacy Details</h3>
                            <p v-if="selectedPharmacy" style="font-size: 1em; text-align: left;">Current Pharmacy:
                                <b>{{ selectedPharmacy.name }}</b></p>
                            <p v-if="!selectedPharmacy" style="font-size: 1em; text-align: left; margin: 0;">Select
                                pharmacy to link to resident:</p>
                            <p v-else style="font-size: 1em; text-align: left; margin: 0;">Change pharmacy:</p>
                            <Dropdown v-model="selectedPharmacy" :options="pharmacies" optionLabel="name"
                                placeholder="Select Pharmacy" style="width: fit-content; margin-top: 10px;" />
                        </div>

                        <div style="display: flex;">
                            <button class="button blue-button" style="margin-top: 1em;" @click="submitPharmacy()" 
                            v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">Update
                                Pharmacy</button>
                        </div>
                        <!-- PHARMACY LINK -->
                        <!-- PHARMACY LINK -->
                        <!-- PHARMACY LINK -->
                        <div class="prescriber-info-form-content" v-for="(prescriber, pi) in untitled.prescribers"
                            :key="pi">
                            <h3>Prescriber Details (not Primary GP)</h3>
                            <!-- ALGOLIA PRESCRIBER SEARCH -->
                            <!-- ALGOLIA PRESCRIBER SEARCH -->
                            <!-- ALGOLIA PRESCRIBER SEARCH -->
                            <div style="display: flex; align-items: center;">
                                <div class="text-search-container search-container text-field"
                                    style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px; position: relative;">
                                    <label for="first_name" style="text-align: left; padding-left: 10px">Search by
                                        Prescriber Number</label>
                                    <input v-model="nonGP" v-on:keyup="gpSearch(nonGP)"
                                        placeholder="Search Prescriber No."
                                        style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                    <div class="drug-search-result-container" v-if="nonGP"
                                        style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                        <p class="drug-search-result"
                                            style="text-align: left; padding-left: 20px; cursor:pointer"
                                            v-for="(item, index) in prescriberResults" :key="index"
                                            @click="selectPrescriber(item)">{{ item.prescriber_number }}</p>
                                    </div>
                                </div>
                                <p style="text-align: left; margin-left: 20px; cursor:pointer; width: fit-content; margin: 0; margin-top: 20px;
                                        border-bottom: 1px solid grey; " v-if="prescriber.uuid"
                                    @click="clearPrescriber">Clear Selected Prescriber</p>
                            </div>
                            <!-- ALGOLIA PRESCRIBER SEARCH -->
                            <!-- ALGOLIA PRESCRIBER SEARCH -->
                            <!-- ALGOLIA PRESCRIBER SEARCH -->
                            <div class="form-fields">
                                <div class="form-field text-field">
                                    <label for="first_name">Prescriber Number</label>
                                    <input v-if="prescriber.uuid" type="text" v-model="prescriber.prescriber_number"
                                        disabled>
                                    <input v-else type="text" name="first_name" v-model="prescriber.prescriber_number">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Name</label>
                                    <input v-if="prescriber.uuid" type="text"
                                        :value="prescriber.first_name + ' ' + prescriber.last_name" disabled>
                                    <input v-else type="text" name="first_name" v-model="prescriber.name">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Phone</label>
                                    <input v-if="prescriber.uuid" type="text" v-model="prescriber.phone" disabled>
                                    <input v-else type="text" name="first_name" v-model="prescriber.phone">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">After Hours Phone</label>
                                    <input v-if="prescriber.uuid" type="text" v-model="prescriber.after_hours" disabled>
                                    <input v-else type="text" name="first_name" v-model="prescriber.after_hours">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Address</label>
                                    <vue-google-autocomplete v-if="!prescriber.uuid" id="prescriberaddress"
                                        classname="form-control" placeholder=""
                                        v-on:placechanged="getAddressData('prescriber')"
                                        :country="$store.state.country">
                                    </vue-google-autocomplete>
                                    <input v-else type="text" v-model="prescriber.address" disabled>
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Email</label>
                                    <input v-if="prescriber.uuid" type="text" v-model="prescriber.email" disabled>
                                    <input v-else type="text" name="first_name" v-model="prescriber.email">
                                </div>
                                <div class="form-field text-field">
                                    <label for="first_name">Fax Number</label>
                                    <input v-if="prescriber.uuid" type="text" v-model="prescriber.fax" disabled>
                                    <input v-else type="text" name="first_name" v-model="prescriber.fax">
                                </div>
                            </div>
                            <div style="display: flex;">
                                <button class="button blue-button" style="margin-top: 1em;"
                                    @click="removeAndReplaceGP()">Update Prescriber</button>
                            </div>
                        </div>

                    </div>

                    <div class="patient-form-content-section" v-if="selectedNavigation == 'allergies'">
                        <div class="adr-info-form-content">
                            <h3>Allergies & Adverse Drug Reactions</h3>
                            <p style="text-align: left;">Enter any applicable allergies/drugs below:</p>
                            <div style="max-height: 38em; overflow-y: scroll;">
                                <table>
                                    <tr>
                                        <th>Drug Name or Substance</th>
                                        <th style="width: 30em">Reaction Description</th>
                                        <th>Type of Reaction</th>
                                        <th>Date Reaction Last Occured</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(item, index) in untitled.adr" :key="index">
                                        <td><input type="text" v-model="item.drug" style="width: 95%;"></td>
                                        <td><input type="text" v-model="item.reaction_description" style="width: 95%;">
                                        </td>
                                        <td><input type="text" v-model="item.reaction_type" style="width: 95%;"></td>
                                        <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                        <td><i @click="removeADR(index)"
                                                style="cursor: pointer; font-size: 1.2em !important"
                                                class='pi pi-trash'></i></td>
                                    </tr>
                                </table>
                            </div>
                            <div class="button outline-button" @click="addNewADR">
                                <p>Add Another ADR &nbsp; &nbsp; &nbsp; <span style="font-size: 1.2em;">+</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="patient-form-content-section" v-if="selectedNavigation == 'considerations'">
                        <h3>Special Considerations</h3>
                        <div class="considerations-info-form-content"
                            style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; width: 80%">
                            <div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Medication Method</h4>
                                    <div class="special-considerations-form">
                                        <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item" v-for="(item, index) in medication_methods"
                                                :key="index">
                                                <input type="checkbox" name="inactive" @change="saveSelectedItem(item)"
                                                    :checked="untitled.special_considerations.includes(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Medication Administration</h4>
                                    <div class="special-considerations-form">
                                        <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item"
                                                v-for="(item, index) in medication_administration" :key="index">
                                                <input type="checkbox" name="inactive" @change="saveSelectedItem(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Patient Considerations</h4>
                                    <div class="special-considerations-form">
                                        <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item" v-for="(item, index) in patient_considerations"
                                                :key="index">
                                                <input type="checkbox" name="inactive" @change="saveSelectedItem(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500;">Medication Delivery</h4>
                                    <div class="special-considerations-form">
                                        <!-- <Chip v-for="(item, index) in medication_methods" :key="index" :label="item" class="p-mb-2 blue-chip" removable /> -->
                                        <div class="spec-cons-items">
                                            <div class="spec-cons-item" v-for="(item, index) in medication_delivery"
                                                :key="index">
                                                <input type="checkbox" name="inactive"
                                                    @change="cacheSelectedItem(item)">
                                                <label for="inactive">{{ item }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="special-considerations-form-container">
                                    <h4 style="text-align: left;font-weight: 500; margin-bottom: 0;">Specific
                                        Instructions/Additional Comments</h4>
                                    <p style="margin-top: 0; text-align: left">Note here if other medication
                                        methods/administration/delivery or patient considerations.</p>
                                    <div class="special-considerations-form">
                                        <textarea name="spec-cons-notes" id="spec-cons-notes"
                                            style="max-width: 95%; box-sizing: border-box" cols="60"
                                            rows="10"></textarea>
                                    </div>
                                </div>
                            </div>


                            <div>

                            </div>

                        </div>
                    </div>

                    <div class="patient-form-content-section" v-if="selectedNavigation == 'vaccinations'">
                        <div class="vac-weight-info-form-content">
                            <h3>Vaccination History</h3>
                            <div class="vac-weight-table">
                                <table>
                                    <tr>
                                        <th>Vaccine</th>
                                        <th>Date Last Given</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(item, index) in untitled.vaccinations" :key="index">
                                        <td><input type="text" v-model="item.vaccine" style="width: 95%;"></td>
                                        <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                        <td><i @click="removeVaccine(index)"
                                                style="cursor: pointer; font-size: 1.2em !important"
                                                class='pi pi-trash'></i></td>
                                    </tr>
                                </table>
                            </div>

                            <div class="button outline-button" @click="addNewVaccine">
                                <p>Add Another Vaccination Record &nbsp; &nbsp; &nbsp; <span
                                        style="font-size: 1.2em;">+</span></p>
                            </div>
                        </div>
                        <div class="vac-weight-info-form-content">
                            <h3>Weights</h3>
                            <div class="vac-weight-table">
                                <table>
                                    <tr>
                                        <th>Weights</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(item, index) in untitled.weights" :key="index">
                                        <td><input type="text" v-model="item.weight" style="width: 95%;"></td>
                                        <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                        <td><i @click="removeWeight(index)"
                                                style="cursor: pointer; font-size: 1.2em !important"
                                                class='pi pi-trash'></i></td>
                                    </tr>
                                </table>
                            </div>
                            <div class="button outline-button" @click="addNewWeight">
                                <p>Add Another Weight Record &nbsp; &nbsp; &nbsp; <span
                                        style="font-size: 1.2em;">+</span></p>
                            </div>
                        </div>
                    </div>

                    <!-- FORM BUTTONS -->
                    <div style="display:flex; align-items: center; justify-content: flex-end; align-self: flex-end">
                        <button class="button" @click="nextFormPage" v-if="selectedNavigation != 'prescriber_details'"
                            style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white;">Next</button>
                    </div>
                </div>
            </div>
            <div v-else class="panel-content-container patient-form" style="display: flex">
                <div style='width: 100%; margin-top: 5em;'>
                    <p style="font-size: 1em; font-weight: bold;">Loading Patient...</p>
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                </div>
            </div>
        </div>
        <SuccessModal v-if="displaySuccessModal" @close="close" :loading="loading" :statusMessage="statusMessage"
            :first_spec_line="first_spec_line" />
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';

export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            loadingPatient: false,
            loadingLocations: false,
            selectedFacility: '',
            facilities: [],
            nodes: [],
            selectedNodeKey: '',
            statusMessage: 'Updating Patient',
            displaySuccessModal: false,
            loading: false,
            first_spec_line: '',
            somelocation: '',
            selectedNavigation: 'personal_information',
            gp: '',
            nonGP: '',
            patientUUID: '',
            pharmacySearchInput: '',
            validForm: false,
            prescriberResults: [],
            pharmacyResults: [],
            navigationItems: [
                {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Details',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Entitlement Numbers',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Prescriber and Pharmacy Details',
                    link: 'prescriber_details',
                    completed: false,
                },
                // {
                //     name: 'Allergies & Adverse Drug Reactions',
                //     link:'allergies',
                //     completed:false,
                // },
                // {
                //     name: 'Special Considerations',
                //     link:'considerations',
                //     completed:false,
                // },
                // {
                //     name: 'Vaccinations and Weights',
                //     link:'vaccinations',
                //     completed:false,
                // },
                // {
                //     name: 'Diabetes Action Plan',
                //     link:'complex',
                //     completed:false,
                // },
                // {
                //     name: 'Additional Comments',
                //     link:'additional',
                //     completed:false,
                // },
                // {
                //     name: 'New Patient Sign Off',
                //     link:'sign_off',
                //     completed:false,
                // },
            ],
            adrForm: {
                drug: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            },
            personalInformation: {
                first_name: '',
                last_name: '',
                preferred_name: '',
                sex: '',
                dob: '',
                tag: [],
            },
            regulatory_numbers: [
            ],
            address: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            facility_location: {
                room_uuid: '',
            },
            kin: {
                first_name: '',
                last_name: '',
                relationship: '',
                phone: '',
                email: ''
            },
            untitled: {
                commencement_date: '',
                expiry_date: '',
                phone: '',
                phone_uuid: '',
                email: '',
                email_uuid: '',
                gp: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: true
                },
                pharmacy: {
                    name: '',
                    address: '',
                    contact: '',
                    phone: '',
                    email: '',
                    fax: '',
                    uuid: ''
                },
                prescriber: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: false
                },
                prescribers: [],
                adr: [{
                    drug: '',
                    reaction_description: '',
                    reaction_type: '',
                    date: ''
                }
                ],
                special_considerations: [],
                special_considerations_notes: '',
                vaccinations: [
                    {
                        vaccine: '',
                        date: ''
                    }
                ],
                weights: [
                    {
                        weight: '',
                        date: ''
                    }
                ]
            },
            // SPECIAL CONSIDERATION OPTIONS
            medication_methods: [
                'Whole',
                'Quartered',
                'Dissolved',
                'Halved',
                'Capsule Opened',
                'Crushed and Mixed with...',
            ],
            medication_administration: [
                'Water',
                'Thickened Fluids',
            ],
            patient_considerations: [
                'Swallowing Difficulty',
                'Cognitive Impairment',
                'Resistive to Medication',
                'Self Administers',
                'Crush Medications',
                'Dexterity Issues',
                'Nil by Mouth',
            ],
            medication_delivery: [
                'Teaspoon - Staff Guide',
                'Medication Cup',
                'Resident/Patient Hand',
                'PEG Administration',
            ],
            // LOCATION DETAILS
            parentLocation: '',
            locationName: [],
            childLocations: [],
            previewImage: null,
            originalGPID: '',
            patient: {

            },
            pharmacies: [],
            selectedPharmacy: '',
            prescribers: [],
            selectedPrescriber: ''
        }
    },
    methods: {
        retrieveSelectedFacilityLocations() {
            this.loadingLocations = true;
            this.locationDirectory = '';
            console.log('this is the selected facility', this.selectedFacility);
            axios.post(`/facility/${this.selectedFacility.value}/locations`, {}, this.$store.state.header).then(res => {
                console.log('these are the facilitys locations', res.data);
                this.nodes = res.data;
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
                this.loadingLocations = false;
            })
        },
        getFacilities() {
            axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
                this.facilities = res.data;
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        getPharmacies() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/pharmacies`, {}, this.$store.state.header).then(res => {
                console.log('these are the pharmacies', res.data);
                this.pharmacies = res.data;
            })
        },

        submitPharmacy() {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            axios.post(`/person/${this.patientUUID}/link-pharmacy`, { pharmacy_id: this.selectedPharmacy.uuid }, this.$store.state.header).then(res => {
                console.log('patient pharmacy submitted.', res);
                this.first_spec_line = 'Patient updated!';
            })
        },
        getPrescribers() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/prescribers`, {}, this.$store.state.header).then(res => {
                console.log('these are the prescribers', res.data);
                this.prescribers = [];
                res.data.forEach(item=>{
                    if(item.name && item.name != ' '){
                        this.prescribers.push(item);
                    }
                })
                
                this.prescribers = res.data.filter((v, i, a) => a.indexOf(v) === i);
                // this.prescribers = res.data;
            })
        },
        submitPrescriber() {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            axios.post(`/person/${this.patientUUID}/link-prescriber`, { prescriber_id: this.selectedPrescriber.uuid, primary: true }, this.$store.state.header).then(res => {
                console.log('patient prescriber submitted.', res);
                this.first_spec_line = 'Patient updated!';
            })
        },
        // UPLOAD IMAGE
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                console.log(this.previewImage);
            };
        },
        nextFormPage() {
            if (this.selectedNavigation == 'personal_information') {
                console.log('hitting the if, personal_information')
                this.selectedNavigation = this.navigationItems[3].link;
            } else {

                switch (this.selectedNavigation) {
                    case 'prescriber_details':
                        this.selectedNavigation = this.navigationItems[4].link;
                        break;
                    case 'allergies':
                        this.selectedNavigation = this.navigationItems[5].link;
                        break;
                    case 'considerations':
                        this.selectedNavigation = this.navigationItems[6].link;
                        break;
                    case 'vaccinations':
                        this.selectedNavigation = this.navigationItems[7].link;
                        break;
                    case 'complex':
                        break;

                }
            }
        },
        // FORM SUBMIT PROCESS

        // REGISTER PATIENT
        editPersonalPatientInfo() {
            this.loading = true;
            this.displaySuccessModal = true;
            this.personalInformation.dob = new Date(this.personalInformation.dob);
            this.personalInformation.dob = this.personalInformation.dob.toLocaleDateString();
            axios.post('/person/' + this.$route.params.id + '/edit', this.personalInformation, this.$store.state.header).then(res => {
                console.log('patient registered.', res);
                this.loading = false;
                this.first_spec_line = 'Successfully updated!';
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
                this.first_spec_line = '';
            }
        },
        editNextOfKin() {
            this.loading = true;
            this.displaySuccessModal = true;
            console.log('this is the kin', this.kin);
            axios.post(`/patient/${this.patientUUID}/kin/${this.kin.uuid ? this.kin.uuid : ''}`, this.kin, this.$store.state.header).then(res => {
                console.log('kin has been updated', res.data);
                this.loading = false;
                this.first_spec_line = 'Successfully updated!';
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        editEntitlementNumbers() {
            this.loading = true;
            this.displaySuccessModal = true;
            let payload = [];
            this.regulatory_numbers.forEach(num => {
                if (num.uuid) {
                    axios.post(`/person/${this.patientUUID}/remove-regulatory/${num.uuid}`, {}, this.$store.state.header).then(res => {
                        console.log(res);
                    })
                }
                if (num.data) {
                    payload.push(num);
                }
                delete num.uuid;
                delete num.number;
            })
            axios.post(`/person/${this.patientUUID}/regulatory`, { regulatory_numbers: payload }, this.$store.state.header).then(res => {
                console.log('updated ent numbers', res);
                this.loading = false;
                this.first_spec_line = 'Successfully updated!';
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        // SUBMIT IMAGE
        submitImage() {
            if (this.previewImage) {
                axios.post(`/patient/${this.patientUUID}/image`, {
                    image: this.previewImage,
                }, this.$store.state.header).then(res => {
                    console.log('uploaded patient image', res);
                })
            } else {
                console.log('No image has been uploaded');
            }

        },
        // CONFIRM FACILITY LOCATION
        // confirmFacilityLocation(){
        //     if(this.facility_location.room_uuid){
        //         axios.post(`/patient/${this.patientUUID}/move-location/${this.facility_location.room_uuid}`, {}, this.$store.state.header).then(res=>{
        //             console.log('facility location submitted.', res)
        //         })
        //     } else{
        //         console.log('no facility location confirmed.')
        //     }
        // },
        // CONFIRM FACILITY LOCATION
        confirmFacilityLocation() {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            if (Object.keys(this.selectedNodeKey)[0]) {
                axios.post(`/person/${this.patientUUID}/location`, { status: 'resident', location: Object.keys(this.selectedNodeKey)[0] }, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    console.log('facility location submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                }).catch(err => {
                    this.completedActions += 1;
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error assigning resident to location.'
                    console.log('this is the link resident error', err, err.response.data);
                    this.first_spec_line = 'Error: ' + err.response.data;
                })
            } else {
                console.log('no facility location confirmed.');
            }
        },
        // REGULATORY INFO
        submitRegulatoryInfo() {
            let regulatory_numbers = [];
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            for (let key in this.regulatory_numbers) {
                console.log('this is the key', key);
                if (this.regulatory_numbers[key].number) {
                    regulatory_numbers.push({
                        number: this.regulatory_numbers[key].number,
                        regulator_type: this.regulatory_numbers[key].regulator_type,
                        date: this.regulatory_numbers[key].expiry ? this.regulatory_numbers[key].expiry : ''
                    })
                }

            }
            if (this.regulatory_numbers.length) {
                axios.post(`/patient/${this.patientUUID}/regulatory`, regulatory_numbers, this.$store.state.header).then(res => {
                    console.log('regulatory information submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                })
            } else {
                console.log('no regulatory numbers filled in or submitted.')
            }


        },
        // PATIENT ADDRESS
        setPatientAddress() {
            if (this.address.street_address) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/address`, this.address, this.$store.state.header).then(res => {
                    console.log('patient address submitted.', res);
                    this.first_spec_line = 'Patient address updated!';
                })
            } else {
                console.log('no address has been added or submitted');
            }
        },
        editContactMethods() {
            if (this.untitled.phone_uuid) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/contact-method/${this.untitled.phone_uuid}/edit`, { data: this.untitled.phone }, this.$store.state.header).then(res => {
                    console.log('phone has been updated', res.d);
                    this.first_spec_line = 'Patient phone number updated!';
                })
            } else {
                if (this.untitled.phone) {
                    let phone = {
                        name: 'Phone',
                        data: this.untitled.phone,
                        type: 'phone'
                    };
                    this.displaySuccessModal = true;
                    this.first_spec_line = 'Updating patient...';
                    axios.post(`/person/${this.patientUUID}/contact-method`, phone, this.$store.state.header).then(res => {
                        console.log('contact method has been saved (new)', res);
                        this.first_spec_line = 'Patient phone number updated!';
                    })
                }
            }

            if (this.untitled.email_uuid) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/contact-method/${this.untitled.email_uuid}/edit`, { data: this.untitled.email }, this.$store.state.header).then(res => {
                    console.log('phone has been updated', res.data);
                    this.first_spec_line = 'Patient email updated!';
                })
            } else {
                let email = {
                    name: 'Email',
                    data: this.untitled.email,
                    type: 'email'
                };
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/contact-method`, email, this.$store.state.header).then(res => {
                    console.log('contact method has been saved (new)', res);
                    this.first_spec_line = 'Patient email updated!';
                })
            }

        },
        editContactDetails() {
            this.setPatientAddress();
            this.editContactMethods();
        },
        // CREATE KIN
        createKin() {
            if (this.kin.first_name) {
                let names = this.kin.split(' ');
                this.kin.first_name = names.shift();
                this.kin.last_name = names.join(' ');
                axios.post(`/patient/${this.patientUUID}/kin`, this.kin, this.$store.state.header).then(res => {
                    console.log('patient kin submitted.', res)
                })
            } else {
                console.log('first name of kin not entered, so submission not made for kin')
            }

        },
        // ADD PHONE AND EMAIL CONTACT METHODS
        addContactMethods() {
            let contactMethods = [];
            if (this.untitled.phone) {
                contactMethods.push({
                    name: 'Phone',
                    data: this.untitled.phone,
                    type: 'phone'
                });
            }
            if (this.untitled.email) {
                contactMethods.push({
                    name: 'Email',
                    data: this.untitled.email,
                    type: 'email'
                });
            }
            if (this.contactMethods.length) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/patient/${this.patientUUID}/contact-method`, { contact_methods: contactMethods }, this.$store.state.header).then(res => {
                    console.log('patient kin submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                })
            } else {
                console.log('no contact methods were entered, therefore not submitted.')
            }

        },
        // SUBMIT ADR ARRAY
        submitADR() {
            if (this.untitled.adr[0].drug != '') {
                axios.post(`/patient/${this.patientUUID}/adr`, this.untitled.adr, this.$store.state.header).then(res => {
                    console.log(' ADR submitted.', res)
                })
            } else {
                console.log('no adr to submit')
            }
        },
        // SUBMIT SPECIAL CONSIDERATIONS
        submitSpecCons() {
            let specCons = {
                special_considerations: this.untitled.special_considerations,
                comments: this.untitled.special_considerations_notes
            }
            if (specCons.special_considerations.length || specCons.comments) {
                axios.post(`/patient/${this.patientUUID}/special-considerations`, specCons, this.$store.state.header).then(res => {
                    console.log('spec cons submitted.', res)
                })
            } else {
                console.log('no special cons or notes made, not submitted.')
            }

        },
        // SUBMIT VAX
        submitVx() {
            if (this.untitled.vaccinations[0].vaccine != '') {
                axios.post(`/patient/${this.patientUUID}/vaccinations`, this.untitled.vaccinations, this.$store.state.header).then(res => {
                    console.log('vaccinations submitted.', res)
                })
            } else {
                console.log('no vaccinations recorded')
            }
        },
        // SUBMIT WEIGHTS
        submitWeights() {
            if (this.untitled.weights[0].weight != '') {
                axios.post(`/patient/${this.patientUUID}/weights`, this.untitled.weights, this.$store.state.header).then(res => {
                    console.log('weights submitted.', res)
                })
            } else {
                console.log('no weights recorded')
            }
        },
        submitForm() {
            // this.patientRegistration();
            this.submitImage();
            this.submitRegulatoryInfo();
            this.setPatientAddress();
            this.addContactMethods();
            this.submitPrescribers();
            this.submitPharmacy();
            this.submitADR();
            this.submitSpecCons();
            this.submitVx();
            this.submitWeights();
        },
        getLocations() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/locations`, {}, this.$store.state.header).then(res => {
                console.log('this is the facility location', res);
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
            })
        },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        addNewADR() {
            let newEntry = {
                drug: '',
                reaction_description: '',
                reaction_type: '',
                date: ''
            };
            this.untitled.adr.unshift(newEntry);
        },
        removeADR(index) {
            this.untitled.adr.splice(index, 1);
        },
        addNewVaccine() {
            let newVaccine = {
                vaccine: '',
                date: ''
            };
            this.untitled.vaccinations.unshift(newVaccine);
        },
        removeVaccine(index) {
            this.untitled.vaccinations.splice(index, 1);
        },
        addNewWeight() {
            let newWeight = {
                weight: '',
                date: ''
            };
            this.untitled.weights.unshift(newWeight);
        },
        removeWeight(index) {
            this.untitled.weights.splice(index, 1);
        },
        saveSelectedItem(item) {
            console.log('cacheSelectedItem is working', item);
            if (this.untitled.special_considerations.includes(item)) {
                console.log('this item exists in array', this.untitled.special_considerations);
                this.untitled.special_considerations.forEach((alreadySavedItem, index) => {
                    if (item == alreadySavedItem) {
                        this.untitled.special_considerations.splice(index, 1);
                    }
                })
            } else {
                console.log('this item does not exist in array', this.untitled.special_considerations);
                this.untitled.special_considerations.push(item);
            }
        },
        getPersonalAddressData(addressData, propertyData, id) {

            this.address.state = addressData.administrative_area_level_1;
            this.address.city = addressData.administrative_area_level_2;
            this.address.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.address.country = addressData.country;
            this.address.postcode = addressData.postal_code;
            this.address.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.address);
        },
        clearGP() {
            this.untitled.gp = {};
            this.gp = '';
        },
        getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
    },
    mounted() {
        this.getPharmacies();
        this.getFacilities();
        this.getPrescribers();
        this.patientUUID = this.$route.params.id;
        this.loadingPatient = true;
        axios.post('/retrieve-patient', { patient_id: this.patientUUID, location: this.$store.state.currentLocation.uuid }, this.$store.state.header).then(res => {
            this.patient = res.data;
            this.loadingPatient = false;
            this.patient.personal_information.age = this.getAge(this.patient.personal_information.dob);
            this.patient.personal_information.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU';
            console.log('this is the res data for retrieve patient', res.data, this.patient);
        }).then(() => {
            // IMAGE
            this.previewImage = this.patient.personal_information.image;
            // PERSONAL INFORMATION
            this.personalInformation = {
                first_name: this.patient.personal_information.first_name,
                last_name: this.patient.personal_information.last_name,
                preferred_name: this.patient.personal_information.preferred_name,
                sex: this.patient.personal_information.sex,
                dob: this.patient.personal_information.dob,
            }
            // PRESCRIBERS
            if (this.patient.prescribers.length) {
                this.selectedPrescriber = this.patient.prescribers[0]
                this.selectedPrescriber.name = `${this.patient.prescribers[0].personal_information.first_name} ${this.patient.prescribers[0].personal_information.last_name}`
            }
            if (this.patient.pharmacy) {
                this.selectedPharmacy = this.patient.pharmacy
            }

            // this.patient.prescribers.forEach(prescriber=>{
            // if(prescriber.primary_gp == false){
            //     this.untitled.prescribers.push(prescriber)
            // } else{
            //     this.untitled.gp = prescriber;
            //     if(this.untitled.gp.contact_methods.length){
            //         this.untitled.gp.contact_methods.forEach(method=>{
            //             if(method.type == 'phone'){
            //                 this.untitled.gp.phone = method.data
            //             }
            //             if(method.type == 'email'){
            //                 this.untitled.gp.email = method.data;
            //             }
            //             if(method.type == 'fax'){
            //                 this.untitled.gp.fax = method.data;
            //             }
            //             if(method.type == 'after_hours'){
            //                 this.untitled.gp.after_hours = method.data;
            //             }
            //         })
            //     }
            //     this.originalGPID = prescriber.uuid;
            //     console.log('THIS IS THE UNTITLED GP', this.untitled.gp);
            // }
            // })
            // PHARMACY
            // this.untitled.pharmacy = this.patient.pharmacy;
            // NEXT OF KIN
            if (this.patient.emergency.length) {
                this.kin = this.patient.emergency[0];
                this.kin.name = this.patient.emergency[0].first_name + ' ' + this.patient.emergency[0].last_name;
            }

            // CONTACT METHODS
            this.patient.contact_methods.forEach(method => {
                if (method.type == 'phone') {
                    this.untitled.phone = method.data;
                    this.untitled.phone_uuid = method.uuid;
                }
                if (method.type == 'email') {
                    this.untitled.email = method.data;
                    this.untitled.email_uuid = method.uuid;
                }
            })
            // this.untitled.phone = this.patient.contact_methods.phone[0].data;
            // this.untitled.email = this.patient.contact_methods.email[0].data;

            // ENTITLEMENT/REGULATORY NUMBERS
            // , 'Medicare Expiry'
            let reg_types = ['IHI Number', 'RAC ID', 'URN/MRN Number', 'Pharmaceutical Entitlement Number', 'Medicare Number', 'Safety Net Number', 'Safety Net Number 2', 'DVA Number', 'Concession Number']
            let included_reg = [];
            this.patient.regulatory_information.forEach(ent_number => {
                if (reg_types.includes(ent_number.regulatory_type)) {
                    this.regulatory_numbers.push(ent_number);
                    included_reg.push(ent_number.regulatory_type);
                    console.log('this is what is included so far', included_reg);
                }
            })
            let not_included = reg_types.filter(x => !included_reg.includes(x));
            console.log('this is what was not included yet', not_included);
            not_included.forEach(excl => {
                this.regulatory_numbers.push(
                    {
                        regulatory_type: excl,
                        expiry: '',
                        data: ''
                    }
                )
            })
        }).catch(err => {
            console.log('this is the err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                this.$router.push('/login');
            }
        })



    },
    computed() {

    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.uploading-image {
    max-height: 200px;
    max-width: 100%;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 8px;
}

.patient-form-image-container {
    display: grid;
    align-items: center;
    grid-gap: 10px;
    height: 280px;
    margin-bottom: 10px;
}

.patient-form {
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.patient-form-navigation {
    border-right: 2px solid $grey;
    padding-right: 20px;
}

.patient-form-content-section {
    h3 {
        margin-bottom: 0;
        font-size: 1.3em;
    }
}

.patient-form-navigation-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.patient-form-navigation-label {
    padding: 0 10px;
    background-color: $grey;
    border: 3px solid $bordergrey;
    border-radius: 8px;
    margin-bottom: 5px;
    cursor: pointer;
    width: 100%;

    p {
        color: rgba(0, 0, 0, 0.5);
        font-weight: bold;
        font-size: 0.95em;
    }
}

.patient-form-navigation-label-completed {
    border: 3px solid $correctborder;
    background-color: $correct;

    p {
        color: white;
    }
}

.selected-navigation-item {
    width: 5px;
    height: 45px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    background-color: $strongblue;
}

.patient-form-content {
    padding: 10px;

    h3 {
        text-align: left;
    }

    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: space-between;
}

.form-fields {
    display: flex;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;

    label {
        padding-left: 10px;
    }

    input,
    label {
        display: block;
    }
}

.text-field {
    width: 20em;
}

.date-field {
    width: 20em;
}

.address-field {
    width: 12em;
}

.special-considerations-form {
    background-color: $grey;
    padding: 10px;
    border-radius: 8px;
}

.spec-cons-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.spec-cons-item {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.vac-weight-table {
    max-height: 300px;
    overflow-y: scroll;
}

// .form-control{
//     width: 20em;
// }

.prescriber-info-form-content {
    h3 {
        margin-top: 12px;
    }
}

#monthpicker {
    background-color: #F3F4F4 !important
}
</style>