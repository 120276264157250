<template>
    <div>
        <div style="display: flex; align-items: center">
            <div class="select-filters-container">

              <p @click="()=>{form.companyList = true; loadDrugList()}" 
                  v-bind:class="[form.companyList == true ? 'active-select-filter' : '']" class="select-filter-item">Company Medications List</p>

              <p @click="()=>{form.companyList = false; loadDrugList()}" 
                  v-bind:class="[form.companyList == false ? 'active-select-filter' : '']" class="select-filter-item">Non-Company Medications List</p>
              
            </div>
        </div>
        <div class="drug-register-table table-container" style="width: fit-content; border-top-left-radius: 0; box-shadow: none; padding-left: 0" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
            <div class="filters">
            <div class="text-search-container search-container">
                <input v-model="form.filter.name" placeholder="Drug..." style="position: relative" />
                <div class="drug-search-result-container" v-if='form.filter.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
                <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
                </div>
            </div>
            <div class="select-filters-container">
                </div>
                    <button class="button submit-button" @click="loadDrugList()">Search</button>
                    <div style="display: flex; align-items: center; margin-left: 20px">
                        <div class="select-filters-container">

                        <p @click="()=>{form.filter.tags.includes('s8') ? form.filter.tags.splice(form.filter.tags.indexOf('s8'), 1) : form.filter.tags.push('s8'); loadDrugList()}" 
                            v-bind:class="[form.filter.tags.includes('s8') ? 'active-select-filter' : '']" class="select-filter-item">S8</p>

                        <p @click="()=>{form.filter.tags.includes('s4') ? form.filter.tags.splice(form.filter.tags.indexOf('s4'), 1) : form.filter.tags.push('s4'); loadDrugList()}" 
                        v-bind:class="[form.filter.tags.includes('s4') ? 'active-select-filter' : '']" class="select-filter-item">S4</p>
                    
                    </div>
                    <div style="display: flex; align-items: center; margin-left: 20px" >
                        <p @click="()=>{updateList()}" v-if="changeDrugList.length" style="padding: 4px 10px;margin: 0"    class="select-filter-item outline-button">{{form.companyList ? 'Remove': 'Add'}} Items</p>
                    </div>
                </div>
            </div>
            <div class="drug-variances-container" style="display: flex;" v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
            </div>
            <div style='overflow-y: scroll; margin-top: -2em;' >
                <div class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" v-if='!$store.state.user.role.permissions.includes("can_view_stock_report")' >
                <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
                </div>
                <div v-else >
                <table class="data-table" cellspacing="0">
                <!-- LOADING SPINNER -->
                <!-- <div  v-if="!getDrugEntries" >
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div> -->
                <!-- v-else  -->
                <tr class="table-headers" >

                    <th style="width: 150px;">
                    <h3>{{form.companyList ? 'Remove from list' : 'Add to list'}}</h3>
                    </th>
                    <th>
                    <h3 @click="sortBy('drug', 'desc')">Drug</h3>
                    <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort drug-sort" v-if="form.col =='drug' && form.sort_direction =='asc'" @click="sortBy('drug', 'desc')"></i>
                        <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
                    </div>
                    </th>
                    <th style="width: 250px;">
                    <h3 @click="sortBy('resident', 'desc')" >Measurement Unit</h3>
                    <div v-if="form.col == 'resident'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort resident-sort" v-if="form.col =='resident' && form.sort_direction =='asc'" @click="sortBy('resident', 'desc')"></i>
                        <i class="pi pi-sort resident-sort" v-else @click="sortBy('resident', 'asc')"></i>
                    </div>
                    </th>
                    <th style="width: 150px;"> 
                    <h3 @click="sortBy('stock_level', 'desc')" >Form</h3>
                    <div v-if="form.col == 'stock_level'" style="display: inline; cursor: pointer">
                        <i class="pi pi-sort stock_level-sort" v-if="form.col =='stock_level' && form.sort_direction =='asc'" @click="sortBy('stock_level', 'desc')"></i>
                        <i class="pi pi-sort stock_level-sort" v-else @click="sortBy('stock_level', 'asc')"></i>
                    </div>
                    </th>
                </tr>
                
                <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
                    <tr v-if="!$store.state.loadingDrugListData">
                        <td style="padding-left: 30px; margin: 0 auto; align-items: center">
                            <Checkbox v-model="item.inList" :binary="true" @click="addToList(item.inList, item.alias_id)"/>
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.measurement_unit}}</td>
                        <td>{{item.form}}</td>
                    </tr>
                </tbody>
                </table>
                </div>
                <div v-if="$store.state.reportError">
                <h3 style="color: #E04F39">{{$store.state.reportError}}</h3>
                </div>
                <div v-if="$store.state.loadingDrugListData">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div>
            </div>
            
            <div class="pagination-links">
                <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
                <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
                <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
            </div>
        </div>
       </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  components: {
      // QuantityInStock,
      // QuantityUsed
  },
  data(){
    return{
      companyList: true,
      loading:false,
      drugNames: [],
      registerData: [],
      date:[],
      filters:{
        s8:true,
      },
      changeDrugList:[],
      clearForm:{
        companyList: true,
        page:1,
        filter:{
            tags:[],
            name:''
        }
      },
      form:{
        companyList: true,
        page:1,
        filter:{
            tags:[],
            name:''
        }
      },
      testData:[
          {
            alias_id:'30200230230203-asd0asda-23232',
            name: "Endone 500 mg",
            measurement_unit: "mg",
            form: 'tablet'
          },
          {
            alias_id:'hfhhf-3jf3-3fj3jf3f3-49494',
            name: "Methadone 40 ml",
            measurement_unit: "ml",
            form: 'vial'
          }
      ]
    }
  },
  methods:{
    // SORT DATA BY COLUMN AND ORDER
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getDrugList', this.form)
    },
    selectDrugName(name){
      this.form.filter.name = name;
      this.drugNames = [];
    },
    loadDrugList(){
        this.form.page = 1;
        this.changeDrugList = [];
        this.$store.dispatch('getDrugList', this.form);
    },
    //THIS ADDS TO THE LIST PRIOR TO PUSHING TO ENDPOINT
    addToList(bool, id){
        if(bool == true){
            this.changeDrugList.splice(this.changeDrugList.indexOf(id), 1);
        } else{
            this.changeDrugList.push(id);
        }
        console.log('adding to list', bool, id);
    },
    aliasSearch(alias){
      this.form.filter.name = alias;
      this.$store.dispatch('getDrugList', this.form);
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getDrugList', this.form);
    },
    // THIS ACTUALLY UPDATES THE Medications List WITH CHANGES MADE
    updateList(){
        axios.post(`/drug-list/${this.form.companyList ? 'remove' :'add'}`, {drugs:this.changeDrugList}, this.$store.state.header).then(res=>{
            console.log('updated Medications List', res);
            this.changeDrugList = [];
            this.$store.dispatch('getDrugList', this.form);
        })
    },
    clearFilteredDrugRegister(){
      this.form = {
        companyList: true,
        page:1,
        filter:{
            tags:[],
            name:''
        }
      };
      this.date = [];
      this.$store.dispatch('getDrugList', this.form);
    }
    
  },
   mounted(){
    this.loadDrugList();
  },
  computed:{
    getDrugEntries(){
      // getStockReport
      return this.$store.getters.getDrugListEntries
    }
  }
}
</script>