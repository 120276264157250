<template>
    <div>
        <transition name="fade" appear>
            <div class="notification-panel">


                <div class="notification-header">
                    <h3 style="margin: 0px;text-align: left;display: flex;align-items: center;padding: 10px;">Notifications
                    </h3>
                </div>

                <div class="notification-container">
                    <div class="notification-items">
                        <div v-if="!$store.state.loadingNotifications"> 
                            <div v-if="$store.state.notifications" class="notification-container">
                                <div >          
                                        <NewRequisitions />
                                        <NewTransfers />
                                        <NewDestructions />
                                        <NewTheatre />
                                    <!-- 
                                <IncomingDispensings @click="displayIncomingDispensingModal = true" />
                                <PendingInvoices @click="displayPendingInvoicesModal = true" />
                                <PendingDestructions /> 
                                -->
                                </div>
                            </div>
                            <div v-else style="height: 10em; display: flex; align-items: center; justify-content: center;">
                                <h4>No notifications</h4>
                            </div>
                        </div>
                        <div v-else style="display: flex; align-items: center; height: 10em; justify-content: center">
                                <i class="pi pi-spin pi-refresh" style="font-size: 1.5em !important;"></i>
                        </div>

                    </div>
                </div>


                <div class="notification-footer">
                    <p style="font-weight: 500; font-size: 1em;">Last Refresh: {{ !$store.state.lastNotificationRefresh ?
                        'N/A' : $store.state.lastNotificationRefresh.slice(0, -6) + ''
                        + $store.state.lastNotificationRefresh.slice(-2) }}</p>
                    <p class="black-button" @click="refreshNotifications()"><span><i class="pi pi-refresh"
                                style="padding: 0"></i></span> Refresh List</p>
                </div>

            </div>
        </transition>
        <IncomingDispensingModal v-if="displayIncomingDispensingModal" @close="close" />
        <PendingInvoicesModal v-if="displayPendingInvoicesModal" @close="close" />
    </div>
</template>


<script>

// import IncomingDispensings from './IncomingDispensings.vue';
// import PendingInvoices from './PendingInvoices.vue';
import NewRequisitions from './NewRequisitions.vue';
import IncomingDispensingModal from '@/components/modals/IncomingDispensingModal.vue';
import PendingInvoicesModal from '@/components/modals/PendingInvoicesModal.vue';
import NewTransfers from './NewTransfers.vue';
import NewDestructions from './NewDestructions.vue';
import NewTheatre from './NewTheatre.vue';


export default {
    data() {
        return {
            displayPendingInvoicesModal: false,
            displayIncomingDispensingModal: false,
            transferType: '',
            displayForm: '',
            view: ''
        }
    },
    components: {
    // IncomingDispensings,
    // PendingInvoices,
    NewRequisitions,
    IncomingDispensingModal,
    PendingInvoicesModal,
    NewTransfers,
    NewDestructions,
    NewTheatre
},
    methods: {
        toggleNotificationPanel() {
            this.$store.commit('setDisplayNotifications', !this.$store.state.displayNotifications)
        },
        refreshNotifications() {
            console.log('refreshing alerts');
            this.$store.dispatch('getNotifications', null);

        },
        close(value) {
            if (value) {
                this.displayForm = '';
                this.displayIncomingDispensingModal = false;
                this.displayPendingInvoicesModal = false;
            }
        },
        action(form, type) {
            console.log('this is the selected form to display', form, localStorage.getItem('user_id'));
            this.displayForm = form;
            this.transferType = type;


        }
    },
    mounted() {
        console.log('this is the window view', window.location.pathname);
        this.view = window.location.pathname.slice(1);
    }
}

</script>

<style lang="scss">
.notification-container {
    height: 70vh;
}

.notification-panel {
    background-color: white;
    box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
    position: fixed;
    right: 2.5em;
    top: 100px;
    width: 450px;
    min-height: fit-content;
    border-radius: 8px;
    border-bottom-left-radius: 8px;
}

.notification-items {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.notification-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #f3f4f4;
    font-size: 1em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    align-items: center;
    background-color: #f3f4f4;
    font-size: 1em;
    border-bottom-width: 2px;
    border-bottom: solid #bfc4c4;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
</style>