<template>
    <div class="chart-form-approval-container" style="justify-content: space-between">
        <!-- BEGINNING OF CHART -->
        <div class="chart-approval-only" :style="!displayDropDown ? { marginLeft: '-3px' } : {}"
            style="display: flex; flex-wrap: wrap; margin-bottom: 10px">
            <div :ref="'height-measurer' + parentIndex" :id="'height-measurer' + parentIndex">
                <div class="chart-form-container">
                    <div class="chart-form" :style="!displayDropDown ? { backgroundColor: 'transparent' } : {}">
                        <!-- displayDropDown is a conditional on whether to display the chart drop down, it is being used as defacto patient summary modal view conditional -->
                        <div class="zero-chart-row chart-row" v-if="displayDropDown">
                            <div class="first-chart-row-item chart-row-item"
                                style="display: grid !important; align-content: start; min-height: 40px;">
                                <Dropdown v-if="!viewOnly" v-model="selectedChart" @change="clearForm()"
                                    :options="charts" optionLabel="name" placeholder="Select Chart Type"
                                    style="margin-left: 13px; text-align: left;" id="selectchart"
                                    :style="selectedChart.color ? { backgroundColor: selectedChart.color } : ''"
                                    :class="selectedChart.color ? 'active-selected-chart' : ''" />
                                <h3 v-else :style="selectedChart.color ? { backgroundColor: selectedChart.color } : ''"
                                    :class="selectedChart.color ? 'active-selected-chart' : ''"
                                    style="margin: 0; padding: 5px; border-radius: 8px; margin-left: 13px; text-align: left; color: white;">
                                    {{ selectedChart.name }}</h3>
                                <!-- <div class="text-search-container search-container" style="display: flex; align-items: center">
                                    <input v-model="searchPatient" placeholder="Search Patient" style="position: relative; width: fit-content; margin-bottom: 5px" /> 
                                    <div style="cursor: pointer;" @click="searchForPatient(searchPatient)"><i class="pi pi-search" style="font-weight: bold"></i></div>
                                    <span v-if="prescription.patient">{{prescription.patient.first_name}}</span>
                                </div> -->
                                <!-- <div class="patient-search-results-container" v-if="patientSearchResults">
                                    <div class="individual-patient-container" v-for="(item, index) in patientSearchResults" :key="index" @click="selectPatient(item)">
                                        <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">
                                        </div>
                                        <div class="patient-result-info">
                                                <p v-if="item.personal_information"><b>Name:</b> {{item.personal_information.first_name}} {{item.personal_information.last_name}}</p>
                                                <p v-if="item.regulatory_information && item.regulatory_information.length"><b>Medicare Number:</b>  {{ item.regulatory_information[0].data.slice(0,4)}} {{item.regulatory_information[0].data.slice(4, 9)}} {{item.regulatory_information[0].data.slice(9)}}</p>
                                                <p><b>DOB:</b> {{item.personal_information && item.personal_information.dob ? item.personal_information.dob : ''}}</p>
                                                <p><b>Location:</b> {{item.physical_location && item.physical_location.location ? item.physical_location.location.name : ''}}</p>
                                        </div>
                                    </div>
                                    <div v-if="!patientSearchResults.length">
                                        <h4>No results found.</h4>
                                    </div>
                                </div> -->
                           </div>
                       </div>
                       <div v-if="selectedChart" style="display: flex; align-items: center; padding-left: 1em; margin: 10px 0; ">
                             <div style="display: flex; align-items: center; " v-if="displayDropDown">
                                <span style="margin-right: 10px;">Paper prescription </span>
                                <InputSwitch v-model="form.prescription_payload.is_paper" />
                            </div>
                            <div style="padding-left: 30px;" v-if="displayDropDown">
                                <SelectButton style="display: flex" v-model="drugSearchFilter" :options="searchTypes" optionLabel="name" optionValue="value" />
                            </div>
                            <!-- <div style="display: flex; align-items: center; padding-left: 1em;">
                                <span style="margin-right: 10px;">No brand substitution </span>
                                <InputSwitch v-model="substitution" />
                            </div> -->
                        </div>
                       <div v-if="selectedChart" class="first-chart-row chart-row" style='height: inherit !important;'>
                           
                           
                           <div class="first-chart-row-item chart-row-item" :style="displayDropDown ? {minHeight:'60px'}:{minHeight:'43px'}" style="display: grid !important; ">
                               <!-- <div class="text-search-container search-container" style="display: flex; align-items: center">
                                    <input v-if="!viewOnly" v-model="searchedDrugName" placeholder="Medication Name" :style="displayDropDown? '':{backgroundColor: 'white'}" style="position: relative; width: fit-content; margin-bottom: 5px" /> 
                                    <div  v-if="!viewOnly" style="cursor: pointer;" @click="searchDrugs(searchedDrugName)"><i class="pi pi-search" style="font-weight: bold"></i></div>
                                    <span :style="viewOnly ? {marginLeft: '1em'}:{}" v-if="selectedDrug.form">{{selectedDrug.name}}</span>
                                </div> -->
                                <!-- <div class="drug-variances-container"
                                    style="position: absolute; margin-left:15px; padding-top: 10px; background-color: white; display: flex; flex-wrap: wrap; align-items: flex-start;
                                 height: fit-content; border: 1px solid lightgrey; box-shadow: 0 0 0 3px #f8f8f8; max-height: 150px; overflow-y: scroll; margin-top: 170px; padding-left: 15px; width: 600px; z-index: 10;"
                                    v-show='drugList.length'>
                                    <p v-for="(item, index) in drugList" :key="index" @click="drugSelect(item)"
                                        style="padding: 3px 10px; height: 30px; margin-right: 5px; margin-top: 2px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                        {{ item.name }}
                                    </p>
                                </div> -->
                                <!-- MED SELECT OPTION -->
                                <div :style="!displayDropDown ? {display:'flex', paddingLeft:'15px'}:{}" style="display: flex; align-items: center; margin-left: 15px;">
                                    <Dropdown v-if="displayDropDown" v-model="searchedDrugName" ref="med" @change="drugSelect(searchedDrugName), show()" @keyup="searchDrugs(searchedDrugName), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                    style="width: 20em; display: flex"/>
                                    
                                    <span :style="viewOnly || displayDropDown ? {marginLeft: '1em'}:{}" v-if="selectedDrug.full_name">{{!loadingDrugs ? selectedDrug.full_name: 'Updating...'}}</span>
                                </div>
                            </div>
                            <!-- <div class="first-chart-row-item chart-row-item" style="width: 5em;">
                               <InputText v-tooltip="'Form'" placeholder="Form" v-model="form.prescription_payload.form" />
                               
                           </div> -->
                            <!-- <div class="first-chart-row-item chart-row-item">
                               <InputText v-tooltip="'Dose'" placeholder="Dose" v-model="form.prescription_payload.dose" />
                              
                           </div> -->
                        </div>
                        <div v-if="selectedChart" class="chart-row prescription-chart"
                            :class="displayDropDown ? '' : 'expansionFormat'"
                            :style="displayDropDown ? { width: '681px' } : { width: '900px' }" style="flex-wrap: wrap;">
                            <!-- v-for="(item, index) in selectedChart.inputs" :key="index" -->

                            <div class="chart-row-item"
                                style="width: fit-content; display: grid; grid-template-columns: 1fr 1fr 1fr">

                                <div v-tooltip="'Dose'">
                                    <p style="text-align: left; margin-left: 20px;">Dose ({{selectedDrug.form}})</p>
                                    <input type="number" v-model="form.prescription_payload.dose" />
                                    <!-- <InputNumber mode="decimal"
                                        placeholder="Dose" v-model="form.prescription_payload.dose" /> -->
                                </div>


                                <!-- <InputText v-tooltip="'Hourly Frequency'" placeholder="Hourly Frequency" v-if="item == 'hourly_frequency'" v-model="form.prescription_payload.hourly_frequency" /> -->

                                <div v-tooltip="'Max Dose per Administration'">
                                    <p style="text-align: left;"
                                    :style="!selectedChart.inputs.includes('max_dose') ? { color: '#C8C8C8' } : ''">
                                    Max Admin Dose({{selectedDrug.form}})</p>
                                    <input type="number" :disabled="!selectedChart.inputs.includes('max_dose')" 
                                    v-model="form.prescription_payload.max_admin_dose" />
                                    <!-- <InputNumber mode="decimal"
                                        placeholder="Dose" v-model="form.prescription_payload.dose" /> -->
                                </div>

                                <div v-tooltip="'Max Dose in 24hrs'">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('max_24_dose') ? { color: '#C8C8C8' } : ''">
                                        24hr Max Dose</p>
                                    <InputText placeholder="Max Dose in 24hrs"
                                        :disabled="!selectedChart.inputs.includes('max_24_dose')"
                                        v-model="form.prescription_payload.max_dose" />
                                </div>

                                <div v-tooltip="'Route'">
                                    <!-- :style="!selectedChart.inputs.includes('route') ? {color: '#C8C8C8'}:  ''" -->
                                    <p style="text-align: left; margin-left: 10px;">Route</p>
                                    <!-- :disabled="!selectedChart.inputs.includes('route')" -->
                                    <Dropdown id="route-type" placeholder="Select Route" :options="administrationTypes"
                                        v-model="form.prescription_payload.route" />
                                </div>
                                <!--   FREQUENCY -->
                                <!--   FREQUENCY -->
                                <!--   FREQUENCY -->

                                <div id="frequency-type" style="display: grid; padding-left: 10px;"
                                    class="chart-form-select" v-tooltip="'Frequency'">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('frequency') ? { color: '#C8C8C8' } : ''">
                                        Frequency</p>
                                    <Dropdown @change="updateNumberOfTimeFields()"
                                        :disabled="!selectedChart.inputs.includes('frequency')" id="route-type"
                                        placeholder="Select Frequency" :options="frequencyTypes" optionLabel="label"
                                        optionValue="data" v-model="form.prescription_payload.frequency"
                                        style="text-align: left; margin-left: 0px;" />
                                </div>

                                <!-- END FREQUENCY -->
                                <!-- END FREQUENCY -->
                                <!-- END FREQUENCY -->

                                <!-- INTERVAL -->

                                <div
                                    v-tooltip.bottom="`Interval - e.g. frequency set to 'Daily' at interval of 2 means every 2 days.`">
                                    <p style="text-align: left; margin-left: 20px;"
                                        :style="!form.prescription_payload.frequency ? true : form.prescription_payload.days_of_week.length ? true : false ? { color: '#C8C8C8' } : ''">
                                        Interval</p>
                                    <div style="display: flex;
                                                align-items: center;
                                                padding-left: 10px;">
                                        <p>Every</p>
                                        <InputNumber class="dynamic-number-input" style="width: 65px"
                                            :disabled="!form.prescription_payload.frequency ? true : form.prescription_payload.days_of_week.length ? true : false"
                                            placeholder="Interval" v-model="form.prescription_payload.interval" />
                                        <p v-if="form.prescription_payload.frequency">
                                            {{ form.prescription_payload.frequency == 'daily' ||
                                                    form.prescription_payload.frequency == 'BD' ||
                                                    form.prescription_payload.frequency == 'TDS' ||
                                                    form.prescription_payload.frequency == 'QiD' ? 'day' :
                                                    form.prescription_payload.frequency.split('ly')[0]
                                            }}/s</p>
                                    </div>

                                </div>

                                <!-- END OF INTERVAL -->


                                <!-- DAYS OF WEEK -->

                                <div v-tooltip="'Days of week'">
                                    <p style="text-align: left; margin-left: 20px;"
                                        :style="selectedChart.inputs.includes('frequency') && form.prescription_payload.frequency ? (form.prescription_payload.interval > 1 ? { color: '#C8C8C8' } : '') : { color: '#C8C8C8' }">
                                        Days of Week</p>
                                    <MultiSelect v-model="form.prescription_payload.days_of_week"
                                        :disabled="!form.prescription_payload.frequency ? false : (form.prescription_payload.interval > 1 ? true : false)"
                                        :options="daysOfWeek" optionLabel="label" optionValue="value"
                                        placeholder="Select Days of Week" />
                                </div>

                                <!-- END DAYS OF WEEK -->

                                <!-- DYNAMIC TIME INPUT LOOP -->
                                <div v-for="(item, key, index) in formTimes" :key="index" class="chart-row-item"
                                    style="width: fit-content; display: grid;" v-tooltip="`Time ${index + 1}`">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="(selectedChart.inputs.includes('frequency') && form.prescription_payload.frequency) ||
                                        (selectedChart.inputs.includes('datetime') && index == 0) ||
                                        (index < 2 && form.prescription_payload.frequency == 'BD') ? '' : { color: '#C8C8C8' }">
                                        Time {{ index + 1 }}
                                    </p>
                                    <div style='display: flex; align-items: center'>
                                        <input type="time" :placeholder="`Time ${index + 1}`"
                                            v-if="(selectedChart.inputs.includes('frequency') && form.prescription_payload.frequency) || selectedChart.inputs.includes('datetime')"
                                            v-model="formTimes[`${key}`]" />
                                        <input v-else type="time" :placeholder="`Time ${index + 1}`" disabled
                                            v-model="formTimes[`${key}`]" />
                                        <i @click="() => { delete formTimes[key]; readHeightMeasurer() }"
                                            style="cursor: pointer; font-size: 1.2em !important; padding: 0;"
                                            class='pi pi-trash'></i>
                                    </div>

                                </div>

                                <!-- END DYNAMIC TIME INPUT LOOP -->


                                <!-- ADD ADDITIONAL TIME -->

                                <div v-if="!viewOnly" class="chart-row-item" id="time-adder"
                                    style="width: fit-content; display: grid;" v-tooltip="'Add additional time'">
                                    <p style="  text-align: left;
                                            margin-left: 10px;
                                            margin-bottom: 0px;
                                            padding: 5px 15px;" class="outline-button"
                                        @click="() => { insertNewTimeField(); form.prescription_payload.frequency == 'daily' }">
                                        Add new time</p>
                                    <!-- :style="selectedChart.inputs.includes('frequency') && form.prescription_payload.frequency && ( form.prescription_payload.frequency !='BD' && form.prescription_payload.frequency !='TDS' && this.form.prescription_payload.frequency !='QiD') ? '' : {color: '#C8C8C8', border: '2px solid grey', cursor:'default'}" -->

                                </div>

                                <!-- END ADD ADDITIONAL TIME -->

                                <!-- DATE -->


                                <div style="display: grid; " v-tooltip="'Start Date'">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('start_date') && !selectedChart.inputs.includes('datetime') ? { color: '#C8C8C8' } : ''">
                                        Start Date</p>
                                    <Calendar id="startdateformat" @date-select="formatDate('start')"
                                        :disabled="!selectedChart.inputs.includes('start_date') && !selectedChart.inputs.includes('datetime')"
                                        v-model="start_date" dateFormat="dd/mm/yy" placeholder="Start Date" />
                                </div>

                                <div style="display: grid; " v-tooltip="'Stop Date'" v-if="selectedChart.inputs.includes('stop_date')">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('stop_date') ? { color: '#C8C8C8' } : ''">
                                        Stop Date</p>
                                    <Calendar id="stopdateformat" @date-select="formatDate('end')"
                                        :disabled="!selectedChart.inputs.includes('stop_date')" v-model="stop_date"
                                        dateFormat="dd/mm/yy" placeholder="Stop Date" />
                                </div>
                                <!-- END DATE -->

<!-- 
                                <div id="reason-input-container" style="grid-column-start: 1; grid-column-end: 4;"
                                    v-if="displayDropDown">
                                    <p style="text-align: left; margin-left: 20px;"
                                        :style="!selectedChart.inputs.includes('reason') ? { color: '#C8C8C8' } : ''">
                                        Reason</p>
                                    <InputText v-tooltip="'Reason'" placeholder="Reason"
                                        style="min-width: 50%; width: 35em; "
                                        :disabled="!selectedChart.inputs.includes('reason')"
                                        v-model="form.prescription_payload.reason" />
                                </div> -->




                                <!-- <TreeSelect v-if="item == 'frequency'" v-model="form.prescription_payload.frequency" :options="frequencyTypes" placeholder="Frequency"></TreeSelect> -->


                                <div id="reason-input-container" style="grid-column-start: 1; grid-column-end: 4;"
                                    v-if="displayDropDown">
                                    <p style="text-align: left; margin-left: 20px;"
                                        :style="!selectedChart.inputs.includes('indication') ? { color: '#C8C8C8' } : ''">
                                        Indication</p>
                                    <InputText v-tooltip="'Indication'" style="min-width: 50%; width: 35em; "
                                        placeholder="Indication"
                                        :disabled="!selectedChart.inputs.includes('indication')"
                                        v-model="form.chart_payload.chart_form.indication" />
                                </div>

                                <div class="chart-row-item"
                                    style="width: fit-content; grid-column-start: 1; grid-column-end: 4; margin-left: 10px;"
                                    v-if="displayDropDown">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('comments') ? { color: '#C8C8C8' } : ''">
                                        Notes/Instructions</p>
                                    <Textarea v-tooltip="'Notes/Instructions'"
                                        style="min-width: 650px; width: 100%; min-height: 100%; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                                        placeholder="Notes/Instructions"
                                        :disabled="!selectedChart.inputs.includes('comments')" :autoResize="true"
                                        v-model="form.prescription_payload.notes" />
                                </div>

                                <div class="chart-row-item"
                                    style="width: fit-content; grid-column-start: 1; grid-column-end: 4; display: flex; flex-wrap: wrap"
                                    v-if="$store.state.userType =='pharmacy'">
                                    <div class="toggle-switch" style="margin: 0 px;"
                                        v-if="selectedChart.inputs.includes('packed')"
                                        :style="{ border: '2px solid' + selectedChart.color }">
                                        <p class="toggle-switch-item"
                                            :style="packed == 'packed' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                            @click="packed == 'packed' ? packed = '' : packed = 'packed'">Packed</p>
                                        <p class="toggle-switch-item"
                                            :style="packed == 'non-packed' ? { color: 'white', backgroundColor: selectedChart.color } : { color: selectedChart.color }"
                                            @click="packed == 'non-packed' ? packed = '' : packed = 'non-packed'">
                                            Non-Packed</p>
                                    </div>

                                    <!-- <div class="toggle-switch" style="margin: 0 5px; " v-if="selectedChart.inputs.includes('now_or_next_pack')"  :style="{border: '2px solid'+selectedChart.color}">
                                        <p class="toggle-switch-item"  :style="now_or_next_pack == 'now' ? { color: 'white', backgroundColor: selectedChart.color}: {color: selectedChart.color}" 
                                        @click=" now_or_next_pack == 'now' ? now_or_next_pack ='' : now_or_next_pack ='now'">Now</p>
                                        <p class="toggle-switch-item" :style="now_or_next_pack == 'next-pack' ? { color: 'white', backgroundColor: selectedChart.color}: {color: selectedChart.color}" 
                                        @click="now_or_next_pack == 'next-pack' ? now_or_next_pack ='' : now_or_next_pack ='next-pack'">Next Pack</p>
                                    </div> -->

                                    <!-- <div class="toggle-switch" v-if="selectedChart.inputs.includes('single_multi')" :style="{border: '2px solid'+selectedChart.color}">
                                        <p class="toggle-switch-item" :style="single_multi == 'single' ? { color: 'white', backgroundColor: selectedChart.color}: {color: selectedChart.color}" 
                                        @click="single_multi == 'single' ? single_multi ='' : single_multi ='single'">Single</p>
                                        <p class="toggle-switch-item" :style="single_multi == 'multi' ? { color: 'white', backgroundColor: selectedChart.color}: {color: selectedChart.color}" 
                                        @click="single_multi == 'multi' ? single_multi ='' : single_multi ='multi'">Multi</p>
                                    </div> -->
                                </div>



                                <!-- <Calendar v-tooltip="'Prescription Date'" id="presdateformat" v-if="item == 'prescription_date'" v-model="prescription.prescription_date"  dateFormat="dd/mm/yy" placeholder="Prescription Date" /> -->


                                <!-- <Calendar v-tooltip="'Date and Time'" id="time24" placeholder="Date & Time" v-if="item == 'datetime'" v-model="form.prescription_payload.start_date" :showTime="true" :showSeconds="false" /> -->
                                <!-- <div class="toggle-switch" style="margin: 0 5px; min-width: 240px" v-if="item == 'substitution'" :style="{border: '2px solid'+selectedChart.color}">
                                   <p class="toggle-switch-item" style="width: 100%" :style="substitution ? { color: 'white', backgroundColor: selectedChart.color}: {color: selectedChart.color}" 
                                   @click="substitution = !substitution">Brand Substitution Allowed</p>
                               </div> -->
                                <div style="width: 100%; overflow: scroll" v-if="error_message">
                                    <p style="margin: 0 auto; font-size: 1em; ">Error message: {{ error_message.message
                                    }}
                                    </p>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>


            </div>
            <!--                
               <div class="approval-container" >
                   <div class="valid-prescription-notes" style='margin-top: 0; width: 400px;' v-if="selectedChart">
                        <div>
                            <h4>Valid Prescription Notes</h4>
                            <div>
                                <p>Streamlined Authority Code</p>
                                <InputText style="width: 100%; background-color: #F3F4F4" placeholder="Enter code here..." v-model="prescription.authority_code" />
                            </div>
                            <div class="prescriber-options-container">
                                <div class="select-filters-container prescriber-options-item" style="border-radius: 10px">
                                    <p @click="()=>{ pbs = !pbs}" 
                                    v-bind:class="[pbs == true? 'active-select-filter' : '']" class="select-filter-item">PBS</p>
                                </div>
                                <div class="select-filters-container prescriber-options-item" style="border-radius: 10px">
                                    <p @click="()=>{rpbs = !rpbs}" 
                                    v-bind:class="[rpbs? 'active-select-filter' : '']" class="select-filter-item">RPBS</p>
                                </div>
                                <div class="select-filters-container prescriber-options-item" style="border-radius: 10px">
                                    <p @click="()=>{ctg= !ctg}" 
                                    v-bind:class="[ctg? 'active-select-filter' : '']" class="select-filter-item">CTG</p>
                                </div>
                            </div>
                                
                            
                        </div>
                    </div>
                   <div class="chart-approval" v-if="selectedChart">
                       <button class="complete-button" @click="createChart()" style="font-weight: bold; width:150px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);">Create Chart</button>
                   </div>
               </div> -->
            <div class="chart-approval" style="width: 681px; margin-top: 0;" v-if="selectedChart && !viewOnly"
                :style="displayDropDown ? '' : { justifyContent: 'flex-start', marginTop:'10px' }">
                <button class="blue-button" @click="cancelPrescription()"
                    v-if="(!addedToReview || !chartReview) && $store.state.user.role.permissions.includes('can_sign_prescription')"
                    style="font-weight: 500; width:150px; margin-right: 10px; backgroundColor: #b8b8b8 !important">Cancel</button>
                <button class="blue-button"
                    v-if="(form.signatures && form.signatures.length) && $store.state.user.role.permissions.includes('can_sign_prescription')"
                    @click="createChart(true, false)" style="font-weight: 500; margin-right: 10px; ">Disable
                    Prescription</button>
                <button class="blue-button"
                    v-if="((isDraft || !form.chart_payload.prescription_id) && displayDropDown) && (form.chart_payload.prescription_id ? $store.state.user.role.permissions.includes('can_edit_draft_prescription') : $store.state.user.role.permissions.includes('can_create_draft_prescription'))"
                    @click="form.chart_payload.prescription_id ? updateChart(false) : createChart(false, false)"
                    style="font-weight: 500; width:150px; margin-right: 10px;">{{ form.chart_payload.prescription_id ?
                            'Update Draft' : 'Save Draft'
                    }}</button>
                <button class="blue-button"
                    v-if="!isDraft && (!addedToReview || !chartReview) && form.chart_payload.prescription_id && $store.state.user.role.permissions.includes('can_edit_draft_prescription')"
                    @click="createChart(true, true)" style="font-weight: 500; ">Edit Prescription</button>
                <button class="blue-button"
                    v-if="!isDraft && !addedToReview && chartReview && form.chart_payload.prescription_id && $store.state.user.role.permissions.includes('can_sign_prescription')"
                    @click="addIDToReview(), addedToReview = true" style="font-weight: 500; margin-left: 10px;">Add to
                    Review</button>
                <button class="blue-button"
                    v-if="!isDraft && addedToReview && chartReview && form.chart_payload.prescription_id && $store.state.user.role.permissions.includes('can_sign_prescription')"
                    @click="removeIDFromReview(), addedToReview = false"
                    style="font-weight: 500; margin-left: 10px;">Remove from Review</button>
                <button class="blue-button"
                    v-if="(isDraft || !form.chart_payload.prescription_id) && $store.state.user.role.permissions.includes('can_sign_prescription')"
                    @click="form.chart_payload.prescription_id ? updateChart(true) : createChart(true, false)"
                    style="font-weight: 500; ">Save <span v-if="!chartReview">& Approve</span> Chart</button>
            </div>
        </div>
        <!-- END OF CHART -->
        <!-- <SignatureModal v-if="displaySignatureModal" :payload="form.prescription_payload" @close="close" :endpoint="endpoint" /> -->

        <SignatureModal v-if="displaySignatureModal" :payload="payload" @close="close" :endpoint="endpoint" />

        <SuccessModal :loading="loading" @stopAction="stopAction" :loadingDelay="loadingDelay"
            @successfullySubmitted="successfullySubmitted" @close="close"
            v-if="displaySuccessModal || signFromMedicationChartRow" :action="signature" actionType='sign'
            :payload="prescription_sign_payload" :statusMessage="'Prescription Action'"
            :first_spec_line="displayMessage" :second_spec_line="displayMessage2"
            signature_endpoint="/prescription/sign" />
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './modals/SignatureModal.vue';
import SuccessModal from './modals/SuccessModal.vue';

export default {
    props: ['patientId', 'activePresChart', 'draftDrug', 'isDraft', 'displayDropDown',
        'parentIndex', 'checkHeight', 'signFromMedicationChartRow', 'viewOnly', 'chartReview', 'reviewed'],
    components: {
        SignatureModal,
        SuccessModal
    },
    data() {
        return {
            addedToReview: false,
            payload: {},
            signature: false,
            loading: false,
            loadingDelay: false,
            cancelAction: null,
            displaySuccessModal: false,
            displaySignatureModal: false,
            displayMessage: '',
            displayMessage2: '',
            searchedDrugName: '',
            clearDrug: false,
            drugList: [],
            selectedDrug: {},
            loadingDrugs:false,
            endpoint: '/prescription/create',
            endpoint2: '/chart',
            prescription_sign_payload: {
                prescription_id: ''
            },
            form: {
                chart_payload: {
                    prescription_id: '',
                    chart_form: {
                        type: '',
                        route: '',
                        indication: '',
                        tags: []
                    }
                },
                prescription_payload:{
                    is_paper: false,
                    prescription_id:'',
                    patient_id:'',
                    prescriber:'',
                    drug_uuid:'',
                    days_of_week: [],
                    interval: 1,
                    frequency: '',
                    times: [],
                    dose: '',
                    max_admin_dose:'',
                    route: '',
                    reason: '',
                    notes: '',
                    max_dose: '',
                    start_date: '',
                    stop_date: '',
                    additional_regulatory_checks: ''
                }
            },
            charts: [
                {
                    name: 'Regular Medicine Order',
                    chart_name: 'regular',
                    inputs: [
                        'dose',
                        'route',
                        'start_date',
                        'frequency',
                        // 'reason',
                        'substitution',
                        'indication',
                        'single_multi',
                        'packed',
                        'comments',
                    ],
                    subtext: '',
                    color: '#9F5CC0',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'PRN Medicine',
                    chart_name: 'prn',
                    inputs: [
                        // 'reason',
                        'indication',
                        'frequency',
                        'max_24_dose',
                        'dose',
                        'route',
                        'max_dose',
                        'substitution',
                        'packed',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'comments',
                    ],
                    subtext: '',
                    color: '#00B2A2',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Short Term Medicine',
                    chart_name: 'short_term',
                    inputs: [
                        'now_or_next_pack',
                        'dose',
                        'route',
                        'packed',
                        'frequency',
                        'indication',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        // 'reason',
                        'substitution',
                        'comments',

                    ],
                    subtext: '(antibiotics)',
                    color: '#D6001C',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Once Only Meds (Stat)',
                    chart_name: 'stat',
                    inputs: [
                        'datetime',
                        'dose',
                        'route',
                        // 'reason'
                    ],
                    subtext: '',
                    color: '#8E0018',
                    prescriber: true,
                    sign1: true,
                    sign2: false,
                },
                {
                    name: 'Intermittent Medicine',
                    chart_name: 'intermittent',
                    inputs: [
                        'frequency',
                        'start_date'
                    ],
                    subtext: '',
                    color: '#5B00AA',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Variable Dose Meds',
                    chart_name: 'variable_dose',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        'packed',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'comments',
                    ],
                    subtext: '(not insulin)',
                    color: '#00AD50',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                //   {
                //       name:'Nurse Initiated Meds',
                //       inputs:[
                //           'frequency',
                //           'dose',
                //           'route',
                //           'reason',
                //           'start_date',
                //           'comments'
                //       ],
                //       subtext:'(non-prescription)',
                //       color: '#274188',
                //       prescriber:false,
                //       sign1:false,
                //       sign2: false,
                //       nurse: true,
                //   },
                {
                    name: 'Non Prescription Meds',
                    chart_name: 'non_prescription',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        // 'reason',
                        'start_date',
                        'comments',
                    ],
                    subtext: '(patient initiated)',
                    color: '#EF9600',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Nutritional Supplement',
                    chart_name: 'supplement',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        // 'reason',
                        'start_date',
                        'stop_date',
                        'substitution',
                        'packed',
                        'comments',
                    ],
                    subtext: '',
                    color: '#E56DB1',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
            ],
            administrationTypes: [
                'PO',
                'PR',
                'Topical',
                'Subcut',
                'Subling',
                'NG',
                'PEG',
                'IM',
                'IV',
                'Epidural',
                'Inhale/Inhalation',
                'Intraarticular (joint)',
                'Intrathecal (spine)',
                'Intranasal (nose)',
                'Irrigation',
                'NEB',
                'PV',
                'PICC'
            ],
            frequencyTypes: [
                //   {"label": "Mane", "data": "Mane"},
                //   {"label": "Nocte", "data": "Nocte"},
                { "label": "BD", "data": "BD" },
                { "label": "TDS", "data": "TDS" },
                { "label": "QiD", "data": "QiD" },
                //   {"label": "PRN", "data": "PRN"},
                { "label": "Daily", "data": "daily" },
                { "label": "Weekly", "data": "weekly" },
                { "label": "Monthly", "data": "monthly" },
            ],
            daysOfWeek: [
                {
                    value: 'MO',
                    label: 'Monday'
                },
                {
                    value: 'TU',
                    label: 'Tuesday'
                },
                {
                    value: 'WE',
                    label: 'Wednesday'
                },
                {
                    value: 'TH',
                    label: 'Thursday'
                },
                {
                    value: 'FR',
                    label: 'Friday'
                },
                {
                    value: 'SA',
                    label: 'Saturday'
                },
                {
                    value: 'SU',
                    label: 'Sunday'
                }
            ],
            selectedChart: '',
            formTimes: {
                time1: '',
            },
            drugSearchFilter:0,
            searchTypes:[
                {name: 'All', value:0},
                {name: 'Brands', value: 2},
                {name: 'Generics', value:1}
            ],
            packed: '',
            now_or_next_pack: '',
            single_multi: '',
            error_message: '',
            start_date: '',
            stop_date: ''
        }
    },
    methods: {
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        addIDToReview() {
            let id = this.parentIndex + ''
            this.$emit('addToReview', this.form.chart_payload.prescription_id);
            this.$emit('displayItem', id);
        },
        removeIDFromReview() {
            this.$emit('removeFromReview', this.form.chart_payload.prescription_id);
            //  this.$emit('displayItem', id);
        },
        // updateFrequencyBasedOnTimeFields(){
        //     let numOfTimes = 0;
        //     for(let key in this.formTimes){
        //         numOfTimes +=1;
        //         console.log('key is', key);
        //     }
        //     if(numOfTimes > 4){
        //         this.form.prescription_payload.frequency = 'daily';
        //     } else{
        //         switch(numOfTimes){
        //         case 1:
        //             this.form.prescription_payload.frequency = 'daily';
        //             break;
        //         case 2:
        //             this.form.prescription_payload.frequency = 'BD';
        //             break;
        //         case 3:
        //             this.form.prescription_payload.frequency = 'TDS';
        //             break;
        //         case 4:
        //             this.form.prescription_payload.frequency = 'QiD';
        //             break;
        //         case numOfTimes>4:
        //             break;
        //         }
        //     }
        // },
        formatDate(start_or_end) {
            console.log('its the', start_or_end, this.stop_date, this.start_date, this.form.prescription_payload);
            if (start_or_end == 'end') {
                this.form.prescription_payload.stop_date = new Date(this.stop_date).toLocaleDateString('en-AU')
            } else {
                this.form.prescription_payload.start_date = new Date(this.start_date).toLocaleDateString('en-AU')
            }
        },
        stopAction(value) {
            if (value == true) {
                this.cancelAction = true;
                this.close(value);
            }
            if (value == false) {
                this.cancelAction = false;
            }
        },
        readHeightMeasurer() {
            console.log('this is the height', this.$refs['height-measurer' + this.parentIndex].clientHeight);
            setTimeout(() => {
                this.$emit('updateHeightCheck', { checkHeight: false, index: this.parentIndex, height: this.$refs['height-measurer' + this.parentIndex].clientHeight });

            }, 100)
        },
        updateNumberOfTimeFields() {
            console.log('FREQUENCY HAS BEEN CHANGED');
            if (!this.form.chart_payload.prescription_id) {
                if (this.form.prescription_payload.frequency == 'TDS') {
                    console.log('TDS has been selected');
                    this.formTimes = {
                        time1: this.formTimes.time1,
                        time2: this.formTimes.time2,
                        time3: this.formTimes.time3,
                    }
                } else if (this.form.prescription_payload.frequency == 'BD') {
                    console.log('TDS has been selected');
                    this.formTimes = {
                        time1: this.formTimes.time1,
                        time2: this.formTimes.time2
                    }
                } else if (this.form.prescription_payload.frequency == 'QiD') {
                    console.log('TDS has been selected');
                    this.formTimes = {
                        time1: this.formTimes.time1,
                        time2: this.formTimes.time2,
                        time3: this.formTimes.time3,
                        time4: this.formTimes.time4,
                    }
                } else {
                    this.formTimes = { ...this.formTimes };
                }
            }
        },
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
                this.displaySignatureModal = false;
                this.displayMessage = '';
                this.displayMessage2 = '';
                console.log('closing prescription chart success modal');
                if (!this.chartReview) {
                    this.$emit('isSuccessful', true);
                }
                if (this.displayDropDown) {
                    this.selectedChart = '';
                }
            }
        },
        successfullySubmitted(value) {
            if (value) {
                this.$emit('isSuccessful', true);
                this.displaySuccessModal = false;
                this.displaySignatureModal = false;
                this.displayMessage = '';
                this.displayMessage2 = '';
                if (this.chartReview) {
                    this.$emit('addToReview', this.form.chart_payload.prescription_id);
                }
            }
        },
        cancelPrescription() {
            console.log('CANCEL PRESCRIPTION FUNCTION RUNNING');
            let timer = 10;
            this.displaySuccessModal = true;
            let interval = setInterval(() => {
                timer--;
                this.displayMessage = `${timer}`;
                this.loadingDelay = true;
                if (timer == 0 || this.cancelAction == true) {

                    clearInterval(interval);
                    this.loadingDelay = false;
                    if (this.cancelAction == true) {
                        console.log('CANCELLING THE ACTION', this.cancelAction)
                    }
                    if (this.cancelAction == null) {
                        console.log('Cancel action should be null', this.cancelAction)
                        this.displayMessage = 'Submitting request...';
                        if (this.form.chart_payload.prescription_id) {
                            if (this.chartReview) {
                                this.$emit('addToReview', this.form.chart_payload.prescription_id);
                            }
                            axios.post(`/prescription/cancel`, { prescription_id: this.form.chart_payload.prescription_id }, this.$store.state.header).then(res => {
                                console.log('cancelled the prescription', res.data);
                                this.displayMessage = 'Successfully cancelled prescription.';
                                this.$store.dispatch('getRefreshToken');
                                this.$emit('isSuccessful', this.form.chart_payload.prescription_id);
                                this.$emit('removeFromReview', this.form.chart_payload.prescription_id);
                            }).catch(err => {
                                this.loading = false;
                                this.error_message = err.response.data.message;
                                this.displayMessage = 'Error: ' + this.error_message;
                                console.log('HITTING CREATE ERROR', this.error_message);
                                this.signature = false;
                            })
                        }
                        this.clearForm();
                    }
                    this.cancelAction = null;

                }

            }, 1000)
            timer = 10;
            this.displayMessage = '';

        },
        clearForm() {
            this.form = {
                chart_payload: {
                    prescription_id: '',
                    chart_form: {
                        type: '',
                        route: '',
                        indication: '',
                        tags: []
                    }
                },
                prescription_payload: {
                    patient_id: '',
                    prescriber: '',
                    drug_uuid: '',
                    days_of_week: [],
                    interval: 1,
                    frequency: '',
                    times: [],
                    dose: 0.00,
                    route: '',
                    reason: '',
                    notes: '',
                    max_dose: 0.00,
                    start_date: '',
                    stop_date: '',
                    additional_regulatory_checks: ''
                }
            }
            this.formTimes = {
                time1: '',
            }
            this.selectedDrug = {};
            this.searchedDrugName = '';
            this.packed = '';
            this.now_or_next_pack = '';
            this.single_multi = '';
            this.error_message = '';

        },
        searchDrugs() {
            
            if(this.searchedDrugName){
                this.loadingDrugs = true;
                axios.post('/get-drug-aliases', { drug: this.searchedDrugName, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    res.data.drug_aliases.forEach(item=>{
                        item.full_name = `(${item.generic_name}) ${item.name}`
                    })
                    this.drugList = res.data.drug_aliases;

                    this.drugSelect(this.drugList[0]);
                    this.loadingDrugs = false;
                    // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
                }).catch(err => {
                    console.log('this is the err', err);
                    this.loadingDrugs = false;
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
            
        },
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.drug_uuid = item.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = `${item.strength}`;
            this.selectedDrug.measurement_unit = `${item.measurement_unit}`;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
            // this.drugList = [];
            // this.searchedDrugName = item.name;
            // this.clearDrug = true;
        },
        updateChart(sign) {
            this.error_message = '';
            let payload = {};
            console.log('this is the  drug', this.selectedDrug, this.form.prescription_payload.start_date);
            payload.dose = parseFloat(this.form.prescription_payload.dose).toFixed(2);
            payload.max_dose = parseFloat(this.form.prescription_payload.max_dose).toFixed(2);
            payload.drug_uuid = this.selectedDrug.alias_id;
            payload.start_date = this.form.prescription_payload.start_date && this.form.prescription_payload.start_date.includes('/') ? this.form.prescription_payload.start_date : new Date(this.form.prescription_payload.start_date).toLocaleDateString('en-AU');
            payload.stop_date = this.form.prescription_payload.stop_date && this.form.prescription_payload.stop_date.includes('/') ? this.form.prescription_payload.stop_date : new Date(this.form.prescription_payload.stop_date).toLocaleDateString('en-AU');
            payload.prescription_id = this.form.chart_payload.prescription_id;
            payload.days_of_week = this.form.prescription_payload.days_of_week;
            payload.interval = this.form.prescription_payload.interval;
            payload.frequency = this.form.prescription_payload.frequency;
            payload.route = this.form.prescription_payload.route;
            payload.reason = this.form.prescription_payload.reason;
            payload.frequency_shorthand = 'BD';
            payload.notes = this.form.prescription_payload.notes ? this.form.prescription_payload.notes : '';
            payload.times = [];

            payload.chart_form = {
                type: this.form.chart_payload.chart_form.type,
                route: this.form.prescription_payload.route,
                max_dose: parseFloat(this.form.prescription_payload.max_dose).toFixed(2)
            };


            for (let key in this.formTimes) {
                console.log('this is a key', key, this.formTimes[key]);
                if (this.formTimes[key]) {
                    payload.times.push(this.formTimes[key].split(':').join(''))
                }
            }

            //   if(this.packed){
            //       this.form.chart_payload.chart_form.tags.push(this.packed);
            //   }
            if (this.selectedChart.chart_name == 'stat' || this.selectedChart.chart_name == 'intermittent' || this.selectedChart.chart_name == 'non_prescription') {
                console.log('this chart is a stat chart!')
                this.form.prescription_payload.frequency = 'daily';
                this.form.prescription_payload.stop_date = this.form.prescription_payload.start_date;
            }

            if (this.form.prescription_payload.frequency == 'QiD' || this.form.prescription_payload.frequency == 'TDS' ||
                this.form.prescription_payload.frequency == 'BD') {
                this.form.prescription_payload.frequency = 'daily';
            }
            this.loading = true;
            this.displaySuccessModal = true;
            if (sign && !this.chartReview) {
                this.signature = true;
            }

            axios.post('/prescription/edit', payload, this.$store.state.header).then(res => {
                console.log('this is the res', res);
                this.$store.dispatch('getRefreshToken');
                this.loading = false;
                if (sign && !this.chartReview) {
                    //   this.prescription_sign_payload.prescription_id = this.form.chart_payload.prescription_id;
                    this.displayMessage2 = 'Click the Sign button to sign off on prescription.';

                } else {
                    this.displayMessage = 'Successfully updated prescription draft.';
                    if (this.chartReview) {
                        this.$emit('addToReview', this.form.chart_payload.prescription_id);
                    } else{
                        this.$emit('isSuccessful', true);
                    }
                }

            }).catch(err => {
                this.loading = false;
                this.error_message = err.response.data.message;
                this.displayMessage = 'Error: ' + this.error_message;
                console.log('HITTING CREATE ERROR', this.error_message);
                this.signature = false;
            })
        },
        createChart(sign, renew) {
            this.formatDate();
            let timer = 10;
            this.displaySuccessModal = true;
            let interval = setInterval(() => {
                timer--;
                this.displayMessage = `${timer}`;
                this.loadingDelay = true;
                if (timer == 0 || this.cancelAction == true) {

                    clearInterval(interval);
                    this.loadingDelay = false;
                    if (this.cancelAction == true) {
                        console.log('CANCELLING THE ACTION', this.cancelAction)
                    }
                    if (this.cancelAction == null) {
                        console.log('Cancel action should be null', this.cancelAction)
                        this.displayMessage = 'Submitting request...'
                        this.error_message = '';

                        // FUNCTION HERE
                        // FUNCTION HERE
                        console.log('this is the  date', new Date(this.form.prescription_payload.start_date).toLocaleDateString('en-AU'));
                        this.form.prescription_payload.patient_id = this.patientId;
                        this.form.prescription_payload.frequency_shorthand = 'BD';
                        this.form.prescription_payload.dose = parseFloat(this.form.prescription_payload.dose).toFixed(2);
                        this.form.prescription_payload.max_dose = parseFloat(this.form.prescription_payload.max_dose).toFixed(2);
                        this.form.prescription_payload.prescriber = JSON.parse(localStorage.getItem('user_id'));
                        this.form.prescription_payload.drug_uuid = this.draftDrug.alias_id && !this.selectedDrug.drug_uuid ? this.draftDrug.alias_id : this.selectedDrug.drug_uuid;
                        // this.form.prescription_payload.drug_uuid = this.selectedDrug && this.selectedDrug.alias_id ? this.selectedDrug.alias_id : this.draftDrug.alias_id;
                        this.form.prescription_payload.reason = this.form.prescription_payload.reason ? this.form.prescription_payload.reason : '';
                        this.form.prescription_payload.notes = this.form.prescription_payload.notes ? this.form.prescription_payload.notes : '';
                        // this.form.prescription_payload.start_date =  new Date(this.form.prescription_payload.start_date).toLocaleDateString();
                        // this.form.prescription_payload.stop_date = this.form.prescription_payload.stop_date.length == 10 ? this.form.prescription_payload.stop_date : new Date(this.form.prescription_payload.start_date).toLocaleDateString();
                        if (renew) {
                            let prescriptionToCancel = this.form.chart_payload.prescription_id;
                            this.form.prescription_payload.prescription_id = prescriptionToCancel;
                            axios.post(`/prescription/cancel`, {prescription_id: prescriptionToCancel}, this.$store.state.header).then(res=>{
                                console.log("cancelled prescription", prescriptionToCancel, res.data);
                                this.form.chart_payload.prescription_id = '';
                            })
                        }
                        this.form.prescription_payload.times = [];
                        for (let key in this.formTimes) {
                            console.log('this is a key', key);
                            if (this.formTimes[key]) {
                                this.form.prescription_payload.times.push(this.formTimes[key].split(':').join(''))
                            }
                        }
                        this.form.chart_payload.chart_form.tags = [];
                        if (this.single_multi) {
                            this.form.chart_payload.chart_form.tags.push(this.single_multi);
                        }
                        if (this.now_or_next_pack) {
                            this.form.chart_payload.chart_form.tags.push(this.now_or_next_pack);
                        }
                        if (this.packed) {
                            this.form.chart_payload.chart_form.tags.push(this.packed);
                        }
                        if (this.selectedChart.chart_name == 'stat' || this.selectedChart.chart_name == 'intermittent' || this.selectedChart.chart_name == 'non_prescription') {
                            console.log('this chart is a stat chart!')
                            this.form.prescription_payload.frequency = 'daily';
                            this.form.prescription_payload.stop_date = this.form.prescription_payload.start_date;
                        }

                        if (this.form.prescription_payload.frequency == 'QiD' || this.form.prescription_payload.frequency == 'TDS' ||
                            this.form.prescription_payload.frequency == 'BD') {
                            this.form.prescription_payload.frequency = 'daily';
                        }
                        this.loading = true;
                        this.displaySuccessModal = true;
                        if (sign && !this.chartReview) {
                            this.signature = true;
                        }
                        axios.post(this.endpoint, this.form.prescription_payload, this.$store.state.header).then(res => {
                            console.log('this is the res', res);
                            this.form.chart_payload.prescription_id = res.data;
                            this.form.chart_payload.chart_form.route = this.form.prescription_payload.route;
                            this.form.chart_payload.chart_form.type = this.selectedChart.chart_name;
                            if (this.selectedDrug.tags) {
                                this.form.chart_payload.chart_form.tags = [];
                                this.selectedDrug.tags.forEach(tag => {
                                    this.form.chart_payload.chart_form.tags.push(tag.toLowerCase())
                                })
                            }
                            console.log('this is the chart payload', this.form.chart_payload);
                        }).then(() => {
                            console.log('the create chart endpoint is being processed now');
                            axios.post('/chart', this.form.chart_payload, this.$store.state.header).then(res2 => {
                                console.log('submitted chart create, this is res', res2);
                                this.$store.dispatch('getRefreshToken');
                                this.loading = false;
                                this.displayMessage = 'Your prescription and chart have been successfully created.';
                                if (sign && !this.chartReview) {
                                    this.prescription_sign_payload.prescription_id = this.form.chart_payload.prescription_id
                                    this.displayMessage2 = 'Click the Sign button to sign off on prescription.';
                                }
                                if (this.chartReview) {
                                    this.displayMessage2 = 'Prescription has been added to the list of prescriptions that will be signed off at the end of the chart review.';
                                    this.$emit('addToReview', this.form.chart_payload.prescription_id);
                                }
                            })
                            // .then(()=>{
                            //     if(this.selectedDrug.tags){
                            //         axios.post(`/chart/${item.chart_id}/add-tags`, {
                            //             tags:[item.toAction.toLowerCase()]
                            //         }, this.$store.state.header).then(res=>{
                            //             console.log('added tags to chart', res);
                            //         }).catch(err=>{
                            //             console.log('this is the err', err);
                            //             if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                            //                 console.log('yes, it does include expired')
                            //                 this.$router.push('/login');
                            //             }
                            //         })
                            //     }
                            // })
                        }).catch(err => {
                            this.loading = false;
                            this.error_message = err.response.data.message;
                            this.displayMessage = 'Error: ' + this.error_message;
                            console.log('HITTING CREATE ERROR', this.error_message);
                            this.signature = false;
                        })
                        console.log('this is the prescrption/chart form', this.form, this.patientId);
                        // FUNCTION END
                        // FUNCTION END
                        // FUNCTION END
                    }
                    this.cancelAction = null;

                }

            }, 1000)
            timer = 10;
            this.displayMessage = '';

        },
        insertNewTimeField() {
            //  if(this.selectedChart.inputs.includes('frequency') && this.form.prescription_payload.frequency && ( this.form.prescription_payload.frequency !='BD' && this.form.prescription_payload.frequency !='TDS' && this.form.prescription_payload.frequency !='QiD')){
            let numOfTimeInputs = 0;
            for (let key in this.formTimes) {
                console.log('this is a key', key);
                numOfTimeInputs++;
            }
            this.formTimes[`time${numOfTimeInputs + 1}`] = '';
            this.readHeightMeasurer();
            //  }

            // this.updateFrequencyBasedOnTimeFields();


        },
        updateForm(newData) {
            this.form = newData;
            this.form.prescription_payload.patient_id = this.patientId ? this.patientId : this.$route.params.id;
            this.form.prescription_payload.reason = this.form.prescription_payload.reason ? this.form.prescription_payload.reason : '';
            this.form.prescription_payload.drug_uuid = this.draftDrug.alias_id;

            console.log('THIS IS THE FORM', this.form, newData);
            this.start_date = this.form.prescription_payload.start_date;
            this.stop_date = this.form.prescription_payload.stop_date;

            this.formTimes = {};

            let chartTypes = this.$store.getters.getChartTypes;
            console.log('update form has been hit', this.form, this.draftDrug);
            chartTypes.forEach(chartType => {
                if (chartType.chart_name == this.form.chart_payload.chart_form.type) {
                    this.selectedChart = chartType;
                }
            })

            this.selectedDrug = this.draftDrug;
            this.selectedDrug.full_name = `(${this.selectedDrug.generic_name}) ${this.selectedDrug.name}`
            console.log('THIS ITHE NEW DATA', newData.chart_payload.chart_form.tags);
            if (newData.chart_payload.chart_form.tags.length) {
                if (newData.chart_payload.chart_form.tags.includes('packed')) {
                    console.log('this contains packed');
                    this.packed = 'packed';
                }
                if (newData.chart_payload.chart_form.tags.includes('non-packed')) {
                    console.log('this contains non-packed');
                    this.packed = 'non-packed';
                }
                if (newData.chart_payload.chart_form.tags.includes('single')) {
                    console.log('this contains single');
                    this.single_multi = 'single';
                }
                if (newData.chart_payload.chart_form.tags.includes('multi')) {
                    console.log('this contains multi');
                    this.single_multi = 'multi';
                }
                if (newData.chart_payload.chart_form.tags.includes('now')) {
                    console.log('this contains now');
                    this.now_or_next_pack = 'now';
                }
                if (newData.chart_payload.chart_form.tags.includes('next_pack')) {
                    console.log('this contains next_pack');
                    this.now_or_next_pack = 'next_pack';
                }
            }

            let numOfTimes = this.form.prescription_payload.times.length;
            let times = [];
            this.form.prescription_payload.times.forEach(time => {
                time = time.split('');
                time = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
                times.push(time);
            })

            let num = 1;
            setTimeout(() => {
                console.log('this is the payload times', times)
                while (numOfTimes > 0) {
                    console.log('numof times', num);
                    this.formTimes[`time${num}`] = times.shift();
                    if (num > 4) {
                        console.log('ITS GREATER THAN 4', num);
                    }
                    numOfTimes -= 1;
                    num += 1;
                }
            }, 500)


        }
    },
    computed: {
    },
    watch: {
        emptyDrugList(){
            console.log('EMPTYING DRUG LIST, BLUR');
            this.drugList = []
        },
        // WATCH MIGHT BE INEFFICIENT, MAY NEED A NEW SOLUTION, ALSO UNDEFINED COUNTS AS NEW DATA FOR SOME REASON SO HAD TO FILTER THAT OUT IN 972
        activePresChart(newData, oldData) {
            if (newData != oldData && newData != undefined && this.displayDropDown) {
                this.updateForm(newData);
                console.log('change has taken place', newData, oldData);
            }
        },
        checkHeight() {
            this.readHeightMeasurer();
        }
        // formTimes(newData, oldData){
        //     if(newData != oldData){
        //         console.log('form times have been updated');
        //         this.updateFrequencyBasedOnTimeFields();
        //     }
        // }
    },
    mounted() {
        if (!this.displayDropDown) {
            this.updateForm(this.activePresChart);
        }

        this.formTimes = {
            time1: '',
        }
        this.searchedDrugName = '';

        this.prescription_sign_payload.prescription_id = this.form.chart_payload.prescription_id
        console.log('this is the active pres chart SS', this.displayDropDown);
        console.log('this is the height', this.$refs['height-measurer' + this.parentIndex].clientHeight);
        console.log('IS IN THE REVIEW', this.reviewed);
        this.addedToReview = this.reviewed;

        this.start_date = new Date();
    }
}
</script>


<style lang="scss">
@import "../assets/css/main.scss";

.prescription-chart {
    .chart-row-item {
        border: none;
        flex-wrap: wrap;
        justify-items: flex-start;

        input {
            background-color: #F3F4F4;
            // width: 14em;
            min-width: 140px;
            width: 140px;
        }

        .p-dropdown,
        .p-multiselect {
            margin-left: 10px;
            width: 140px;
            text-align: left;
        }
    }

    .toggle-switch {
        padding: 0px;
    }
}

.p-dropdown-label {
    padding: 5px;
}

.p-dropdown,
.p-multiselect {
    padding: 0px !important;
}

#reason-input-container {
    .p-inputtext {
        width: 25em;
    }
}

.active-selected-chart {

    .p-dropdown-label,
    .p-dropdown-trigger {
        color: white !important;
    }
}

.dynamic-number-input {
    .p-inputnumber-input {
        min-width: 50px !important;
        width: 50px;
    }
}

.expansionFormat {
    .chart-form-select {
        background-color: transparent !important;
    }

    display: grid !important;
    grid-template-columns: 1fr 1fr;

    input {
        background-color: white !important;
    }
}

.toggle-switch-item {
    margin-top: 0 !important;
}
</style>