<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">New Destruction</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">

                                    <div>
                                        <p class="note-header">Notes</p>
                                        <textarea style="border-top-left-radius: 0; border-top-right-radius: 0;"
                                            name="notes" id="note-modal" cols="44" rows="16"
                                            placeholder="Enter notes here..." v-model="form.submit.notes"></textarea>
                                    </div>
                                    <!-- METHOD -->
                                    <input v-model="form.submit.method" placeholder="Method"
                                        style="position: relative; width: 100%; margin-bottom: 5px" />
                                    <p @click="addToPending()"
                                        :class="[form.submit.pending ? 'selected-hollow-button' : '']"
                                        :style="form.submit.pending ? {color: 'white'}:{color: '#0870DC'}"
                                        style="padding: 3px 10px; height: 30px;  border-radius: 8px; border: 2px solid #0870DC; cursor: pointer; margin-bottom: 5px; font-weight: bold;  ">
                                        {{ form.submit.pending ? 'Adding to pending list' : 'Add to pending list?' }}
                                    </p>
                                    <div v-if="!form.submit.pending">
                                        <input v-model="form.submit.courier_name" placeholder="Courier Name"
                                            style="position: relative; width: 100%; margin-bottom: 5px; background-color: #E5F1FE;" />
                                        <input v-model="form.submit.courier_notes" placeholder="Courier Notes"
                                            style="position: relative; width: 100%; background-color: #E5F1FE;" />
                                    </div>

                                </div>

                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel" style="display: flex; flex-direction: column;">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p v-if="patient.regulatory_information.length"><b>{{
                                                patient.regulatory_information[0].regulatory_type
                                            }}:</b>
                                                {{ patient.regulatory_information[0].data }}</p>
                                            <p><b>DOB:</b> {{
                                                patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{
                                                patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>



                                    <div v-if="!imprest_pom">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or patient medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div class="pom-imprest-select">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']">
                                                    Patient Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                   >
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>


                                    <!-- PATIENT MEDICATION SEARCH -->
                                    <div class="right-form-section-drug-entry" v-if="patient">
                                        <div style="width: 100%; margin-right: 10px">
                                            <PatientStockSearch :patientMeds="patientMeds" @selectStock="changeStock"/>
                                        </div>
                                        
                                        <input v-model="qty" min="0" type="number" placeholder="Quantity"
                                            style="position: relative; width: 30%; margin-right: 10px;" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty && validateQty(stock.quantity, qty)) { aliasSelect(stock) } }">
                                            Add</p>
                                    </div>


                                    <!-- <div class="right-form-section-drug-entry" v-if="imprest_pom == 'imprest'">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div>
                                    <div class="drug-variances-container" style="display: flex; height: fit-content"
                                        v-show='refinedDrugs.length'>
                                        <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                        <p v-for="(item, index) in refinedDrugs" :key="index" @click="aliasSelect(item)"
                                            style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                            {{ item.alias.name }} <span style="font-weight: bold">In Stock:
                                                {{ item.qty_left }}</span>
                                        </p>
                                    </div>
                                    <div v-if="Array.isArray(refinedDrugs) && refinedDrugs.length == 0">
                                        <p style="font-size: 1em;">No stock found for that item.</p>
                                    </div> -->

                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <div class="right-form-section-drug-entry" v-if="imprest_pom == 'imprest'">
                                       
                                            <StockSearch @drugselected="setdrug"/>
                
                                        <input v-model="qty" min="0" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 25%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty && validateQty(selectedDrug.full_item.qty_left, qty)) { checkIfExistingItem(selectedDrug.full_item) } }">
                                            Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>


                                    <div class="right-form-section-drug-container">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{item.patient_owned ? '[PATIENT OWNED]':''}} {{ item.stock.slice(0, 1).toUpperCase() + item.stock.slice(1) }}</p>
                                                    
                                                </td>
                                                <td>
                                                    <p>
                                                        {{ item.qty }} {{
                                                            item.qty != 'None in stock.' &&
                                                                (item.form[item.form.length - 1] != 'y' && item.qty > 1) ?
                                                                item.form : ''
                                                        }}{{
    item.qty > 1 && (item.form[item.form.length - 1] != 'y') ?
        's' : (item.form[item.form.length - 1] == 'y' && item.qty > 1) ?
            item.form.slice(0, item.form.length - 1) + 'ies' : item.form
}}
                                                        (Stock qty:{{
                                                            item.qty_left ?
                                                                item.qty_left : 0
                                                        }})</p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style='padding: 0'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red; font-weight: 500; font-size: 1em;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                        <p class="regular-button complete-button" style="margin-right: 10px;" @click="submitDestroy()">
                            Destroy {{ form.submit.pending ? 'Later' : '' }}</p>
                        <p class="regular-button complete-button" @click="submitDestroyAndCreateNew()">Destroy
                            {{ form.submit.pending ? 'Later' : '' }} + New</p>
                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SignatureModal dual="true" v-if="displaySignatureModal" :payload="realSubmit"
            :witness="$store.state.currentLocation.state == 'WA'" :optional="form.submit.pending"
            @isSuccessful="isSuccessful" :saveContext="saveContext" @close="close"
            :endpoint="'/location/' + $store.state.currentLocation.uuid + '/destroy'" />
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import StockSearch from '@/components/searches/StockSearch.vue';
import PatientStockSearch from '@/components/searches/PatientStockSearch.vue';
import axios from 'axios';

export default {
    props: ['transferType'],
    components: {
        // DualVerify,
        SignatureModal,
        PatientSearchModal,
        StockSearch,
        PatientStockSearch
    },
    data() {
        return {
            witness: false,
            selectedDrug: {},
            drugList: [],
            loadingDrugs: false,
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            reasonCat: '',
            reasonSubCat: '',
            qty: null,
            location: '',
            addNewLocation: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: '',
            form: {
                type: 'destroy',
                submit: {
                    patient_uuid: '',
                    prescriber_uuid: '',
                    method: '',
                    notes: '',
                    pending: false,
                    drugs: [],
                    courier_name: '',
                    courier_notes: ''
                }
            },
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'Destruction',
                patient: '',
                location: '',
                drugs: [],
                doubleSig: false
            },
            timeout: 0
        }
    },
    methods: {
        setdrug(drugFormatted) {
            this.selectedDrug = drugFormatted
        },
        show() {
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        debounceSearchDrugs() {
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.searchDrugs()
            }, 400); // delay
        },
        validateQty(qtyLeft, qty){
            if(qtyLeft < qty){
                this.errorMessage = 'Not enough quantity in stock';
                return false;
            }
            this.errorMessage = '';
            return true;
        },
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.alias.form[0].toUpperCase() + item.alias.form.slice(1);
            this.selectedDrug.strength = item.alias.strength;
            this.selectedDrug.measurement_unit = item.alias.measurement_unit;
            this.selectedDrug.name = item.alias.name[0].toUpperCase() + item.alias.name.slice(1);
            this.selectedDrug.tags = item.alias.tags;
            this.loadingDrugs = false;
        },
        searchDrugs() {
            if (this.stock) {
                this.loadingDrugs = true;
                axios.post('/get-stock-items', { drug: this.stock }, this.$store.state.header).then(res => {
                    console.log('this is the res data getstockitems', res.data);
                    this.$store.dispatch('getRefreshToken');
                    res.data.forEach(item => {
                        item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                        if (item.qty_left == 0) {
                            item.disabled = true;
                        } else {
                            item.disabled = false;
                        }
                    })
                    this.drugList = res.data;
                    this.errorMessage = '';
                    this.loadingDrugs = false;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                    console.log('this is the transfer stock items error', err.response.data);
                    this.errorMessage = err.response.data;
                })
            }
        },
        checkIfExistingItem(item) {
            let exit = false;
            if (this.form.submit.drugs.length) {
                this.form.submit.drugs.forEach(drugItem => {
                    console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } else if (!exit) {
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else {
                this.aliasSelect(item);
            }
        },
        changeStock(drugdata) {
            this.stock = drugdata.itemvalue
            this.qty = Number(drugdata.itemqty).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        close(value) {
            if (value) {
                this.witness = false;
                this.displaySignatureModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        isSuccessful(value) {
            if (value) {
                if (window.location.pathname == '/stock/destructions') {
                    this.$store.dispatch('getDestructionItems', {
                        sort_direction: '',
                        col: '',
                        page: 1,
                        location: this.$store.state.uuid,
                        filters: {
                            status: 'pending',
                            drug: '',
                            date: {
                                start: '',
                                end: ''
                            }
                        }
                    })
                }
                if (this.addNew) {
                    this.form.submit.drugs = [];
                    this.imprest_pom = '';
                    this.patient = '';
                    this.patient_uuid = '';
                    this.prescriber_uuid = '';
                    this.selectedPrescriber = '';
                } else {
                    this.closeModal();
                }
                
                
      
            }

        },
        addToPending() {
            this.form.submit.pending = !this.form.submit.pending;
        },
        addToReason(reason) {
            this.form.submit.reason = reason;
            this.reasonCat = '';
            console.log('DOES IT CONTAIN RETURN STOCK TO PHARM?', this.form.submit.reason.includes('Return Stock to Pharmacy'))
            if (this.form.submit.reason == 'Damaged or Expired Stock') {
                this.reasonCat = '';
                this.reasonSubCat = '';
            } else {
                this.reasonCat = 'Return Stock to Pharmacy';
            }
            if (reason == 'From Doctor' || reason == 'From Resident') {
                this.form.submit.reason = 'Return Stock to Pharmacy -' + reason
                this.reasonSubCat = reason;
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);
            // if((this.imprest_pom == 'imprest')){
            // axios.post('/get-stock', {alias_id:item.alias_id, location_id: this.$store.state.uuid }, this.$store.state.header).then(res=>{

            //         console.log('this is the quantity if condition',res, this.qty, res.data.qty_left, this.qty > res.data.qty_left);
            //         this.form.submit.drugs.unshift({tags: res.data.tags, stock: res.data.alias.name, form:res.data.alias.form, drug:res.data.uuid, qty_left: res.data.qty_left, qty: this.qty <= Number(res.data.qty_left) ? Number(this.qty).toFixed(1) : Number(res.data.qty_left).toFixed(1)});

            //     this.$store.dispatch('getRefreshToken');
            //     this.qty = null;
            //     this.refinedDrugs = [];
            //     this.noStock = false;
            // }).catch(err=>{
            //     console.log('err is', err.response.data);
            //     if(err.response.data == 'No stock has been recorded on this location for that drug alias.'){
            //         console.log('hitting the if condition');
            //         this.form.submit.drugs.unshift({tags: item.tags, stock:item.name, drug: item.alias_id, form: item.form, qty:'None in stock.', qty_left: 0});
            //     }
            //     this.qty = null;
            //     this.refinedDrugs = [];
            // })} else{
            //     this.form.submit.drugs.unshift({tags: item.tags, stock: item.name, form:item.form, qty_left:item.quantity, drug:item.uuid, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity)});
            // }

            if ((this.imprest_pom == 'imprest')) {
                this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left });
                this.qty = null;
                this.refinedDrugs = '';
                this.noStock = false;
                this.loadingDrugs = false;
                this.stock = '';
                this.selectedDrug = {};
                this.addingMed = false;
            } else {
                this.form.submit.drugs.unshift({ tags: item.alias ? item.alias.tags : '', patient_owned: item.bag_id ? true : false, stock: item.alias.name, form: item.alias.form, qty_left: item.quantity, drug: item.uuid, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity) });
            }
        },
        checkAndAddStock(drugName) {
            if (drugName) {
                // axios.post('/get-drug-aliases', {drug:drugName}, this.$store.state.header).then(res=>{
                //     console.log('this is the res data', res.data);
                //     this.$store.dispatch('getRefreshToken');
                //     this.refinedDrugs = res.data.drug_aliases;
                //     this.errorMessage = '';
                // }).catch(err=>{
                //     if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                //         console.log('yes, it does include expired')
                //         this.$router.push('/login');
                //     }
                // })
                axios.post('/get-stock-items', { drug: drugName }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.refinedDrugs = res.data.data;
                    this.errorMessage = '';
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        submitDestroy() {

            // this.$store.dispatch('submitForm',this.form);
            // let testID = "c309671f-2c62-3b0e-8088-4f0bd851f0e5";
            let newDrugSub = [];
            // LOCAL VARIABLE FOR DRUG CONTEXT AUTOCACHE
            let autoDrugs = [];
            this.addNew = false;
            this.form.submit.drugs.forEach(item => {
                let p = {
                    drug: item.drug,
                    qty: Number(item.qty)
                }
                let ad = {
                    drug: item.stock,
                    qty: Number(item.qty)
                }
                autoDrugs.push(ad);
                newDrugSub.push(p);
                if (item.tags && item.tags.length) {
                    this.witness = true;
                }
            });
            this.realSubmit = {
                drugs: newDrugSub,
                notes: this.form.submit.notes,
                method: this.form.submit.method,
                pending: this.form.submit.pending,
                prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
                patient_uuid: this.patient ? this.patient.uuid : '',
                // patient_uuid: this.patient ? testID : '',
                courier_name: this.form.submit.courier_name,
                courier_notes: this.form.submit.courier_notes
            };
            this.saveContext = {
                drugs: autoDrugs,
                location: JSON.parse(localStorage.getItem('currentLocation')).name,
                patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : 'Imprest/Emergency Stock',
                supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                doubleSig: this.witness ? true : false,
                type: 'Destruction'
            }
            // this.realSubmit.drugs = newDrugSub;
            console.log('this is the form to submit', this.form, newDrugSub);
            console.log('this is the SAVE CONTEXT', this.saveContext);
            console.log('DRUG SUB', newDrugSub);
            console.log(this.form.submit.drugs.length && this.form.submit.notes);
            if (this.form.submit.drugs.length && this.form.submit.notes) {
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drugs and notes are required.'
            }
            // this.refinedLocations = [];

        },
        selectPrescriber(prescriber) {
            console.log('this is the selected result', this.nonGP);
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    console.log('this is the patient register', res);
                    this.patientMeds = res.data;
                })
            }
        },
        submitDestroyAndCreateNew() {
            if (this.form.submit.drugs.length && this.form.submit.notes) {
                this.addNew = true;
                let newDrugSub = [];
                this.form.submit.drugs.forEach(item => {
                    let p = {
                        drug: item.drug,
                        qty: Number(item.qty)
                    }

                    if (item.qty > 0) {
                        newDrugSub.push(p);
                    }
                });
                this.realSubmit = {
                    drugs: newDrugSub,
                    notes: this.form.submit.notes,
                    method: this.form.submit.method,
                    pending: this.form.submit.pending,
                    prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
                    patient_uuid: this.patient ? this.patient.uuid : '',
                    courier_name: this.form.submit.courier_name,
                    courier_notes: this.form.submit.courier_notes
                };
                // this.$store.dispatch('submitDelivery',this.form);
                // this.refinedLocations = [];

                this.displaySignatureModal = true;
                this.errorMessage = '';
                this.stock = '';
            } else {
                this.errorMessage = 'Drugs and notes are required.'
            }
        },

        //     gpSearch(prescriber) {
        //         let headers = {
        //             "X-Algolia-API-Key": 'b7ecebf2d8e997c8e5d567658035de2a',
        //             "X-Algolia-Application-Id": 'H4AK5A0Y5K'
        //         }
        //         // let results = [];
        //         this.prescriberResults = [];
        //         console.log('this is the drug', prescriber);
        //         // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
        //         axios.post(`https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/*/queries`, 
        //         // params: `query=${prescriber}&hitsPerPage=5`,
        //         { 
        //           requests:[
        //             { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
        //             { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}` },
        //           ]  
        //         },
        //             { headers }).then(res => {
        //                 console.log('this is the algolia res', res.data.results);
        //                 res.data.results.forEach(indexResult=>{
        //                     console.log('these are the results for this particular index', indexResult);
        //                     if(indexResult.hits.length){

        //                         // results.push(indexResult.hits);
        //                         indexResult.hits.forEach(hit=>{
        //                             this.prescriberResults.push(hit);
        //                         })
        //                         console.log('this is the local results varaible', this.prescriberResults);
        //                     }

        //                 })
        //                 // this.prescriberResults = res.data.hits;
        //                 // state.commit('setDrugNames', res.data.hits);
        //             })

        //    },
    

},
mounted() {
    this.form.submit.type = this.transferType;
}

}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


 

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
