<template>
  <div>
    <div class="menu" style="min-width: 30em">
      <!-- FOR THEATRES ONLY FILTER ADD BACK IN && $store.state.currentLocation.type =='theatre' -->
      <router-link 
      @click="passViewToState('theatre')" v-if="$store.state.currentLocation.type =='theatre'"      
      class="menu-item" id="drug-register-item" v-bind:class="[$route.name == 'Theatre' ? 'active-menu-item' : '']" to="/theatre" >
        <p>Theatre</p>
      </router-link>
      <router-link 
      @click="passViewToState('dashboard')"    
      class="menu-item" id="dashboard-item" v-if="$store.state.userType == 'prescriber' || $store.state.userType == 'pharmacy'" v-bind:class="[$route.name == 'Dashboard' ? 'active-menu-item' : '']" to="/" >
        <p>Dashboard</p>
      </router-link>
      <router-link 
      @click="passViewToState('drug-register')" v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_drug_register')"     
      class="menu-item" id="drug-register-item" v-bind:class="[$route.name == 'Drug Register' ? 'active-menu-item' : '']" to="/drug-register" >
        <p>Drug Register</p>
      </router-link>
      <router-link 
      @click="passViewToState('stock')" 
      v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_stock_take') || $store.state.user.role.permissions.includes('can_view_transfers') || $store.state.user.role.permissions.includes('can_view_destruction') || $store.state.user.role.permissions.includes('can_view_deliveries')"        
      class="menu-item" id="stock-item" v-bind:class="[$route.name == 'Stocktake' || $route.name == 'Stock' || 
      $route.name == 'Transfers' || $route.name == 'Destructions' || $route.name == 'Deliveries' || $route.name == 'Requisitions' ? 'extended-active-menu-item' : '']" to="/stock" >
        <p>Stock</p>
      </router-link>
      <router-link 
      @click="passViewToState('patients')"    
      class="menu-item" id="patients-item" v-bind:class="[$route.name == 'Patients' ? 'active-menu-item' : '']"
      v-if="$store.state.user.role.permissions.includes('can_view_drug_register')" to="/patients" >
        <p>Patients</p>
      </router-link>
      <!-- <router-link v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_administer')"
      @click="passViewToState('administration')"    
      class="menu-item" id="administration-item" v-bind:class="[$route.name == 'Administration' ? 'active-menu-item' : '']" to="/administration" >
        <p>Administration</p>
      </router-link> -->
      <router-link 
      @click="passViewToState('reports')" v-if="($store.state.userType != 'prescriber') && $store.state.user.role.permissions.includes('can_view_transactions_report') || $store.state.user.role.permissions.includes('can_view_stock_report') || $store.state.user.role.permissions.includes('can_view_logs_report')"    
      class="menu-item" id="reports-item" v-bind:class="[$route.name == 'Reports' ? 'active-menu-item' : '']" to="/reports" >
        <p>Reports</p>
      </router-link>
      <router-link 
      @click="passViewToState('settings')"     
      class="menu-item" id="settings-item" v-bind:class="[$route.name == 'Settings' ? 'active-menu-item' : '']" to="/settings" >
        <p>Settings</p>
      </router-link>
    </div>
    <StockSubmenu v-show="$route.name == 'Stocktake' || $route.name == 'Stock' || 
      $route.name == 'Transfers' || $route.name == 'Destructions' || $route.name == 'Deliveries' || $route.name == 'Requisitions'" />
  </div>
</template>

<script>
import StockSubmenu from '@/components/navigation/StockSubmenu.vue'

export default {
  props:["page"],
  name: 'Menu',
  components:{
    StockSubmenu
  },
  data: function(){
    return{
      selectedView:'',
      isStock: false,
    }
  },
  methods:{
    passViewToState(view){
      console.log('this is the current view', view);
      this.$store.commit('changeView', view);
      this.selectedView = view;
      // remove any active indicator of a preselected sub menu item
      let submenuitems = document.getElementsByClassName('sub-menu-item');
      console.log('this is the submenu item list', submenuitems);
      for (let item of submenuitems) {
        item.classList.remove('active-sub-menu-item');
      }
      // submenuitems.forEach((item)=>{
      //   item.classList.remove('active-sub-menu-item');
      // });
      if(this.selectedView && this.selectedView.startsWith('stock')){
       this.isStock = true;
      } else{
        this.isStock = false;
      }

    }
  },
  mounted(){
    this.selectedView = window.location.pathname.slice(1);
    if(this.selectedView && this.selectedView.startsWith('stock')){
       this.isStock = true;
    }
   
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.menu-item{
  
  p{
    font-weight: bold;
  }
}
.active-menu-item{
  border-radius: 8px;
  background-color: white;
}

.extended-active-menu-item{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
}
.menu-item:first-child, .menu-item:last-child{
    border-radius: 8px;
}
</style>
