<template>
    <div>
        <div style="display: inline-block;display:flex ;justify-content: start; padding-left: 10px;">
            <h4><i class="pi pi-exclamation-circle"></i>{{ arrayOfAlertKind.AlertType }}</h4>
        </div>
        <!-- each cluster (for missed admin it's person) -->
        <div v-for="singlclusteredalert in arrayOfAlertKind.alerts" :key="singlclusteredalert.AlertType">
            <div>
                <!-- Patient name -->
                <div style="display:flex ;justify-content: start;align-items: center; height: 40px;">
                <h5 style="padding-left: 40px;"> <i style="padding-left:0px" class="pi pi-user"></i>{{
                    singlclusteredalert.patientInvolved }}</h5>
                </div>
                <ul style="list-style-type: none; text-align: left;margin-top: 0px;">
                    <li style="display: grid;grid-template-rows: auto auto;" v-for="singlalert in singleclusteredAlertsLimit(singlclusteredalert.alerts)" :key="singlalert.message">
                        <div style="display:grid ;justify-content: start;align-items: center; height: 40px;grid-template-columns: 90% 10%;">
                        <p> {{ singlalert.body.message.split('||')[0] }} was <b>missed at {{
                            singlalert.body.message.split('||')[3] }}</b></p>
                            <button class="small-red-close-button">
                                <i class="pi pi-times" style="padding: 0"
                                    @click="dismissAlert(singlalert.id, singlalert.index)"></i>
                            </button>
                        </div>
                    </li>
                </ul>
                <div class="card flex justify-content-center">
                    <Sidebar style="width: 40%;" v-model:visible="visible" position="right">
                        <h2>{{ selectedCluster.patientInvolved }}'s Missed Adminstrations</h2>
                        <table>
                            <tr v-for="singlalert in selectedCluster.alerts" :key="singlalert.message">
                                <div style="display:grid ;justify-content: start;align-items: center; height: 40px;grid-template-columns: 95% 5%;">
                        <p> {{ singlalert.body.message.split('||')[0] }} was <b>missed at {{
                            singlalert.body.message.split('||')[3] }}</b></p>
                            <button class="small-red-close-button">
                                <i class="pi pi-times" style="padding: 0"
                                    @click="dismissAlert(singlalert.id, singlalert.index)"></i>
                            </button>
                        </div>
                            </tr>
                        </table>
                    </Sidebar>
                    <Button @click="handlesidebar" :id="singlclusteredalert.patientInvolved"
                        v-if="numberOfClusteredAlerts(singlclusteredalert) > 3">See All ({{
                            numberOfClusteredAlerts(singlclusteredalert) }})</Button>
                </div>
            </div>




        </div>

    </div>
</template>

<script>
import { computed } from 'vue';

export default {
    props: ['arrayOfAlertKind'],
    data() {
        return {
            visible: false,
            selectedCluster: {}
        }
    },
    methods: {
        numberOfClusteredAlerts(clust) {
            return Object.keys(clust.alerts).length
        },
        singleclusteredAlertsLimit(singlclusteredalert) {
            console.log(Object.fromEntries(Object.entries(singlclusteredalert).slice(0, 3)))
            return Object.fromEntries(Object.entries(singlclusteredalert).slice(0, 3));
        },
        handlesidebar(event) {
            this.visible = true;
            const buttonclusterkey = event.currentTarget.id
            this.selectedCluster = this.arrayOfAlertKind.alerts[buttonclusterkey]
        },
        dismissAlert(id, index) {
            console.log(id)
            this.$store.dispatch('readAlert', { 'id': id, 'index': index });
        }
    }



}
</script>

<style lang="scss" scoped>
.alert-count {
    color: white;
    background-color: #E04F39;
    border-radius: 8px;
    font-size: 13px;
    margin-left: 10px;
    font-weight: bold;
    padding: 1px 5px;

    p {
        margin: 0;
    }
}

.alerts-content-container {
    display: flex;
    padding: 10px;
    // border-bottom: 1px solid #a4a4a4;
    // background-color: rgba(224,79,57, 0.5);
    // background-color: #f3f4f4;
    gap: 10px;
    justify-content: space-between;
}

.alert-items {
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.alert-item-type {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    margin: 0;
}

.alert-item-content {
    text-align: left;
}

.small-red-close-button {
    background-color: #E04F39;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    padding: 0px;
    cursor: pointer;
    margin: 1px 0;

    .pi {
        font-size: 0.7rem !important;
        color: white;
        font-weight: bold;
    }
}

.spacer {
    flex-grow: 1;
}</style>