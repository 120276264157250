<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel">
      <h3 class="panel-header">Actions</h3>
      <ActionsMenu />
    </div>
    <div class="panel">
      <h3 class="panel-header">Drug Register | <span style="color: darkslategray">{{ $store.state.currentLocation.label }}</span></h3>
      <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_drug_register") || $store.state.reponsiblePersonError' >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">{{$store.state.reponsiblePersonError ? $store.state.reponsiblePersonErrorMessage : 'You do not have permission to access this feature. Please talk to your admin if you require access.' }}</h3>
      </div>
      <div v-else class="drug-register-table table-container" :class="[$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length ? 'adjust-for-drug-variance' : '']">
        <div class="filters">
          <div class="text-search-container search-container">
            <input v-model="form.filters.drug" placeholder="Medication..." style="position: relative" />
            <div class="drug-search-result-container" v-if='form.filters.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
              <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
            </div>
            
          </div>
          <div class="date-search-container search-container">
            <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
          </div>
          <div class="tofrom-search-container search-container">
            <input v-model="form.filters.to_from" placeholder="To/From..." />
          </div>
          <div class="enteredby-search-container search-container">
            <input v-model="form.filters.entered_by" placeholder="Entered by..." />
          </div>
          <div class="enteredby-search-container search-container">
            <input v-model="form.filters.transaction_id" placeholder="Transaction ID..." />
          </div>
            <button class="button submit-button" @click="form.page = 1, loadDrugRegister()">Search</button>
            <button class="button clear-button" @click="clearFilteredDrugRegister()">Clear</button>
            <button class="button" @click="toggleImprest()"
            :class="$store.state.drugRegImprestToggle ? 'blue-button' : 'outline-button'"
            :style="$store.state.drugRegImprestToggle ? { color: 'white' } : { color: '#0870DC' }" style="margin: 6px 0px 6px 10px !important; padding: 0 30px;"
            >Imprest Only</button>
            <button class="button" @click="toggleDestruction()"
            :class="$store.state.drugRegDestructionToggle ? 'blue-button' : 'outline-button'"
            :style="$store.state.drugRegDestructionToggle ? { color: 'white' } : { color: '#0870DC' }" style="margin: 6px 0px 6px 10px !important; padding: 0 30px;"
            >Destruction Only</button>
        </div>
        <div class="drug-variances-container" style="display: flex;" v-show='$store.state.drugEntries.drug_aliases && $store.state.drugEntries.drug_aliases.length'>
          <p style="padding: 5px 10px; margin-right: 10px;">
            Refine your search: 
          </p>
          <p v-for="(item, index) in $store.state.drugEntries.drug_aliases" :key="index" @click="aliasSearch(item)" class="drug-variance-option">{{item}}</p>
          <!-- <p style="padding: 5px 10px; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer" @click="aliasSearch('Endone 10mg')" >Endone 10mg</p> -->
        </div>
        <div v-if="!$store.state.loadingDrugRegisterData" style="overflow-y: auto;">
          <table class="data-table" cellspacing="0">
            <tr class="table-headers">
              <th style="width: 30px;"></th>
              <th style="width: 10em">
                <h3 @click="form.sort_direction == 'asc' && form.col == 'created_at' ? sortBy('created_at', 'desc'): sortBy('created_at', 'asc')"  style="padding-right: 10px;">Date</h3>
                <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  date-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
                  <i class="pi pi-sort date-sort" v-else @click="sortBy('created_at', 'asc')"></i>
                </div>
              </th>
              <th>
                <h3 @click="sortBy('drug', 'desc')">Drug</h3>
                <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort drug-sort" v-if="form.col =='drug' && form.sort_direction =='asc'" @click="sortBy('drug', 'desc')"></i>
                  <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
                </div>
              </th>
              <th style="width: 120px; padding-left: 10px">
                <h3 @click="sortBy('action', 'desc')" >Action</h3>
                <div v-if="form.col == 'action'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort action-sort" v-if="form.col =='action' && form.sort_direction =='asc'" @click="sortBy('action', 'desc')"></i>
                  <i class="pi pi-sort action-sort" v-else @click="sortBy('action', 'asc')"></i>
                </div>
              </th>
              <th>
                <h3 @click="sortBy('to_from', 'desc')">To/From</h3>
                <div v-if="form.col == 'to_from'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort to_from-sort" v-if="form.col =='to_from' && form.sort_direction =='asc'" @click="sortBy('to_from', 'desc')"></i>
                  <i class="pi pi-sort to_from-sort" v-else @click="sortBy('to_from', 'asc')"></i>
                </div>
              </th>
              <th>
                <h3 @click="sortBy('resident', 'desc')">Patient</h3>
                <div v-if="form.col == 'resident'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort script-sort" v-if="form.col =='resident' && form.sort_direction =='asc'" @click="sortBy('resident', 'desc')"></i>
                  <i class="pi pi-sort script-sort" v-else @click="sortBy('resident', 'asc')"></i>
                </div>
              </th>
              <th>
                <h3 @click="sortBy('entered_by', 'desc')">Entered By</h3>
                <div v-if="form.col == 'entered_by'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort enteredby-sort" v-if="form.col =='entered_by' && form.sort_direction =='asc'" @click="sortBy('entered_by', 'desc')"></i>
                  <i class="pi pi-sort enteredby-sort" v-else @click="sortBy('entered_by', 'asc')"></i>
                </div>
              </th>
              <th style="width: 60px; padding-left: 10px;">
                <h3 @click="sortBy('in', 'desc')">In</h3>
                <div v-if="form.col == 'in'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort in-sort" v-if="form.col =='in' && form.sort_direction =='asc'" @click="sortBy('in', 'desc')"></i>
                  <i class="pi pi-sort in-sort" v-else @click="sortBy('in', 'asc')"></i>
                </div>
              </th>
              <th style="width: 60px;">
                <h3 @click="sortBy('out', 'desc')">Out</h3>
                <div v-if="form.col == 'out'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort out-sort" v-if="form.col =='out' && form.sort_direction =='asc'" @click="sortBy('out', 'desc')"></i>
                  <i class="pi pi-sort out-sort" v-else @click="sortBy('out', 'asc')"></i>
                </div>
              </th>
              <th style="width: 80px;">
                <h3 @click="sortBy('balance', 'desc')">Balance</h3>
                <div v-if="form.col == 'balance'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort balance-sort" v-if="form.col =='balance' && form.sort_direction =='asc'" @click="sortBy('balance', 'desc')"></i>
                  <i class="pi pi-sort balance-sort" v-else @click="sortBy('balance', 'asc')"></i>
                </div>
              </th>
            </tr>
            <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
            <tr @click="showContent(item, index)" :class="[ item.display && (item.display == true) && !item.reversed ? 'open-transfer-row': '', item.reversed ? 'reversed-row': '', item.action == 'reversal' || ((item.action == 'Balance check' && item.in != item.out) || (item.action == 'Balance check for pending' && item.in != item.out)) ? 'mismatch-status-color': ((item.action == 'Balance check' && item.in == item.out) || (item.action == 'Balance check for pending' && item.in == item.out)) ? 'correct-status-color' : ((item.action == 'pending destruction' && item.in > item.out) || (item?.notes?.includes('PENDING DESTRUCTION ITEM'))) ? 'warning-status-color' : '']"
            :style="item.action == 'reversal' ? {fontWeight:'normal', color: 'black'}:{}"
            >
              <td style="min-width: 30px;" :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important', borderLeft:'1.5px solid #0870DC !important', borderBottomLeftRadius:'6px', borderTopLeftRadius:'6px'}:{}" >
                  <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                  <i v-else class="pi pi-angle-right"></i>
              </td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}"  style="padding-right: 10px;">{{item?.date?.slice(0,-3)}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{item.drug}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}"  style="width: 120px; padding-left: 10px;">{{item.action && (item.action != 'pending destruction') ? item.action.charAt(0).toUpperCase() + item.action.slice(1) : (item.out > item.in) ? 'Sent Pending Destruction' : 'Pending destruction'}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}"  style=" padding-left: 10px;">{{item.to_from}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}"  style=" padding-left: 10px;">{{item.resident}}</td>
              <!-- <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" ><span v-if="item.to">{{item.to}}</span> <span v-if="item.from">{{item.from}}</span></td> -->
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >{{item.entered_by}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}"  style="width: 60px; padding-left: 10px;">{{((item.action == 'Balance check') || (item.action == 'Balance check for pending')) ? '--' : item.in}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}"  style="width: 60px;">{{((item.action == 'Balance check') || (item.action == 'Balance check for pending')) ? '--'  : item.out}}</td>
              <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important', borderRight:'1.5px solid #0870DC !important', borderBottomRightRadius:'6px', borderTopRightRadius:'6px'}:{}"  style="width: 80px;">{{item.balance}}</td>
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
                <div class="expanded-transfer-container">
                    <div v-if="!item.expandedInfo" style="margin: 0 auto; margin-bottom: 1em;">
                        <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                    </div>
                    <div class="drug-register-specs-container" v-if="item.expandedInfo">

                        <div class="transfer-type-field">
                            <p class="field-title">Transaction Type</p>
                            <!-- v-if="item.expandedInfo.transaction_type" -->
                            <p class="field-value" >{{item.action ? item.action.charAt(0).toUpperCase() + item.action.slice(1) : ''}}</p>
                        </div>
                        <div class="script-field">
                            <p class="field-title">Script/Invoice #</p>
                            <p class="field-value">{{item.script_invoice ? item.script_invoice : 'N/A'}}</p>
                        </div>
                        <div class="date-entered-field">
                            <p class="field-title">Date Entered</p>
                            <p class="field-value">{{item.date}}</p>
                        </div>
                        <div class="to-from-field">
                            <p class="field-title">To/From</p>
                            <p class="field-value">{{item.to_from ? item.to_from : 'N/A'}}</p>
                        </div>
                        <div class="location-contact-field">
                            <p class="field-title">Address</p>
                            <div class="field-value-container">
                                <p v-if="item.expandedInfo.address">{{item.expandedInfo.address.street_address}}, {{item.expandedInfo.address.suburb ? item.expandedInfo.address.suburb:''}}, 
                                  {{item.expandedInfo.address.city ? item.expandedInfo.address.city : ''}}, {{item.expandedInfo.address.state ? item.expandedInfo.address.state : ''}}
                                  {{item.expandedInfo.address.postcode ? item.expandedInfo.address.postcode : ''}}, {{item.expandedInfo.address.country ? item.expandedInfo.address.country : ''}}</p>
                                  <p v-else>N/A</p>
                            </div>
                        </div>
                        <div class="fulfilled-by-field">
                            <p class="field-title">Entered By</p>
                            <p class="field-value">{{item.entered_by ? item.entered_by : 'N/A'}}</p>
                        </div>
                        <div class="fulfilled-time-field">
                            <p class="field-title">Witnessed By</p>
                            <p class="field-value">{{item.expandedInfo.witness ? item.expandedInfo.witness : 'N/A'}}</p>
                        </div>
                    </div>

                    <div class="reverse-container" v-if="item.expandedInfo && (item.expandedInfo.reversed_notes || item.expandedInfo.reversed_by || item.expandedInfo.reversed_date || item.expandedInfo.reversed_witness_by)">
                      <div class="reversed-notes-field" :style="[item.expandedInfo.reversed_notes ? {minWidth: '15em', paddingRight: '5px'}:{}]">
                          <p class="field-title">Reverse Notes</p>
                          <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversed_notes ? item.expandedInfo.reversed_notes:'N/A'}}</p>
                      </div>
                      <div class="reversed-by-field">
                          <p class="field-title">Reversed By</p>
                          <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversed_by ? item.expandedInfo.reversed_by :'N/A'}}</p>
                      </div>
                      <div class="reversed-date-field">
                          <p class="field-title">Reversed Date</p>
                          <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversed_date ?  item.expandedInfo.reversed_date :'N/A'}}</p>
                      </div>
                      <div class="reversed-witness-by-field">
                          <p class="field-title">Reverse Witness By</p>
                          <p class="field-value" style="border: 1px solid red">{{item.expandedInfo.reversed_witness ?  item.expandedInfo.reversed_witness :'N/A'}}</p>
                      </div>

                    </div>

                    <div class="transfer-notes-container" style="min-width: 15em" v-if="item.expandedInfo">
                        <div class="date-entered-field">
                            <p class="field-title">Transaction ID <span class="blue-button copy-button" style="margin-left: 10px;" @click="copyTransactionID(item)">{{item.copied ? 'Copied!' : 'Copy ID'}}</span></p>
                            <!-- <p class="field-value">{{item.action != 'Balance check' ? item.transaction_id : item.uuid}}</p> -->
                            <p class="field-value">{{item.transaction_id}}</p>
                        </div>
                        <p class="field-title">Notes</p>
                        <div class="field-notes" style="width: 280px; height: 150px;">
                            <p >{{item.expandedInfo.notes}}</p>
                            <p>{{ item.expandedInfo.discrepancy }}</p>
                        </div>
                    </div>
                </div>
                  <!-- TRANSFER ACTIONS -->
                  <div class="transfer-actions-container" v-if="item.expandedInfo">
                      <!-- <button @click="drugEntryAction('Cancel', item.uuid)" class="transfer-action-button error-button" v-show="item.transaction_type != 'Destruction' && item.transaction_type != 'Reversal'">Cancel Entry</button> -->
                      <!-- <button @click="drugEntryAction('Reject', item.uuid)" class="transfer-action-button error-button" v-show="item.transaction_type != 'Destruction' && item.transaction_type != 'Reversal'">Reject Entry</button> -->
                      <button @click="drugEntryAction('Reverse', item.uuid)" class="transfer-action-button error-button" v-show="item.action != 'destruction' && item.action != 'requisition pickup' &&  item.action != 'supply' && item.action != 'requisition complete' && item.action != 'reversal' && item.action != 'adjustment' && item.action != 'Balance check' && !item.expandedInfo.reversed_date && $store.state.user.role.permissions.includes('can_do_reversal')">Reverse Entry</button>
                      <!-- <button @click="drugEntryAction('Approve', item.uuid)" class="transfer-action-button complete-button" v-show="item.transaction_type != 'Destruction' && item.transaction_type != 'Reversal'">Complete Entry</button> -->
                      <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                  </div>
              </tr> 
            </tbody>
          </table>
        </div>
        <div v-else>
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
        <div class="pagination-links">
          <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
          <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
          <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
        </div>
       </div>
    </div>
    <ResetPasswordModal v-if="displayResetPasswordModal" @close="close" />
    <ActionModal v-if="displayActionModal" @close="close" :toAction="toAction" endpoint="/single-drug-entry/reverse" />
  </div>
</template>


<script>
// @ is an alias to /src
import ActionModal from '@/components/modals/ActionModal.vue';
import ActionsMenu from '@/components/actions/ActionsMenu.vue';
import ResetPasswordModal from '@/components/modals/ResetPasswordModal.vue';
import axios from 'axios';

export default {
  components: { 
    ActionsMenu,
    ActionModal,
    ResetPasswordModal,
   },
  data(){
    return{
      drugNames: [],
      registerData: [],
      toAction:{},
      displayActionModal: false,
      displayResetPasswordModal: false,
      date:[],
      clearForm:{
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters:{
          transaction_id: '',
          to_from:'',
          entered_by:'',
          date:{
            start:'',
            end:''
          },
          drug:'',
          imprest_only: false
        }
      },
      form:{
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters:{
          transaction_id: '',
          to_from:'',
          entered_by:'',
          date:{
            start:'',
            end:''
          },
          drug:'',
          imprest_only: this.$store.state.drugRegImprestToggle,
          destruction_only: this.$store.state.drugRegDestructionToggle,

        }
      },
      testData:[
        //   {
        //     completed_date:'10/03/2021 09:14',
        //     request_date:'20/02/2021',
        //     completed_by:'Jack Jeans',
        //     request_by:'Sally Adams',
        //     type:'Sent',
        //     to_location:'Melbourne',
        //     from_location:null,
        //     status:'Pending',
        //     uuid:'uuid939393'
        // },
        // {
        //     completed_date:'10/03/2021 09:14',
        //     request_date:'20/02/2021',
        //     completed_by:'Jack Jeans',
        //     request_by:'Sally Adams',
        //     type:'Received',
        //     to_location:'Melbourne',
        //     from_location:null,
        //     status:'Pending',
        //     uuid:'uuid939393'
        // },
        // {
        //     completed_date:'15/03/2021 10:00',
        //     request_date:'23/02/2021',
        //     completed_by:'Ben Olly',
        //     request_by:'Jo Pink',
        //     type:'Received',
        //     to_location:null,
        //     from_location:'Geelong',
        //     status:'Completed',
        //     uuid:'uuid9000000'
        // },
        // {
        //     completed_date:'15/03/2021 10:00',
        //     request_date:'23/02/2021',
        //     completed_by:'Ben Olly',
        //     request_by:'Jo Pink',
        //     type:'Received',
        //     to_location:null,
        //     from_location:'Geelong',
        //     status:'Reversed',
        //     uuid:'uuid9000000'
        // },
        // {
        //     completed_date:'15/03/2021 10:00',
        //     request_date:'23/02/2021',
        //     completed_by:'Ben Olly',
        //     request_by:'Jo Pink',
        //     type:'Sent',
        //     to_location:null,
        //     from_location:'Geelong',
        //     status:'Rejected',
        //     uuid:'uuid9000000'
        // }

      ]
    }
  },
  methods:{
    toggleImprest(){
      this.$store.commit('setdrugRegImprestToggle', !this.$store.state.drugRegImprestToggle);
      setTimeout(()=>{this.loadDrugRegister()}, 200);
      
    },
    toggleDestruction(){
      this.$store.commit('setdrugRegDestructionToggle', !this.$store.state.drugRegDestructionToggle);
      setTimeout(()=>{this.loadDrugRegister()}, 200);
      
    },
    close(value){
      if(value){
        this.displayActionModal = false;
        this.displayResetPasswordModal = false;
        this.loadDrugRegister();
      }
    },
    drugEntryAction(actionType, id){
      this.toAction.action = actionType;
      this.toAction.uuid = id;
      this.displayActionModal = true;
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getDrugRegister', this.form)
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadDrugRegister(){
      console.log('this is the date', this.date);
        if(this.date.length){
          if(typeof this.date == "string"){
            this.form.filters.date.start = this.date.split("-")[0]
            this.form.filters.date.end = this.date.split("-")[1]
          } else{
            this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
            this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
          }
          this.$store.dispatch('getDrugRegister', this.form);
        } else{
          this.form.filters.date.start ='';
          this.form.filters.date.end = '';
          this.$store.dispatch('getDrugRegister', this.form);
        }
    },
    aliasSearch(alias){
      this.form.filters.drug = alias;
      this.$store.dispatch('getDrugRegister', this.form);
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getDrugRegister', this.form);
    },
    copyTransactionID(item) {
      item.copied = true;
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = item.transaction_id;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setTimeout(()=>{item.copied = false}, 500)
    },
    clearFilteredDrugRegister(){
      this.form = {
        location: this.$store.state.uuid,
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters:{
          transaction_id: '',
          to_from:'',
          entered_by:'',
          date:{
            start:'',
            end:''
          },
          drug:'',
          imprest_only: this.$store.state.drugRegImprestToggle,
          destruction_only: this.$store.state.drugRegDestructionToggle,
        }
      };
      this.date = [];
      this.$store.dispatch('getDrugRegister', this.form);
    },
    showContent(item, index){
        console.log('this the selected log', item)
        // let refid=`expand-${index}`;

        if(item.display && (item.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            item.display = !item.display;
        } else{
            item.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
            axios.post('/single-drug-register-entry', {uuid: item.uuid}, this.$store.state.header).then(res=>{
                console.log('this is the state function to get a single drug register item', res.data);
                item.expandedInfo = {
                  transaction_type:res.data.transaction_type,
                  // still waiting on script Invoice
                  address: res.data.address,
                  witness:res.data.witness,
                  notes: res.data.notes,
                  discrepancy: item.action == 'Balance check' && item.in != item.out ? `Discrepancy found: [EXPECTED: ${item.in}] - [COUNTED: ${item.out}]` : '',
                  reversed_by:res.data.reversed_by,
                  reversed_witness:res.data.reversed_witness,
                  reversed_notes: res.data.reversed_notes,
                  reversed_date:res.data.reversed_date
                }
            }).catch(err=>{
                  if(err.response && err.response.data && err.response.data.split(' ').includes('expired')){
                      console.log('yes, it does include expired')
                      this.$router.push('/login');
                  }
              })
            

        }
    },
    alertMe(){
      console.log('closing')
    }
  },
  mounted(){
    this.loadDrugRegister();
    if(localStorage.getItem('reset_password') == 'true'){
        this.displayResetPasswordModal = true;
        // localStorage.setItem('reset_password', false);
      } else{
        // this.displayResetPasswordModal = false;
      }
  },
  beforeUnmount() {
      this.$store.commit('setDrugEntries', []);
  },
  created() {
    // window.addEventListener('beforeunload', function (event) {
    //     document.cookie = 'auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    //     alert('leaving', event) // logs to my logger
    //   }, false)
    window.addEventListener("beforeunload", function () {
      // var confirmationMessage = window.event;

      // (e || window.event).returnValue = confirmationMessage;
      // console.log('this is the performance', window.performance.getEntriesByType("navigation")[0].type);
      console.info( "This page is reloaded", performance.type );
      if (performance.type == performance.TYPE_RELOAD) {
        alert( "This page is reloaded", performance.type );
      } else {
        document.cookie = 'auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        console.log('this is being closed');
      }
      // return confirmationMessage;                            //Webkit, Safari, Chrome
    });
  },
  computed:{
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    },
    getDrugNames(){
      return this.$store.getters.getDrugNames
    }
  }
}
</script>
