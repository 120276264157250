<template>
  <div class="drug-register-view view">
        <div class="panel" style="margin-left: 3em; width: 95%;">
            <!-- HEADER ANF FILTER -->
            <div style="display: flex; align-items: center;">
                <h3 class="panel-header" style="margin: 0; margin-right: 1em;">Active Charts</h3>
                <div class="filters">
                    <div class="text-search-container search-container">
                        <div class="text-search-container search-container" style="display: flex; align-items: center">
                            <input v-model="searchedDrug" placeholder="Medication Name" style="position: relative; width: 100%; margin-bottom: 5px" />
                            <div style="cursor: pointer;" @click="searchDrugs(searchedDrug)"><i class="pi pi-search" style="font-weight: bold"></i></div>
                        </div>
                            <div class="drug-variances-container" 
                            style="position: absolute; margin-left:5px; padding-top: 10px; background-color: white; display: flex; flex-wrap: wrap; align-items: flex-start;
                            height: fit-content; border: 1px solid lightgrey; box-shadow: 0 0 0 3px #f8f8f8;
                            height: fit-content; max-height: 150px; overflow-y: scroll; padding-left: 15px; width: 600px;"
                            v-show='drugList.length'>
                            <p v-for="(item, index) in drugList" :key="index" @click="drugSelect(item)"
                            style="padding: 3px 10px; height: 30px; margin-right: 5px; margin-top: 2px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                            {{item.name}}
                            </p>
                        </div>
                        
                    </div>
                    
                    <div class="tofrom-search-container search-container">
                        <!-- <input v-model="searchFilter.chart_type" placeholder="Chart type..." /> -->
                        <select @change="selectChart()"  id="chart-type" class="chart-form-select"
                        style="font-size: 15px; height: 35px; border-radius: 8px; padding: 5px 10px; background-color: #F3F4F4; ">
                            <option value="" disabled selected>Chart Type</option>
                            <option v-for="(item, index) in charts" :key="index" >{{item.name}}</option>
                        </select>
                    </div>

                    <div style="display: flex; align-items: center">
                        <div class="select-filters-container" style="height: 35px;">

                        <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.sign_filter='all'}" 
                        v-bind:class="[searchFilter.sign_filter=='all' ? 'active-select-filter' : '']" class="select-filter-item">All</p>

                        <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.sign_filter='unsigned'}" 
                        v-bind:class="[searchFilter.sign_filter=='unsigned' ? 'active-select-filter' : '']" class="select-filter-item">Unsigned Only</p>

                        <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.sign_filter='signed'}" 
                        v-bind:class="[searchFilter.sign_filter=='signed' ? 'active-select-filter' : '']" class="select-filter-item">Signed Only</p>
                    
                        </div>
                        
                    </div>

                        <button class="button submit-button" @click="searchActiveCharts()">Search</button>
                        <button class="button clear-button" @click="clearChartFilter()">Clear</button>


                        <router-link :to="'/patient/'+$route.params.id+'/create-chart'" style="text-decoration: none; margin-left: 3em">
                            <div style="background-color: white; height: 35px; display: flex; align-items: center; border-radius: 8px; "><i class='pi pi-plus' style="font-weight: bold"></i></div>
                        </router-link>
                    </div>
                
            </div>
            
            <!-- HEADER AND FILTER -->
            <div class="active-chart-table-container">
                
                <div class="chart-approval-only" style="display: flex; flex-wrap: wrap; margin: 1em 1em; margin-left: 0;" v-for="(item, index) in activeCharts" :key="index">
                 <div>
                   <div class="chart-form-container" style="width: fit-content; min-width: 455px;" :style="{ border:'3px solid '+item.color}" v-if="item">
                   <h4 :style="{ backgroundColor:item.color}">{{item.chart_type}}</h4>
                   <div class="chart-form">
                       <div class="first-chart-row chart-row" style="height: 60px; width: 455px">
                           <div class="first-chart-row-item chart-row-item">
                              <p class="active-chart-field" v-tooltip="'Medicine Name'" placeholder="Medicine">{{item.drug_name}} {{item.strength}} {{item.form_description}} {{item.form}} </p>
                           </div>
                       </div>
                       <div class="chart-row" style="flex-wrap: wrap; width: 455px">
                            
                           <div class="chart-row-item" style="width: fit-content" v-for="(prop, index) in item.inputs" :key="index">
                               <p class="active-chart-field" style="min-width: 360px; text-align: left; overflow-y:scroll;" v-tooltip="'Reason'" v-if="prop == 'reason'" placeholder="Reason">{{item.reason}}</p>
                               <div class="toggle-switch"  v-if="prop == 'single_multi'" :style="{border: '2px solid'+item.color}">
                                   <p class="toggle-switch-item" :style="{color: item.color}" 
                                   >{{item.tags.includes('single') ? 'Single Dose':'Multi Dose'}}</p>
                               </div>
                               <p class="active-chart-field" style="min-width: 5em; text-align: left;" v-tooltip="'Dose'" placeholder="Dose" v-if="prop == 'dose'" >{{item.dose}} {{item.form_description}}</p>
                               <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Route'" placeholder="Route" v-if="prop == 'route'" >{{item.route}}</p>
                               <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Hourly Frequency'" placeholder="Hourly Frequency" v-if="prop == 'hourly_frequency'" >{{item.hourly_frequency}}</p>
                               <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Max Dose in 24hrs'" placeholder="Max Dose in 24hrs" v-if="prop == 'max_dose'" >{{item.max_dose}} {{item.form_description}}</p>

                               <div class="toggle-switch" style="margin: 0 auto;" v-if="prop == 'packed'" :style="{border: '2px solid'+item.color, color: item.color}">
                                   <p class="toggle-switch-item"
                                   >{{item.tags.includes('packed') ? 'Packed' : 'Non-Packed'}}</p>
                                   <!-- <p class="toggle-switch-item" :style="item.tags.includes('non-packed') ? { color: 'white', backgroundColor: item.color}: {color: item.color}" 
                                   >Non-Packed</p> -->
                               </div>
                               <div class="toggle-switch" style="margin: 0 5px" v-if="prop == 'now_or_next_pack'" :style="{border: '2px solid'+item.color}">
                                   <p class="toggle-switch-item" style="border-right: 2px solid" :style="item.tags.includes('now') ? { color: 'white', backgroundColor: item.color}: {color: item.color}" 
                                  >Now</p>
                                   <p class="toggle-switch-item" :style="item.tags.includes('next-pack') ? { color: 'white', backgroundColor: item.color}: {color: item.color}" 
                                   >Next Pack</p>
                               </div>

                               <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Frequency'" placeholder="Frequency" v-if="prop == 'frequency'" >{{item.frequency}}</p>
                              
                               <p class="active-chart-field" style="min-width: 448px;max-height: 100px; text-align: left; overflow-y:scroll;" v-tooltip="'Indication'" placeholder="Indication" v-if="prop == 'indication'" >{{item.indication}}</p>

                               <p class="active-chart-field" style="min-width: 448px; max-height: 100px; text-align: left; overflow-y:scroll;" v-tooltip="'Comments/Instructions'" placeholder="Comments/Instructions" v-if="prop == 'comments'" >{{item.comments}}</p>

                               <p class="active-chart-field" v-tooltip="'Prescription Date'" placeholder="Prescription Date" v-if="prop == 'prescription_date'" >{{item.prescription_date}}</p>
                              
                               <p class="active-chart-field" style="min-width: 10em; text-align: left;" v-tooltip="'Start Date'" placeholder="Start Date" v-if="prop == 'start_date'" >{{item.start_date}}</p>
                               <p class="active-chart-field" style="min-width: 10em; text-align: left;" v-tooltip="'Stop Date'" placeholder="Stop Date" v-if="prop == 'stop_date'" >{{item.stop_date}}</p>
                               
                               <p class="active-chart-field" style="min-width: 9em; text-align: left;" v-tooltip="'Date & Time'" placeholder="Date & Time" v-if="prop == 'datetime'" >{{item.start_date}}</p>
                                <div class="toggle-switch" style="margin: 0 auto; min-width: 400px;" v-if="prop == 'substitution'" :style="{border: '2px solid'+item.color}">
                                   <p class="toggle-switch-item" style="width: 100%" :style="{color: item.color}" 
                                   >{{item.substitution ? 'SUBSTITUTION ALLOWED': 'NO SUBSTITUTION ALLOWED'}}</p>
                               </div>
                           </div>
                           <div class="chart-row-item signature-item" style="width: fit-content" v-if="item.prescriber" @click="()=>{if(!item.signatures.includes('prescriber')){displaySignatureModal = true; prescriberSig = true}}">
                                <!-- PRESCRIBER SIGNATURES -->
                                <p class="active-chart-field signature-field" v-if="item.prescriber" @click="!item.signatures.includes('prescriber') ? signChart():''" style="min-width: 390px; text-align: center;" v-tooltip="'Prescriber Signed'" 
                                placeholder="Prescriber Signed" :style="item.signatures.includes('prescriber') ? {backgroundColor: '#86C3EC'}:{backgroundColor: 'white', color: '#E04F39', border:'2px solid #E04F39', cursor:'pointer', fontWeight: 'bold'}" >{{item.signatures.includes('prescriber') ? 'PRESCRIBER SIGNED' :'PRESCRIBER SIGNATURE REQUIRED'}}</p>
                                <i class="pi pi-check" :style="item.signatures.includes('prescriber') ? {backgroundColor: '#0870DC'}:{backgroundColor: '#F7B9B0', border:'2px solid #E04F39', borderLeft:'none', cursor:'pointer'}"></i>
                                <!-- PRESCRIBER SIGNATURES -->
                           </div>
                           <div class="chart-row-item signature-item" style="width: fit-content" v-if="item.sign1" @click="()=>{if(!item.signatures.includes('sign1')){displaySignatureModal = true; prescriberSig = false}}">
                               <!-- SIGN1 SIGNATURES -->
                                <p class="active-chart-field signature-field" v-if="item.sign1" style="min-width: 160px; text-align: center;" v-tooltip="'Signature 1'" 
                                placeholder="Signature 1" :style="item.signatures.includes('sign1') ? {backgroundColor: '#86C3EC'}:{backgroundColor: 'white', color: '#E04F39', border:'2px solid #E04F39', cursor:'pointer', fontWeight: 'bold'}" >{{item.signatures.includes('sign1') ? 'SIGNED' :'SIGN 1 REQUIRED'}}</p>
                                <i class="pi pi-check" :style="item.signatures.includes('sign1') ? {backgroundColor: '#0870DC'}:{backgroundColor: '#F7B9B0', border:'2px solid #E04F39', borderLeft:'none', cursor:'pointer'}"></i>
                                <!-- SIGN1 SIGNATURES -->
                           </div>
                           <div class="chart-row-item signature-item" style="width: fit-content" v-if="item.sign2" @click="()=>{if(!item.signatures.includes('sign2')){displaySignatureModal = true; prescriberSig = false}}">
                               <!-- SIGN2 SIGNATURES -->
                                <p class="active-chart-field signature-field" v-if="item.sign2" style="min-width: 160px; text-align: center;" v-tooltip="'Signature 2'" 
                                placeholder="Signature 2" :style="item.signatures.includes('sign2') ? {backgroundColor: '#86C3EC'}:{backgroundColor: 'white', color: '#E04F39', border:'2px solid #E04F39', cursor:'pointer', fontWeight: 'bold'}">{{item.signatures.includes('sign2') ? 'SIGNED' :'SIGN 2 REQUIRED'}} 
                                </p>
                                <i class="pi pi-check" :style="item.signatures.includes('sign2') ? {backgroundColor: '#0870DC'}:{backgroundColor: '#F7B9B0', border:'2px solid #E04F39', borderLeft:'none', cursor:'pointer'}"></i>
                                <!-- SIGN2 SIGNATURES -->
                           </div>
                           
                       </div>
                       <div class="chart-row" style="flex-wrap: wrap; width: 455px">
                           <div class="chart-row-item signature-item" style="width: fit-content;">
                               <h3 style="margin: 0 auto" :style="{ color: item.color}">Next Due: {{item.next_due}}</h3>
                           </div>
                        </div>
                   </div>
               </div>
                    
               </div>
               
              </div>
            </div>
        </div>
        <!-- PATIENT INFORMATION SUMMARY  -->
               <div style="margin-top: 10px">
                   <div style="display: flex; align-items: flex-end">
                       <router-link :to="'/patient/'+$route.params.id" style="text-decoration: none;">
                       <i class="pi-angle-left pi" style="background-color: white; border-top-left-radius: 8px; border-top-right-radius: 8px;
                       display: inline; width: fit-content; margin: 0; padding: 5px; font-size: 1.5em !important; margin-right: 5px;"></i></router-link>
                       
                       <h3 style="text-align: left; margin: 0;" >Patient Information</h3>
                   </div>
                   <div class='valid-prescription-notes' style="margin-top: 0; border-top-left-radius: 0px;">
                       <div class="general-patient">
                            <img :src="patient.general_info.image" alt="patient-image" style="max-height: 165px; box-shadow: 0 0 5px rgba(0,0,0,0.05)">
                            <h3 class="general-patient-name">{{patient.general_info.name}}</h3>
                        </div>
                        <!-- ADR -->
                        <div class="adr-patient-header-container" style="background-color: #F7B9B0 !important; margin-top: 1em">
                            <div class="adr-patient-header" style="background-color: #E04F39 !important; border-radius: 12px; align-items: center">
                                <h3 class="adr-patient-header-key">ADR</h3>
                                <h3 class="adr-patient-header-value" style="margin: 3px;">{{ patient.adr.length ? 'YES' : 'NO'}}</h3>
                            </div>
                            <div style="overflow-y: scroll; height: 50px; width: 15em; display: flex; flex-wrap: wrap; padding: 5px 10px;">
                                <span style="display: inline-block; margin-right: 10px;" v-for="(item, index) in patient.adr" :key="index">
                                    {{item.drug}}{{(index+1) != patient.adr.length ? ',':''}}
                                </span>
                            </div>
                        </div>
                        <!-- ADR -->
                        <!-- SPEC CONSIDERATIONS -->
                        <div style="border: 2px solid #E04F39; border-radius: 8px; padding-top: 5px; margin-top: 10px;">
                            <div style="padding: 0; margin: 0; text-align: left; padding-left:5px; 
                            width: 15em;flex-wrap: wrap; display: flex; max-height: 80px; min-height: 80px; overflow-y: scroll">
                                <span class="consideration-tag" v-for="(item, index) in patient.considerations.tags" :key="index">{{item}}</span>
                            </div>
                            <div>
                                <p style="font-weight: 500; text-align: left; padding-left: 5px;">Details:</p>
                                <div style="height: 60px; overflow-y: scroll; width: 15em; ">
                                    <p style="text-align: left; padding-left: 5px; margin: 0;">{{patient.considerations.details}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- SPEC CONSIDERATIONS -->
                        <!-- COMPLEX MEDS -->
                        <div></div>
                        <!-- COMPLEX MEDS -->
                   </div>
                   <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" :prescriber="prescriberSig" :endpoint="'/chart/'+chartId+'/signature'" @close="close" />
               </div>
  </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from '@/components/modals/SignatureModal.vue'
export default {
    components:{
        SignatureModal
    },
    data(){
        return{
            displaySignatureModal: false,
            searchedDrug:'',
            drugList:[],
            searchFilter:{
                location: this.$store.state.uuid,
                chart_type:'',
                sign_filter:'all',
                drug_alias:''
            },
            charts:[
            {
                name:'Regular Medicine Order',
                inputs:[
                    'dose',
                    'single_multi',
                    'route',
                    'packed',
                    'frequency',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'substitution',
                    'indication',
                    'comments',
                    
                ],
                subtext:'',
                color: '#9F5CC0',
                prescriber:true,
                sign1:false,
                sign2: false,
                substitution: true,
            },
            {
                name:'PRN Medicine',
                inputs:[
                    'dose',
                    'route',
                    'hourly_frequency',
                    'max_dose',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'packed', 
                    'reason',
                    'comments',
                    'substitution',
                    
                ],
                subtext:'',
                color: '#00B2A2',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Phone Order',
                inputs:[
                    'dose',
                    'reason',
                    'route',
                    'frequency',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'comments',
                ],
                subtext:'',
                color: '#2E97DE',
                prescriber:true,
                sign1:true,
                sign2: true,
            },
            {
                name:'Short Term Medicine',
                inputs:[
                    'dose',
                    'now_or_next_pack',
                    'route',
                    'frequency',
                    'packed',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'indication',
                    'comments',
                ],
                subtext:'(antibiotics)',
                color: '#D6001C',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Once Only Meds (Stat)',
                inputs:[
                    'dose',
                    'datetime'
                ],
                subtext:'',
                color: '#8E0018',
                prescriber:true,
                sign1:true,
                sign2: false,
            },
            {
                name:'Intermittent Medicine',
                inputs:[
                    'dose',
                    'frequency',
                    'start_date'
                ],
                subtext:'',
                color: '#5B00AA',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Variable Dose Meds',
                inputs:[
                    'dose',
                    'frequency',
                    'route',
                    'prescription_date',
                    'start_date',
                    'stop_date',
                    'packed',
                    'comments',
                ],
                subtext:'(not insulin)',
                color: '#00AD50',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Nurse Initiated Meds',
                inputs:[
                    'dose',
                    'frequency',
                    'route',
                    'reason',
                    'start_date'
                ],
                subtext:'(non-prescription)',
                color: '#274188',
                prescriber:false,
                sign1:false,
                sign2: false,
                nurse: true,
            },
            {
                name:'Non Prescription Meds',
                inputs:[
                    'dose',
                    'frequency',
                    'route',
                    'reason',
                    'start_date',
                    'comments',
                ],
                subtext:'(patient initiated)',
                color: '#EF9600',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            {
                name:'Nutritional Supplement',
                inputs:[
                    'dose',
                    'frequency',
                    'route',
                    'start_date',
                    'stop_date',
                    'packed',
                    'substitution',
                    'reason',
                    'comments',
                ],
                subtext:'',
                color: '#E56DB1',
                prescriber:true,
                sign1:false,
                sign2: false,
            },
            ],
            activeCharts:[
                {
                    chart_id:'1020293-13regmedorder',
                    chart_type:'Regular Medicine Order',
                    drug_name:'Endone',
                    strength:'500',
                    form:'Tablets',
                    form_description:'mg',
                    frequency:'Daily',
                    dose:'250',
                    route:'PO',
                    indication:'When taking food.',
                    substitution: true,
                    comments:'Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol. Do not let them have with alcohol.',
                    start_date:'10/07/2021',
                    stop_date:'21/07/2021',
                    prescription_date:'05/07/2021',
                    tags:['packed', 'single' ],
                    reason:'',
                    hourly_frequency:'',
                    signatures:[
                        'prescriber',
                        'sign1'
                    ],
                    next_due:'10/04/2021',
                },
                {
                    chart_id:'9385793-13phoorder',
                    chart_type:'Phone Order',
                    drug_name:'Endone',
                    strength:'500',
                    frequency: 'Daily',
                    form:'Tablets',
                    form_description:'mg',
                    reason:'Just because we can.',
                    dose:'250',
                    route:'PO',
                    indication:'When taking food.',
                    substitution: false,
                    comments:'Do not let them have with alcohol.',
                    start_date:'10/07/2021',
                    stop_date:'21/07/2021',
                    prescription_date:'05/07/2021',
                    tags:[],
                    hourly_frequency:'',
                    signatures:[
                        'prescriber',
                        'sign1',
                    ],
                    next_due:'10/04/2021',
                },
                {
                    chart_id:'66666-13phoorder',
                    chart_type:'Once Only Meds (Stat)',
                    drug_name:'Endone',
                    strength:'500',
                    frequency: 'Daily',
                    form:'Tablets',
                    form_description:'mg',
                    reason:'Just because we can.',
                    dose:'250',
                    route:'PO',
                    indication:'When taking food.',
                    substitution: false,
                    comments:'Do not let them have with alcohol.',
                    start_date:'10/07/2021',
                    stop_date:'21/07/2021',
                    prescription_date:'05/07/2021',
                    tags:[],
                    hourly_frequency:'',
                    signatures:[
                        'prescriber',
                    ],
                    next_due:'10/04/2021',
                },
                {
                    chart_id:'66666-13phoorder',
                    chart_type:'PRN Medicine',
                    drug_name:'Endone',
                    strength:'500',
                    frequency: 'Daily',
                    form:'Tablets',
                    form_description:'mg',
                    reason:'Just because we can.',
                    dose:'250',
                    route:'PO',
                    indication:'When taking food.',
                    substitution: true,
                    comments:'Do not let them have with alcohol.',
                    start_date:'10/07/2021',
                    stop_date:'21/07/2021',
                    prescription_date:'05/07/2021',
                    max_dose: '1400',
                    tags:['non-packed'],
                    hourly_frequency:'Twice an hour',
                    signatures:[
                    ],
                    next_due:'10/04/2021',
                },
                {
                    chart_id:'66666-13phoorder',
                    chart_type:'Nutritional Supplement',
                    drug_name:'Endone',
                    strength:'500',
                    frequency: 'Daily',
                    form:'Tablets',
                    form_description:'mg',
                    reason:'Just because we can.',
                    dose:'250',
                    route:'PO',
                    indication:'When taking food.',
                    substitution: false,
                    comments:'Do not let them have with alcohol.',
                    start_date:'10/07/2021',
                    stop_date:'21/07/2021',
                    prescription_date:'05/07/2021',
                    tags:[],
                    hourly_frequency:'',
                    signatures:[
                        'prescriber',
                    ],
                    next_due:'10/04/2021',
                },
                {
                    chart_id:'66666-13phoorder',
                    chart_type:'Short Term Medicine',
                    drug_name:'Endone Pfeizer (Oncological Dep)',
                    strength:'500',
                    frequency: 'Daily',
                    form:'Tablets',
                    form_description:'mg',
                    reason:'Just because we can.',
                    dose:'250',
                    route:'PO',
                    indication:'When taking food.',
                    substitution: false,
                    comments:'Do not let them have with alcohol.',
                    start_date:'10/07/2021',
                    stop_date:'21/07/2021',
                    prescription_date:'05/07/2021',
                    tags:['now'],
                    hourly_frequency:'',
                    signatures:[
                        'prescriber',
                    ],
                    next_due:'10/04/2021',
                },
                
            ],
            patient:{
	    general_info:{
                    image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU',
                    name:'Sally Smith',
                    preferred_name :'Sally',
                    gender:'Female',
                    dob:'10/01/1930',
                    age:88,
                    room:'10A',
                    building:'Lower Left Building',
                    chart_commenced:'10/02/2021',
                    expiry_date:'10/10/2022',
                    ihi:'2340234293423423',
                    rac_id:'L93939393',
                    urn_mrn:'kkdsfkskdfksdf'
            },
        adr:[
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            },
            {
                drug:'Endone',
                reaction:'Rashes',
                date:'10/02/2020',
                type:'Drug',
            }
            
         ],
        considerations:{
            tags:[
                'Cognitive Impairment',
                'Dizziness',
                'Alzheimers',
                'Cognitive Impairment',
                'Dizziness',
                'Alzheimers',
                'Cognitive Impairment',
                'Dizziness',
                'Alzheimers',
                'Cognitive Impairment',
                'Dizziness',
                'Alzheimers'
            ],
            details:'Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth. Place medicine in spoon and place inside mouth.'
        },
          },
        }
    },
    methods:{
        isSuccessful(value){
            if(value == true){
                window.location.reload()
            }
        },
        close(value){
            if(value){
                this.displaySignatureModal = false;
            }
        },
        searchDrugs(){
          axios.post('/get-drug-aliases', {drug:this.searchedDrugName}, this.$store.state.header).then(res=>{
            console.log('this is the res data', res.data);
            this.drugList = res.data.drug_aliases;
          })
        this.drugList = [
            {
                name: 'Endone 5mg Pfeizer',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000,
                tags:['s8']
            },
            {
                name: 'Methadone 15mg',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000,
                tags:['s8']
            },
            {
                name: 'Abilify 25mg',
                alias_id: '1231-mf232-wer233',
                form: 'vial',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Endone 5mg Pfeizer',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Methadone 15mg',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Abilify 25mg',
                alias_id: '1231-mf232-wer233',
                form: 'vial',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Endone 5mg Pfeizer',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Methadone 15mg',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Abilify 25mg',
                alias_id: '1231-mf232-wer233',
                form: 'vial',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Endone 5mg Pfeizer',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Methadone 15mg',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Abilify 25mg',
                alias_id: '1231-mf232-wer233',
                form: 'vial',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Endone 5mg Pfeizer',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Methadone 15mg',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Abilify 25mg',
                alias_id: '1231-mf232-wer233',
                form: 'vial',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Endone 5mg Pfeizer',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Methadone 15mg',
                alias_id: '1231-mf232-wer233',
                form: 'tablet',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            },
            {
                name: 'Abilify 25mg',
                alias_id: '1231-mf232-wer233',
                form: 'vial',
                strength: 1234.00,
                form_description: 'mg/each',
                quantity: 1234.0000
            }
        ]
      },
        drugSelect(item){
          this.searchFilter.drug_alias = item.alias_id;
          this.drugList = [];
          this.searchedDrug = item.name;
          this.clearDrug = true;
        },
        selectChart(){
            this.searchFilter.chart_type = document.getElementById('chart-type').value;
            console.log('this is the selected chart', this.searchFilter.chart_type );
        },
        clearChartFilter(){
            this.searchedDrug = '';
            this.searchFilter = {
                location: this.$store.state.uuid,
                chart_type:'',
                sign_filter:'all',
                drug_alias:''
            }
            document.getElementById('chart-type').value = null
        },
        searchActiveCharts(){
            axios.post(`/patient/${this.$route.params.id}/active-charts`, this.searchFilter).then(res=>{
                console.log('these are the charts', res);
            })
        },
        signChart(chartid, signer){
            console.log('this charg will be signed', chartid, signer);
        },
        assignChartColor(){
            this.activeCharts.forEach(chart=>{
                switch(chart.chart_type){
                    case 'Regular Medicine Order':
                        chart.color = '#9F5CC0';
                        chart.inputs = this.charts[0].inputs;
                        chart.prescriber = this.charts[0].prescriber;
                        chart.sign1 = this.charts[0].sign1;
                        chart.sign2 = this.charts[0].sign2;
                        break;
                    case 'Nutritional Supplement':
                        chart.color = '#E56DB1';
                        chart.inputs = this.charts[9].inputs;
                        chart.prescriber = this.charts[9].prescriber;
                        chart.sign1 = this.charts[9].sign1;
                        chart.sign2 = this.charts[9].sign2;
                        break;
                    case 'Non Prescription Meds':
                        chart.color = '#EF9600';
                        chart.inputs = this.charts[8].inputs;
                        chart.prescriber = this.charts[8].prescriber;
                        chart.sign1 = this.charts[8].sign1;
                        chart.sign2 = this.charts[8].sign2;
                        break;
                    case 'Nurse Initiated Meds':
                        chart.color = '#274188';
                        chart.inputs = this.charts[7].inputs;
                        chart.prescriber = this.charts[7].prescriber;
                        chart.sign1 = this.charts[7].sign1;
                        chart.sign2 = this.charts[7].sign2;
                        break;
                    case 'Variable Dose Meds':
                        chart.color = '#00AD50';
                        chart.inputs = this.charts[6].inputs;
                        chart.prescriber = this.charts[6].prescriber;
                        chart.sign1 = this.charts[6].sign1;
                        chart.sign2 = this.charts[6].sign2;
                        break;
                    case 'Intermittent Medicine':
                        chart.color = '#5B00AA';
                        chart.inputs = this.charts[5].inputs;
                        chart.prescriber = this.charts[5].prescriber;
                        chart.sign1 = this.charts[5].sign1;
                        chart.sign2 = this.charts[5].sign2;
                        break;
                    case 'Once Only Meds (Stat)':
                        chart.color = '#8E0018';
                        chart.inputs = this.charts[4].inputs;
                        chart.prescriber = this.charts[4].prescriber;
                        chart.sign1 = this.charts[4].sign1;
                        chart.sign2 = this.charts[4].sign2;
                        break;
                    case 'Short Term Medicine':
                        chart.color = '#D6001C';
                        chart.inputs = this.charts[3].inputs;
                        chart.prescriber = this.charts[3].prescriber;
                        chart.sign1 = this.charts[3].sign1;
                        chart.sign2 = this.charts[3].sign2;
                        break;
                    case 'Phone Order':
                        chart.color = '#2E97DE';
                        chart.inputs = this.charts[2].inputs;
                        chart.prescriber = this.charts[2].prescriber;
                        chart.sign1 = this.charts[2].sign1;
                        chart.sign2 = this.charts[2].sign2;
                        break;
                    case 'PRN Medicine':
                        chart.color = '#00B2A2';
                        chart.inputs = this.charts[1].inputs;
                        chart.prescriber = this.charts[1].prescriber;
                        chart.sign1 = this.charts[1].sign1;
                        chart.sign2 = this.charts[1].sign2;
                        break;
                }
            })
        }
    },
    mounted(){
        this.assignChartColor();
    }
}
</script>

<style lang="scss">
    @import "../../../assets/css/main.scss";
    .signature-item{
        display: flex;
        align-items: center;
        height: 60px;
        width: fit-content;
        p{
            margin: 0;
            margin-left: 10px;
            font-size: 1em;
            padding: 5px;
            border-radius: 4px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        i{
            color: white !important;
            font-size: 1.5em !important;
            font-weight: 900;
            padding: 4px 10px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    .active-chart-field{
        padding: 0 10px;
        font-size: 1em;
        margin: 3px;
        overflow: scroll;
        
    }
    .active-chart-table-container{
        display: flex;
        flex-wrap: wrap;
        // .chart-approval-only{
        //     p{
        //         font-size: 0.9em;
        //     }
        // }
        .chart-row-item{
            min-height: 45px;
        }
        .first-chart-row-item{
            .active-chart-field{
                white-space: nowrap;
                max-width: 285px;
            }
        }
    }
    .signature-field{
        background-color: $signatureblue;
        color: white;
        font-weight: bold;
    }
    
</style>