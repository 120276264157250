<template>
    <div class="drug-register-table table-container"
        style="width: fit-content; box-shadow: none; padding-left: 0">
        <div class="filters" style="padding: 10px">
            <div class="text-search-container search-container" style="display: flex; align-items: center">
                <!-- <input v-model="searchName" placeholder="Patient..."
                    style="position: relative; margin-right: 10px;" /> -->
                <p style="font-size: 1em; margin-right: 10px;">Select facilities:</p>
                <MultiSelect v-model="searchFacilities" :options="facilities" optionLabel="name"
                    optionValue="name" placeholder="Facilities" @change="updateResults()" />
                <SelectButton style="display: flex" v-model="form.read" :options="displayOptions" optionLabel="name" optionValue="value" @click="loadNotifications()" />
                <!-- <button class="button submit-button" style="margin-right: 10px;" @click="searchTasks()">Search</button>
                <p  style="margin-right: 10px; border-bottom: 1px solid black; border-radius: 0; font-size: 1em; cursor: pointer" @click="clearSearch()">Clear</p> -->
            </div>
            
        </div>
        <div style='overflow-y: scroll; margin-top: -2em;'>
            <div>
                <table class="data-table" cellspacing="0">
                    <!-- LOADING SPINNER -->
                    <!-- <div  v-if="!getDrugEntries" >
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div> -->
                <!-- v-else  -->
                <tr class="table-headers" >
                    <th style="width: 6em;">
                        <h3 style="padding: 10px">Status</h3>
                    </th>
                    <th style="width: 6em;">
                        <h3 style="padding: 10px">Date</h3>
                    </th>
                    <th style="width: 7em;"> 
                        <h3 >Patient</h3>
                    </th>
                    <th> 
                        <h3>Info</h3>
                    </th>
                    <th style="min-width: 10em;"> 
                        <h3 style="padding: 10px">Prescriber</h3>
                    </th>
                    <th style="min-width: 7em"> 
                        <h3>Facility</h3>
                    </th>
                    <th style="min-width: 7em"> 
                        <h3>Medication Time (Dose)</h3>
                    </th>
                    <th style="width: 11em"> 
                        <h3>Action</h3>
                    </th>
                    <th style="width: 3em">
                    </th>
                </tr>
                
                <tbody class="table-data" v-for="(item, index) in filteredTasks.length ? filteredTasks: tasks" :key="index">
                    <tr>
                        <td>
                            <p class="button task-status-indicator" 
                            :style="item.status == 'New' || item.status == 'new' ? {backgroundColor: '#00AD50'}:item.status == 'Cancel' || item.status == 'cancel' ? {backgroundColor: '#E04F39'}: 
                            item.status == 'Hold' || item.status == 'hold' ? {backgroundColor: '#EF9600'}: item.status == 'Update' || item.status == 'update' ? {backgroundColor: '#9F5CC0'}:
                            item.status == 'Draft' || item.status == 'draft' ? {backgroundColor: '#0870DC'}:''">
                                {{item.status.slice(0,1).toUpperCase()+item.status.slice(1)}}
                            </p>
                        </td>
                        <td><p style="padding: 10px">{{item.date}}</p></td>
                        <td><p style="font-weight: bold; color: #0870DC; cursor: pointer;" @click="choosePatient(item.patient.uuid)">{{item.patient.first_name}} {{item.patient.last_name}}</p></td>
                        <td>{{item.info}}</td>
                        <td style="padding: 10px">{{item.prescriber}}</td>
                        <td>{{item.facility }}</td>
                        <td><span v-if="item.times"><b>{{item.frequency}}: </b>[<span v-for="(time, i) in item.times" :key="'p'+i">{{time}}({{ item.dose }}){{i == (item.times.length -1) ? '':', '}} </span>]</span></td>
                        <td v-if="item.status && item.status != 'Hold' && item.status != 'Stop' && item.status != 'Draft'"><Dropdown style='width: 10em' placeholder="Select Action" :options="actionTypes" v-model="item.toAction" /></td>
                        <td v-else><p class="blue-button" style="text-align: center; width: 10em; margin-left: 10px;" @click="removeFromList(item.uuid, index)">Mark as Read</p></td>
                        <td >
                            <i v-if="item.status" class="pi pi-check confirm-check" @click="confirmTask(item, index)" :v-tooltip="item.toAction ? 'Confirm' : 'Acknowledge'" ></i>
                        </td>
                    </tr>
                </tbody>
                </table>
                <div v-if="loadingTasks">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 2em;"></i>
                </div>
                <div v-if="!tasks.length || (!filteredTasks.length && !loadingTasks)">
                    <p style="font-size: 1em; margin-top: 3em;">No unread tasks.</p>
                </div>
            </div>
        </div>

        <div class="pagination-links">
            <p class="pagination-arrow" v-show="current_page > 1"
                @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{ current_page }} of {{ page_count }}</p>
            <p class="pagination-arrow"
                v-show="current_page < page_count"
                @click="displayPage(current_page + 1)"> &rarr; </p>
        </div>
        <PatientSummaryModal v-if="expandPatientSummaryModal" @close="close" :patientId="patientId" :viewOnly="true" />
    </div>
</template>

<script>
import axios from 'axios';
import PatientSummaryModal from '../modals/PatientSummaryModal.vue';


export default {
    components: {
        PatientSummaryModal
    },
    data() {
        return {
            items: [{
                label: 'Update',
                icon: 'pi pi-refresh',
                command: () => {
                    this.$toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.$toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                }
            }
            ],
            form: {
               page:1,
               read:false
            },
            actionTypes: [
                'Packed',
                'Non-packed'
            ],
            displayOption:'',
            displayOptions:[
                {name:'Read',
                value: true},
                {name:'Unread',
                value: false},
            ],
            loadingTasks: false,
            patientId: '',
            expandPatientSummaryModal: false,
            facilities: [],
            searchFacilities: [],
            searchedByPatientName:[],
            searchedPatient: false,
            tasks: [],
            filteredTasks:[],
            current_page: 1,
            page_count: 1,
            searchName:'',
        }
    },
    methods: {
        displayPage(page){
            this.form.page = page;
            this.loadNotifications();
        },
        confirmTask(item, index) {
            console.log('will send this', item.toAction, item.chart_id);
            if(item.prescription && item.toAction){
                console.log('this is a prescription, so will be adding chart tag');
                axios.post(`/chart/${item.chart_id}/add-tags`, {
                    tags: [item.toAction.toLowerCase()]
                }, this.$store.state.header).then(res => {
                    console.log('added tags to chart', res);
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
            this.removeFromList(item.uuid, index);
        },
        removeFromList(id, index) {
            this.tasks.splice(index, 1);
            console.log('will be removing this from list', id);
            axios.post('/read-notifications', {
                notification_ids: [id]
            }, this.$store.state.header).then(res => {
                console.log('market item as read', res);
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })

        },
        close(value) {
            if (value) {
                this.expandPatientSummaryModal = false;
            }
        },
        choosePatient(id) {
            this.patientId = id;
            // this.expandPatientSummaryModal = true;
            this.$router.push(`/patient/${id}`);
        },
        clearSearch(){
            this.searchName = [];
            this.searchFacilities = [];
            this.searchTasks();
        },
        searchTasks() {
            console.log('these are the search filters', this.searchName);
            
            if(this.searchName != '' || this.searchFacilities.length){
                this.searchedPatient = true;
                    this.filteredTasks = [];
                    let tempVar = [];
                    if(this.searchName != ''){
                        console.log('HITTING SEARCH NAME CONDITIONAL')
                        this.tasks.forEach(task =>{
                        if(task.patient && task.patient.last_name && (task.patient.first_name.toLowerCase().includes(this.searchName.toLowerCase()) ||
                            task.patient.last_name.toLowerCase().includes(this.searchName.toLowerCase()))){
                                tempVar.push(task);
                            }
                        });
                        setTimeout(()=>{
                            if(this.searchFacilities.length){
                                console.log('HITTING SEARCH FACILITY CONDITIONAL via searchName', tempVar);
                            
                                if(tempVar.length){
                                    tempVar.forEach(task=>{
                                        if(this.searchFacilities.includes(task.facility)){
                                            this.filteredTasks.push(task);
                                        }
                                    })
                                } else{
                                    this.tasks.forEach(task=>{
                                        if(this.searchFacilities.includes(task.facility)){
                                            this.filteredTasks.push(task);
                                        }
                                    })
                                }
                                
                            } else{
                                this.filteredTasks = tempVar;
                            }
                        },400)
                        
                    } else{
                        if(this.searchFacilities.length){
                            console.log('HITTING SEARCH FACILITY CONDITIONAL, facilities only', this.searchFacilities, tempVar, this.filteredTasks)
                            if(tempVar.length){
                                console.log('going tempvar', this.tempVar, this.searchFacilities, this.filteredTasks);
                                tempVar.forEach(task=>{
                                    console.log('looping through if', task, this.searchFacilities);
                                    if(this.searchFacilities.includes(task.facility)){
                                        this.filteredTasks.push(task);
                                    }
                                })
                            } else{
                                console.log('going else tempvar', this.tasks, this.searchFacilities, this.filteredTasks);
                                this.tasks.forEach(task=>{
                                    console.log('looping through else', task, this.searchFacilities);
                                    if(this.searchFacilities.includes(task.facility)){
                                        this.filteredTasks.push(task);
                                        console.log('MATCH', task, this.filteredTasks);
                                    }
                                })
                            }
                        } 
                        this.filteredTasks = tempVar;
                    }
                    
            } else{
                this.filteredTasks = this.tasks;
                this.searchFacilities = [];
            }
           
        },
        loadNotifications() {
            this.loadingTasks = true;
            this.searchedPatient = false;
            axios.post('/get-notifications', this.form, this.$store.state.header).then(res => {
                console.log('THESE ARE THE NOTIFICATIONS', res.data);
                this.current_page = res.data.header.current_page;
                this.page_count = res.data.header.page_count;
                this.loadingTasks = false;
                res.data.notifications.forEach(notification => {
                    if(notification.read == 0){
                        if (notification.subject && notification.context != 'Draft' && notification.context != 'draft') {
                            this.tasks.push({
                                status: notification.context,
                                prescription: notification.prescription ? notification.prescription.uuid : false,
                                info: notification.prescription.alias.name,
                                uuid: notification.uuid,
                                chart_id: notification.prescription.chart ? notification.prescription.chart.uuid : '',
                                date: notification.created_at,
                                times: notification.prescription ? notification.prescription.times : '',
                                dose: notification.prescription ? notification.prescription.dose : '',
                                frequency: notification.prescription ? notification.prescription.frequency : '',
                                patient: {
                                    first_name: notification.prescription ? notification.prescription.patient.personal_information.first_name : 'N/A',
                                    last_name: notification.prescription ? notification.prescription.patient.personal_information.last_name : '',
                                    uuid: notification.prescription ? notification.prescription.patient.uuid : ''
                                },
                                prescriber: `${notification.prescription.doctor.personal_information.first_name} ${notification.prescription.doctor.personal_information.last_name}`,
                                facility: notification.prescription && notification.prescription.patient.physical_location ? notification.prescription.patient.physical_location.location.facility.name : 'N/A',
                                toAction: ''
                            })
                            if(notification.prescription && notification.prescription.patient.physical_location && notification.prescription.patient.physical_location.location.facility.name){
                                this.facilities.push(notification.prescription.patient.physical_location.location.facility.name);
                            }
                        } else {
                            if (notification.context != 'Draft' && notification.context != 'draft') {
                                this.tasks.push({
                                    status: '',
                                    context: notification.context,
                                    info: notification.text,
                                    uuid: notification.uuid,
                                    chart_id: '',
                                    date: notification.created_at,
                                    patient: '',
                                    prescriber: '',
                                    facility: 'N/A',
                                    toAction: ''
                                })
                            }

                        }
                    }
                    
                })
            }).then(()=>{
                let onlyPrescriptions = this.tasks.filter(task=> task.uuid);
                let uniqueTasks = [...new Map(onlyPrescriptions.map((item) => [item["prescription"], item])).values()];
                console.log('THESE ARE THE UNIQUE TASKS', uniqueTasks, onlyPrescriptions);
                this.tasks = uniqueTasks;
                this.facilities = [...new Set(this.facilities)];
                let uniqueFac = [];
                this.facilities.forEach(facility => uniqueFac.push({name: facility}));
                console.log('THESE ARE THE FILTERED FACILITIES', uniqueFac);
                this.facilities = uniqueFac;
                this.loadingTasks = false;
                this.filteredTasks = this.tasks;
                // setTimeout(this.updateResults(), 300);
            }).catch(err=>{
                this.loadingTasks = false;
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        updateResults(){
            this.filteredTasks = [];
            if(this.searchFacilities.length){
                    this.tasks.forEach(task=>{
                        if(this.searchFacilities.includes(task.facility)){
                            this.filteredTasks.push(task);
                        }
                    })
            }
        },
        
    },
    mounted() {
        this.loadNotifications();
    }
}
</script>

<style lang="scss">
.confirm-check {
    cursor: pointer;
    font-weight: bold;
    border-radius: 50px;
    background-color: #f3f4f4;
    padding: 10px !important;

}

.confirm-check:hover {
    background-color: #00AD50 !important; //$correctborder !important;
    color: white;
}

.task-status-indicator {
    padding: 5px;
    font-weight: bold;
    text-align: center;
    margin-right: 15px;
    border-radius: 5px;
    color: white;
}
.p-selectbutton{
    .p-button.p-component{
        padding: 10px;
    }
}
</style>