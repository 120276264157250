<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Reconcile Theatre Usage</h3>
                        <button @click="closeModal()" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">

                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="theatre-section-container">

                                <div v-if="checkinData" style="flex flex-direction: column">

                                    <div style="display:grid;">

                                        <div style="display: flex;" class="info-field">
                                            <p style="font-size: 1em; margin: 5px;">Patient: {{ (checkinData.patient.personal_information).firstname }}
                                                {{ (checkinData.patient.personal_information).lastname }}</p>
                                        </div>

                                        <div style="display: flex;" class="info-field">
                                            <p style="font-size: 1em; margin: 5px;">Anaesthetist: {{
                                                (checkinData.anesthetist.personal_information).firstname }} {{
        (checkinData.anesthetist.personal_information).lastname }}</p>
                                        </div>
                                        <div style="display: flex;" class="info-field">
                                            <p style="font-size: 1em; margin: 5px;">Status: <span :class="checkinData.status == 'open' ? 'green-text':'red-text'" style="font-weight: bold;">{{ checkinData.status.charAt(0).toUpperCase() +
                                                checkinData.status.slice(1) }}</span></p>
                                        </div>
                                    </div>
                                    <!-- if open -->
                                    <table v-if="checkinData.status == 'open'" style="border-collapse: collapse">
                                        <tr>
                                            <th width="42%">Medication</th>
                                            <th width="10%">Assigned</th>
                                            <th width="12%">Returned</th>
                                            <th width="12%">Discarded</th>
                                            <th width="12%">Administered</th>
                                            <th width="12%">Select to Reconcile</th>
                                        </tr>
                                        <!-- :class="(drug.administered + drug.checkin + drug.discard)== drug.quantity ? 'green-background':''" -->
                                        <tr v-for="(drug, index) in reconciledDrugs" :key="index" >
                                            <td style="padding-left: 10px;">{{ drug.stock_item.alias.name }}</td>
                                            <td>{{ drug.quantity }}</td>
                                            
                                            <td> <input style="width: 80%;" placeholder="Enter qty" type="number" v-if="!drug.completed"
                                                    v-model="drug.checkin" @change="calculateAdministered(index)" :max="drug.quantity - drug.discard" :min="0" />
                                                <p v-else>{{ drug.checkin }}</p></td>
                                            <td> <input style="width: 80%;" placeholder="Enter qty" type="number" v-if="!drug.completed"
                                                    v-model="drug.discard" @change="calculateAdministered(index)" :max="drug.quantity - drug.checkin" :min="0" />
                                                <p v-else>{{ drug.discard }}</p></td>
                                            <td><i :style="drug.administered < 0 ? {color: 'red'}:{}">{{ drug.administered.toFixed(2) }} {{ !drug.completed ? '(calculated)' : '' }}</i></td>
                                            <!-- {{ drug.administered + drug.checkin + drug.discard }} -->
                                            <td><Checkbox :binary="true" v-model="drug.selected" v-if="!drug.completed"/></td>
                                        </tr>
                                    </table>

                                    <table v-if="checkinData.status == 'closed'">
                                        <tr>
                                            <th width="50%">Medication</th>
                                            <th width="10%">Assigned</th>
                                            <th width="10%">Administered</th>
                                            <th width="10%">Returned</th>
                                            <th width="10%">Discarded</th>
                                        </tr>
                                        <tr v-for="(drug, index) in checkinData.drugs" :key="index">
                                            <td>{{ drug.stock_item.alias.name }}</td>
                                            <td>{{ drug.quantity }}</td>
                                            <td>{{ drug.administered }}</td>
                                            <td>{{ drug.checkin }}</td>
                                            <td>{{ drug.discard }} </td>
                                        </tr>
                                    </table>

                                </div>

                            </div>

                        </form>
                    </slot>
                </div>
                <p style="color: red; font-size: 1em;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <div style="padding-right: 20px;">
                            <p v-if="contructRequiredData != ''" style="display: inline; font-weight: 500; font-size: 1em;">
                                Missing Fields: </p>
                            <p v-if="contructRequiredData != ''"
                                style="display: inline;color: red; font-weight: 500; font-size: 1em;">{{
                                    contructRequiredData }}</p>
                        </div>
                        <div class="spacer"></div>
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;"
                            @click="closeModal">Cancel</p>

                        <!-- submitTransferAction() -->
                        <p v-if="checkinData.status != 'closed'" class="regular-button complete-button"
                            style="margin-right: 10px;" @click="submitAllocateMeds()">Reconcile Medications</p>
                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SignatureModal v-if="displaySignatureModal" @close="close" @isSuccessful="isSuccessful" :endpoint="endpoint"
            :payload="payload" :dual="true" />
        <ReferencePrescriberModal @close="close" v-if="displayReferencePrescriberModal" @prescriber="getPrescriber" />
        <SuccessModal v-if="displaySuccessModal" @close="close"
            :message="'There is a discrepancy between what has been entered and the actual stock count. \n Please repeat the count. If discrepancy is repeated, a balance check will be submitted .'" />
    </div>
</template>

<script>
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import ReferencePrescriberModal from '@/components/modals/ReferencePrescriberModal.vue';
import axios from 'axios';

export default {
    props: ['prefill', 'dispensePayload', 'requisitionIndex'],
    components: {
        SignatureModal,
        SuccessModal,
        PatientSearchModal,
        ReferencePrescriberModal
    },
    data() {
        return {
            endpoint: "/theatre-checkin",
            reconciledDrugs: [],
            payload: {},
            displayPatientSearchModal: false,
            displaySignatureModal: false,
            displayReferencePrescriberModal: false,
            displaySuccessModal: false,
            errorMessage: "",
            checkinData: [],
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'TheatreAllocate',
                patient: '',
                location: '',
                drugs: [],
                doubleSig: true
            }
        }
    },
    methods: {
        setdrug(drugFormatted) {
            this.selectedDrug = drugFormatted
        },
        setreconciledDrugs(drugs) {
            // set up drugs
            drugs.forEach(drug=>{
                this.reconciledDrugs.push({
                    administered: !drug.administered? drug.quantity : drug.administered,
                    checkin: 0,
                    discard: 0,
                    quantity: drug.quantity,
                    drug: drug.uuid,
                    stock_item: drug.stock_item,
                    selected: drug.administered || drug.checkin || drug.discard? false : true,
                    completed: drug.administered || drug.checkin || drug.discard? true : false,
                    disable: drug.checkin || drug.discard || drug.quantity ? true : false
                })
            })

        },
        submitAllocateMeds() {
            // add uuid
            this.payload.uuid = this.checkinData.uuid
            // add drugs in array
            let drugs = [];
            let isValid = true;
            this.reconciledDrugs.forEach(drug=>{
                if(!drug.completed){
                    if(drug.selected && (drug.quantity == (drug.checkin+drug.administered+drug.discard))){
                        if(drug.administered == ""){
                            drug.administered = 0
                        }
                        if(drug.discard == ""){
                            drug.discard = 0
                        }
                        drugs.push(drug);
                    }
                    if(drug.administered < 0 ){
                        isValid = false;
                        this.errorMessage = "Cannot submit, medications have totals that do not match assigned quantity."
                    }
                    if(drug.checkin < 0 || drug.discard < 0){
                        isValid = false;
                        this.errorMessage = "Cannot submit, medication values cannot be less than zero."
                    }
                }
            })
            if(isValid){
                this.errorMessage = "";
                this.displaySignatureModal = true
            }
            this.payload.drugs = drugs;
            
        },
        close(value) {
            if (value) {
                this.SuccessModal = false
                this.displaySignatureModal = false
                this.displayPatientSearchModal = false
            }
        },
        isSuccessful(value){
            console.log('closing the theatre checking modal', value);
            this.closeModal();
            if(value){
                this.closeModal();
            }
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('refreshRequisitionItem', this.requisitionIndex)
            this.$emit('close', true);
        },
        calculateAdministered(index){
            this.reconciledDrugs[index].administered = this.reconciledDrugs[index].quantity - (this.reconciledDrugs[index].discard+this.reconciledDrugs[index].checkin).toFixed(2);
        }

    },
    beforeMount() {
        if (this.prefill) {
            this.checkinData = this.prefill
            this.setreconciledDrugs(this.prefill.drugs)
        }
    },
    computed: {
        contructRequiredData() {
            return ""
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";


th,
td {
    padding: 5px;
}


.info-value {
    text-align: left;
    background-color: #f3f4f4 !important;
    border-radius: 4px;
    height: 80%;
    padding: 6px 10px;
    margin: 0;
    margin-left: 4px;
}

.info-title {
    text-align: left;
    padding-left: 10px;
    padding-top: 6px;
    font-weight: 500;
    margin: 0;
}

.info-field {
    text-align: left;
    font-weight: 500;
    margin: 0;
}

.theatre-section-container {
    display: grid;
    width: 97%;

}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.input {
    background-color: #E5F1FE;
}

.spacer {
    flex-grow: 1;
}



.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}



.selected-location {
    background-color: $complete;
    color: white;
}</style>
