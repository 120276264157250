<template>
  <div class="drug-register-view view">
    <div class="panel" v-if="!notReady">
      <h3 class="panel-header">Patient Search</h3>
      <div class="drug-register-table table-container" style="width: 95.5vw; overflow: hidden">
        <div v-if="$store.state.user.role.permissions.includes('can_view_resident')">
          <div class="filters">
              <div style="width: 40%;">
                  <input v-model="searchTerm" v-on:keyup.enter="()=>{if(!searchTerm || searchTerm){searchForPatient()}}" placeholder="Enter Patient name or Medicare Number" style="position: relative; width:100%" />
              </div>
              <button class="regular-button submit-button" style="margin-left: 10px; margin-right: 10px;"
              @click="()=>{if(!searchTerm || searchTerm){searchForPatient()}}">Search</button>

              <button class="button clear-button" @click="clearPatients()">Clear</button>
              <div style="margin-left: 50px;">
                <Checkbox inputId="binary" v-model="includeInactive" :binary="true" @change="setInactive()" />
                <label for="binary" style="margin-left: 2px;">Include inactive patients</label>
              </div>
          </div>

          <div style="overflow-y: scroll; height: 90vh" v-if="this.$store.getters.getPatients.length">
            <div v-for="(item, index) in getPatients"  :key="index">
              <router-link 
              class="patient-result" :to="'/patient/'+item.uuid">
                <div class="individual-patient-container" style="width: 100%; background-color: transparent;">
                    <div class="patient-result-image" >
                      <img v-if="item.image_information && item.image_information.url" onerror="this.onerror=null; this.className='resident-image-default resident-image small'" class="resident-image small" :src="item.image_information.url" alt="ss" style="max-height: 100px; max-width: 100px;">
                      <img v-else class="resident-image-default resident-image small" alt="">
                    </div>
                    <div class="patient-result-info" style=" width: 35vw;">
                            <p v-if="item.personal_information"><b>Name:</b> {{item.personal_information.first_name}} {{item.personal_information.last_name}}</p>
                            <p v-if="item.regulatory_information && item.regulatory_information.length"><b>{{item.regulatory_information[0].regulatory_type}}:</b>  {{ item.regulatory_information[0].data.slice(0,4)}} {{item.regulatory_information[0].data.slice(4, 9)}} {{item.regulatory_information[0].data.slice(9)}}</p>
                            <p><b>DOB:</b> {{item.personal_information && item.personal_information.dob ? item.personal_information.dob : ''}}</p>
                            <p><b>Location:</b> {{item.physical_location && item.physical_location.location ? item.physical_location.location.name : ''}}</p>
                    </div>
                </div>
              </router-link>
            </div>
          </div>

          <div v-if="searched && !this.$store.getters.getPatients.length && !$store.state.loadingPatientsData">
              <h4>No results found.</h4>
          </div>

          <div v-if="$store.state.loadingPatientsData">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important; margin-top: 2em;"></i>
          </div>
  <!-- 
            <div class="pagination-links" v-if="patients.length">
              <p class="pagination-arrow" v-show="$store.state.patients.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
              <p>Page {{$store.state.patients.current_page}} of {{$store.state.patients.page_count}}</p>
              <p class="pagination-arrow" v-show="$store.state.patients.current_page < $store.state.patients.page_count" @click="displayPage( $store.state.patients.current_page + 1)"> &rarr; </p>
            </div> -->
        </div>
        <div v-else style="margin-top: 10em;">
          <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
              You do not have permission to access this feature. Please talk to your admin if you require access.
          </h3>
        </div>
      </div>
    </div>
    <!-- <div class="drug-register-table table-container" style="padding-top: 10em; margin: 0 auto; height: 22em; margin-top: 5em;" >
      <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">This feature is not available yet.</h3>
    </div> -->
    
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  props:['quickSearch'],
  data() {
    return {
      searched: false,
      notReady: false,
      includeInactive: false,
    }
  },
  methods: {
    searchForPatient() {
      this.searchError = '';
      this.displayResults = true;
      this.searched = true;
      // Call the getPatientSearchResults store function
      this.$store.dispatch('getPatientSearchResults', this.searchTerm);
    },
    clearPatients() {
      this.$store.commit('setPatients', []);
      this.searchTerm = '';
      this.searched = false;
    },
    setInactive(){
      this.$store.commit('setInactive', this.includeInactive);
    }
  },
  components: { 
  },
  mounted() {
  },
  unmounted() {
    // Remove patients and search Term
    this.$store.commit('setPatients', []);
    this.$store.commit('setInactive', false);
    this.searchTerm = '';
  },
  created(){
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params.quickSearch) {
          // Call the getPatientSearchResults store function
          this.$store.dispatch('getPatientSearchResults', this.$route.state.quickSearch);
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  computed:{
    getPatients() {
      return this.$store.getters.getPatients;
    },
    searchTerm: {
      get () {
        return this.$store.state.quickSearch
      },
      set (value) {
        this.$store.commit('setQuickSearch', value)
      }
    }
  }
}
</script>

<style lang="scss">
  .p-checkbox-box{
    margin: 0 !important;
  }
</style>
