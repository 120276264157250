<template>
    <div class="action-form lateral-form">
        <textarea name="action-text" class="notes-area" style="min-width: 200px" rows="3" placeholder='Notes' v-model="form.submit.notes"></textarea>
        <!-- <DualVerify @user="getUsername" @pass="getPassword" :password="form.submit.password"  :username="form.submit.username"
        @user2="getUsername2" @pass2="getPassword2" :password2="form.submit.password2"  :username2="form.submit.username2" lateral=true /> -->
        <button :class="form.submit.notes ? 'submit-button':'grey-button'" @click="form.submit.notes ? submitForm():''" style="margin-top: 0; align-self: end" >Balance Check</button> 
        <SignatureModal v-if="displaySignatureModal" @isSuccessful="isSuccessful" dual="true" @close="close" endpoint="/stock-take-submit" :payload="form.submit" />       
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue'

export default {
    props:['toCache', 'clear'],
    data(){
        return{
            displaySignatureModal: false,
            form:{
                submit:{
                    notes:'',
                    stocktake:[]
                }
            }
        }
    },
    components:{
        SignatureModal
        // SingleVerify,
        // DualVerify
    },
    methods:{
        isSuccessful(value){
            if(value){
                window.location.reload();
            }
        },
        submitForm(){
            // console.log('this is the verify from the destroy form', this.store.verify)
            // this.$store.dispatch('submitForm',this.form);
            this.form.submit.stocktake = this.$store.state.stockTakeCount;
            this.displaySignatureModal = true;
            // this.form = {
            //     // type:'stock-take-submit',
            //     submit:{
            //         location:'',
            //         notes:'',
            //         stocktake:this.toCache
            //     }
            // };
            // this.$emit('clear', true);
        },
        close(value){
            if(value){
                this.displaySignatureModal = false;
            }
        }
    },
    
}
</script>