<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel">
      <h3 class="panel-header">Actions</h3>
      <ActionsMenu />
    </div>
    <div class="panel">
      <h3 class="panel-header">Stocktake | <span style="color: darkslategray">{{ $store.state.currentLocation.label }}</span></h3>
      <div class="drug-form-table table-container" style="padding: 23px">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">
            <div class="text-search-container search-container">
            <input v-model="form.filters.product_name" placeholder="Medication..." style="position: relative" />
            <input v-model="form.filters.patient" placeholder="Patient..." style="position: relative; margin-left: 10px;" />
            <div class="drug-search-result-container" v-if='form.filters.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
                <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
              </div>
              
            </div>
            <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Date..."  dateFormat="dd/mm/yy" />
            </div>
            <button class="button submit-button" @click="loadStockTake()">Search</button>
            <button class="button clear-button" @click="clearStockTakeFilters()">Clear</button>
          </div>
          <!-- SELECT FILTER SECTION -->
          <div style="display: flex; align-items: center;">
            <div class="select-filters-container">

              <!-- <p @click="()=>{filters.all = true; filters.in_stock = false; filters.s8 = false;}" 
                  v-bind:class="[filters.all == true ? 'active-select-filter' : '']" class="select-filter-item">All</p> -->

              <!-- <p @click="()=>{filters.is_active = !filters.is_active; filters.all = false; autoAll()}" 
              v-bind:class="[filters.is_active == true && filters.all == false  ? 'active-select-filter' : '']" class="select-filter-item">Active</p> -->

              <p @click="()=>{filters.s8 = !filters.s8; autoAll()}" 
              v-bind:class="[filters.s8 == true ? 'active-select-filter' : '']" class="select-filter-item">Include S8</p>

              <p @click="()=>{form.filters.in_stock = !form.filters.in_stock;}" 
              v-bind:class="[form.filters.in_stock == true ? 'active-select-filter' : '']" class="select-filter-item">Display In Stock Only</p>

              <p @click="()=>{form.filters.due_stocktake = !form.filters.due_stocktake;}" 
              v-bind:class="[form.filters.due_stocktake == true ? 'active-select-filter' : '']" class="select-filter-item">Due for Stocktake</p>

              <p @click="()=>{form.filters.imprest_only = !form.filters.imprest_only;}" 
              v-bind:class="[form.filters.imprest_only == true ? 'active-select-filter' : '']" class="select-filter-item">Display Imprest Only</p>

              <p @click="()=>{form.filters.show_inactive = !form.filters.show_inactive;}" 
              v-bind:class="[form.filters.show_inactive == true ? 'active-select-filter' : '']" class="select-filter-item">Include inactive products</p>
            </div>
            <!-- <div>
              <input type="checkbox" id="inactive" name="inactive" v-model="form.filters.show_inactive">
              <label for="inactive">Include inactive products</label><br>
            </div> -->
          </div>
        </div>
        <div v-if="!$store.state.loadingStockTakeData" style="display: flex; align-items: start; overflow-y: scroll">
        <table v-if='$store.state.user.role.permissions.includes("can_view_stock_take")' class="data-table" cellspacing="0" >
          <tr class="table-headers" >
            <th height=40px style="width: 20em">
              <h3 @click="sortBy('drug_cards.concat_name', 'desc')">Medication</h3>
              <div v-if="form.col == 'drug_cards.concat_name'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort drug-sort" v-if="form.col =='drug_cards.concat_name' && form.sort_direction =='asc'" @click="sortBy('drug_cards.concat_name', 'desc')"></i>
                <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug_cards.concat_name', 'asc')"></i>
              </div>
            </th>
            <th height=40px style="min-width: 100px">
              <h3 @click="sortBy('personal_information.firstname', 'desc')">Patient</h3>
              <div v-if="form.col == 'personal_information.firstname'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort patient-sort" v-if="form.col =='personal_information.firstname' && form.sort_direction =='asc'" @click="sortBy('personal_information.firstname', 'desc')"></i>
                <i class="pi pi-sort patient-sort" v-else @click="sortBy('personal_information.firstname', 'asc')"></i>
              </div>
              
            </th>
            <th style="min-width: 100px">
              <h3 @click="sortBy('stocktake_items.created_at', 'desc')">Last Count</h3>
              <div v-if="form.col == 'stocktake_items.created_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  date-sort" v-if="form.col =='stocktake_items.created_at' && form.sort_direction =='asc'" @click="sortBy('stocktake_items.created_at', 'desc')"></i>
              <i class="pi pi-sort date-sort" v-else @click="sortBy('stocktake_items.created_at', 'asc')"></i>
              </div>
            </th>
            <th style="min-width: 90px">
              <h3 @click="sortBy('quantity', 'desc')">Expected</h3>
              <div v-if="form.col == 'quantity'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  expected-sort" v-if="form.col =='quantity' && form.sort_direction =='asc'" @click="sortBy('quantity', 'desc')"></i>
              <i class="pi pi-sort expected-sort" v-else @click="sortBy('quantity', 'asc')"></i>
              </div>
            </th>
            <th style="min-width: 100px">
              <h3>Counted</h3>
            </th>
            <th style="width: 80px">
              <h3>Diff</h3>
            </th>
            <th style="min-width: 100px">
              <h3>Status</h3>
            </th>

          </tr>
          <tbody 
            class="table-data"
            v-for="(item, index) in getDrugEntries"
            :key="index"
            :class="[item.count >=0 && item.count == item.qty && item.count != '' ? 'correct-status-color' : (item.count && item.count != item.qty? 'mismatch-status-color' : '')]">
          <tr>
            <td height="40px">
              <b>{{item.type == 'pending destruction' ? '[PENDING DESTRUCTION]':''}}</b> {{item.alias ? `${item.alias.name}` : ''}}
            </td>
            <td>
              {{item.patient}}
            </td>
            <td>
              {{item.last_counted}}
            </td>
            <td v-if="item.alias && item.alias.form">
              {{item.qty}} {{!item.alias ? '' : item.alias.form + '(s)' }}
            </td>
            <td>
              <input type="number" min="0" @change="addToStockTake(item.uuid, $event.target.value, item), displayDiff(item, $event.target.value)" placeholder='Enter count'
              :disabled="$store.state.currentLocation.state == 'WA' && toCache.length && toCache[0].uuid != item.uuid" v-if="($store.state.currentLocation.state == 'WA' && (!toCache.length || toCache[0].uuid == item.uuid)) || $store.state.currentLocation.state != 'WA'" :value="item.count" style="width: 80%">
              <!-- <div  v-for="(cacheItem, key) in toCache" :key="key">
                <input v-if="cacheItem.uuid == item.uuid" type="number" @change="addToStockTake(item.uuid, $event.target.value)" :value="cacheItem.count" placeholder='Enter count'>
              </div> -->
            </td>
            <td>{{item.count ? (item.count - item.qty).toFixed(2) : ''}}</td>
            <td>
              <p :class="[item.status == 'Overdue' ? 'mismatch-status-color' : (item.status == 'N/A' && item.last_counted == 'N/A') ?  'warning-status-color' : 'blue-status-color']"
              style="font-weight: bold !important"
              :style="item.status == 'Overdue' ? {color: '#E04F3 !important'}:{}">{{item.status == 'Overdue' ? 'Stocktake Now' : (item.status == 'N/A' && item.last_counted == 'N/A') ? 'Never Counted': 'Counted'}}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="drug-register-table table-container" style="padding-top: 10em;" v-else >
          <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
        </div>
        </div>
        <div v-else>
          <h3 v-if="$store.state.stockError">{{$store.state.stockError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="$store.state.drugEntries.current_page">
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
          </div>
        <NewStockTake :toCache="toCache" @clear="clearStockValues" @reload="reload" />
       </div>
       
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import ActionsMenu from '@/components/actions/ActionsMenu.vue';
import NewStockTake from '@/components/actions/NewStockTake.vue';

export default {
  components: {
    ActionsMenu,
    NewStockTake,
},
  data(){
    return{
      loadingError:'',
      drugNames: [],
      registerData: [],
      filters:{
          s8: true
      },
      toCache:[],
      date:[],
      clearForm:{
        sort_direction: '',
        col: '',
        page: 1,
          location: this.$store.state.uuid,
        filters:{
          tags:['s8'],
          show_inactive: false,
          imprest_only: false,
          in_stock: true,
          due_stocktake: false,
          date:{
            start:'',
            end:''
          },
          product_name:'',
          patient:''
        }
      },
      form:{
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          tags:["s8"],
          show_inactive: false,
          imprest_only: false,
          in_stock: true,
          due_stocktake: false,
          date:{
            start:'',
            end:''
          },
          product_name:'',
          patient:''
        }
      },
      drugEntries:[
        
      ]

    }
  },
  methods:{
    reload(value){
      if(value){
        this.toCache=[];
        this.$store.commit('updateCount', this.toCache);
        this.loadStockTake();
        
      }
    },
    clearStockValues(value){
      console.log('clear has been requested')
      if(value == true){
        this.toCache = [];
      }
      this.$store.commit('updateCount', this.toCache);
      this.$store.dispatch('getStockTake', this.form);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getStockTake', this.form)
    },
    addToStockTake(id, countValue, stock){
        // console.log('this is the drug and count', id, countValue);
        if(!this.toCache.length){
          // console.log('there is nothing in the cache array right now');
          console.log('this is the count and qty', countValue, stock.qty);
          this.toCache.push({uuid: id, count: Number(countValue), expected:stock.qty, type: stock.type, name: stock.alias.name, adjustmentRequired: countValue != stock.qty});
        } else{
          let listOfIds = [];

          this.toCache.forEach((item)=>{
            listOfIds.push(item.uuid);
          })
          if(!listOfIds.includes(id) && countValue >= 0){
            console.log('this is the count and qty', countValue, stock.qty);
            this.toCache.push({uuid: id, count: Number(countValue), expected:stock.qty, type: stock.type, name: stock.alias.name, adjustmentRequired: countValue != stock.qty})
          } else{
            this.toCache.forEach((item,index)=>{
              if(item.uuid == id){
                if(countValue === 0 || countValue > 0){
                  item.count = Number(countValue);
                } else{
                  this.toCache.splice(index, 1);
                }
              }
              if(isNaN(item.count)){
                this.toCache.splice(index, 1);
              }
            })
          }
        }
        this.$store.commit('updateCount', this.toCache);
        // this.$store.dispatch('getStockTake', this.form);
    },
    displayDiff(item, countValue){
      item.count = countValue;
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadStockTake(){
      console.log('this is the date', this.date);
      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString();
        }
        this.$store.dispatch('getStockTake', this.form);
      } else{
        this.form.filters.date.start ='';
        this.form.filters.date.end = '';
        this.$store.dispatch('getStockTake', this.form);
      }
      
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getStockTake', this.form);
    },
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    clearStockTakeFilters(){
      this.form = {
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          tags:["s8"],
          show_inactive: false,
          imprest_only: false,
          in_stock: true,
          due_stocktake: false,
          product_name:'',
          patient:'',
          date:{
            start:'',
            end:''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getStockTake', this.form);
    },
  },
   mounted(){
    this.loadStockTake();
    this.toCache = [];
    this.$store.dispatch('emptyStockCache');
    // this.$store.dispatch('algoliaSearch', 'cas');
  },
  computed:{
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    },
    getDrugNames(){
      return this.$store.getters.getDrugNames
    }
  }
}
</script>
