<template>
  <div class="drug-register-view view" style="margin: 1em 0; min-height: 85vh; overflow: scroll; display: grid">

    <h2 class="panel-header">Settings</h2>
    <div style="display: flex; background-color: white; width: 95.4vw; border-radius: 12px;" class="panel settings-panel">
      <div class="actions-panel panel left-side" style="width: 400px; margin: 25px; background-color: #f3f4f4">

        <div class="settings-directory" >
          <div v-for="(item, index) in directory" :key="index">
            <!-- @click="item.open = !item.open" -->
            
            <h4 class="settings-header" :class="item.open ? 'active-settings-header':''" @click="item.open = !item.open">{{ item.name }}
            <span>
              <i class="pi pi-chevron-down" v-if="item.open"></i>
              <i class="pi pi-chevron-right" v-else></i>
            </span></h4>
            <div class="settings-subdirectory" v-if="item.open">
              <p :class="[subView == sub ? 'active-subdirectory-item':'']"
                @click="subView = sub, selectedView = item.name, retrieveRelevantData(sub)"
                v-for="(sub, i) in item.directory" :key="i">{{ sub }}</p>
            </div>
          </div>
        </div>

      </div>
      <div style="margin: 25px; margin-left: 0px; width: 70%;">
        <div style="display: flex; align-items: center;">
          <!-- <h2 class="panel-header">{{ selectedView }} </h2> -->
          <h2 class="panel-header" style="margin-left: 0; padding-left: 10px; color: #0870DC" v-if="subView"> {{ subView }}</h2>
        </div>

        <div v-if="!$store.state.user.role.permissions.includes('view_admin')">
          <!-- BASIC CONFIGURATION -->
          <!-- BASIC CONFIGURATION -->
          <!-- BASIC CONFIGURATION -->
          <div
            v-if="subView == 'Basic Configuration' && $store.state.user.role.permissions.includes('can_view_admin_company')"
            style="display: grid; grid-gap: 10px; grid-template-columns: 1fr 1fr;">
            <div style="display: grid; grid-gap: 10px;">
              <div style="display: grid; justify-content: flex-start">
                <p style="font-size: 1em; text-align: left;">Set Stocktake frequency for S8 (hours)</p>
                <div style="display: flex; align-items: center;">
                  <input type="number" placeholder="Enter hours here" min="0" style="width: 26em;" v-model="s8Frequency" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')">
                  <p class="blue-button complete-button" @click="setFrequency(true)"
                    v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                    style="cursor:pointer; width: fit-content; margin: 1em 0; margin-left: 1em; padding: 8px 20px;">Save</p>
                </div>
              </div>

              <div style="display: grid; justify-content: flex-start">
                <p style="font-size: 1em; text-align: left;">Set Stocktake frequency for non-S8</p>
                <div style="display: flex; align-items: center;">
                  <input type="number" placeholder="Enter hours here" min="0" style="width: 26em;"  v-model="nons8Frequency" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')">
                  <Dropdown v-model="nons8FrequencyUnit" :options="setFrequencyInterval" placeholder="Select a unit" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"/>
                  <p class="blue-button complete-button" @click="setFrequency(false)"
                    v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                    style="cursor:pointer; width: fit-content; margin: 1em 0; margin-left: 1em; padding: 8px 20px;">Save</p>
                </div>
              </div>

              <!-- Enabling/disabling blind stock count (take) on facility -->
              <div style="display: grid; justify-content: flex-start">
                <p style="font-size: 1em; text-align: left;">Set Blind count for drug register actions</p>
                <div style="display: flex; align-items: center;">
                  <input style="width: 1.2em;" type="checkbox" v-model="enableBlindCount" name="inactive" @change="updateBlindCountSetting()"
                  :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')">
                  <label style="font-weight: 200;margin-left:5px" for="inactive"> Force Blind Stock Count</label>
                </div>
              </div>

            </div>
            

          </div>

          <!-- REGISTER USER -->
          <!-- REGISTER USER -->
          <!-- REGISTER USER -->
          <div v-if="subView == 'Register User' && $store.state.user.role.permissions.includes('can_do_admin_users')"
            style="display: grid; grid-gap: 10px;">
            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em; align-items:flex-start">
              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name" style="font-weight: 500">First Name <span style="color: red;">*</span></label>
                <input type="text" v-model="registerUserPersonalInfo.first_name" placeholder="Enter First Name *">
              </div>
              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name" style="font-weight: 500">Last Name <span style="color: red;">*</span></label>
                <input type="text" v-model="registerUserPersonalInfo.last_name" placeholder="Enter Last Name *">
              </div>
              <!-- <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name" style="font-weight: 500">Profession</label>
                <input type="text" v-model="registerUserContactInfo.identification" placeholder="Enter Profession">
            </div> -->
            <!-- Phone number field -->
            <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name" style="font-weight: 500">Phone Number <span style="color: red;">*</span></label>
                <div style="display: grid;grid-template-columns: 40% 58%; grid-gap: 2%;padding-bottom: 10px;">
                  <div style="display: flex; align-items: center">
                    <div class="select-filters-container">
                      <p @click="()=>{isMobile = false}" 
                          v-bind:class="[isMobile == false ? 'active-select-filter' : '']" class="select-filter-item">Landline</p>
                      <p @click="()=>{isMobile = true}" 
                          v-bind:class="[isMobile == true ? 'active-select-filter' : '']" class="select-filter-item">Mobile</p>
                    </div>
                  </div>
                <Dropdown v-model="selectedPhoneCountry" :options="countries" optionLabel="name" placeholder="Country Code" class="w-full md:w-14rem" />
              </div>
              <div>
                <div style="display: grid;grid-template-columns: 10% 90%; align-items: center ;" >
                  <p>{{ selectedPhoneCountry.callingcode }} </p> 
                  <InputText type="number" style="background-color: #F3F4F4;"  v-model="registerUserContactInfo.phone_number" placeholder="Enter Phone Number" />
                </div>

              </div>
            </div>

            <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name" style="font-weight: 500">Identification Number</label>
                <input type="text" v-model="registerUserContactInfo.identification"
                  placeholder="Enter Identification Number (e.g. AHPRA No.)">
              </div>

              <div class="form-field wide-text-field" style="width: 80%;">
                <label for="first_name" style="font-weight: 500">Email Address</label>
                <input type="text" style="margin-bottom: 0; width: 100%" v-model="registerUserContactInfo.email"
                  placeholder="Enter Email">
                <p style="margin: 0; font-size: 1em">
                  This email address does not affect login details, but is required for MIMS activation.
                </p>
              </div>


              <div class="form-field wide-text-field" style="width: 80%">
                <label for="first_name" style="font-weight: 500">Address: <span v-if="registerUserAddress.address" style="font-size: 0.9em">{{
                    registerUserAddress.address.street_address
                }},
                    {{ registerUserAddress.address.suburb }}, {{ registerUserAddress.address.country }}</span></label>
                <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                <div class="form-field text-field" style="width: 100%; margin-top: 0">
                  <vue-google-autocomplete id="contactmap" classname="form-control"
                    :placeholder="registerUserAddress.address ? registerUserAddress.address.street_address + ', ' + registerUserAddress.address.suburb + ', ' + registerUserAddress.address.country : '1 Apple Street, Melbourne, VIC 3000'"
                    v-on:placechanged="getPersonalAddressData" :country="$store.state.country">
                  </vue-google-autocomplete>
                </div>
              </div>
            </div>

            <p v-if="createUserError" style="color: red; font-weight: 500; font-size: 1em;">{{ createUserError }}</p>
            <p v-if="!registerUserPersonalInfo.first_name || !registerUserPersonalInfo.last_name || !registerUserContactInfo.phone_number" class="grey-button" style="cursor:pointer; width: 15em;">Register User</p>
            <p v-else @click="submitUser()" class="blue-button complete-button" style="cursor:pointer; width: 15em;">Register User</p>
            <!-- MATCHES FOUND -->
            <div v-if="matchesFound">
              <h4 style="text-align: left;">Matches Found ({{ matchesFound.length }})</h4>
              <div style="display: grid; grid-gap: 1em;grid-template-columns: auto auto auto auto; width: 100%;">
                <div @click="useExistingUser(item, index)" v-for="(item, index) in matchesFound" :key="index"
                  :style="item.selected ? { border: '1px solid green', backgroundColor: '#00ad5031' } : { border: '1px solid green' }"
                  style="width: fit-content; max-height: 6.25em; overflow: scroll; border-radius: 8px; cursor: pointer; padding: 0 20px;">
                  <p v-if="item.personal_information && item.personal_information.personal_information" style="color: green;">{{ item?.personal_information?.first_name }}
                    {{ item?.personal_information?.last_name }}</p>
                  <div style="margin-top: 7px;">
                    <p style="color: green; margin: 0" v-for="(email, i) in item.contact_methods.email" :key="i">{{ email.data }}</p>
                    <p style="color: green; margin: 0" v-for="(phone, p) in item.contact_methods.phone" :key="p">{{ phone.data }}</p>
                  </div>
                </div>
                <div @click="createNewUser(), matchesFound = ''"
                  style="width: 10em; height: 50px; border-radius: 8px; border: 1px solid green; display: flex; align-items: center; justify-content: center;cursor: pointer">
                  <p style="color: green;">Create new user</p><i class="pi pi-plus-circle" style="color: green"></i>
                </div>
              </div>

            </div>
            <!-- NEW LOGIN ACCOUNT FOR EXISTING USER -->
            <h3 v-if="displayLoginCreate"  style="text-align: left; margin-bottom: 0;">Login account: </h3>
            <div v-if="displayLoginCreate" style="width: 100%; display: flex; flex-wrap: wrap;">
              <div style="width: 50%;">
                
                <div style="display: grid; grid-gap: 1em;">
                  <div style="display: grid; grid-template-rows: auto auto">
                    <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Username<span style="color: red">*</span></p>
                    <input type="text" style="width: 80%;" v-model="registerLogin.name" placeholder="Create a Username">
                  </div>
                  <div style="display: grid; grid-template-rows: auto auto">
                    <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Password<span style="color: red">*</span></p>
                    <input type="text" style="width: 80%;" v-model="registerLogin.password" minlength="8" placeholder="Create a Password">
                    <p v-if="passwordWarning">{{ passwordWarning }}</p>
                  </div>
                  

                <div style="display: flex; flex-direction: column">
                      <p style="text-align: left; font-size: 1em; font-weight: 500;margin: 5px 0;">Login contact email</p>
                      <Dropdown style="width: 80%" v-if="user"
                      v-model="newLoginForm.email_id" :options="user.emails ? user.emails : user.contact_methods.email ? user.contact_methods.email : 'No option available.'" optionLabel="data" optionValue="uuid" placeholder="Select a email" />
                      <i v-else class="pi pi-spin pi-spinner" style="fontSize: 2rem; margin-top: 1em;"></i>
                </div>

                <div style="display: flex; flex-direction: column" v-if="user">
                      <p style="text-align: left; font-size: 1em; font-weight: 500;margin: 5px 0;">Login mobile number</p>
                      <Dropdown style="width: 80%" v-if="loginaccountmobiles.length"
                      v-model="newLoginForm.phone_id" :options="loginaccountmobiles" optionLabel="data" optionValue="uuid" placeholder="Select a phone number" />
                      <div v-if="!loginaccountmobiles.length"><p style="text-align: start;">No mobile numbers available. Edit User to add them.</p></div>
                </div>
              </div>
                   <!-- user.contact_methods.phone possibly doesn't exist -->
                
                
                <!-- <div class="p-field-radiobutton" v-for="(item, index) in roles" :key="index">
                  <RadioButton :name="item.name" :value="item.uuid" v-model="newLoginForm.role" />
                  <label :for="item.name">{{ item.name }}</label>
                </div> -->
              </div>
              <div style="width: 50%;">

                <div style="display: grid; grid-gap: 1em; width: 100%;">

                <div style="display: flex; flex-direction: column;">
                  <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Select Role<span style="color: red">*</span></p>
                  <Dropdown style="width: 80%;" v-model="newLoginForm.role" :options="roles"
                  optionLabel="name" optionValue="uuid" placeholder="Select a Role"  />
                </div>
                <div style="display: flex; flex-direction: column;">
                  <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Select Locations<span style="color: red">*</span></p>
                  <MultiSelect style="width: 80%;" v-model="newLoginForm.locations"  :options="flatLocations" :maxSelectedLabels="3"
                    optionLabel="name" :filter="true" placeholder="Select Locations" />
                  <!-- <p @click="linkLocations(newLoginForm)" class="blue-button" style="cursor:pointer; margin-top: 1em;">Link Locations</p> -->
                </div>

                </div>

                <div style="margin-top: 25px;">
                  <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0;  font-size: 1em;'>Dashboard Type<span style="color: red">*</span></p>
                  <SelectButton style="display: flex; width: 80%" class="dashboard-type" v-model="newLoginForm.type" :options="loginTypes" optionLabel="name" optionValue="value" />
                </div>

                <div style="text-align: left; font-size: 1em; margin-top: 25px;">
                  <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0;  font-size: 1em;'>Select Timezone<span style="color: red">*</span></p>
                  <Dropdown v-model="newLoginForm.timezone" :options="timezones" placeholder="Select a timezone" style="width: 80%;" />
                </div>


                <div style='margin-top: 20px;' v-if="displayLoginCreate">
                  <p v-if="loginCreateError" style="font-size: 1em; color: red; font-weight: 500">{{ loginCreateError }}</p>
                  <!-- <p class="blue-button" style="width: fit-content;" @click="submitLogin()"></p> -->
                  <p @click="newLoginForm.type ? submitLogin() : ''" class="blue-button" :class="newLoginForm.type ? ' complete-button' : 'grey-button'"
                  style="width: 15em; margin: 1em 0;" :style="newLoginForm.type ? {cursor:'pointer'}:{ cursor:'inherit'}">Create Login Account</p>
                  <p v-if="!newLoginForm.type" style="color: red; font-size: 1em; text-align: left;">Dashboard Type is required.</p>
                </div>
              </div>
              

              
            </div>
            <!-- END NEW LOGIN ACCOUNT FOR EXISTING USER -->
            <!-- <input type="text" v-model="registerUser.location" placeholder="Location"> -->

          </div>

          <!-- EDIT USER -->
          <!-- EDIT USER -->
          <!-- EDIT USER -->
          <div v-if="subView == 'Edit User' && $store.state.user.role.permissions.includes('can_view_admin_users')"
            style="display: grid; margin-top: 0.25em;">
            <label for="first_name" style="text-align: left; padding-left: 10px">Search for user</label>
            <div class="text-search-container search-container"
              style="display: flex; align-items: center; max-width: 25em">
              <input v-model="searchEntry" v-on:keyup.enter="searchUser(searchEntry)" placeholder="Search by username."
                style="position: relative; width: 100%;" />
              <div style="cursor: pointer; margin-left: 10px;" @click="searchUser(searchEntry)">
              <p class="green-button">Search</p></div>
            </div>
            <div v-if="loadingUsers">
              <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div class="drug-variances-container"
              style="display: flex;align-items: center;margin-top: 0; height: fit-content; align-items: center;padding-top: 10px;"
              v-show='searchUserResults.length'>
              <p style="padding: 5px 10px; margin-right: 10px; margin-top: 0; margin-bottom: 0; font-weight: 500">Possible
                matches: </p>
              <p v-for="(item, index) in searchUserResults" :key="index" @click="retrieveUser(item)"
                class="drug-variance-option" style="margin: 0; margin-right: 10px; font-size: 1em; font-weight: bold;">{{ item.name }}</p>
            </div>
            <div v-if="noResults">
              <p style="font-weight: 500; font-size: 1em;">No user found with that username.</p>
            </div>
            <!-- DISPLAY IF USER IS RETRIEVED -->
            <div v-if='user'>
              <h4 style="text-align: left;padding-bottom: 0px;">User Details</h4>
              <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em;">

                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="first_name">First Name <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="user.first_name" placeholder="Enter First Name">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="first_name">Last Name <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="user.last_name" placeholder="Enter Last Name">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;" v-for="(item, index) in user.regulatory_numbers" :key="index">
                  <label for="first_name">{{item.regulatory_type}}</label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="item.data"
                    placeholder="Enter Identification Number (e.g. AHPRA No.)">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;" v-if="user.prescriber_details && user.prescriber_details.email && user.hpi_number && user.hpi_number">
                  <label for="first_name">HPI Number</label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="user.hpi_number.number"
                    placeholder="Enter HPI Number (e.g.HPI No.)">
                </div>

                <div class="form-field wide-text-field" style="width: 80%">
                  <label for="first_name">Address:</label>
                  <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                  <div class="form-field text-field" style="width: 100%; margin-top: 0">
                    <vue-google-autocomplete id="contactmap" classname="form-control"
                      :placeholder="registerUserAddress.address ? registerUserAddress.address.street_address + ', ' + registerUserAddress.address.suburb + ', ' + registerUserAddress.address.country : '1 Apple Street, Melbourne, VIC 3000'"
                      v-on:placechanged="getPersonalAddressData" :country="$store.state.country" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')">
                    </vue-google-autocomplete>
                  </div>
                </div>

              </div>
              <h4 style="text-align: left;">Contact Details</h4>
              <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em;">
               
                <!--Phone numbers box  -->
                <div style="display: grid;grid-template-rows: auto auto auto;">
                <div style="overflow-y: auto; overflow-x: hidden; height: 110px; border-radius: 8px; border: 2px solid #f3f4f4;width: 80%; ">
                  <div style="padding: 3px 3px;" v-for="(item, index) in phonesInContactMethods" :key="index">
                    <div v-if="item.type == 'phone'" style="width: 100%">
                      <div style="display:grid;grid-template-columns: 10% 20% 67%;">
                        <i class="pi-phone pi input-icon"></i>
                        <div style="background-color: #F3F4F4; border-top-right-radius: 8px; border-bottom-right-radius: 8px; line-height: 35px;" v-if="item.name"> {{ item.name == 'phone' ? "Mobile" : "Landline" }}</div> 
                      <input type="text" v-model="item.data" placeholder="Enter Phone Number" style="margin-left: 3%; width: 100%;border-radius: 8px;" class="iconned-field" />
                      </div>
                    </div>
                </div>
              </div>
              <p style="margin: 0; font-size: 1em; text-align: left;width: 80%">
                    Only mobile numbers can be used as a login account number.
                </p>
              <!-- NEW PHONE BUTTON -->
              <div v-if="!displayNewPhone && !displayNewEmail && $store.state.user.role.permissions.includes('can_do_admin_users')" class="button outline-button"
              style="margin: 1em 0; margin-right: 1em;" @click="displayNewPhone = true">
                  <p><span style="font-size: 1.2em;">+</span> Add Phone Number</p>
              </div>
            </div>

            <!--Email adresses box  -->
            <div style="display: grid;grid-template-rows: auto auto auto;">
                <div style="overflow-y: auto; overflow-x: hidden; height: 110px; border-radius: 8px; border: 2px solid #f3f4f4;width: 80%; ">
                <div style="padding: 3px 3px;" v-for="(item, index) in emailsInContactMethods" :key="index">
                  <div v-if="item.type == 'email'" style="width: 100%">
                    <div style="display:grid;grid-template-columns: 10% 90%;">
                      <i class="pi-envelope pi input-icon"></i>
                      <input type="text" style="margin-bottom: 0; width: 100%;" class="iconned-field" v-model="item.data"
                        placeholder="Enter Email">
                    </div>
                    
                  </div>
                </div>
              </div>
              <p style="margin: 0; font-size: 1em; text-align: left;width: 80%">
                    Email address(s) does not affect login details, but is required for MIMS activation.
                </p>
              <!-- NEW EMAIL BUTTON -->
              <div v-if="!displayNewEmail && !displayNewPhone && $store.state.user.role.permissions.includes('can_do_admin_users')" class="button outline-button"
                  style="margin: 1em 0; margin-right: 1em;" @click="displayNewEmail = true">
                  <p><span style="font-size: 1.2em;">+</span> Add Email </p>
                </div>

              </div>
              </div>
              

            


              <!-- ADD NEW EMAIL ADDRESS TO EXISTING USER -->
              <!-- ADD NEW EMAIL ADDRESS TO EXISTING USER -->
              <div v-if="displayNewEmail">
                <input type="text" style="margin-bottom: 0;margin-top: 10px; width: 50%; display: flex;" v-model="newEmail.data"
                  placeholder="Enter Email">
                <p style="margin: 0; font-size: 1em; text-align: left;">
                  This email address does not affect login details, but is required for MIMS activation.
                </p>
              </div>
              <div style="display: flex;">
                <div v-if="displayNewEmail" class="button outline-button"
                  style="margin: 1em 0; margin-right: 1em; border-color: grey;" @click="displayNewEmail = false">
                  <p style="color: grey;">Cancel</p>
                </div>
                <div v-if="displayNewEmail" class="button outline-button" style="margin: 1em 0; margin-right: 1em;"
                  @click="displayNewEmail = false, addEmailToUser()">
                  <p>Submit</p>
                </div>
              </div>



              <!-- ADD NEW PHONE TO EXISTING USER -->
              <!-- ADD NEW PHONE TO EXISTING USER -->
              <div v-if="displayNewPhone" style=" width:300px">
                  <div class="form-field wide-text-field" style="width: 100%;">
                    <div style="display: grid;grid-template-columns: 50% 48%; grid-gap: 2%;padding-bottom: 10px;">
                  <div style="display: flex; align-items: center">
                    <div class="select-filters-container">
                      <p @click="()=>{isMobile = false}" 
                          v-bind:class="[isMobile == false ? 'active-select-filter' : '']" class="select-filter-item">Landline</p>
                      <p @click="()=>{isMobile = true}" 
                          v-bind:class="[isMobile == true ? 'active-select-filter' : '']" class="select-filter-item">Mobile</p>
                    </div>
                  </div>
                <Dropdown v-model="selectedPhoneCountry" :options="countries" optionLabel="name" placeholder="Country Code" class="w-full md:w-14rem" />
              </div>
              <div style="display: grid;grid-template-columns: 10% 90%; align-items: center ;" >
                  <p> {{ selectedPhoneCountry.callingcode }} </p> 
                  <InputText type="number" style="background-color: #F3F4F4;" v-model="registerUserContactInfo.phone_number" placeholder="Enter Phone Number" />
              </div>
            </div>
              </div>

             

              <div style="display: flex;">
                <div v-if="displayNewPhone" class="button outline-button"
                  style="margin: 1em 0; margin-right: 1em; border-color: grey;" @click="displayNewPhone = false">
                  <p style="color: grey;">Cancel</p>
                </div>
                <div v-if="displayNewPhone" class="button outline-button" style="margin: 1em 0; margin-right: 1em;" @click="displayNewPhone = false, addPhoneToUser()">
                  <p>Submit</p>
                </div>
              </div>

              <p @click="editUser()" class="blue-button complete-button" style="cursor:pointer; width: 18em; margin: 1em 0;" v-if="$store.state.user.role.permissions.includes('can_do_admin_users')">
                  Update Basic User Information
              </p>




              <!-- LOOPING THROUGH LOGINS -->
              <div
                style="display: flex; justify-content: flex-start; flex-direction: column; width: 100%; margin-top: 1.5em;">
                <!-- <h3 style='text-align: left; margin: 5px 0'>Login Account/s</h3> -->
                <div>

                  <!-- <div v-for="(item, index) in user.logins" :key="index" style="display: flex; justify-content: flex-start; flex-direction: column; "> -->
                    <h4 style="text-align: left;padding-bottom: 0px;">Login Accounts</h4>
                    <div style="display: grid;grid-template-columns:1fr 1fr; width: fit-content"> 
                    <div style="display: grid;grid-template-rows: auto auto;">
                    <Dropdown v-if="!displayLoginCreate" v-model="loginToEdit" :options="user.logins" optionLabel="name"
                      placeholder="Select a login" />
                      <p class="red-button" style="width: fit-content; font-weight: bold; margin-top: 10px;" v-if="!resetPassword && $store.state.user.role.permissions.includes('can_do_admin_users') && !displayLoginCreate"
                        @click="resetPassword = true; loginToEdit.new_password = ''">Reset Password</p>
                    </div> 
                    <div>
                      <p v-if="!displayLoginCreate && $store.state.user.role.permissions.includes('can_do_admin_users')" class="green-button" style="margin-left: 1em; margin-top: 5px" @click="displayLoginCreate = true; refreshLoginForm()">
                        <i class="pi pi-plus-circle"></i>
                        Create New Login
                    </p>
                    <p v-if="displayLoginCreate" class="grey-button" style="margin-left: 1em;" @click="displayLoginCreate = false;">
                      <i class="pi pi-minus-circle"></i> Cancel New Login
                    </p>
                    </div>
                  </div>
                  <div v-if="!displayLoginCreate" style="width: 100%; display: flex; flex-wrap: wrap;">
                    <div style="width: 50%;">
                      
                      <!-- <div style="display: grid; grid-gap: 1em; width: 80%;"> -->
                      <div>
                      
                      <!-- v-on:keyup="validatePassword(loginToEdit.new_password, loginToEdit.name)" -->
                      <input type="text" placeholder="Enter New Password" v-if="resetPassword"
                        style="display: flex; width: 20em; margin: 15px 0" v-model="loginToEdit.new_password">
                      <p v-if="resetPassword">{{ passwordWarning }}</p>
                      <ul v-if="resetPassword">
                        <template v-for="(value, name) in passwordRestrictions" :key="name">
                          <li v-if="name == 'min_pwd_length' && value !== null && value !== false" style="text-align: left">
                            At least {{ value }} characters
                          </li>
                          <li v-else-if="name == 'at_least_1_num_pwd' && value !== null && value !== false" style="text-align: left">
                            At least 1 number
                          </li>
                          <li v-else-if="name == 'at_least_1_special_char_pwd' && value !== null && value !== false" style="text-align: left">
                            At least 1 special character
                          </li>
                          <li v-else-if="name == 'pwd_has_letters' && value !== null && value !== false" style="text-align: left">
                            At least 1 letter
                          </li>
                          <li v-else-if="name == 'pwd_is_mixed_case' && value !== null && value !== false" style="text-align: left">
                            At least 1 uppercase and 1 lowercase letter
                          </li>
                          <li v-else-if="name == 'pwd_reuse_interval' && value !== null && value !== false" style="text-align: left">
                            Password Reuse Interval: {{ value }} days
                          </li>
                          <li v-else-if="value !== null && value !== false" style="text-align: left">
                            {{ name }}: {{ value }}
                          </li>
                        </template>
                      </ul>
                      <div v-if="resetPassword && loginToEdit.new_password && loginToEdit.new_password.length && $store.state.user.role.permissions.includes('can_do_admin_users')" style="display: flex;">
                        <span class="blue-button" style="width: fit-content; margin: 10px 0; margin-right: 10px;"
                          
                          @click="editLoginPassword()">Reset</span>
                        <span class="grey-button" style="width: fit-content; margin: 10px 0;" v-if="resetPassword"
                          @click="resetPassword = false">Cancel</span>
                      </div>

                    </div>
                    <div style="margin-bottom: 1.2em; width: 80%;">
                      <p style="text-align: left; margin: 5px 0px; font-size: 1em; font-weight: bold;">Login contact email</p>
                      <p style="margin: 0; font-size: 1em; text-align: left">Current email: {{ loginToEdit.email }}</p>
                      <Dropdown style="width: 100%" v-model="loginToEdit.email_id" :options="user.emails" optionLabel="data" placeholder="Select a email" />
                    </div>

                    <div style="margin-bottom: 1.2em; margin-top: 2.2em; width: 80%;">
                      <p style="text-align: left; margin: 5px 0px; font-size: 1em; font-weight: bold;">Login mobile number</p>
                      <p style="margin: 0; font-size: 1em; text-align: left">Current number: {{ loginToEdit.phone }}</p>
                      <Dropdown style="width: 100%" v-model="loginToEdit.phone_id" :options="loginaccountmobiles" optionLabel="data" placeholder="Select a phone number" />
                      <div v-if="!loginaccountmobiles.length"><p style="text-align: start;">No mobile numbers available. Submit mobile numbers above to add them.</p></div>

                    </div>

                    <p style='text-align: left; margin: 5px 0;  font-size: 1em; font-weight: bold;'>
                        Link Login to Location/s
                    </p>
                    <MultiSelect style="width: 80%; display: flex;" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="loginToEdit.locations" display="chip"
                      :options="flatLocations" optionLabel="name" :filter="true" placeholder="Select Locations" />
                    <!-- <p @click="linkLocations(loginToEdit)" class="blue-button" style="cursor:pointer; margin-top: 1em; width: fit-content">Link Locations</p> -->
                    </div>
                    <div style="width: 50%;">
                    <!-- DASHBOARD TYPE -->
                    <div>
                        <p style='text-align: left; margin: 5px 0;  font-size: 1em; font-weight: bold;'>
                            Dashboard Type<span style="color: red">*</span>
                        </p>
                        <SelectButton style="display: flex; width: 80%" class="dashboard-type" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="loginToEdit.type"  :options="loginTypes" optionLabel="name" optionValue="value" />
                    </div>

                    <!-- EXISTING ROLE FOR USER -->
                    <div v-if='loginToEdit.roles' style="margin-top: 1em; display: flex; flex-direction: column;">
                      <h4 style='text-align: left; margin: 5px 0; margin-top:1em;'>Select Role</h4>
                      <Dropdown :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')"  style="margin: 0; width: 80%;" v-model="loginToEdit.roles[0]" :options="roles"
                  optionLabel="name" />
                      <!-- <div style="display:grid; grid-template-columns: 1fr 1fr">
                        <div class="p-field-radiobutton" v-for="(r, i) in roles" :key="i">
                          <RadioButton :name="r.name" :value="r" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="loginToEdit.roles[0]" />
                          <label :for="r.name">{{ r.name }}</label>
                        </div>
                      </div> -->
                      
                    </div>
                    <!-- TIMEZONE -->
                    <div style="display: flex; flex-direction: column;">
                      <h4 style='text-align: left; margin: 5px 0; margin-top:1em;'>Select Timezone</h4>
                      <Dropdown :disabled="!$store.state.user.role.permissions.includes('can_do_admin_users')" v-model="loginToEdit.timezone" :options="timezones" 
                      style="width: 80%;" placeholder="Select a timezone" />
                    </div>
                    <div style="width: 80%; display: flex; justify-content: flex-end">
                      <p @click="loginToEdit.type ? editLogin() : ''" v-if="$store.state.user.role.permissions.includes('can_do_admin_users')" :class="loginToEdit.type ? 'green-button' : 'grey-button'"
                    style="width: fit-content; margin: 1em 0; font-weight: bold;" :style="loginToEdit.type ? {cursor:'pointer'}:{ cursor:'inherit'}">Update Login Details</p>
                    </div>
                    
                    <p v-if="!loginToEdit.type" style="color: red; font-size: 1em; text-align: left;">Dashboard Type is required.</p>
                    <!-- <p @click="resetPassword()" class="blue-button" style="cursor:pointer; width: 15em;">Reset Login Password</p> -->
                  </div>
                  </div>
                  <!-- </div> -->

                  <!-- NEW LOGIN ACCOUNT FOR EXISTING USER -->
                  <!-- NEW LOGIN ACCOUNT FOR EXISTING USER -->
                  <!-- <div v-show="displayLoginCreate">
                    <div>
                      <h3 style="text-align: left;">Login account: </h3>
                      <div style="display: grid; grid-gap: 1em;">
                        <input type="text" v-model="registerLogin.name" placeholder="Create a Username">
                        <input type="text" v-model="registerLogin.password" minlength="8" placeholder="Create a Password">
                        <p>{{ passwordWarning }}</p>
                      </div>
                      <div>
                        <h4 style="text-align: left;">Login contact email:</h4>
                        <Dropdown style="width: 100%" v-model="newLoginForm.email_id" :options="user.emails" optionLabel="data" placeholder="Select a email" />
                      </div>
                      <h4 style='text-align: left; margin: 5px 0'>Select Role</h4>
                      <Dropdown style="width: 20em;" v-model="newLoginForm.role" :options="roles"
                  optionLabel="name"  />
                    </div>

                    <div>
                      <h4 style='text-align: left; margin: 5px 0'>Select Locations</h4>
                      <MultiSelect style="width: 20em;" v-model="newLoginForm.locations" display="chip"
                        :options="flatLocations" optionLabel="name" :filter="true" placeholder="Select Locations" />
                    </div>

                    <div style="text-align: left">
                      <h4>Select Timezone</h4>
                      <Dropdown style="width: 100%" v-model="newLoginForm.timezone" :options="timezones"
                        placeholder="Select a timezone" />
                    </div>

                    <div style='margin-top: 20px;' v-if="displayLoginCreate">
                      <p v-if="loginCreateError" style="font-size: 1em; color: red; font-weight: 500">{{ loginCreateError
                      }}
                      </p>
                      <p class="blue-button" style="width: fit-content;" @click="submitLogin()">Create Login Account</p>
                    </div>
                  </div> -->
                  <div v-if="displayLoginCreate" style="width: 100%; display: flex; flex-wrap: wrap;">
                      <div style="width: 50%;">
                        
                        <div style="display: grid; grid-gap: 1em; width: 80%;">
                          <div>
                            <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Username<span style="color: red">*</span></p>
                              <input type="text" style="width: 100%;" v-model="registerLogin.name" placeholder="Create a Username">
                            </div>
                            <div>
                              <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Password<span style="color: red">*</span></p>
                              <input type="text" style="width: 100%;" v-model="registerLogin.password" minlength="8" placeholder="Create a Password">
                            </div>
                            <!-- v-on:keyup="validatePassword(registerLogin.password, registerLogin.name)" -->
                            
                            <p v-if="passwordWarning">{{ passwordWarning }}</p>
                          </div>
                          <div style="display: flex; flex-direction: column;">
                                <p style="text-align: left; font-size: 1em; font-weight: 500;">Login contact email:</p>
                                <Dropdown style="width: 80%" v-if="user"
                                v-model="newLoginForm.email_id" :options="user.emails ? user.emails : user.contact_methods.email ? user.contact_methods.email : 'No option available.'" optionLabel="data" optionValue="uuid" placeholder="Select a email" />
                                <i v-else class="pi pi-spin pi-spinner" style="fontSize: 2rem; margin-top: 1em;"></i>
                            </div>
                          <div style="display: flex; flex-direction: column; margin-top: 25px;">
                            <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Select Role<span style="color: red">*</span></p>
                            <Dropdown style="width: 80%;" v-model="newLoginForm.role" :options="roles"
                            optionLabel="name" optionValue="uuid" placeholder="Select a Role"  />
                          </div>
                          
                          <!-- <div class="p-field-radiobutton" v-for="(item, index) in roles" :key="index">
                            <RadioButton :name="item.name" :value="item.uuid" v-model="newLoginForm.role" />
                            <label :for="item.name">{{ item.name }}</label>
                          </div> -->
                        </div>
                        <div style="width: 50%;">
                          <div style="display: flex; flex-direction: column;">
                            <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0'>Select Locations<span style="color: red">*</span></p>
                            <MultiSelect style="width: 80%;" v-model="newLoginForm.locations" display="chip" :options="flatLocations"
                              optionLabel="name" :filter="true" placeholder="Select Locations" />
                            <!-- <p @click="linkLocations(newLoginForm)" class="blue-button" style="cursor:pointer; margin-top: 1em;">Link Locations</p> -->
                          </div>

                          <div style="margin-top: 25px;">
                            <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0;  font-size: 1em;'>Dashboard Type<span style="color: red">*</span></p>
                            <SelectButton style="display: flex; width: 80%" class="dashboard-type" v-model="newLoginForm.type" :options="loginTypes" optionLabel="name" optionValue="value" />
                          </div>

                          <div style="text-align: left; font-size: 1em; margin-top: 25px;">
                            <p style='text-align: left; font-size: 1em; font-weight: 500; margin: 5px 0;  font-size: 1em;'>Select Timezone<span style="color: red">*</span></p>
                            <Dropdown v-model="newLoginForm.timezone" :options="timezones" placeholder="Select a timezone" style="width: 80%;" />
                          </div>


                          <div style='margin-top: 20px;' v-if="displayLoginCreate">
                            <p v-if="loginCreateError" style="font-size: 1em; color: red; font-weight: 500">{{ loginCreateError }}</p>
                            <!-- <p class="blue-button" style="width: fit-content;" @click="submitLogin()"></p> -->
                            <p @click="submitLogin()" class="blue-button" :class="newLoginForm.type ? ' complete-button' : 'grey-button'"
                            style="width: 15em; margin: 1em 0;" :style="newLoginForm.type ? {cursor:'pointer'}:{ cursor:'inherit'}">Create Login Account</p>
                            <p v-if="!newLoginForm.type" style="color: red; font-size: 1em; text-align: left;">Dashboard Type is required.</p>
                          </div>
                        </div>
                      </div>

                  
                  <!-- END NEW LOGIN ACCOUNT FOR EXISTING USER -->
                </div>
              </div>


              <!-- <p @click="resetPassword()" class="blue-button" style="cursor:pointer">Reset Password</p> -->
              <!-- <input type="text" v-model="registerUser.location" placeholder="Location"> -->
            </div>

          </div>


          <!-- FIND USER -->
          <!-- FIND USER -->
          <!-- FIND USER -->
          <div v-if="subView == 'Find User' && $store.state.user.role.permissions.includes('can_view_admin_users')"
            style="display: grid; grid-gap: 10px;">
            <div class="text-search-container search-container"
              style="display: flex; align-items: center; max-width: 25em;">
              <input v-model="searchEntry" v-on:keyup.enter="searchUser(searchEntry)" placeholder="Search by username."
                style="position: relative; width: 100%;" />
              <div style="cursor: pointer;" @click="searchUser(searchEntry)">
                <p class="green-button" style="margin-left: 10px;">Search</p>
              </div>
            </div>
            <div v-if="loadingUsers">
              <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: space-between;" v-else>
              <div style="flex:1">
                <table>
                  <tr>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Role</th>
                    <th width='10%'>Timezone</th>
                    <th width='5%' v-if="$store.state.user.role.permissions.includes('can_do_admin_users')"></th>
                  </tr>
                  <tr v-for="(item, index) in searchUserResults" :key="index">
                    <td v-if="item.person">{{ item.name }}</td>
                    <td v-if="item.person && item.person.personal_information">
                      {{ item.person.personal_information.first_name }}</td>
                    <td v-else></td>
                    <td v-if="item.person && item.person.personal_information">
                      {{ item.person.personal_information.last_name }}</td>
                    <td v-else></td>
                    <td >
                      {{ item.type ? item.type.charAt(0).toUpperCase() + item.type.slice(1) : "N/A" }}</td>

                    <td v-if="item.person">{{ item.timezone }}</td>
                    <td v-if="$store.state.user.role.permissions.includes('can_do_admin_users')" style="cursor: pointer;" @click="editUsrFromFind(item)"><i class="pi pi-user-edit" style="font-size: 1.2em !important;"></i></td>
                  </tr>
                </table>
              </div>

              <div class="pagination-links">
                <p class="pagination-arrow" v-show="searchPage > 1" @click="displayPage(searchPage - 1)"> &larr; </p>
                <p>Page {{ searchPage }} of {{ pageCount }}</p>
                <p class="pagination-arrow" v-show="searchPage < pageCount" @click="displayPage(searchPage + 1)"> &rarr;
                </p>
              </div>
            </div>
          </div>



          <!-- REGISTER PRESCRIBER/PRACTICE -->
          <!-- REGISTER PRESCRIBER/PRACTICE -->
          <!-- REGISTER PRESCRIBER/PRACTICE -->
          <!-- && $store.state.user.role.permissions.includes('can_do_admin_prescribers') -->
          <div
            v-if="subView == 'Register Practice/Prescriber' && $store.state.user.role.permissions.includes('can_do_admin_prescribers')"
            style="display: grid; grid-gap: 10px;">
            <div v-if="displayPrescriberRegistration">

            </div>
            <h4 style="text-align: left; margin: 0; margin-top: 1em;" v-if="!selectedPractice">
              Enter Practice Details
            </h4>
            <div v-if="!displayPrescriberRegistration">
              <h4 style="text-align: left;" v-if="!selectedPractice">
                Search for practice to see if it already exists:
              </h4>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <div style="display: flex; align-items: center; margin-top: -1em">
                <div v-if="!selectedPractice" class="text-search-container search-container text-field"
                  style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                  <label for="first_name" style="text-align: left; padding-left: 10px">Search for Practice</label>
                  <input v-model="practice" v-on:keyup="practiceSearch(practice)" placeholder="Enter Practice Name"
                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                  <div class="drug-search-result-container" v-if="!selectedPractice"
                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                    <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                      v-for="(item, index) in practiceResults" :key="index" @click="selectPractice(item)">{{ item.name }}
                    </p>
                  </div>
                </div>
                <div v-if="selectedPractice"
                  style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 50px; margin-top: 1em; align-items: center;">
                  <p style="font-size: 1em"><b>Practice:</b> {{ selectedPractice.name }}</p>
                  <p @click="changePractice()"
                    style="cursor: pointer; margin: 0; padding: 0; margin-left: 2em; border-bottom: 1px solid black; text-align: left; width: fit-content">
                    Change Practice</p>
                </div>
              </div>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <p style="text-align: left; font-size: 1em; cursor: pointer;" v-if="!selectedPractice && !displayPrescriberRegistration && $store.state.user.role.permissions.includes('can_do_admin_company')">
                Can't find the practice in the list? <span
                  @click="displayPrescriberRegistration = true, prescriberDetails.practice_id = ''"
                  style="color: #0870DC; font-weight: bold;">Register a new practice.</span>
              </p>
            </div>

            <div style="display: grid; grid-gap:1em; margin-top: 1em;"
              v-if="selectedPractice && !displayPrescriberRegistration">

              <p v-if="!selectedPrescriber"
                style='font-size: 1em; text-align: left; margin: 0; margin-bottom: 1em; font-weight: bold;'>Select the
                practice to provide access to.</p>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <div style="display: flex; align-items: center; margin-top: -1em">
                <div v-if="!selectedPrescriber" class="text-search-container search-container text-field"
                  style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                  <label for="first_name" style="text-align: left; padding-left: 10px">Search for prescriber</label>
                  <input v-model="prescriber" v-on:keyup="prescriberSearch(prescriber)"
                    placeholder="Enter Prescriber Branch/Facility Name"
                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                  <div class="drug-search-result-container" v-if="!selectedPrescriber"
                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                    <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                      v-for="(item, index) in prescriberResults" :key="index" @click="selectPrescriber(item)">
                      {{ item.regulatory_numbers.length ? item.regulatory_numbers[0].data : '' }} - {{ item.first_name }}
                      {{ item.last_name }}</p>
                  </div>
                </div>
                <div v-if="selectedPrescriber"
                  style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 50px; align-items: center;">
                  <p style="font-size: 1em"><b>Prescriber:</b> {{ selectedPrescriber.first_name }}
                    {{ selectedPrescriber.last_name }}</p>
                  <p @click="changePrescriber()"
                    style="cursor: pointer; margin: 0; padding: 0; margin-left: 2em; border-bottom: 1px solid black; text-align: left; width: fit-content">
                    Change Prescriber</p>
                </div>
              </div>
              <!-- ALGOLIA PRESCRIBER SEARCH -->
              <!-- ALGOLIA PRESCRIBER SEARCH -->
              <!-- ALGOLIA PRESCRIBER SEARCH -->
              <div v-if="selectedPrescriber || selectedFacility" class="form-field wide-text-field"
                style="width: 80%; margin:0">
                <label for="contact_name" style="padding-left:0px">Select Facility <span
                    style="color: red;">*</span></label>
                <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label" optionValue="value"
                  placeholder="Select Facility" style=" width: 26em" />
              </div>
              <p class="green-button" v-if="selectedPrescriber && selectedFacility" @click="linkPrescriber()"
                style="cursor:pointer; width: fit-content; margin: 1em 0; justify-self: flex-start; font-size: 1em; margin-right: 2em;">
                Link Facility</p>
            </div>
            <p style="text-align: left; font-size: 1em; cursor: pointer;"
              v-if="selectedPractice && !selectedPrescriber && !displayPrescriberRegistration && $store.state.user.role.permissions.includes('can_do_admin_prescribers')">Can't find the prescriber
              in the list? <span
                @click="displayPrescriberRegistration = true, prescriberDetails.practice_id = selectedPracticeID, prescriberDetails.practice_name = selectedPractice.name"
                style="color: #0870DC; font-weight: bold;">Register a new prescriber.</span></p>



            <!-- <div v-if="">
            <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
          </div> -->
            <div v-if="displayPrescriberRegistration"
              style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em;">
              <div>
                <div v-if="!selectedPracticeID" class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Practice Name <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="prescriberDetails.practice_name" placeholder="Enter Company Name">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Prescriber Number <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_prescribers')"
                    v-model="prescriberDetails.prescriber_number" placeholder="Enter Prescriber Number">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Practice State <span style="color: red;">*</span></label>
                  <Dropdown v-model="prescriberDetails.state_id" :options="states" optionValue="id" optionLabel="code"
                    :placeholder="'Select state'" style="margin-left: 5px" />
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Select Facility <span style="color: red;">*</span></label>
                  <Dropdown v-model="prescriberDetails.facility_id" :options="facilities" optionLabel="label"
                    optionValue="value" placeholder="Select Facility" style=" width: fit-content" />
                </div>
                <div v-if="!selectedPracticeID" class="form-field wide-text-field" style="width: 80%;">
                  <label for="address">Practice Email <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="prescriberDetails.contact_methods[0].data" placeholder="Enter Practice Email Address">
                </div>
                <br>
                <div v-if="!selectedPracticeID" class="form-field wide-text-field" style="width: 80%; margin-top: 0;">
                  <label for="first_name">Address: 
                    <span v-if="prescriberDetails.address.street" style="font-size: 0.9em">{{ prescriberDetails.address.street_address }}, {{ prescriberDetails.address.suburb }}, {{ prescriberDetails.address.country }}</span></label>
                  <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                  <div class="form-field text-field" style="width: 100%; margin-top: 0"
                    v-if="$store.state.user.role.permissions.includes('can_do_admin_prescribers')">
                    <vue-google-autocomplete id="contactmap" classname="form-control"
                      :placeholder="prescriberDetails.address.street ? prescriberDetails.address.street_address + ', ' + prescriberDetails.address.suburb + ', ' + prescriberDetails.address.country : '1 Apple Street, Melbourne, VIC 3000'"
                      v-on:placechanged="getPrescriberAddressData" :country="$store.state.country">
                    </vue-google-autocomplete>
                  </div>
                </div>
              </div>
              <div>
                <h3 style="text-align: left; margin: 0;">Enter a prescriber email<span style="color: red;">*</span></h3>
                <p style="margin: 0; text-align: left;">Enter a prescriber's email address to link to practice.</p>
                <InputText v-model="prescriberDetails.email" placeholder="Enter email address here"
                  style="background-color: #f3f4f4; margin: 10px 0; width: 20em; display: flex;" />
              </div>

            </div>
            <p class="green-button" v-if="displayPrescriberRegistration && !selectedPracticeID && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="createPrescriber()"
              style="cursor:pointer; width: fit-content; margin: 1em 0; justify-self: flex-end; font-size: 1em; margin-right: 2em;">
              Create Practice</p>
            <p class="green-button" v-if="displayPrescriberRegistration && selectedPracticeID && $store.state.user.role.permissions.includes('can_do_admin_prescribers')"
              @click="createPrescriberExistingPractice()"
              style="cursor:pointer; width: fit-content; margin: 1em 0; justify-self: flex-end; font-size: 1em; margin-right: 2em;">
              Create Prescriber</p>


          </div>
          

          <!-- CREATE PHARMACIST COMPANY -->
          <!-- CREATE PHARMACIST COMPANY -->
          <!-- CREATE PHARMACIST COMPANY -->
          <div
            v-if="subView == 'Register Pharmacy' && $store.state.user.role.permissions.includes('can_do_admin_company')"
            style="display: grid; grid-gap: 10px; ;">
            <div v-if="displayPharmacyRegistration">

            </div>
            <h4 style="text-align: left; margin: 0; margin-top: 1em;" v-if="!selectedPharmacyCompany">Enter Pharmacy
              Company Details </h4>
            <div v-if="!displayPharmacyRegistration">
              <h4 style="text-align: left;" v-if="!selectedPharmacyCompany">Search for pharmacy company to see if it
                already exists:</h4>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <div style="display: flex; align-items: center; margin-top: -1em">
                <div v-if="!selectedPharmacyCompany" class="text-search-container search-container text-field"
                  style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                  <label for="first_name" style="text-align: left; padding-left: 10px">Search for Pharmacy Company</label>
                  <input v-model="pharmacyCompany" v-on:keyup="pharmacyCompanySearch(pharmacyCompany)"
                    placeholder="Enter Pharmacy Company Name"
                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                  <div class="drug-search-result-container" v-if="!selectedPharmacyCompany"
                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                    <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                      v-for="(item, index) in pharmacyCompanyResults" :key="index" @click="selectPharmacyCompany(item)">
                      {{ item.name }}</p>
                  </div>
                </div>
                <div v-if="selectedPharmacyCompany"
                  style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 50px; margin-top: 1em; align-items: center;">
                  <p style="font-size: 1em"><b>Pharmacy Company:</b> {{ selectedPharmacyCompany.name }}</p>
                  <p @click="changePharmacyCompany()"
                    style="cursor: pointer; margin: 0; padding: 0; margin-left: 2em; border-bottom: 1px solid black; text-align: left; width: fit-content">
                    Change Pharmacy Company</p>
                </div>
              </div>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <p style="text-align: left; font-size: 1em; cursor: pointer;"
                v-if="!selectedPharmacyCompany && !displayPharmacyRegistration">Can't find the company in the list? <span
                  @click="displayPharmacyRegistration = true, pharmacyDetails.company_id = ''"
                  style="color: #0870DC; font-weight: bold;">Register a new company.</span></p>
            </div>

            <div style="display: grid; grid-gap:1em; margin-top: 1em;"
              v-if="selectedPharmacyCompany && !displayPharmacyRegistration">

              <p v-if="!selectedPharmacy"
                style='font-size: 1em; text-align: left; margin: 0; margin-bottom: 1em; font-weight: bold;'>Select the
                pharmacy's facility/branch to provide access to.</p>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <div style="display: flex; align-items: center; margin-top: -1em">
                <div v-if="!selectedPharmacy" class="text-search-container search-container text-field"
                  style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                  <label for="first_name" style="text-align: left; padding-left: 10px">Search for Pharmacy</label>
                  <input v-model="pharmacy" v-on:keyup="pharmacySearch(pharmacy)"
                    placeholder="Enter Pharmacy Branch/Facility Name"
                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                  <div class="drug-search-result-container" v-if="!selectedPharmacy"
                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                    <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                      v-for="(item, index) in pharmacyResults" :key="index" @click="selectPharmacy(item)">{{ item.name }}
                    </p>
                  </div>
                </div>
                <div v-if="selectedPharmacy"
                  style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em; margin-right: 50px; align-items: center;">
                  <p style="font-size: 1em"><b>Pharmacy Facility/Branch:</b> {{ selectedPharmacy.name }}</p>
                  <p @click="changePharmacy()"
                    style="cursor: pointer; margin: 0; padding: 0; margin-left: 2em; border-bottom: 1px solid black; text-align: left; width: fit-content">
                    Change Facility/Branch</p>
                </div>
              </div>
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <!-- ALGOLIA PHARMACY SEARCH -->
              <div v-if="selectedPharmacy || selectedFacility" class="form-field wide-text-field"
                style="width: 80%; margin:0">
                <label for="contact_name" style="padding-left:0px">Select Facility <span
                    style="color: red;">*</span></label>
                <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label" optionValue="value"
                  placeholder="Select Facility" style=" width: 26em" />
              </div>
              <p class="green-button" v-if="selectedPharmacy && selectedFacility" @click="linkPharmacy()"
                style="cursor:pointer; width: fit-content; margin: 1em 0; justify-self: flex-start; font-size: 1em; margin-right: 2em;">
                Link Facility</p>
            </div>
            <p style="text-align: left; font-size: 1em; cursor: pointer;"
              v-if="selectedPharmacyCompany && !selectedPharmacy && !displayPharmacyRegistration">Can't find the
              facility/branch in the list? <span
                @click="displayPharmacyRegistration = true, pharmacyDetails.company_id = selectedPharmacyCompanyID, pharmacyDetails.company_name = selectedPharmacyCompany.name"
                style="color: #0870DC; font-weight: bold;">Register a new branch/facility.</span></p>



            <!-- <div v-if="">
            <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
          </div> -->
            <div v-if="displayPharmacyRegistration" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em;">
              <div>
                <div v-if="!selectedPharmacyCompanyID" class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Company Name <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="pharmacyDetails.company_name" placeholder="Enter Company Name">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Branch Name (E.g. Green Pharmacy Brunswick) <span
                      style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="pharmacyDetails.pharmacy_name" placeholder="Enter Branch/Facility Name">
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Branch State <span style="color: red;">*</span></label>
                  <Dropdown v-model="pharmacyDetails.state_id" :options="states" optionValue="id" optionLabel="code"
                    :placeholder="'Select state'"/>
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Select Facility <span style="color: red;">*</span></label>
                  <Dropdown v-model="pharmacyDetails.facility_id" :options="facilities" optionLabel="label"
                    optionValue="value" placeholder="Select Facility" />
                </div>
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="address">Company Email <span style="color: red;">*</span></label>
                  <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
                    v-model="pharmacyDetails.contact_methods[0].data" placeholder="Enter Company Email Address">
                </div>
                <br>
                <div class="form-field wide-text-field" style="width: 80%; margin-top: 0;">
                  <label for="first_name">Address: <span v-if="pharmacyDetails.address.street" style="font-size: 0.9em">{{
                      pharmacyDetails.address.street_address
                  }},
                      {{ pharmacyDetails.address.suburb }}, {{ pharmacyDetails.address.country }}</span></label>
                  <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
                  <div class="form-field text-field" style="width: 100%; margin-top: 0"
                    v-if="$store.state.user.role.permissions.includes('can_do_admin_company')">
                    <vue-google-autocomplete id="contactmap" classname="form-control"
                      :placeholder="pharmacyDetails.address.street ? pharmacyDetails.address.street_address + ', ' + pharmacyDetails.address.suburb + ', ' + pharmacyDetails.address.country : '1 Apple Street, Melbourne, VIC 3000'"
                      v-on:placechanged="getPharmacyAddressData" :country="$store.state.country">
                    </vue-google-autocomplete>
                  </div>
                </div>
              </div>
              <div>
                <h3 style="text-align: left; margin: 0;">Enter pharmacy members <span style="color: red;">*</span></h3>
                <p style="margin: 0; text-align: left;">At least 1 member must be added to register pharmacy company.</p>
                <div v-if="pharmacyDetails.emails.length" style="display: flex; flex-direction: column;">
                  <div v-for="(item, index) in pharmacyDetails.emails" :key="index"
                    style="display: flex; align-items: center">
                    <InputText v-model="item.value" placeholder="Enter email address here"
                      style="background-color: #f3f4f4; margin: 10px 0; width: 20em;" />
                    <div class="p-field-checkbox">
                      <Checkbox id="admin-binary" v-model="item.isAdmin" @change="makePharmacyAdmin(index)" :binary="true"
                        style="margin-right: 5px;" />
                      <label for="admin-binary" style="margin-right: 10px;">Assign Admin</label>
                    </div>
                    <i @click="() => { pharmacyDetails.emails.splice(index, 1) }" v-if="!item.isAdmin"
                      style="cursor: pointer; font-size: 1.2em !important" class='pi pi-trash'></i>
                  </div>
                </div>
                <p class="green-button" style="width: fit-content; margin: 10px; margin-left: 0px;"
                  @click="() => { pharmacyDetails.emails.push({ value: '', isAdmin: false }) }">Add
                  additional member</p>
              </div>

            </div>
            <p class="green-button" v-if="displayPharmacyRegistration" @click="createPharmacy()"
              style="cursor:pointer; width: fit-content; margin: 1em 0; justify-self: flex-end; font-size: 1em; margin-right: 2em;">
              Create Pharmacy</p>

          </div>


          <!-- CREATE/EDIT/MOVE FACILITY AND LOCATION -->
          <!-- CREATE/EDIT/MOVE FACILITY AND LOCATION -->
          <!-- CREATE/EDIT/MOVE FACILITY AND LOCATION -->
          <div
            v-if="subView == 'Create/Edit Facilities & Locations' && $store.state.user.role.permissions.includes('can_view_admin_company')"
            style="display: grid; grid-gap: 10px; ;">
            <div>
              <p @click="displayFacilityForm = true" v-if="!displayFacilityForm && $store.state.user.role.permissions.includes('can_do_admin_company')" class="green-button button"
                style="width: fit-content">Create New Facility</p>
              <div style="display: grid" v-if="displayFacilityForm">
                <div style="display: flex; align-items: center">
                  <input type="text" v-model="newLocation" placeholder="Facility Name" style="margin-right: 5px;">
                  <Dropdown v-model="newLocState" :options="states" optionLabel="code" optionValue="id" placeholder="Select state"
                    style="margin-left: 5px" />

                  <div class="p-field-checkbox">
                    <Checkbox id="binary" v-model="editHPIO" :binary="true" style="margin-right: 5px;" />
                    <label for="binary" style="margin-right: 10px;">Has HPI-O</label>
                  </div>
                  <input v-if="editHPIO || hpi" type="text" v-model="hpi" placeholder="Enter HPI-O">
                </div>

                <div style="display:flex; align-items: flex-end;">
                  <div class="form-field wide-text-field" style="width: 80%">
                    <label for="first_name">Address: <span v-if="companyDetails.address" style="font-size: 0.9em">{{
                        companyDetails.address.street_address
                    }},
                        {{ companyDetails.address.suburb }}, {{ companyDetails.address.country }}</span></label>
                    <div class="form-field text-field" style="width: 100%; margin-top: 0">
                      <vue-google-autocomplete id="contactmap" classname="form-control"
                        :placeholder="companyDetails.address ? companyDetails.address.street_address + ', ' + companyDetails.address.suburb + ', ' + companyDetails.address.country : '1 Apple Street, Melbourne, VIC 3000'"
                        v-on:placechanged="getPersonalAddressData" :country="$store.state.country">
                      </vue-google-autocomplete>
                    </div>
                  </div>

                  <!-- FACILITY -->
                  <div class="form-field wide-text-field" style="width: 80%;">
                    <label for="phone_num">Phone Number <span style="color: red;">*</span></label>
                    <input type="text" v-model="facility_contact_methods.phone_number" placeholder="Enter Phone Number">
                  </div>

                  <div class="form-field wide-text-field" style="width: 80%;">
                    <label for="contact_name">Email <span style="color: red;">*</span></label>
                    <input type="text" v-model="facility_contact_methods.email" placeholder="Enter Email">
                  </div>

                  <div class="form-field wide-text-field" style="width: 80%;">
                    <label for="contact_name">Fax <span style="color: red;">*</span></label>
                    <input type="text" v-model="facility_contact_methods.fax" placeholder="Enter Fax">
                  </div>

                  <p v-if="newLocState && newLocation" class="green-button" style="cursor:pointer; display: inline;"
                    @click="submitFacility()">Add</p>
                  <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                    @click="closeFacilityForm()">Cancel</p>
                </div>
              </div>
            </div>
            <p style="font-size: 1em; text-align: left; margin: 0; margin-top: 1em;">Select Facility:</p>
            <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
              @change="retrieveSelectedFacilityLocations()"
              :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" style=" width: fit-content" />
            <p v-if="selectedFacility && !displayEditFacilityForm && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="displayEditFacilityForm = true"
              style="font-size: 1em; text-align: left; margin: 0; margin-top: 0px; border-bottom: 1px solid black; width: fit-content; cursor: pointer;">
              Edit Facility</p>
            <!--EDIT FACILITY FORM -->
            <!-- EDIT FACILITY FORM -->
            <div style="display: grid" v-if="displayEditFacilityForm">
              <div style="display: flex; align-items: center">

                <!-- <Dropdown  v-model="newLocState" :options="states" optionLabel="code" placeholder="Select state" style="margin-left: 5px" /> -->

                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="fac_name">Facility Name<span style="color: red;">*</span></label>
                  <input type="text" id="fac_name" v-model="selectedFacility.label" placeholder="Facility Name"
                    style="margin-right: 5px;">
                </div>

                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="fac_hpio">HPI-O <span style="color: red;">*</span></label>
                  <input type="text" id="fac_hpio" v-model="selectedFacility.hpi_o" placeholder="Enter HPI-O"
                    style="width: fit-content">
                </div>

              </div>

              <div style="display:flex; align-items: flex-end;">
                <!-- <div class="form-field wide-text-field" style="width: 80%">
                    <label for="first_name">Address: <span v-if="companyDetails.address" style="font-size: 0.9em">{{companyDetails.address.street_address}}, {{companyDetails.address.suburb}}, {{companyDetails.address.country}}</span></label>
                    <div class="form-field text-field" style="width: 100%; margin-top: 0">
                        <vue-google-autocomplete
                        id="contactmap"
                        classname="form-control"
                        :placeholder="companyDetails.address ? companyDetails.address.street_address+', '+companyDetails.address.suburb+', '+companyDetails.address.country:'1 Apple Street, Melbourne, VIC 3000'"
                        v-on:placechanged="getPersonalAddressData"
                        :country="$store.state.country"
                        >
                        </vue-google-autocomplete>
                    </div>
              </div> -->

                <!-- FACILITY -->
                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="phone_num">Phone Number <span style="color: red;">*</span></label>
                  <input type="text" v-model="selectedFacility.phone" placeholder="Enter Phone Number">
                </div>

                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Email <span style="color: red;">*</span></label>
                  <input type="text" v-model="selectedFacility.email" placeholder="Enter Email">
                </div>

                <div class="form-field wide-text-field" style="width: 80%;">
                  <label for="contact_name">Fax <span style="color: red;">*</span></label>
                  <input type="text" v-model="selectedFacility.fax" placeholder="Enter Fax">
                </div>

                <p v-if="selectedFacility.label" class="blue-button" style="cursor:pointer; display: inline;"
                  @click="editFacility()">Save</p>
                <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                  @click="displayEditFacilityForm = false">Cancel</p>
              </div>
            </div>
            <!-- END EDIT FACILITY FORM -->
            <!-- END EDIT FACILITY FORM -->
            <div>
              <i v-if="!locationDirectory" class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; "></i>
              <h3 v-else style="text-align: left; margin: 0; margin-top: 1em;">Facility Locations</h3>
              <table v-if="locationDirectory" class="location-tree" style="border-radius: 12px;">
                <tbody v-for="(cluster, clusteri) in locationDirectory" :key="clusteri">
                  <tr>
                    <td style="display: flex; align-items: center">
                      <p v-if="!cluster.editName">{{ cluster.name }}</p>
                      
                      <input v-else type="text" v-model="cluster.editNameValue" :placeholder="cluster.name">
                      <p v-if="cluster.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                      <!-- LOCATION STATE -->
                      <p v-if="!cluster.editName" class="outline-button"
                        style='margin-left: 10px; border: 1px solid black;'> {{ cluster.state ? cluster.state : 'No \
                        State'}}</p>


                      <Dropdown v-else v-model="cluster.state_id" :options="states" optionLabel="code"
                        :placeholder="cluster.state ? cluster.state : 'Select state'" style="margin-left: 5px" />

                        <Dropdown v-if="cluster.editName" v-model="cluster.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="cluster.location_type ? cluster.location_type : 'Location Type'"/>
                      <i  v-if="!cluster.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                        @click="cluster.editName = true, cluster.editNameValue = '', checkDrugReg(cluster)"></i>
                      <div class="p-field-checkbox" v-if="cluster.editName">
                        <Checkbox id="binary" v-model="cluster.has_drug_register" :binary="true"
                          style="margin-right: 5px;" />
                        <label for="binary" style="margin-right: 10px;">Drug Register</label>
                      </div>
                      <!-- <div class="p-field-checkbox" >
                      <Checkbox id="binary-hpi" v-model="cluster.editHPIO" :binary="true" style="margin-right: 5px;" @click="checkHPIO(cluster)" />
                      <label for="binary-hpi" style="margin-right: 10px;">Has HPI-O</label>
                  </div> -->
                      <!-- <input v-if="cluster.editHPIO || cluster.hpi" type="text" v-model="cluster.hpi" placeholder="Enter HPI-O"> -->
                      <i  v-if="cluster.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                        @click="cluster.editName = false, editLocation(cluster)"></i>
                      <i  v-if="cluster.editName" class="pi pi-times"
                        @click="cluster.editName = false, cluster.editNameValue = ''"></i>
                      <i class="pi pi-copy pulse-fade" 
                        @click="() => { copyLocationID(cluster.key) }"></i>
                      <i  v-if="$store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-plus-circle"
                        @click="openLocationEntryForm(cluster.key)"></i>
                    </td>
                  </tr>
                  <!-- NEW PARENT LOC -->
                <tbody :id="cluster.key + '-new-entry'" class="location-new-entry">
                  <tr>
                    <!-- FACILITY OR LOCATION CHOICE -->
                    <!-- <td class="new-loc-cell" v-if="!facility_or_location"><i class="pi pi-reply"
                          style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 30px; padding-right: 30px;"></i>
                          <p class="outline-button" style="margin-right: 10px; font-size: 1em;" @click="facility_or_location = 'facility'">Create facility</p>
                          <p class="outline-button" style="font-size: 1em;" @click="facility_or_location = 'location'">Create location</p>
                          </td> -->

                    <!-- FACILITY CREATE -->
                    <!-- <td class="new-loc-cell" v-if="facility_or_location == 'facility'"><i class="pi pi-reply"
                          style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 30px; padding-right: 30px;"></i>
                          <div style="display: grid">
                            <div style="display: flex; align-items: center">
                              <input type="text" v-model="newLocation" placeholder="Facility Name" style="margin-right: 5px;">
                                <Dropdown  v-model="newLocState" :options="states" optionLabel="code" placeholder="Select state" style="margin-left: 5px" />

                                <div class="p-field-checkbox" >
                                    <Checkbox id="binary" v-model="editHPIO" :binary="true" style="margin-right: 5px;" />
                                    <label for="binary" style="margin-right: 10px;">Has HPI-O</label>
                                </div>
                                <input v-if="editHPIO || hpi" type="text" v-model="hpi" placeholder="Enter HPI-O">
                            </div>

                            <div style="display:flex; align-items: flex-end;">
                              <div class="form-field wide-text-field" style="width: 80%">
                                    <label for="first_name">Address: <span v-if="companyDetails.address" style="font-size: 0.9em">{{companyDetails.address.street_address}}, {{companyDetails.address.suburb}}, {{companyDetails.address.country}}</span></label>
                                    <div class="form-field text-field" style="width: 100%; margin-top: 0">
                                        <vue-google-autocomplete
                                        id="contactmap"
                                        classname="form-control"
                                        :placeholder="companyDetails.address ? companyDetails.address.street_address+', '+companyDetails.address.suburb+', '+companyDetails.address.country:'1 Apple Street, Melbourne, VIC 3000'"
                                        v-on:placechanged="getPersonalAddressData"
                                        :country="$store.state.country"
                                        >
                                        </vue-google-autocomplete>
                                    </div>
                              </div>


                              <div class="form-field wide-text-field" style="width: 80%;">
                                  <label for="phone_num">Phone Number <span style="color: red;">*</span></label>
                                  <input type="text" v-model="facility_contact_methods.phone_number" placeholder="Enter Phone Number">
                              </div>

                              <div class="form-field wide-text-field" style="width: 80%;">
                                  <label for="contact_name">Email <span style="color: red;">*</span></label>
                                  <input type="text" v-model="facility_contact_methods.email" placeholder="Enter Email">
                              </div>

                              <div class="form-field wide-text-field" style="width: 80%;">
                                  <label for="contact_name">Fax <span style="color: red;">*</span></label>
                                  <input type="text" v-model="facility_contact_methods.fax" placeholder="Enter Fax">
                              </div>

                              <p v-if="newLocState && newLocation" class="blue-button" style="cursor:pointer; display: inline;" @click="submitFacility(cluster.key)">Add</p>
                              <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;" @click="closeLocationEntryForm(cluster.key)">Cancel</p>
                            </div>
                          </div>



                          </td> -->

                    <!-- END FACILITY CREATE -->

                    <!-- LOCATION CREATE -->
                    <!-- LOCATION CREATE -->
                    <!-- class="new-loc-cell" v-if="facility_or_location == 'location'" -->
                    <td><i class="pi pi-reply"
                        style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 30px; padding-right: 30px;"></i>
                      <input type="text" v-model="newLocation" placeholder="Location Name" style="margin-right: 5px;">
                      <!-- NEW LOC STATE -->
                      <Dropdown v-model="newLocState" :options="states" optionLabel="code" optionValue="id" placeholder="Select state"
                        style="margin-left: 5px" />
                        <Dropdown v-model="locationType" :options="compLocationTypes" optionLabel="name" optionValue="name" placeholder="Location Type"/>
                      <div class="p-field-checkbox">
                        <Checkbox id="binary" v-model="has_drug_register" :binary="true" style="margin-right: 5px;" />
                        <label for="binary" style="margin-right: 10px;">Drug Register</label>
                      </div>
                      <!-- <div class="p-field-checkbox" >
                                <Checkbox id="binary" v-model="editHPIO" :binary="true" style="margin-right: 5px;" />
                                <label for="binary" style="margin-right: 10px;">Has HPI-O</label>
                            </div> -->
                      <!-- <input v-if="editHPIO || hpi" type="text" v-model="hpi" placeholder="Enter HPI-O"> -->
                      <p v-if="newLocState && newLocation" class="green-button" style="cursor:pointer; display: inline;"
                        @click="submitLocation(cluster.key)">Add</p>
                      <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                        @click="closeLocationEntryForm(cluster.key)">Cancel</p>
                    </td>
                  </tr>
                </tbody>
                <!-- END NEW PARENT LOC -->
                <tr v-for="(item, index) in cluster.children" :key="index">
                  <td style="border-top: 2px solid #e2e2e2; padding-top: 20px; ">
                    <div style="display: flex; align-items: center">
                      <i class="pi-chevron-right pi" v-if="!item.expand" @click="item.expand = true"></i>
                      <i class="pi-chevron-down pi" v-if="item.expand" @click="item.expand = false"></i>
                      <i class="pi pi-reply"
                        style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 30px; padding-right: 30px;"></i>
                      <p class="location-name" v-if="!item.editName">{{ item.name }}</p>
                      <input v-else type="text" v-model="item.editNameValue" :placeholder="item.name">
                      <p v-if="item.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                      <!-- LOCATION STATE -->
                      <p v-if="!item.editName" class="outline-button" style='margin-left: 10px; border: 1px solid black;'>
                        {{ item.state ? item.state : 'No State' }}</p>
                      <Dropdown v-else v-model="item.state_id" :options="states" optionLabel="code"
                        :placeholder="item.state ? item.state : 'Select state'" style="margin-left: 5px" />

                      <Dropdown v-if="item.editName" v-model="item.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="item.location_type ? item.location_type : 'Location Type'"/>
                        
                      <i  v-if="!item.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                        @click="item.editName = true, item.editNameValue = ''"></i>
                      <div class="p-field-checkbox" v-if="item.editName">
                        <Checkbox id="binary" v-model="item.has_drug_register" :binary="true"
                          style="margin-right: 5px;" />
                        <label for="binary" style="margin-right: 10px;">Drug Register</label>
                      </div>
                      <i  v-if="item.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                        @click="item.editName = false, editLocation(item)"></i>
                      <i  v-if="item.editName" class="pi pi-times"
                        @click="item.editName = false, item.editNameValue = ''"></i>
                      <!-- <div class="p-field-checkbox" v-if="item.editHPIO">
                          <Checkbox id="binary" v-model="item.editHPIO" :binary="true" style="margin-right: 5px;" />
                          <label for="binary" style="margin-right: 10px;">Has HPI-O</label>
                      </div> -->
                      <i class="pi pi-copy pulse-fade" 
                        @click="() => { copyLocationID(item.key) }"></i>
                      <i  v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                        @click="openLocationEntryForm(item.key)" class="pi pi-plus-circle"></i>
                      <i  v-if="!item.move && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="item.move = true"
                        class="pi pi-sort-alt"></i>
                    </div>
                    <div class="location-entry">
                      <!-- MOVE LOCATION -->
                      <div style="display: flex; align-items: center; padding-left: 30px">
                        <input v-if="item.move" type="text" v-model="moveForm.destination" style="width: 15em;"
                          placeholder="Enter new parent location ID">
                        <p class="blue-button" v-if="item.move" @click="moveLocation(item.key), item.move = false"
                          style="margin-left: 10px">Move</p>
                        <p class="grey-button" v-if="item.move" @click="item.move = false, moveForm.destination = ''"
                          style="margin-left: 10px">Cancel</p>
                      </div>
                      <p v-if="item.move" style="margin-left: 10px; padding-left: 30px; color: red">
                        {{ movingWarning }}
                      </p>
                      <!-- MOVE LOCATION -->

                      <!-- NEW LOC -->
                      <tbody :id="item.key + '-new-entry'" class="location-new-entry">
                        <tr>
                          <td class="new-loc-cell">
                            <i class="pi pi-reply"
                              style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                            <input type="text" v-model="newLocation" placeholder="Location Name"
                              style="margin-right: 5px;">
                            <!-- NEW LOC STATE -->
                            <Dropdown v-model="newLocState" :options="states" optionLabel="code" optionValue="id"
                              placeholder="Select state" style="margin-left: 5px" />
                              <Dropdown v-model="locationType" :options="compLocationTypes" optionLabel="name" optionValue="name" placeholder="Location Type"/>
                            <div class="p-field-checkbox">
                              <Checkbox id="binary" v-model="has_drug_register" :binary="true"
                                style="margin-right: 5px;" />
                              <label for="binary" style="margin-right: 10px;">Drug Register</label>
                            </div>
                            <p v-if="newLocState && newLocation" class="green-button"
                              style="cursor:pointer; display: inline;" @click="submitLocation(item.key)">Add</p>
                            <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                              @click="closeLocationEntryForm(item.key)">Cancel</p>
                          </td>
                        </tr>
                      </tbody>
                      <!-- END NEW LOC -->
                      <div v-if="item.expand">
                        <tbody v-for="(a, aA) in item.children" :key="aA">
                          <tr>
                            <td>
                              <div style="display: flex; align-items: center">
                                <i class="pi pi-reply"
                                  style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                <p class="location-name" v-if="!a.editName">{{ a.name }}</p>
                                <input v-else type="text" v-model="a.editNameValue" :placeholder="a.name">
                                <p v-if="a.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                                <!-- LOCATION STATE -->
                                <p v-if="!a.editName" class="outline-button"
                                  style='margin-left: 10px; border: 1px solid black;'> {{ a.state ? a.state : 'No State'
                                  }}
                                </p>
                                <Dropdown v-else v-model="a.state_id" :options="states" optionLabel="code"
                                  :placeholder="a.state ? a.state : 'Select state'" style="margin-left: 5px" />

                                  <Dropdown v-if="a.editName" v-model="a.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="a.location_type ? a.location_type : 'Location Type'"/>
                                <i  v-if="!a.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                                  @click="a.editName = true, a.editNameValue = ''"></i>
                                <div class="p-field-checkbox" v-if="a.editName">
                                  <Checkbox id="binary" v-model="a.has_drug_register" :binary="true"
                                    style="margin-right: 5px;" />
                                  <label for="binary" style="margin-right: 10px;">Drug Register</label>
                                </div>
                                <i  v-if="a.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                                  @click="a.editName = false, editLocation(a)"></i>
                                <i  v-if="a.editName" class="pi pi-times"
                                  @click="a.editName = false, a.editNameValue = ''"></i>
                                <i class="pi pi-copy pulse-fade" 
                                  @click="() => { copyLocationID(a.key) }"></i>
                                <i  v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                                  @click="openLocationEntryForm(a.key)" class="pi pi-plus-circle"></i>
                                <i  v-if="!a.move && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="a.move = true"
                                  class="pi pi-sort-alt"></i>
                              </div>
                              <div class="location-entry">
                                <!-- MOVE LOCATION -->
                                <div style="display: flex; align-items: center; padding-left: 30px">
                                  <input v-if="a.move" type="text" v-model="moveForm.destination" style="width: 15em;"
                                    placeholder="Enter new parent location ID">
                                  <p class="blue-button" v-if="a.move" @click="moveLocation(a.key), a.move = false"
                                    style="margin-left: 10px">Move</p>
                                  <p class="grey-button" v-if="a.move" @click="a.move = false, moveForm.destination = ''"
                                    style="margin-left: 10px">Cancel</p>
                                </div>
                                <p v-if="a.move" style="margin-left: 10px; padding-left: 30px; color: red">
                                  {{ movingWarning }}</p>
                                <!-- MOVE LOCATION -->
                                <!-- NEW aA LOC -->
                        <tbody :id="a.key + '-new-entry'" class="location-new-entry">
                          <tr>
                            <td class="new-loc-cell"><i class="pi pi-reply"
                                style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                              <input type="text" v-model="newLocation" placeholder="Location Name"
                                style="margin-right: 5px;">
                              <!-- NEW LOC STATE -->
                              <Dropdown v-model="newLocState" :options="states" optionLabel="code" optionValue="id"
                                placeholder="Select state" style="margin-left: 5px" />
                                <Dropdown v-model="locationType" :options="compLocationTypes" optionLabel="name"  optionValue="name" placeholder="Location Type"/>
                              <div class="p-field-checkbox">
                                <Checkbox id="binary" v-model="has_drug_register" :binary="true"
                                  style="margin-right: 5px;" />
                                <label for="binary" style="margin-right: 10px;">Drug Register</label>
                              </div>
                              <p v-if="newLocState && newLocation" class="green-button"
                                style="cursor:pointer; display: inline;" @click="submitLocation(a.key)">Add</p>
                              <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                                @click="closeLocationEntryForm(a.key)">Cancel</p>
                            </td>
                          </tr>
                        </tbody>
                        <!-- END NEW aA LOC -->
                        <tbody v-for="(b, bB) in a.children" :key="bB">
                          <tr>
                            <td>
                              <div style="display: flex; align-items: center">
                                <i class="pi pi-reply"
                                  style="align-items: center; transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                <p class="location-name" v-if="!b.editName">{{ b.name }}</p>
                                <input v-else type="text" v-model="b.editNameValue" :placeholder="b.name">
                                <p v-if="b.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                                <!-- LOCATION STATE -->
                                <p v-if="!b.editName" class="outline-button" style='margin-left: 10px; border: 1px solid black;'>
                                  {{ b.state ? b.state : 'No State' }}
                                </p>
                                <Dropdown v-else v-model="b.state_id" :options="states" optionLabel="code"
                                  :placeholder="b.state ? b.state : 'Select state'" style="margin-left: 5px" />

                                  <Dropdown v-if="b.editName" v-model="b.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="b.location_type ? b.location_type : 'Location Type'"/>
                                <i  v-if="!b.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                                  @click="b.editName = true, b.editNameValue = ''"></i>
                                <div class="p-field-checkbox" v-if="b.editName">
                                  <Checkbox id="binary" v-model="b.has_drug_register" :binary="true"
                                    style="margin-right: 5px;" />
                                  <label for="binary" style="margin-right: 10px;">Drug Register</label>
                                </div>
                                <i  v-if="b.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                                  @click="b.editName = false, editLocation(b)"></i>
                                <i  v-if="b.editName" class="pi pi-times"
                                  @click="b.editName = false, b.editNameValue = ''"></i>
                                <i class="pi pi-copy pulse-fade" 
                                  @click="() => { copyLocationID(b.key) }"></i>
                                <i @click="openLocationEntryForm(b.key)"  v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                                  class="pi pi-plus-circle"></i>
                                <i  v-if="!b.move && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="b.move = true"
                                  class="pi pi-sort-alt"></i>
                              </div>
                              <div class="location-entry">
                                <!-- MOVE LOCATION -->
                                <div style="display: flex; align-items: center; padding-left: 30px">
                                  <input v-if="b.move" type="text" v-model="moveForm.destination" style="width: 15em;"
                                    placeholder="Enter new parent location ID">
                                  <p class="blue-button" v-if="b.move" @click="moveLocation(b.key), b.move = false"
                                    style="margin-left: 10px">Move</p>
                                  <p class="grey-button" v-if="b.move" @click="b.move = false, moveForm.destination = ''"
                                    style="margin-left: 10px">Cancel</p>
                                </div>
                                <p v-if="b.move" style="margin-left: 10px; padding-left: 30px; color: red">
                                  {{ movingWarning }}</p>
                                <!-- MOVE LOCATION -->
                                <!-- NEW bB LOC -->
                        <tbody :id="b.key + '-new-entry'" class="location-new-entry">
                          <tr>
                            <td class="new-loc-cell"><i class="pi pi-reply"
                                style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                              <input type="text" v-model="newLocation" placeholder="Location Name"
                                style="margin-right: 5px;">
                              <!-- NEW LOC STATE -->
                              <Dropdown v-model="newLocState" :options="states" optionLabel="code"
                                placeholder="Select state" optionValue="id" style="margin-left: 5px" />
                                <Dropdown v-model="locationType" :options="compLocationTypes" optionLabel="name" optionValue="name" placeholder="Location Type"/>
                              <div class="p-field-checkbox">
                                <Checkbox id="binary" v-model="has_drug_register" :binary="true"
                                  style="margin-right: 5px;" />
                                <label for="binary" style="margin-right: 10px;">Drug Register</label>
                              </div>
                              <p v-if="newLocState && newLocation" class="green-button"
                                style="cursor:pointer; display: inline;" @click="submitLocation(b.key)">Add</p>
                              <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                                @click="closeLocationEntryForm(b.key)">Cancel</p>
                            </td>
                          </tr>
                        </tbody>
                        <!-- END NEW bB LOC -->
                        <tbody v-for="(c, cC) in b.children" :key="cC">
                          <tr>
                            <td>
                              <div style="display: flex; align-items: center">
                                <i class="pi pi-reply"
                                  style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                <p class="location-name" v-if="!c.editName">
                                  {{ c.name }}
                                </p>
                                <input v-else type="text" v-model="c.editNameValue" :placeholder="c.name">
                                <p v-if="c.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                                <!-- LOCATION STATE -->
                                <p v-if="!c.editName" class="outline-button"
                                  style='margin-left: 10px; border: 1px solid black;'> {{ c.state ? c.state : 'No State'
                                  }}
                                </p>
                                <Dropdown v-else v-model="c.state_id" :options="states" optionLabel="code"
                                  :placeholder="c.state ? c.state : 'Select state'" style="margin-left: 5px" />

                                  <Dropdown v-if="c.editName" v-model="c.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="c.location_type ? c.location_type : 'Location Type'"/>  
                                <i  v-if="!c.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                                  @click="c.editName = true, c.editNameValue = ''"></i>
                                <div class="p-field-checkbox" v-if="c.editName">
                                  <Checkbox id="binary" v-model="c.has_drug_register" :binary="true"
                                    style="margin-right: 5px;" />
                                  <label for="binary" style="margin-right: 10px;">Drug Register</label>
                                </div>
                                <i  v-if="c.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                                  @click="c.editName = false, editLocation(c)"></i>
                                <i  v-if="c.editName" class="pi pi-times"
                                  @click="c.editName = false, c.editNameValue = ''"></i>
                                <i class="pi pi-copy pulse-fade" 
                                  @click="() => { copyLocationID(c.key) }"></i>
                                <i @click="openLocationEntryForm(c.key)"  v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                                  class="pi pi-plus-circle"></i>
                                <i  v-if="!c.move && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="c.move = true"
                                  class="pi pi-sort-alt"></i>
                              </div>
                              <div class="location-entry">
                                <!-- MOVE LOCATION -->
                                <div style="display: flex; align-items: center; padding-left: 30px">
                                  <input v-if="c.move" type="text" v-model="moveForm.destination" style="width: 15em;"
                                    placeholder="Enter new parent location ID">
                                  <p class="blue-button" v-if="c.move" @click="moveLocation(c.key), c.move = false"
                                    style="margin-left: 10px">Move</p>
                                  <p class="grey-button" v-if="c.move" @click="c.move = false, moveForm.destination = ''"
                                    style="margin-left: 10px">Cancel</p>
                                </div>
                                <p v-if="c.move" style="margin-left: 10px; padding-left: 30px; color: red">
                                  {{ movingWarning }}</p>
                                <!-- MOVE LOCATION -->
                                <!-- NEW cC LOC -->
                        <tbody :id="c.key + '-new-entry'" class="location-new-entry">
                          <tr>
                            <td class="new-loc-cell"><i class="pi pi-reply"
                                style="align-items: center; transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                              <input type="text" v-model="newLocation" placeholder="Location Name"
                                style="margin-right: 5px;">
                              <!-- NEW LOC STATE -->
                              <Dropdown v-model="newLocState" optionValue="id" :options="states" optionLabel="code"
                                placeholder="Select state" style="margin-left: 5px" />
                              <Dropdown v-model="locationType" :options="compLocationTypes" optionLabel="name" optionValue="name" placeholder="Location Type"/>
                              <div class="p-field-checkbox">
                                <Checkbox id="binary" v-model="has_drug_register" :binary="true"
                                  style="margin-right: 5px;" />
                                <label for="binary" style="margin-right: 10px;">Drug Register</label>
                              </div>
                              <p v-if="newLocState && newLocation" class="green-button"
                                style="cursor:pointer; display: inline;" @click="submitLocation(c.key)">Add</p>
                              <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                                @click="closeLocationEntryForm(c.key)">Cancel</p>
                            </td>
                          </tr>
                        </tbody>
                        <!-- END NEW cC LOC -->
                        <tbody v-for="(d, dD) in c.children" :key="dD">
                          <tr>
                            <td>
                              <div style="display: flex; align-items: center">
                                <i class="pi pi-reply"
                                  style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                <p class="location-name" v-if="!d.editName">
                                  {{ d.name }}
                                </p>
                                <input v-else type="text" v-model="d.editNameValue" :placeholder="d.name">
                                <p v-if="d.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                                <!-- LOCATION STATE -->
                                <p v-if="!d.editName" class="outline-button"
                                  style='margin-left: 10px; border: 1px solid black;'> {{ d.state ? d.state : 'No State'
                                  }}
                                </p>
                                <Dropdown v-else v-model="d.state_id" :options="states" optionLabel="code"
                                  :placeholder="d.state ? d.state : 'Select state'" style="margin-left: 5px" />

                                <Dropdown v-if="d.editName" v-model="d.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="d.location_type ? d.location_type : 'Location Type'"/>
                                <i  v-if="!d.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                                  @click="d.editName = true, d.editNameValue = ''"></i>
                                <div class="p-field-checkbox" v-if="d.editName">
                                  <Checkbox id="binary" v-model="d.has_drug_register" :binary="true"
                                    style="margin-right: 5px;" />
                                  <label for="binary" style="margin-right: 10px;">Drug Register</label>
                                </div>
                                <i  v-if="d.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                                  @click="d.editName = false, editLocation(d)"></i>
                                <i  v-if="d.editName" class="pi pi-times"
                                  @click="d.editName = false, d.editNameValue = ''"></i>
                                <i class="pi pi-copy pulse-fade" 
                                  @click="() => { copyLocationID(d.key) }"></i>
                                <i @click="openLocationEntryForm(d.key)"  v-if="$store.state.user.role.permissions.includes('can_do_admin_company')"
                                  class="pi pi-plus-circle"></i>
                                <i  v-if="!d.move && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="d.move = true"
                                  class="pi pi-sort-alt"></i>
                              </div>
                              <div class="location-entry">
                                <!-- MOVE LOCATION -->
                                <div style="display: flex; align-items: center; padding-left: 30px">
                                  <input v-if="d.move" type="text" v-model="moveForm.destination" style="width: 15em;"
                                    placeholder="Enter new parent location ID">
                                  <p class="blue-button" v-if="d.move" @click="moveLocation(d.key), d.move = false"
                                    style="margin-left: 10px">Move</p>
                                  <p class="grey-button" v-if="d.move" @click="d.move = false, moveForm.destination = ''"
                                    style="margin-left: 10px">Cancel</p>
                                </div>
                                <p v-if="d.move" style="margin-left: 10px; padding-left: 30px; color: red">
                                  {{ movingWarning }}</p>
                                <!-- MOVE LOCATION -->
                                <!-- NEW dD LOC -->
                        <tbody :id="d.key + '-new-entry'" class="location-new-entry">
                          <tr>
                            <td class="new-loc-cell"><i class="pi pi-reply"
                                style="align-items: center; transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                              <input type="text" v-model="newLocation" placeholder="Location Name"
                                style="margin-right: 5px;">
                              <!-- NEW LOC STATE -->
                              <Dropdown v-model="newLocState" optionValue="id" :options="states" optionLabel="code"
                                placeholder="Select state" style="margin-left: 5px" />
                              <Dropdown v-model="locationType" :options="compLocationTypes" optionLabel="name" optionValue="name" placeholder="Location Type"/>
                              <div class="p-field-checkbox">
                                <Checkbox id="binary" v-model="has_drug_register" :binary="true"
                                  style="margin-right: 5px;" />
                                <label for="binary" style="margin-right: 10px;">Drug Register</label>
                              </div>
                              <p v-if="newLocState && newLocation" class="green-button"
                                style="cursor:pointer; display: inline;" @click="submitLocation(d.key)">Add</p>
                              <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                                @click="closeLocationEntryForm(d.key)">Cancel</p>
                            </td>
                          </tr>
                        </tbody>
                        <!-- END NEW dD LOC -->
                        <tbody v-for="(e, eE) in d.children" :key="eE">
                          <tr>
                            <td>
                              <div style="display: flex; align-items: center">
                                <i class="pi pi-reply"
                                  style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 50px;"></i>
                                <p class="location-name" v-if="!e.editName">{{ e.name }}</p>
                                <input v-else type="text" v-model="e.editNameValue" :placeholder="e.name">
                                <p v-if="e.has_drug_register" class="blue-button" style="margin-left: 10px; font-weight: bold; cursor: inherit; border-radius: 20px; background-color: #9F5CC0 !important">Drug Reg</p>
                                <!-- LOCATION STATE -->
                                <p v-if="!e.editName" class="outline-button"
                                  style='margin-left: 10px; border: 1px solid black;'> {{ e.state ? e.state : 'No State'
                                  }}
                                </p>
                                <Dropdown v-else v-model="e.state_id" :options="states" optionLabel="code"
                                  :placeholder="e.state ? e.state : 'Select state'" style="margin-left: 5px" />

                                  <Dropdown v-if="e.editName" v-model="e.location_type" :options="compLocationTypes" style="margin-left: 5px" optionLabel="name" optionValue="name" :placeholder="e.location_type ? e.location_type : 'Location Type'"/>
                                <i  v-if="!e.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-pencil"
                                  @click="e.editName = true, e.editNameValue = ''"></i>
                                <div class="p-field-checkbox" v-if="e.editName">
                                  <Checkbox id="binary" v-model="e.has_drug_register" :binary="true"
                                    style="margin-right: 5px;" />
                                  <label for="binary" style="margin-right: 10px;">Stock Register</label>
                                </div>
                                <i  v-if="e.editName && $store.state.user.role.permissions.includes('can_do_admin_company')" class="pi pi-save"
                                  @click="e.editName = false, editLocation(e)"></i>
                                <i  v-if="e.editName" class="pi pi-times"
                                  @click="e.editName = false, e.editNameValue = ''"></i>
                                <i class="pi pi-copy pulse-fade" 
                                  @click="() => { copyLocationID(e.key) }"></i>
                                <i  v-if="!e.move && $store.state.user.role.permissions.includes('can_do_admin_company')" @click="e.move = true"
                                  class="pi pi-sort-alt"></i>
                              </div>
                              <div class="location-entry">
                                <!-- MOVE LOCATION -->
                                <div style="display: flex; align-items: center; padding-left: 30px">
                                  <input v-if="e.move" type="text" v-model="moveForm.destination" style="width: 15em;"
                                    placeholder="Enter new parent location ID">
                                  <p class="blue-button" v-if="e.move" @click="moveLocation(e.key), e.move = false"
                                    style="margin-left: 10px">Move</p>
                                  <p class="grey-button" v-if="e.move" @click="e.move = false, moveForm.destination = ''"
                                    style="margin-left: 10px">Cancel</p>
                                </div>
                                <!-- MOVE LOCATION -->
                                <p v-if="e.move" style="margin-left: 10px; padding-left: 30px; color: red">
                                  {{ movingWarning }}</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                  </td>
                </tr>
                </tbody>
            </div>
            </td>
            </tr>
            </tbody>
          </div>
          </td>
          </tr>
          </tbody>
        </div>
        </td>
        </tr>
        </tbody>
      </div>
    </div>

    </td>

    </tr>
    </tbody>
    </table>
    <!-- v-if="locationDirectory && !locationDirectory.length" -->
    <div>
      <h2 v-if="locationDirectory && !locationDirectory.length" style="color: grey; text-align: left;">No locations listed
        under this facility.</h2>
      <div v-if="!createParentLocation && $store.state.user.role.permissions.includes('can_do_admin_company')">
        <p class="green-button" style="width: fit-content;" @click="createParentLocation = true">Create Parent Location</p>
      </div>
      <table v-if="createParentLocation">
        <tbody>
          <tr>
            <td class="new-loc-cell">
              <input type="text" v-model="newLocation" placeholder="Location Name" style="margin-right: 5px;">
              <!-- NEW LOC STATE -->
              <Dropdown v-model="newLocState" optionValue="id" :options="states" optionLabel="code" placeholder="Select state"
                style="margin-left: 5px" />
              <Dropdown  v-model="locationType" :options="compLocationTypes" optionLabel="name" optionValue="name" placeholder="Location Type"/>
              <div class="p-field-checkbox">
                <Checkbox id="binary" v-model="has_drug_register" :binary="true" style="margin-right: 5px;" />
                <label for="binary" style="margin-right: 10px;">Drug Register</label>
              </div>
              <p v-if="newLocState && newLocation" class="green-button" style="cursor:pointer; display: inline;"
                @click="submitLocation()">Add</p>
              <p class="grey-button" style="cursor:pointer; display: inline; margin-left: 10px;"
                @click="createParentLocation = false">Cancel</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    </div>

    <!-- COMPANY DETAILS -->
    <!-- COMPANY DETAILS -->
    <!-- COMPANY DETAILS -->
    <div v-if="subView == 'Company Details' && $store.state.user.role.permissions.includes('can_view_admin_company')"
      style="display: grid; grid-gap: 10px; ;">
      <h3 v-if="!loadingCompanyDetails" style="text-align: left;">{{ companyDetails.name }} </h3>
      <div v-if="loadingCompanyDetails">
        <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
      </div>
      <div v-else style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1em;">
        <div class="form-field wide-text-field" style="width: 80%;">
          <label for="contact_name">Contact Name <span style="color: red;">*</span></label>
          <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
            v-model="companyDetails.contact" placeholder="Enter Contact Name">
        </div>
        <div class="form-field wide-text-field" style="width: 80%;">
          <label for="phone_num">Company Phone Number <span style="color: red;">*</span></label>
          <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
            v-model="companyDetails.phone_number" placeholder="Enter Company Phone Number">
        </div>
        <div class="form-field wide-text-field" style="width: 80%;">
          <label for="address">Company Email <span style="color: red;">*</span></label>
          <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_company')"
            v-model="companyDetails.email" placeholder="Enter Company Email Address">
        </div>


        <br>
        <div class="form-field wide-text-field" style="width: 80%">
          <label for="first_name">Address: <span v-if="companyDetails.address" style="font-size: 0.9em">{{
              companyDetails.address.street_address
          }}, {{ companyDetails.address.suburb }},
              {{ companyDetails.address.country }}</span></label>
          <!-- <input type="text" name="first_name" placeholder="Street" v-model="address.street_address"> -->
          <div class="form-field text-field" style="width: 100%; margin-top: 0"
            v-if="$store.state.user.role.permissions.includes('can_do_admin_company')">
            <vue-google-autocomplete id="contactmap" classname="form-control"
              :placeholder="companyDetails.address ? companyDetails.address.street_address + ', ' + companyDetails.address.suburb + ', ' + companyDetails.address.country : '1 Apple Street, Melbourne, VIC 3000'"
              v-on:placechanged="getPersonalAddressData" :country="$store.state.country">
            </vue-google-autocomplete>
          </div>
        </div>

      </div>
      <div style="display: flex; align-items: center;"
        v-if="$store.state.user.role.permissions.includes('can_do_admin_company')">
        <p style="margin-right: 10px; font-size: 1em;">Company Multi-Factor Authentication</p>
        <InputSwitch v-model="enableCompanyMFA" @change="updateCompanyMFAStatus()" />
      </div>

      <p v-if="!loadingCompanyDetails && $store.state.user.role.permissions.includes('can_do_admin_company')"
        class="green-button" @click="updateCompanyDetails()" style="cursor:pointer; width: fit-content; margin: 1em 0; ">
        Save Company Details</p>

     

    </div>

    <!-- COMPANY MEDICATIONS LIST -->
    <!-- COMPANY MEDICATIONS LIST -->
    <!-- COMPANY MEDICATIONS LIST -->
    <div v-if="subView == 'Medications List' && $store.state.user.role.permissions.includes('can_view_admin_company')"
      style="display: grid; grid-gap: 10px;">
      <DrugListTable />
    </div>


    <!-- COMPANY PASSWORD CONFIG -->
    <!-- COMPANY PASSWORD CONFIG -->
    <!-- COMPANY PASSWORD CONFIG -->
    <div v-if="subView == 'Password Configuration' && $store.state.user.role.permissions.includes('can_view_admin_company')"
      style="display: grid; grid-gap: 10px;">
      <AdminPasswordConfig />
    </div>

      <!-- CREATE SUPPLIER -->
  <!-- CREATE SUPPLIER -->
  <!-- CREATE SUPPLIER -->
  <div v-if="subView == 'Find/Create Supplier' && $store.state.user.role.permissions.includes('can_do_admin_company')"
    style="display: grid; grid-gap: 10px; ;">
    <NewSupplier />
  </div>

    <!-- SET RESPONSIBLE PERSON -->
    <!-- SET RESPONSIBLE PERSON -->
    <!-- SET RESPONSIBLE PERSON -->
    <div
      v-if="subView == 'Set Responsible Person' && $store.state.user.role.permissions.includes('can_do_admin_company')"
      style="display: grid; grid-gap: 10px;"
    >
      <SetResponsiblePerson :facilities="facilities" />
    </div>
 

    <!-- CREATE ROLE -->
    <!-- CREATE ROLE -->
    <!-- CREATE ROLE -->
    <div v-if="subView == 'Create Role' && $store.state.user.role.permissions.includes('can_view_admin_roles')"
      style="display: grid; grid-gap: 10px; min-width: 50em;">
      <p style="text-align: left; font-size: 1em; margin-bottom: 0; font-weight: 500">Enter Role name</p>
      <input type="text" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_roles')" v-model="newRole.name" style="width: 20em;">
      <!-- permission table -->
      <div v-if="!rolePermissions.length">
        <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
      </div>
      <div v-else>
        <p style="text-align: left; font-size: 1em; margin-bottom: 0; font-weight: 500">Select Permissions</p>

        <div style="display: grid; grid-template-columns: 1fr 1fr; min-width: 50em; width: 50vh;">
          <div class="p-field-checkbox" style="padding: 10px; font-weight: bold; color: black;"
            :style="item.includes('report') && !item.includes('logs_report') ? { backgroundColor: 'rgba(187, 16, 114, 0.6)' } : item.includes('admin_') ? { backgroundColor: 'rgba(176, 224, 230,0.7)' } : item.includes('logs') ? { backgroundColor: '#00ad5031' } :
            item.includes('prescription') || item.includes('chart') || item.includes('administer') ? { backgroundColor: '#FED182' } : { backgroundColor: '#F8F8F8' }"
            v-for="(item, index) in rolePermissions" :key="index">
            <Checkbox :id="item" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_roles')" name="city" :value="item" v-model="newRole.permissions" />
            <label style="padding-left: 10px;" :for="item">{{ item }}</label>
          </div>
        </div>
        <div style='margin-top: 20px;' v-if='$store.state.user.role.permissions.includes("can_do_admin_roles")'>
          <p class="green-button" style="width: 10em;" @click="createRole()">Add New Role</p>
        </div>
      </div>

    </div>

    <!-- EDIT ROLE -->
    <!-- EDIT ROLE -->
    <!-- EDIT ROLE -->
    <!-- && $store.state.user.role.permissions.includes('can_view_admin_roles')" -->
    <div v-if="subView == 'Edit Role' && $store.state.user.role.permissions.includes('can_view_admin_roles')"
      style="display: grid; grid-gap: 10px; min-width: 50em;">
      <div style="display: grid; justify-content: flex-start">
        <p style="text-align: left; font-size:1em; font-weight: 500">Select Role to Edit</p>
        <Dropdown v-model="selectedRole" :options="roles" optionLabel="name" placeholder="Select Role" style="width: 20em" />
      </div>
      <div v-if="selectedRole">
        <p style="text-align: left; font-size: 1em; margin-bottom: 0; font-weight: 500; margin-bottom: 1em; font-weight: bold;">{{ selectedRole.name
        }}
        </p>
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh;">
          <div class="p-field-checkbox" v-for="(perm, i) in rolePermissions" :key="i">
            <Checkbox :id="perm + i" :name="perm" :value="perm" v-model="selectedRole.permissions" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_roles')"/>
            <label style="padding-left: 10px;" :for="perm + i">{{ perm }}</label>
          </div>
        </div>
        <div style='margin-top: 20px;' v-if='$store.state.user.role.permissions.includes("can_do_admin_roles")'>
          <p class="green-button" style="width: 15em;" @click="updateRole(selectedRole)">Update Role Permissions</p>
        </div>
      </div>
      <div v-if="loadingRoles">
        <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
      </div>
      <!-- <div v-for="(item, index) in roles" :key="index">
        <p style="text-align: left; font-size: 1em; margin-bottom: 0; font-weight: 500; margin-bottom: 1em">{{ item.name
        }}
        </p>
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh;">
          <div class="p-field-checkbox" v-for="(perm, i) in rolePermissions" :key="i">
            <Checkbox :id="perm + index" :name="perm" :value="perm" v-model="item.permissions" :disabled="!$store.state.user.role.permissions.includes('can_do_admin_roles')"/>
            <label style="padding-left: 10px;" :for="perm + index">{{ perm }}</label>
          </div>
        </div>
        <div style='margin-top: 20px;' v-if='$store.state.user.role.permissions.includes("can_do_admin_roles")'>
          <p class="blue-button" style="width: 15em;" @click="updateRole(item)">Update Role Permissions</p>
        </div>
      </div> -->
    </div>



    <!-- ASSIGN NOTIFICATION CHANNELS -->
    <!-- ASSIGN NOTIFICATION CHANNELS -->
    <!-- ASSIGN NOTIFICATION CHANNELS -->
    <div v-if="subView == 'Assign Notification Channels' && $store.state.user.role.permissions.includes('can_view_admin_roles')"
      style="display: grid; grid-gap: 10px; min-width: 50em;">
      <div v-if="loadingRoles">
        <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
      </div>
      <div v-else style="display: grid; grid-gap:1em; margin-top: 1em;">
        <p style='font-size: 1em; text-align: left; margin: 0;'>Assign Notification channels to roles. Multiple channels may be
          assigned to a role.</p>
        <p style='font-size: 1em; text-align: left; margin: 0; margin-bottom: 1em; font-weight: bold;'>Selecting a channel
          will enable the channel's site notifications by default.</p>
        <!-- <p class="green-button" style="width: 15em;" @click="createNewChannel()">Create a new channel</p> -->
        
        <p style="margin: 0; margin-bottom: -10px; font-size: 1em; text-align: left; font-weight: 500">Select Role:</p>
        <Dropdown id="role-type" style="width: fit-content;" placeholder="Select Role" :options="roles" optionLabel="name"
          optionValue="uuid" v-model="assignNotificationChannelPayload.role_id" @change="getRoleChannels(assignNotificationChannelPayload.role_id)" />
        <div v-if='assignNotificationChannelPayload.role_id'
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; min-width: 50em; width: 50vh;">
          <div v-for="(channel, i) in notificationChannels" :key="i"
            style="border-radius: 8px; box-shadow: 0 0 5px #d9d9d9; padding 10px; padding-bottom: 0px; height: fit-content">
            <div class="p-field-checkbox" style="display: flex">
              <Checkbox :id="i + '-channel'" :binary="true" v-model="channel.selected" />
              <label style="padding: 10px;" :for="i + '-channel'">{{ channel.name }}</label>
            </div>
            <div style="display: grid; background-color: #f3f4f4" v-if="channel.selected">
              <div class="p-field-checkbox">
                <Checkbox :id="i + 'sms-channel'" :binary="true" v-model="channel.sms" />
                <label style="padding: 10px;" :for="i + 'sms-channel'">SMS</label>
              </div>
              <div class="p-field-checkbox">
                <Checkbox :id="i + 'email-channel'" :binary="true" v-model="channel.email" />
                <label style="padding: 10px;" :for="i + 'email-channel'">Email</label>
              </div>
            </div>
          </div>
        </div>
        <div style='margin-top: 20px;' v-if='assignNotificationChannelPayload.role_id'>
          <p class="green-button" style="width: 15em;" @click="updateRoleChannels()">Update Role Channels
          </p>
        </div>
      </div>
    </div>

    <div v-if="subView == 'Assign Alert Channels' && $store.state.user.role.permissions.includes('can_view_admin_roles')"
      style="display: grid; grid-gap: 10px; min-width: 50em;">
      <div v-if="loadingRoles">
        <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
      </div>
      <SetAlertChannels v-else />
    </div>

    <div v-if="subView == 'Set Stock Lists' && $store.state.user.role.permissions.includes('can_view_admin_roles')"
      style="display: grid; grid-gap: 10px; min-width: 50em;">
      <div v-if="loadingRoles">
        <i class="pi pi-spin pi-spinner" style="fontSize: 3rem !important; margin-top: 3em;"></i>
      </div>
      <StockLists v-else />
    </div>


    </div>
    <!-- END OF ADMIN VIEW PERMISSION -->

    <!-- SEARCHABLE LOCATIONS -->
    <!-- SEARCHABLE LOCATIONS -->
    <!-- SEARCHABLE LOCATIONS -->
    <div v-if="subView == 'Searchable Locations'" >

      <i v-if="!selectedSearchableFilterDetails.name && !locationDirectory"  class="pi pi-spin pi-spinner"
          style="fontSize: 3rem !important; margin-top: 5em;"></i>
      <div v-else style="display: grid; grid-gap: 10px; ;" >
      <p style="font-size: 1em; text-align: left; margin: 0; margin-top: 1em;">Select Facility:</p>
      <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
        @change="retrieveSelectedFacilityLocations()"
        :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'" style=" width: fit-content" />
      <div style="display: flex; align-items: center;">
        <h3 style="text-align: left;">Lists of Searchable Locations</h3>
        <div style='margin-left: 10px;' v-if="!createNewList">
          <p class="blue-button" style="width: fit-content; "
            @click="createNewList = true; retrieveCompanyTree()">Create New List</p>
        </div>
      </div>


      <div style="display: grid;grid-template-columns: 1fr 1fr; grid-gap: 20px; justify-content: flex-start; ">
        <div v-for="(item, index) in searchableFilters" :key="index"
          style="text-align: left; display: flex; align-items: center;">
          <RadioButton :name="item.name" :value="item.uuid" v-model="selectedSearchableFilter" @change="selectList()" />
          <label :for="item" style="padding-left: 10px;"> {{ item.name }}
            <span style="margin-left: 10px; color: green; font-weight: bold;" v-if="item.active">Active</span>
            <span @click="activateList(item.uuid)"
              style="margin-left: 10px; border-radius: 4px; border: 1px solid grey; padding: 0 5px; cursor: pointer"
              v-else>Activate</span>
          </label>
        </div>
      </div>
      <div>

        <div v-if="createNewList">
          <div style="display: grid;grid-template-rows;: 1fr 1fr">
            <div style="display: flex; margin-top: 20px;">
          <input type="text" placeholder="Enter List Name" v-model="newList">
          <p v-if="selectedLocations.length && newList.length > 0 && !tableloading" class="blue-button"
            style="width: fit-content; margin-left: 10px; display:flex; align-items: center;" @click="createList()"> Create 
          </p>
          <p class="grey-button" style="width: fit-content; margin-left: 10px; display:flex; align-items: center;"
            @click="createNewList = false">Cancel</p>
          </div>
          <div style="display: flex;">
            <p> Please ensure you have selected at least <i>one</i> location below. </p>
          </div>
        </div>
      </div>
      </div>

      <div>
       


        <table  class="location-tree" style="border-radius: 12px;">
          <div v-if="!createNewList" style="display: flex; align-items: center;">
            <h3 style="text-align: left; display: inline; margin: 0;">{{ selectedSearchableFilterDetails.name }}</h3>
            <span class="green-button" style="font-size: 0.9em; margin-left: 10px;" @click="updateFilterList()">Update {{
                selectedSearchableFilterDetails.active ? '' : '& Activate '
            }}List</span>
          </div>


           <h3 v-if="retrievingLocations"> Loading {{selectedSearchableFilterDetails.name}} Location Table... </h3>
          <tbody v-for="(cluster, clusteri) in locationDirectory" :key="clusteri">
            <tr>
              <td style="display: flex; align-items: center">
                <p>{{ cluster.name }}</p>
                <div class="p-field-checkbox">
                  <Checkbox id="binary" @change="addOrRemoveLoc(cluster)" v-model="cluster.is_searched" :binary="true"
                    style="margin-right: 5px;" />
                  <!-- <label for="binary" style="margin-right: 10px;">Search this location</label> -->
                </div>
              </td>
            </tr>
            <tr v-for="(item, index) in cluster.children" :key="index">
              <td style="border-top: 2px solid #e2e2e2; padding-top: 20px; ">
                <div style="display: flex; align-items: center">
                  <i class="pi-chevron-right pi" v-if="!item.expand" @click="item.expand = true"></i>
                  <i class="pi-chevron-down pi" v-if="item.expand" @click="item.expand = false"></i>
                  <i class="pi pi-reply"
                    style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 30px; padding-right: 30px;"></i>
                  <p class="location-name">{{ item.name }}</p>
                  <div class="p-field-checkbox">
                    <Checkbox id="binary" @change="addOrRemoveLoc(item)" v-model="item.is_searched" :binary="true"
                      style="margin-right: 5px;" />
                    <!-- <label for="binary" style="margin-right: 10px;">Search this location</label> -->
                  </div>
                </div>
                <div class="location-entry">
                  <div v-if="item.expand">
                    <tbody v-for="(a, aA) in item.children" :key="aA">
                      <tr>
                        <td>
                          <div style="display: flex; align-items: center">
                            <i class="pi pi-reply"
                              style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                            <p class="location-name">{{ a.name }}</p>
                            <div class="p-field-checkbox">
                              <Checkbox id="binary" @change="addOrRemoveLoc(a)" v-model="a.is_searched" :binary="true"
                                style="margin-right: 5px;" />
                              <!-- <label for="binary" style="margin-right: 10px;">Search this location</label> -->
                            </div>
                          </div>
                          <div class="location-entry">
                            <tbody v-for="(b, bB) in a.children" :key="bB">
                              <tr>
                                <td>
                                  <div style="display: flex; align-items: center">
                                    <i class="pi pi-reply"
                                      style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                    <p class="location-name">{{ b.name }}</p>
                                    <div class="p-field-checkbox">
                                      <Checkbox id="binary" @change="addOrRemoveLoc(b)" v-model="b.is_searched" :binary="true"
                                        style="margin-right: 5px;" />
                                      <!-- <label for="binary" style="margin-right: 10px;">Search this location</label> -->
                                    </div>
                                  </div>
                                  <div class="location-entry">
                                    <tbody v-for="(c, cC) in b.children" :key="cC">
                                      <tr>
                                        <td>
                                          <div style="display: flex; align-items: center">
                                            <i class="pi pi-reply"
                                              style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                            <p class="location-name">{{ c.name }}</p>
                                            <div class="p-field-checkbox">
                                              <Checkbox id="binary" @change="addOrRemoveLoc(c)" v-model="c.is_searched" :binary="true"
                                                style="margin-right: 5px;" />
                                              <!-- <label for="binary" style="margin-right: 10px;">Search this location</label> -->
                                            </div>
                                          </div>
                                          <div class="location-entry">
                                            <tbody v-for="(d, dD) in c.children" :key="dD">
                                              <tr>
                                                <td>
                                                  <div style="display: flex; align-items: center">
                                                    <i class="pi pi-reply"
                                                      style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 30px;"></i>
                                                    <p class="location-name">{{ d.name }}</p>
                                                    <div class="p-field-checkbox">
                                                      <Checkbox id="binary" @change="addOrRemoveLoc(d)" v-model="d.is_searched" :binary="true"
                                                        style="margin-right: 5px;" />
                                                      <!-- <label for="binary" style="margin-right: 10px;">Search this location</label> -->
                                                    </div>
                                                  </div>
                                                  <div class="location-entry">
                                                    <tbody v-for="(e, eE) in d.children" :key="eE">
                                                      <tr>
                                                        <td>
                                                          <div style="display: flex; align-items: center">
                                                            <i class="pi pi-reply"
                                                              style=" align-items: center;transform: scaleY(-1); width: 20px; padding-left: 50px; padding-right: 50px;"></i>
                                                            <p class="location-name">{{ e.name }}</p>
                                                            <div class="p-field-checkbox">
                                                              <Checkbox id="binary" @change="addOrRemoveLoc(e)" v-model="e.is_searched" :binary="true"
                                                                style="margin-right: 5px;" />
                                                              <!-- <label for="binary" style="margin-right: 10px;">Stock Register</label> -->
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
      </div>
      </td>
      </tr>
      </tbody>
    </div>
    </td>
    </tr>
    </tbody>
    </div>
    </td>
    </tr>
    </tbody>
    </div>
    </td>
    </tr>
    </tbody>
    </div>
    </div>

    </td>

    </tr>
    </tbody>
    </table>
    </div>
      <!-- this one -->
  </div>
    </div>

    <!-- PERSONAL SETTINGS -- MFA -->
    <!-- PERSONAL SETTINGS -- MFA -->
    <!-- PERSONAL SETTINGS -- MFA -->
    <div v-if="subView == 'Multi-Factor Authentication'" style="display: grid; grid-gap: 10px; ;">
      <MFAConfig :user="user" @getloggeduser="getLoggedUser" />
    </div>


    <!-- PERSONAL SETTINGS -- ACCOUNT SETTINGS -->
    <!-- PERSONAL SETTINGS -- ACCOUNT SETTINGS -->
    <!-- PERSONAL SETTINGS -- ACCOUNT SETTINGS -->
    <div v-if="subView == 'Account Settings'" style="display: grid; grid-gap: 10px; ;">
      <div style="margin: 20px 0; display: grid; grid-gap: 10px; width:fit-content;">
        <h4 style="text-align: left;">
          Create or Reset your Pin
        </h4>
        <p style="text-align: left; font-size: 1em; margin: 0;">
          PIN code must be at least 4 characters long.
        </p>
        <inputText class="grey-input" style="border: 1px solid grey" placeholder="Enter PIN Code" v-model="pinCode" />
        <inputText class="grey-input" style="border: 1px solid grey" placeholder="Password Confirmation" v-model="password" type="password"/>
        <button  class="green-button" @click="savePIN()" :disabled="pinCode.length < 4 && password">
          Save PIN
        </button>

        <h4 style="text-align: left;">
          Reset Password
        </h4>
        <label style="text-align: left" for="old_password">
          Old Password <span style="color: red;">*</span>
        </label>
        <Password v-model="passwordChange.old_password" toggleMask :feedback="false" inputStyle="width:100%;"></Password>
        <label style="text-align: left" for="new_password">
          New Password <span style="color: red;">*</span>
        </label>
        <Password v-model="passwordChange.password" toggleMask :feedback="false" inputStyle="width:100%;"></Password>
        <label style="text-align: left" for="confirm_new_password">
          Confirm New Password <span style="color: red;">*</span>
        </label>
        <Password v-model="passwordChange.password_confirm" toggleMask :feedback="false" inputStyle="width:100%;"></Password>

        <ul>
          <template v-for="(value, name) in passwordRestrictions" :key="name">
            <li v-if="name == 'min_pwd_length' && value !== null && value !== false" style="text-align: left">
              At least {{ value }} characters
            </li>
            <li v-else-if="name == 'at_least_1_num_pwd' && value !== null && value !== false" style="text-align: left">
              At least 1 number
            </li>
            <li v-else-if="name == 'at_least_1_special_char_pwd' && value !== null && value !== false" style="text-align: left">
              At least 1 special character
            </li>
            <li v-else-if="name == 'pwd_has_letters' && value !== null && value !== false" style="text-align: left">
              At least 1 letter
            </li>
            <li v-else-if="name == 'pwd_is_mixed_case' && value !== null && value !== false" style="text-align: left">
              At least 1 uppercase and 1 lowercase letter
            </li>
            <li v-else-if="name == 'pwd_reuse_interval' && value !== null && value !== false" style="text-align: left">
              Password Reuse Interval: {{ value }} days
            </li>
            <li v-else-if="value !== null && value !== false" style="text-align: left">
              {{ name }}: {{ value }}
            </li>
          </template>
        </ul>

        <p v-if="passwordChange.password != passwordChange.password_confirm">
          Passwords do not match.
        </p>
        <div style="display: flex;">
          <span class="green-button" style="width: fit-content; margin-right: 10px;"
            v-if="passwordChange.old_password && passwordChange.password && passwordChange.password_confirm"
            @click="editPersonalPassword()">Reset</span>
          <span class="black-button" style="width: fit-content;"
            @click="resetPassword = false, passwordChange.old_password = '', passwordChange.password ='', passwordChange.password_confirm = ''">Cancel</span>
        </div>

        <h4 style="text-align: left;">
          Deactivate Account
        </h4>
        <span
          class="red-button"
          style="width: fit-content;"
          @click="displayConfirmationModal = true;">
          Deactivate Account
        </span>
      </div>
      <p style="font-weight: bold; color: red; text-align: left; font-size: 1em;" v-if="mailCodeError || phoneCodeError">
        Error: {{ mailCodeError }} {{ phoneCodeError }}
      </p>
    </div>

    <div v-if="!$store.state.user.role.permissions.includes('can_view_admin_users') && (subView.includes('Edit User') || subView.includes('Find User'))"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div v-if="!$store.state.user.role.permissions.includes('can_do_admin_users') && subView.includes('Register User')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="!$store.state.user.role.permissions.includes('can_view_admin_company') && subView.includes('Create/Edit Facilities & Locations')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div v-if="!$store.state.user.role.permissions.includes('can_view_admin_roles') && subView.includes('Role')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="!$store.state.user.role.permissions.includes('can_view_admin_company') && subView.includes('Company Details')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div v-if="subView.includes('Medications List') && !$store.state.user.role.permissions.includes('can_view_admin_company')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="subView.includes('Basic Configuration') && !$store.state.user.role.permissions.includes('can_view_admin_company')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="subView.includes('Register Practice/Prescriber') && !$store.state.user.role.permissions.includes('can_do_admin_prescribers')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="subView.includes('Find Prescriber') && !$store.state.user.role.permissions.includes('can_view_admin_prescribers')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="subView.includes('Register Pharmacy') && !$store.state.user.role.permissions.includes('can_do_admin_company')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="subView.includes('Assign Notification Channels') && !$store.state.user.role.permissions.includes('can_view_admin_roles')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <div
      v-if="subView == 'Password Configuration' && !$store.state.user.role.permissions.includes('can_view_admin_company')"
      style="margin-top: 10em;">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
      </div>
  </div>
  <NewChannelModal
    @close="close"
      v-if="displayNewChannelModal"
      :reloadPageOnClose="reloadPageOnClose"
  />
  <SuccessModal
    @close="close"
    v-if="displaySuccessModal"
    :reloadPageOnClose="reloadPageOnClose"
    :message="successMessage"
    :first_spec_line="first_spec_line"
    :second_spec_line="second_spec_line"
  />
  <SignatureModal
    @close="close"
    v-if="displaySignatureModal"
    :endpoint="endpoint"
    :payload ="payload"
    @isSuccessful="isSuccessful"
  />
  <ConfirmationModal
    v-if="displayConfirmationModal"
    :actionDescription="`Your login will be permenantly deactivated.\nTo reactivate your account you will need to contact your administrator.\nAre you sure you want to proceed?`"
    :loading="loadingDeactivateAccount"
    :errorMessage="deactivateAccountErrorMessage"
    rejectText="No, go back"
    acceptText="Yes, delete it"
    @accepted="deactivateAccount"
    @close="close"
  />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import NewChannelModal from '../components/modals/NewChannelModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import DrugListTable from '@/components/tables/DrugListTable.vue';
import AdminPasswordConfig from '@/components/settings/AdminPasswordConfig.vue';
import SetAlertChannels from '@/components/settings/SetAlertChannels.vue';
import ConfirmationModal from '../components/modals/ConfirmationModal.vue';
import NewSupplier from '@/components/settings/NewSupplier.vue';
import StockLists from '@/components/settings/StockLists.vue';
import SetResponsiblePerson from '@/components/settings/SetResponsiblePerson.vue';
import MFAConfig from '../components/settings/MFAConfig.vue';


export default {
  components: {
    SignatureModal,
    SuccessModal,
    NewChannelModal,
    DrugListTable,
    AdminPasswordConfig,
    SetResponsiblePerson,
    SetAlertChannels,
    ConfirmationModal,
    NewSupplier,
    StockLists,
    MFAConfig
  },
  middleware: 'auth',
  computed: {
    emailsInContactMethods() {
      return this.user.contact_methods.filter(contactmethod => contactmethod.type == 'email')
    },
    phonesInContactMethods() {
      return this.user.contact_methods.filter(contactmethod => contactmethod.type == 'phone')
    },
    loginaccountmobiles() {
      if(this.user.phones) {
        return this.user.phones.filter(singluarphone => singluarphone.name == "phone")
      } else if (this.user.contact_methods) {
        return this.user.contact_methods.filter(singluarcontactmethod => singluarcontactmethod.name == "phone")
      } else {
        return []
      }
    },
    compLocationTypes() {
      var loctype = []
      this.$store.state.locationTypes.forEach((value) => loctype.push({"name": value}))
      return loctype
    },
    blindStockValue(){
      let computedEnableBlindCount;
      computedEnableBlindCount = this.$store.state.blind_count_setting;
      return computedEnableBlindCount
    },
    countries() {
      return this.$store.getters.getCountries
    }
  },
  data() {
    return {
      enableBlindCount:false,
      tableloading : false,
      filterAgedCareNotifications: ["prescription_created","prescription_updated","prescription_cancelled","prescription_drafted","nim_administered"],
      selectedPhoneCountry : {"name":"Australia","code":"AU","callingcode":"+61"},
      isMobile : false,
      pinCode: '',
      password:'',
      endpoint:'',
      passwordWarning: '',
      retrievingLocations : false,
      loadedfilterlist : false,
      displayNewEmail: false,
      displayNewPhone: false,
      reloadPageOnClose: false,
      nons8Frequency: '',
      s8Frequency: '',
      loadingUser: false,
      loadingUsers: false,
      loadingRoles: false,
      loadingCompanyDetails: false,
      loadingCompanytot: 0,
      loadingCompanycurr: 0,
      setFrequencyInterval: [
        'Hours',
        'Days',
        'Weeks',
        'Months',
      ],
      nons8FrequencyUnit: 'Hours',
      gp: '',
      successMessage: '',
      first_spec_line: '',
      second_spec_line: '',
      searchPage: 1,
      pageCount: 1,
      newEmail: {
        name: 'email',
        data: '',
        type: 'email'
      },
      newPhone: {
        name: 'phone',
        data: '',
        type: 'phone'
      },
      noResults: false,
      displaySuccessModal: false,
      displayNewChannelModal:false,
      displaySignatureModal: false,
      displayConfirmationModal: false,
      loadingDeactivateAccount: false,
      deactivateAccountErrorMessage: '',
      timezones: [],
      searchEntry: '',
      searchUserResults: '',
      numPrescriberActions: 0,
      prescriberActionsCompleted: 0,
      rolePermissions: [
      ],
      loginTypes:[
        {name: 'Nurse', value: 'nurse'},
        {name: 'Admin', value: 'hospital'},
        {name: 'Prescriber', value:'prescriber'},
        {name: 'Pharmacist', value:'pharmacist'},
        {name: 'Anesthetist', value:'anesthetist'}
      ],
      newRole: {
        name: "",
        permissions: []
      },
      editForm: {
        name: '',
        has_drug_register: false
      },
      moveForm: {
        destination: '',
        location: '',
      },
      movingWarning: "Warning: Moving locations may cause several issues.",
      display: false,
      parentLoc: false,
      // NEW LOCATION REGISTRATION
      newLocation: '',
      newLocState: '',
      locationType: '',
      has_drug_register: false,
      selectedView: 'General',
      subView: 'Basic Configuration',
      // SETTINGS DIRECTORY
      directory: [
        {
          name: 'General',
          open: true,
          directory: [
            'Basic Configuration'
          ],
          permissions: [
            'view_admin',
          ]
        },
        {
          name: 'Users',
          open: false,
          directory: [
            'Register User',
            'Edit User',
            'Find User'
          ]
        },
        {
          name:'Organisation',
          open: false,
          directory: [
            'Create/Edit Facilities & Locations',
            'Set Stock Lists',
            'Company Details',
            'Medications List',
            'Password Configuration',
            'Set Responsible Person',
          ]
        },
        {
          name: 'Suppliers',
          open: false,
          directory: [
            'Find/Create Supplier',
          ]
        },
        {
          name: 'Roles',
          open: false,
          directory: [
            'Create Role',
            'Edit Role'
          ]
        },
        {
          name: 'Notifications & Alerts',
          open: false,
          directory: [
            'Assign Notification Channels',
            'Assign Alert Channels'
          ]
        },
        {
          name: 'Patient Search',
          open: false,
          directory: [
            'Searchable Locations'
          ]
        },
        {
          name: 'Personal Settings',
          open: false,
          directory: [
            'Multi-Factor Authentication',
            'Account Settings'
          ]
        },
      ],
      registerUserPersonalInfo: {
        first_name: '',
        last_name: '',
        preferred_name: 'N/A',
        sex: 'N/A',
        dob: ''
      },
      registerPrescriberInfo: {
        first_name: '',
        last_name: '',
        preferred_name: 'N/A',
        prescriber_number: '',
        sex: 'N/A',
        dob: ''
      },
      registerPrescriberAddress: {
        street_address: '',
        country: '',
        postcode: '',
        suburb: '',
        city: ''
      },
      registerUserAddress: {
        street_address: '',
        country: '',
        postcode: '',
        suburb: '',
        city: ''
      },
      registerUserContactInfo: {
        phone_number: '',
        email: '',
        identification: ''
      },
      registerPrescriberContactInfo: {
        phone_number: '',
        email: '',
        fax_number: ''
      },
      registerLogin: {
        name: '',
        password: '',
        user_id: '',
        company_id: this.$store.state.company.uuid
      },
      companyDetails: {
        name: '',
        email: '',
        email_id: '',
        contact: '',
        contact_id: '',
        phone_number: '',
        phone_number_id: '',
        address: ''
      },
      pharmacyDetails: {
        company_id: null,
        company_name: null,
        contact_methods: [{
          name: 'Email',
          data: '',
          type: 'email'
        }],
        pharmacy_name: '',
        state_id: '',
        facility_id: '',
        address: {},
        emails: [
          {
            value: '',
            isAdmin: true,
          }
        ]
      },
      pharmacyResults: [],
      pharmacyCompanyResults: [],
      pharmacy: '',
      pharmacyCompany: '',
      selectedPharmacy: '',
      selectedPharmacyCompany: '',
      selectedPharmacyCompanyID: '',
      displayPharmacyRegistration: false,
      // PRESCRIBER/PRACTICE
      prescriberDetails: {
        practice_id: null,
        practice_name: null,
        contact_methods: [{
          name: 'Email',
          data: '',
          type: 'email'
        }],
        prescriber_number: '',
        state_id: '',
        facility_id: '',
        address: {},
        email: ''
      },
      prescriberResults: [],
      practiceResults: [],
      prescriber: '',
      practice: '',
      selectedPrescriber: '',
      selectedPractice: '',
      selectedPracticeID: '',
      displayPrescriberRegistration: false,
      // NOTIFICATIONS
      notificationChannels: [
      ],
      assignNotificationChannelPayload: {
        channels: [],
        role_id: ''
      },
      createParentLocation: false,
      locationDirectory: '',
      roles: [],
      flatLocations: [
      ],
      user: '',
      // PRESCRIBER
      displayFacilityForm: false,
      displayEditFacilityForm: false,
      selectedFacilities: '',
      selectedFacility: '',
      facilities: [],
      newLoginForm: {
        locations: [],
        role: '',
        email_id: '',
        phone_id: '',
        timezone: '',
        type:''
      },
      matchesFound: '',
      noMatchFound: true,
      displayLoginCreate: false,
      loginToEdit: '',
      passwordChange:{
        old_password:'',
        password:'',
        password_confirm:''
      },
      userSettings: {
        mfa_phone_active: false,
        mfa_email_active: false
      },
      passwordRestrictions: {
        min_pwd_length: null,
        pwd_reuse_interval: null,
        at_least_1_num_pwd: false,
        at_least_1_special_char_pwd: false,
        pwd_has_letters: false,
        pwd_is_mixed_case: false
      },
      resetPassword: false,
      loginCreateError: '',
      createUserError: '',
      userUuid: '',
      prescriberUuid: '',
      selectedSearchableFilter: [],
      selectedSearchableFilterDetails: [],
      searchableFilters: [],
      selectedLocations: [],
      newList: '',
      createNewList: false,
      states: [],
      // MFA
      sendMailCode: false,
      sentMailCode: false,
      successMailCode: false,
      mailCode: '',
      mailCodeError: '',
      sendPhoneCode: false,
      sentPhoneCode: false,
      successPhoneCode: false,
      phoneCode: '',
      phoneCodeError: '',
      verifyEmail: false,
      verifiedEmail: false,
      verifiedPhone: false,
      displayMFAWarning: false,
      enableCompanyMFA: false,
      // CREATE FACILITY
      editHPIO: '',
      hpi: '',
      facility_or_location: '',
      facility_uuid: '',
      facility_address_id: '',
      facility_contact_methods: {
        phone_number: '',
        email: '',
        fax: ''
      },
      updatedRole:false,
      selectedRole:'',
      payload:''
    }
  },
  methods: {
    updateBlindCountSetting(){
      console.log('updating blind count setting');
      axios.post(`/set-blind-count`, {enable: this.enableBlindCount}, this.$store.state.header).then(res=>{
        // saving the set value globally
        console.log('saved blind count', this.enableBlindCount);
        this.$store.commit('setCompanyBlindCount', this.enableBlindCount);
        this.successMessage  = "Successfully set the blind check for the company.";
      }).catch(err => {
        // revert the changes to show it didn't change 
        this.enableBlindCount = !this.enableBlindCount;
        this.successMessage = err.response && err.response.data && err.response.data.message ? err.response.data.message : err.response.data ? err.response.data : 'Error: Please try again later.';
        if(err.response && err.response.data && err.response.data.errors){
          this.first_spec_line = err.response.data.errors;
        }
        console.log('err is', err, err.response.data)
      });
    },
    linkPharmacy(){
      this.displaySuccessModal = true;
      this.successMessage = "Linking pharmacy to facility...";
      // LINKING AN EXISTING PHARMACY TO AN EXISTING FACILITY
      axios.post(`facility/${this.selectedFacility}/link-pharmacy`, {pharmacy_id: this.selectedPharmacy.uuid}, this.$store.state.header).then(res=>{
        console.log('successfully linked facility to pharmacy', res);
        this.successMessage  = "Successfully linked the pharmacy to the selected facility.";
      })
    },
    savePIN(){
      this.displaySuccessModal = true;
      this.successMessage = 'Validating and saving PIN code...'
      if(this.pinCode.length >= 4){
        axios.post('/set-pin', {pin_code: this.pinCode, password: this.password}, this.$store.state.header).then(res=>{
          console.log('saved pin', res);
          this.displaySuccessModal = true;
          this.successMessage = 'Saved PIN successfully!';
          this.pinCode = '';
          this.password = '';
        }).catch(err=>{
          this.displaySuccessModal = true;
          this.successMessage = err.response.data;
          this.pinCode = '';
          this.password = '';
        })
      }
    },
    isSuccessful(value){
      if(value){
        this.displaySignatureModal = false;
        this.displaySuccessModal= true;
        this.successMessage = 'PIN successfully saved!';
      }
    },
    retrieveSelectedFacilityLocations() {

      this.locationDirectory = '';
      console.log('this is the selected facility', this.selectedFacility);
      axios.post(`/facility/${this.selectedFacility.value}/locations`, {}, this.$store.state.header).then(res => {
        this.retrievingLocations = false;
        console.log('these are the facilitys locations', res.data);
        this.$store.dispatch('getRefreshToken');
        this.locationDirectory = res.data;
        if (this.subView == 'Searchable Locations') {
          this.selectList();
        }
      })
    },
    createPharmacy() {
      let emailload = [];
      this.displaySuccessModal = true;
      this.successMessage = 'Submitting registration, please wait...'
      this.pharmacyDetails.emails.forEach(email => {
        if (email.value) {
          emailload.push(email);
        }
      })
      if (this.pharmacyDetails.company_id) {
        this.pharmacyDetails.company_name = null;
      } else {
        this.pharmacyDetails.company_id = null;
      }

      // this.pharmacyDetails.state_id = this.pharmacyDetails.state_id.toString();
      this.pharmacyDetails.emails = emailload;
      console.log('THIS IS THE PHARMACY DETAILS PAYLOAD', this.pharmacyDetails);
      axios.post('/onload-pharmacy', this.pharmacyDetails, this.$store.state.header).then(res => {
        console.log('SUCCESSFULLY SUBMITTED', res);
        this.$store.dispatch('getRefreshToken');
        this.pharmacyDetails = {
          company_name: '',
          contact_methods: [{
            name: 'Email',
            data: '',
            type: 'email'
          }],
          pharmacy_name: '',
          state_id: '',
          facility_id: '',
          address: {},
          emails: [
            {
              value: '',
              isAdmin: true,
            }
          ]
        }
        this.successMessage = 'Successfully submitted pharmacy details!'
        this.first_spec_line = 'Pharmacy members will receive an email to confirm the account. After confirmation, you may proceed to reference the pharmacy within the system.'
      })
    },
    createPrescriberExistingPractice() {
      this.displaySuccessModal = true;
      this.successMessage = 'Submitting registration, please wait...'
      let payload = {
        "prescriber_number": this.prescriberDetails.prescriber_number,
        "state_id": this.prescriberDetails.state_id,
        "email": this.prescriberDetails.email,
        "facility_id": this.prescriberDetails.facility_id
      }
      axios.post(`/practice/${this.selectedPracticeID}/add-prescriber`, payload, this.$store.state.header).then(res => {
        console.log('prescriber added to practice', res.data);
        this.$store.dispatch('getRefreshToken');
        this.successMessage = 'Successfully registered and linked prescriber!';
        this.prescriberDetails = {
          practice_id: null,
          practice_name: null,
          contact_methods: [{
            name: 'Email',
            data: '',
            type: 'email'
          }],
          prescriber_number: '',
          state_id: '',
          facility_id: '',
          address: {},
          email: ''
        }
      }).catch(err => {
        this.successMessage = err.response && err.response.data && err.response.data.message ? err.response.data.message : err.response.data ? err.response.data : 'Error: Please try again later.';
        if(err.response && err.response.data && err.response.data.errors){
          this.first_spec_line = err.response.data.errors;
        }
        console.log('err is', err, err.response.data)
      })
    },
    createPrescriber() {
      this.displaySuccessModal = true;
      this.successMessage = 'Submitting registration, please wait...'

      if (this.prescriberDetails.practice_id) {
        this.prescriberDetails.practice_name = null;
      } else {
        this.prescriberDetails.practice_id = null;
      }

      // this.prescriberDetails.state_id = this.prescriberDetails.state_id.toString();
      console.log('THIS IS THE PRESCRIBER DETAILS PAYLOAD', this.prescriberDetails);
      axios.post('/onload-prescriber', this.prescriberDetails, this.$store.state.header).then(res => {
        console.log('SUCCESSFULLY SUBMITTED', res);
        this.$store.dispatch('getRefreshToken');
        this.prescriberDetails = {
          practice_id: null,
          practice_name: null,
          contact_methods: [{
            name: 'Email',
            data: '',
            type: 'email'
          }],
          prescriber_number: '',
          state_id: '',
          facility_id: '',
          address: {},
          email: ''
        }
        this.successMessage = 'Successfully submitted prescriber details!'
        this.first_spec_line = 'Prescribers will receive an email to confirm the account. After confirmation, you may proceed to reference the prescriber within the system.'
      })
    },
    makePharmacyAdmin(index) {
      console.log('THIS IS THE ADMIN PERSON', index);
      if (this.pharmacyDetails.emails[index].isAdmin) {
        this.pharmacyDetails.emails.forEach((email, i) => {
          if (i != index) {
            email.isAdmin = false;
          }
        })
      } else {
        this.pharmacyDetails.emails[index].isAdmin = true;
      }

    },
    checkHPIO(item) {
      console.log('this is the alert', item);
      // if(item.)
      if (!item.hpi) {
        item.hpi = ''
      }
    },
    close(value) {
      if (value == 'reload') {
        this.displaySuccessModal = false;
        window.location.reload();
        this.successMessage = '';
        this.first_spec_line = '';
        this.second_spec_line = '';
        this.user = '';
        this.matchesFound = '';
        this.registerUserContactInfo = {
          phone_number: '',
          email: '',
        };
        this.registerUserPersonalInfo = {
          first_name: '',
          last_name: ''
        };
        this.registerLogin = {
          name: '',
          password: '',
          user_id: '',
          company_id: this.$store.state.company.uuid
        };
        this.newLoginForm = {
          locations: [],
          role: '',
          timezone: 'Melbourne/Australia'
        };
        this.displayLoginCreate = false;
      } else {
        if(this.updatedRole == true){
          this.getRoles();
        }
        this.displaySuccessModal = false;
        this.displaySignatureModal = false;
        this.displayConfirmationModal = false;
        this.displayNewChannelModal = false;
        this.successMessage = '';
        this.first_spec_line = '';
        this.second_spec_line = '';
      }
      this.newRole = {
        name: '',
        permissions: []
      }
    },
    setFrequency(s8) {
      let nons8payload = 0;
      if (s8 == false) {
        switch (this.nons8FrequencyUnit) {
          case "Hours":
            nons8payload = this.nons8Frequency * 1;
            break;
          case "Days":
            nons8payload = this.nons8Frequency * 24;
            break;
          case "Weeks":
            nons8payload = this.nons8Frequency * 168;
            break;
          case "Months":
            nons8payload = this.nons8Frequency * 730;
            break;
        }
      }
      axios.post(`/stocktake-frequency`,
        { hours: s8 == true ? parseInt(this.s8Frequency) : parseInt(nons8payload), 's8': s8 }, this.$store.state.header).then(res => {
          console.log('Frequency has been set', res);
          this.successMessage = 'Frequency has been set.';
          this.displaySuccessModal = true;
          this.reloadPageOnClose = true;
          this.$store.dispatch('getRefreshToken');
        })
    },
    getFrequency() {
      axios.post(`/get-stocktake-frequencies`, {}, this.$store.state.header).then(res => {
        this.s8Frequency = res.data.s8;
        this.nons8Frequency = res.data.non_s8;
        this.$store.dispatch('getRefreshToken');
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    openLocationEntryForm(id) {
      console.log('this is the location clicked on', id);
      this.newLocation = '';
      this.locationType = '';
      this.newLocState = '';
      let locationentryitems = document.getElementsByClassName('location-new-entry');
      [].forEach.call(locationentryitems, function(item) {
        console.log(item);
        item.style.display = 'none';
      });
      document.getElementById(`${id}-new-entry`).style.display = 'block';
    },
    closeLocationEntryForm(id) {
      console.log('this is the location clicked on', id);
      document.getElementById(`${id}-new-entry`).style.display = 'none';
      this.facility_or_location = '';
      this.newLocation = '';
      this.newLocState = '';
      this.hpi = '';
      this.facility_contact_methods.email = '';
      this.facility_contact_methods.phone_number = '';
      this.facility_contact_methods.fax = '';
      this.editHPIO = false;
    },
    submitLocation(parent) {
      console.log('this is the parent location', parent);
      console.log(this.locationType)
      axios.post('/location', {
        facility_id: this.selectedFacility.value,
        parent_id: parent ? parent : '',
        name: this.newLocation,
        
        location_type: this.locationType ? this.locationType.toLowerCase() : this.$store.state.locationTypes[0].toLowerCase(),
        state_id: this.newLocState,
        has_drug_register: this.has_drug_register
      },
        this.$store.state.header).then(res => {
          this.$store.dispatch('getRefreshToken');
          console.log('this is the res for submitting location', res);
          this.createParentLocation = false;
          this.retrieveSelectedFacilityLocations();
        }).catch(err => {
          console.log('err issss', typeof err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
          console.log('err is', err.response.data, err.response.data);
        })
      // document.getElementsByClassName('location-new-entry').forEach((item, index) => {
      //   console.log(item);
      //   document.getElementsByClassName('location-new-entry')[index].style.display = 'none';
      // })
    },
    submitFacility() {
      let payload = {
        // address_id: null,
        company_id: this.$store.state.company.uuid,
        name: this.newLocation,
        state_id: this.newLocState,
        hpi_o: this.hpi,
        email: this.facility_contact_methods.email,
        phone: this.facility_contact_methods.phone_number,
        fax: this.facility_contact_methods.fax
      }

      console.log('this is the facility submit payload', payload);

      axios.post('/facility', payload,
        this.$store.state.header).then(res => {
          console.log('this is the res for submitting location', res);
          this.setFacilityAddress(res.data.uuid);
          this.retrieveCompanyTree();
          this.$store.dispatch('getRefreshToken');

        }).then(() => {
          this.newLocation = '';
          this.newLocState = '';
          this.hpi = '';
          this.facility_contact_methods.email = '';
          this.facility_contact_methods.phone_number = '';
          this.facility_contact_methods.fax = '';
          this.displayFacilityForm = false;
        }).catch(err => {
          console.log('err issss', typeof err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
          console.log('err is', err.response.data, err.response.data);
        })
      document.getElementsByClassName('location-new-entry').forEach((item, index) => {
        console.log(item);
        document.getElementsByClassName('location-new-entry')[index].style.display = 'none';
      })
    },
    editFacility() {
      let payload = {
        name: this.selectedFacility.label,
        hpi_o: this.selectedFacility.hpi_o,
        email: this.selectedFacility.email,
        phone: this.selectedFacility.phone,
        fax: this.selectedFacility.fax
      }
      this.displaySuccessModal = true;
      this.first_spec_line = 'Updating facility...';

      axios.post(`/facility/${this.selectedFacility.value}/edit`, payload, this.$store.state.header).then(res => {
        this.displayEditFacilityForm = false;
        this.$store.dispatch('getRefreshToken');
        console.log('facility has been updated', res.data);
        this.first_spec_line = 'Updated facility!';
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        this.first_spec_line = 'Error: ' + err;
        console.log('err is', err.response.data);
      })
    },
    // editFacilityAddress(){

    // },
    closeFacilityForm() {
      this.newLocation = '';
      this.newLocState = '';
      this.hpi = '';
      this.facility_contact_methods.email = '';
      this.facility_contact_methods.phone_number = '';
      this.facility_contact_methods.fax = '';
      this.displayFacilityForm = false;

    },
    checkDrugReg(location) {
      this.has_drug_register = location.has_drug_register;
    },
    editLocation(locationarg) {
      console.log('this is the locationarg', locationarg);
      let payload = {
        name: locationarg.editNameValue ? locationarg.editNameValue : locationarg.name,
        'has_drug_register': locationarg.has_drug_register,
        location: locationarg.key,
        location_type: locationarg.location_type.toLowerCase(),
      }
      if(locationarg.state_id && locationarg.state_id.id){
        payload.state_id = locationarg.state_id.id;
      } else{
        for(let key in this.states){
          if(key.code == locationarg.state_id){
            payload.state_id = key.id;
          }
        }
      }
      console.log('this is the payload being submitted');
      axios.post(`/location/${locationarg.key}`, payload, this.$store.state.header).then(res => {
        console.log('this is the res', res);
        this.$store.dispatch('getRefreshToken');
        this.retrieveCompanyTree();
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    refreshLoginForm() {
      this.newLoginForm = {
        name: '',
        password: '',
        user_id: '',
        company_id: this.$store.state.company.uuid
      }
      this.registerLogin.name = '';
      this.registerLogin.password = '';
    },
    copyLocationID(id) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = id;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    moveLocation(id) {
      this.moveForm.location = id;
      console.log('this is the location to move', id);
      axios.post('/location/move', this.moveForm, this.$store.state.header).then(res => {
        console.log('this is the move location res', res);
        this.$store.dispatch('getRefreshToken');

      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
      this.moveForm = {
        location: '',
        destination: ''
      }
      this.retrieveCompanyTree();
    },
    retrieveCompanyTree() {
      this.retrievingLocations = true;
      this.selectedLocations = [];
      this.locationDirectory = '';
      axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
        console.log('these are the facility locations', res.data);
        this.facilities = res.data;
        this.selectedFacility = this.facilities[0];
        this.retrieveSelectedFacilityLocations();
        this.$store.dispatch('getRefreshToken');
      }).catch(err => {
          console.log('err issss', typeof err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
          this.loginCreateError = err.response.data
          console.log('err is', err.response.data, err.response.data);
          this.$store.dispatch('getRefreshToken');
        })
    },
    submitLogin() {
      let payload = {
        user_id: this.user.uuid,
        timezone: this.newLoginForm.timezone,
        email_id: this.newLoginForm.email_id && this.newLoginForm.email_id.uuid ? this.newLoginForm.email_id.uuid : this.newLoginForm.email_id ? this.newLoginForm.email_id : '',
        phone_id: this.newLoginForm.phone_id && this.newLoginForm.phone_id.uuid ? this.newLoginForm.phone_id.uuid : this.newLoginForm.phone_id ? this.newLoginForm.phone_id : '',
        role_id: this.newLoginForm.role,
        name: this.registerLogin.name,
        password: this.registerLogin.password,
        company_id: this.$store.state.company.uuid,
        type: this.newLoginForm.type
      }
      if (this.registerLogin.name && this.registerLogin.password) {
        axios.post('/register', payload, this.$store.state.header).then(res => {
          this.$store.dispatch('getRefreshToken');
          console.log('this is the response data', res)
          this.newLoginForm.login_id = res.data.uuid;
          this.loginToEdit = res.data;
          this.linkLocations(this.newLoginForm);
          this.clearRegisterUser();

          this.successMessage = 'Success! Login Account for ' + this.user.first_name + ' ' + this.user.last_name + ' has been created!'
          this.displaySuccessModal = true;
          this.displayLoginCreate = false;

          this.newLoginForm = {
            locations: [],
            role: '',
            email_id: '',
            timezone: '',
          }
          this.registerLogin = {
            name: '',
            password: '',
            user_id: '',
            company_id: this.$store.state.company.uuid
          };

          this.retrieveUser({ person: { uuid: this.user.uuid } });
        }).catch(err => {
          console.log('err issss', typeof err.response.data);
          this.loginCreateError = err.response.data
          console.log('err is', err.response.data, err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        })
      }
    },
    clearRegisterUser() {
      this.registerUserPersonalInfo.first_name = '';
      this.registerUserPersonalInfo.last_name = '';
      this.registerUserPersonalInfo.preferred_name = 'N/A';
      this.registerUserPersonalInfo.sex = 'N/A';
      this.registerUserPersonalInfo.dob = '';
      this.registerUserContactInfo.phone_number = '';
      this.registerUserContactInfo.email = '';
      this.registerUserContactInfo.identification = '';
      this.registerPrescriberAddress.street_address = '';
      this.registerPrescriberAddress.country = '';
      this.registerPrescriberAddress.postcode = '';
      this.registerPrescriberAddress.suburb = '';
      this.registerPrescriberAddress.city = '';
      this.loginCreateError = '';
      this.createUserError = '';
    },
    createRole() {
      axios.post('/role', this.newRole, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        console.log('this is the res for new role', res);
        this.successMessage = `${this.newRole.name} has been created.`;
        this.displaySuccessModal = true;
        this.reloadPageOnClose = false;
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })

    },
    updateRole(role) {
      this.displaySuccessModal = true;
      this.successMessage = 'Updating Role...';
      console.log('updating role', role);
      let payload = {
        'name': role.name,
        permissions: role.permissions
      }
      axios.post('/role/' + role.uuid, payload, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        console.log('role has been updated', res);
        // this.successMessage =  `${role.name} has been updated.`;
        this.updatedRole = true;
        this.displaySuccessModal = true;
        this.successMessage = 'Successfully updated role!';
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    getPermissions() {
      axios.post('/get-permissions', {}, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        this.rolePermissions = res.data.permissions;
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })

    },
    getRoles() {
      this.loadingRoles = true;
      this.updatedRole = false;
      axios.post(`/get-roles`, {}, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        this.roles = res.data.roles;
        this.loadingRoles = false;
        // PERMISSION ENDPOINT TO BE ADDED, DELETE BELOW WHEN ADDED
        this.getPermissions();
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        this.loadingRoles = false;
        console.log('this is the stocktake error', err.response.data);
      });

    },
    getChannels() {
      let payload = {
        'vertical': 'hospital'
      }
      axios.post('/get-channels', payload, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        console.log('THESE ARE THE CHANNELS', res.data);
        this.notificationChannels = [];
        res.data.forEach(channel => {
          this.notificationChannels.push({
            name: channel,
            selected: false,
            sms: false,
            email: false,
          })
          this.notificationChannels = this.notificationChannels.filter(notificationChannel => !this.filterAgedCareNotifications.includes(notificationChannel.name))
        })
      })
    },
    getRoleChannels(role_id){
      this.getChannels()
      this.displaySuccessModal = true;
      this.successMessage = 'Getting role channels';
      setTimeout(()=>{
        axios.post(`/role/${role_id}/channels`, {}, this.$store.state.header).then(res=>{
        console.log('these are the role channels', res.data.data);
        let roleChannels = res.data.data;
        if(roleChannels.length){
          roleChannels.forEach(item=>{
            this.notificationChannels.forEach(channel=>{
              if(item.name == channel.name){
                channel.selected = true;
                if(item.type == 'email'){
                  channel.email = true;
                }
                if(item.type == 'sms'){
                  channel.sms = true;
                }
              }
            })
          })
        }
        this.displaySuccessModal = false;
      },500)
      
      })
    },
    createNewChannel(){
      this.displayNewChannelModal = true;
    },

    updateRoleChannels() {
      this.displaySuccessModal = true;
      this.successMessage = 'Submitting...';
      this.assignNotificationChannelPayload.channels = [];
      this.notificationChannels.forEach(channel => {
        if (channel.selected) {
          let submitChannel = { ...channel }
          delete submitChannel.selected;
          submitChannel.vertical = 'hospital';
          this.assignNotificationChannelPayload.channels.push(submitChannel);
        }
      })
      console.log('THESE ARE THE SELECTED CHANNELS', this.assignNotificationChannelPayload.channels);
      axios.post('/set-role-channels', this.assignNotificationChannelPayload, this.$store.state.header).then(res => {
        this.$store.dispatch('getRefreshToken');
        console.log('CHANNELS HAVE BEEN SUBMITED', res.data);
        this.successMessage = 'Successfully submitted...';
      }).catch(err => {
        // err.response.data.message
        this.successMessage = err.message;
      })
    },
    retrieveStates() {
      axios.post('/get-states', {}, this.$store.state.header).then(res => {
        this.states = res.data;
        this.$store.dispatch('getRefreshToken');
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    // RETRIEVE DATA RELEVANT TO THE SELECTED PAGE AND EMPTY THE PREVIOUS OBJECTS WHEN REVISITING PAGES
    retrieveRelevantData(type) {
      this.registerPrescriberAddress = {
        street_address: '',
        country: '',
        postcode: '',
        suburb: '',
        city: ''
      }
      switch (type) {
        case 'Edit User':
          this.user = '';
          this.searchPage = 1;
          this.searchUserResults = [];
          this.searchEntry = '';
          this.noResults = false;
          this.displayLoginCreate = false;
          this.registerUserContactInfo.identification = '';
          this.getRoles();
          this.getFlatLocations();
          this.getTimezones();
          this.getPasswordRestrictions();
          break;
        case 'Create/Edit Facilities & Locations':
          this.locationDirectory = '';
          this.noResults = false;
          this.retrieveStates();
          this.retrieveCompanyTree();
          break;
        case 'Edit Role':
          this.roles = [];
          this.getRoles();
          this.noResults = false;
          this.getPermissions();
          break;
        case 'Create Role':
          this.roles = [];
          this.getRoles();
          this.noResults = false;
          this.getPermissions();
          break;
        case 'Register User':
          this.getRoles();
          this.getFlatLocations();
          this.matchesFound = '';
          this.getTimezones();
          this.noMatchFound = true;
          this.displayLoginCreate = false;
          this.noResults = false;
          this.registerUserPersonalInfo = {
            first_name: '',
            last_name: '',
            preferred_name: 'N/A',
            sex: 'N/A',
            dob: ''
          }
          this.registerUserContactInfo = {
            phone_number: '',
            email: '',
            identification: ''
          }
          this.registerUserContactInfo.identification = '';
          break;
        case 'Find User':
          this.searchPage = 1;
          this.searchUser('');
          this.searchEntry = '';
          this.noResults = false;
          this.matchesFound = '';
          this.noMatchFound = true;
          break;
        case 'Basic Configuration':
          this.getFrequency();
          break;
        case 'Register Practice/Prescriber':
          this.selectedPrescriber = '';
          this.displayPrescriberRegistration = false;
          this.selectedPractice = '';
          this.selectedPracticeID = '';
          this.selectedPrescriber = '';
          this.prescriber = '';
          this.practice = '';
          this.retrieveStates();
          this.getFacilities();
          break;
        case 'Edit Prescriber':
          this.displayLoginCreate = false;
          this.noResults = false;
          this.selectedPrescriber = '';
          this.gp = '';
          this.numPrescriberActions = 0;
          this.prescriberActionsCompleted = 0;
          this.registerPrescriberAddress = {
            street_address: '',
            country: '',
            postcode: '',
            suburb: '',
            city: ''
          }
          break;
        case 'Find Prescriber':
          this.displayLoginCreate = false;
          this.noResults = false;
          this.selectedPrescriber = '';
          this.gp = '';
          break;
        case 'Register Pharmacy':
          this.selectedFacility = '';
          this.pharmacyDetails = {
            company_id:null,
            company_name:null,
            contact_methods:[{
              name:'Email',
              data:'',
              type:'email'
            }],
            pharmacy_name:'',
            state_id:'',
            facility_id:'',
            address:{},
            emails:[
              {
                value:'',
                isAdmin:true,
              }
            ]
          }
          this.displayPharmacyRegistration = false;
          this.selectedPharmacyCompany = '';
          this.selectedPharmacyCompanyID = '';
          this.selectedPharmacy = '';
          this.pharmacy = '';
          this.pharmacyCompany = '';
          this.retrieveStates();
          this.getFacilities();
          break;
        case 'Link Facilities':
          this.selectedPharmacy = '';
          this.pharmacy = '';
          this.getFacilities();
          break;
        case 'Searchable Locations':
          this.createNewList = false;
          this.selectedLocations = [];
          this.getLocationFilterLists();
          this.retrieveCompanyTree();
          this.locationDirectory = '';
          this.noResults = false;
          break;
        case 'Assign Notification Channels':
          this.notificationChannels = [];
          this.roles = [];
          this.getRoles();
          this.getChannels();
          this.assignNotificationChannelPayload = {
            channels: [],
            role_id: ''
          }
          break;
        case 'Assign Alert Channels':
          this.notificationChannels = [];
          this.roles = [];
          this.getRoles();
          this.assignNotificationChannelPayload = {
            channels: [],
            role_id: ''
          }
          break;
        case 'Company Details':
          this.retrieveCompanyDetails();
          break;
        case 'Set Admin Round Times':
          this.getFacilities();
          break;
        case 'Set DAA Frequencies':
          this.getFacilities();
          break;
        case 'Set Responsible Person':
          this.getFacilities();
          break;  
        case 'Multi-Factor Authentication':
          // this.retrieveUser({person:{uuid: '5e2e0483-33d9-3ea4-83c5-70bb0e6bd097'}});

          this.getLoggedUser();
          break;
        case 'Account Settings':
          this.password = '';
          this.pinCode = '';
          this.getPasswordRestrictions();
          // this.retrieveUser({person:{uuid: '5e2e0483-33d9-3ea4-83c5-70bb0e6bd097'}});
          this.passwordChange ={
            old_password:'',
            password:'',
            password_confirm:''
          }
          break;
      }
    },
    linkLocations(login) {
      console.log('locations are being linked.');
      let locationLoad = [];
      login.locations.forEach(location => {
        locationLoad.push(location.uuid);
      })
      axios.post('/login/link-locations', { login_id: this.loginToEdit.login_id, locations: locationLoad }, this.$store.state.header).then(res => {
        console.log('this location has been linked', res);
        this.successMessage = `Login account has been updated!`;
        this.$store.dispatch('getRefreshToken');
        this.retrieveUser({ person: { uuid: this.user.uuid } });
        this.displaySuccessModal = true;
        this.reloadPageOnClose = false;
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    prescriberSearch(prescriber) {
      let headers = {
        "X-Algolia-API-Key": 'b7ecebf2d8e997c8e5d567658035de2a',
        "X-Algolia-Application-Id": 'H4AK5A0Y5K'
      }

      console.log('this is the drug', prescriber);
      if (prescriber) {
        axios.post(`https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/query`,
          { params: `query=${prescriber}&hitsPerPage=5` },
          { headers })
        .then(res => {
          console.log('this is the algolia res', res.data.hits);
          this.prescriberResults = res.data.hits;
          // state.commit('setDrugNames', res.data.hits);
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      } else {
        this.prescriberResults = [];
      }

    },
    pharmacySearch(pharmacy) {
      let headers = {
        "X-Algolia-API-Key": 'b7ecebf2d8e997c8e5d567658035de2a',
        "X-Algolia-Application-Id": 'H4AK5A0Y5K'
      }

      console.log('this is the drug', pharmacy);
      if (pharmacy) {
        axios.post(`https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_satellitePharmacies/query`,
          { params: `query=${pharmacy}&hitsPerPage=5&facetFilters=companyID:${this.selectedPharmacyCompanyID}` },
          { headers }).then(res => {
            console.log('this is the algolia res', res.data.hits);
            this.pharmacyResults = res.data.hits;
            console.log('this is the pharmacy results', this.pharmacyResults);
            // state.commit('setDrugNames', res.data.hits);
          }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              this.$router.push('/login');
            }
            console.log('this is the stocktake error', err.response.data);
          })
      } else {
        this.pharmacyResults = [];
      }

    },
    selectPharmacy(pharmacy) {
      console.log('this is the selected result', pharmacy);
      this.pharmacyResults = [];
      this.selectedPharmacy = pharmacy;
    },
    changePharmacy() {
      this.selectedPharmacy = '';
    },
    pharmacyCompanySearch(pharmacyCompany) {
      let headers = {
        "X-Algolia-API-Key": 'b7ecebf2d8e997c8e5d567658035de2a',
        "X-Algolia-Application-Id": 'H4AK5A0Y5K'
      }

      console.log('this is the pharmacy company', pharmacyCompany);
      if (pharmacyCompany) {
        axios.post(`https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_companies/query`,
          { params: `query=${pharmacyCompany}&hitsPerPage=5&facetFilters=type:pharmacy` },
          { headers })
        .then(res => {
            console.log('this is the algolia res', res.data.hits);
            this.pharmacyCompanyResults = res.data.hits;
            console.log('this is the pharmacy company results', this.pharmacyCompanyResults);
          }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              this.$router.push('/login');
            }
            console.log('this is the stocktake error', err.response.data);
          })
      } else {
        this.pharmacyCompanyResults = [];
      }

    },
    selectPharmacyCompany(pharmacyCompany) {
      console.log('this is the selected company result', pharmacyCompany);
      this.pharmacyCompanyResults = [];
      this.selectedPharmacyCompany = pharmacyCompany;
      this.selectedPharmacyCompanyID = this.selectedPharmacyCompany.uuid;
    },
    changePharmacyCompany() {
      this.selectedPharmacyCompany = '';
    },
    linkPrescriber() {
      this.displaySuccessModal = true;
      this.successMessage = 'Linking facility with prescriber...'
      console.log('LINKING PRESCRIBER AND FACILITY', this.selectedPrescriber.uuid);
      axios.post(`/facility/${this.$store.state.currentLocation.facility.uuid}/link-prescriber`, { prescriber_id: this.selectedPrescriber.uuid }, this.$store.state.header).then(res => {
        console.log('facility and prescriber linked', res.data);
        this.successMessage = 'Successfully linked!';
        this.$store.dispatch('getRefreshToken');
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        this.first_spec_line = err.response.data;
        this.second_spec_line = '';
        console.log('this is the error', err.response.data);
      })
    },
    practiceSearch(practice) {
      let headers = {
        "X-Algolia-API-Key": 'b7ecebf2d8e997c8e5d567658035de2a',
        "X-Algolia-Application-Id": 'H4AK5A0Y5K'
      }

      console.log('this is the pharmacy company', practice);
      if (practice) {

        axios.post(`https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_companies/query`,
          { params: `query=${practice}&hitsPerPage=5&facetFilters=type:prescriber` },
          { headers })
          .then(res => {
            console.log('this is the algolia res', res.data.hits);
            this.practiceResults = res.data.hits;
            console.log('this is the pharmacy company results', this.practiceResults);
          }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              this.$router.push('/login');
            }
            this.$store.dispatch('getRefreshToken');
            console.log('this is the stocktake error', err.response.data);
          })
      } else {
        this.practiceResults = [];
      }
    },
    selectPractice(practice) {
      console.log('this is the selected company result', practice);
      this.practiceResults = [];
      this.selectedPractice = practice;
      this.selectedPracticeID = this.selectedPractice.uuid;
    },
    changePractice() {
      this.selectedPractice = '';
    },
    changePrescriber() {
      this.selectedPrescriber = '';
    },
    selectPrescriber(prescriber) {
      console.log('this is the selected result', this.nonGP);
      this.prescriberResults = [];
      this.selectedPrescriber = prescriber;
    },
    displayPage(page) {
      this.searchPage = page;
      this.searchUser(this.searchEntry);
    },
    searchUser(entry) {
      this.loadingUsers = true;
      this.searchUserResults = '';
      axios.post('/user/search', { name: entry, page: this.searchPage }, this.$store.state.header).then(res => {
        this.searchUserResults = res.data.results;
        this.loadingUsers = false;
        this.searchPage = res.data.current_page;
        this.pageCount = res.data.page_count;
        if (this.searchUserResults.length == 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    editUsrFromFind(user) {
      console.log("usr from find")
      this.loadingUsers = true
      this.subView = 'Edit User'
      this.retrieveRelevantData("Edit User")
      this.retrieveUser(user)
      this.searchEntry = user.name
    },
    retrieveUser(login) {
      console.log(login)
      this.userUuid = login.person.uuid;
      this.loadingUser = true;
      axios.post('/user/' + login.person.uuid + '/retrieve', {}, this.$store.state.header).then(res => {
        this.loadingUsers = false
        console.log('this is the retrieved user res', res);
        this.user = res.data.user;
        this.user.uuid = this.userUuid;
        this.user.emails = [];
        this.user.phones = [];
        this.user.contact_methods.forEach(method => {
          if (method.type == 'email') {
            this.user.emails.push(method);
          }
          if (method.type == 'phone') {
            this.user.phones.push(method);
          }
        })
        this.registerUserAddress.address = res.data.user.address;
        this.loginToEdit = this.user.logins[0];
        this.loadingUser = false;
      }).catch(err => {
        console.log('err issss', err, err.response);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })
    },
    createNewUser() {
      const phonetype = this.isMobile ? "phone" : "landline"
      const fullphonenumber = this.selectedPhoneCountry.callingcode.concat(this.registerUserContactInfo.phone_number)
      const phonecheckPayload = {"type": phonetype,"country_code": this.selectedPhoneCountry.code,"phone": fullphonenumber}
      // before signup, FE phone validation check
      axios.post('/validate-phone', phonecheckPayload, this.$store.state.header).then(res => {
              
      this.displayLoginCreate = true;
      this.successMessage = 'Creating User...';

      if (this.matchesFound.length) {
        this.matchesFound.forEach(match => {
          match.selected = false;
        })
      }
      let payload = {
        contact_methods: []
      }
      if (this.registerUserContactInfo.phone_number || this.registerUserContactInfo.email) {


        const phonename = this.isMobile ? "phone" : "landline"
        const fullphonenumber = this.selectedPhoneCountry.callingcode.concat(this.registerUserContactInfo.phone_number)
        if (this.registerUserContactInfo.phone_number) {
          payload.contact_methods.push({
            data: fullphonenumber,
            type: 'phone',
            name: phonename,
            country_code: this.selectedPhoneCountry.code
          })
        }
        if (this.registerUserContactInfo.email) {
          payload.contact_methods.push({
            data: this.registerUserContactInfo.email,
            type: 'email',
            name: 'Email'
          })
        }
        if (this.registerUserContactInfo.fax_number) {
          payload.contact_methods.push({
            data: this.registerUserContactInfo.fax_number,
            type: 'fax',
            name: 'Fax Number'
          })
        }
      }
      let reg;
      if (this.registerUserContactInfo.identification) {
        reg = [
          {
            data: this.registerUserContactInfo.identification,
            regulatory_type: 'Identification Number',
            expiry: '',
          }
        ]
      }

      
      let resId = '';
      axios.post('/person/bundle', this.registerUserPersonalInfo, this.$store.state.header).then((res) => {
        resId = res.data.uuid;
        console.log('this is the person creation res', res);
        if (reg) {
          axios.post(`/person/${resId}/regulatory`, { regulatory_numbers: reg }, this.$store.state.header).then(res => {
            console.log('identification number has been added', res.data)
          })
        }

        if (this.registerPrescriberAddress.street_address) {
          axios.post(`/user/${resId}/address`, this.$registerPrescriberAddress, this.$store.state.header).then(res => {
            console.log('user address has been updated', res);
          }).catch(err => {
            console.log('this is the error for user address', err);
          })
        }

        if (this.registerPrescriberAddress.street_address) {
          this.setUserAddress(res.data.uuid);
        }

        if (payload.contact_methods.length) {
          axios.post('/person/' + res.data.uuid + '/contact-method',
            payload,
            this.$store.state.header
          ).then(response => {
            console.log('the contact method has been uploaded', response);
            this.retrieveUser({ person: { uuid: resId } });
          }).catch(err => {
            this.displaySuccessModal = true;
            this.successMessage = "Failed to add phone number. " +err.response.data;
            console.log('contact method endpoint error:', err);
          })
        } else {
          this.retrieveUser({ person: { uuid: resId } });
        }

        // Create the MIMS user
        this.retrieveCompanyDetails();
        let mimsPayload = {
          salutation: '-',
          first_name: this.registerUserPersonalInfo ? this.registerUserPersonalInfo.first_name : '',
          last_name: this.registerUserPersonalInfo ? this.registerUserPersonalInfo.last_name : '',
          company: this.companyDetails ? this.companyDetails.name : '',
          email: this.registerUserContactInfo ? this.registerUserContactInfo.email : '',
          phone: this.registerUserContactInfo ? this.registerUserContactInfo.phone_number : '',
          reference_id: resId
        }
        console.log('mims payload', mimsPayload);
        axios.post('/mims/create_user', mimsPayload, this.$store.state.header).then(res => {
          console.log('This is the response from mims create user', res.data);
          this.successMessage = 'User has been created!'
        }).catch(err => {
          console.log('/mims/create_user failed with the following error:', err, err.response);
          var mimsErrors = '';
          if (err.response && err.response.data) {
            if (err.response.data.length) {
              err.response.data.forEach(err => {
                mimsErrors = mimsErrors + (err + '; ');
              })
            } else {
              mimsErrors = err.response.data.message;
            }
          }
          this.successMessage = 'User created in Strong Hospital, but creation failed in MIMS. Please edit the user and try again to resolve the following errors: \n' + mimsErrors;
        })

        this.reloadPageOnClose = false;
        this.displaySuccessModal = true;
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    }).catch(err => {
              this.displaySuccessModal = true
              this.successMessage = "Register Failed. Invalid Phone Number"
            console.log('Register Failed. Invalid Phone Number', err);
        })
    },
    submitUser() {
      // this.displayLoginCreate = true;
      this.matchesFound = '';
      let payload = {
        contact_methods: [
          {
            data: this.registerUserContactInfo.phone_number,
            type: 'phone'
          },
          {
            data: this.registerUserContactInfo.email,
            type: 'email'
          }
        ]
      }
      

      if (this.registerUserContactInfo.phone_number && this.registerUserContactInfo.email
        && this.registerUserPersonalInfo.first_name && this.registerUserPersonalInfo.last_name) {
        this.createUserError = '';
        axios.post('/contact-methods/search', payload, this.$store.state.header).then(res => {
          console.log('this is the response', res);
          if (!res.data.length) {
              this.createNewUser()
          } else {
            console.log('there is a match');
            this.matchesFound = res.data;
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the error', err);
        })

      } else if (this.registerUserPersonalInfo.first_name && this.registerUserPersonalInfo.last_name) {
        this.createNewUser()
      } else {
        this.createUserError = 'First name and last name are required to create a user.'
      }
    },
    editUser() {
      this.first_spec_line = 'Editing User...';
      this.loading = true;
      this.displaySuccessModal = true;
      
      let personalPayload = {
        first_name: this.user.first_name,
        last_name: this.user.last_name
      }

      
      if(this.user.hpi_number && this.user.hpi_number.number){
        axios.post(`/validate-hpi`, {prescriber_id: this.user.uuid}, this.$store.state.header).then(res=>{
          console.log('validated hpi', res.data);
        })
        axios.post('/manual-hpi', {prescriber_id: this.user.uuid, hpi_number: this.user.hpi_number.number})
      }
      

      axios.post(`/person/${this.user.uuid}/edit`, personalPayload, this.$store.state.header)
      .then(res => {
        console.log('this is the edit person res', res);
        this.displaySuccessModal = true;
        this.first_spec_line = 'Successfully updated user details!';
        this.editRegulatoryNumbers();
        
        if (this.registerPrescriberAddress.street_address) {
          axios.post(`/person/${this.user.uuid}/address`, this.registerPrescriberAddress, this.$store.state.header).then(res => {
            console.log('user address has been added', res);
          })
        }
        var mimsPhoneNumber = '';
        var mimsEmailAddress = '';
        this.user.contact_methods.forEach(method => {
          if (method.type == 'phone' && mimsPhoneNumber == '') {
            mimsPhoneNumber = method.data;
          } else if (method.type == 'email' && mimsEmailAddress == '') {
            mimsEmailAddress = method.data;
          }
          axios.post(`/person/${this.user.uuid}/contact-method/${method.uuid}/edit`, { data: method.data }, this.$store.state.header)
          .then(res1 => {
            console.log('contact method has been updated', res1);
            this.$store.dispatch('getRefreshToken');
          }).catch(err => {
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              this.$router.push('/login');
            }
            console.log('this is the stocktake error', err.response.data);
          })
        })

        // Edit the MIMS user
        this.retrieveCompanyDetails();
        let mimsPayload = {
          user_id: this.user.uuid,
          salutation: '-',
          first_name: this.user ? this.user.first_name : '',
          last_name: this.user ? this.user.last_name : '',
          company: this.companyDetails ? this.companyDetails.name : '',
          phone: mimsPhoneNumber,
          email: mimsEmailAddress,
          reference_id: this.user.uuid
        }
        console.log('mims payload', mimsPayload);
        axios.post('/mims/edit_user', mimsPayload, this.$store.state.header).then(res => {
          console.log('This is the response from mims edit user', res.data);
          this.first_spec_line = "User successfully updated!"
        }).catch(err => {
          console.log('/mims/edit_user failed with the following error:', err, err.response);
          var mimsErrors = '';
          if (err.response && err.response.data) {
            if (err.response.data.length) {
              err.response.data.forEach(err => {
                mimsErrors = mimsErrors + (err + '; ');
              })
            } else {
              mimsErrors = err.response.data.message;
            }
          }
          this.first_spec_line = 'User edited in Strong Care, but edit failed in MIMS. Please edit the user and try again to resolve the following errors: \n' + mimsErrors;
        })
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the /person/bundle error', err.response.data);
      })
      this.loading = false;
    },
    editRegulatoryNumbers() {
            // this.first_spec_line = 'Updating Regulatory numbers';
            this.loading = true;
            this.displaySuccessModal = true;
            let payload = [];
            this.user.regulatory_numbers.forEach(num => {
                console.log('reg num: ', num);
                if (num.uuid) {
                    axios.post(`/person/${this.user.uuid}/remove-regulatory/${num.uuid}`, {}, this.$store.state.header).then(res => {
                        console.log(res);
                    })
                }
                if (num.data) {
                    if(num.expiry){
                        num.expiry = new Date(num.expiry).toLocaleDateString('en-AU')
                    }
                    payload.push(num);
                }
                delete num.uuid;
                delete num.number;
            })
            if(payload.length){
                axios.post(`/person/${this.user.uuid}/regulatory`, { regulatory_numbers: payload }, this.$store.state.header).then(res => {
                    console.log('updated ent numbers', res);
                    this.loading = false;
                    this.first_spec_line = 'Successfully updated!';
                    this.regulatory_numbers.forEach(number=>{
                        res.data.forEach(resNum=>{
                            if(resNum.regulatory_type == number.regulatory_type){
                                
                                number = resNum;
                                number.uuid = resNum.uuid;
                                console.log('this is the regulatory number uuid', resNum.uuid, number)
                            }
                        })
                    })
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
            
        },

    useExistingUser(user, index) {
      this.user = user;
      this.displayLoginCreate = true;
      this.matchesFound.forEach((match, i) => {
        if (index == i) {
          match.selected = true
        } else {
          match.selected = false
        }
      })
    },
    submitPrescriber() {
      this.numPrescriberActions = 3;
      this.matchesFound = '';
      let uuid = '';
      let contactPayload = [];
      let prescriberFacilities = [];
      if (this.registerPrescriberContactInfo.phone_number || this.registerPrescriberContactInfo.email) {
        this.numPrescriberActions += 1;
        if (this.registerPrescriberContactInfo.phone_number) {
          contactPayload.push({
            data: this.registerPrescriberContactInfo.phone_number,
            type: 'phone',
            name: 'Phone'
          })
        }
        if (this.registerPrescriberContactInfo.email) {
          contactPayload.push({
            data: this.registerPrescriberContactInfo.email,
            type: 'email',
            name: 'Email'
          })
        }
        if (this.registerPrescriberContactInfo.fax_number) {
          contactPayload.push({
            data: this.registerPrescriberContactInfo.fax_number,
            type: 'fax',
            name: 'Fax'
          })
        }
      }
      let personPayload = {
        first_name: this.registerPrescriberInfo.first_name,
        last_name: this.registerPrescriberInfo.last_name,
        preferred_name: 'N/A',
        tag: ['Prescriber'],
        sex: 'N/A',
        dob: 'N/A'
      }
      //will save address
      if (this.registerPrescriberAddress.street_address) {
        this.numPrescriberActions += 1;
      }
      // will be doing a get-hpi request
      if (this.registerPrescriberInfo.prescriber_number) {
        this.numPrescriberActions += 1;
      }
      // check if facilities for prescriber have been selected
      if (this.selectedFacilities && this.selectedFacilities.length) {
        this.numPrescriberActions += 1;
        prescriberFacilities = this.selectedFacilities;
      }
      if (this.registerPrescriberInfo.first_name && this.registerPrescriberInfo.last_name && this.registerPrescriberInfo.prescriber_number) {
        axios.post('/person/bundle', personPayload, this.$store.state.header).then(res => {
          this.prescriberActionsCompleted += 1;
          uuid = res.data.uuid;
          this.selectedPrescriber = { 'uuid': uuid };
          axios.post('/person/' + uuid + '/regulatory', {
            regulatory_numbers:
              [
                {
                  data: this.registerPrescriberInfo.prescriber_number,
                  regulatory_type: 'Prescriber Number',
                  expiry: ''
                }
              ]
          },
            this.$store.state.header
          ).then(() => {
            this.prescriberActionsCompleted += 1;
            axios.post('/sync-prescriber', { 'uuid': uuid }, this.$store.state.header).then(res1 => {
              console.log('sync prescriber', res1);
              this.prescriberActionsCompleted += 1;
            })
            // .then(()=>{
            // axios.post('/ihi/get-hpi', {
            //   last_name: this.registerPrescriberInfo.last_name,
            //   registration_id: this.registerPrescriberInfo.prescriber_number
            // }, this.$store.state.header).then(res=>{
            //   this.prescriberActionsCompleted +=1;
            //   console.log('this is the response from hpi', res)
            // })
            // })

            // ADD PRESCRIBER CONTACT METHODS
            if (contactPayload.length) {
              axios.post('/person/' + res.data.uuid + '/contact-method',
                { contact_methods: contactPayload },
                this.$store.state.header
              ).then(response => {
                console.log('the contact method has been uploaded', response);
                this.prescriberActionsCompleted += 1;
                this.setPrescriberAddress(uuid);
              }).catch(err => {
                console.log('contact method endpoint error:', err);
              })
            }

            // LINK PRESCRIBER FACILITIES
            if (prescriberFacilities.length) {
              axios.post(`/prescriber/${uuid}/facilities`, { facilities: prescriberFacilities }, this.$store.state.header).then(res => {
                console.log('facilities have been linked to prescriber', res);
              })(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                  this.$router.push('/login');
                }
                console.log('this is the error', err.response.data);
              })
            }

          })
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }
      // else if(!this.registerPrescriberInfo.first_name && !this.registerPrescriberInfo.last_name && this.registerPrescriberInfo.prescriber_number){
      //   axios.post('/person', {}, this.$store.state.header).then((res)=>{
      //     uuid = res.data.uuid;
      //     axios.post('/person/'+uuid+'/regulatory', {regulatory_numbers:
      //               [
      //                 {number: this.registerPrescriberInfo.prescriber_number,
      //                 regulatory_type: 'Prescriber Number',
      //                 expiry: ''}
      //               ]},
      //               this.$store.state.header
      //               ).then(()=>{
      //                   if(contactPayload.length){
      //                     axios.post('/person/'+res.data.uuid+'/contact-method',
      //                       contactPayload,
      //                       this.$store.state.header
      //                     ).then(response=>{
      //                       console.log('the contact method has been uploaded', response);
      //                     }).catch(err=>{
      //                       console.log('contact method endpoint error:', err);
      //                     })
      //                   }
      //               })
      //           })
      // }
      else {
        this.createUserError = 'Prescriber number, first name and last name are required to create a user.'
      }
    },
    editPrescriber() {
      this.numPrescriberActions = 0;
      this.prescriberActionsCompleted = 0;
      let personPayload = {
        first_name: this.selectedPrescriber.first_name,
        last_name: this.selectedPrescriber.last_name,
        preferred_name: 'N/A',
        tag: ['Prescriber'],
        sex: 'N/A',
        dob: ''
      };
      let contactPayload = [];

      if (this.selectedPrescriber.phone_number || this.selectedPrescriber.email) {
        this.numPrescriberActions += 1;

        if (this.selectedPrescriber.phone_number) {
          contactPayload.push({
            data: this.selectedPrescriber.phone_number,
            type: 'phone',
            name: 'Phone'
          })
        }
        if (this.selectedPrescriber.email) {
          contactPayload.push({
            data: this.selectedPrescriber.email,
            type: 'email',
            name: 'Email'
          })
        }
        if (this.selectedPrescriber.fax_number) {
          contactPayload.push({
            data: this.selectedPrescriber.fax_number,
            type: 'fax',
            name: 'Fax'
          })
        }
      }

      let regNums = {
        regulatory_numbers:
          [
            {
              data: this.selectedPrescriber.prescriber_no,
              regulatory_type: 'Prescriber Number',
              expiry: ''
            }
          ]
      }
      if (this.registerPrescriberAddress.street_address) {
        this.numPrescriberActions += 1;
      }
      if (this.selectedPrescriber.first_name && this.selectedPrescriber.last_name && this.selectedPrescriber.prescriber_no) {

        this.numPrescriberActions += 3;
        axios.post(`/person/${this.selectedPrescriber.uuid}/edit`, personPayload, this.$store.state.header).then(res => {
          console.log('this is the res', res);
          this.prescriberActionsCompleted += 1;
          axios.post('/person/' + this.selectedPrescriber.uuid + '/remove-regulatory/' + this.selectedPrescriber.prescriber_no_uuid, {}, this.$store.state.header).then(() => {
            this.prescriberActionsCompleted += 1;
          });
          axios.post('/person/' + this.selectedPrescriber.uuid + '/regulatory', regNums,
            this.$store.state.header
          ).then(() => {
            this.prescriberActionsCompleted += 1;
            if (contactPayload.length) {
              axios.post('/person/' + res.data.uuid + '/contact-method',
                { 'contact_methods': contactPayload },
                this.$store.state.header
              ).then(response => {
                this.prescriberActionsCompleted += 1;
                console.log('the contact method has been uploaded', response);
                this.setPrescriberAddress(this.selectedPrescriber.uuid);
              }).catch(err => {
                console.log('contact method endpoint error:', err);
              })
            }
          })
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }
    },
    getUserAddressData(addressData, propertyData, id) {

      this.registerUserAddress.state = addressData.administrative_area_level_1;
      this.registerUserAddress.city = addressData.administrative_area_level_2;
      this.registerUserAddress.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
      this.registerUserAddress.country = addressData.country;
      this.registerUserAddress.postcode = addressData.postal_code;
      this.registerUserAddress.suburb = addressData.locality;
      console.log('this is the address now', addressData, propertyData, this.registerUserAddress);
    },
    getPersonalAddressData(addressData, propertyData, id) {

      this.registerPrescriberAddress.state = addressData.administrative_area_level_1;
      this.registerPrescriberAddress.city = addressData.administrative_area_level_2;
      this.registerPrescriberAddress.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
      this.registerPrescriberAddress.country = addressData.country;
      this.registerPrescriberAddress.postcode = addressData.postal_code;
      this.registerPrescriberAddress.suburb = addressData.locality;
      console.log('this is the address now', addressData, propertyData, this.registerPrescriberAddress);
    },
    getPharmacyAddressData(addressData, propertyData, id) {

      this.pharmacyDetails.address.state = addressData.administrative_area_level_1;
      this.pharmacyDetails.address.city = addressData.administrative_area_level_2;
      this.pharmacyDetails.address.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
      this.pharmacyDetails.address.country = addressData.country;
      this.pharmacyDetails.address.postcode = addressData.postal_code;
      this.pharmacyDetails.address.suburb = addressData.locality;
      console.log('this is the address now', addressData, propertyData, this.pharmacyDetails.address);
    },
    getPrescriberAddressData(addressData, propertyData, id) {

      this.prescriberDetails.address.state = addressData.administrative_area_level_1;
      this.prescriberDetails.address.city = addressData.administrative_area_level_2;
      this.prescriberDetails.address.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
      this.prescriberDetails.address.country = addressData.country;
      this.prescriberDetails.address.postcode = addressData.postal_code;
      this.prescriberDetails.address.suburb = addressData.locality;
      console.log('this is the address now', addressData, propertyData, this.prescriberDetails.address);
    },
    setFacilityAddress(id) {
      if (this.registerPrescriberAddress.street_address) {
        axios.post(`/facility/${id}/address`, this.registerPrescriberAddress, this.$store.state.header).then(res => {
          console.log('patient address submitted.', res);
          this.facility_address_id = res.data.uuid;
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the facility address error', err.response.data);
        })
      } else {
        console.log('no address has been added or submitted');
      }
    },
    setPrescriberAddress() {
      if (this.registerPrescriberAddress.street_address) {
        this.prescriberActionsCompleted += 1;
        axios.post(`/person/${this.selectedPrescriber.uuid}/address`, this.registerPrescriberAddress, this.$store.state.header).then(res => {
          console.log('patient address submitted.', res);
          axios.post('/sync-prescriber', { 'uuid': this.selectedPrescriber.uuid }, this.$store.state.header).then(res1 => {
            console.log('sync prescriber', res1);
            this.prescriberActionsCompleted += 1;
          })
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the prescriber address error', err.response.data);
        })
      } else {
        console.log('no address has been added or submitted');
      }
    },
    setUserAddress(id) {
      if (this.registerPrescriberAddress.street_address) {
        axios.post(`/person/${id}/address`, this.registerPrescriberAddress, this.$store.state.header).then(res => {
          console.log('user address submitted.', res);
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the user address error', err.response.data);
        })
      } else {
        console.log('no address has been added or submitted');
      }
    },
    editLogin() {
      let payload;
      let emailId = '';
      let phoneId = '';
      if(this.loginToEdit.email_id){
        emailId = this.loginToEdit.email_id.uuid
      } else if(this.user.emails.length && this.loginToEdit.email){
        this.user.emails.forEach(emailObject=>{
          if(emailObject.data == this.loginToEdit.email){
            emailId = emailObject.uuid;
            return;
          }
        })
      }
      //user has new selected phone number and we will use that
      if(this.loginToEdit.phone_id){
        phoneId = this.loginToEdit.phone_id.uuid
      } else if(this.user.phones.length && this.loginToEdit.phone){ // or the user has left it blank and we will use current one
        this.user.phones.forEach(phoneObject=>{
          if(phoneObject.data == this.loginToEdit.phone){
            phoneId = phoneObject.uuid; // find uuid from just the phone number in loginToEdit
            return;
          }
        })
      }

      payload = {
        uuid: this.loginToEdit.login_id,
        role: this.loginToEdit.roles[0].uuid,
        timezone: this.loginToEdit.timezone,
        type: this.loginToEdit.type,
        email_id: emailId,
        phone_id: phoneId
      }

      if(payload){
        axios.post('/login/edit', payload, this.$store.state.header).then(res => {
          console.log('login has been edited', res);
          this.linkLocations(this.loginToEdit);
        }).catch(err => {
          console.log('err issss', typeof err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
          console.log('err is', err.response.data, err.response.data);
        })
      }
      

    },
    editPersonalPassword() {
      this.displaySuccessModal = true;
      this.successMessage = 'Updating password...'
      axios.post('/reset-password/account', this.passwordChange, this.$store.state.header).then(res => {
        console.log('login has been edited', res);
        this.retrieveUser({ person: { uuid: this.user.uuid } });
        this.editLoginPassword = false;
        this.resetPassword = false;
        this.successMessage = 'Password updated!'
        this.passwordChange = {
          old_password: '',
          password:'',
          password_confirm: ''
        }
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        this.successMessage = err.response.data.errors ? (err.response.data.errors && err.response.data.errors.password ? err.response.data.errors.password[0] : err.response.data.message) : err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data);
      })
    },
    deactivateAccount() {
      if (!this.loadingDeactivateAccount) {
        this.loadingDeactivateAccount = true;

        let user_id = JSON.parse(localStorage.getItem('user_id'));
        axios.post('/login/deactivate', { user_id: user_id }, this.$store.state.header)
        .then(res => {
          console.log('login has been deactivated', res);
          this.loadingDeactivateAccount = false;
          // Take the user back to the login page
          this.$router.push('/login');
        })
        .catch(err => {
          this.loadingDeactivateAccount = false;
          console.log('deactivate account err is', err.response, err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          } else {
            this.deactivateAccountErrorMessage = err.response.data;
          }
        })
      }
    },
    editLoginPassword() {
      this.displaySuccessModal = true;
      this.successMessage = 'Updating password...'
      axios.post('/login/password-reset', {login_id: this.loginToEdit.login_id, password: this.loginToEdit.new_password}, this.$store.state.header).then(res => {
        console.log('login has been edited', res);
        this.retrieveUser({ person: { uuid: this.user.uuid } });
        this.editLoginPassword = false;
        this.resetPassword = false;
        this.successMessage = 'Password updated!'
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        this.successMessage = err.response.data.errors ? (err.response.data.errors && err.response.data.errors.password ? err.response.data.errors.password[0] : err.response.data.message) : err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data);
      })
    },
    validatePassword(password, username) {
      const checkIfNameInPassword = new RegExp("/^" + username + "/g");
      const reg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/g);
      console.log('this is the username in validatePassord', username);
      this.passwordWarning = `${reg.test(password)}, also username check ${checkIfNameInPassword.test(password)}`;
    },
    getFlatLocations() {
      axios.post('/location/search', { location_type: '', name: '' }, this.$store.state.header).then(res => {
        this.flatLocations = res.data.locations
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    getTimezones() {
      axios.post('/get-timezones', {}, this.$store.state.header).then(res => {
        this.timezones = res.data;
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    addPhoneToUser(instance) {
      const phonename = this.isMobile ? "phone" : "landline"
      const fullphonenumber = this.selectedPhoneCountry.callingcode.concat(this.registerUserContactInfo.phone_number)
      this.newPhone.name = phonename
      this.newPhone.data = fullphonenumber
      this.newPhone.country_code = this.selectedPhoneCountry.code
      this.newPhone.type = 'phone'

      axios.post(`/person/${this.user.uuid}/contact-method`, { contact_methods: [this.newPhone] }, this.$store.state.header).then(res => {
        console.log('added phone to user', res.data)
        this.newPhone = {
          name: 'phone',
          data: '',
          type: 'phone'
        }
        if (instance == 'MFA') {
          this.getLoggedUser()
        } else {
          this.retrieveUser({ person: { uuid: this.user.uuid } });
        }

      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        this.displaySuccessModal = true;
        this.successMessage = "Failed to add phone number. " +err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    addEmailToUser(instance) {
      axios.post(`/person/${this.user.uuid}/contact-method`, { contact_methods: [this.newEmail] }, this.$store.state.header).then(res => {
        console.log('added email to user', res.data)
        this.newEmail = {
          name: 'email',
          data: '',
          type: 'email'
        }
        if (instance == 'MFA') {
          this.getLoggedUser()
        } else {
          this.retrieveUser({ person: { uuid: this.user.uuid } });
        }

      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        this.displaySuccessModal = true;
        this.successMessage = "Failed to add email address. " +err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    },
    getLocationFilterLists() {
      axios.post('/get-filter-lists', {}, this.$store.state.header).then(res => {
        this.loadedfilterlist = true;
        this.searchableFilters = res.data;
        this.searchableFilters.forEach(item => {
          if (item.active) {
            this.selectedSearchableFilter = item.uuid;
          }
        })
        this.selectList()
        this.loadingLocations = false;
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })

    },
    selectList() {
      // this.retrieveCompanyTree();
      this.selectedLocations = [];
      // DELAYING GET FILTER TO SEQUENCE ACTIONS IN CLOUD
      setTimeout(() => {
        axios.post('/get-filter-list', { uuid: this.selectedSearchableFilter }, this.$store.state.header).then(res => {
          this.selectedSearchableFilterDetails = res.data;
        }).then(() => {

          if (this.locationDirectory) {
            console.log('RUNNING THROUGH LOCATION DIRECTORY LOCATIONS');
            this.locationDirectory.forEach(location => {

              this.seeIfLocationIsInFilterListAndMapIt(location);
            })
          }

        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }, 500)


    },
    // recursive function
    seeIfLocationIsInFilterListAndMapIt(location) {
      location.is_searched = false;
      // console.log('this is coming through location', location, this.selectedSearchableFilterDetails.locations);
      this.selectedSearchableFilterDetails.locations.forEach(loc => {
        if (location.key == loc.uuid) {
          location.is_searched = true;
          this.selectedLocations.push(location.key);
        }
      })
      if (location.children.length) {
        // console.log('this is coming through child recursive', location.children);
        location.children.forEach(child => {
          this.seeIfLocationIsInFilterListAndMapIt(child);
        })
      }
    },
    addOrRemoveLoc(loc) {
      console.log('this is the id selected', loc.key);
      console.log('THESE ARE THE SELECTED LOCATIONS', this.selectedLocations);
      if (this.selectedLocations.includes(loc.key)) {
        this.selectedLocations.splice(this.selectedLocations.indexOf(loc.key), 1);
        console.log('this is the updated selected Locations list if', this.selectedLocations);
        loc.is_searched = false;
      } else {
        this.selectedLocations.push(loc.key);
        console.log('this is the updated selected Locations list else', this.selectedLocations);
        loc.is_searched = true;
      }

      if (loc.children.length) {
        console.log('hitting the child recursive', loc);
        loc.children.forEach(childloc => {
          this.childAddOrRemoveLoc(childloc, loc.is_searched);
        })
      }
    },
    childAddOrRemoveLoc(loc, bool) {
      if (this.selectedLocations.includes(loc.key)) {
        this.selectedLocations.splice(this.selectedLocations.indexOf(loc.key), 1);
        console.log('this is the updated selected Locations list if', this.selectedLocations);
        loc.is_searched = bool;
      } else {
        this.selectedLocations.push(loc.key);
        console.log('this is the updated selected Locations list else', this.selectedLocations);
        loc.is_searched = bool;
      }
      if (loc.children.length) {
        console.log('hitting the child recursive', loc);
        loc.children.forEach(childloc => {
          this.childAddOrRemoveLoc(childloc, bool);
        })
      }
    },
    activateList(id) {
      this.loadingLocations = true;
      axios.post('/activate-filter-list', { uuid: id }, this.$store.state.header).then(res => {
        console.log('this list is activated now', res.data);
        this.getLocationFilterLists();
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    createList() {
      this.tableloading = true
      let payload = {
        name: this.newList,
        active: true,
        locations: this.selectedLocations
      };
      axios.post('/create-filter-list', payload, this.$store.state.header).then(res => {
        this.displaySuccessModal = true;
        this.successMessage = "New List Created.";
        console.log('list has been created', res);
        this.tableloading = false
        this.getLocationFilterLists();
        this.newList = '';
        this.createNewList = false;
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    updateFilterList() {
      let payload = {
        uuid: this.selectedSearchableFilterDetails.uuid,
        name: this.selectedSearchableFilterDetails.name,
        active: true,
        locations: this.selectedLocations
      };
      axios.post('/update-filter-list', payload, this.$store.state.header).then(res => {
        this.displaySuccessModal = true;
        this.successMessage = "Filter List Updated.";
        console.log('filter list has been updated', res);
        this.activateList(payload.uuid);
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    // COMPANY DETAILS
    retrieveCompanyDetails() {
      this.loadingCompanyDetails = true;
      this.companyDetails = {
        address: '',
        contact_methods: [],
        name: JSON.parse(localStorage.getItem('company')).name
      }
      axios.post(`/company/${JSON.parse(localStorage.getItem('company')).uuid}/retrieve`, {}, this.$store.state.header).then(res => {
        this.loadingCompanyDetails = false;
        console.log('this is the res', res.data);
        this.companyDetails = {
          address: res.data.address,
          name: res.data.name,
        }
        res.data.contact_methods.forEach(method => {
          switch (method.type) {
            case 'name':
              this.companyDetails.contact = method.data;
              this.companyDetails.contact_id = method.uuid;
              break;
            case 'phone':
              this.companyDetails.phone_number = method.data;
              this.companyDetails.phone_number_id = method.uuid;
              break;
            case 'email':
              this.companyDetails.email = method.data;
              this.companyDetails.email_id = method.uuid;
              break;
          }
        })
      }).catch(err => {
        if (err.response & err.response.data & err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        } else {
          console.log('this is the error', err, err.response.data);
        }
      })
    },
    updateCompanyDetails() {
      this.setCompanyAddress();
      let payload = { contact_methods: [] }
      this.displaySuccessModal = true;
      this.loadingCompanytot = 2;
      this.loadingCompanycurr = 0;
      this.successMessage = 'Updating company details...';

      if (this.companyDetails.phone_number_id) {
        this.loadingCompanytot += 1;
      }
      if (this.registerPrescriberAddress.street_address) {
        this.loadingCompanytot += 1;
      }
      if (!this.companyDetails.contact_id) {
        payload.contact_methods.push({
          name: 'Contact Name',
          type: 'name',
          data: this.companyDetails.contact,
        })
      } else {
        axios.post(`/company/${JSON.parse(localStorage.getItem('company')).uuid}/contact-method/${this.companyDetails.contact_id}/edit`, { data: this.companyDetails.contact }, this.$store.state.header).then(res => {
          console.log('company contact method upated.', res);
          this.loadingCompanycurr += 1;
          this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
          if (this.loadingCompanytot == this.loadingCompanycurr) {
            this.successMessage = 'Update completed.'
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }

      if (!this.companyDetails.phone_number_id) {
        payload.contact_methods.push({
          name: 'Phone Number',
          type: 'phone',
          data: this.companyDetails.phone_number,
        })
      } else {
        axios.post(`/company/${JSON.parse(localStorage.getItem('company')).uuid}/contact-method/${this.companyDetails.phone_number_id}/edit`, { data: this.companyDetails.phone_number }, this.$store.state.header).then(res => {
          console.log('company contact method upated.', res);
          this.loadingCompanycurr += 1;
          this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
          if (this.loadingCompanytot == this.loadingCompanycurr) {
            this.successMessage = 'Edit Completed.';
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }

      if (!this.companyDetails.email_id) {
        payload.contact_methods.push({
          name: 'Email',
          type: 'email',
          data: this.companyDetails.email,
        })
      } else {
        axios.post(`/company/${JSON.parse(localStorage.getItem('company')).uuid}/contact-method/${this.companyDetails.email_id}/edit`, { data: this.companyDetails.email }, this.$store.state.header).then(res => {
          console.log('company contact method upated.', res);
          this.loadingCompanycurr += 1;
          this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
          if (this.loadingCompanytot == this.loadingCompanycurr) {
            this.successMessage = 'Edit Completed.'
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }

      if (payload.contact_methods.length) {
        axios.post(`/company/${JSON.parse(localStorage.getItem('company')).uuid}/contact-method`, payload, this.$store.state.header).then(res => {
          console.log('company contact methods submitted.', res);
          this.loadingCompanycurr += 1;
          this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
          if (this.loadingCompanytot == this.loadingCompanycurr) {
            this.successMessage = 'Edit Completed.'
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      }

      this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
    },
    setCompanyAddress() {
      if (this.registerPrescriberAddress.street_address) {
        axios.post(`/company/${JSON.parse(localStorage.getItem('company')).uuid}/address`, this.registerPrescriberAddress, this.$store.state.header).then(res => {
          console.log('company address submitted.', res);
          this.loadingCompanycurr += 1;
          this.first_spec_line = 'Updating ' + this.loadingCompanycurr + '/' + this.loadingCompanytot;
          if (this.loadingCompanytot == this.loadingCompanycurr) {
            this.successMessage = 'Edit Completed.'
          }
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            this.$router.push('/login');
          }
          console.log('this is the stocktake error', err.response.data);
        })
      } else {
        console.log('no address has been added or submitted');
      }
    },
    getLoggedUser() {
      axios.post('/me', {}, this.$store.state.header).then(res => {
        this.user = res.data;
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    updateCompanyMFAStatus() {
      axios.post('/company-settings', {
        company_mfa: this.enableCompanyMFA
      }, this.$store.state.header).then(res => {
        console.log('has been updated', res);
      }).catch(err => {
        this.mailCodeError = err.response.data;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the stocktake error', err.response.data);
      })
    },
    getPasswordRestrictions() {
      axios.post('/get-password-restrictions', {}, this.$store.state.header).then(res => {
          console.log("this is the data: " + res.data);
          this.passwordRestrictions = res.data;
      }).catch(err => {
          this.errorMessage = err.response.data;
          console.log('this is the err', err, err.response.data);
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              this.$router.push('/login');
          }
      })
    },
    getFacilities() {
      axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
        this.facilities = res.data;
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
        console.log('err is', err.response.data, err.response.data);
      })
    }
    // setUserAddress(){
    //         if(this.address.street_address){
    //             axios.post(`/person/${this.patientUUID}/address`, this.address, this.$store.state.header).then(res=>{
    //                 this.completedActions +=1;
    //                 console.log('patient address submitted.', res)
    //              }).catch(err=>{
    //                     this.completedActions +=1;
    //                     // Keeping tab on error count for possibly more detailed error management
    //                     this.errActionsCount +=1;
    //                     this.errActions += ' Error adding address..'
    //                     console.log('this is the address error', err, err.response.data);
    //                 })
    //         } else{
    //             console.log('no address has been added or submitted');
    //         }

    //     },
  },
  mounted() {


    console.log('this is the blind count state: ', this.$store.state.blind_count_settings);
    if (this.locationDirectory.location && this.locationDirectory.location.child_locations) {
      console.log('looping through directory now')
      this.locationDirectory.location.child_locations.forEach(item => {
        item.expand = false;
      })
    }

    if(this.$store.state.blind_count_settings){
      this.enableBlindCount = true
    } else{
      this.enableBlindCount = false;
    }

    
    // if(this.$store.state.userType == 'prescriber' || this.$store.state.userType == 'pharmacy' || this.$store.state.userType == 'pharmacist') {
    //   if(this.$store.state.userType == 'prescriber'){
    //     this.directory = [
    //       {
    //         name: 'Users',
    //         open: true,
    //         directory: [
    //           'Register User',
    //           'Edit User',
    //           'Find User'
    //         ]
    //       },
    //       {
    //         name:'Organisation',
    //         open: true,
    //         directory: [
    //           'Create/Edit Facilities & Locations',
    //           'Company Details',
    //           'Medications List',
    //           'Password Configuration'
    //         ]
    //       },
    //       {
    //         name: 'Roles',
    //         open: true,
    //         directory: [
    //           'Create Role',
    //           'Edit Role'
    //         ]
    //       },
    //       {
    //         name: 'Notifications & Alerts',
    //         open: true,
    //         directory: [
    //           'Assign Notification Channels',
    //           'Assign Alert Channels'
    //         ]
    //       },
    //       {
    //         name: 'Personal Settings',
    //         open: true,
    //         directory: [
    //           'Multi-Factor Authentication',
    //           'Account Settings'
    //         ]
    //       },
    //     ]
    //   } else{
    //     this.directory = [
    //       {
    //         name: 'Users',
    //         open: true,
    //         directory: [
    //           'Register User',
    //           'Edit User',
    //           'Find User'
    //         ]
    //       },
    //       {
    //         name:'Organisation',
    //         open: true,
    //         directory: [
    //           'Create/Edit Facilities & Locations',
    //           'Company Details',
    //           'Medications List',
    //           'Password Configuration'
    //         ]
    //       },
    //       {
    //         name: 'Roles',
    //         open: true,
    //         directory: [
    //           'Create Role',
    //           'Edit Role'
    //         ]
    //       },
    //       {
    //         name: 'Notifications',
    //         open: true,
    //         directory: [
    //           'Assign Notification Channels',
    //         ]
    //       },
    //       {
    //         name: 'Personal Settings',
    //         open: true,
    //         directory: [
    //           'Multi-Factor Authentication',
    //         ]
    //       },
    //     ]
    //   }
      
    //   this.selectedView =  'Organisation';
    //   this.subView =  'Company Details';
    // }
    // if(!this.$store.state.user.patient_admin_active){
    //   this.directory = [
    //     {
    //       name: 'General',
    //       open: true,
    //       directory: [
    //         'Basic Configuration'
    //       ],
    //       permissions: [
    //         'view_admin',
    //       ]
    //     },
    //     {
    //       name: 'Users',
    //       open: true,
    //       directory: [
    //         'Register User',
    //         'Edit User',
    //         'Find User'
    //       ]
    //     },
    //     {
    //       name:'Organisation',
    //       open: true,
    //       directory: [
    //         'Create/Edit Facilities & Locations',
    //         'Company Details',
    //         'Medications List',
    //         'Password Configuration',
    //       ]
    //     },
    //     {
    //       name: 'Roles',
    //       open: true,
    //       directory: [
    //         'Create Role',
    //         'Edit Role'
    //       ]
    //     },
    //     // {
    //     //   name: 'Notifications',
    //     //   open: true,
    //     //   directory: [
    //     //     'Assign Notification Channels'
    //     //   ]
    //     // },
    //     {
    //       name: 'Patient Search',
    //       open: true,
    //       directory: [
    //         'Searchable Locations'
    //       ]
    //     },
    //     {
    //       name: 'Personal Settings',
    //       open: true,
    //       directory: [
    //         'Multi-Factor Authentication',
    //         'Account Settings'
    //       ]
    //     },
    //   ]
    // }
    console.log('this was the previous route', localStorage.getItem('prev') == "/login", localStorage.getItem('prev').indexOf('login'));

    if (localStorage.getItem('prev').indexOf('login') > 0 && localStorage.getItem('direct_to_mfa_settings') == 'true') {
      this.subView = 'Multi-Factor Authentication';
      this.displayMFAWarning = true;
      this.enableCompanyMFA = true;
      console.log('this is from the login');
      this.getMFA();
    } else {
      console.log('this is not from the login', localStorage.getItem('direct_to_mfa_settings'));
    }
    this.getFrequency();
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.settings-directory {
  padding: 10px 20px;
  border-radius: 12px;
  height: 84vh;
  overflow-y: scroll;

  h4,
  p {
    text-align: left;
    font-size: 1em;
    margin: 6px 0;
    cursor: pointer;
    padding: 7px 20px;
  }
  h4{
    display: flex;
    justify-content: space-between;
  }

}

.panel.left-side {
  background-color: #f9f9f9;
  box-shadow: 0 0 5px -2px #9b9b9b;
  border-radius: 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

}

.settings-panel.panel {
  min-width: 50em;
  width: 80.5vw;
  background-color: white;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid #e9e9e9;
  box-shadow: 2px 0 5px -2px #9b9b9b;
  h2.panel-header{
    padding-left: 0 !important;
    color: $strongblue;
  }
}


.settings-subdirectory {
  margin-left: 50px;
  p {
    // padding: 5px 0px 5px 10px;
    text-align: left;
    font-size: 15px;
    margin: 6px 0;
    cursor: pointer;
    padding: 7px 20px;
    background-color: white;
    border-radius: 6px;
    font-weight: bold;
  }
}
.active-subdirectory-item{
  background-color: $strongblue !important;
  color: white;

}

.p-field-radiobutton {
  display: flex;
  align-items: left;
  margin: 5px 0;

  label {
    padding-left: 10px;
  }
}

.location-tree {
  background-color: white;
  padding: 20px;

  p {
    text-align: left;
  }

  tbody {
    height: fit-content;
  }
}

.location-name {
  display: inline;
  // padding: 5px 0;
}

.location-entry {
  // padding: 2px 0;
  margin-left: 20px;
}

.location-new-entry {
  display: none;
}

.pi-plus-circle {
  cursor: pointer;
}



@keyframes fade {
  0% {
    color: black;

  }

  100% {
    color: white;
    background-color: green;
  }
}

.pulse-fade {
  border-radius: 12px;
}

.pulse-fade:active {
  animation: 0.3s fade;
}

.new-loc-cell {
  display: flex;
  align-items: center;
}

.p-field-checkbox {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.success-linked-locations {
  animation: 0.5s success;
}
// .p-password-panel{
//   border: 1px solid grey !important;
// }
input.p-password-input{
  background-color: #F3F4F4;
}

@keyframes success {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

.input-icon {
  background-color: #F3F4F4;
  height: 35px;
  padding-top: 12px !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.iconned-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
