<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 90%; max-height: 90vh;">
                <div class="modal-header">
                    <h3 class="form-header" style="margin-left: 1em">Patient Summary</h3>
                    <button @click.stop="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <div class="action-modal"
                            style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;">
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container patient-summary-block"
                                style="grid-template-columns: 1fr;   margin: 0 20px;">
                                <div v-if="!patient">
                                    <i class="pi pi-spin pi-spinner"
                                        style="font-size: 2rem !important;margin-top: 2em;"></i>
                                </div>
                                <div v-else style="margin-left: 1em">
                                    <div style="display: flex; flex-wrap: wrap">
                                        <div style="margin-top: 10px;display: flex;">
                                            <div style="height: fit-content; border-radius: 12px; ">
                                                <!-- <h3 class="general-patient-name" style="border-radius: 12px 12px 0px 0px;">{{patient.personal_information.first_name}} {{patient.personal_information.last_name}}</h3> -->
                                                <img :src="patient.personal_information.image" alt="patient-image"
                                                    style="max-height: 150px; box-shadow: 0 0 5px rgba(0,0,0,0.05)">
                                            </div>
                                        </div>
                                        <div
                                            style="display: flex; flex-wrap: wrap; flex-direction: column; padding-bottom: 0px; width: 65%;">
                                            <h4
                                                style="padding-left: 10px; text-align: left; margin: 0; margin-top: 10px; margin-left: 10px;">
                                                {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</h4>
                                            <div
                                                style="display: flex; flex-wrap: wrap; padding: 3px 10px; width: 100%; justify-content: space-between">
                                                <div style="width: 30%;">
                                                    <p class="field-title">Date of birth</p>
                                                    <p class="field-value">{{ patient.personal_information.dob }}</p>
                                                </div>
                                                <div>
                                                    <p class="field-title">Age</p>
                                                    <p class="field-value">{{ patient.personal_information.age }}</p>
                                                </div>
                                                <div>
                                                    <p class="field-title">Sex</p>
                                                    <p class="field-value" style='width: 5em'>
                                                        {{ patient.personal_information.sex }}</p>
                                                </div>
                                                <div style="width: 30%;">
                                                    <p class="field-title">Location</p>
                                                    <p v-if="patient.physical_location && patient.physical_location.location && patient.physical_location.location.name" class="field-value"
                                                        style="overflow-x: scroll">{{ patient.physical_location.location.name
                                                        }}
                                                    </p>
                                                    <p class="field-value" v-else>N/A</p>
                                                </div>
                                            </div>
                                            <div
                                                style="display: flex; flex-wrap: wrap; padding: 3px 10px; width: 100%; max-height: 120px; overflow: scroll;">
                                                <div style="width: 30%; margin-right: 10px;"
                                                    v-for="(item, index) in patient.regulatory_information"
                                                    :key="index">

                                                    <p class="field-title" v-if="item.regulatory_type == 'Medicare Number' || item.regulatory_type == 'Pharmaceutical Entitlement Number' || item.regulatory_type == 'Safety Net Number'">{{ item.regulatory_type == 'Pharmaceutical Entitlement Number' ? 'Pharm.Ent.Number' :
                                                            item.regulatory_type == 'Safety Net Number 2' ? 'Safety Net Num 2': item.regulatory_type
                                                    }}</p>
                                                    <p class="field-value" style='width: 100%' v-if="item.regulatory_type == 'Medicare Number' || item.regulatory_type == 'Pharmaceutical Entitlement Number' || item.regulatory_type == 'Safety Net Number'">{{ item.data }}</p>
                                                </div>
                                                <!-- <div style="width: 33%;">
                                            <p class="field-title">RAC ID</p>
                                            <p class="field-value" style='width: 100%'>{{patient.entitlement_numbers.rac_id}}</p>
                                        </div>
                                        <div style="width: 33%;">
                                            <p class="field-title">MRN</p>
                                            <p class="field-value" style='width: 100%'>{{patient.entitlement_numbers.urn_mrn}}</p>
                                        </div> -->
                                            </div>
                                            <div
                                                style="display: flex; flex-wrap: wrap; padding: 3px 10px; width: 100%;">
                                                <div style="width: 50%;"
                                                    v-if="patient.primary_prescriber">
                                                    <p class="field-title">Primary GP</p>
                                                    <p class="field-value"
                                                        style='width: 100%; padding-top: 0px; padding-bottom: 0px; white-space:nowrap; overflow: scroll; margin: 0; border-radius: 4px 4px 0 0'>
                                                        Dr {{ patient.primary_prescriber.personal_information.first_name }}
                                                        {{ patient.primary_prescriber.personal_information.last_name }}</p>
                                                    <p class="field-value"
                                                        style='width: 100%; padding-top: 0px; padding-bottom: 0px; white-space:nowrap; overflow: scroll; margin: 0; border-radius: 0;'>
                                                        <i class="pi-phone pi input-icon"
                                                            style="padding: 0; margin: 0; line-height: 0; height: 5px;"></i>:
                                                        {{ patient.primary_prescriber.phone ?
                                                                patient.primary_prescriber.phone : 'N/A'
                                                        }}
                                                    </p>
                                                    <p class="field-value"
                                                        style='width: 100%; padding-top: 0px; padding-bottom: 0px; white-space:nowrap; overflow: scroll; margin: 0; border-radius: 0 0 4px 4px '>
                                                        <i class="pi-envelope pi input-icon"
                                                            style="padding: 0; margin: 0; line-height: 0; height: 5px;"></i>:{{
                                                                    patient.primary_prescriber.email
                                                                        ? patient.primary_prescriber.email : 'N/A'
                                                            }}
                                                    </p>
                                                </div>
                                                <div style="width: 50%;"
                                                    v-if="patient.pharmacy && patient.pharmacy.length">
                                                    <p class="field-title">Pharmacy</p>
                                                    <p class="field-value"
                                                        style='width: 100%; padding-top: 0px; padding-bottom: 0px; white-space:nowrap; overflow: scroll; margin: 0; border-radius: 4px 4px 0 0'>
                                                        {{ patient.pharmacy.name }}</p>
                                                    <p class="field-value"
                                                        style='width: 100%; padding-top: 0px; padding-bottom: 0px;  white-space:nowrap; overflow: scroll; margin: 0;border-radius: 0;'>
                                                        <i class="pi-phone pi input-icon"
                                                            style="padding: 0; margin: 0; line-height: 0; height: 5px;"></i>:
                                                        {{ patient.pharmacy.phone }}
                                                    </p>
                                                    <p class="field-value"
                                                        style='width: 100%; padding-top: 0px; padding-bottom: 0px; white-space:nowrap; overflow: scroll; margin: 0; border-radius: 0 0 4px 4px '>
                                                        <i class="pi-envelope pi input-icon"
                                                            style="padding: 0; margin: 0; line-height: 0; height: 5px;"></i>:{{
                                                                    patient.pharmacy.email
                                                            }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                        <p class="field-title">Patient Alerts</p>
                                        <p v-if="!patient.adr.length" class="field-value">N/A</p>
                                        <div v-else
                                            style="display: flex;; border-radius: 4px; padding: 5px; background-color: #F3F4F4 !important"
                                            class="field-value-container">
                                            <p class="field-value" v-for="(item, index) in patient.adr" :key="index"
                                                style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; padding: 3px 10px;  margin-right: 3px;">
                                                {{ item.drug.toUpperCase() }}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <PatientChartsSummaryTable @retrievePresChart="retrievePresChart" :charts="charts"
                                    :loadingCharts="loadingCharts" />
                            </div>

                            <!-- NEW PRESCRIPTION -->
                            <!-- <h3 v-if="!activePresChart && patient" style="margin: 0">Select prescription on left panel to view.</h3> -->
                            <!-- v-else  -->
                            <PrescriptionChart :viewOnly="viewOnly" @isSuccessful="isSuccessful" :patientId="patientId"
                                :activePresChart="activePresChart" :draftDrug="draftDrug" :isDraft="isDraft"
                                :displayDropDown="true" />
                        </div>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import PatientChartsSummaryTable from '../tables/PatientChartsSummaryTable.vue';
import PrescriptionChart from '../PrescriptionChart.vue';

export default {
    props: ['patientId', 'close', 'viewOnly'],
    components: {
        PrescriptionChart,
        PatientChartsSummaryTable
    },
    data() {
        return {
            loadingCharts: false,
            patient: '',
            charts: '',
            activePresChart: '',
            draftDrug: '',
            isDraft: false,
        }
    },
    methods: {
        isSuccessful(value) {
            if (value) {
                this.retrieveAllCharts();
                this.$emit('close', true);
            }
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        retrievePatientSummary() {
            axios.post('/retrieve-patient', { location: this.$store.state.uuid, patient_id: this.patientId }, this.$store.state.header).then(res => {
                this.patient = res.data;
                this.patient.personal_information.age = this.getAge(this.patient.personal_information.dob);
                this.patient.personal_information.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU';
                console.log('this is the res data for retrieve patient', res.data, this.patient);
                // this.patient.entitlement_numbers = {};
                // this.patient.regulatory_information.forEach(number=>{
                //     if(number.regulatory_type = 'Medicare Number'){
                //         this.patient.entitlement_numbers.
                //     }
                // })
                this.patient.primary_prescriber.contact_methods.forEach(method => {
                    if (method.name == 'Phone') {
                        this.patient.primary_prescriber.phone = method.data;
                    }
                    if (method.name == 'Email') {
                        this.patient.primary_prescriber.email = method.data;
                    }
                })
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })


        },
        retrievePresChart(value) {
            console.log('received this from the table', value);

            // explicitly mapping all datapoints in case of any object changes from the response value
            this.activePresChart = {
                chart_payload: {
                    prescription_id: value.uuid,
                    chart_form: {
                        type: value.chart_type,
                        route: value.route,
                        indication: value.indication,
                        tags: value.tags ? value.tags : ''
                    }
                },
                prescription_payload: {
                    patient_id: value.patient_id,
                    prescriber: value.doctor.uuid,
                    drug_uuid: value.alias.uuid,
                    days_of_week: value.days_of_week,
                    interval: value.interval,
                    frequency: value.frequency,
                    times: value.times,
                    dose: value.dose,
                    route: value.route,
                    reason: value.reason,
                    notes: value.notes,
                    max_dose: value.max_dose,
                    start_date: new Date(value.start_date).toLocaleDateString(),
                    stop_date: new Date(value.stop_date).toLocaleDateString(),
                    additional_regulatory_checks: ''
                }
            };
            this.draftDrug = value.alias;
            if (!value.signatures.length) {
                this.isDraft = true;
            } else {
                this.isDraft = false;
            }
        },
        retrieveAllCharts() {
            this.loadingCharts = true;
            axios.post(`/person/${this.patientId}/prescriptions`, {
                payload: {
                    drug: '',
                    type: '',
                    frequency: '',
                    route: ''
                }
            }, this.$store.state.header).then(res => {
                console.log('this is the patient summary retrieval', res);
                this.charts = res.data;
                this.loadingCharts = false;

            }).then(() => {
                let chartTypes = this.$store.getters.getChartTypes;
                this.charts.forEach(chartItem => {

                    chartTypes.forEach(chartType => {
                        if ((chartItem.chart_type == chartType.chart_name) || (chartItem.chart_type == chartType.name)) {
                            console.log('LOOPING THROUGH THE CHART ITEMS', chartItem.chart_type, chartType.chart_name);
                            chartItem.chart_type = chartType.chart_name;
                            chartItem.color = chartType.color;
                            chartItem.chart_name = chartType.name;
                            console.log('this is the color now', chartItem.color);
                        }
                    })
                })
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })

        }
    },
    mounted() {
        this.retrievePatientSummary();
        this.retrieveAllCharts();

    },
    computed: {
        getPrimary() {
            return this.patient.prescribers.filter(item => item.primary_gp)
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


 

.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.patient-summary-block {
    .field-value {
        background-color: #f3f4f4;
    }
}

.chart-row-item {
    p {
        margin: 0;
        margin-top: 10px;
    }
}
</style>
