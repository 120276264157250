<template>
    <div >
        <transition name="fade" appear>
            <div class="alerts-panel">

                <div class="alerts-header">
                <h3 style="margin: 0px;text-align: left;display: flex;align-items: center;padding: 10px;">Alerts</h3>
                </div>
                
                <!-- 
                <div style="display: flex; align-items: center; justify-content: space-between">
                    <p @click="toggleAlertsPanel()"
                    style="margin: 0; font-size: 1em; font-weight: bold; border: 1px solid #2c3e50; padding: 0 5px; border-radius: 4px; color: #2c3e50; cursor: pointer">Hide</p>
                </div> -->

                <div class="alerts-container">
                    <div class="alert-items">
                        <div v-if="!$store.state.loadingAlerts">
                            <div v-for="arrayOfAlertKind in formattedAlerts" :key="arrayOfAlertKind.AlertType">
                                <div v-if="arrayOfAlertKind.displayType == 'single'">
                                    <AlertItem :arrayOfAlertKind="arrayOfAlertKind"> </AlertItem>
                                </div>
                                <div v-else-if="arrayOfAlertKind.displayType == 'clustered'">
                                    <AlertItemClustered :arrayOfAlertKind="arrayOfAlertKind"> </AlertItemClustered>
                                </div>  
                            </div>
                            <div v-if="!Object.keys(formattedAlerts).length && !$store.state.loadingAlerts" style="height: 10em; display: flex; align-items: center; justify-content: center;">
                                 <h4>No alerts</h4>
                            </div>
                        </div>
                        <div v-if="$store.state.loadingAlerts"
                            style="display: flex; align-items: center; height: 10em; justify-content: center">
                            <i class="pi pi-spin pi-refresh" style="font-size: 1.5em !important;"></i>
                        </div>
                    </div> 
                </div>

                <div class="alerts-footer">
                    <p style="font-weight: 500; font-size: 1em;">Last Refresh: {{ !$store.state.lastAlertRefresh ? 'N/A' : $store.state.lastAlertRefresh.slice(0, -6) + '' +$store.state.lastAlertRefresh.slice(-2)  }}</p>
                    <p class="black-button" @click="refreshAlerts()"><span><i class="pi pi-refresh" style="padding: 0"></i></span> Refresh List</p>
                </div>
            </div>
        </transition>
    </div>

    
</template>


<script>
import axios from 'axios';
import AlertItem from '@/components/notifications/AlertItem.vue'
import AlertItemClustered from '@/components/notifications/AlertItemClustered.vue'


export default{
    data(){
        return{
            loadingAlerts : false,
            displayAlertType: 1, //0 is expiring, 1 is alerts, 2 is upcoming
            displayPendingInvoicesModal: false,
            displayIncomingDispensingModal: false,
            alerts:[],
            transferType: '',
            displayForm:'',
            view:'',
            dismissedAlerts:[],
            formattedExpiring:[],
            clusteredAlertsTypes: [],

        }
    },
    components:{
        AlertItem,
        AlertItemClustered
    },
    methods:{
        formattedAlertsPackager() {
            let alerts = {}
            // populate alerts based on channel type
            this.$store.state.alerts.forEach(
                alert => {
                        // add tag to each alert displaying if it is to be clustered or not
                        const thealertschanneltype = alert.channel
                        const alertTitle = alert.body.title
                        const specificAlertId = alert.id
                        const alertDetails = alert
                        // alertschanneltype doesn't exist in alerts object yet
                        if(!(thealertschanneltype in alerts)) {
                            // check if clustered type or not
                            if(this.clusteredAlertsTypes.includes(thealertschanneltype)) {
                            const newAlertType = {
                                    AlertType : alertTitle,
                                    displayType: "clustered",
                                    alerts: {
                                        [specificAlertId] : alertDetails
                                    }
                               }
                               alerts[thealertschanneltype] = newAlertType
                            } else {
                                const newAlertType = {
                                    AlertType : alertTitle,
                                    displayType: "single",
                                    alerts: {
                                        [specificAlertId] : alertDetails
                                    }
                            }
                            alerts[thealertschanneltype] = newAlertType
                        }
                    } else {
                        alerts[thealertschanneltype].alerts[specificAlertId] = alertDetails
                    }
                }
            );
            
            // use different functions to sort clusteredAlerts of different types. (non currently for hospital)
            // 1. ??  
            Object.entries(alerts).forEach(entry => {
                const [key, value] = entry;
                if(this.clusteredAlertsTypes.includes(key)) {
                // 1. future proof for further clustered alerts 
                console.log(value)
                }

            })
            return alerts
    },
        toggleAlertsPanel(){
            this.$store.commit('setDisplayAlerts', !this.$store.state.displayAlerts);
        },
        close(value){
        if(value){
            this.displayForm = '';
            this.displayIncomingDispensingModal = false;
            this.displayPendingInvoicesModal = false;
            }
        },
        action(form, type){
            console.log('this is the selected form to display', form, localStorage.getItem('user_id'));
            this.displayForm = form;
            this.transferType = type;
        },
        refreshAlerts(){
            console.log('refreshing alerts');
            this.$store.commit('setAlerts', null);
            this.$store.dispatch('getAlerts'); 
        },
        dismissAlert(id, index){
            this.$store.dispatch('readAlert', {'id': id, 'index': index});
        },
        formatExpiring(){
            if(this.expiringChartsOnly.length){
                this.expiringChartsOnly.forEach(item=>{
                    let expiring = {...item};
                    expiring.name = item.body.message.split('||')[0];
                    expiring.item = expiring.body.message.split('||').slice(1);
                    expiring.items = [];
                })
            }
        }
    },
    mounted(){
        console.log('this is the window view', window.location.pathname);
        this.view = window.location.pathname.slice(1);
    },
    computed:{
        formattedAlerts() {
            if (this.$store.state.alerts) {
                return this.formattedAlertsPackager()
            } else {
                return []
            }
        }
    }
}

</script>

<style lang="scss">
.alerts-panel{
    background-color: white;
    box-shadow: 0px 0 5px -2px rgb(155, 155, 155);
    position: fixed;
    right: 2.5em;
    top: 100px;
    width: 450px;
    min-height: fit-content;
    border-radius: 8px;
    border-bottom-left-radius: 8px;
}

.notification-container{
    display: grid;
    grid-gap: 10px;
}

.alerts-toggle{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    border-bottom: 2px solid #BDC4C4;
;
}

.alert-toggle-item{
    width: fit-content;
    padding: 0 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    h4{
        margin: 10px 0;
        font-size: 1.1em;
    }
}

.alerts-container{
    height: 70vh;
}

.alert-type-box {
    border-bottom: 1px solid #a4a4a4;
}


.alert-items{
    display: grid;
    grid-gap: 10px;
    max-height: 65vh;
    overflow-y: auto;
}

.alert-count{
    color: white; 
    background-color: #3170d5; 
    border-radius: 8px; 
    font-size: 13px; 
    margin-left: 10px;
    font-weight: bold;
    padding: 1px 5px;
    p{
        margin :0;
    }
}

.alert-item-type{
    font-size: 1em; 
    font-weight: bold; 
    text-align: left;
    margin: 0;
}

.alert-item-content{
    text-align: left;
}


.small-red-close-button{
    background-color: #3170d5;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    padding: 0px;
    cursor: pointer;
    margin: 1px 0;
    .pi{
        font-size: 0.7rem !important;
        color: white;
        font-weight: bold;
    }
}
.alerts-footer{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #f3f4f4;
    font-size: 1em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    
}

.alerts-header{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    background-color: #f3f4f4;
    font-size: 1em;
    border-bottom-width: 2px;
    border-bottom: solid #bfc4c4;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; 
}
</style>

