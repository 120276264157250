<template>
    <div>
        <div class="actions-menu" style="display: flex; flex-wrap: wrap">
            <button class="actions-menu-item" @click="action('receive', 'Receive')">
                Transfer In
            </button>
            <button class="actions-menu-item" @click="action('send', 'Send')">
                Transfer Out
            </button>
            <button class="actions-menu-item" @click="action('destroy')">
                New Destruction
            </button>
            <button class="actions-menu-item" @click="action('reverse')">
                Outgoing
            </button>
            <button class="actions-menu-item" @click="action('adjustment')">
                Adjustment
            </button>
            <button class="actions-menu-item" @click="action('patient_owned')">
                Patient Owned
            </button>
            <button class="actions-menu-item" @click="action('delivery')">
                New Delivery
            </button>
            <button class="actions-menu-item" @click="action('manual_dispensing')">
                Dispensing
            </button>
            <button class="actions-menu-item" @click="action('manufacturing')">
                Manufacturing
            </button>
            <button class="actions-menu-item" @click="action('administration')">
                Administration
            </button>
        </div>
        <!-- <Destroy v-show="displayForm=='destroy'" />
        <Move v-show="displayForm=='move'" />
        <Receive v-show="displayForm=='receive'" />
        <Reverse v-show="displayForm=='reverse'" /> -->
        <TheatreAllocateModal v-if="displayForm == 'theatreMeds'" @close="close" />
        <!-- <TheatreCheckinModal v-if="displayForm == 'theatreUsage'" @close="close"/> -->
        <NewTransferModal v-if="(displayForm == 'send') || (displayForm == 'receive')" @close="close"
            :transferType="transferType" />
        <DestructionModal v-if="displayForm == 'destroy'" @close="close" />
        <DeliveryModal v-if="displayForm == 'delivery'" @close="close" />
        <ManufacturingModal v-if="displayForm == 'manufacturing'" @close="close" />
        <LostDamagedModal v-if="displayForm == 'reverse'" @close="close" />
        <AdministrationModal v-if="displayForm == 'administration'" @close="close" />
        <ReceivePOMModal v-if="displayForm == 'patient_owned'" @close="close" />
        <AdjustmentModal v-if="displayForm == 'adjustment'" @close="close" :transferType="transferType" />
        <ManualDispensingModal v-if="displayForm == 'manual_dispensing'" @close="close" />
    </div>
</template>


<script>

// import Destroy from '@/components/actions/Destroy.vue'
// import Move from '@/components/actions/Move.vue'
// import Receive from '@/components/actions/Receive.vue'
// import Reverse from '@/components/actions/Reverse.vue'
import ManualDispensingModal from '@/components/modals/ManualDispensingModal.vue';
import DeliveryModal from '@/components/modals/DeliveryModal.vue';
import TheatreAllocateModal from '@/components/modals/TheatreAllocateModal.vue';
import ManufacturingModal from '@/components/modals/ManufacturingModal.vue';
import NewTransferModal from '@/components/modals/NewTransferModal.vue';
import DestructionModal from '@/components/modals/DestructionModal.vue';
import LostDamagedModal from '@/components/modals/LostDamagedModal.vue';
import AdministrationModal from '@/components/modals/AdministrationModal.vue';
import AdjustmentModal from '@/components/modals/AdjustmentModal.vue';
import ReceivePOMModal from '@/components/modals/ReceivePOMModal.vue';
// import TheatreCheckinModal from '@/components/modals/TheatreCheckinModal.vue';

export default {
    data() {
        return {
            transferType: '',
            displayForm: '',
            view: ''
        }
    },
    components: {
        ManualDispensingModal,
        DeliveryModal,
        NewTransferModal,
        ManufacturingModal,
        DestructionModal,
        LostDamagedModal,
        AdministrationModal,
        ReceivePOMModal,
        AdjustmentModal,
        TheatreAllocateModal,
        // TheatreCheckinModal
        // Destroy,
        // Move,
        // Receive,
        // Reverse
    },
    methods: {
        close(value) {
            if (value) {
                this.displayForm = '';
                console.log(this.$route.name)
                if (this.$route.name == 'Drug Register') {
                    console.log('loading the drug register stuff');
                    this.$store.dispatch('getDrugRegister', {
                        sort_direction: 'desc',
                        col: 'created_at',
                        page: 1,
                        filters: {
                            transaction_id: '',
                            to_from: '',
                            entered_by: '',
                            date: {
                                start: '',
                                end: ''
                            },
                            drug: '',
                            imprest_only: false
                        }
                    });
                } else if (this.$route.name == 'Transfers') {
                    console.log('loading the transfer stuff');
                    this.$store.dispatch('getTransfers', {
                        sort_direction: '',
                        col: '',
                        page: 1,
                        location: this.$store.state.uuid,
                        filters: {
                            status: ['pending', 'reversed', 'rejected', 'completed'],
                            completed_by: '',
                            location: '',
                            type: ['send', 'receive'],
                            date: {
                                start: '',
                                end: ''
                            },
                        }
                    });
                } else if (this.$route.name == 'Stocktake') {
                    console.log('loading the stock take version stuff');
                    this.$store.dispatch('getStockTake', {
                        sort_direction: '',
                        col: '',
                        page: 1,
                        filters: {
                            tags: ["s8"],
                            show_inactive: false,
                            imprest_only: false,
                            in_stock: true,
                            due_stocktake: false,
                            date: {
                                start: '',
                                end: ''
                            },
                            product_name: '',
                            patient: ''
                        }
                    });
                }
            }
        },
        action(form, type) {
            console.log('this is the selected form to display', form, localStorage.getItem('user_id'));
            this.displayForm = form;
            this.transferType = type;


        }
    },
    mounted() {
        console.log('this is the window view', window.location.pathname);
        this.view = window.location.pathname.slice(1);
    }
}

</script>