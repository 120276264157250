<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel">
      <h3 class="panel-header">Actions</h3>
      <div>
        <div class="actions-menu" style="display: flex; flex-wrap: wrap;">
            <button @click="displayRequisitionModal = true" class="actions-menu-item">New Requisition</button>
        </div>
      </div>
    </div>
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_transfers')">
      <h3 class="panel-header">Requisitions</h3>
      <div class="drug-register-table table-container">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">
            <div class="date-search-container search-container">
              <label for="date">Date</label>
              <div class="calendar-container">
                <i class="pi pi-calendar"></i>
                <Calendar v-model="date" selectionMode="range" class="calendar-input-field" placeholder="Date..." dateFormat="dd/mm/yy" />
              </div>
            </div>

            <div class="text-search-container search-container">
              <label for="location">Location</label>
              <input v-model="form.filters.location" placeholder="Select a Location..." style="position: relative" />
            </div>

            <div class="text-search-container search-container">
              <label for="location">Status</label>
              <MultiSelect v-model="selectedStatuses" :options="statusSelect" @change="updateStatusList" optionLabel="status" placeholder="Status" />
            </div>

            

            <div style="display:flex; padding-top:14px;">
              <button class="button clear-button" @click="clearTransfersFilters()">Clear</button>
              <button class="button submit-button" @click="loadRequisitions()">Search</button>
              
            </div>
            
          </div>

          <!-- <div style="display: flex; align-items: center">
              <div class="select-filters-container">

              <p @click="()=>{types.send = !types.send; updateType()}" 
                  v-bind:class="[types.send == true ? 'active-select-filter' : '']" class="select-filter-item">Sent</p>

              <p @click="()=>{types.receive = !types.receive; updateType()}"  
                  v-bind:class="[types.receive == true ? 'active-select-filter' : '']" class="select-filter-item">Received</p>
              
            </div>
          </div> -->
          

        </div>
        
        <div v-if="$store.state.loadingRequisitionData" >
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
        <div v-if="getRequisitionEntries && !getRequisitionEntries.length && !$store.state.loadingRequisitionData"><h3 >There are no requisitions for this location.</h3></div>
        <!-- v-if="!$store.state.loadingTransfersData" -->
        <div v-if="!$store.state.loadingRequisitionData"  style="display: flex; align-items: start; overflow-y: scroll">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_transfers")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
          <!-- v-else -->
        <table v-if="!$store.state.loadingRequisitionData &&  getRequisitionEntries &&  getRequisitionEntries.length"  class="data-table" cellspacing="0">
          <tr class="table-headers" >
            <th style="width: 50px"></th>
            <th>
              <h3 @click="sortBy('created_at', 'desc')" style="cursor: pointer">Requested Date</h3>
              <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort  created_at-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
                <i class="pi pi-sort created_at-sort" v-else @click="sortBy('created_at', 'asc')"></i>
              </div>
            </th>
            <th height=40px >
              <h3 @click="sortBy('type', 'desc')">{{ this.$store.state.currentLocation.type == 'pharmacy' ? 'To':'From' }}</h3>
              <div v-if="form.col == 'type'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort type-sort" v-if="form.col =='type' && form.sort_direction =='asc'" @click="sortBy('type', 'desc')"></i>
                <i class="pi pi-sort type-sort" v-else @click="sortBy('type', 'asc')"></i>
              </div>
              
            </th>
            <th>
              <!-- @click="sortBy('location', 'desc')" -->
              <h3 >Requested By</h3>
              <!-- <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  location-sort" v-if="form.col =='location' && form.sort_direction =='asc'" @click="sortBy('location', 'desc')"></i>
              <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <!-- @click="sortBy('completed_by', 'desc')" -->
              <h3 >Requisition ID</h3>
              <!-- <div v-if="form.col == 'completed_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_by-sort" v-if="form.col =='completed_by' && form.sort_direction =='asc'" @click="sortBy('completed_by', 'desc')"></i>
              <i class="pi pi-sort completed_by-sort" v-else @click="sortBy('completed_by', 'asc')"></i>
              </div> -->
            </th>
            
            <th>
              <!-- @click="sortBy('created_by', 'desc')" -->
              <h3 >Completed by</h3>
              <!-- <div v-if="form.col == 'created_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  request-by-sort" v-if="form.col =='created_by' && form.sort_direction =='asc'" @click="sortBy('created_by', 'desc')"></i>
              <i class="pi pi-sort request-by-sort" v-else @click="sortBy('created_by', 'asc')"></i>
              </div> -->
            </th>
            <th>
              <h3 @click="sortBy('completed_at', 'desc')" style="cursor: pointer">Completed Date</h3>
              <div v-if="form.col == 'completed_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_at-sort" v-if="form.col =='completed_at' && form.sort_direction =='asc'" @click="sortBy('completed_at', 'desc')"></i>
              <i class="pi pi-sort completed_at-sort" v-else @click="sortBy('completed_at', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('status', 'desc')" style="padding-left: 20px;">Status</h3>
              <div v-if="form.col == 'status'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  status-sort" v-if="form.col =='status' && form.sort_direction =='asc'" @click="sortBy('status', 'desc')"></i>
              <i class="pi pi-sort status-sort" v-else @click="sortBy('status', 'asc')"></i>
              </div>
            </th>
            

          </tr>
          
          <tbody class="table-data" v-for="(item, index) in getRequisitionEntries" :key="index">
            <tr @click="showContent(item, index), activeRequisitionItem = item" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']">
                <td>
                    <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                    <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>{{item.requested_date}}</td>
                <!-- v-if="item.type == 'receive'" -->
                <td height="40px">
                  <!-- <i class="pi pi-download" v-if="item.to_location == $store.state.currentLocation.name"></i> -->
                  <p>{{ this.$store.state.currentLocation.type == 'pharmacy' ? item.to.name :item.from.name }}</p>
                  <!-- <i v-else class="pi pi-upload"></i> -->
                </td>
                <td>{{item.requested_by && item.requested_by.personal_information? item.requested_by.personal_information.first_name+' '+item.requested_by.personal_information.last_name : 'N/A'}}</td>
                <td>{{item.uuid}}</td>
                
                <td :style="[!item.completed_by ? {paddingLeft: '3em'}:{}]">{{item.completed_by && item.completed_by.personal_information? item.completed_by.personal_information.first_name+' '+item.completed_by.personal_information.last_name : '--'}}</td>
                <td :style="[!item.completed_date ? {paddingLeft: '3em'}:{}]">{{item.completed_date ? item.completed_date: '--'}}</td>
                <td v-if="item.status" ><p :class="[getStatusColors(item.status)]">{{item.type == 'reversal' && item.status == 'completed' ? 'Reversal' : item.status.charAt(0).toUpperCase()+item.status.slice(1)}}</p></td>
            </tr>
            <!--[item.status == 'complete' ? 'correct-status-color' : (item.status == 'rejected' || item.status == 'pickup ready' ? 'info-status-color' : 'warning-status-color')]-->
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container" style="display: grid" :style="!item.expandedInfo ? {justifyContent: 'center'}:{}" v-if="item.display">
                 <div v-if="!item.expandedInfo" style="margin: 0 auto; margin-bottom: 1em;">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important;"></i>
                 </div>
                   <div v-if="item.expandedInfo" style="display: flex; flex-wrap: wrap">
                       <div style="display: grid; align-items: center">
                           <p class="field-title" style="width: 9em">From Location</p>
                           <p class="field-value" style="margin: 3px; width: 15em">{{item.from.name}}</p>
                       </div>
                       
                       <div style="display: grid; align-items: center">
                           <p class="field-title" style="width: 9em">Distributed By</p>
                           <p class="field-value" style="margin: 3px; width: 15em">{{item.expandedInfo.distributed_by && item.expandedInfo.distributed_by.personal_information ? `${item.expandedInfo.distributed_by.personal_information.first_name} ${item.expandedInfo.distributed_by.personal_information.last_name}` : 'N/A'}}</p>
                       </div>
                       <div style="display: grid; align-items: center">
                           <p class="field-title" style="width: 9em">Distributed Time</p>
                           <p class="field-value" style="margin: 3px; width: 15em">{{item.expandedInfo.distribution_date ? item.expandedInfo.distribution_date : 'N/A'}}</p>
                       </div>
                       <div style="display: grid; align-items: center">
                           <p class="field-title" style="width: 9em">Collected By</p>
                           <p class="field-value" style="margin: 3px; width: 15em">{{item.expandedInfo.picked_up_by && item.expandedInfo.picked_up_by.personal_information? item.expandedInfo.picked_up_by.personal_information.first_name+' '+item.expandedInfo.picked_up_by.personal_information.last_name : 'N/A'}}</p>
                       </div>
                       <div style="display: grid; align-items: center">
                           <p class="field-title" style="width: 9em">Collected Time</p>
                           <p class="field-value" style="margin: 3px; width: 15em">{{item.expandedInfo.pickup_date ? item.expandedInfo.pickup_date : 'N/A'}}</p>
                       </div>
                   </div>
                   <div class="requisition-item-table" style="width: fit-content; display: flex; margin-bottom: 10px;" v-if="item.expandedInfo">
                      <div class="transfer-notes-container" v-if=" item.expandedInfo">
                        <p class="field-title">Notes</p>
                        <div class="field-notes" style="width: 350px" >
                            <p >{{item.expandedInfo.notes}}</p>
                        </div>
                      </div>
                      <div>
                       <p class="field-title">Drugs</p>
                       <div class="drug-field-tables" style="border: 2px solid rgb(182, 182, 182); border-radius: 4px; height: 300px; width: 54vw"> 
                        <div style="height: 100%; overflow-y: scroll; background-color: white;">
                            <table style="padding-top: 0;" class="table-data">
                                <tr style="background-color: white;">
                                    <th style="width: 10vw; padding-left: 10px">Patient</th>
                                    <th style="width: 20vw; ">Name</th>
                                    <th style="width: 3vw; ">Qty</th>
                                    <th style="width: 5vw; " v-if="$store.state.currentLocation.type == 'pharmacy' && $store.state.blind_count_settings && item.nextAction == 'distribute'">Blind Count</th>
                                    <th v-else></th>
                                    <th style="width: 10vw; " v-if="$store.state.currentLocation.type == 'pharmacy' && $store.state.blind_count_settings && item.nextAction == 'distribute'"></th>
                                    <!-- <th v-else></th> -->
                                    <th style="width: 7vw; ">Status</th>
                                    <th style="width: 9vw; " v-if="item.expandedInfo.type=='patient'">Action</th>
                                </tr>
                                <tr v-for="(drug,key) in item.expandedInfo.requisition_items" :key="key" style="height: 25px">
                                    <td style="font-size: 0.8em; width: 9vw" >{{drug.patient &&  drug.patient.personal_information? drug.patient.personal_information.first_name+' '+drug.patient.personal_information.last_name : 'Imprest'}}</td>
                                    <td style="font-size: 0.8em; width: 15vw">{{drug.alias &&  drug.alias.name? drug.alias.name : 'N/A'}}</td>
                                    <td style="font-size: 0.8em; width: 5vw;">{{drug.quantity}}</td>
                                    <!-- drug.uuid && (drug.status != 'completed' && (!drug.dispensings || !drug.dispensings.length)) && -->
                                    <td v-if=" (!drug.dispensings || !drug.dispensings.length) &&  $store.state.currentLocation.type == 'pharmacy' && $store.state.blind_count_settings && item.nextAction == 'distribute'">
                                        <input type="number" :disabled="drug.valid" style="width: fit-content; border: 1px solid grey; width: 8em" :style="drug.last_count ? {marginTop: '15px'}:{}" v-model="drug.count">
                                        <p style="font-size: 0.8em; margin: 0" v-if="drug.last_count">Last Count: {{ drug.last_count }}</p>
                                    </td>
                                    <td v-if=" (drug.dispensings && drug.dispensings.length) &&  $store.state.currentLocation.type == 'pharmacy' && $store.state.blind_count_settings && item.nextAction == 'distribute'">   
                                      <p>Counted</p>
                                    </td>
                                    <td v-if="(!drug.dispensings || !drug.dispensings.length) &&  $store.state.currentLocation.type == 'pharmacy' && $store.state.blind_count_settings && item.nextAction == 'distribute'">
                                        <p class="blue-button" style="padding:7px 10px; text-align: center" @click="verifyCount(drug, item)" v-if="!drug.valid || !drug.count">Check</p>
                                        <p v-if="drug.valid" class="blue-button" style="padding:7px 10px; text-align: center; cursor: inherit; opacity: 0.7">Counted</p>
                                    </td>
                                    <td v-else>   
                                    </td>
                                    <td style="width: 5vw;" ><span v-html="getDrugStatus(item, drug)"></span></td>
                                    <td style="width: 5vw;"><p style="width: fit-content;" class="green-button" v-if="drug.patient && (item.expandedInfo.status == 'pending' && (drug.valid || !$store.state.blind_count_settings)  && drug.status != 'completed' && (!drug.dispensings || !drug.dispensings.length)) && $store.state.currentLocation.type == 'pharmacy'" 
                                    @click="supplyRequisitionItem(index,drug.uuid, drug); requisitionIndex=index" >Supply</p></td>
                                    
                                </tr>
                            </table>
                        </div>
                       </div>
                      </div>
                   </div>

                   

                   <!-- <div class="requisition-item-table" style="width: fit-content" v-if="item.display">
                       <p class="field-title">Dispensing Requests</p>
                       <div class="drug-field-tables" style="width: fit-content; border: 2px solid rgb(182, 182, 182); border-radius: 4px; height: 300px; width: 350px;">
                        <table style="padding: 0px 5px">
                            <tr>
                                <th style="width: 70px">Name</th>
                                <th style="width: 70px">Drug</th>
                                <th style="width: 30px">Qty</th>
                                <th style="width: 60px">Status</th>
                            </tr>
                        </table>

                        <div style="height: 250px; overflow-y: scroll; background-color: white;">
                            <table style="padding-top: 0;">
                                <tr v-for="(req,i) in item.dispensing_requests" :key="i" style="height: 25px">
                                    <td style="font-size: 0.8em; width: 95px">{{req.name}}</td>
                                    <td style="font-size: 0.8em; width: 100px">{{req.brand_name ? req.brand_name : req.generic_name}}</td>
                                    <td style="font-size: 0.8em; width: 50px;">{{req.quantity}}</td>
                                    <td v-if="req.status" style="width: 70px;"><p style="min-width: fit-content;" :class="[req.status == 'completed' ? 'correct-status-color' : (req.status == 'rejected' || req.status == 'reversed' ? 'mismatch-status-color' : 'warning-status-color')]">{{req.type == 'reversal' && req.status == 'completed' ? 'Reversal' : req.status.charAt(0).toUpperCase()+req.status.slice(1)}}</p></td>
                                </tr>
                            </table>
                        </div>
                       </div>
                   </div> -->
               </div>
                <!-- REQUISITION ACTIONS -->
                
                <div class="transfer-actions-container" style="align-items: center" v-if="item.expandedInfo">
                    <button v-if="item.nextAction == 'distribute' && $store.state.currentLocation.type == 'pharmacy' && !item.showPickupBtn && item.type == 'imprest' && !item.ready_for_pickup && ($store.state.blind_count_settings ? item.all_counted : true)" class="transfer-action-button green-button" @click="supplyRequisitionItems(index, item.uuid)">Supply</button>
                    <button v-if="(item.showPickupBtn || item.ready_for_pickup) && item.status == 'pending' && $store.state.currentLocation.type == 'pharmacy'" class="transfer-action-button green-button" @click="markAsReady(item.uuid, index)">Ready for Pickup</button>
                    <button v-if="item.nextAction == 'collect'" class="transfer-action-button green-button" @click="updateRequisition(item, '/pickup-requisition', 'Collect Requisition')">Collect</button>
                    <button v-if="item.nextAction == 'complete' && $store.state.currentLocation.type != 'pharmacy'" class="transfer-action-button green-button" @click="updateRequisition(item, '/complete-requisition', 'Complete Requisition')">Complete</button>
                    <button v-if="item.canCancel" class="transfer-action-button error-button" @click="updateRequisition(item, '/requisition/'+ item.uuid+'/cancel', 'Cancel Requisition')">Cancel</button>
                    <button @click="updateRequisition('Approve', item)" class="transfer-action-button green-button" v-show="(item.status == 'pending' && item.to_location == $store.state.currentLocation.name) || ( item.status == 'pending reversal' && item.to_location == $store.state.currentLocation.name) ">Complete Transfer</button>
                    <button @click="showContent(item, index)" style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                </div>
            </tr>    
          </tbody>
          
        </table>
        </div>
          <div class="pagination-links" v-if="!$store.state.loadingRequisitionData">
            <p class="pagination-arrow" v-show="$store.getters.getRequisitionEntries.page_count > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.getters.getRequisitionEntries.current_page}} of {{$store.getters.getRequisitionEntries.page_count}}</p>
            <p class="pagination-arrow" v-show="$store.getters.getRequisitionEntries.current_page < $store.getters.getRequisitionEntries.page_count" @click="displayPage( $store.getters.getRequisitionEntries.current_page + 1)"> &rarr; </p>
          </div>
       </div>
       
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <ActionModal v-if="displayActionModal" :endpoint="endpoint" @close="close" :toAction="toAction"  @isSuccessful="isSuccessful" />
    <RequisitionModal v-if="displayRequisitionModal" @close="close" />
    <ManualDispensingModal v-if="displayManualDispensingModal" @refreshRequisitionItem="refreshRequisitionItem" @close="close" 
    @dispensedItem="dispensedItem" :requisitionIndex="requisitionIndex" :dispensePayload="dispensePayload" />
    <SignatureModal v-if="displaySignatureModal" 
    :endpoint2=" $store.state.blind_count_settings && endpoint == '/supply-requisition-items' ? endpoint : null" @close="close" :payload2="$store.state.blind_count_settings && endpoint == '/supply-requisition-items' ? dispensePayload : null" 
    :endpoint="$store.state.blind_count_settings &&  endpoint == '/supply-requisition-items' ? '/stock-take-submit' : endpoint" :payload="$store.state.blind_count_settings &&  endpoint == '/supply-requisition-items' ? realSubmit2 : dispensePayload"
    @isSuccessful="isSignedSuccessful" :dual="true" />
    <SuccessModal v-if="displaySuccessModal" @close="close"
        :message="'There is a discrepancy between what has been entered and the actual stock count. \n Please repeat the count. If discrepancy is repeated, a balance check will be submitted .'" />
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import RequisitionModal from '@/components/modals/RequisitionModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import ManualDispensingModal from '@/components/modals/ManualDispensingModal.vue';
import ActionModal from '@/components/modals/ActionModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import axios from 'axios';

export default {
  components: { 
    SuccessModal,
    ActionModal,
    RequisitionModal,
    ManualDispensingModal,
    SignatureModal
  },
  data(){
    return{
        endoint:'',
        displaySuccessModal: false,
        displayRequisitionModal: false,
        displaySignatureModal: false,
        displayManualDispensingModal: false,
        displayActionModal: false,
        dispensePayload:'soemth',
        requisitionIndex:0,
        activeRequisitionItem:'',
        toAction:{
            uuid:'',
            action: ''
        },
        selectedStatuses: [
            {status:'Pending'},
            {status:'Pickup Ready'},
            {status:'Transit'},
            {status:'Complete'},
            {status:'Cancelled'}
		],
		statusSelect: [
            {status:'Pending'},
            {status:'Pickup Ready'},
            {status:'Transit'},
            {status:'Complete'},
            {status:'Cancelled'}
		],
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true,
          reversal: true,
      },
      toCache:[],
      date:[],
      clearForm:{
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          // ,'pending reversal'
          status:['pending', 'pickup ready', 'transit','complete', 'cancelled'],
          completed_by:'',
          location:'',
          type:[],
          date:{
            start:'',
            end:''
          },
        }
      },
      form:{
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          // ,'pending reversal'
          status:['pending', 'pickup ready', 'transit','complete', 'cancelled'],
          completed_by:'',
          location:'',
          type:[],
          date:{
            start:'',
            end:''
          },
        }
      },
      realSubmit:{
        requisition_id:'',
      },
      realSubmit2:{}
    }
  },
  methods:{
    verifyCount(drug, requisition){
        console.log('verifying count', drug);
        if(drug.count != drug.stock_item.stock_level){
            console.log('doesnt match', drug);
            if(!drug.last_count){
                drug.valid = false;
                drug.last_count = drug.count;
                this.displaySuccessModal = true;
            } else{
                drug.valid = true;
            }
        } else{
            console.log('matches', drug);
            drug.valid = true;
        }
        this.checkCountedMeds(requisition);
    },
    getStatusColors(status){
      if(status == 'complete'){
        return 'correct-status-color';
      }else if(status == 'rejected' || status == 'cancelled' || status == 'pickup ready'){
        return 'info-status-color';
      }else{
        return 'warning-status-color';
      } 
    },
    getDrugStatus(item, drug){
      let classColor = 'correct-status-color';
      let status = 'supplied';

      if(item.expandedInfo.status == 'cancelled'){
        classColor='info-status-color';
        status = 'Cancelled';
      }else if(!drug.dispensings.length && !drug.status && item.expandedInfo.status == 'pending'){ 
        classColor='warning-status-color';
        status = 'Pending';
      }

      return "<p style='reqmin-width: 70px' class='" + classColor + "'> " + status + "</p>";
      
    },
    checkCountedMeds(requisition){
      let checkedCount = 0;
      requisition.expandedInfo.requisition_items.forEach(drug=>{
          if(drug.valid){
            checkedCount +=1;
          }
      });
      if(checkedCount == requisition.expandedInfo.requisition_items.length){
          console.log('checked Count is true')
          requisition.all_counted = true;
        } else{
          console.log('checked Count is false')
          requisition.all_counted = false;
        }
      
    },
    stockTakeSubmit(requisition){
        let payload = {
            notes: `Stock check for requisition.`,
            blind_count: true,
            stocktake:[
                // {
                //   uuid: '92342' ,
                //   count: 55,
                // }
            ]
        }
        console.log('this is the requisition', requisition);
      if(requisition.expandedInfo){
        requisition.expandedInfo.requisition_items.forEach(drug=>{
            payload.stocktake.push({
                uuid: drug.stock_item.uuid,
                count: drug.count
            })
        })
      } else if(requisition.stock_item){
          payload.stocktake.push({
            uuid: requisition.stock_item.uuid,
            count: requisition.count
          })
      }
      
        this.realSubmit2 = payload;
        
    },
    close(value){
        if(value){
            this.displaySuccessModal = false;
            this.displayActionModal = false;
            this.displayRequisitionModal = false;
            this.displayManualDispensingModal = false;
            this.displaySignatureModal = false;
            this.loadRequisitions();
        }
    },
    markAsReady(id, index){
      axios.post('/mark-ready-requisition', {requisition_id: id}, this.$store.state.header).then(res=>{
        console.log('marked as ready for pickup', res);
        this.getRequisitionEntries[index].status="collect";
        this.getRequisitionEntries[index].nextAction="collect";
      }).catch(err=>{
        console.log('this is an err', err, err.response?.data);
        this.getRequisitionEntries[index].nextAction="collect";
        this.getRequisitionEntries[index].status="collect";
      })
    },
    isSignedSuccessful(){
      console.log('calling this signed successful function');
      if(this.endpoint == `/supply-requisition-items`){
        this.loadRequisitions();
        // let numReqItems = this.getRequisitionEntries[this.dispensePayload.requisition_index].expandedInfo.requisition_items.length;
        // let suppliedItems = 0;
        // console.log('looping through requisition items');
        // this.dispensePayload.requisition_items.forEach(item=>{
        //   this.getRequisitionEntries[this.dispensePayload.requisition_index].expandedInfo.requisition_items.forEach(reqitem=>{
        //     console.log('trying to find a match', item, reqitem.uuid);
        //     if(item.unit_id == reqitem.uuid){
        //       reqitem.status = 'completed';
              
        //       console.log('found a match!!!', item, reqitem.uuid);
        //     }
        //     if(reqitem.status == 'completed'){
        //       suppliedItems += 1;
        //     }
        //   })
        // })
        // if(suppliedItems == numReqItems){
        //   console.log('all items supplied');
        //   this.getRequisitionEntries[this.dispensePayload.requisition_index].ready_for_pickup = true;
        // } else{
        //   console.log('no items supplied', suppliedItems, numReqItems)
        // }   
      }
    },
    isSuccessful(value){
      if(value){
        this.$store.dispatch('getRequisitions', this.form);
      }
    },
    updateRequisition(item, endpoint, type){
        let newDate = new Date();
        console.log('this is the transfer being actioned', item);
        this.toAction = {
            action: type,
            uuid: item.uuid,
            'note': item.expandedInfo.notes ? `${item.expandedInfo.notes} \n${newDate.toLocaleDateString('en-au')} ${newDate.toLocaleTimeString('en-GB')}:` : `${newDate.toLocaleDateString('en-au')} ${newDate.toLocaleTimeString('en-GB')}:`,
        }
        console.log();
        this.endpoint=endpoint; 
        this.displayActionModal = true;
    },
    supplyRequisitionItem(requisitionIndex, id, requisitionItem){
      // "patient_id" (uuid string, nullable),
      //  script_number: (string, nullable),
      //  prescriber_id: (uuid string, nullable),
      //  unit_id: (string)
      this.dispensePayload = {
        requisition_id: this.getRequisitionEntries[requisitionIndex].uuid,
        requisition_items: [{unit_id: id, patient_id: requisitionItem.patient?.uuid, prescriber_id: null, script_number: null}],
        requisition_index: requisitionIndex
      };
      if(this.$store.state.blind_count_settings){
        this.stockTakeSubmit(requisitionItem);
      }
      this.endpoint = `/supply-requisition-items`;
      this.displaySignatureModal = true;
      console.log('THIS IS THE ID AND DRUG ITEM', id, this.dispensePayload);
    },
    supplyRequisitionItems(requisitionIndex, id){
      this.dispensePayload = {
        requisition_id: id,
        requisition_items: [],
        requisition_index: requisitionIndex
      };

      this.getRequisitionEntries[requisitionIndex].expandedInfo.requisition_items.forEach(item=>{
        this.dispensePayload.requisition_items.push({unit_id: item.uuid, patient_id: null, prescriber_id: null, script_number: null});
      })
      
      this.endpoint = `/supply-requisition-items`;
      this.displaySignatureModal = true; 
      if(this.$store.state.blind_count_settings){
        this.stockTakeSubmit(this.getRequisitionEntries[requisitionIndex]);
      }
      
      console.log('THIS IS THE ID AND DRUG ITEM', id, this.dispensePayload);
      // setTimeout(this.isSignedSuccessful(), 2000);

    },
    refreshRequisitionItem(value){
      if(value){
        console.log('refreshing requisition item', this.activeRequisitionItem, value);
        this.activeRequisitionItem.expandedInfo = '';
        this.showContent(this.activeRequisitionItem, value, true);
      }
    },
    dispensedItem(value){
      if(value){
        console.log('THIS IS THE RESPONSE BACK', value);
      }
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getRequisitions', this.form);
    },
    showContent(requisition, index, refreshing){
        console.log('this the selected log', requisition);
        // let refid=`expand-${index}`;
        if(!refreshing && requisition.display && (requisition.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            requisition.display = !requisition.display;
        } else{
            requisition.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
            axios.post('/get-requisition', {requisition_id: requisition.uuid}, this.$store.state.header).then(res=>{
                console.log('this is the state function to get a single requisition', res);
                requisition.expandedInfo = res.data;
                if(res.data.requisition_items.length && res.data.requisition_items[0].patient){
                  requisition.type = 'patient'
                } else{
                  requisition.type = 'imprest'
                }
                switch(res.data.status){
                  case 'pickup ready':
                    requisition.nextAction = 'collect';
                    break;
                  case 'transit':
                    requisition.nextAction = 'complete';
                    break;
                  case 'pending':
                    requisition.nextAction = 'distribute';
                    requisition.canCancel = true;
                    break;
                }
                requisition.showPickupBtn = false;
                let myPickUp = true;
                requisition.expandedInfo.requisition_items.forEach(item=>{
                  if(item.patient && item.dispensings.length){
                    requisition.canCancel = false;
                  }
                  item.count = null;
                  item.stock_id = item.uuid;
                  if(!item.dispensings.length){
                    myPickUp = false;
                  }
                })

                requisition.showPickupBtn = myPickUp;
                console.log('this is the new requisition', requisition);
            }).catch(err=>{
              console.log('ERROR IS THIS', err.response);
            })
        }
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadRequisitions(){
      console.log('this is the date', this.date);
      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0];
          this.form.filters.date.end = this.date.split("-")[1];
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
          this.form.filters.date.end = new Date(this.date[1]).toLocaleDateString('en-AU');
        }
        if(!this.form.filters.status.includes('reversal') && !this.form.filters.status.includes('pending reversal')){
          this.form.filters.type.splice(this.form.filters.type.indexOf('reversal'), 1);
          this.updateType();
        }

        this.$store.dispatch('getRequisitions', this.form);
      } else{
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        if(!this.form.filters.status.includes('reversal') && !this.form.filters.status.includes('pending reversal')){
          this.form.filters.type.splice(this.form.filters.type.indexOf('reversal'), 1);
          this.updateType();
        }

        this.$store.dispatch('getRequisitions', this.form);
      }
    },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getRequisitions', this.form);
    },
    updateStatusList(){
        this.form.filters.status = [];
        console.log('updating status list')
        console.log(this.selectedStatuses);
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                // console.log('this is the key', key);
                this.form.filters.status.push(value.toLowerCase());
             }
        })
        this.loadRequisitions();
    },
    updateType(){
        this.form.filters.type = [];
        for (const [key, value] of Object.entries(this.types)) {
            if(value == true){
                this.form.filters.type.push(key)
            }
        }
    },
    clearTransfersFilters(){
      this.form = {
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          // ,'pending reversal'
          status:['pending', 'pickup ready', 'transit','complete', 'cancelled'],
          completed_by:'',
          location:'',
          type:[],
          date:{
            start:'',
            end:''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getRequisitions', this.form);
    },
  },
   mounted(){
    this.loadRequisitions();
    this.$store.dispatch('getRefreshToken');
    this.toCache = [];
    setTimeout(()=>{
      console.log('this is the drug register', this.$store.getters.getRequisitionEntries);
    },10000)
  },
  computed:{
    getRequisitionEntries(){
      return this.$store.getters.getRequisitionEntries.data
    }
  }
}
</script>

<style lang="scss">
    .requisition-item-table{
      table{
        border-collapse: collapse;
      }
      td{
        border: none !important;
        padding: 0 5px;
      }
    }
    .search-container{
      text-align: left;
    }
    .search-container label{
      display: block;
      padding-left: 10px;
    }
    
    .calendar-input-field input.p-inputtext{
      background-color: transparent;
    }
    .calendar-container{
      border-radius: 8px;
      background-color: #F3F4F4;
      margin-right:0 !important;
    }

    .select-filter-item{
      font-size: 15px;
      height: 33px;
      padding:5px 10px !important;
      line-height: 24px;
    }
    .clear-button{
      margin: 6px 10px 6px 0;
    }
</style>
