<template>
    <div class="drug-register-view view">
        <div class="panel" style=" width: 95.5vw;">
            <div v-if="$store.state.user.role.permissions.includes('can_view_resident')">
                <div v-if="patient">
                    <div style="display: flex; align-items: flex-end; justify-content: space-between;">
                        <!-- RESIDENTIAL INFORMATION TITLE AND EDIT BUTTON -->
                        <div style="display: flex; align-items: flex-end">
                            <!-- style="text-align: left; margin: 0; margin-left: 10px;" -->
                            <h2 class="panel-header">Patient Information</h2>
                            <p style="font-weight: 500; color: black; margin: 5px; font-size: 1em; cursor: pointer; margin-left: 1em"
                                v-if="selectedTab == 'medications' && displayPrescription"
                                @click="selectTab('medications'), displayPrescription = false">
                                &lt; Return to Patient Profile
                            </p>
                        </div>
                        <!-- END RESIDENTIAL INFORMATION TITLE AND EDIT BUTTON -->
                        <!-- MEDICATIONS, PATIENT INFO, OBSERVATIONS, ADMINISTRATION, MEDICATION HISTORY TABS -->
                        <div class="parent-tabs-container" style="display: flex; align-items: flex-end">
                            <!-- <h3 class="tab" :class="selectedTab == 'medications' ? 'selected-tab' : ''"
                                @click="selectTab('medications')"
                                v-if="$store.state.user.patient_admin_active || $store.state.user.type == 'prescriber'">
                                Medications</h3> -->
                            <h3 class="tab"
                                v-if="$store.state.user.role.permissions.includes('can_view_prescription') && ($store.state.user.patient_admin_active || $store.state.user.type == 'prescriber')"
                                :class="selectedTab == 'medication_history' ? 'selected-tab' : ''"
                                @click="selectTab('medication_history')">Medication History</h3>
                        </div>
                        <!-- END MEDICATIONS, PATIENT INFO, OBSERVATIONS, ADMINISTRATION, MEDICATION HISTORY TABS -->
                    </div>
                    <div class="main-patient-container" style="border-top-left-radius: 0px; width: 100%; display: grid;"
                        :style="selectedTab != 'patient_info' ? { gridTemplateColumns: '250px 5fr' } : { gridTemplateColumns: '1fr' }">
                        <!-- GENERAL INFO -->
                        <div class="general-patient" v-if="selectedTab != 'patient_info'"
                            style="grid-area: initial; padding: 0 25px; border-top-left-radius: 8px; border-bottom-left-radius: 8px; display: flex;">
                            <div style="width: 220px; margin-right: 10px;">
                                <h2
                                    style="padding-top: 25px; padding-bottom: 12px; margin: 0; color: #0870DC; font-size: 1.2em; text-align: left">
                                    {{ patient.personal_information.first_name }} {{ patient.personal_information.last_name
                                    }}</h2>


                                <div class="divider">
                                    <img class="img-square" :src="patient.image_information.url" alt="Patient Image"
                                        v-if="patient.image_information && patient.image_information.created_at"
                                        onerror="this.onerror=null; this.className='resident-image-default resident-image large admin'" />
                                    <img v-else class="img-square resident-image-default">


                                    <p style="margin: 0 auto; width: 100%; padding: 4.7px 0; margin-bottom: 12px; border-radius: 4px; font-weight: bold; color: white"
                                        v-if="patient.status"
                                        :style="patient.status == 'Inactive' ? { backgroundColor: '#E04F39', border: '1px solid #E04F39' } : patient.status == 'Onboarding' ? { backgroundColor: '#EF9600', border: '1px solid #EF9600' } : { backgroundColor: '#00AD50', border: '1px solid #00AD50' }">
                                        {{ patient.status[0].toUpperCase() + patient.status.slice(1).toLowerCase() }}</p>
                                    <div style='background-color:white !important;'>
                                        <div>
                                            <!-- PATIENT INFO -->

                                            <table class="patientInfo">
                                                <thead>
                                                    <tr>
                                                        <th style="text-align: center; font-weight: bold;">INFO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <p class="tableSubheading">Sex:</p>
                                                        <p class="tableContent">{{ patient.personal_information.sex }}</p>

                                                    </tr>
                                                    <tr>
                                                        <p class="tableSubheading">Preferred Name:</p>
                                                        <p class="tableContent">
                                                            {{ patient.personal_information.preferred_name }}</p>

                                                    </tr>
                                                    <tr>
                                                        <p class="tableSubheading">DOB:</p>
                                                        <p class="tableContent">{{ patient.personal_information.dob }}</p>
                                                    </tr>
                                                    <tr>
                                                        <p class="tableSubheading">Age:</p>
                                                        <p class="tableContent">{{ patient.personal_information.age }}</p>
                                                    </tr>
                                                    <tr>
                                                        <div v-for="(item, index) in patient.regulatory_information"
                                                            :key="index">
                                                            <p class="tableSubheading">
                                                                {{ item.regulatory_type.charAt(0).toUpperCase() +
                                                                    item.regulatory_type.slice(1) }}:</p>
                                                            <p class="tableContent">{{ item.data }}</p>
                                                        </div>
                                                    </tr>
                                                    <tr>
                                                        <div>
                                                            <p class="tableSubheading">Address:</p>
                                                            <p class="tableContent">{{ facility?.address ?
                                                                facility.address.street_address :
                                                                'N/A' }}{{ facility?.address?.suburb ? ', ' :'' }}{{ facility?.address?.suburb }}{{ facility?.address?.state? ', ' : ''}}{{ facility?.address?.state }}{{ facility?.address?.postcode? ', ' : '' }}{{ facility?.address?.postcode }}</p>
                                                        </div>
                                                    </tr>
                                                    <tr>
                                                            <div v-for="(item, index) in patient.contact_methods"
                                                                :key="index">
                                                                <p class="tableSubheading">{{
                                                                    item.type.charAt(0).toUpperCase() + item.type.slice(1)
                                                                }}:</p>
                                                                <p class="tableContent">{{ item.data }}</p>
                                                            </div>
                                                    </tr>
                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>
                                <div style="margin-bottom: 12px;">
                                    <div v-if="spec_cons && $store.state.user.patient_admin_active">
                                        <div v-if="spec_cons.special_considerations && spec_cons.special_considerations.length"
                                            style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 130px;">
                                            <p class="field-value" v-for="(item, index) in uniqueSpecCons" :key="index"
                                                style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 20px; margin-top: 0px; margin-right: 5px;"
                                                :v-tooltip="' ' + item.name + ' '">
                                                {{ item.name.length > 15 ? item.name.slice(0, 15).toUpperCase() + '...' :
                                                    item.name.toUpperCase() }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <hr style="border:none; border-left:2px solid #e8e8e8; height:90%; width:2px; align-self: center"> -->
                        </div>
                        <div style="background-color: white; border-radius: 12px; border-top-left-radius: 0px; border-top-right-radius: 0px;"
                            :style="selectedTab == 'patient_info' ? { borderTopLeftRadius: '8px' } : {}">
                            <!-- <PatientMedicationTable v-if="selectedTab == 'medications' && !displayPrescription"
                                @remapPresChart="remapPresChart" @displayPrescriptionForm="displayPrescriptionForm"
                                @signChart='signChart' @updateChartHeight="updateChartHeight"
                                @addingToReview="addingToReview" @removingFromReview="removingFromReview"
                                @emptyReviewPrescription="emptyReviewPrescription" @retrieveAllCharts="retrieveAllCharts"
                                @getAdministration="getAdministration" :reviewedPrescriptions="reviewedPrescriptions"
                                :loadingCharts="loadingCharts"
                                :facility_id="patient && patient.physical_location && patient.physical_location.location && patient.physical_location.location.facility && patient.physical_location.location.facility.uuid ? patient.physical_location.location.facility.uuid : ''"
                                :charts="charts" :patient="patient" :patientId="$route.params.id" :frequencies="frequencies"
                                :types="typeOptions" :routes="routes" :drugs="drugs" /> -->
                            <!-- <PatientInfo v-if="selectedTab == 'patient_info'" :patient="patient"
                                @changeStatus="changeStatus" /> -->
                            <PatientMedicationHistory v-if="selectedTab == 'medication_history'" :patient="patient"
                                :frequencyOptions="frequencies" />
                            <!-- <PrescriptionChart :displayDropDown="true" :patient="patient" :medicare="medicare"
                                v-if="selectedTab == 'medications' && displayPrescription" :patientId="$route.params.id"
                                :new="true" /> -->
                            <!-- <PrescriptionChart  v-if="selectedTab == 'medications' && displayPrescription" :viewOnly="false" @isSuccessful="isSuccessful" 
                            :patientId="$route.params.id" :activePresChart="activePresChart" :draftDrug="draftDrug" :isDraft="false"
                            :displayDropDown="true" /> -->
                        </div>
                    </div>
                </div>
                <div v-else class="panel-content-container patient-form"
                    style="display: flex; width: 95vw; margin: 0 auto;">
                    <div style='width: 100%; margin-top: 5em;'>
                        <p style="font-size: 1em; font-weight: bold;">Loading Patient...</p>
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                    </div>
                </div>
                <ChartExpansion v-if="displayExpansion" :chartId="displayedChartId"
                    :expandedChartDetails="expandedChartDetails" />
            </div>
            <div v-else class="panel-content-container">
                <div style="margin-top: 10em; grid-column: 1 / 3;">
                    <h3
                        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                        You do not have permission to access this feature. Please talk to your admin if you require access.
                    </h3>
                </div>
            </div>
        </div>
        <!-- <patient-expand-modal v-if="expandModal" @close="close" :modalTitle="modalTitle" :tableData="tableData" /> -->
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
// import PrescriptionChart from '../../components/PrescriptionChart.vue';
// import PatientInfo from '../../components/patientViewComponents/PatientInfo.vue';
// import PatientMedicationTable from '../../components/tables/PatientMedicationTable.vue';
// import PatientExpandModal from '../../components/modals/PatientExpandModal.vue';
import PatientMedicationHistory from '../../components/patientViewComponents/PatientMedicationHistory.vue';
import ChartExpansion from '@/components/ChartExpansion.vue';


export default {
    components: {
        // PatientInfo,
        // PatientMedicationTable,
        // PatientExpandModal,
        ChartExpansion,
        PatientMedicationHistory,
        // PrescriptionChart,

    },
    data() {
        return {
            displayPrescription: false,
            reviewedPrescriptions: [
                //   "f9ab5e13-9cc6-4a27-a274-6fbb85a1289a"
            ],
            loadingCharts: false,
            drugs: [],
            routes: [],
            types: [],
            typeOptions: [],
            frequencies: [],
            infoADRTab: 'INFO',
            selectedTab: 'medication_history',
            expandModal: false,
            //   displayMedicationHistoryModal: false,
            displayedChartId: '',
            expandedChartDetails: {},
            displayExpansion: false,
            modalTitle: 'Primary General Practitioner',
            tableData: null,
            //   this is a string grouping of all the types of charts this patient has
            patientChartTypes: [],
            //   this is the object grouping of all chart types incl name and color
            chartGroups: [],
            drugNames: [],
            registerData: [],
            date: [],
            clearForm: {
                name: '',
                location: this.$store.state.uuid,
                page: 1
            },
            charts: [],
            patient: '',

            //   patient:'',
            form: {
                patient_id: null,
                location: this.$store.state.uuid,
            },
            medicare: '',
            dva: '',
            spec_cons: [],
            facility_id: '',
            facility: ''
        }
    },
    methods: {
        changeStatus(value) {
            console.log('receiving the res status in pat general', value);
            if (value && this.patient) {
                console.log('changing the res status in pat general', value);
                this.patient.status = value.slice(0, 1).toUpperCase() + value.slice(1);
            }
        },
        displayPrescriptionForm(value) {
            if (value) {
                this.displayPrescription = true;
            }
        },
        addingToReview(value) {
            if (value) {
                this.reviewedPrescriptions.push(value.prescription_id);
                this.reviewedPrescriptions = this.reviewedPrescriptions.filter((v, i, a) => a.indexOf(v) === i);
                console.log('ADDED TO PRESCRIPTION REVIEW', this.reviewedPrescriptions);
            }
        },
        removingFromReview(value) {
            if (value) {
                this.reviewedPrescriptions.splice(this.reviewedPrescriptions.indexOf(value), 1);
                console.log('REMOVED FROM REVIEW', this.reviewedPrescriptions);
            }
        },
        emptyReviewPrescription(value) {
            if (value) {
                this.reviewedPrescriptions = [];
                console.log('EMPTYING REVIEW PRESCRIPTIONS', this.reviewedPrescriptions);
            }
        },
        signChart(value) {
            this.charts[value].signNow = true;
            console.log('this is the chart from sign chart', this.charts[value]);
        },
        getAdministration(value) {
            console.log('this is the administration request uuid', this.charts[value].uuid);

            axios.post(`/prescription/${this.charts[value].uuid}/administrations`, {}, this.$store.state.header).then(res => {
                console.log('this is the res for the chart admin history', res);
                this.charts[value].administration = res.data;
            })
        },
        updateChartHeight(value) {
            this.chart[value.index].checkHeight = true;
            console.log('hitting the patient general emit updatechartheight', value);
        },
        selectTab(tab) {
            this.selectedTab = tab;
        },
        displayChartGroupItems(chartType) {
            console.log(chartType);
        },
        searchPatients() {
            this.$store.dispatch('searchPatients', this.form).then(res => {
                console.log('these are the returning patients', res.data);
            })
        },
        close(value) {
            if (value) {
                this.expandModal = false;
                this.displayMedicationHistoryModal = false;
            }
            console.log('getting close to the general');
        },
        openModal(displayContent) {
            this.expandModal = true;
            this.modalTitle = displayContent;
            console.log('this is the computed primary gp', this.getPrimary[0]);
            switch (displayContent) {
                case 'Primary General Practitioner':
                    this.tableData = [{
                        name: this.getPrimary[0].name,
                        address: this.getPrimary[0].address,
                        phone: this.getPrimary[0].phone,
                        after_hours: this.getPrimary[0].after_hours,
                        fax: this.getPrimary[0].fax,
                        email: this.getPrimary[0].email,
                        prescriber_no: this.getPrimary[0].prescriber_no
                    }];
                    break;

                case 'Pharmacy':
                    this.tableData = [this.patient.pharmacy];
                    break;
                // case 'All Prescribers (not primary GP)':
                //     this.tableData = this.getPrescribers;
                //     break;

            }
        },
        getFilterValues() {
            console.log('GOING THROUGH FILTER VALUES')
            this.types = this.types.filter((v, i, a) => a.indexOf(v) === i);

            let chartTypes = this.$store.getters.getChartTypes;
            this.types.forEach(type => {
                chartTypes.forEach(chartType => {
                    if (type == chartType.chart_name) {
                        this.typeOptions.push(chartType);
                        console.log('this is the list of TYPES', this.typeOptions);
                    }
                })
            })
        },
        remapPresChart(value) {
            console.log('remapping data', value);
            value.activePresChart = {};
            // explicitly mapping all datapoints in case of any object changes from the response value

            value.activePresChart = {
                chart_payload: {
                    prescription_id: value.uuid,
                    chart_form: {
                        type: value.chart_type,
                        route: value.route,
                        indication: value.indication,
                        tags: []
                    }
                },
                prescription_payload: {
                    scheduled: value.alias.tags.includes('S8') ? true : false,
                    patient_id: this.$route.params.id,
                    prescriber: value.doctor?.uuid ? value.doctor?.uuid : '',
                    drug_uuid: value.alias.alias_id,
                    substitution_not_permitted: value.substitution_not_permitted ? true : false,
                    chart_id: value.chart_id,
                    authority_prescription_number: value.authority_prescription_number ? value.authority_prescription_number : '',
                    pbs_code: value.pbs_code ? value.pbs_code : '',
                    pbs_manufacturer_code: value.pbs_manufacturer_code ? value.pbs_manufacturer_code : '',
                    pbs_authority_code: value.pbs_authority_code ? value.pbs_authority_code : '',
                    days_of_week: value.days_of_week,
                    interval: value.interval,
                    num_repeats_authorised: value.num_repeats_authorised,
                    repeat_interval: value.repeat_interval,
                    frequency: value.frequency,
                    frequency_shorthand: value.frequency_shorthand,
                    times: value.times,
                    dose: value.dose,
                    dose_unit: value.dose_unit,
                    route: value.route,
                    sliding_scale: value.sliding_scale,
                    max_admin_dose: value.max_admin_dose,
                    max_daily_admin: value.max_daily_admin,
                    is_paper: value.is_paper ? true : false,
                    paper_prescription_number: value.paper_prescription_number ? value.paper_prescription_number : value.is_paper ? '1' : '',
                    reason: value.reason,
                    max_dose: value.max_dose,
                    start_date: new Date(value.start_date).toLocaleDateString('en-AU'),
                    stop_date: new Date(value.stop_date).toLocaleDateString('en-AU'),
                    additional_regulatory_checks: '',
                    administrations: value.administrations,
                    // created_date: !isNaN(Date.parse(value.created_at)) ? new Date(value.created_at).toLocaleDateString('en-AU') : '' ,
                    created_date: new Date(value.created_at).toLocaleDateString('en-AU'),
                    dose_times: value.dose_times,
                    last_administration: value.last_administration ? value.last_administration : '',
                    administration_history: value.administration ? value.administration : '',
                    prescribed_quantity: value.prescribed_quantity,
                    notes: value.instructions && value.instructions.text ? value.instructions.text : '',
                    chart_tags: value.chart_tags,
                    signed: value.signatures.length ? true : false,
                }
            };
            console.log("created at to created date", value.activePresChart.prescription_payload.created_date);
            console.log("created at to created date before conversion", value.created_at);
            value.draftDrug = value.alias;
            // if(!value.signatures && !value.signatures.length){
            //     value.isDraft = true;
            // } else{
            //     value.isDraft = false;
            // }
            console.log('received this from the table', value);
        },
        retrieveAllCharts(filters) {

            this.requiringAction = 0;
            this.frequencies = [];
            this.drugs = [];
            this.types = [];
            this.routes = [];
            let payload = { ...filters };
            // if(!filters.frequency){
            //     delete filters.frequency
            // }
            // if(!filters.type){
            //     delete filters.type
            // }
            // if(!filters.route){
            //     delete filters.route
            // }
            // if(!filters.drug){
            //     delete filters.drug
            // }
            this.loadingCharts = true;
            axios.post(`/person/${this.$route.params.id}/prescriptions`, {
                payload
            }, this.$store.state.header).then(res => {
                console.log('this is the patient summary retrieval', res);
                this.charts = res.data;
                this.charts.forEach(chart => {
                    // chart.created_at = new Date(chart.created_at);
                    // chart.created_at = chart.created_at.toLocaleDateString('en-AU');
                })
                this.$store.dispatch('getRefreshToken');
            }).then(() => {
                let chartTypes = this.$store.getters.getChartTypes;
                for (let i = 0; i < this.charts.length; i++) {
                    this.drugs.push(this.charts[i].alias.name);
                    this.frequencies.push(this.charts[i].frequency);
                    this.types.push(this.charts[i].chart_type);
                    this.routes.push(this.charts[i].route);
                    let b = new Date(this.charts[i].start_date);
                    b = b.toLocaleDateString('en-AU');
                    let c = new Date(this.charts[i].stop_date);
                    c = c.toLocaleDateString('en-AU');
                    this.charts[i].dateRange = `${b} - ${c}`
                    chartTypes.forEach(chartType => {
                        if ((this.charts[i].chart_type == chartType.chart_name) || (this.charts[i].chart_type == chartType.name)) {
                            console.log('LOOPING THROUGH THE CHART ITEMS', this.charts[i].chart_type, chartType.chart_name);
                            this.charts[i].chart_type = chartType.chart_name;
                            this.charts[i].type = chartType.chart_name;
                            this.charts[i].color = chartType.color;
                            this.charts[i].chart_name = chartType.name;
                            this.charts[i].selectedChart = chartType;
                            console.log('this is the color now', this.charts[i].color);
                        }
                    })
                    console.log('looping through this this.charts', this.charts[i], this.charts.length);
                    // this.remapPresChart(this.charts[i]);
                }
                this.loadingCharts = false;
            }).then(() => {
                this.getFilterValues();
                this.loadingCharts = false;
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        getAge(dateString) {
            dateString = dateString.split('/')[1] + '/' + dateString.split('/')[0] + '/' + dateString.split('/')[2];
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            console.log('THIS IS THE AGE AND DATESTRING', age, dateString, new Date(dateString));
            console.log('THIS IS THE birthdate', birthDate);
            console.log('THIS IS THE birthdate', birthDate.getFullYear(), birthDate.getMonth());
            console.log('THIS IS THE today', today);
            console.log('THIS IS THE today', today.getFullYear(), today.getMonth());
            return age;
        },
        getFacilityInfo() {
            axios.post('/get-facility', { facility_id: this.facility_id }, this.$store.state.header).then(res => {
                this.facility = res.data;
                this.$store.dispatch('getRefreshToken');
                console.log('this is the facility data for retrieve patient', this.facility);

            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })

        },
        getBasicInfo() {
            this.medicare = (this.patient.regulatory_information.filter(item => item.regulatory_type == 'Medicare Number'))[0];

            this.dva = (this.patient.regulatory_information.filter(item => item.regulatory_type == 'DVA Number'))[0];
            console.log('this is the medicare number', this.medicare, this.dva);
        },
        getIHI() {
            axios.post(`/person/${this.patientUUID}/ihi`, {}, this.$store.state.header).then(res => {
                this.ihiDetails = res.data;
                if (res.data.conflicted) {
                    this.displayIHIConflictModal = true;
                }
            })
        },
        getPatient() {
            //    GET PATIENT DATA
            axios.post('/retrieve-patient', this.form, this.$store.state.header).then(res => {
                this.patient = res.data;
                this.$store.dispatch('getRefreshToken');
                this.patient.personal_information.age = this.getAge(this.patient.personal_information.dob);
                this.patient.personal_information.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU';
                console.log('this is the res data for retrieve patient', res.data, this.patient);
                if (this.patient.considerations && this.patient.considerations.length) {
                    this.spec_cons = this.patient.considerations[this.patient.considerations.length - 1]
                }

                this.facility_id = this.patient.physical_location?.location?.facility?.uuid;
                this.getBasicInfo();
                this.getFacilityInfo();
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        }
    },
    mounted() {
        console.log('this is the route param', this.$route.params.id);
        this.form.patient_id = this.$route.params.id;
        if (!this.$store.state.user.patient_admin_active) {
            this.selectedTab = 'patient_info';
        }
        this.getPatient();
    },
    computed: {
        getPatients() {
            return this.$store.getters.getPatients;
        },
        getPrimary() {
            return this.patient.prescribers.filter(item => item.primary_gp)
        },
        uniqueSpecCons() {
            let b = this.spec_cons && this.spec_cons.special_considerations ? this.spec_cons.special_considerations : [];
            const set = new Set();
            const result = b.filter((o) => {
                if (set.has(o.name)) return false;
                set.add(o.name);
                return true;
            });
            return result;
        }
        // getPrescribers() {
        //     return this.patient.prescribers.filter(item => !item.primary_gp)
        // }
    }
}
</script>


<style lang="scss">
@import "../../assets/css/main.scss";

table.patientInfo  {
    border-spacing: 0;
    border-collapse: collapse;
}

table.patientInfo th {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: #0870dc;
    color: #ffffff;
    margin-top: 10px; 
}

table.patientInfo td {
    border: 1px solid black;
    text-align: center;
    color: #313030;
}

.tableSubheading {
    text-align: left;
    text-indent: 5%;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5px;
}

.tableContent {
    text-align: left;
    margin-left: 5%;
    margin-top: 5px;
}

.chart-expansion-item {
    text-align: left;
    justify-content: space-between;
    background-color: white;
    // padding: 5px 20px; 
    margin: 10px;
    // border-bottom: 2px solid #cacaca
}

.parent-tabs-container {
    h3 {
        cursor: pointer;
        font-size: 1em;
        margin: 0;
        background-color: #0870DC; //$strongblue;
        padding: 7px 20px;
        border-radius: 20px 20px 0 0;
        color: white;
        min-width: 150px;
    }
}

.selected-tab {
    background-color: white !important;
    color: #0870DC !important; //$strongblue !important;
}

.img-square {
    object-fit: cover;
    object-position: center;
    height: 190px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 5px;
}</style>