<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 80%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Allocate Theatre Medications</h3>
                        <button @click="closeModal()" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel">
                                    <div style="background-color: rgb(229, 241, 254);border-radius: 6px;">
                                        <p class="note-header" style="margin: 0; padding-bottom: 5px;">Notes</p>
                                        <textarea name="notes" id="note-modal" rows="15"
                                        placeholder=" Enter notes here..."
                                        style="width: 100%;border-radius: 6px; border-top-right-radius: 0px; border-top-left-radius: 0px; border: none; background-color: #E5F1FE; padding: 0 8px;"
                                        v-model="notes"></textarea>
                                    </div>
                                </div>
                                <div class="right-form-section-panel">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information"
                                        class="individual-patient-container">

                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p
                                                v-if='patient.regulatory_information && patient.regulatory_information.length'>
                                                <b>{{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].regulatory_type : ''
                                                }}:</b>
                                                {{ patient.regulatory_information &&
                                                        patient.regulatory_information.length ?
                                                        patient.regulatory_information[0].data : ''
                                                }}</p>
                                            <p><b>DOB:</b> {{ patient.personal_information &&
                                                    patient.personal_information.dob ? patient.personal_information.dob :
                                                    'N/A'
                                            }}</p>
                                            <p><b>Location:</b> {{ patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>

                                    <div v-if="patient">
                                        <div style="display: flex; margin-bottom: 10px;" :style="!selectedAnesthetist ? {alignItems: 'flex-end'}:{alignItems: 'center'}">
                                            <div v-if="!selectedAnesthetist"
                                                class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px;  position: relative;">
                                                <label for="first_name"
                                                    style="text-align: left; padding-left: 10px">Search for Anaesthetist</label>
                                                <input v-model="anesthetist" v-on:keyup="anesthetistSearch()"
                                                    placeholder="Enter Anesthetist Name"
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="anesthetist"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6; z-index:9">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in anesthetistResults" :key="index"
                                                        @click="selectAnesthetist(item)"> {{ item.firstname }} {{ item.lastname }}</p>
                                                </div>
                                                
                                            </div>
                                            <div  v-if="selectedAnesthetist.uuid" style="display: flex; align-items: center; gap: 10px;">
                                                <p style="font-size: 1em;">{{ selectedAnesthetist.first_name }}{{selectedAnesthetist.last_name ? " " + selectedAnesthetist.last_name : "" }}</p>
                                                <p style="text-align: left;  cursor:pointer; width: fit-content; font-size: 1em;
                                        border-bottom: 1px solid grey; margin: 0; height: fit-content"
                                                @click="changeAnesthetist()">Change
                                                Anaesthetist</p>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div v-if="!imprest_pom">
                                        <div class="right-form-section-drug-entry">
                                            <div
                                                style="width: 100%; display: grid; grid-template-columns: 1fr; grid-gap: 10px;">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']"
                                                    class="pom-imprest-choice-button">
                                                    Search Patient</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- AFTER THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- <div v-if="patient && selectedAnesthetist && !form.submit.drugs.length"
                                        class="right-form-section-drug-entry">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Medication"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter prescribed quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button" :class="qty > 0 && stock ? 'blue-button': 'grey-button'"
                                        :style="qty > 0 && stock ? {cursor: 'pointer !important'}:{cursor:'initial !important'}"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div>

                                    <div >
                                        <div class="drug-variances-container" style="display: flex; height: fit-content"
                                            v-show='refinedDrugs && refinedDrugs.length'>
                                            <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                            <p v-for="(item, index) in refinedDrugs" :key="index"
                                                @click="item.qty_left >= qty ? aliasSelect(item) : ''"
                                                style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px;"
                                                :style="item.qty_left >= qty ? {border:'2px solid green', cursor: 'pointer'}:{border:'2px solid grey', color: 'grey', backgroundColor: '#f3f4f4'}">
                                                {{ item.alias.name.slice(0, 1).toUpperCase() + item.alias.name.slice(1) }} (In stock: <b :style="item.qty_left >= qty ? {}:{color: '#E04F39'}">{{ item.qty_left }}</b>)
                                            </p>
                                        </div>
                                    </div> -->

                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN -->
                                    <!-- MED SELECT DROPDOWN     -->
                                    <div class="right-form-section-drug-entry"  v-if="!dispensePayload && patient && selectedAnesthetist">
                                        <StockSearch @drugselected="setdrug"/>
                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 25%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkIfExistingItem(selectedDrug.full_item) } }">Add</p>
                                    </div>
                                    <div v-if="addingMed" style="display: flex; align-items: center; justify-content: center;">
                                        <span>Adding medication...</span>
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                                    </div>
                                   

                                    <div class="right-form-section-drug-container" v-if="selectedAnesthetist || drugList">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 30em;">
                                                    <p>{{ item.patient_owned ? `[PATIENT OWNED]`:`` }} {{ item.stock }}</p>
                                                </td>
                                                <td v-if="item.drug && $store.state.blind_count_settings">
                                                    <input type="number" :disabled="item.valid" style="background-color: white; width: fit-content;"
                                                     placeholder="Enter Blind Count"
                                                     :style="item.last_count ? {marginTop: '20px'}:{}" v-model="item.count">
                                                    <p v-if="item.last_count">Last Count: {{ item.last_count }}</p>
                                                </td>
                                                <td v-else></td>
                                                <td v-if="item.drug && $store.state.blind_count_settings">
                                                    <p class="blue-button" style="padding:7px 10px; text-align: center" @click="verifyCount(item)" v-if="!item.valid || !item.count">Verify Count</p>
                                                    <p v-if="item.valid" class="green-button" style="padding:7px 10px; text-align: center">Counted</p>
                                                </td>
                                                <td v-else>   
                                                </td>
                                                <td>
                                                    <p>
                                                        <s v-if="item.qty != item.entered_qty"
                                                            style="">{{ item.entered_qty }}</s>
                                                        <span
                                                            :style="[item.entered_qty > item.qty ? { color: 'red' } : '']">&nbsp;
                                                            {{ item.qty }}</span> {{ item.qty != 'None in stock.' ?
                                                                    item.form : ''
                                                            }}{{ item.qty > 1 ? 's' : '' }}
                                                    </p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <button v-if="!dispensePayload" @click="removeDrug(index)" class="red-close-button small-button">
                                                        <i class="pi pi-times" style='padding: 0'></i>
                                                    </button>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>


                            </div>



                        </form>
                    </slot>
                </div>
                <p style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <div style="padding-right: 20px;">
                        <p v-if="contructRequiredData != ''" style="display: inline; font-weight: 500; font-size: 1em;">Missing Fields: </p>
                        <p v-if="contructRequiredData != ''" style="display: inline;color: red; font-weight: 500; font-size: 1em;">{{ contructRequiredData }}</p>
                        </div>
                        <div class="spacer"></div>
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel</p>
                        
                        <!-- submitTransferAction() -->
                        <p v-if="contructRequiredData == ''" class="regular-button complete-button" style="margin-right: 10px;" @click="submitAllocateMeds()">Allocate Medications</p>
                        <p v-else class="grey-button" style="margin-right: 10px;">Allocate Medications</p>

                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <SignatureModal v-if="displaySignatureModal" @close="close" @isSuccessful="isSuccessful"
            :endpoint="$store.state.blind_count_settings ? '/stock-take-submit' : patient ? '/theatre-checkout': ''" :payload="$store.state.blind_count_settings ? realSubmit2 : realSubmit"
            :saveContext="saveContext" :payload2='$store.state.blind_count_settings ? realSubmit : null' :endpoint2="$store.state.blind_count_settings && patient ? '/theatre-checkout': ''" :dual="dual" />
        <ReferencePrescriberModal @close="close" v-if="displayReferencePrescriberModal" @prescriber="getPrescriber" />
        <SuccessModal v-if="displaySuccessModal" @close="close"
        :message="'There is a discrepancy between what has been entered and the actual stock count. \n Please repeat the count. If discrepancy is repeated, a balance check will be submitted .'" />
    </div>
</template>

<script>
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import StockSearch from '@/components/searches/StockSearch.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import ReferencePrescriberModal from '@/components/modals/ReferencePrescriberModal.vue';
import axios from 'axios';

export default {
    props: ['dispensePayload', 'requisitionIndex'],
    components: {
        SignatureModal,
        SuccessModal,
        PatientSearchModal,
        StockSearch,
        ReferencePrescriberModal
    },
    data() {
        return {
            displayReferencePrescriberModal : false,
            notes: "",
            dual: true,
            imprest_pom: '',
            pom: '',
            patient: '',
            anesthetist: '',
            noStock: false,
            anesthetistResults: [],
            addNew: false,
            patientMeds: [],
            drugList:[],
            selectedDrug:{},
            addingMed: false,
            loadingDrugs: false,
            prescriber: '',
            selectedAnesthetist: '',
            errorMessage: '',
            displaySuccessModal: false,
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            qty: null,
            location: '',
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: '',
            form: {
                submit: {
                    prescriber_number:'',
                    drugs: [],
                    notes: '',
                }
            },
            realSubmit: {
                script_number:'',
                type: '',
                to_from: '',
                drugs: [],
                notes: '',
            },
            realSubmit2:{},
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'TheatreAllocate',
                patient: '',
                location: '',
                drugs: [],
                doubleSig: true
            }
        }
    },
    methods: {
        setdrug(drugFormatted) {
            this.selectedDrug = drugFormatted
        },
        verifyCount(drug){
            console.log('verifying count', drug);
            if(drug.count != drug.qty_left){
                console.log('doesnt match', drug);
                if(!drug.last_count){
                    drug.valid = false;
                    drug.last_count = drug.count;
                    this.displaySuccessModal = true;
                } else{
                    drug.valid = true;
                }
                
            } else{
                console.log('matches', drug);
                drug.valid = true;
            }
        },
        stockTakeSubmit(){
            let payload = {
                notes: `Stock check for delivery.`,
                blind_count: true,
                stocktake:[]
            }

            this.form.submit.drugs.forEach(drug=>{
                if(drug.drug){
                    payload.stocktake.push({
                        uuid: drug.drug,
                        count: drug.count
                    })
                }
                
            })
            this.realSubmit2 = payload;
        },
        getPrescriber(value){
            console.log('getprescriber function is called', value);
            if(value){
                this.selectedAnesthetist = value;
                this.displayReferencePrescriberModal = false;
            }
            
        },
        show(){
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        checkIfExistingItem(item){
            let exit = false;
            if(this.form.submit.drugs.length){
                this.form.submit.drugs.forEach(drugItem => {
                    console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit ) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } else if(!exit){
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else{
                this.aliasSelect(item);
            }
        },
        changeStock() {
            this.stock = this.patientMeds[document.getElementById('pom-select').value];
            this.qty = Number(this.stock.quantity).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('refreshRequisitionItem', this.requisitionIndex);
            this.$emit('close', true);
        },
        anesthetistSearch() {
            axios.post('/get-anesthetist', { name: this.anesthetist }, this.$store.state.header).then(res => {
                    console.log('this is the res data', res.data);
                    this.anesthetistResults = res.data
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
        },
        isSuccessful(value) {
            this.$emit('close', true);
            if (value) {
                console.log('IS SUCCESSFUL!');
                if (this.$route.name == 'Stock') {
                    console.log('loading the stock stuff');
                } 
                if (this.addNew) {
                    this.form.submit.drugs = [];
                    this.stock = '';
                    this.imprest_pom = '';
                    this.patient = '';
                    this.patient_uuid = '';
                    this.prescriber_uuid = '';
                    this.selectedAnesthetist = '';
                    this.refinedDrugs = '';
                } else {
                    this.closeModal();
                }
            }
        },
        close(value) {
            if(this.patient == "") {
                    console.log("geypatt")
                    this.imprest_pom = false;
                }
            if (value) {
                this.displaySuccessModal = false;
                this.displaySignatureModal = false;
                this.displayPatientSearchModal = false;
                this.displayReferencePrescriberModal = false;
                // this.dual = false;
                // if (this.imprest_pom != 'imprest' && !this.patient) {
                //     this.displayPatientSearchModal = false;
                //     this.imprest_pom = '';
                // }
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);
            
            this.form.submit.drugs.unshift({ tags: item.alias.tags, patient_owned: item.bag_id ? true : false, stock: `(${item.alias.generic_name}) ${item.alias.name}`, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left, count: null });
            this.qty = null;
            this.refinedDrugs = [];
            this.noStock = false;
            this.stock = '';
        },
        requisitionDispense(drug, qty){
            this.loadingDrugs = true;
            axios.post('/get-stock-items', { drug: drug.name, patient_id: null }, this.$store.state.header).then(res => {
                console.log('this is the res data getstockitems', res.data);
                this.$store.dispatch('getRefreshToken');
                res.data.forEach(item=>{
                    item.entered_qty = qty;
                    item.full_name = `(${item.alias.generic_name}) ${item.alias.name}`;
                    this.qty = qty;
                    console.log('THIS IS THE DRUG', {stock:item.full_name, qty: qty, drug: item.uuid, tags: drug.tags, qty_left: item.qty_left, form: drug.form})
                    this.form.submit.drugs.unshift({stock:item.full_name, qty: qty, drug: item.uuid, tags: drug.tags, qty_left: item.qty_left, form: drug.form});
                    // this.form.submit.drugs.unshift({ tags: drug.tags, stock: item.full_name, drug: item.uuid, form: drug.form, qty: this.qty, qty_left: item.qty_left });
                })
                console.log('THIS IS THE REQUISITION DISPENSE FUNCTION DRUG/S', res.data, qty);
                this.errorMessage = '';
                this.loadingDrugs = false;
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('this is the transfer stock items error', err.response.data);
                this.errorMessage = err.response.data;
            })
        },
        checkAndAddStock(drugName) {
            axios.post('/get-stock-items', { drug: drugName, patient_id: null  }, this.$store.state.header).then(res => {
                console.log('this is the res data getstockitems', res.data);
                this.$store.dispatch('getRefreshToken');
                this.refinedDrugs = res.data;
                this.errorMessage = '';
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('this is the transfer stock items error', err.response.data);
                this.errorMessage = err.response.data;
            })
        },
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        submitAllocateMeds() {
            let newDrugSub = [];
            let autoDrugs = [];
            this.addNew = false;
            console.log('HERE ARE THE SUBMIT RECEIVE DRUGS', this.form.submit.drugs);
            if(this.$store.state.blind_count_settings){
                this.stockTakeSubmit();
            }   
            
            this.form.submit.drugs.forEach(item => {
                let p = {
                    drug: item.drug,
                    qty: Number(item.qty)
                }
                let ad = {
                    drug: item.stock,
                    qty: Number(item.qty)
                }
                autoDrugs.push(ad);
                // if(item.tags && item.tags.length){
                //     this.dual = true;
                // }

                if (item.qty > 0) {
                    newDrugSub.push(p);
                }
            });
            this.realSubmit = {
                notes: this.notes,
                patient_id: this.patient.uuid,
                anesthetist_id: this.selectedAnesthetist.uuid,
                drugs : newDrugSub
            }
            this.saveContext = {
                drugs: autoDrugs,
                location: JSON.parse(localStorage.getItem('currentLocation')).name,
                patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : '',
                supplemental: this.patient,
                doubleSig: true,
            }

            if (this.form.submit.drugs.length && this.patient) {
                this.displaySignatureModal = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Drugs and a patient are required.'
            }

        },
        selectAnesthetist(anesthetist) {
            this.anesthetistResults = [];
            this.selectedAnesthetist = {
                uuid: anesthetist.uuid,
                first_name: anesthetist.firstname,
                last_name: anesthetist.lastname,
            }
        },
        changeAnesthetist() {
            this.selectedAnesthetist = '';
            this.anesthetist = '';
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                // {uuid: this.patient.uuid}

                // axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                //     console.log('this is the patient register', res);
                //     this.patientMeds = res.data;
                // })
            }
        },
        // submitDispense() {
        //     this.realSubmit = {
        //         drugs: this.form.submit.drugs,
        //         notes: this.form.submit.notes,
        //         // prescriber_uuid: this.selectedAnesthetist ? this.selectedAnesthetist.uuid : '',
        //         patient_uuid: this.patient ? this.patient.uuid : '',
        //     };
        //     if (this.form.submit.drugs.length && this.form.submit.type && this.form.submit.to_from) {
        //         this.addNew = true;
        //         this.form.submit.type = this.transferType;
        //         console.log('this is the form to submit', this.form);
        //         this.form.submit.drugs.forEach(drug => {
        //             delete drug.qty_left;
        //             drug.qty = Number(drug.qty);
        //         })
        //         // this.$store.dispatch('submitDelivery',this.form);
        //         // this.refinedLocations = [];

        //         this.displaySignatureModal = true;
        //         this.errorMessage = '';
        //     } else {
        //         this.errorMessage = 'Drugs and location are required.'
        //     }
        // },
    },
    mounted() {
        if(this.dispensePayload){
            this.imprest_pom = 'patient';
            this.patient = this.dispensePayload.drug.patient;
            console.log('this is the dispense Payload from modal', this.dispensePayload.drug);
            // this.aliasSelect(this.dispensePayload.drug.alias)
            this.requisitionDispense(this.dispensePayload.drug.alias, this.dispensePayload.drug.quantity);
        }
    },
    computed:{
        contructRequiredData() {
            let remaining = []
            let stringbuilder = ""
            if (this.patient == "") {
                remaining.push("Patient") 
            } 
            if (this.selectedAnesthetist == "") {
                remaining.push("Anesthetist") 
            } 
            if (this.form.submit.drugs == "") {
                remaining.push("Medication(s)") 
            } 
            remaining.forEach((field, index) => {
                if(remaining.length == index+1) {
                    stringbuilder += field 
                } else {
                    stringbuilder += (field + ", ")
                }
            })
            return stringbuilder
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}

.spacer {
  flex-grow: 1;
}

 

.right-form-section-drug-container {
    height: 200px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}

.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}
</style>
