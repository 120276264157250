<template>
  <div class="drug-register-view view">
    <div class="panel" style="margin-left: 3em; width: 95%;">
      <!-- HEADER ANF FILTER -->
      <div style="display: flex; align-items: center;">
        <div class="filters">

          <div class="select-filters-container" style="height: 35px;">
            <p style="margin-right: 0; padding: 8px 15px;" class="select-filter-item active-select-filter">
              Administration Round</p>
            <Dropdown @change="selectRound()" class="admin-rooms-dropdown" v-model="selectedRound" :options="rounds"
              optionLabel="name" placeholder="Round" style="position: relative" />
          </div>

          <div style="display: flex; align-items: center">
            <div class="select-filters-container" style="height: 35px;">
              <!-- <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.all = !searchFilter.all; searchFilter.packed = true; searchFilter.non_packed = true; searchFilter.s8 = true}" 
                          v-bind:class="[(searchFilter.packed && searchFilter.non_packed && searchFilter.s8) || searchFilter.all ? 'active-select-filter' : '']" class="select-filter-item">All</p> -->

              <p style="margin-right: 0; padding: 8px 15px;" @click="() => { form.packed = !form.packed; }"
                v-bind:class="[form.packed ? 'active-select-filter' : '']" class="select-filter-item">Packed</p>

              <p style="margin-right: 0; padding: 8px 15px;" @click="() => { form.s8 = !form.s8; }"
                v-bind:class="[form.s8 ? 'active-select-filter' : '']" class="select-filter-item">S8/S4D</p>
              <!-- 
                          <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.non_packed = !searchFilter.non_packed; }" 
                          v-bind:class="[searchFilter.non_packed ? 'active-select-filter' : '']" class="select-filter-item">Non Packed</p> -->
            </div>
          </div>

          <!-- <div style="display: flex; align-items: center">
                        <div class="select-filters-container" style="height: 35px;">
                          <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.sign_filter='all'}" 
                          v-bind:class="[searchFilter.sign_filter=='all' ? 'active-select-filter' : '']" class="select-filter-item">Current</p>

                          <p style="margin-right: 0; padding: 8px 15px;" @click="()=>{searchFilter.sign_filter='unsigned'}" 
                          v-bind:class="[searchFilter.sign_filter=='unsigned' ? 'active-select-filter' : '']" class="select-filter-item">Register 1</p>
                        </div>
                    </div> -->

          <div style="display: flex; align-items: center">
            <div class="select-filters-container" style="height: 35px;">
              <p style="margin-right: 0; padding: 8px 15px;" class="select-filter-item">Location</p>
              <MultiSelect class="admin-rooms-dropdown" @change="selectRoomRange()" v-model="form.locations"
                :options="locations" optionLabel="range" placeholder="Select locations" />


            </div>
          </div>

          <!-- <button class="button submit-button" @click="searchActiveCharts()">Search</button> -->
          <!-- <button class="button clear-button" style="display: flex; align-items: center" 
                        v-bind:class="[searchFilter.prn ? 'active-select-filter' : '']"
                        @click="searchFilter.prn = !searchFilter.prn"><p style="font-weight: bold">PRN</p></button> -->

        </div>

      </div>
      <!-- END HEADER AND FILTER -->
      <div style="display: grid; grid-template-columns: 2fr 5fr; margin-top: 10px;">
        <AdministrationRoundList :patients="adminCharts" @selectPatient="selectPatient" />
        <SelectedPatientAdministration :patient="selectedPatient" :selectedPatientUUID="selectedPatientUUID"
          :adminCharts="adminCharts" @primeSignature="primeSignature" @isCompleted="isCompleted" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import PatientAdministrationSummaryModal from '@/components/modals/PatientAdministrationSummaryModal.vue';
import AdministrationRoundList from '@/components/administration/AdministrationRoundList.vue';
import SelectedPatientAdministration from '@/components/administration/SelectedPatientAdministration.vue';


export default {
  components: {
    // PatientAdministrationSummaryModal,
    AdministrationRoundList,
    SelectedPatientAdministration
  },
  data() {
    return {
      notReady: false,
      toSign: '',
      selectedPatient: '',
      selectedPatientUUID: '',
      selectedRound: '',
      selectedLocations: [],
      expandModal: false,
      locations: [
        {
          range: 'West Wing',
          uuid: '"0f5781a0-6649-4742-ad47-9cf02674984c"'
        },
        {
          range: 'East Wing',
          uuid: '1120ee'
        },
        {
          range: '21-30',
          uuid: '2130ee'
        }
      ],
      form: {
        locations: [],
        // patients:[],
        start_date: '',
        end_date: '',
        packed: true,
        s8: true
      },
      adminCharts: [],
      rounds: [
        {
          name: 'Current - (-1hr) - (+3hrs)',
          value: ''
        },
        {
          name: 'Midnight - 00:00 - 04:00',
          value: ' 00:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Early Morning - 04:00 - 08:00',
          value: ' 04:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Morning - 08:00 - 12:00',
          value: ' 08:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Early Afternoon :00 - 16:00',
          value: ' 12:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Late Afternoon 16:00 - 20:00',
          value: ' 16:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
        {
          name: 'Night 20:00 - 00:00',
          value: ' 20:00:00 GMT+1000 (Australian Eastern Standard Time)'
        },
      ]
    }
  },
  methods: {

    retrieveChartRound() {
      if (!this.form.start_date) {
        let miltime = Date.now();
        let endtime = miltime + 10800000;

        this.form.start_date = new Date(miltime - 3600000).toISOString();
        this.form.end_date = new Date(endtime).toISOString();
      }
      axios.post('/administration-round', this.form, this.$store.state.header).then(res => {
        console.log('THESE ARE THE ROUND OF CHARTS', res);
      }).catch(err => {
        console.log('this is the err', err);
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    selectRound() {
      let miltime = '';
      let endtime = '';
      let nowTime = new Date().toDateString();

      if (this.selectedRound.name == 'Current - (-1hr) - (+3hrs)') {
        miltime = Date.now();
        endtime = miltime + 10800000;
        this.form.start_date = new Date(miltime - 3600000).toISOString();
        this.form.end_date = new Date(endtime).toISOString();
        this.retrieveChartRound();
      } else {
        miltime = nowTime.concat(this.selectedRound.value);
        miltime = new Date(miltime);
        miltime = miltime.getTime();
        endtime = miltime + 14400000;
        this.form.start_date = new Date(miltime).toISOString();
        this.form.end_date = new Date(endtime).toISOString();
        this.retrieveChartRound();
      }

    },
    selectRoomRange() {
      console.log('will send updated room range query', this.searchFilter.location);
    },
    selectPatient(value) {
      if (value) {
        this.selectedPatientUUID = value;
        console.log('this is the selected patient', value);
        axios.post('/retrieve-patient', {
          patient_id: this.selectedPatientUUID,
          location: this.$store.state.uuid,
        },
          this.$store.state.header).then(res => {
            this.selectedPatient = res.data;
            this.selectedPatient.personal_information.age = this.getAge(this.selectedPatient.personal_information.dob);
            this.selectedPatient.personal_information.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU';
            console.log('this is the res data for retrieve patient', res.data, this.selectedPatient);
          }).catch(err => {
            console.log('this is the err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
              console.log('yes, it does include expired')
              this.$router.push('/login');
            }
          })
      }
    },
    openSummary(id) {
      this.expandModal = true;
      this.patientId = id;
    },
    close(value) {
      if (value) {
        this.expandModal = false;
      }
    },
    isCompleted(value) {
      if (!value.item.stock_register_id) {
        console.log('this is the value from isCompleted', value);
        this.selectedPatient.charts[value].completed = true;
        let allCharts = this.selectedPatient.charts.length;
        let chartProgress = 0;
        this.selectedPatient.charts.forEach(chart => {
          if (chart.completed) {
            chartProgress += 1;
            console.log('all charts vs chartprogress', allCharts, chartProgress)
          }
        })
        if (allCharts == chartProgress) {
          this.selectedPatient.completed = true;
          this.selectedPatient.charts.forEach(chart => {
            if (chart.status == "approve") {
              console.log('this APPROVED', chart.status);
            } else if (chart.status != 'R' && chart.status != 'N' && chart.status != 'W') {
              if (this.selectedPatient.report != 'danger') {
                this.selectedPatient.report = 'warning'
              }

            } else {
              this.selectedPatient.report = 'danger'
            }
          })
        }
        this.selectedPatient.error_messages = this.selectedPatient.charts.filter((chart, index, self) =>
          index === self.findIndex((t) => (
            t.status === chart.status
            // && t.chart_type === chart.chart_type 
          ))
        )
      } else {
        this.selectedPatient.charts.forEach(chart => {
          if (chart.alias.uuid == value.item.stock_register_id) {
            chart.removed = true;
          }
        })

      }
    },
    selectDOC(drug) {
      drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
      console.log('this is the drug doc and object', drug.status, drug);
    },
    primeSignature(drug, signStat) {
      // primeSignature(patientIndex, drugIndex, signStat){
      // console.log('this is the patient', this.adminCharts[patientIndex], this.adminCharts[patientIndex].charts)
      if (signStat == 'reject') {
        drug.sign = true;
        drug.status = '';
        // document.getElementById(drug.chart_id+'-approve').style.backgroundColor = 'transparent';
        document.getElementById(drug.chart_id + '-' + signStat).style.backgroundColor = '#E04F39';
        document.getElementById(drug.chart_id + '-' + signStat).style.color = 'white';
      }
      if (signStat == "approve") {
        drug.sign = true;
        drug.status = 'approve';
        // document.getElementById(drug.chart_id+'-reject').style.backgroundColor = 'transparent';
        document.getElementById(drug.chart_id + '-' + signStat).style.backgroundColor = '#00AD50';
        document.getElementById(drug.chart_id + '-' + signStat).style.color = 'white';
      }

    },
    clearChartFilter() {
      this.searchedDrug = '';
      this.searchFilter = {
        location: this.$store.state.uuid,
        chart_type: '',
        sign_filter: 'all',
        drug_alias: ''
      }
      document.getElementById('chart-type').value = null
    },
    searchActiveCharts() {
    },


  },
  mounted() {
    this.selectedPatient = this.adminCharts[0];
    this.form.locations.push(this.locations[0]);
    this.selectedRound = this.rounds[0];
    this.retrieveChartRound();
    // this.selectPatient(this.selectedPatient.uuid);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.select-filters-container {
  p {
    font-size: 15px;
  }
}

.p-dropdown.admin-rooms-dropdown {
  background: #F3F4F4;
  border: none;
}

.admin-chart-form {
  background-color: white;
  border-radius: 12px;

}

.admin-chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  h4 {
    color: $strongblue;
  }

  border-bottom: 2px solid $bordergrey;
  width: 100%;
}

.admin-chart-drug-list-table {
  padding: 0 20px;

  td {
    padding: 0;
  }
}

.pi-info-circle {
  color: $strongblue;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  font-size: 1.3em !important;
  font-weight: bold;
  cursor: pointer;
}

.admin-chart-signature-button {
  background-color: $strongblue;
  color: white;
  height: 27px;
  font-weight: bold;
  margin-left: 10px;

  p {
    margin: 0;
    padding: 0;
  }
}

.doc-select-container {
  select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  }
}

.completed-chart-text {
  color: white;
  font-weight: 500;
  margin: 0;
  font-size: 14.5px;
  padding: 0 10px;
  text-align: left
}

.completed-chart-text:first-of-type {
  margin-top: 10px;
}

.completed-chart-text:last-of-type {
  margin-bottom: 10px;
}
</style>