<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="min-width: 600px; width: 600px">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                            <h3 class="form-header">Add Note</h3>
                        </div>

                        <button @click="closeModal" class="red-close-button" style="width: 30px !important">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">

                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <div>
                                    <Textarea v-tooltip="'Notes'" style="width: 100%; min-height: 100%;"
                                        placeholder="Add note here..." :autoResize="true" v-model="payload.note" />
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <div class="submit-button" style="border-radius: 8px; cursor:pointer" @click="submitNote()">
                            <p style="margin: 0; padding: 5px 10px; ">Submit Note</p>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['close', 'dual', 'warning', 'chartId'],
    components: {
    },
    data() {
        return {
            payload: {
                note: '',
                chart_id: this.chartId
            }
        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        submitNote() {
            this.payload.date = new Date();
            axios.post(`/add-note`, this.payload, this.$store.state.header).then(res => {
                console.log('note has been submitted', res.data)
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            });
            this.closeModal();
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.form-section-container {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-gap: 10px;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.left-form-section-panel {
    padding: 0 20px;

    p {
        margin: 0;
    }

    textarea {
        border: none;
        background-color: $babyblue;
        border-radius: 4px;
        padding: 10px;
    }

    .search-container input {
        background-color: $babyblue;
    }
}


 

.right-form-section-drug-container {
    height: 250px;
    overflow-y: scroll;
}

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.regular-button {
    background-color: black;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    font-weight: bold;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
