<template>
    <div class="administration-round-list-container">
            <div class="administration-search-filters">
                <div class="text-search-container search-container">
                <Dropdown @change="selectPatientRoom()" v-model="searchResult" :options="patients" :optionLabel="'room'" placeholder="Room" style="position: relative" />
                </div>
                <div class="text-search-container search-container">
                <InputText v-on:keyup.enter="updateList()" v-model="filters.name" style="width: 15em; background-color: #F3F4F4; opacity: 1" placeholder="Patient name"/>
                </div>
                <i class="pi pi-filter-slash" style="cursor:pointer" @click="clearFilters()"></i>
            </div>
            <div class="administration-round-results">
                <div v-if="!searchResult">
                    <div v-for="(item, index) in patients" :key="index" class="administration-round-item" :class="selectedItem == index ? 'selected-round-item':''" @click="choosePatient(item.uuid, index)">
                        <div style="display: flex; align-items: center;">
                            <h4 style="color: #0870DC; padding-right: 10px;">{{item.location}}.{{item.room}}</h4>
                            <h4>{{item.name}}</h4>
                        </div>
                        <img :src="item.image" style="width: 120px; border-radius: 8px;"/>
                    </div>
                </div>
                <div v-else>
                    <div class="administration-round-item selected-round-item">
                        <div style="display: flex; align-items: center;">
                            <h4 style="color: #0870DC; padding-right: 10px;">{{searchResult.location}}.{{searchResult.room}}</h4>
                            <h4>{{searchResult.name}}</h4>
                        </div>
                        <img :src="searchResult.image" style="width: 120px; border-radius: 8px;"/>
                    </div>
                </div>
                
            </div>
    </div>
</template>

<script>

export default{
    props:['patients'],
    data(){
        return{
            selectedItem:0,
            filters:{
                name:''
            },
            searchResult:'',
        }
    },
    methods:{
        choosePatient(patient, index){
            this.selectedItem = index;
            this.$emit('selectPatient', patient);
        },
        clearFilters(){
            this.searchResult = '';
            this.filters = {name:''};
        },
        selectPatientRoom(){
            
        },
        updateList(){
            console.log('will send request with this payload', this.filters);
        }
    }
}
</script>

<style lang="scss">
    .administration-round-list-container{
        background-color: white;
        border-radius: 8px;
    }
    .administration-search-filters{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
    }

    .administration-round-item{
        
        padding: 10px 5px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #f3f4f4;
        h4{
            text-align: left;
        }
    }

    .selected-round-item{
        background-color: rgba(8, 112, 220, 0.2);
    }
</style>