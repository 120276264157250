<template>
  <div class="login-view">
    <div class="login-form">
      <div class="login-logo" style="width: fit-content">
        <img src="@/assets/logos/full_logo.png" alt="" style="height:70px;">
      </div>
      <div class="login-form-details" style="margin-top: 1em">
        <h2>Strong Room - Prescriber Registration</h2>
        <div style="display: flex; flex-wrap: wrap">
          <div class="login-form-fields slide-fade">
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">First Name <span style="color: red;">*</span></label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.first_name" placeholder="Enter First Name *">
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Last Name <span style="color: red;">*</span></label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.last_name" placeholder="Enter Last Name *">
            </div>
            <div class="form-field wide-text-field" style="width: 95%;">
              <label for="first_name">Timezone <span style="color: red;">*</span></label>
              <Dropdown id="route-type" placeholder="Select Timezone" :options="timezones"
                v-model="registerLogin.timezone" />
            </div>
            <div class="form-field wide-text-field" style="width: 95%;">
              <label for="contact_name">Branch State <span style="color: red;">*</span></label>
              <Dropdown v-model="registerLogin.state_id" :options="states" optionValue="id" optionLabel="code"
                :placeholder="'Select state'" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Identification Number <span style="color: red;">*</span></label>
              <input type="text" style="position: relative; width: 26em; background-color: #E5F1FE;"
                v-model="registerLogin.regulatory_numbers[0].data"
                placeholder="Enter Identification Number (e.g. AHPRA No.)">
            </div>
          </div>
          <div class="login-form-fields slide-fade">
            <!-- CONTACT METHODS -->
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">E-mail (this will be your login email) <span style="color: red;">*</span></label>
              <input placeholder="Email" v-model="registerLogin.contact_methods[0].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Password <span style="color: red;">*</span></label>
              <input placeholder="Password" v-model="registerLogin.password" type="password"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Confirm Password <span style="color: red;">*</span></label>
              <input placeholder="Password" v-model="confirmPassword" type="password"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
                <span style="color: red;" v-if="registerLogin.password.length && confirmPassword.length && (registerLogin.password != confirmPassword)">Passwords do not match.</span>
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Phone Number <span style="color: red;">*</span></label>
              <input placeholder="Phone Number" v-model="registerLogin.contact_methods[1].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">After-hours Phone Number</label>
              <input placeholder="After-hours Phone Number" v-model="registerLogin.contact_methods[2].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <div class="form-field wide-text-field" style="width: 80%;">
              <label for="first_name">Fax Number</label>
              <input placeholder="Fax Number" v-model="registerLogin.contact_methods[3].data" type="text"
                style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" />
            </div>
            <!--  END OF CONTACT METHODS -->
          </div>
        </div>
        <div style="margin-top: 2em; display: grid; justify-items: center; margin-right: 1em;">
          <p @click="confirmPassword == registerLogin.password ? submitRegistration() : ''" class="blue-button"
            style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; "
            :style="confirmPassword == registerLogin.password? {}:{backgroundColor: '#c8c8c8 !important', cursor:'inherit'} ">
            Register</p>
            <span style="color: red;" v-if="registerLogin.password.length && confirmPassword.length && (registerLogin.password != confirmPassword)">Passwords do not match.</span>
        </div>

        <p v-if="registrationError" style="color: red; font-size: 1em;">Error: {{ registrationError }}</p>




      </div>
    </div>
    <div class="login-context">

      <div class="login-logo" style="display: grid; justify-content: flex-end">
        <img src="@/assets/logos/short_logo.png" alt="" style="height:70px;">
      </div>
      <h1 style="margin-top: 3em; ">The AI-Driven Drug Management Platform</h1>

      <p style="font-size: 1.4em; font-weight: 500">Powering a secure digital gateway to quickly and safely access
        specialised treatment plans for patients.</p>
    </div>
      <SuccessModal :loading="loading" @close="close"
            v-if="displaySuccessModal" :statusMessage="'Submitting Registration'"
            :first_spec_line="displayMessage" :second_spec_line="displayMessage2" />
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue'


export default {
  components: {
    SuccessModal
  },
  data() {
    return {
      confirmPassword:'',
      displaySuccessModal: false,
      loading: false,
      displayMessage: '',
      displayMessage2: '',
      registrationError: '',
      company_name: '',
      registerLogin: {
        first_name: '',
        last_name: '',
        password: '',
        timezone: '',
        state_id: '',
        contact_methods: [
          {
            name: 'Email',
            data: '',
            type: 'email'
          },
          {
            name: 'Phone',
            data: '',
            type: 'phone'
          },
          {
            name: 'After hours',
            data: '',
            type: 'phone'
          },
          {
            name: 'Fax',
            data: '',
            type: 'fax'
          }
        ],
        regulatory_numbers: [
          {
            data: '',
            regulatory_type: 'Prescriber Number',
            expiry: ''
          }
        ]
      },
      timezones: [],
      states: []
    }
  },
  methods: {
    close(value){
      if(value){
        this.displaySuccessModal = false;
        this.$router.push('/login');
      }
    },
    submitRegistration() {
      // let methods=[];
      this.loading = true;
      this.displaySuccessModal = true;
      this.displayMessage = 'Your registration is being submitted...'
      this.registerLogin.contact_methods.forEach((method, index) => {
        if (method.data) {
          this.registerLogin.contact_methods.slice(this.registerLogin.contact_methods[index], 1);
        }
      })
      // console.log('THESE ARE THE METHODS', methods);

      setTimeout(() => {
        //  this.registerLogin.contact_methods = methods;
        axios.post(`/onboarding/${this.$route.params.id}`, this.registerLogin).then(res => {
          console.log('this is the reg completion', res.data);
          this.displayMessage = 'Your registration is successful!';
          this.loading = false;
        }).catch(err => {
          console.log('this is the error with regstration', err);
          this.loading = false;
          this.displayMessage2 = 'Error:'+ err;
          this.displayMessage = 'Sorry that has been an error with your submission. Please try again later.'
        })
      }, 300)

    },
    retrieveStates() {
      axios.post('/get-states', {}).then(res => {
        this.states = res.data;
      }).catch(err => {
        console.log('this is the state error', err.response.data);
      })
    },
    getTimezones() {
      axios.post('/get-timezones', {}).then(res => {
        this.timezones = res.data;
      }).catch(err => {
        console.log('err issss', typeof err.response.data);
      })
    },
  },
  mounted() {
    this.getTimezones();
    this.retrieveStates();
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login-context {
  background: linear-gradient(84.65deg, #10DCF4 31.37%, #0870DC 87.12%);
  color: white;
  padding-top: 8.125em;
  padding-left: 17em;
  padding-right: 8em;
  text-align: left;

  .login-logo {
    justify-content: flex-end;
  }

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  justify-self: flex-start;
}

.login-form {
  padding: 8.125em 4.375em;
  border-bottom-right-radius: 8.25em;
  background-color: white;
  margin-right: -10em;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.login-form-details {

  width: fit-content;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10em;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;

  h2 {
    text-align: left;
  }
}

.login-form-fields {
  display: grid;
  justify-content: center;
}

.form-error {
  margin: 0;
  margin-bottom: 1em;
  color: $error;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
